import React, { useContext } from 'react';
import SideMenu from '../SideMenus/SideMenu';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { makeStyles, CssBaseline, Card } from '@material-ui/core';
import Footer from '../../Footer';
import Users from './Users';
import Loading from '../Loading';
import TeamManagers from './TeamManagers';
import AdminActivities from './AdminActivities';
import SideMenuV2 from '../SideMenus/SideMenuV2';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
        // marginBottom: theme.spacing(2),
    },
}))
//          Current roles- updated 20/12/2022
//         || roles.includes('viewAcc')
//         || roles[0] === 'member' && roles.length === 1
//         || roles.includes('memberIS&T')
//         || roles.includes('memberHR')
//         || roles.includes('memberENG')
//         || roles.includes('memberAM')
//         || roles.includes('memberFacilities')
//         || roles.includes('admin')
//         || roles.includes('adminIS&T')
//         || roles.includes('adminHR')
//         || roles.includes('adminENG')
//         || roles.includes('adminAM')
//         || roles.includes('adminFacilities')
//         || roles.includes('teamManager')
//         || roles.includes('wiseAcademyEditor')

const AdminConsole = (props) => {
    const classes = useStyles();
    const { isAuthenticated, loading, role, roles } = useContext(AuthContext);

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        } else {
            if (roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={45} />
                            <div className={classes.main}>

                                {/* <h1>Admin Console</h1> */}
                                <Users />
                                <br />
                                {<TeamManagers />}
                                <br />
                                <Card>
                                    <AdminActivities />
                                </Card>

                            </div>
                        </CssBaseline>
                        <Footer />

                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}

export default AdminConsole