import React from 'react';
import { makeStyles } from '@material-ui/core';
import women from '../../../../images/womenDay2023.jpg'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '70%',
        borderRadius: 20,
    },

}));

export default function WomensDay2023() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                <h2 className={classes.textCentered}>International Women's Day 2023</h2>

            </header>
            <div className={classes.textCentered}>
                <img className={classes.media} src={women} alt="women" />
            </div>

            <article >
                <h5 >Dear Colleagues,</h5>
                <section>
                    Wednesday the 8th of March is International Women’s Day and we would like to celebrate it together with you.
                    <br />
                    Happy Women’s Day to all the amazing female employees of our company.
                    We consider ourselves privileged to have such incredible and talented women in our company.
                    <br />
                    Best wishes have a wonderful day.  🙋

                </section>
                <br />

            </article>

            <h4 className={classes.textCentered}>
                Enjoy some delicious sweets and cakes in the canteen during your lunch break.
            </h4>


            <br />
            <p>Regards,<br /><br />

                Evelyn Wiacek<br />
                {/* Global Head of HR<br />
            Wisetek™<br /> */}
            </p>
            <br />

        </div>
    )
}