import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, makeStyles, TextField } from '@material-ui/core'
import React, { useState } from 'react';
import { useContext } from 'react';
import { DataContext } from '../../../contexts/DataContext';

const useStyles = makeStyles(theme => ({
    internalPositionCard: {
        maxWidth: 500,
        backgroundColor: '#EAEDF2',
        border: '2px solid lightgreen',
        margin: '25px',
        padding: '50px',
        fontSize: 'medium'
    },
    rotate: {
        padding: 5,
        backgroundColor: 'lightgreen',
        textAlign: 'center',
        color: 'darkgreen',
        width: 200,
        transform: 'rotateY(0deg) rotate(-45deg) translate(-50px,-100px)'
    },
    textCenter: {
        textAlign: 'center'
    },
    margin10: {
        margin: 10
    },
    referTextfield: {
        maxWidth: 350
    },
    buttons: {
        maxWidth: 200,
        margin: 10
        // margin: '10px 30px 10px 30px',
    },
    buttonLink: {
        textDecoration: 'none',
        "&:link": {
            textDecoration: 'none',
        },
        "&:visited": {
            textDecoration: 'none',
        },
        "&:hover": {
            textDecoration: 'none',
        },
        "&:active": {
            textDecoration: 'none',
        }
    }
}))

export default function InternalPositionCard(props) {
    const classes = useStyles();

    const { sendInternalPositionEmail } = useContext(DataContext)

    const [open, setOpen] = useState(false);
    const [currentPositionData, setCurrentPositionData] = useState({ ...props.data, userEmail: '', friendEmail: '' })
    // console.log(currentPositionData)

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setCurrentPositionData({
            ...currentPositionData,
            userEmail: '',
            friendEmail: ''
        })
        setOpen(false);
    };
    const handleChange = (e) => {
        setCurrentPositionData({
            ...currentPositionData,
            [e.target.name]: e.target.value
        })
    };
    const handleSubmit = (e) => {
        if (currentPositionData.userEmail && currentPositionData.friendEmail) {
            if (currentPositionData.userEmail !== currentPositionData.friendEmail) {
                sendInternalPositionEmail(currentPositionData)
                    .then(success => {
                        console.log('feedback submitted')
                        //display success
                        props.setSnackbar({
                            open: true,
                            severity: 'success',
                            message: '"Refer a friend" email successfully submited. Thank you.',
                        });
                        handleClose()
                    })
                    .catch(error => {
                        console.log(error)
                        //display error
                        props.setSnackbar({
                            open: true,
                            severity: 'error',
                            message: 'Could not send "Refer a friend" email at this time. Please try again later.',
                        });
                    })
            }
            else {
                //display error
                props.setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: "Both emails can't be the same, please provide two different emails.",
                })
            }
        }
        else {
            //display error
            props.setSnackbar({
                open: true,
                severity: 'warning',
                message: "Please provide two different emails.",
            })
        }
    }


    return (
        <div>

            <Card className={classes.internalPositionCard} elevation={3}>
                <p className={classes.rotate}>{props.data.Status}</p>
                <h2 className={classes.textCenter}>{props.data.Title}</h2>
                <br />
                <p><b>Job type: </b>{props.data.JobType}</p>
                <p><b>Location: </b>{props.data.Location}</p>
                <p><b>Description: </b>{props.data.Description}</p>
                {/* <p>{props.data.UrlLink}</p> */}
                {/* <p>{moment(props.data.dateCreated.toDate()).format('DD MMM YYYY')}</p> */}
                <br /><br /><br />
                <div className={classes.textCenter}>
                    <Button variant='outlined' color='primary' fullWidth className={classes.buttons} onClick={handleClickOpen}>Refer a friend</Button>
                    <Link href={props.data.UrlLink} className={classes.buttonLink} target="_blank" rel="noopener noreferrer"><Button variant='outlined' color='primary' fullWidth className={classes.buttons}>Apply</Button></Link>
                </div>
            </Card>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" className={classes.textCenter}>Refer a Friend</DialogTitle>
                <DialogContent>
                    <DialogContentText>To refer a friend please tell us your and your friend email, and we will take care of the rest.</DialogContentText>
                    <div className={classes.textCenter}>
                        <br /><br />
                        <TextField
                            autoFocus
                            className={classes.referTextfield}
                            value={currentPositionData.userEmail}
                            onChange={handleChange}
                            margin="dense"
                            id="userEmail"
                            name="userEmail"
                            label="Your Email Address"
                            type="email"
                            fullWidth
                        />
                        <br /><br />
                        <TextField
                            // autoFocus
                            className={classes.referTextfield}
                            value={currentPositionData.friendEmail}
                            onChange={handleChange}
                            margin="dense"
                            id="friendEmail"
                            name="friendEmail"
                            label="Friend Email Address"
                            type="email"
                            fullWidth
                        />
                        <br /><br /><br />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='secondary' onClick={handleClose} className={classes.margin10}>Cancel</Button>
                    <Button variant='outlined' color='primary' onClick={handleSubmit} className={classes.margin10}>Send Email</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
