import React, { useContext } from 'react'
import { Card, CssBaseline, Grid, makeStyles } from '@material-ui/core'
import Footer from '../../Footer'
import SideMenuV2 from '../SideMenus/SideMenuV2'
import referFriend from '../../../images/ReferFriend.png'
import referFriendTandC from '../../../images/ReferFriendTandC.png'
import { useEffect } from 'react'
import { db } from '../../../config/fbConfig'
import { useState } from 'react'
import moment from 'moment'
import InternalPositionCard from './InternalPositionCard'
import SnackbarComponent from '../../customComponents/SnackbarComponent'
import { Redirect } from 'react-router-dom'
import { AuthContext } from '../../../contexts/AuthContext'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    cardComponent: {
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    textCentered: {
        textAlign: 'center'
    },
    textCenteredAndMargin: {
        textAlign: 'center',
        margin: 50
    },
    mediaVideo: {
        width: '40%',
        borderRadius: 10,
        margin: 50
    },
    mediaImage1: {
        width: '38%',
        borderRadius: 10,
        margin: 50
    },
    mediaImage2: {
        width: '70%',
        borderRadius: 10,
        margin: 50
    },
}));

export default function Careers() {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    const [internalCareers, setInternalCareers] = useState([])

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    useEffect(() => {
        getInternalCareers()

        return () => { }
    }, [])

    function getInternalCareers() {
        db.collection('careersInternal')
            .where('Status', '==', 'Open')
            .orderBy("dateCreated", "desc")
            .get()
            .then(snap => {
                var snapData = []
                snap.forEach(doc => {
                    snapData.push({ ...doc.data(), id: doc.id, })
                })
                setInternalCareers(snapData)
            })
            .catch(err => {
                console.log("Error getting internal positions: ", err);
            })
    }

    if (!isAuthenticated || roles.includes('viewAcc')) {
        return <Redirect to='/' />
    }
    else {
        return (
            <div className={classes.root}>
                <CssBaseline >
                    <SideMenuV2 selectedIndex={49} openHumanResourcesColapse={true} />
                    <div className={classes.main}>
                        <Card className={classes.cardComponent}>
                            <div className={classes.textCentered}>
                                <div className={classes.textCentered}>
                                    <img className={classes.mediaImage1} src={referFriend} alt="Refer a friend" />
                                    <video id='referFriendSlideshow' className={classes.mediaVideo} src='https://storage.googleapis.com/wisetekappappspotcom/hrPublic/ReferFriendSlideshow.m4v'
                                        type="video/mp4"
                                        controls
                                        controlsList="nodownload">
                                        Your browser does not support HTML video.
                                    </video>
                                    <img className={classes.mediaImage2} src={referFriendTandC} alt="Refer a friend Terms & Conditions" />
                                </div>

                                <br /><br />
                                <h2>Currently Open Internal Positions</h2>

                            </div>
                            <p>Below you can check all currently open internal positions that we have in Wisetek, each position will have two buttons to "REFER A FRIEND" or "APPLY".<br />By clicking "REFER A FRIEND" button you will need to provide email address of your frined and we will send an email to this candidate with all the details on how to apply for this position. By clicking 'APPLY' button you will be redirected to our external application to finish application process.</p>
                            <br />
                            {internalCareers.length > 0 ?
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                >
                                    {internalCareers.map((intPos, i) => (
                                        <InternalPositionCard key={i} data={intPos} setSnackbar={setSnackbar} />
                                    ))}
                                </Grid>
                                :
                                <div className={classes.textCenteredAndMargin}><i>Sorry, There are no open positions at this moment. Please check back here at later date.</i></div>
                            }
                        </Card>
                    </div>
                </CssBaseline>
                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                <Footer />
            </div>
        )
    }
}
