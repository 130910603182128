import React from 'react';
import { makeStyles } from '@material-ui/core';
import summerBBQ from '../../../../images/BBQ2023.png'
import bbq2023slidesVideo from '../../../../images/bbq2023slidesVideo.mp4'


const useStyles = makeStyles(theme => ({
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaVideoSlide: {
        width: '100%',
        borderRadius: 20,
    },
    media: {
        width: '60%',
        borderRadius: 20,
    },
}));

export default function BBQ2023(props) {
    const classes = useStyles();

    return (
        <div className={classes.mainDiv}>
            <br />
            <br />
            <p>Dear Colleagues,</p>
            <p>Please see some pictures from our Summer BBQ 2023</p>
            <br />
            <br />
            <div className={classes.textCentered}>
                {/* <img className={classes.media} src={women} alt="women" /> */}
                <video id='slides' className={classes.mediaVideoSlide} src={bbq2023slidesVideo} loop muted autoPlay
                    type="video/mp4"
                    // poster={posterVol2}
                    controls
                    controlsList="nodownload">
                    Your browser does not support HTML video.
                </video>
            </div>
            <br />
            <br />
            <p>Thank you for joining us at  Annual Summer BBQ in 2023, raffles, games, food, music and more....</p>
            <p>This year it was held in "Deep South" 51 Grand Parade, Cork. Please check tha map below for directions.</p>
            <br />
            <br />
            <div className={classes.textCentered}>
                <img className={classes.media} src={summerBBQ} alt="Annual Summer BBQ" />
            </div>
            <br />
            <br />
            <div className={classes.textCentered}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.1614797053588!2d-8.47539942485036!3d51.89626442176696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4844901a73b5c595%3A0xa2080b5c9c9e1bab!2sDeep%20South!5e0!3m2!1sen!2sie!4v1681807137317!5m2!1sen!2sie" width="90%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <br /><br />
                <h4>See you all there!</h4>
                <br /><br />
            </div>

            <p>Regards,<br /><br />
                Evelyn Wiacek<br />
            </p>
            <br />
            <br />
        </div>
    )
}