import React, { useState, useContext } from 'react'
import { TextField, makeStyles, Button, Grid, Slider } from '@material-ui/core'
import { DataContext } from '../../../contexts/DataContext'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { green } from '@material-ui/core/colors';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({

    detailBody: {
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '50px',
        paddingRight: '50px',
    },
    tableContainer: {
        backgroundColor: '#90f0a9'
    },
    myForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '35ch',
            maxWidth: '70ch',
        },
        padding: '35px',
    },
    addButton: {
        margin: theme.spacing(1),
    }

}));

const UserTasksDetails = (props) => {
    // console.log(props.userData)
    const classes = useStyles();

    const { updateWeeklyTask } = useContext(DataContext);

    const [hours, setHours] = useState({
        assigned: props.userData.allocatedHours,
        actual: props.userData.actualHours > -1 ? props.userData.actualHours : props.userData.allocatedHours,
        // userSetActual: 0,
        additionalUserComment: props.userData.additionalUserComment,
        id: props.userData.id
    })

    //#region Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    const handleSubmit = (e) => {
        e.preventDefault();

        updateWeeklyTask(hours)
            .then(success => {
                //display success
                console.log('back from db with success')
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                })
                props.refreshTasks();
            })
            .catch(error => {
                //display error
                console.log('back from db with error')
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                })
            })
    };


    const handleChange = (event, newValue) => {
        // console.log(event, newValue)
        setHours({
            ...hours,
            actual: newValue
        })
    };
    const handleChangeText = (e) => {
        setHours({
            ...hours,
            [e.target.name]: e.target.value
        })
    };
    function valuetext(value) {
        return `${value}h`;
    }

    return (
        <div className={classes.detailBody}>

            <p>Have you finished this task?</p>
            <p>Below you can submit actual hours you spent on it and add optional tekst message.</p>
            <form className={classes.myForm} autoComplete="off" onSubmit={handleSubmit}>
                <Grid container direction="column" justifyContent="center" alignItems="center">

                    <Slider
                        name="hoursSlider"
                        value={hours.actual}
                        // defaultValue={hours.actual > -1 ? hours.actual : hours.assigned}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="on"
                        step={0.5}
                        marks
                        min={0}
                        max={37.5}
                        onChangeCommitted={handleChange}
                    />
                    <br />
                    <br />
                    <TextField
                        size="small"
                        name="additionalUserComment"
                        id={"additionalUserComment"}
                        label="Optional User Comment"
                        // variant="outlined"
                        value={hours.additionalUserComment}
                        fullWidth
                        className={classes.textfield}
                        inputProps={{ maxLength: 100 }}
                        helperText='* limited to 100 characters'
                        onChange={handleChangeText}
                    />
                    <br />
                    <br />
                    <Button type="submit"
                        className={classes.addButton}
                        variant="contained"
                        color="default"
                        startIcon={<DoneOutlineIcon style={{ color: green[500] }} />}
                    >
                        Hours Spent
                    </Button>
                </Grid>
            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    )
}

export default UserTasksDetails