import React, { useContext, useState, useEffect } from 'react';
import { CssBaseline, makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import Footer from '../../Footer'
import { db } from '../../../config/fbConfig';
import { DataContext } from '../../../contexts/DataContext';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import SideMenuV2 from '../SideMenus/SideMenuV2';
import Loading from '../Loading';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    myTable: {
        padding: '0px',
    },
    overrides: {
        MuiTableCell: {
            root: {
                paddingTop: '2px',
                paddingBottom: '2px',
                "&:last-child": {
                    paddingRight: '2px',
                    paddingLeft: '2px'
                }
            }
        }
    }
}));

const EngActionTracker = (props) => {

    const classes = useStyles();

    const { createEngActionTracker, updateEngActionTracker } = useContext(DataContext);

    const { isAuthenticated, loading, roles, displayName } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const [tracker, setTracker] = useState({
        columns: [
            { title: 'Task Submit Date', field: 'taskSubmitDate', type: 'date', initialEditValue: moment().format('DD-MMM-YYYY'), defaultSort: 'asc', editable: 'never' },
            {
                title: 'Status', field: 'status', initialEditValue: 'Open', lookup: {
                    'Open': 'Open',
                    'WIP - Scope': 'WIP - Scope',
                    'WIP - Dev': 'WIP - Dev',
                    'WIP - UAT': 'WIP - UAT',
                    'WIP - Production Cut in': 'WIP - Production Cut in',
                    'On Hold': 'On Hold',
                    'Completed': 'Completed',
                    'Closed - Not Needed': 'Closed - Not Needed',
                    'Aknowledge': 'Aknowledge',
                },
            },
            {
                title: 'Priority', field: 'priority', initialEditValue: 'Low', lookup: {
                    'Low': 'Low',
                    'Medium': 'Medium',
                    'High': 'High'
                }
            },
            { title: 'Description', field: 'description', initialEditValue: '' },
            { title: 'Submited By', field: 'raisedBy', initialEditValue: displayName, editable: 'never' },
            { title: 'Requested By', field: 'requestedBy', initialEditValue: '' },
            {
                title: 'Assigned Engineer', field: 'assignedEng', initialEditValue: '', lookup: {
                    '': '',
                    'Declan Maguire': 'Declan Maguire',
                    'Dermot Moane': 'Dermot Moane',
                    'Donnacha McEnery': 'Donnacha McEnery',
                    'John Murphy': 'John Murphy',
                    'John Morrison': 'John Morrison',
                    'Kiran AR': 'Kiran AR',
                    'Pat Cunningham': 'Pat Cunningham',
                    'Pierce Whelan': 'Pierce Whelan',
                }
            },
            { title: 'Planned Start Date', field: 'startDate', type: 'date', initialEditValue: moment().format('DD-MMM-YYYY') },
            { title: 'Estimated Completion Date', field: 'completionDate', type: 'date', initialEditValue: moment().format('DD-MMM-YYYY') },
            { title: 'Comments / Actions', field: 'commentAction', initialEditValue: '' },
            // { title: 'Item UID', field: 'docID', editable: 'never' },

            // {
            //     title: 'Description', field: 'Description',
            //     editComponent: props => (
            //         <TextField
            //             className={classes.minWidth}
            //             placeholder="Description"
            //             inputProps={{ maxLength: 500 }}
            //             value={props.value}
            //             onChange={e => props.onChange(e.target.value)}
            //         />
            //     )
            // },
        ],
        data: []
    })

    const allTrackers = [];
    function getAllActionTrackers() {
        db.collection('engActionTrackers').orderBy('taskSubmitDate', 'asc').get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    allTrackers.push({
                        ...doc.data(),
                        docID: doc.id,
                        taskSubmitDate: moment(doc.data().taskSubmitDate.toDate()).format('DD-MMM-YYYY'),
                        startDate: moment(doc.data().startDate.toDate()).format('DD-MMM-YYYY'),
                        completionDate: moment(doc.data().completionDate.toDate()).format('DD-MMM-YYYY'),
                    });
                })
                setTracker({
                    ...tracker,
                    data: allTrackers
                })
            })
            .catch(function (error) {
                console.log("Error getting trackers data: ", error);
            });
    }
    useEffect(() => {
        getAllActionTrackers()
        return () => { }
    }, []);


    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('memberENG') || roles.includes('adminENG') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={41} openEngColapse={true} />
                            {/* <Container component="main" className={classes.main} maxWidth="xl"> */}

                            <div className={classes.main}>
                                {/* <h1>Engineering - Action Tracker</h1> */}
                                <MaterialTable
                                    title={"Action Trackers"}
                                    //tableRef={this.tableRef}
                                    columns={tracker.columns}
                                    data={tracker.data}
                                    options={{ addRowPosition: "first" }}//actionsColumnIndex: -1 }}
                                    actions={[
                                        {
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => getAllActionTrackers(),
                                        }
                                    ]}
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    setTracker(prevState => {
                                                        const data = [...prevState.data];
                                                        data.unshift(newData);

                                                        createEngActionTracker(newData)
                                                            .then(success => {
                                                                // console.log('Added new tracker to ENG DB')
                                                                //display success
                                                                setSnackbar({
                                                                    open: true,
                                                                    severity: 'success',
                                                                    message: success,
                                                                })
                                                                getAllActionTrackers()
                                                            })
                                                            .catch(error => {
                                                                console.log(error)
                                                                //display error
                                                                reject()
                                                                setSnackbar({
                                                                    open: true,
                                                                    severity: 'error',
                                                                    message: error,
                                                                })
                                                            });
                                                        resolve();
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>

                                            new Promise(resolve => {
                                                // console.log(oldData);
                                                // console.log(newData);
                                                setTimeout(() => {
                                                    if (oldData) {
                                                        setTracker(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;

                                                            // Update data
                                                            updateEngActionTracker(newData)
                                                                .then(success => {
                                                                    console.log('Updated tracker in ENG DB')
                                                                    //display success
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'success',
                                                                        message: success,
                                                                    })
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                    //display error
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'error',
                                                                        message: error,
                                                                    })
                                                                });
                                                            resolve();
                                                            return { ...prevState, data };
                                                        })
                                                    }
                                                }, 600)
                                            }),
                                        // onRowDelete: oldData =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             resolve();
                                        //             setTracker(prevState => {
                                        //                 const data = [...prevState.data];
                                        //                 data.splice(data.indexOf(oldData), 1);
                                        //                 return { ...prevState, data };
                                        //             });
                                        //         }, 600);
                                        //     }),
                                    }}
                                />
                            </div>

                            {/* </Container> */}
                        </CssBaseline>
                        <Footer />

                        <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}

export default EngActionTracker