import React, { useState, useContext, useEffect } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, makeStyles, MenuItem, TextField } from '@material-ui/core'
import { DataContext } from '../../../contexts/DataContext'


const useStyles = makeStyles(theme => ({
    marginOne: {
        margin: 15
    },
    checkboxArea: {
        margin: 15,
        padding: '10px 50px 15px 50px',
        border: 'solid 1px lightgrey',
        borderRadius: 10
    },
    checkboxMargin: {
        marginRight: 40
    },
    selectTextfield: {
        width: 223,
        margin: 15
    },
    buttonLeft: {
        float: 'left',
        marginLeft: '24px',
        marginTop: '24px',
        marginBottom: '35px',

    },
    buttonRight: {
        float: 'right',
        marginTop: '24px',
        marginBottom: '35px',

    },


}))

const usersRoles = [
    'admin',
    'adminAM',
    'adminCS',
    'adminENG',
    'adminFacilities',
    'adminHR',
    'adminIS&T',
    'adminSalesOps',
    'member',
    'memberAM',
    'memberCS',
    'memberENG',
    'memberFacilities',
    'memberHR',
    'memberIS&T',
    'memberSalesOps',
    'teamManager',
    'viewAcc',
    'partnerManager',
    'viewAccAcademyExternal',
    'wiseAcademyEditor'
]
const listLocations = [
    { value: 'IE', label: 'IE' },
    { value: 'UK', label: 'UK' },
    { value: 'US', label: 'US' },
];
const listShiftWorkers = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];

const UsersDetails = (props) => {
    const classes = useStyles()
    const { listDepartments, listManagers, updateUser } = useContext(DataContext)

    const [editValues, setEditValues] = useState({
        accumulatedApprovedDays: props.userData.accumulatedApprovedDays,
        accumulatedRemainingDays: props.userData.accumulatedRemainingDays,
        accumulatedUnpaidLeaveTaken: props.userData.accumulatedUnpaidLeaveTaken,
        availableDays: props.userData.availableDays,
        department: props.userData.department,
        firstName: props.userData.firstName,
        holidayApprovingManager: props.userData.holidayApprovingManager,
        initials: props.userData.initials,
        lastName: props.userData.lastName,
        location: props.userData.location,
        // role: props.userData.role,
        roles: props.userData.roles,
        shiftWorker: props.userData.shiftWorker,
        // shiftWorker: props.userData.shiftWorker === 'true' ? true : false,
        userEmail: props.userData.userEmail,
        userId: props.userData.userId,
    })

    // //Snackbar
    // const [snackbar, setSnackbar] = useState({
    //     open: false,
    //     severity: 'info',
    //     message: '',
    // });

    const [admin, setAdmin] = useState(false)
    const [adminAM, setAdminAM] = useState(false)
    const [adminCS, setAdminCS] = useState(false)
    const [adminENG, setAdminENG] = useState(false)
    const [adminFacilities, setAdminFacilities] = useState(false)
    const [adminHR, setAdminHR] = useState(false)
    const [adminISandT, setAdminISandT] = useState(false)
    const [adminSalesOps, setAdminSalesOps] = useState(false)
    const [member, setMember] = useState(false)
    const [memberAM, setMemberAM] = useState(false)
    const [memberCS, setMemberCS] = useState(false)
    const [memberENG, setMemberENG] = useState(false)
    const [memberFacilities, setMemberFacilities] = useState(false)
    const [memberHR, setMemberHR] = useState(false)
    const [memberISandT, setMemberISandT] = useState(false)
    const [memberSalesOps, setMemberSalesOps] = useState(false)
    const [teamManager, setTeamManager] = useState(false)
    const [viewAcc, setViewAcc] = useState(false)
    const [partnerManager, setPartnerManager] = useState(false)
    const [viewAccAcademyExternal, setViewAccAcademyExternal] = useState(false)
    const [wiseAcademyEditor, setWiseAcademyEditor] = useState(false)

    function setCheckboxes() {
        editValues.roles.forEach(role => {
            settingCheckboxes(role, true)
        })
    }
    useEffect(() => {
        setCheckboxes()
        return () => { }
    }, [])

    const handleChange = (e) => {
        setEditValues({
            ...editValues,
            [e.target.name]: e.target.value
        })
    };
    const handleChangeCheckbox = (e) => {
        settingCheckboxes(e.target.name, e.target.checked)
    };

    function settingCheckboxes(checkboxName, isChecked) {
        switch (checkboxName) {
            case 'admin':
                setAdmin(isChecked);
                break;
            case 'adminAM':
                setAdminAM(isChecked);
                break;
            case 'adminCS':
                setAdminCS(isChecked);
                break;
            case 'adminENG':
                setAdminENG(isChecked);
                break;
            case 'adminFacilities':
                setAdminFacilities(isChecked);
                break;
            case 'adminHR':
                setAdminHR(isChecked);
                break;
            case 'adminISandT':
                setAdminISandT(isChecked);
                break;
            case 'adminSalesOps':
                setAdminSalesOps(isChecked);
                break;
            case 'member':
                setMember(isChecked);
                break;
            case 'memberAM':
                setMemberAM(isChecked);
                break;
            case 'memberCS':
                setMemberCS(isChecked);
                break;
            case 'memberENG':
                setMemberENG(isChecked);
                break;
            case 'memberFacilities':
                setMemberFacilities(isChecked);
                break;
            case 'memberHR':
                setMemberHR(isChecked);
                break;
            case 'memberISandT':
                setMemberISandT(isChecked);
                break;
            case 'memberSalesOps':
                setMemberSalesOps(isChecked);
                break;
            case 'teamManager':
                setTeamManager(isChecked);
                break;
            case 'viewAcc':
                setViewAcc(isChecked);
                break;
            case 'partnerManager':
                setPartnerManager(isChecked);
                break;
            case 'viewAccAcademyExternal':
                setViewAccAcademyExternal(isChecked);
                break;
            case 'wiseAcademyEditor':
                setWiseAcademyEditor(isChecked);
                break;

            default:
                break;
        }

    }
    const handleUpdate = () => {
        // create new roles array
        var newRoles = []
        if (member) { newRoles.push('member') }
        if (admin) { newRoles.push('admin') }
        if (adminAM) { newRoles.push('adminAM') }
        if (adminCS) { newRoles.push('adminCS') }
        if (adminENG) { newRoles.push('adminENG') }
        if (adminFacilities) { newRoles.push('adminFacilities') }
        if (adminHR) { newRoles.push('adminHR') }
        if (adminISandT) { newRoles.push('adminISandT') }
        if (adminSalesOps) { newRoles.push('adminSalesOps') }
        if (memberAM) { newRoles.push('memberAM') }
        if (memberCS) { newRoles.push('memberCS') }
        if (memberENG) { newRoles.push('memberENG') }
        if (memberFacilities) { newRoles.push('memberFacilities') }
        if (memberHR) { newRoles.push('memberHR') }
        if (memberISandT) { newRoles.push('memberISandT') }
        if (memberSalesOps) { newRoles.push('memberSalesOps') }
        if (teamManager) { newRoles.push('teamManager') }
        if (viewAcc) { newRoles.push('viewAcc') }
        if (partnerManager) { newRoles.push('partnerManager') }
        if (viewAccAcademyExternal) { newRoles.push('viewAccAcademyExternal') }
        if (wiseAcademyEditor) { newRoles.push('wiseAcademyEditor') }

        console.log(newRoles)
        var userToUpdate = editValues
        userToUpdate.roles = newRoles
        updateUser(userToUpdate)
            .then(suc => {
                //display succ and refresh table
                props.setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Successfully updated user details.',
                })
                props.refresh()
            })
            .catch(err => {
                //display err
                props.setSnackbar({
                    open: true,
                    severity: 'error',
                    message: err,
                })
            })
    }
    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <p className={classes.marginOne}>User Id: {editValues.userId}</p>
            <Grid container item
                direction="row"
                justifyContent="center"
                alignItems="center">
                <TextField name="firstName" label="Name" variant="outlined" value={editValues.firstName} onChange={handleChange} className={classes.marginOne} size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="lastName" label="Surname" variant="outlined" value={editValues.lastName} onChange={handleChange} className={classes.marginOne} size="small" InputLabelProps={{ shrink: true }} />
                <TextField select name="department" label="Department" variant="outlined" value={editValues.department} onChange={handleChange} className={classes.selectTextfield} size="small" InputLabelProps={{ shrink: true }}>
                    {listDepartments.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField select name="location" label="Location" variant="outlined" value={editValues.location} onChange={handleChange} className={classes.selectTextfield} size="small" InputLabelProps={{ shrink: true }}>
                    {listLocations.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid container item
                direction="row"
                justifyContent="center"
                alignItems="center">
                <TextField select name="holidayApprovingManager" label="Holiday Approving Manager" variant="outlined" value={editValues.holidayApprovingManager} onChange={handleChange} className={classes.selectTextfield} size="small" InputLabelProps={{ shrink: true }}>
                    {listManagers.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField select name="shiftWorker" label="Shift Worker" variant="outlined" value={editValues.shiftWorker} onChange={handleChange} className={classes.selectTextfield} size="small" InputLabelProps={{ shrink: true }}>
                    {listShiftWorkers.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField name="userEmail" label="Email or Username" variant="outlined" value={editValues.userEmail} onChange={handleChange} className={classes.marginOne} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="initials" label="Initials" variant="outlined" value={editValues.initials} onChange={handleChange} className={classes.marginOne} disabled size="small" InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid container item
                direction="row"
                justifyContent="center"
                alignItems="center">
                <TextField name="availableDays" label="Available Days" variant="outlined" type="number" value={editValues.availableDays} onChange={handleChange} className={classes.marginOne} size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="accumulatedUnpaidLeaveTaken" label="Accumulated Unpaid Leave Taken" variant="outlined" type="number" value={editValues.accumulatedUnpaidLeaveTaken} onChange={handleChange} className={classes.marginOne} size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="accumulatedRemainingDays" label="Accumulated Remaining Days" variant="outlined" type="number" value={editValues.accumulatedRemainingDays} onChange={handleChange} className={classes.marginOne} size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="accumulatedApprovedDays" label="Accumulated Approved Days" variant="outlined" type="number" value={editValues.accumulatedApprovedDays} onChange={handleChange} className={classes.marginOne} size="small" InputLabelProps={{ shrink: true }} />
            </Grid>
            <div className={classes.checkboxArea}>
                <p style={{ textAlign: 'center' }}>Available User Roles:</p>
                <Grid container item
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <FormControlLabel control={<Checkbox checked={admin} onChange={handleChangeCheckbox} name='admin' color='primary' />} label='admin' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={adminAM} onChange={handleChangeCheckbox} name='adminAM' color='primary' />} label='adminAM' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={adminCS} onChange={handleChangeCheckbox} name='adminCS' color='primary' />} label='adminCS' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={adminENG} onChange={handleChangeCheckbox} name='adminENG' color='primary' />} label='adminENG' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={adminFacilities} onChange={handleChangeCheckbox} name='adminFacilities' color='primary' />} label='adminFacilities' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={adminHR} onChange={handleChangeCheckbox} name='adminHR' color='primary' />} label='adminHR' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={adminISandT} onChange={handleChangeCheckbox} name='adminISandT' color='primary' />} label='adminISandT' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={adminSalesOps} onChange={handleChangeCheckbox} name='adminSalesOps' color='primary' />} label='adminSalesOps' className={classes.checkboxMargin} />
                </Grid>
                <Grid container item
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <FormControlLabel control={<Checkbox checked={member} onChange={handleChangeCheckbox} name='member' color='primary' />} label='member' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={memberAM} onChange={handleChangeCheckbox} name='memberAM' color='primary' />} label='memberAM' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={memberCS} onChange={handleChangeCheckbox} name='memberCS' color='primary' />} label='memberCS' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={memberENG} onChange={handleChangeCheckbox} name='memberENG' color='primary' />} label='memberENG' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={memberFacilities} onChange={handleChangeCheckbox} name='memberFacilities' color='primary' />} label='memberFacilities' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={memberHR} onChange={handleChangeCheckbox} name='memberHR' color='primary' />} label='memberHR' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={memberISandT} onChange={handleChangeCheckbox} name='memberISandT' color='primary' />} label='memberISandT' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={memberSalesOps} onChange={handleChangeCheckbox} name='memberSalesOps' color='primary' />} label='memberSalesOps' className={classes.checkboxMargin} />
                </Grid>
                <Grid container item
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <FormControlLabel control={<Checkbox checked={teamManager} onChange={handleChangeCheckbox} name='teamManager' color='primary' />} label='teamManager' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={viewAcc} onChange={handleChangeCheckbox} name='viewAcc' color='primary' />} label='viewAcc' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={partnerManager} onChange={handleChangeCheckbox} name='partnerManager' color='primary' />} label='partnerManager' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={viewAccAcademyExternal} onChange={handleChangeCheckbox} name='viewAccAcademyExternal' color='primary' />} label='viewAccAcademyExternal' className={classes.checkboxMargin} />
                    <FormControlLabel control={<Checkbox checked={wiseAcademyEditor} onChange={handleChangeCheckbox} name='wiseAcademyEditor' color='primary' />} label='wiseAcademyEditor' className={classes.checkboxMargin} />
                </Grid>
            </div>
            <Button
                // type="submit"
                className={classes.buttonRight}
                variant="contained"
                color="primary"
                onClick={handleUpdate}
            >
                Update User Details
            </Button>

        </Grid>
    )
}
export default UsersDetails