import React, { useContext, useState } from 'react'
import { Button, Card, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DataContext } from '../../../contexts/DataContext';
import { db } from '../../../config/fbConfig';
import MaterialTable from 'material-table';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    dropzone: {
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '30px',
        textAlign: 'center',
        border: '1px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        padding: '20px',
    },
    input: {
        display: 'none',
    },
    buttonMargin: {
        margin: '10px',
    },
    marginBottom: {
        marginBottom: '15px',
    },
    dropProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    searchProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    searchDataTable: {
        display: 'none',
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    spanRed: {
        color: 'red'
    },

}));

class PunchcardEvent {
    ClockIn
    ClockOut
    Hours
    constructor() {

    }
}
class EmployeePunchcard {
    FullName
    BadgeId
    Department
    Shift
    Event
    TotalWorkTime
    TotalHolidays
    SickTimeTaken
    SickTimeOwing
    VacationTimeTaken
    VacationTimeOwing
    year
    week
    month
    constructor() {
        // this.FullName=FullName
        this.Event = []
        // this.TotalWorkTime=TotalWorkTime
        // this.TotalHolidays=TotalHolidays
        // this.SickTimeTaken=SickTimeTaken
        // this.SickTimeOwing=SickTimeOwing
        // this.VacationTimeTaken=VacationTimeTaken
        // this.VacationTimeOwing=VacationTimeOwing
        // this.year=year
        // this.week=week
        // this.month=month
    }
}
export default function PunchcardReports() {
    const classes = useStyles();
    const { uploadPunchcardsData } = useContext(DataContext);

    const [punchcardReports, setPunchcardReports] = useState([]);

    const [searchDateWeeklyData, setSearchDateWeeklyData] = useState({
        week: moment().isoWeek(),
        searchDate: new Date(),
        columns: [
            { title: 'Badge ID', field: 'BadgeId' },
            { title: 'Full Name', field: 'FullName' },
            { title: 'Shift', field: 'Shift' },
            { title: 'Department', field: 'Department' },
            {
                title: 'ClockIn/ClockOut', field: 'Event', render: rowData =>
                    <div>
                        {rowData.Event.map(evt => (
                            <section>
                                <p style={{ whiteSpace: 'pre' }}>{moment(evt.ClockIn.toDate()).format('DD MMM YYYY HH:mm:ss') + '\n'}
                                    {moment(evt.ClockOut.toDate()).format('DD MMM YYYY HH:mm:ss') + '\ndaily hours:' + evt.Hours}</p>
                            </section>
                        ))}
                    </div>
            },
            { title: 'Total Work Time', field: 'TotalWorkTime' },
        ],
        data: []
    });

    // Dialog
    const [dialog, setDialog] = useState({
        open: false,
        title: '',
    });
    const handleCloseAndCancelDialog = (e) => {
        setPunchcardReports([]);
        setDialog({
            open: false,
            title: '',
        });

    };

    // Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleSearchDateData = (date) => {
        var weekNo = moment(date).isoWeek();
        var yearNo = moment(date).year();
        var dataResults = [];
        var searchDataTable = document.getElementById('searchDataTable');
        var searchProgress = document.getElementById('searchProgress');
        searchProgress.style.visibility = 'visible';

        db.collection('punchcardsReports')
            .where('week', '==', weekNo)
            .where('year', '==', yearNo)
            .orderBy('FullName')
            .get()
            .then(snap => {
                if (snap.empty) {
                    setSearchDateWeeklyData({
                        ...searchDateWeeklyData,
                        week: weekNo,
                        searchDate: date,
                        data: dataResults
                    })
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'There was no results for week ' + moment.utc(date).isoWeek(),
                    })
                    searchProgress.style.visibility = 'hidden';
                    searchDataTable.style.display = 'none';
                    return
                }
                snap.forEach(doc => {
                    dataResults.push(doc.data())
                })
                setSearchDateWeeklyData({
                    ...searchDateWeeklyData,
                    week: weekNo,
                    searchDate: date,
                    data: dataResults
                })
                searchDataTable.style.display = 'block';
                searchProgress.style.visibility = 'hidden';
            })
    }

    function fileTypes(fileName) {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }
    const dragOver = (e) => {
        e.preventDefault();
    }
    const dragEnter = (e) => {
        e.preventDefault();
    }
    const dragLeave = (e) => {
        e.preventDefault();
    }
    const readCSVFile = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsText(file);

        reader.onload = () => resolve((reader.result));

        reader.onerror = error => reject(error);
    });

    function csvToArray(csv, delimiter = ",", omitFirstRow = false) {
        // console.log(csv.indexOf('\n'));
        return csv.slice(omitFirstRow ? csv.indexOf('\n') + 1 : 0)
            .split("\n")
            .map((element) => element.replace(/(\r\n|\n|\r)/gm, '').split(delimiter));
    }

    const fileDrop = (e) => {
        e.preventDefault();
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        const files = e.dataTransfer.files;

        if (files.length > 1) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Only one file allowed.',
            })
            dropProgress.style.visibility = 'hidden';
        }
        else {
            [...files].forEach(file => {
                var fileType = String(fileTypes(file.name));
                const maxSize = 25000000
                var header = "Punchcard Report,,,"
                // console.log(header)
                if (fileType.toLowerCase() === 'csv') {
                    // console.log('jest csv, zaczynamy obliczanie')
                    readCSVFile(file)
                        .then(result => {
                            // console.log(result)
                            var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                            var headerThirdRow = (result.split('\n')[2]).replace(/(\r\n|\n|\r)/gm, '')
                            // console.log(headerThirdRow)
                            var monday = (headerThirdRow.split(':,')[1]).split(' to ')[0]
                            var sunday = ((headerThirdRow.split(':,')[1]).split(' to ')[1]).slice(0, -2)
                            var weekNo = moment(monday).isoWeek()
                            var year = moment(monday).year()
                            // console.log(weekNo, year)
                            // console.log(monday, sunday)
                            if (header === uploadedHeader) {
                                if (moment(sunday).diff(moment(monday), 'days') + 1 === 7) {
                                    // console.log('seven days', moment(sunday).diff(moment(monday), 'days') + 1)

                                    let csvRows = csvToArray(result);
                                    var reportsCollection = [];
                                    var punchcard = null // change to null
                                    var punchcardEvent = null // change to null
                                    var counter = 0
                                    csvRows.forEach(row => {
                                        counter++;
                                        if (row[0].includes('Employee:')) {
                                            if (punchcard) {
                                                if (punchcardEvent) {
                                                    punchcard.Event.push(punchcardEvent)
                                                }
                                                reportsCollection.push(punchcard)
                                                punchcard = null
                                                punchcardEvent = null
                                            }
                                            var fName = (row[0].split(': '))[1].trim()
                                            // console.log(fName)
                                            punchcard = new EmployeePunchcard()
                                            punchcard.FullName = fName
                                            punchcard.week = weekNo
                                            punchcard.year = year
                                        }
                                        if (row[0].includes('Badge/ID:')) {

                                            var badgeId = (row[0].split(': '))[1].trim()
                                            // console.log(badgeId)
                                            punchcard.BadgeId = badgeId
                                        }
                                        if (row[0].includes('Department:')) {

                                            var dept = (row[0].split(': '))[1].trim()
                                            // console.log(badgeId)
                                            punchcard.Department = dept
                                        }
                                        if (row[0].includes('Shift:')) {

                                            var shift = (row[0].split(': '))[1].trim()
                                            // console.log(badgeId)
                                            punchcard.Shift = shift
                                        }
                                        if (moment(row[0]).isValid() && moment(row[0]).isBetween('2020-01-01', '2100-01-01')) {
                                            // console.log(moment(row[0]).format('DD MMM YYYY HH:mm:ss'))
                                            if (punchcardEvent) {
                                                punchcard.Event.push(punchcardEvent)
                                                punchcardEvent = null
                                            }
                                            punchcardEvent = new PunchcardEvent()
                                            punchcardEvent.ClockIn = new Date(moment(row[0]).format('DD MMM YYYY HH:mm:ss'))
                                            punchcardEvent.ClockOut = new Date(moment(row[1]).format('DD MMM YYYY HH:mm:ss'))
                                            punchcardEvent.Hours = parseFloat(row[2].trim())
                                            // console.log(punchcardEvent)
                                        }

                                        if (row[0].includes('Total Work Time:')) {
                                            punchcard.TotalWorkTime = row[1].trim()
                                        }
                                        if (row[0].includes('Total Holidays:')) {
                                            punchcard.TotalHolidays = row[1].trim()
                                        }
                                        if (row[0].includes('Sick Time Taken:')) {
                                            punchcard.SickTimeTaken = (row[1].trim()).split(' ')[0]
                                        }
                                        if (row[0].includes('Sick Time Owing:')) {
                                            punchcard.SickTimeOwing = (row[1].trim()).split(' ')[0]
                                        }
                                        if (row[0].includes('Vacation Time Taken:')) {
                                            punchcard.VacationTimeTaken = (row[1].trim()).split(' ')[0]
                                        }
                                        if (row[0].includes('Vacation Time Owing:')) {
                                            punchcard.VacationTimeOwing = (row[1].trim()).split(' ')[0]
                                        }
                                        // console.log(csvRows.length, counter)
                                        if (csvRows.length === counter) {
                                            reportsCollection.push(punchcard)
                                        }
                                    })
                                    // console.log(reportsCollection);
                                    setPunchcardReports(reportsCollection);
                                    setDialog({
                                        open: true,
                                        title: 'Found ' + reportsCollection.length + ' users data to upload, would you like to proceed?'
                                    })
                                    dropProgress.style.visibility = 'hidden';
                                }
                                else {
                                    // console.log('not seven days', moment(sunday).diff(moment(monday), 'days')+1)
                                    setSnackbar({
                                        open: true,
                                        severity: 'error',
                                        message: 'Please upload data for 7 days from Monday to Sunday.',
                                    })
                                    dropProgress.style.visibility = 'hidden';
                                }
                            }
                            else {
                                setSnackbar({
                                    open: true,
                                    severity: 'error',
                                    message: 'CSV file does not match the template.',
                                })
                                dropProgress.style.visibility = 'hidden';
                            }
                        })
                }
                else {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: 'Please make sure that your are uploading a CSV file.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
            })
        }
    }

    const fileSelect = (e) => {
        var { target: { files } } = e;
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';

        [...files].forEach(file => {
            var fileType = String(fileTypes(file.name));
            const maxSize = 25000000
            var header = "Punchcard Report,,,"
            // console.log(header)
            if (fileType.toLowerCase() === 'csv') {
                // console.log('jest csv, zaczynamy obliczanie')
                readCSVFile(file)
                    .then(result => {
                        // console.log(result)
                        var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                        var headerThirdRow = (result.split('\n')[2]).replace(/(\r\n|\n|\r)/gm, '')
                        // console.log(headerThirdRow)
                        var monday = (headerThirdRow.split(':,')[1]).split(' to ')[0]
                        var sunday = ((headerThirdRow.split(':,')[1]).split(' to ')[1]).slice(0, -2)
                        var weekNo = moment(monday).isoWeek()
                        var year = moment(monday).year()
                        // console.log(weekNo, year)
                        // console.log(monday, sunday)
                        if (header === uploadedHeader) {
                            if (moment(sunday).diff(moment(monday), 'days') + 1 === 7) {
                                // console.log('seven days', moment(sunday).diff(moment(monday), 'days') + 1)

                                let csvRows = csvToArray(result);
                                var reportsCollection = [];
                                var punchcard = null // change to null
                                var punchcardEvent = null // change to null
                                var counter = 0
                                csvRows.forEach(row => {
                                    counter++;
                                    if (row[0].includes('Employee:')) {
                                        if (punchcard) {
                                            if (punchcardEvent) {
                                                punchcard.Event.push(punchcardEvent)
                                            }
                                            reportsCollection.push(punchcard)
                                            punchcard = null
                                            punchcardEvent = null
                                        }
                                        var fName = (row[0].split(': '))[1].trim()
                                        // console.log(fName)
                                        punchcard = new EmployeePunchcard()
                                        punchcard.FullName = fName
                                        punchcard.week = weekNo
                                        punchcard.year = year
                                    }
                                    if (row[0].includes('Badge/ID:')) {

                                        var badgeId = (row[0].split(': '))[1].trim()
                                        punchcard.BadgeId = badgeId
                                    }
                                    if (row[0].includes('Department:')) {

                                        var dept = (row[0].split(': '))[1].trim()
                                        punchcard.Department = dept
                                    }
                                    if (row[0].includes('Shift:')) {

                                        var shift = (row[0].split(': '))[1].trim()
                                        punchcard.Shift = shift
                                    }
                                    if (moment(row[0]).isValid() && moment(row[0]).isBetween('2020-01-01', '2100-01-01')) {
                                        // console.log(moment(row[0]).format('DD MMM YYYY HH:mm:ss'))
                                        if (punchcardEvent) {
                                            punchcard.Event.push(punchcardEvent)
                                            punchcardEvent = null
                                        }
                                        punchcardEvent = new PunchcardEvent()
                                        punchcardEvent.ClockIn = new Date(moment(row[0]).format('DD MMM YYYY HH:mm:ss'))
                                        punchcardEvent.ClockOut = new Date(moment(row[1]).format('DD MMM YYYY HH:mm:ss'))
                                        punchcardEvent.Hours = parseFloat(row[2].trim())
                                        // console.log(punchcardEvent)
                                    }

                                    if (row[0].includes('Total Work Time:')) {
                                        punchcard.TotalWorkTime = row[1].trim()
                                    }
                                    if (row[0].includes('Total Holidays:')) {
                                        punchcard.TotalHolidays = row[1].trim()
                                    }
                                    if (row[0].includes('Sick Time Taken:')) {
                                        punchcard.SickTimeTaken = (row[1].trim()).split(' ')[0]
                                    }
                                    if (row[0].includes('Sick Time Owing:')) {
                                        punchcard.SickTimeOwing = (row[1].trim()).split(' ')[0]
                                    }
                                    if (row[0].includes('Vacation Time Taken:')) {
                                        punchcard.VacationTimeTaken = (row[1].trim()).split(' ')[0]
                                    }
                                    if (row[0].includes('Vacation Time Owing:')) {
                                        punchcard.VacationTimeOwing = (row[1].trim()).split(' ')[0]
                                    }
                                    // console.log(csvRows.length, counter)
                                    if (csvRows.length === counter) {
                                        reportsCollection.push(punchcard)
                                    }
                                })
                                // console.log(reportsCollection);
                                setPunchcardReports(reportsCollection);
                                setDialog({
                                    open: true,
                                    title: 'Found ' + reportsCollection.length + ' users data to upload, would you like to proceed?'
                                })
                                dropProgress.style.visibility = 'hidden';
                            }
                            else {
                                // console.log('not seven days', moment(sunday).diff(moment(monday), 'days')+1)
                                setSnackbar({
                                    open: true,
                                    severity: 'error',
                                    message: 'Please upload data for 7 days from Monday to Sunday.',
                                })
                                dropProgress.style.visibility = 'hidden';
                            }
                        }
                        else {
                            setSnackbar({
                                open: true,
                                severity: 'error',
                                message: 'CSV file does not match the template.',
                            })
                            dropProgress.style.visibility = 'hidden';
                        }
                    })
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'Please make sure that your are uploading a CSV file.',
                })
                dropProgress.style.visibility = 'hidden';
            }
        })
    }

    const handleUploadDialog = (e) => {
        setDialog({
            open: false,
            title: '',
        });
        // upload data to db and snackbar success
        uploadPunchcardsData(punchcardReports)
            .then(success => {
                console.log('added ' + punchcardReports.length + ' new rows to punchcards data table')
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                });
            })
            .catch(error => {
                console.log(error)
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                });
            })
    };

    return (
        <Card>

            <h3 className={classes.center}>Punchcard Reports</h3>

            <div className={classes.dropzone}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}>
                <LinearProgress id='dropProgress' className={classes.dropProgress} />
                <GetAppIcon />
                <p>Drag & Drop CSV file here</p>
                <h4>Or</h4>
                <input type="file" accept=".csv" id="addFiles" className={classes.input} onChange={fileSelect} />
                <label htmlFor="addFiles">
                    <Button
                        className={classes.marginBottom}
                        variant="contained"
                        color="default"
                        component="span"
                        startIcon={<AttachFileIcon />}>
                        Select CSV file
                    </Button>
                </label>
                {/* <button onClick={handleAdding}>Add Metrics Data</button> */}
            </div>

            <div id='dropResult' style={{ visibility: 'hidden' }} ><p className={classes.center}>Results</p></div>
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <DatePicker
                            margin="normal"
                            id="searchDateUpload"
                            disableFuture
                            // label="From Date:"
                            name="searchDate"
                            format="dd MMM yyyy"
                            // error // makes text red
                            helperText="Please select Date to display data"
                            // required
                            className={classes.datePickerCenter}
                            value={searchDateWeeklyData.searchDate}
                            onChange={handleSearchDateData}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
                <LinearProgress id='searchProgress' className={classes.searchProgress} />
                <div className={classes.searchDataTable} id='searchDataTable'>
                    <MaterialTable
                        title={"Data Results for week " + moment(searchDateWeeklyData.searchDate).isoWeek() + ", from Monday " + moment(searchDateWeeklyData.searchDate).startOf('isoWeek').format('DD MMM YYYY') + " to Sunday " + moment(searchDateWeeklyData.searchDate).endOf('isoWeek').format('DD MMM YYYY')}
                        columns={searchDateWeeklyData.columns}
                        data={searchDateWeeklyData.data}
                        options={{
                            search: false,
                            filtering: true,
                            // fixedColumns: {
                            //     left: 1,
                            //     // right: 1
                            // },
                            // detailPanelType: "single", actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50]
                            detailPanelType: "single", actionsColumnIndex: -1, pageSize: 10, pageSizeOptions: [5, 10, 50, searchDateWeeklyData.data.length === 0 ? 100 : { value: searchDateWeeklyData.data.length, label: 'All' }]
                        }}//, { value: ticketsIT.data.length, label: 'All' }] }}
                    />
                </div>
            </div>

            <Dialog
                open={dialog.open}
                onClose={handleCloseAndCancelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>

                <DialogActions>
                    <Button variant="contained" onClick={handleCloseAndCancelDialog} color="default" className={classes.buttonMargin}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleUploadDialog} color="primary" className={classes.buttonMargin}>
                        Yes, proceed to upload
                    </Button>
                </DialogActions>
            </Dialog>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </Card >
    )
}