import React, { useContext } from 'react';
import Loading from '../Loading';
import Footer from '../../Footer'
import { AuthContext } from '../../../contexts/AuthContext';
import { makeStyles, CssBaseline } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { HolidayForm } from './HolidayForm';
import { HolidayRequestApplicant } from './HolidayRequestApplicant';
import { HolidayRequestManager } from './HolidayRequestManager';
import { HolidayRequestAll } from './HolidayRequestAll';
import SideMenuV2 from '../SideMenus/SideMenuV2';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
}));

const HolidayRequest = (props) => {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline >
                        <SideMenuV2 selectedIndex={7} />
                        <div className={classes.main}>
                            {/* <h1>Holiday Request Console</h1> */}

                            <HolidayForm />
                            <br />
                            <br />
                            <HolidayRequestApplicant />
                            <br />
                            <br />
                            {roles.includes('adminIS&T') || roles.includes('adminHR') || roles.includes('teamManager') || roles.includes('admin') ? <HolidayRequestManager /> : null}
                            <br />
                            <br />
                            {roles.includes('adminHR') || roles.includes('admin') ? <HolidayRequestAll /> : null}

                        </div>
                    </CssBaseline>
                    <Footer />

                </div>
            );
        }
    }
}
export default HolidayRequest