import { Card, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Zoom } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React, { useContext } from 'react'
import { AuthContext } from '../../../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
    largeAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor: '#5E89B4',
    },
    userCard: {
        padding: '40px',
        textAlign: 'center'
    },
    pMargin: {
        marginTop: '8px',
        marginBottom: '8px',
        marginLeft: '15px',
        marginRight: '10px',
        alignSelf: 'center'
    },
    pFontBigger: {
        marginTop: '8px',
        marginBottom: '8px',
        fontSize: '20px'
    }
}));

export default function Profile() {
    const classes = useStyles();
    const { displayName, uid, email, initials, department, availableDays, username } = useContext(AuthContext);

    return (

        // <Grid item xs={12} sm={8} md={4} >
        <Zoom in={true} style={{ transitionDelay: true ? '1000ms' : '0ms' }}>
            <Card className={classes.userCard} elevation={4}>
                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item >
                        <Avatar className={classes.largeAvatar}>{initials}</Avatar>
                    </Grid>
                    <br />
                    <Grid item >
                        <Typography variant='h4'>{displayName}</Typography>
                        <Typography variant='caption'>{uid}</Typography>
                    </Grid>
                </Grid>

                <br /><br />
                <Grid container direction="row" spacing={1}>
                    <p className={classes.pMargin}>Username:</p><p className={classes.pFontBigger}>{username}</p>
                </Grid>
                <Grid container direction="row" spacing={1}>
                    <p className={classes.pMargin}>Department:</p><p className={classes.pFontBigger}>{department}</p>
                </Grid>
                {/* <Grid container direction="row" spacing={1}>
                    <p className={classes.pMargin}>Holidays:</p><p className={classes.pFontBigger}>{availableDays} day/days</p>
                </Grid> */}
            </Card>
        </Zoom>
        // </Grid>

    )
}