import React from 'react';
import { makeStyles } from '@material-ui/core';
import EhsWeekCanteenVideo from '../../../../images/EhsWeekCanteenVideohalfSize.mp4'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '90%',
        borderRadius: 10,
    },
}));

export default function EHSWeek2023(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header>
                <h2 className={classes.textCentered}>Environmental Health & Safety Week 2023</h2>
            </header>

            <br />
            <div className={classes.textCentered}>
                <video className={classes.media} src={EhsWeekCanteenVideo}
                    type="video/mp4"
                    // poster={posterEHSWeek}
                    loop
                    controls
                    controlsList="nodownload">
                    Your browser does not support HTML video.
                </video>
            </div>

            <p>Regards,<br /><br />

                Health & Safety Team<br />
                Wisetek™<br />
            </p>
            <br />

        </div>
    )
}