import React, { useContext, useEffect, useState } from 'react'
import { Chip, Grid, CssBaseline, makeStyles, Typography, LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import ChartistGraph from 'react-chartist'
import ctPointLabels from 'chartist-plugin-pointlabels'
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import { DataContext } from '../../../../contexts/DataContext';
import { db } from '../../../../config/fbConfig';
import Chartist from 'chartist';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
    },
    searchProgress: {
        // visibility: 'hidden',
        marginBottom: '15px'
    },
    graphHeader: {
        marginTop: '100px',
        textAlign: 'center'
    },
}));

const MetricsCustomerSLAforIEandUK = (props) => {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);
    const { addMetricsCustomerSLAIEandUK, updateMetricsCustomerSLAIEandUK, deleteMetricsCustomerSLAIEandUK } = useContext(DataContext);

    const year = moment().get('year');
    const week = moment().get('isoWeek');

    const [dealsInSales, setDealsInSales] = useState({
        // //Open	Rec Comp	Req BA	BA Comp	Req TA	TA Comp	Req TO	Turnout Comp
        // //inputs
        // itadCorkOpen:0,
        // itadCorkRecComp:0,
        // itadCorkReqBA:0,
        // itadCorkBAComp:0,
        // itadCorkReqTA:0,
        // itadCorkTAComp:0,
        // itadCorkReqTO:0,
        // itadCorkTOComp:0,
        // itadMKOpen:0,
        // itadMKRecComp:0,
        // itadMKReqBA:0,
        // itadMKBAComp:0,
        // itadMKReqTA:0,
        // itadMKTAComp:0,
        // itadMKReqTO:0,
        // itadMKTOComp:0,
        // //calcluated percentages
        // itadCorkRec:0,
        // itadCorkBA:0,
        // itadCorkTA:0,
        // itadCorkTO:0,
        // itadMKRec:0,
        // itadMKBA:0,
        // itadMKTA:0,
        // itadMKTO:0,
        columns: [
            { title: 'Year', field: 'Year', type: 'numeric', filtering: false, initialEditValue: year },
            { title: 'Week', field: 'Week', type: 'numeric', filtering: false, initialEditValue: week },
            //input CK
            { title: 'Open', field: 'itadCorkOpen', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Rec Comp', field: 'itadCorkRecComp', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Req BA', field: 'itadCorkReqBA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'BA Comp', field: 'itadCorkBAComp', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Req TA', field: 'itadCorkReqTA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'TA Comp', field: 'itadCorkTAComp', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Req Turnout', field: 'itadCorkReqTO', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Turnout Comp', field: 'itadCorkTOComp', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            //calculations CK
            { title: 'Receiving', field: 'itadCorkRecPercent', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Basic Audit', field: 'itadCorkBAPercent', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Tech Audit', field: 'itadCorkTAPercent', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Turnout Report', field: 'itadCorkTOPercent', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            //input MK
            { title: 'Open', field: 'itadMKOpen', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Rec Comp', field: 'itadMKRecComp', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Req BA', field: 'itadMKReqBA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'BA Comp', field: 'itadMKBAComp', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Req TA', field: 'itadMKReqTA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'TA Comp', field: 'itadMKTAComp', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Req Turnout', field: 'itadMKReqTO', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Turnout Comp', field: 'itadMKTOComp', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            //calculations MK
            { title: 'Receiving', field: 'itadMKRecPercent', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Basic Audit', field: 'itadMKBAPercent', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Tech Audit', field: 'itadMKTAPercent', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Turnout Report', field: 'itadMKTOPercent', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
        ],
        data: []
    });
    const [itadCorkGraph, setItadCorkGraph] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    });
    const [itadMKGraph, setItadMKGraph] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    });

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleGraphChangeClick = (e) => {

        var optionId = e.target.id

        switch (optionId) {
            case 'reCK':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'baCK':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'taCK':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'toCK':
                var graph = document.getElementsByClassName('ct-series-d');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'reMK':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'baMK':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'taMK':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'toMK':
                var graph = document.getElementsByClassName('ct-series-d');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;

            default:
                break;
        }
    }

    function getMaxFrom2dAarray(a) {
        return Math.max(...a.map(e => Array.isArray(e) ? getMaxFrom2dAarray(e) : e));
    }
    function getMinFrom2dAarray(a) {
        return Math.min(...a.map(e => Array.isArray(e) ? getMinFrom2dAarray(e) : e));
    }

    const weekNumbers = [
        moment().day(-77).isoWeek(),
        moment().day(-70).isoWeek(),
        moment().day(-63).isoWeek(),
        moment().day(-56).isoWeek(),
        moment().day(-49).isoWeek(),
        moment().day(-42).isoWeek(),
        moment().day(-35).isoWeek(),
        moment().day(-28).isoWeek(),
        moment().day(-21).isoWeek(),
        moment().day(-14).isoWeek(),
        moment().day(-7).isoWeek(),
        moment().day(0).isoWeek(),
        moment().day(7).isoWeek() // current isoWeek
    ]

    function getCalculatedLabels() {
        return [
            'Week ' + weekNumbers[0],
            'Week ' + weekNumbers[1],
            'Week ' + weekNumbers[2],
            'Week ' + weekNumbers[3],
            'Week ' + weekNumbers[4],
            'Week ' + weekNumbers[5],
            'Week ' + weekNumbers[6],
            'Week ' + weekNumbers[7],
            'Week ' + weekNumbers[8],
            'Week ' + weekNumbers[9],
            'Week ' + weekNumbers[10],
            'Week ' + weekNumbers[11],
            'Week ' + weekNumbers[12]
        ]
    }

    function calcWeeklyVars(data) {
        // console.log(data)
        var dealsInSalesData = [];
        var percentGraphCK = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //Receiving
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //Basic Audit
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],  //Tech Audit
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //Turnout Report
        ]
        var percentGraphMK = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //Receiving
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //Basic Audit
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],  //Tech Audit
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //Turnout Report
        ]


        data.forEach(doc => {
            // console.log(doc.id)
            var row = doc.data()
            row.id = doc.id
            row.itadCorkRecPercent = doc.data().itadCorkRec + '%'
            row.itadCorkBAPercent = doc.data().itadCorkBA + '%'
            row.itadCorkTAPercent = doc.data().itadCorkTA + '%'
            row.itadCorkTOPercent = doc.data().itadCorkTO + '%'
            row.itadMKRecPercent = doc.data().itadMKRec + '%'
            row.itadMKBAPercent = doc.data().itadMKBA + '%'
            row.itadMKTAPercent = doc.data().itadMKTA + '%'
            row.itadMKTOPercent = doc.data().itadMKTO + '%'

            dealsInSalesData.push(row)
            var positionWeek = weekNumbers.findIndex(week => week === doc.data().Week)
            // console.log(positionWeek)
            if (positionWeek >= 0) {

                percentGraphCK[0][positionWeek] = doc.data().itadCorkRec
                percentGraphCK[1][positionWeek] = doc.data().itadCorkBA
                percentGraphCK[2][positionWeek] = doc.data().itadCorkTA
                percentGraphCK[3][positionWeek] = doc.data().itadCorkTO
                percentGraphMK[0][positionWeek] = doc.data().itadMKRec
                percentGraphMK[1][positionWeek] = doc.data().itadMKBA
                percentGraphMK[2][positionWeek] = doc.data().itadMKTA
                percentGraphMK[3][positionWeek] = doc.data().itadMKTO
            }
        })

        var averageIreGraphCalcHigh = getMaxFrom2dAarray(percentGraphCK) + 5
        // var averageIreGraphCalcLow = getMinFrom2dAarray(percentGraphCK) - 5
        var averageUSGraphCalcHigh = getMaxFrom2dAarray(percentGraphMK) + 5
        // var averageUSGraphCalcLow = getMinFrom2dAarray(percentGraphMK) - 5

        setDealsInSales({
            ...dealsInSales,
            data: dealsInSalesData
        })
        setItadCorkGraph({
            ...itadCorkGraph,
            data: {
                labels: getCalculatedLabels(),
                series: percentGraphCK
            },
            options: {
                high: averageIreGraphCalcHigh,
                low: -5
            }
        })
        setItadMKGraph({
            ...itadMKGraph,
            data: {
                labels: getCalculatedLabels(),
                series: percentGraphMK
            },
            options: {
                high: averageUSGraphCalcHigh,
                low: -5
            }
        })
        var searchProgress = document.getElementById('searchGraphProgress');
        searchProgress.style.visibility = 'hidden';
    }

    const getGraphsData = () => {
        // searchProgress.style.visibility = 'visible';

        db.collection('metricsIEandUK')
            .where("Year", "<=", year)
            .orderBy("Year", "desc")
            .orderBy("Week", "desc")
            .limit(13)
            .get()
            .then(snapshot => {
                if (snapshot.empty) {
                    var searchProgress = document.getElementById('searchGraphProgress');
                    searchProgress.style.visibility = 'hidden';
                }
                else {
                    calcWeeklyVars(snapshot)
                }
            })
            .catch(function (error) {
                console.log("Error getting metrics SLA for IE & UK data: ", error);
            });
    }

    useEffect(() => {
        getGraphsData()
        return () => { }
    }, [])

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={35} openMetricsColapse={true} openMetricsCustomerSLAColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Metrics - IE & UK SLA</h1> */}
                            <LinearProgress id='searchGraphProgress' className={classes.searchProgress} />
                            {/* <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <img className={classes.media} src={underConstruction} alt="Under Construction" />
                            </Grid> */}

                            {roles.includes('admin') ?
                                <MaterialTable
                                    title={"Open Deals - Volumes & Ageing"}
                                    columns={dealsInSales.columns}
                                    data={dealsInSales.data}
                                    // detailPanel={rowData => {
                                    //     // console.log(rowData)
                                    //     return (
                                    //         <TicketHRDetails rowData={rowData} />
                                    //     )
                                    // }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="ITAD Cork" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD Milton Keynes" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15],
                                        headerStyle: {
                                            textAlign: 'center'
                                        },
                                        cellStyle: {
                                            textAlign: 'center'
                                        }
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    addMetricsCustomerSLAIEandUK(newData);
                                                    getGraphsData()
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    if (oldData) {
                                                        setDealsInSales(prevState => {
                                                            const data = [...prevState.data];
                                                            // newData.dateCreated = moment(newData.dateCreated).format('DD MMM YYYY')
                                                            data[data.indexOf(oldData)] = newData;
                                                            console.log('Updated Deals In Sales row in DB')
                                                            // Update data
                                                            updateMetricsCustomerSLAIEandUK(newData)
                                                                .then(success => {
                                                                    //display success
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'success',
                                                                        message: success,
                                                                    });
                                                                    resolve();
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                    //display error
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'error',
                                                                        message: error,
                                                                    });
                                                                })
                                                            getGraphsData();
                                                            return { ...prevState, newData };
                                                        })
                                                    }
                                                }, 600)
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    console.log(oldData.id)
                                                    deleteMetricsCustomerSLAIEandUK(oldData.id)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    getGraphsData();
                                                    // setDealsInSales(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.splice(data.indexOf(oldData), 1);
                                                    //     return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                    }}
                                />
                                :
                                <MaterialTable
                                    title={"Open Deals - Volumes & Ageing"}
                                    columns={dealsInSales.columns}
                                    data={dealsInSales.data}
                                    // detailPanel={rowData => {
                                    //     // console.log(rowData)
                                    //     return (
                                    //         <TicketHRDetails rowData={rowData} />
                                    //     )
                                    // }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="ITAD Cork" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD Milton Keynes" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15],
                                        headerStyle: {
                                            textAlign: 'center'
                                        },
                                        cellStyle: {
                                            textAlign: 'center'
                                        }
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    addMetricsCustomerSLAIEandUK(newData);
                                                    getGraphsData()
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        // onRowUpdate: (newData, oldData) =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             if (oldData) {
                                        //                 setTicketsFacilities(prevState => {
                                        //                     const data = [...prevState.data];
                                        //                     data[data.indexOf(oldData)] = newData;
                                        //                     if (newData.Status == 'Completed' || newData.Status == 'Closed - Not Needed') {
                                        //                         var date = new Date();
                                        //                         var today = date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();
                                        //                         newData.ClosedDate = today;
                                        //                     }
                                        //                     console.log('Updated issue error ticket in DB')
                                        //                     // Update data
                                        //                     // if closed or not needed then add closing date
                                        //                     db.collection("issueErrorTickets").doc(newData.ticketId).update({
                                        //                         Status: newData.Status,
                                        //                         AssignedIT: newData.AssignedIT,
                                        //                         RootCauseCategory: newData.RootCauseCategory,
                                        //                         FixType: newData.FixType,
                                        //                         FailureCategory: newData.FailureCategory,
                                        //                         FixUpdateDescription: newData.FixUpdateDescription,
                                        //                         ClosedDate: newData.ClosedDate,
                                        //                     });
                                        //                     resolve();
                                        //                     return { ...prevState, data };
                                        //                 })
                                        //             }
                                        //         }, 600)
                                        //     }),
                                        // onRowDelete: oldData =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             resolve();
                                        //             console.log(oldData.id)
                                        //             deleteCapaVol(oldData.id)
                                        //             setCapaVol(prevState => {
                                        //                 const data = [...prevState.data];
                                        //                 data.splice(data.indexOf(oldData), 1);
                                        //                 return { ...prevState, data };
                                        //             });
                                        //         }, 600);
                                        //     }),
                                    }}
                                />
                            }


                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Percent Compliant ITAD Cork - Receiving, Basic Audit, Tech Audit & Turnout Report
                                </Typography>

                                <ChartistGraph
                                    id='percentItadCork'
                                    data={itadCorkGraph.data}
                                    type={itadCorkGraph.type}
                                    options={itadCorkGraph.options}
                                    // listener={itadCorkGraph.animation}
                                    plugins={itadCorkGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="reCK" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Receiving"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="baCK" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Basic Audit"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="taCK" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Tech Audit"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="toCK" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(210, 180, 30)',
                                            '&$checked': {
                                                color: 'rgb(210, 180, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Turnout Report"
                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Percent Compliant ITAD Milton Keynes - Receiving, Basic Audit, Tech Audit & Turnout Report
                                </Typography>

                                <ChartistGraph
                                    id='percentItadMiltonKeynes'
                                    data={itadMKGraph.data}
                                    type={itadMKGraph.type}
                                    options={itadMKGraph.options}
                                    // listener={itadMKGraph.animation}
                                    plugins={itadMKGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="reMK" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Receiving"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="baMK" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Basic Audit"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="taMK" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Tech Audit"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="toMK" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(210, 180, 30)',
                                            '&$checked': {
                                                color: 'rgb(210, 180, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Turnout Report"
                                    />

                                </Grid>
                            </Grid>


                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}
export default MetricsCustomerSLAforIEandUK