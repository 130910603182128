import React, { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Loading from '../../Loading'
import Html5QrCodeScanner from '../../../customComponents/Html5QrCodeScanner';


const useStyles = makeStyles(theme => ({

    hidden: {
        display: 'none'
    },

    marginLeft: {
        marginLeft: 24
    },
    buttonLeft: {
        float: 'left',
        marginLeft: '24px',
        marginTop: '24px',
        marginBottom: '35px',

    },
    buttonRight: {
        float: 'right',
        marginTop: '24px',
        marginBottom: '35px',

    },
    input: {
        display: 'none',
    },

    marginRight: {
        marginRight: '15px'
    },

}))


export default function BarcodeScan() {
    const classes = useStyles()

    const [searching, setSearching] = useState(false)
    const [scanText, setScanText] = useState('')
    const [scanResult, setScanResult] = useState(null)


    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        console.log(files[0])
        const img = 'https://storage.googleapis.com/wisetekappappspotcom/testFolder/20230215_133901.jpg'
        setSearching(true)


    }

    const handleNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
        console.log('decodedText', decodedText)
        console.log('decodedResult', decodedResult)
        setScanText('Decoded Text: ' + decodedText)
        setScanResult('Decoded Result: ' + JSON.stringify(decodedResult))
        // {
        //     decodedText: '65G4SQ2',
        //     result: {
        //         text: '65G4SQ2',
        //         format: { format: 3, formatName: 'CODE_39' },
        //         debugData: { decoderName: 'BarcodeDetector' }
        //     }
        // }
    };


    return (
        <div>
            <p>Barcode scan</p>


            {/* <input
                // accept="audio/*,video/*,image/*,.xlsx,.xls"
                accept="image/*"
                className={classes.input}
                id="add-contained-button-file"
                // multiple
                type="file"
                onChange={handleChangeFilePicker}
            />
            <label htmlFor="add-contained-button-file">
                <Button
                    className={classes.marginRight}
                    variant="contained"
                    color="default"
                    component="span"
                    startIcon={<AttachFileIcon />}>
                    Select Img
                </Button>
            </label>
            {searching ? <div><br /><Loading /><br /></div> : null}

            <br />
            <br />
            <br />
            <p>{foundText}</p> */}


            <Html5QrCodeScanner
                fps={10}
                qrbox={{ width: 500, height: 250 }}
                disableFlip={false}
                qrCodeSuccessCallback={handleNewScanResult}
            />
            <h1>{scanText}</h1>
            <h2>{scanResult}</h2>
        </div>
    )
}
