import React from 'react'
import { makeStyles } from '@material-ui/core';
import layaHealthcare from '../../../../images/LayaHealthcareConsultationsWisetek.jpg'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '90%',
        borderRadius: 20,
    },

}));

export default function Healthcare2024(row) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            {/* <header >
                <h2 className={classes.textCentered}>International Women's Day 2024 campaign to support Women's Aid Charity</h2>

            </header> */}
            <div className={classes.textCentered}>
                <img className={classes.media} src={layaHealthcare} alt="Laya Healthcare" />
            </div>

            <br />
            <h4 className={classes.marginLeft}>Dear Colleagues,</h4>
            <p>Please be advised that Wisetek continue to partner with Laya Healthcare. This partnership gives all Employees a <b>discount</b> on all Laya Healthcare Policies (whether an existing or new member).  All waiting periods have been waived  for those of you who may have pre-existing conditions.  All policies can be paid through Payroll, so there is no need to set up a direct debit.</p>
            <br />
            <p><b>Laya will be available onsite at Wisetek on Wednesday 20th March,</b>  for any members who would like to discuss their existing membership, you should have recently received your renewal pack directly from Laya.  Any employee who would like to become a new member of Laya healthcare or anyone who would like to compare Laya to their current provider.  Please let me know if you would like to meet with Marcella Donovan our account manager and I will set up an appointment for you.</p>

            <br />
            <p>Regards,<br /><br />
                Eleanor Lester<br />
                HR Co-Ordinator | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}
