import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CssBaseline, FormControl, FormControlLabel, makeStyles, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useRef } from 'react';
import ChartistGraph from 'react-chartist'
import ctPointLabels from 'chartist-plugin-pointlabels'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AuthContext } from '../../../contexts/AuthContext';
import { DataContext } from '../../../contexts/DataContext';
import { db } from '../../../config/fbConfig';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import wiseStars from '../../../images/WiseStars.gif';
import SideMenuV2 from '../SideMenus/SideMenuV2';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    // root: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     minHeight: '100vh',
    //     backgroundColor: '#cfd8dc'
    // },
    // main: {
    //     backgroundColor: '#f0f5f4',
    // },
    center: {
        // color: 'White',
        textAlign: 'center',
        // padding: '30px',
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    marginOne: {
        margin: 20
    },
    marginTwo: {
        margin: 40
    },
    padding30: {
        paddingLeft: 30,
        paddingRight: 30
    },
    wiseStars: {
        width: 300,
    },
    speechBubble: {
        padding: '5px 40px 5px 40px',
        background: '#5E84AF',
        opacity: 0.7,
        borderRadius: '0.5em',
        color: '#fff',
        marginBottom: '0px'
    },
    speechBubbleBottomLeft: {
        width: '10px',
        height: '10px',
        opacity: 0.7,
        borderLeft: '24px solid #5E84AF',
        borderRight: '25px solid transparent',
        // borderTop: '12px solid #5E84AF',
        borderBottom: '20px solid transparent',
        marginLeft: '15px'
    },
    authorText: {
        textAlign: 'left',
        marginTop: '0px',
        marginLeft: '10px',
        marginBottom: '30px',
        fontFamily: 'sans-serif',
        fontSize: '12px',
        lineHeight: '24px',
        fontStyle: 'italic'
    }

}));

const years = [{ id: 0, value: 0, label: "" }, { id: 1, value: 2022, label: 2022 }, { id: 2, value: 2023, label: 2023 }, { id: 3, value: 2024, label: 2024 }]
const quarters = [{ id: 0, value: 0, label: "" }, { id: 1, value: 1, label: 'First Quarter' }, { id: 2, value: 2, label: 'Second Quarter' }, { id: 3, value: 3, label: 'Third Quarter' }, { id: 4, value: 4, label: 'Fourth Quarter' }]

export default function WiseStar(props) {
    const classes = useStyles();
    const { uid, roles, isAuthenticated } = useContext(AuthContext);
    const { voteForWiseStar, searchForWiseStar, wiseStarsLocations } = useContext(DataContext);
    const history = useHistory();

    var propData = useLocation().state;
    // console.log(propData);
    const [nominationForm, setNominationForm] = useState({
        NominatedPerson: '',
        Location: '',
        NominatedFor: '',
        NominatedWhy: '',
    });
    const [allEmployees, setAllEmployees] = useState({
        columns: [
            { title: 'Full Name', field: 'full_name', editable: 'never' },
            { title: 'City', field: 'city', editable: 'never' },
            // { title: 'Status', field: 'Status', editable: 'never' },
            // { title: 'Status', field: 'Status', editable: 'never' }
        ],
        data: [],
        disabledInputs: true
    });
    const [wiseStarSearch, setWiseStarSearch] = useState({
        year: 0,
        quarter: 0,
        searching: false,
        data: null
    });
    const [votesChart, setVotesChart] = useState({
        data: {
            // // labels: ['Week ' + weekOne, 'Week ' + weekTwo, 'Week ' + weekThree],
            // series: [
            //     [weekOneSameDay, weekTwoSameDay, weekThreeSameDay],
            //     [weekOne2Days, weekTwo2Days, weekThree2Days],
            //     [weekOne3Days, weekTwo3Days, weekThree3Days],
            //     [weekOne4Days, weekTwo4Days, weekThree4Days],
            //     [weekOne5Days, weekTwo5Days, weekThree5Days],
            //     [weekOne6to10Days, weekTwo6to10Days, weekThree6to10Days],
            //     [weekOneOver10Days, weekTwoOver10Days, weekThreeOver10Days]
            // ]
        },
        type: 'Bar',
        options: {
            // high: 10,
            horizontalBars: true,
            reverseData: true,
            // stackBars: false,
            height: 30,
            // seriesBarDistance: 15,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                },
                offset: 100
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 5
                    },
                    // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        }
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    // const handleGetingFactorialEmployees = () => {
    //     const callable = functions.httpsCallable('getFactorialEmployees');
    //     var data = { text: 'empty string' }
    //     return callable(data, auth)
    //         .then(sucess => {
    //             // console.log('Get Factorial Employees trigerred....')
    //             setAllEmployees({
    //                 ...allEmployees,
    //                 data: JSON.parse(sucess.data),
    //                 disabledInputs: false,
    //             })
    //             console.log(JSON.parse(sucess.data))

    //         })
    //         .catch(err => {
    //             console.log('Error GET Factorial Employees....', err)

    //         })
    // }
    const getWiseStarsUserList = () => {
        db.collection('wiseStarsList').orderBy('fullName', 'asc').get()
            .then(snap => {
                var allWiseStars = [""]
                snap.forEach(star => {
                    allWiseStars.push(star.data().fullName)
                })
                setAllEmployees({
                    ...allEmployees,
                    data: allWiseStars,
                    disabledInputs: false,
                })
                // console.log(allWiseStars)
            })
            .catch(err => {
                console.log('Error GET WiseStars User List....', err)
            })
    }
    useEffect(() => {
        // handleGetingFactorialEmployees();
        getWiseStarsUserList();
        return () => { }
    }, [])


    const handleChangeAutoComplete = (e, newValue) => {
        setNominationForm({
            ...nominationForm,
            NominatedPerson: newValue
        })
    }
    const handleChange = (e) => {
        setNominationForm({
            ...nominationForm,
            [e.target.name]: e.target.value
        })
    };
    function clearForm() {
        setNominationForm({
            ...nominationForm,
            NominatedPerson: '',
            Location: '',
            NominatedFor: '',
            NominatedWhy: ''
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Submiting form')
        if (nominationForm.NominatedPerson === null || nominationForm.NominatedPerson === '' || nominationForm.Location === '' || nominationForm.NominatedFor === '' | nominationForm.NominatedWhy === '') {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please answer for all of the questions',
            })
            return
        } else {
            voteForWiseStar(nominationForm)
                .then(succ => {
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: succ,
                    })
                    clearForm();
                })
                .catch(err => {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: err,
                    })
                    clearForm();
                })
        }
    };
    const handleChangeSearchForWiseStar = (e) => {
        setWiseStarSearch({
            ...wiseStarSearch,
            [e.target.name]: e.target.value,
            data: null
        })
    }
    function countWiseStarsVotes(allVotes) {
        var countedVotes = []
        var tempVotes = []
        var nominatedPeople = []
        var nominatedPeopleVotes = []

        allVotes.forEach(vote => {
            if (tempVotes.includes(vote.NominatedPerson)) {
                var objIndex = tempVotes.findIndex(doc => doc === vote.NominatedPerson)
                // console.log(objIndex)
                countedVotes[objIndex].NominatedFor.push(vote.NominatedFor)
                countedVotes[objIndex].NominatedWhy.push(vote.NominatedWhy)
                countedVotes[objIndex].submitedBy.push(vote.submitedBy)
                countedVotes[objIndex].count = countedVotes[objIndex].count + 1
            } else {
                tempVotes.push(vote.NominatedPerson)
                countedVotes.push({ NominatedPerson: vote.NominatedPerson, NominatedFor: [vote.NominatedFor], NominatedWhy: [vote.NominatedWhy], submitedBy: [vote.submitedBy], count: 1 })
            }
        })
        countedVotes.sort((a, b) => (b.count > a.count) ? 1 : ((a.count > b.count) ? -1 : 0)) // swap b a for reverse sort
        countedVotes.forEach(vote => {
            nominatedPeopleVotes.push(vote.count)
            nominatedPeople.push(vote.NominatedPerson)
        })

        setWiseStarSearch({
            ...wiseStarSearch,
            searching: false,
            data: countedVotes
        })

        var chartHeight = nominatedPeople.length * 100
        setVotesChart({
            ...votesChart,
            data: {
                labels: nominatedPeople,
                series: [
                    nominatedPeopleVotes
                ]
            },
            options: {
                // high: 10,
                horizontalBars: true,
                reverseData: true,
                // stackBars: false,
                height: chartHeight,
                // height: wiseStarSearch.data ? wiseStarSearch.data.length * 20 : 300,
                // seriesBarDistance: 15,
                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                        // return value.split(/\s+/).map(function (word) {
                        //     return word[0];
                        // }).join('');
                    },
                    offset: 100,
                    showLabel: false,
                    showGrid: false
                },
                axisY: {
                    offset: 30,
                    // int on Y axis
                    onlyInteger: true,
                },
                plugins: [
                    ctPointLabels({
                        textAnchor: 'middle',
                        labelOffset: {
                            x: 0,
                            y: 5
                        },
                        // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                        labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                    })
                ]
            }
        })
    }
    const handleWiseStarSearch = (e) => {
        e.preventDefault();
        setWiseStarSearch({
            ...wiseStarSearch,
            searching: true
        })

        if (wiseStarSearch.year > 0 && wiseStarSearch.quarter > 0) {
            searchForWiseStar(wiseStarSearch)
                .then(respData => {
                    console.log(respData)
                    countWiseStarsVotes(respData)
                })
                .catch(err => {
                    console.log(err)
                    setWiseStarSearch({
                        ...wiseStarSearch,
                        searching: false
                    })
                })
        } else {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please select Year and Quarter to start searching for "WiseStars"',
            })
            setWiseStarSearch({
                ...wiseStarSearch,
                searching: false
            })
        }
    }

    const timerRef = useRef(null)

    function startTime() {
        var rightNow = moment()
        var endofThisQuarter = moment(moment().endOf('quarter')).endOf('days')
        var differenceInSec = moment(endofThisQuarter).diff(rightNow, 'seconds')
        var d = Math.floor(differenceInSec / (3600 * 24));
        var h = Math.floor(differenceInSec % (3600 * 24) / 3600);
        var m = Math.floor(differenceInSec % 3600 / 60);
        var s = Math.floor(differenceInSec % 60);
        var dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
        var hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
        // console.log(d, 'days', h, 'hours', m, 'minutes', s, 'seconds')
        // 
        // console.log(moment(endofThisQuarter).diff(rightNow, 'seconds'), 'seconds')
        // console.log(moment(endofThisQuarter).diff(rightNow, 'minutes'), 'minutes')
        // console.log(moment(endofThisQuarter).diff(rightNow, 'hours'), 'hours')
        // console.log(moment(endofThisQuarter).diff(rightNow, 'days'), 'days')
        // console.log(moment(endofThisQuarter).diff(rightNow, 'weeks'), 'weeks')

        // const today = new Date();
        // let h = today.getHours();
        // let m = today.getMinutes();
        // let s = today.getSeconds();
        // m = checkTime(m);
        // s = checkTime(s);
        var clock = document.getElementById('clock');
        if (clock != null) {
            document.getElementById('clock').innerHTML = dDisplay + hDisplay + mDisplay + sDisplay;
            timerRef.current = setTimeout(startTime, 1000);
        }
    }
    function checkTime(i) {
        if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
        return i;
    }
    useEffect(() => {
        startTime()
        return () => {
            clearTimeout(timerRef.current)
        }
    }, [])

    if (!isAuthenticated || roles.includes('viewAcc')) {
        return <Redirect to='/' />
    }
    else {
        return (
            <div className={classes.root}>
                <CssBaseline >
                    <SideMenuV2 selectedIndex={50} openHumanResourcesColapse={true} />
                    <div className={classes.main}>
                        <Card>

                            <div className={classes.center}>
                                <h1>WiseStars</h1>
                                <img className={classes.wiseStars} src={wiseStars} alt="WiseStars logo" />
                            </div>
                            <br /><br />
                            <div style={{ width: '60%', padding: 50, marginLeft: 'auto', marginRight: 'auto' }}>
                                <p className='bookingRoomsClock'>Quarter {moment().quarter()} ends in</p>
                                <div id='clock' className='bookingRoomsClock'></div>
                                <br /><br />
                                <form autoComplete="off">
                                    <h3>1.  Who would you like to nominate?</h3>
                                    <div className={classes.padding30}>
                                        <Autocomplete
                                            disabled={allEmployees.disabledInputs}
                                            value={nominationForm.NominatedPerson}
                                            onChange={handleChangeAutoComplete}
                                            name='NominatedPerson'
                                            id="combo-box-allEmployees"
                                            options={allEmployees.data}
                                            // getOptionLabel={(option) => option.fullName}
                                            style={{ width: '50%' }}
                                            renderInput={(params) => <TextField {...params}
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                label='Nominated Employee'
                                                fullWidth
                                                required
                                                autoComplete='off' />}
                                        />
                                    </div>

                                    <br /><br />
                                    <h3>2.  Where is this person based?</h3>
                                    <div className={classes.padding30}>
                                        <TextField
                                            select
                                            size="small"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="Location"
                                            label="Location"
                                            name="Location"
                                            style={{ width: '50%', marginTop: 20 }}
                                            // className={classes.lowerTextfield}
                                            inputProps={{ maxLength: 50 }}
                                            value={nominationForm.Location}
                                            onChange={handleChange}
                                        // InputLabelProps={{ shrink: true }}
                                        >
                                            {wiseStarsLocations.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                    <br /><br />
                                    <h3>3.  What would you like to nominate this person for?</h3>
                                    <div className={classes.padding30}>
                                        <FormControl component="fieldset" style={{ marginTop: 20 }}
                                            disabled={allEmployees.disabledInputs}>
                                            {/* <FormLabel component="legend">Gender</FormLabel> */}
                                            <RadioGroup aria-label="gender" name="NominatedFor" value={nominationForm.NominatedFor} onChange={handleChange}>
                                                <FormControlLabel value="Acting with Honesty and Integrity" control={<Radio color="primary" />} label="Acting with Honesty and Integrity" />
                                                <FormControlLabel value="Treating People and the Environment with Respect" control={<Radio color="primary" />} label="Treating People and the Environment with Respect" />
                                                <FormControlLabel value="Leading in Individual and Corporate Social Responsibility" control={<Radio color="primary" />} label="Leading in Individual and Corporate Social Responsibility" />
                                                <FormControlLabel value="Delivering Excellent Customer Satisfaction" control={<Radio color="primary" />} label="Delivering Excellent Customer Satisfaction" />
                                                <FormControlLabel value="Improving and Innovating Continuously" control={<Radio color="primary" />} label="Improving and Innovating Continuously" />
                                                <FormControlLabel value="Embracing the Circular Economy through Reuse IT equipment" control={<Radio color="primary" />} label="Embracing the Circular Economy through Reuse IT equipment" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                    <br /><br />
                                    <h3>4.  Tell us why this person should win the "WiseStar" award?</h3>
                                    <div className={classes.padding30}>
                                        <TextField id="NominatedWhy"
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            name='NominatedWhy'
                                            label="Justification"
                                            disabled={allEmployees.disabledInputs}
                                            style={{ marginTop: 20 }}
                                            fullWidth
                                            multiline
                                            rows={10}
                                            required
                                            autoComplete='off'
                                            helperText="up to 1000 characters"
                                            inputProps={{ maxLength: 1000 }}
                                            value={nominationForm.NominatedWhy}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <br /><br />
                                    <div className={classes.center}>
                                        <Button
                                            onClick={handleSubmit}
                                            disabled={allEmployees.disabledInputs}
                                            style={{ width: '50%' }}
                                            variant="contained"
                                            color="primary"
                                            size="small">
                                            {allEmployees.disabledInputs === true ? 'You already voted for this quarter "WiseStar"' : 'Submit'}
                                        </Button>
                                    </div>
                                </form>

                                {roles.includes('admin') || roles.includes('adminHR') ?
                                    <div style={{ width: 500, padding: 50, marginLeft: 'auto', marginRight: 'auto', marginTop: 50 }}>
                                        <h1 className={classes.center}>WiseStars Results</h1>
                                        <form id='wiseStarSearch'>
                                            <TextField
                                                select
                                                fullWidth
                                                required
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                name='year'
                                                label='Please select year'
                                                disabled={wiseStarSearch.searching}
                                                value={wiseStarSearch.year}
                                                onChange={handleChangeSearchForWiseStar}>
                                                {years.map(option => (
                                                    <MenuItem key={option.id} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                select
                                                fullWidth
                                                required
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                name='quarter'
                                                label='Please select quarter'
                                                disabled={wiseStarSearch.searching}
                                                value={wiseStarSearch.quarter}
                                                onChange={handleChangeSearchForWiseStar}>
                                                {quarters.map(option => (
                                                    <MenuItem key={option.id} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <div className={classes.center}>
                                                <Button className={classes.marginTwo}
                                                    onClick={handleWiseStarSearch}
                                                    disabled={wiseStarSearch.searching}
                                                    style={{ width: '50%' }}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small">
                                                    {wiseStarSearch.searching === true ? 'Searching for "WiseStar"...' : 'Search for "WiseStar"'}
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                    : null}

                                {wiseStarSearch.data ?
                                    wiseStarSearch.data.length === 0 ?
                                        <div className={classes.center}>
                                            <p>There was no submissions for Q{wiseStarSearch.quarter} of {wiseStarSearch.year}</p>
                                        </div>
                                        :
                                        <div className={classes.center}>
                                            <h4>Submissions for Q{wiseStarSearch.quarter} of {wiseStarSearch.year}</h4>
                                            <ChartistGraph
                                                data={votesChart.data}
                                                type={votesChart.type}
                                                options={votesChart.options}
                                                // listener={votesChart.animation}
                                                plugins={votesChart.options.plugins}
                                            />

                                            <div>
                                                {wiseStarSearch.data.map((star, starIndex) => (
                                                    <div key={star.NominatedPerson}>
                                                        <Typography variant="h6" paragraph>{star.NominatedPerson} with {star.count} votes</Typography>
                                                        {star.NominatedFor.map((nominatedFor, nominatedForIndex) => (
                                                            <div key={nominatedForIndex}>
                                                                <div className={classes.speechBubble}>
                                                                    <p>{nominatedFor}</p>
                                                                    <p>{star.NominatedWhy[nominatedForIndex]}</p>
                                                                </div>
                                                                <div className={classes.speechBubbleBottomLeft}></div>
                                                                <Typography variant="h5" align="center" color="textSecondary" paragraph className={classes.authorText}>
                                                                    ~ {star.submitedBy[nominatedForIndex]}
                                                                </Typography>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    :
                                    null
                                }

                                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                            </div>
                        </Card>
                    </div>
                </CssBaseline>
            </div>
        )
    }
}