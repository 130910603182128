import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { DataContext } from '../../../../contexts/DataContext';
import moment from 'moment';
import { db } from '../../../../config/fbConfig';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import csvToJson from 'csvtojson'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    dropzone: {
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '30px',
        textAlign: 'center',
        border: '1px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        padding: '20px',
    },
    input: {
        display: 'none',
    },
    buttonMargin: {
        marginLeft: '10px',
    },
    marginBottom: {
        marginBottom: '15px',
    },
    dropProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    searchProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    searchDataTable: {
        display: 'none',
    },

}));
export default function UploadCustomerSLAforUS() {
    const classes = useStyles();

    const { addMetricsCustomerSLAUS } = useContext(DataContext);

    const [metrics, setMetrics] = useState({
        searchDate: new Date(),
        columns: [
            { title: 'TRMA ID', field: 'TRMAID' },
            { title: 'Disposition Routing', field: 'DispositionRouting' },
            { title: 'Buy-from Vendor Name', field: 'BuyFromVendorName' },
            { title: 'Location Code', field: 'LocationCode' },
            { title: 'Deal WIP Status', field: 'DealWIPStatus' },
            { title: 'Date Arrived', field: 'DateArrived' },
            { title: 'SLA Receiving Due Date', field: 'SLAReceivingDueDate' },
            { title: 'Compliant to Rec SLA', field: 'CompliantToRecSLA', type: 'boolean' },
            { title: 'Basic Audit WIP Date', field: 'BasicAuditWIPDate' },
            { title: 'BA SLA Due Date', field: 'BASLADueDate' },
            { title: 'Compliant to Basic Audit SLA', field: 'CompliantToBasicAuditSLA', type: 'boolean' },
            { title: 'Tech Audit WIP Date', field: 'TechAuditWIPDate' },
            { title: 'Tech Audit SLA Due Date', field: 'TechAuditSLADueDate' },
            { title: 'Compliant to Tech Audit SLA', field: 'CompliantToTechAuditSLA', type: 'boolean' },
            { title: 'Verify WIP Date', field: 'VerifyWIPDate' },
            { title: 'Verification Due Date', field: 'VerificationDueDate' },
            { title: 'Verification Compliant to SLA', field: 'VerificationCompliantToSLA', type: 'boolean' },
            { title: 'Sales Date', field: 'SalesDate' },
            { title: 'SLA Turnout Due Date', field: 'SLATurnoutDueDate' },
            { title: 'Compliant Turnout SLA', field: 'CompliantTurnoutSLA', type: 'boolean' }
        ],
        data: [],
    })

    const [outputMatrics, setOutputMatrics] = useState({
        columns: [
            { title: 'Location Code', field: 'locationCode', width: 120 },
            { title: 'Date', field: 'dateCreated', width: 100 },
            { title: 'Rec Wip', field: 'recWip', width: 100 },
            { title: 'Compliant', field: 'recWipCompliant', width: 100 },
            { title: 'Basic Wip', field: 'basicWip', width: 100 },
            { title: 'Compliant', field: 'basicWipCompliant', width: 100 },
            { title: 'Tech Audit WIP', field: 'techAuditWip', width: 100 },
            { title: 'Compliant', field: 'techAuditWipCompliant', width: 100 },
            { title: 'Verification', field: 'verification', width: 100 },
            { title: 'Compliant', field: 'verificationCompliant', width: 100 },
            { title: 'WIP Turnout Required', field: 'wipTurnoutRequired', width: 100 },
            { title: 'WIP Turnout Compliant', field: 'wipTurnoutCompliant', width: 100 },
            { title: 'CS Turnout', field: 'sales', width: 100 },
            { title: 'CS Turnout Compliant', field: 'salesCompliant', width: 100 },
            { title: 'Rec Compliant', field: 'recComp', width: 100 },
            { title: 'BA Compliant', field: 'baComp', width: 100 },
            { title: 'TA Compliant', field: 'taComp', width: 100 },
            { title: 'Ver Compliant', field: 'verComp', width: 120 },
            { title: 'WIP Turnout Compliant', field: 'wipTurnComp', width: 100 },
            { title: 'CS Turnout Compliant', field: 'csTurnComp', width: 100 },
        ],
        data: []
    })

    // Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    // Dialog
    const [dialog, setDialog] = useState({
        open: false,
        title: '',
    });
    const handleCloseAndCancelDialog = (e) => {
        setMetrics({
            ...metrics,
            data: []
        });
        setDialog({
            open: false,
            title: '',
        });
        // var uploadedDataTable = document.getElementById('uploadedDataTable');
        // uploadedDataTable.style.display = 'none';
    };
    const handleUploadDialog = (e) => {
        setDialog({
            open: false,
            title: '',
        });
        //upload data to db and snackbar success
        addMetricsCustomerSLAUS(metrics.data)
            .then(success => {
                console.log('added ' + metrics.data.length + ' new rows to metrics db')
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                });
                // var uploadedDataTable = document.getElementById('uploadedDataTable');
                // uploadedDataTable.style.display = 'block';
            })
            .catch(error => {
                console.log(error)
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                });
            })
    };

    // calculations variables
    //#region

    var bostonCode = 'ITADBOSTON';
    var mdCode = 'ITADMD';
    var sacCode = 'ITADSAC';
    var smCode = 'ITADSM';
    var txCode = 'ITADTX';
    var vaCode = 'ITADVA';
    var boston = 0;
    var md = 0;
    var sac = 0;
    var sm = 0;
    var tx = 0;
    var va = 0;

    // foreach "LocationCode"
    // if "Arrived" in "DealWIPStatus"
    var bostonArrived = 0;
    var mdArrived = 0;
    var sacArrived = 0;
    var smArrived = 0;
    var txArrived = 0;
    var vaArrived = 0;
    // if "CompliantToRecSLA" true
    var bostonArrivedCompliant = 0;
    var mdArrivedCompliant = 0;
    var sacArrivedCompliant = 0;
    var smArrivedCompliant = 0;
    var txArrivedCompliant = 0;
    var vaArrivedCompliant = 0;

    // if "Basic Audit WIP" or "Offloaded" in "DealWIPStatus" and
    // everything else except "SORT" in "DispositionRouting"
    var bostonBasicWip = 0;
    var mdBasicWip = 0;
    var sacBasicWip = 0;
    var smBasicWip = 0;
    var txBasicWip = 0;
    var vaBasicWip = 0;
    // if "CompliantToBasicAuditSLA" true
    var bostonBasicWipCompliant = 0;
    var mdBasicWipCompliant = 0;
    var sacBasicWipCompliant = 0;
    var smBasicWipCompliant = 0;
    var txBasicWipCompliant = 0;
    var vaBasicWipCompliant = 0;

    // if "Tech Audit WIP" in "DealWIPStatus" and "AUDIT" "PICK" in "DispositionRouting"
    var bostonTechAuditWip = 0;
    var mdTechAuditWip = 0;
    var sacTechAuditWip = 0;
    var smTechAuditWip = 0;
    var txTechAuditWip = 0;
    var vaTechAuditWip = 0;
    // if "CompliantToTechAuditSLA" true
    var bostonTechAuditWipCompliant = 0;
    var mdTechAuditWipCompliant = 0;
    var sacTechAuditWipCompliant = 0;
    var smTechAuditWipCompliant = 0;
    var txTechAuditWipCompliant = 0;
    var vaTechAuditWipCompliant = 0;

    // if "Verification WIP" in "DealWIPStatus" and "AUDIT"  "PICK" in "DispositionRouting"
    var bostonVerification = 0;
    var mdVerification = 0;
    var sacVerification = 0;
    var smVerification = 0;
    var txVerification = 0;
    var vaVerification = 0;
    // if "VerificationCompliantToSLA" true
    var bostonVerificationCompliant = 0;
    var mdVerificationCompliant = 0;
    var sacVerificationCompliant = 0;
    var smVerificationCompliant = 0;
    var txVerificationCompliant = 0;
    var vaVerificationCompliant = 0;

    // "Arrived" "Basic Audit WIP" "Offloaded" "Tech Audit WIP" "Verification WIP" in "DealWIPStatus"
    var bostonWipTurnoutRequired = 0;
    var mdWipTurnoutRequired = 0;
    var sacWipTurnoutRequired = 0;
    var smWipTurnoutRequired = 0;
    var txWipTurnoutRequired = 0;
    var vaWipTurnoutRequired = 0;
    // if "CompliantTurnoutSLA" true
    var bostonWipTurnoutCompliant = 0;
    var mdWipTurnoutCompliant = 0;
    var sacWipTurnoutCompliant = 0;
    var smWipTurnoutCompliant = 0;
    var txWipTurnoutCompliant = 0;
    var vaWipTurnoutCompliant = 0;

    // "Sales" in "DealWIPStatus"
    var bostonSales = 0;
    var mdSales = 0;
    var sacSales = 0;
    var smSales = 0;
    var txSales = 0;
    var vaSales = 0;
    // if "CompliantTurnoutSLA" true
    var bostonSalesCompliant = 0;
    var mdSalesCompliant = 0;
    var sacSalesCompliant = 0;
    var smSalesCompliant = 0;
    var txSalesCompliant = 0;
    var vaSalesCompliant = 0;

    //  var ...ArrivedCompliant / ...Arrived
    var bostonRecComp = 0;
    var mdRecComp = 0;
    var sacRecComp = 0;
    var smRecComp = 0;
    var txRecComp = 0;
    var vaRecComp = 0;
    // var ...BasicWipCompliant / ...BasicWip
    var bostonBaComp = 0;
    var mdBaComp = 0;
    var sacBaComp = 0;
    var smBaComp = 0;
    var txBaComp = 0;
    var vaBaComp = 0;
    // var ...TechAuditWipCompliant / ...TechAuditWip
    var bostonTaComp = 0;
    var mdTaComp = 0;
    var sacTaComp = 0;
    var smTaComp = 0;
    var txTaComp = 0;
    var vaTaComp = 0;
    // var ...VerificationCompliant / Verification
    var bostonVerComp = 0;
    var mdVerComp = 0;
    var sacVerComp = 0;
    var smVerComp = 0;
    var txVerComp = 0;
    var vaVerComp = 0;
    // var ...WipTurnoutCompliant / WipTurnoutRequired
    var bostonWipTurnComp = 0;
    var mdWipTurnComp = 0;
    var sacWipTurnComp = 0;
    var smWipTurnComp = 0;
    var txWipTurnComp = 0;
    var vaWipTurnComp = 0;
    // var ...SalesCompliant / ...Sales
    var bostonCsTurnComp = 0;
    var mdCsTurnComp = 0;
    var sacCsTurnComp = 0;
    var smCsTurnComp = 0;
    var txCsTurnComp = 0;
    var vaCsTurnComp = 0;

    //#endregion

    // const calulateMetricsVariables = (snap) => {
    //     // var dataResult = [];
    //     var searchProgress = document.getElementById('searchProgress');

    //     var dataResult = [
    //         // { locationCode: 'ITADMD', dateCreated: '24 March 2021', recWip: 2, recWipCompliant: 0, basicWip: 12, basicWipCompliant: 5, techAuditWip: 12, techAuditWipCompliant: 6, verification: 14, verificationCompliant: 6, wipTurnoutRequired: 68, wipTurnoutCompliant: 48, sales: 100, salesCompliant: 52, recComp: 0 + '%', baComp: 42 + '%', taComp: 50 + '%', verComp: 43 + '%', wipTurnComp: 71 + '%', csTurnComp: 52 + '%' },
    //     ];
    //     // snap.forEach(doc => {
    //     //     console.log(doc.data())
    //     // })

    //     setOutputMatrics({
    //         ...outputMatrics,
    //         data: dataResult
    //     })
    //     searchProgress.style.visibility = 'hidden';
    // }
    // const getMetricsData = () => {
    //     // var dataResult = [];
    //     var selectedDate = new Date(metrics.searchDate)
    //     console.log(selectedDate)
    //     console.log(metrics.searchDate)
    //     db.collection("metrics")
    //         .where("dateCreated", "==", selectedDate).get()
    //         .then(snap => {
    //             calulateMetricsVariables(snap)
    //             // snap.forEach(doc => {
    //             //     console.log(doc)
    //             //     // var tempDoc = doc.data();
    //             //     // tempDoc.DateArrived = moment(doc.DateArrived).format('DD MMMM YYYY')
    //             //     // tempDoc.SLAReceivingDueDate = moment(doc.SLAReceivingDueDate).format('DD MMMM YYYY')
    //             //     // tempDoc.BasicAuditWIPDate = moment(doc.BasicAuditWIPDate).format('DD MMMM YYYY')
    //             //     // tempDoc.BASLADueDate = moment(doc.BASLADueDate).format('DD MMMM YYYY')
    //             //     // tempDoc.TechAuditWIPDate = moment(doc.TechAuditWIPDate).format('DD MMMM YYYY')
    //             //     // tempDoc.TechAuditSLADueDate = moment(doc.TechAuditSLADueDate).format('DD MMMM YYYY')
    //             //     // tempDoc.VerifyWIPDate = moment(doc.VerifyWIPDate).format('DD MMMM YYYY')
    //             //     // tempDoc.VerificationDueDate = moment(doc.VerificationDueDate).format('DD MMMM YYYY')
    //             //     // tempDoc.SalesDate = moment(doc.SalesDate).format('DD MMMM YYYY')
    //             //     // tempDoc.SLATurnoutDueDate = moment(doc.SLATurnoutDueDate).format('DD MMMM YYYY')
    //             //     // dataResult.push(tempDoc)
    //             // })
    //             // // console.log(dataResult)
    //             // setMetrics({
    //             //     ...metrics,
    //             //     data: dataResult
    //             // })
    //         })
    // }

    // useEffect(() => {
    //     getMetricsData()
    //     return () => { }
    // }, [])

    const handleDownloadTemplate = async (e) => {
        e.preventDefault();
        const customerSLATemplateURL = 'https://firebasestorage.googleapis.com/v0/b/wisetekapp.appspot.com/o/docTemplates%2FMetrics%2FCustomerSLATemplate.csv?alt=media&token=96f2d44a-94a4-470f-ae54-007e30e63b50';
        const response = await fetch(customerSLATemplateURL);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "CustomerSLATemplate.csv";
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }


    function fileTypes(fileName) {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }
    const dragOver = (e) => {
        e.preventDefault();
    }
    const dragEnter = (e) => {
        e.preventDefault();
    }
    const dragLeave = (e) => {
        e.preventDefault();
    }
    const readCSVFile = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        // reader.readAsBinaryString(file);
        reader.readAsText(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        // reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onload = () => resolve((reader.result));
        // reader.onload = () => {
        //     // Parse CSV file
        //     csv.parse(reader.result, (err, data) => {
        //         console.log("Parsed CSV data: ", data);
        //         resolve(data);
        //     });
        // };
        reader.onerror = error => reject(error);
    });

    const fileDrop = (e) => {
        e.preventDefault();
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        // for ref please check this article here: https://blog.logrocket.com/create-a-drag-and-drop-component-with-react-dropzone/
        const files = e.dataTransfer.files;
        if (files.length > 1) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Only one file allowed.',
            })
            dropProgress.style.visibility = 'hidden';
        }
        else {
            [...files].forEach(file => {
                var fileType = String(fileTypes(file.name));
                const maxSize = 25000000
                //var header = "TRMAID,DispositionRouting,BuyFromVendorName,LocationCode,DealWIPStatus,DateArrived,SLAReceivingDueDate,CompliantToRecSLA,BasicAuditWIPDate,BASLADueDate,CompliantToBasicAuditSLA,TechAuditWIPDate,TechAuditSLADueDate,CompliantToTechAuditSLA,VerifyWIPDate,VerificationDueDate,VerificationCompliantToSLA,SalesDate,SLATurnoutDueDate,CompliantTurnoutSLA"
                var header = "Col1,Col2,Col3,Col4,TRMAID,DispositionRouting,BuyFromVendorName,LocationCode,DealWIPStatus,AccountManager,CreatedDate,ExpectedArrivalDate,ExpectedContainerType,ExpectedContainerQTY,DateArrived,OffloadDate,SLAReceivingDueDate,SLAReceivingReportDate,CompliantToRecSLA,NoOfPalletsOpen,BasicAuditWIPDate,BASLADueDate,CompliantToBasicAuditSLA,TechAuditWIPDate,BasicAuditOutgoingDispoTech,NoOfPalletsOpen,TechAuditSLADueDate,CompliantToTechAuditSLA,VerifyWIPDate,TechTotalQtyProcessed,VerificationDueDateCalc2,Workdays,VerificationDueDateCal,VerificationDueDate,VerificationCompliantToSLA,SalesDate,SLATurnoutDueDate,SLATurnoutReportSentDate,CompliantTurnoutSLA,Today,Today,CustomerPending,CustomerPendingDate,CustomerReleased,CustomerReleasedDate"
                // console.log(header)
                if (fileType.toLowerCase() === 'csv') {
                    // console.log(file)
                    if (file.size >= maxSize) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'Selected file is too big to process.',
                        })
                        dropProgress.style.visibility = 'hidden';
                    }
                    else {
                        readCSVFile(file)
                            .then(result => {
                                // console.log(result.split('\n')[0])
                                var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                                if (header === uploadedHeader) {
                                    csvToJson().fromString(result)
                                        .then((jsonObj) => {
                                            // console.log(jsonObj);
                                            var processData = [];
                                            //change true false fiedls
                                            jsonObj.forEach(dataObject => {
                                                var calculatedDataObject = {};
                                                if (Object.keys(dataObject).length > 45) {
                                                    setSnackbar({
                                                        open: true,
                                                        severity: 'error',
                                                        message: 'Found additional columns, please use the correct template.',
                                                    })
                                                    dropProgress.style.visibility = 'hidden';
                                                    return
                                                }
                                                // console.log(dataObject)
                                                calculatedDataObject = customerPendingReleaseCalc(dataObject);

                                                processData.push(calculatedDataObject)
                                            })
                                            // console.log(processData)
                                            // //comented out to not show uploaded Table
                                            setMetrics({
                                                ...metrics,
                                                data: processData
                                            })
                                            setDialog({
                                                open: true,
                                                title: 'Found ' + processData.length + ' rows to upload, would you like to proceed?'
                                            })
                                            dropProgress.style.visibility = 'hidden';

                                        })
                                }
                                else {
                                    setSnackbar({
                                        open: true,
                                        severity: 'error',
                                        message: 'Template does not match. Please use correct template.',
                                    })
                                    dropProgress.style.visibility = 'hidden';
                                }
                            })
                    }
                }
                else {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: 'Please make sure that your are uploading a CSV file.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
            })
        }
    }

    const fileSelect = (e) => {
        var { target: { files } } = e;
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        // var onlyCSVs = [];
        // [...files].forEach(file => {
        //     var fileType = String(fileTypes(file.name));
        //     // console.log(fileType.toLowerCase())
        //     if (fileType.toLowerCase() === 'csv') {
        //         onlyCSVs.push(file)
        //     }
        // })
        // console.log(onlyCSVs)
        [...files].forEach(file => {
            var fileType = String(fileTypes(file.name));
            const maxSize = 25000000
            //var header = "TRMAID,DispositionRouting,BuyFromVendorName,LocationCode,DealWIPStatus,DateArrived,SLAReceivingDueDate,CompliantToRecSLA,BasicAuditWIPDate,BASLADueDate,CompliantToBasicAuditSLA,TechAuditWIPDate,TechAuditSLADueDate,CompliantToTechAuditSLA,VerifyWIPDate,VerificationDueDate,VerificationCompliantToSLA,SalesDate,SLATurnoutDueDate,CompliantTurnoutSLA"
            var header = "Col1,Col2,Col3,Col4,TRMAID,DispositionRouting,BuyFromVendorName,LocationCode,DealWIPStatus,AccountManager,CreatedDate,ExpectedArrivalDate,ExpectedContainerType,ExpectedContainerQTY,DateArrived,OffloadDate,SLAReceivingDueDate,SLAReceivingReportDate,CompliantToRecSLA,NoOfPalletsOpen,BasicAuditWIPDate,BASLADueDate,CompliantToBasicAuditSLA,TechAuditWIPDate,BasicAuditOutgoingDispoTech,NoOfPalletsOpen,TechAuditSLADueDate,CompliantToTechAuditSLA,VerifyWIPDate,TechTotalQtyProcessed,VerificationDueDateCalc2,Workdays,VerificationDueDateCal,VerificationDueDate,VerificationCompliantToSLA,SalesDate,SLATurnoutDueDate,SLATurnoutReportSentDate,CompliantTurnoutSLA,Today,Today,CustomerPending,CustomerPendingDate,CustomerReleased,CustomerReleasedDate"
            // console.log(header)
            if (fileType.toLowerCase() === 'csv') {
                // console.log(file)
                if (file.size >= maxSize) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'Selected file is too big to process.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
                else {
                    readCSVFile(file)
                        .then(result => {
                            // console.log(result.split('\n')[0])
                            var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                            if (header === uploadedHeader) {
                                csvToJson().fromString(result)
                                    .then((jsonObj) => {
                                        // console.log(jsonObj);
                                        var processData = [];
                                        //change true false fiedls
                                        jsonObj.forEach(dataObject => {
                                            var calculatedDataObject = {};
                                            if (Object.keys(dataObject).length > 45) {
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'error',
                                                    message: 'Found additional columns, please use the correct template.',
                                                })
                                                dropProgress.style.visibility = 'hidden';
                                                return
                                            }
                                            // console.log(dataObject)
                                            calculatedDataObject = customerPendingReleaseCalc(dataObject);

                                            processData.push(calculatedDataObject)
                                        })
                                        // console.log(processData)
                                        setDialog({
                                            open: true,
                                            title: 'Found ' + processData.length + ' rows to upload, would you like to proceed?'
                                        })
                                        dropProgress.style.visibility = 'hidden';
                                    })
                            }
                            else {
                                setSnackbar({
                                    open: true,
                                    severity: 'error',
                                    message: 'Template does not match. Please use correct template.',
                                })
                                dropProgress.style.visibility = 'hidden';
                            }
                        })
                }
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'Please make sure that your are uploading a CSV file.',
                })
                dropProgress.style.visibility = 'hidden';
            }
        })
    }
    const customerPendingReleaseCalc = (dataObject) => {
        var calculatedDataObject = {};
        // console.log(dataObject)
        // calculations only if custoemr pending true and customer released true
        if (dataObject.CustomerPending === 'TRUE' && dataObject.CustomerReleased === 'TRUE' ||
            dataObject.CustomerPending === true && dataObject.CustomerReleased === true) {
            console.log(dataObject)
            var daysToAdd = 0;
            daysToAdd = moment(dataObject.CustomerReleasedDate).diff(moment(dataObject.CustomerPendingDate), 'days') + 1
            console.log('days to add', daysToAdd)
            switch (dataObject.DealWIPStatus) {
                case 'Basic Audit WIP':
                    var calcVerificationDueDate = ''
                    var calcSLATurnoutDueDate = ''
                    var calcTechAuditSLADueDate = ''
                    var calcBasicAuditSLADueDate = ''
                    calcVerificationDueDate = moment(dataObject.VerificationDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
                    calcSLATurnoutDueDate = moment(dataObject.SLATurnoutDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
                    calcTechAuditSLADueDate = moment(dataObject.TechAuditSLADueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
                    calcBasicAuditSLADueDate = moment(dataObject.BASLADueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
                    console.log(dataObject.VerificationDueDate, 'Days added: ' + daysToAdd, calcVerificationDueDate)
                    console.log(dataObject.SLATurnoutDueDate, 'Days added: ' + daysToAdd, calcSLATurnoutDueDate)
                    console.log(dataObject.TechAuditSLADueDate, 'Days added: ' + daysToAdd, calcTechAuditSLADueDate)
                    console.log(dataObject.BASLADueDate, 'Days added: ' + daysToAdd, calcBasicAuditSLADueDate)
                    dataObject.VerificationDueDate = calcVerificationDueDate
                    dataObject.SLATurnoutDueDate = calcSLATurnoutDueDate
                    dataObject.TechAuditSLADueDate = calcTechAuditSLADueDate
                    dataObject.BASLADueDate = calcBasicAuditSLADueDate
                    break;
                case 'Tech Audit WIP':
                    var calcVerificationDueDate = ''
                    var calcSLATurnoutDueDate = ''
                    var calcTechAuditSLADueDate = ''
                    calcVerificationDueDate = moment(dataObject.VerificationDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
                    calcSLATurnoutDueDate = moment(dataObject.SLATurnoutDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
                    calcTechAuditSLADueDate = moment(dataObject.TechAuditSLADueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
                    console.log(dataObject.VerificationDueDate, 'Days added: ' + daysToAdd, calcVerificationDueDate)
                    console.log(dataObject.SLATurnoutDueDate, 'Days added: ' + daysToAdd, calcSLATurnoutDueDate)
                    console.log(dataObject.TechAuditSLADueDate, 'Days added: ' + daysToAdd, calcTechAuditSLADueDate)
                    dataObject.VerificationDueDate = calcVerificationDueDate
                    dataObject.SLATurnoutDueDate = calcSLATurnoutDueDate
                    dataObject.TechAuditSLADueDate = calcTechAuditSLADueDate
                    break;
                case 'Verification WIP':
                    var calcVerificationDueDate = ''
                    var calcSLATurnoutDueDate = ''
                    calcVerificationDueDate = moment(dataObject.VerificationDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
                    calcSLATurnoutDueDate = moment(dataObject.SLATurnoutDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
                    console.log(dataObject.VerificationDueDate, 'Days added: ' + daysToAdd, calcVerificationDueDate)
                    console.log(dataObject.SLATurnoutDueDate, 'Days added: ' + daysToAdd, calcSLATurnoutDueDate)
                    dataObject.VerificationDueDate = calcVerificationDueDate
                    dataObject.SLATurnoutDueDate = calcSLATurnoutDueDate
                    break;

                default:
                    break;
            }
        }

        // boolean fields are:
        var booleanKeysToCheck = ['CompliantToBasicAuditSLA', 'CompliantToRecSLA', 'CompliantToTechAuditSLA', 'CompliantTurnoutSLA', 'VerificationCompliantToSLA', 'CustomerPending', 'CustomerReleased'];
        // for (var key of Object.keys(dataObject)) {
        for (var key of booleanKeysToCheck) {
            // console.log(key + " -> " + dataObject[key])
            // console.log(dataObject[key])
            if (dataObject[key] == undefined) {
                dataObject[key] = false
            }
            else {
                if (dataObject[key].toLowerCase() === "ok" || dataObject[key].toLowerCase() === "yes" || dataObject[key].toLowerCase() === "true") {
                    dataObject[key] = true
                }
                else {
                    dataObject[key] = false
                }
            }
        }
        calculatedDataObject = dataObject;
        return calculatedDataObject
    }
    const handleSearchDateData = (date) => {
        if (moment(date).isValid()) {
            console.log('Going to search data for:', moment.utc(date).format('DD-MMM-YY'))
            setMetrics({
                ...metrics,
                searchDate: date
            })
            var dateFormatted = moment.utc(date).format('DD-MMM-YY');
            var searchDataTable = document.getElementById('searchDataTable');
            var searchProgress = document.getElementById('searchProgress');
            searchProgress.style.visibility = 'visible';
            var dataResult = [];
            var startDateTime = new Date(date.setHours(0, 0, 0, 0))
            var endDateTime = new Date(date.setHours(23, 59, 59))
            // console.log('startDateTime', startDateTime)
            // console.log('endDateTime', endDateTime)

            db.collection("metrics")
                .where("dateCreated", ">=", startDateTime)
                .where("dateCreated", "<=", endDateTime)
                .get()
                .then(snap => {
                    if (snap.empty) {
                        setOutputMatrics({
                            ...outputMatrics,
                            data: []
                        })
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'There was no results for ' + moment.utc(date).format('DD-MMM-YY'),
                        })
                        searchProgress.style.visibility = 'hidden';
                        searchDataTable.style.display = 'none';
                        return
                    }
                    snap.forEach(doc => {
                        var docData = doc.data();
                        console.log(docData)
                        switch (docData.LocationCode) {
                            case bostonCode:
                                boston++;
                                switch (docData.DealWIPStatus) {
                                    case 'Arrived':
                                        if (docData.CustomerPending === false) {
                                            bostonArrived++;
                                            if (docData.CompliantToRecSLA) {
                                                bostonArrivedCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                bostonArrived++;
                                                if (docData.CompliantToRecSLA) {
                                                    bostonArrivedCompliant++;
                                                }
                                            }
                                        }
                                        break;
                                    case 'Basic Audit WIP':
                                    case 'Offloaded':
                                        if (docData.DispositionRouting != 'SORT') {
                                            if (docData.CustomerPending === false) {
                                                bostonBasicWip++;
                                                if (docData.CompliantToBasicAuditSLA) {
                                                    bostonBasicWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    bostonBasicWip++;
                                                    if (docData.CompliantToBasicAuditSLA) {
                                                        bostonBasicWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Tech Audit WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                bostonTechAuditWip++;
                                                if (docData.CompliantToTechAuditSLA) {
                                                    bostonTechAuditWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    bostonTechAuditWip++;
                                                    if (docData.CompliantToTechAuditSLA) {
                                                        bostonTechAuditWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Verification WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                bostonVerification++;
                                                if (docData.VerificationCompliantToSLA) {
                                                    bostonVerificationCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    bostonVerification++;
                                                    if (docData.VerificationCompliantToSLA) {
                                                        bostonVerificationCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Sales':
                                        if (docData.CustomerPending === false) {
                                            bostonSales++;
                                            if (docData.CompliantTurnoutSLA) {
                                                bostonSalesCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                bostonSales++;
                                                if (docData.CompliantTurnoutSLA) {
                                                    bostonSalesCompliant++;
                                                }
                                            }
                                        }
                                        break;

                                    default:
                                        break;
                                }
                                if (docData.DealWIPStatus != 'Sales') {
                                    if (docData.CustomerPending === false) {
                                        bostonWipTurnoutRequired++;
                                        if (docData.CompliantTurnoutSLA) {
                                            bostonWipTurnoutCompliant++;
                                        }
                                    }
                                    else {
                                        if (docData.CustomerReleased) {
                                            bostonWipTurnoutRequired++;
                                            if (docData.CompliantTurnoutSLA) {
                                                bostonWipTurnoutCompliant++;
                                            }
                                        }
                                    }
                                }
                                break;

                            case mdCode:
                                md++;
                                switch (docData.DealWIPStatus) {
                                    case 'Arrived':
                                        if (docData.CustomerPending === false) {
                                            mdArrived++;
                                            if (docData.CompliantToRecSLA) {
                                                mdArrivedCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                mdArrived++;
                                                if (docData.CompliantToRecSLA) {
                                                    mdArrivedCompliant++;
                                                }
                                            }
                                        }
                                        break;
                                    case 'Basic Audit WIP':
                                    case 'Offloaded':
                                        if (docData.DispositionRouting != 'SORT') {
                                            if (docData.CustomerPending === false) {
                                                mdBasicWip++;
                                                if (docData.CompliantToBasicAuditSLA) {
                                                    mdBasicWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    mdBasicWip++;
                                                    if (docData.CompliantToBasicAuditSLA) {
                                                        mdBasicWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Tech Audit WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                mdTechAuditWip++;
                                                if (docData.CompliantToTechAuditSLA) {
                                                    mdTechAuditWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    mdTechAuditWip++;
                                                    if (docData.CompliantToTechAuditSLA) {
                                                        mdTechAuditWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Verification WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                mdVerification++;
                                                if (docData.VerificationCompliantToSLA) {
                                                    mdVerificationCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    mdVerification++;
                                                    if (docData.VerificationCompliantToSLA) {
                                                        mdVerificationCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Sales':
                                        if (docData.CustomerPending === false) {
                                            mdSales++;
                                            if (docData.CompliantTurnoutSLA) {
                                                mdSalesCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                mdSales++;
                                                if (docData.CompliantTurnoutSLA) {
                                                    mdSalesCompliant++;
                                                }
                                            }
                                        }
                                        break;

                                    default:
                                        break;
                                }
                                if (docData.DealWIPStatus != 'Sales') {
                                    if (docData.CustomerPending === false) {
                                        mdWipTurnoutRequired++;
                                        if (docData.CompliantTurnoutSLA) {
                                            mdWipTurnoutCompliant++;
                                        }
                                    }
                                    else {
                                        if (docData.CustomerReleased) {
                                            mdWipTurnoutRequired++;
                                            if (docData.CompliantTurnoutSLA) {
                                                mdWipTurnoutCompliant++;
                                            }
                                        }
                                    }
                                }
                                break;

                            case sacCode:
                                sac++;
                                switch (docData.DealWIPStatus) {
                                    case 'Arrived':
                                        if (docData.CustomerPending === false) {
                                            sacArrived++;
                                            if (docData.CompliantToRecSLA) {
                                                sacArrivedCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                sacArrived++;
                                                if (docData.CompliantToRecSLA) {
                                                    sacArrivedCompliant++;
                                                }
                                            }
                                        }
                                        break;
                                    case 'Basic Audit WIP':
                                    case 'Offloaded':
                                        if (docData.DispositionRouting != 'SORT') {
                                            if (docData.CustomerPending === false) {
                                                sacBasicWip++;
                                                if (docData.CompliantToBasicAuditSLA) {
                                                    sacBasicWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    sacBasicWip++;
                                                    if (docData.CompliantToBasicAuditSLA) {
                                                        sacBasicWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Tech Audit WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                sacTechAuditWip++;
                                                if (docData.CompliantToTechAuditSLA) {
                                                    sacTechAuditWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    sacTechAuditWip++;
                                                    if (docData.CompliantToTechAuditSLA) {
                                                        sacTechAuditWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Verification WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                sacVerification++;
                                                if (docData.VerificationCompliantToSLA) {
                                                    sacVerificationCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    sacVerification++;
                                                    if (docData.VerificationCompliantToSLA) {
                                                        sacVerificationCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Sales':
                                        if (docData.CustomerPending === false) {
                                            sacSales++;
                                            if (docData.CompliantTurnoutSLA) {
                                                sacSalesCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                sacSales++;
                                                if (docData.CompliantTurnoutSLA) {
                                                    sacSalesCompliant++;
                                                }
                                            }
                                        }
                                        break;

                                    default:
                                        break;
                                }
                                if (docData.DealWIPStatus != 'Sales') {
                                    if (docData.CustomerPending === false) {
                                        sacWipTurnoutRequired++;
                                        if (docData.CompliantTurnoutSLA) {
                                            sacWipTurnoutCompliant++;
                                        }
                                    }
                                    else {
                                        if (docData.CustomerReleased) {
                                            sacWipTurnoutRequired++;
                                            if (docData.CompliantTurnoutSLA) {
                                                sacWipTurnoutCompliant++;
                                            }
                                        }
                                    }
                                }
                                break;

                            case smCode:
                                sm++;
                                switch (docData.DealWIPStatus) {
                                    case 'Arrived':
                                        if (docData.CustomerPending === false) {
                                            smArrived++;
                                            if (docData.CompliantToRecSLA) {
                                                smArrivedCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                smArrived++;
                                                if (docData.CompliantToRecSLA) {
                                                    smArrivedCompliant++;
                                                }
                                            }
                                        }
                                        break;
                                    case 'Basic Audit WIP':
                                    case 'Offloaded':
                                        if (docData.DispositionRouting != 'SORT') {
                                            if (docData.CustomerPending === false) {
                                                smBasicWip++;
                                                if (docData.CompliantToBasicAuditSLA) {
                                                    smBasicWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    smBasicWip++;
                                                    if (docData.CompliantToBasicAuditSLA) {
                                                        smBasicWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Tech Audit WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                smTechAuditWip++;
                                                if (docData.CompliantToTechAuditSLA) {
                                                    smTechAuditWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    smTechAuditWip++;
                                                    if (docData.CompliantToTechAuditSLA) {
                                                        smTechAuditWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Verification WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                smVerification++;
                                                if (docData.VerificationCompliantToSLA) {
                                                    smVerificationCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    smVerification++;
                                                    if (docData.VerificationCompliantToSLA) {
                                                        smVerificationCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Sales':
                                        if (docData.CustomerPending === false) {
                                            smSales++;
                                            if (docData.CompliantTurnoutSLA) {
                                                smSalesCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                smSales++;
                                                if (docData.CompliantTurnoutSLA) {
                                                    smSalesCompliant++;
                                                }
                                            }
                                        }
                                        break;

                                    default:
                                        break;
                                }
                                if (docData.DealWIPStatus != 'Sales') {
                                    if (docData.CustomerPending === false) {
                                        smWipTurnoutRequired++;
                                        if (docData.CompliantTurnoutSLA) {
                                            smWipTurnoutCompliant++;
                                        }
                                    }
                                    else {
                                        if (docData.CustomerReleased) {
                                            smWipTurnoutRequired++;
                                            if (docData.CompliantTurnoutSLA) {
                                                smWipTurnoutCompliant++;
                                            }
                                        }
                                    }
                                }
                                break;

                            case txCode:
                                tx++;
                                switch (docData.DealWIPStatus) {
                                    case 'Arrived':
                                        if (docData.CustomerPending === false) {
                                            txArrived++;
                                            if (docData.CompliantToRecSLA) {
                                                txArrivedCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                txArrived++;
                                                if (docData.CompliantToRecSLA) {
                                                    txArrivedCompliant++;
                                                }
                                            }
                                        }
                                        break;
                                    case 'Basic Audit WIP':
                                    case 'Offloaded':
                                        if (docData.DispositionRouting != 'SORT') {
                                            if (docData.CustomerPending === false) {
                                                txBasicWip++;
                                                if (docData.CompliantToBasicAuditSLA) {
                                                    txBasicWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    txBasicWip++;
                                                    if (docData.CompliantToBasicAuditSLA) {
                                                        txBasicWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Tech Audit WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                txTechAuditWip++;
                                                if (docData.CompliantToTechAuditSLA) {
                                                    txTechAuditWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    txTechAuditWip++;
                                                    if (docData.CompliantToTechAuditSLA) {
                                                        txTechAuditWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Verification WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                txVerification++;
                                                if (docData.VerificationCompliantToSLA) {
                                                    txVerificationCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    txVerification++;
                                                    if (docData.VerificationCompliantToSLA) {
                                                        txVerificationCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Sales':
                                        if (docData.CustomerPending === false) {
                                            txSales++;
                                            if (docData.CompliantTurnoutSLA) {
                                                txSalesCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                txSales++;
                                                if (docData.CompliantTurnoutSLA) {
                                                    txSalesCompliant++;
                                                }
                                            }
                                        }
                                        break;

                                    default:
                                        break;
                                }
                                if (docData.DealWIPStatus != 'Sales') {
                                    if (docData.CustomerPending === false) {
                                        txWipTurnoutRequired++;
                                        if (docData.CompliantTurnoutSLA) {
                                            txWipTurnoutCompliant++;
                                        }
                                    }
                                    else {
                                        if (docData.CustomerReleased) {
                                            txWipTurnoutRequired++;
                                            if (docData.CompliantTurnoutSLA) {
                                                txWipTurnoutCompliant++;
                                            }
                                        }
                                    }
                                }
                                break;

                            case vaCode:
                                va++;
                                switch (docData.DealWIPStatus) {
                                    case 'Arrived':
                                        if (docData.CustomerPending === false) {
                                            vaArrived++;
                                            if (docData.CompliantToRecSLA) {
                                                vaArrivedCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                vaArrived++;
                                                if (docData.CompliantToRecSLA) {
                                                    vaArrivedCompliant++;
                                                }
                                            }
                                        }
                                        break;

                                    case 'Basic Audit WIP':
                                    case 'Offloaded':
                                        if (docData.DispositionRouting != 'SORT') {
                                            if (docData.CustomerPending === false) {
                                                vaBasicWip++;
                                                if (docData.CompliantToBasicAuditSLA) {
                                                    vaBasicWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    vaBasicWip++;
                                                    if (docData.CompliantToBasicAuditSLA) {
                                                        vaBasicWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Tech Audit WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                vaTechAuditWip++;
                                                if (docData.CompliantToTechAuditSLA) {
                                                    vaTechAuditWipCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    vaTechAuditWip++;
                                                    if (docData.CompliantToTechAuditSLA) {
                                                        vaTechAuditWipCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Verification WIP':
                                        if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                                            if (docData.CustomerPending === false) {
                                                vaVerification++;
                                                if (docData.VerificationCompliantToSLA) {
                                                    vaVerificationCompliant++;
                                                }
                                            }
                                            else {
                                                if (docData.CustomerReleased) {
                                                    vaVerification++;
                                                    if (docData.VerificationCompliantToSLA) {
                                                        vaVerificationCompliant++;
                                                    }
                                                }
                                            }
                                        }
                                        break;
                                    case 'Sales':
                                        if (docData.CustomerPending === false) {
                                            vaSales++;
                                            if (docData.CompliantTurnoutSLA) {
                                                vaSalesCompliant++;
                                            }
                                        }
                                        else {
                                            if (docData.CustomerReleased) {
                                                vaSales++;
                                                if (docData.CompliantTurnoutSLA) {
                                                    vaSalesCompliant++;
                                                }
                                            }
                                        }
                                        break;

                                    default:
                                        break;
                                }
                                if (docData.DealWIPStatus != 'Sales') {
                                    if (docData.CustomerPending === false) {
                                        vaWipTurnoutRequired++;
                                        if (docData.CompliantTurnoutSLA) {
                                            vaWipTurnoutCompliant++;
                                        }
                                    }
                                    else {
                                        if (docData.CustomerReleased) {
                                            vaWipTurnoutRequired++;
                                            if (docData.CompliantTurnoutSLA) {
                                                vaWipTurnoutCompliant++;
                                            }
                                        }
                                    }

                                }
                                break;

                            default:
                                break;
                        }

                    })
                    //  var ...ArrivedCompliant / Arrived
                    bostonRecComp = bostonArrivedCompliant == 0 && bostonArrived == 0 ? 100 : bostonArrivedCompliant == 0 || bostonArrived == 0 ? 0 : Math.round(((bostonArrivedCompliant / bostonArrived) * 100) * 10) / 10; //to have decimal percentage
                    mdRecComp = mdArrivedCompliant == 0 && mdArrived == 0 ? 100 : mdArrivedCompliant == 0 || mdArrived == 0 ? 0 : Math.round(((mdArrivedCompliant / mdArrived) * 100) * 10) / 10;
                    sacRecComp = sacArrivedCompliant == 0 && sacArrived == 0 ? 100 : sacArrivedCompliant == 0 || sacArrived == 0 ? 0 : Math.round(((sacArrivedCompliant / sacArrived) * 100) * 10) / 10;
                    smRecComp = smArrivedCompliant == 0 && smArrived == 0 ? 100 : smArrivedCompliant == 0 || smArrived == 0 ? 0 : Math.round(((smArrivedCompliant / smArrived) * 100) * 10) / 10;
                    txRecComp = txArrivedCompliant == 0 && txArrived == 0 ? 100 : txArrivedCompliant == 0 || txArrived == 0 ? 0 : Math.round(((txArrivedCompliant / txArrived) * 100) * 10) / 10;
                    vaRecComp = vaArrivedCompliant == 0 && vaArrived == 0 ? 100 : vaArrivedCompliant == 0 || vaArrived == 0 ? 0 : Math.round(((vaArrivedCompliant / vaArrived) * 100) * 10) / 10;
                    // var ...BasicWipCompliant / BasicWip
                    bostonBaComp = bostonBasicWipCompliant == 0 && bostonBasicWip == 0 ? 100 : bostonBasicWipCompliant == 0 || bostonBasicWip == 0 ? 0 : Math.round(((bostonBasicWipCompliant / bostonBasicWip) * 100) * 10) / 10;
                    mdBaComp = mdBasicWipCompliant == 0 && mdBasicWip == 0 ? 100 : mdBasicWipCompliant == 0 || mdBasicWip == 0 ? 0 : Math.round(((mdBasicWipCompliant / mdBasicWip) * 100) * 10) / 10;
                    sacBaComp = sacBasicWipCompliant == 0 && sacBasicWip == 0 ? 100 : sacBasicWipCompliant == 0 || sacBasicWip == 0 ? 0 : Math.round(((sacBasicWipCompliant / sacBasicWip) * 100) * 10) / 10;
                    smBaComp = smBasicWipCompliant == 0 && smBasicWip == 0 ? 100 : smBasicWipCompliant == 0 || smBasicWip == 0 ? 0 : Math.round(((smBasicWipCompliant / smBasicWip) * 100) * 10) / 10;
                    txBaComp = txBasicWipCompliant == 0 && txBasicWip == 0 ? 100 : txBasicWipCompliant == 0 || txBasicWip == 0 ? 0 : Math.round(((txBasicWipCompliant / txBasicWip) * 100) * 10) / 10;
                    vaBaComp = vaBasicWipCompliant == 0 && vaBasicWip == 0 ? 100 : vaBasicWipCompliant == 0 || vaBasicWip == 0 ? 0 : Math.round(((vaBasicWipCompliant / vaBasicWip) * 100) * 10) / 10;
                    // var ...TechAuditWipCompliant / TechAuditWip
                    bostonTaComp = bostonTechAuditWipCompliant == 0 && bostonTechAuditWip == 0 ? 100 : bostonTechAuditWipCompliant == 0 || bostonTechAuditWip == 0 ? 0 : Math.round(((bostonTechAuditWipCompliant / bostonTechAuditWip) * 100) * 10) / 10;
                    mdTaComp = mdTechAuditWipCompliant == 0 && mdTechAuditWip == 0 ? 100 : mdTechAuditWipCompliant == 0 || mdTechAuditWip == 0 ? 0 : Math.round(((mdTechAuditWipCompliant / mdTechAuditWip) * 100) * 10) / 10;
                    sacTaComp = sacTechAuditWipCompliant == 0 && sacTechAuditWip == 0 ? 100 : sacTechAuditWipCompliant == 0 || sacTechAuditWip == 0 ? 0 : Math.round(((sacTechAuditWipCompliant / sacTechAuditWip) * 100) * 10) / 10;
                    smTaComp = smTechAuditWipCompliant == 0 && smTechAuditWip == 0 ? 100 : smTechAuditWipCompliant == 0 || smTechAuditWip == 0 ? 0 : Math.round(((smTechAuditWipCompliant / smTechAuditWip) * 100) * 10) / 10;
                    txTaComp = txTechAuditWipCompliant == 0 && txTechAuditWip == 0 ? 100 : txTechAuditWipCompliant == 0 || txTechAuditWip == 0 ? 0 : Math.round(((txTechAuditWipCompliant / txTechAuditWip) * 100) * 10) / 10;
                    vaTaComp = vaTechAuditWipCompliant == 0 && vaTechAuditWip == 0 ? 100 : vaTechAuditWipCompliant == 0 || vaTechAuditWip == 0 ? 0 : Math.round(((vaTechAuditWipCompliant / vaTechAuditWip) * 100) * 10) / 10;
                    // var ...VerificationCompliant / Verification
                    bostonVerComp = bostonVerificationCompliant == 0 && bostonVerification == 0 ? 100 : bostonVerificationCompliant == 0 || bostonVerification == 0 ? 0 : Math.round(((bostonVerificationCompliant / bostonVerification) * 100) * 10) / 10;
                    mdVerComp = mdVerificationCompliant == 0 && mdVerification == 0 ? 100 : mdVerificationCompliant == 0 || mdVerification == 0 ? 0 : Math.round(((mdVerificationCompliant / mdVerification) * 100) * 10) / 10;
                    sacVerComp = sacVerificationCompliant == 0 && sacVerification == 0 ? 100 : sacVerificationCompliant == 0 || sacVerification == 0 ? 0 : Math.round(((sacVerificationCompliant / sacVerification) * 100) * 10) / 10;
                    smVerComp = smVerificationCompliant == 0 && smVerification == 0 ? 100 : smVerificationCompliant == 0 || smVerification == 0 ? 0 : Math.round(((smVerificationCompliant / smVerification) * 100) * 10) / 10;
                    txVerComp = txVerificationCompliant == 0 && txVerification == 0 ? 100 : txVerificationCompliant == 0 || txVerification == 0 ? 0 : Math.round(((txVerificationCompliant / txVerification) * 100) * 10) / 10;
                    vaVerComp = vaVerificationCompliant == 0 && vaVerification == 0 ? 100 : vaVerificationCompliant == 0 || vaVerification == 0 ? 0 : Math.round(((vaVerificationCompliant / vaVerification) * 100) * 10) / 10;
                    // var ...WipTurnoutCompliant / WipTurnoutRequired
                    bostonWipTurnComp = bostonWipTurnoutCompliant == 0 && bostonWipTurnoutRequired == 0 ? 100 : bostonWipTurnoutCompliant == 0 || bostonWipTurnoutRequired == 0 ? 0 : Math.round(((bostonWipTurnoutCompliant / bostonWipTurnoutRequired) * 100) * 10) / 10;
                    mdWipTurnComp = mdWipTurnoutCompliant == 0 && mdWipTurnoutRequired == 0 ? 100 : mdWipTurnoutCompliant == 0 || mdWipTurnoutRequired == 0 ? 0 : Math.round(((mdWipTurnoutCompliant / mdWipTurnoutRequired) * 100) * 10) / 10;
                    sacWipTurnComp = sacWipTurnoutCompliant == 0 && sacWipTurnoutRequired == 0 ? 100 : sacWipTurnoutCompliant == 0 || sacWipTurnoutRequired == 0 ? 0 : Math.round(((sacWipTurnoutCompliant / sacWipTurnoutRequired) * 100) * 10) / 10;
                    smWipTurnComp = smWipTurnoutCompliant == 0 && smWipTurnoutRequired == 0 ? 100 : smWipTurnoutCompliant == 0 || smWipTurnoutRequired == 0 ? 0 : Math.round(((smWipTurnoutCompliant / smWipTurnoutRequired) * 100) * 10) / 10;
                    txWipTurnComp = txWipTurnoutCompliant == 0 && txWipTurnoutRequired == 0 ? 100 : txWipTurnoutCompliant == 0 || txWipTurnoutRequired == 0 ? 0 : Math.round(((txWipTurnoutCompliant / txWipTurnoutRequired) * 100) * 10) / 10;
                    vaWipTurnComp = vaWipTurnoutCompliant == 0 && vaWipTurnoutRequired == 0 ? 100 : vaWipTurnoutCompliant == 0 || vaWipTurnoutRequired == 0 ? 0 : Math.round(((vaWipTurnoutCompliant / vaWipTurnoutRequired) * 100) * 10) / 10;
                    // var ...SalesCompliant / Sales
                    bostonCsTurnComp = bostonSalesCompliant == 0 && bostonSales == 0 ? 100 : bostonSalesCompliant == 0 || bostonSales == 0 ? 0 : Math.round(((bostonSalesCompliant / bostonSales) * 100) * 10) / 10;
                    mdCsTurnComp = mdSalesCompliant == 0 && mdSales == 0 ? 100 : mdSalesCompliant == 0 || mdSales == 0 ? 0 : Math.round(((mdSalesCompliant / mdSales) * 100) * 10) / 10;
                    sacCsTurnComp = sacSalesCompliant == 0 && sacSales == 0 ? 100 : sacSalesCompliant == 0 || sacSales == 0 ? 0 : Math.round(((sacSalesCompliant / sacSales) * 100) * 10) / 10;
                    smCsTurnComp = smSalesCompliant == 0 && smSales == 0 ? 100 : smSalesCompliant == 0 || smSales == 0 ? 0 : Math.round(((smSalesCompliant / smSales) * 100) * 10) / 10;
                    txCsTurnComp = txSalesCompliant == 0 && txSales == 0 ? 100 : txSalesCompliant == 0 || txSales == 0 ? 0 : Math.round(((txSalesCompliant / txSales) * 100) * 10) / 10;
                    vaCsTurnComp = vaSalesCompliant == 0 && vaSales == 0 ? 100 : vaSalesCompliant == 0 || vaSales == 0 ? 0 : Math.round(((vaSalesCompliant / vaSales) * 100) * 10) / 10;

                    dataResult = [
                        { locationCode: bostonCode, dateCreated: dateFormatted, recWip: bostonArrived, recWipCompliant: bostonArrivedCompliant, basicWip: bostonBasicWip, basicWipCompliant: bostonBasicWipCompliant, techAuditWip: bostonTechAuditWip, techAuditWipCompliant: bostonTechAuditWipCompliant, verification: bostonVerification, verificationCompliant: bostonVerificationCompliant, wipTurnoutRequired: bostonWipTurnoutRequired, wipTurnoutCompliant: bostonWipTurnoutCompliant, sales: bostonSales, salesCompliant: bostonSalesCompliant, recComp: bostonRecComp + '%', baComp: bostonBaComp + '%', taComp: bostonBaComp + '%', verComp: bostonVerComp + '%', wipTurnComp: bostonWipTurnComp + '%', csTurnComp: bostonCsTurnComp + '%' },
                        { locationCode: mdCode, dateCreated: dateFormatted, recWip: mdArrived, recWipCompliant: mdArrivedCompliant, basicWip: mdBasicWip, basicWipCompliant: mdBasicWipCompliant, techAuditWip: mdTechAuditWip, techAuditWipCompliant: mdTechAuditWipCompliant, verification: mdVerification, verificationCompliant: mdVerificationCompliant, wipTurnoutRequired: mdWipTurnoutRequired, wipTurnoutCompliant: mdWipTurnoutCompliant, sales: mdSales, salesCompliant: mdSalesCompliant, recComp: mdRecComp + '%', baComp: mdBaComp + '%', taComp: mdTaComp + '%', verComp: mdVerComp + '%', wipTurnComp: mdWipTurnComp + '%', csTurnComp: mdCsTurnComp + '%' },
                        { locationCode: sacCode, dateCreated: dateFormatted, recWip: sacArrived, recWipCompliant: sacArrivedCompliant, basicWip: sacBasicWip, basicWipCompliant: sacBasicWipCompliant, techAuditWip: sacTechAuditWip, techAuditWipCompliant: sacTechAuditWipCompliant, verification: sacVerification, verificationCompliant: sacVerificationCompliant, wipTurnoutRequired: sacWipTurnoutRequired, wipTurnoutCompliant: sacWipTurnoutCompliant, sales: sacSales, salesCompliant: sacSalesCompliant, recComp: sacRecComp + '%', baComp: sacBaComp + '%', taComp: sacTaComp + '%', verComp: sacVerComp + '%', wipTurnComp: sacWipTurnComp + '%', csTurnComp: sacCsTurnComp + '%' },
                        { locationCode: smCode, dateCreated: dateFormatted, recWip: smArrived, recWipCompliant: smArrivedCompliant, basicWip: smBasicWip, basicWipCompliant: smBasicWipCompliant, techAuditWip: smTechAuditWip, techAuditWipCompliant: smTechAuditWipCompliant, verification: smVerification, verificationCompliant: smVerificationCompliant, wipTurnoutRequired: smWipTurnoutRequired, wipTurnoutCompliant: smWipTurnoutCompliant, sales: smSales, salesCompliant: smSalesCompliant, recComp: smRecComp + '%', baComp: smBaComp + '%', taComp: smTaComp + '%', verComp: smVerComp + '%', wipTurnComp: smWipTurnComp + '%', csTurnComp: smCsTurnComp + '%' },
                        { locationCode: txCode, dateCreated: dateFormatted, recWip: txArrived, recWipCompliant: txArrivedCompliant, basicWip: txBasicWip, basicWipCompliant: txBasicWipCompliant, techAuditWip: txTechAuditWip, techAuditWipCompliant: txTechAuditWipCompliant, verification: txVerification, verificationCompliant: txVerificationCompliant, wipTurnoutRequired: txWipTurnoutRequired, wipTurnoutCompliant: txWipTurnoutCompliant, sales: txSales, salesCompliant: txSalesCompliant, recComp: txRecComp + '%', baComp: txBaComp + '%', taComp: txTaComp + '%', verComp: txVerComp + '%', wipTurnComp: txWipTurnComp + '%', csTurnComp: txCsTurnComp + '%' },
                        { locationCode: vaCode, dateCreated: dateFormatted, recWip: vaArrived, recWipCompliant: vaArrivedCompliant, basicWip: vaBasicWip, basicWipCompliant: vaBasicWipCompliant, techAuditWip: vaTechAuditWip, techAuditWipCompliant: vaTechAuditWipCompliant, verification: vaVerification, verificationCompliant: vaVerificationCompliant, wipTurnoutRequired: vaWipTurnoutRequired, wipTurnoutCompliant: vaWipTurnoutCompliant, sales: vaSales, salesCompliant: vaSalesCompliant, recComp: vaRecComp + '%', baComp: vaBaComp + '%', taComp: vaTaComp + '%', verComp: vaVerComp + '%', wipTurnComp: vaWipTurnComp + '%', csTurnComp: vaCsTurnComp + '%' },
                        // { locationCode: 'ORGINAL DOC', dateCreated: dateFormatted, recWip: 2, recWipCompliant: 0, basicWip: 12, basicWipCompliant: 8, techAuditWip: 12, techAuditWipCompliant: 10, verification: 14, verificationCompliant: 8, wipTurnoutRequired: 68, wipTurnoutCompliant: 53, sales: 100, salesCompliant: 59, recComp: 0 + '%', baComp: 67 + '%', taComp: 83 + '%', verComp: 57 + '%', wipTurnComp: 78 + '%', csTurnComp: 59 + '%' }
                    ];

                    setOutputMatrics({
                        ...outputMatrics,
                        data: dataResult
                    })
                    // console.log('boston', boston)
                    // console.log('md', md)
                    // console.log('sac', sac)
                    // console.log('sm', sm)
                    // console.log('tx', tx)
                    // console.log('va', va)
                    // console.log('total', boston + md + sac + sm + tx + va)
                    searchDataTable.style.display = 'block';
                    searchProgress.style.visibility = 'hidden';
                })
        }
        else {
            setMetrics({
                ...metrics,
                searchDate: new Date()
            })
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Invalid date. Please select date.',
            })
        }
    }

    return (
        <div>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <h5>Please upload data in CSV format. You can download template by clicking</h5>
                <button className={classes.buttonMargin} onClick={handleDownloadTemplate}>Customer SLA Template</button>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <h5>Note: Please make sure all date fields are in "dd-mmm-yy" format.</h5>
            </Grid>
            <div className={classes.dropzone}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}>
                <LinearProgress id='dropProgress' className={classes.dropProgress} />
                <GetAppIcon />
                <p>Drag & Drop CSV file here</p>
                <h4>Or</h4>
                <input type="file" accept=".csv" id="addFiles" className={classes.input} onChange={fileSelect} />
                <label htmlFor="addFiles">
                    <Button
                        className={classes.marginBottom}
                        variant="contained"
                        color="default"
                        component="span"
                        startIcon={<AttachFileIcon />}>
                        Select CSV file
                    </Button>
                </label>
                {/* <button onClick={handleAdding}>Add Metrics Data</button> */}
            </div>

            <Card>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <KeyboardDatePicker
                            margin="normal"
                            id="searchDateUpload"
                            disableFuture
                            // label="From Date:"
                            name="searchDate"
                            format="dd-MMM-yy"
                            // error // makes text red
                            helperText="Please select Date to display data"
                            // required
                            className={classes.datePickerCenter}
                            value={metrics.searchDate}
                            onChange={handleSearchDateData}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
                <LinearProgress id='searchProgress' className={classes.searchProgress} />
                <div className={classes.searchDataTable} id='searchDataTable'>
                    <MaterialTable
                        title={"Data Results for " + moment(metrics.searchDate).format('DD MMMM YYYY')}
                        columns={outputMatrics.columns}
                        data={outputMatrics.data}
                        options={{
                            search: false,
                            fixedColumns: {
                                left: 1,
                                // right: 1
                            },
                            // actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50]
                            actionsColumnIndex: -1, pageSize: 10, pageSizeOptions: [5, 10, 50, outputMatrics.data.length === 0 ? 100 : { value: outputMatrics.data.length, label: 'All' }]
                        }}//, { value: ticketsIT.data.length, label: 'All' }] }}
                    />
                </div>
            </Card>

            {/* <div className={classes.searchDataTable} id='searchDataTable'>
                <MaterialTable
                    title={"Newly added Metrics data"}
                    columns={metrics.columns}
                    data={metrics.data}
                    options={{
                        // actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50]
                        actionsColumnIndex: -1, pageSize: 5, pageSizeOptions: [5, 10, 50, metrics.data.length === 0 ? 100 : { value: metrics.data.length, label: 'All' }]
                    }}//, { value: ticketsIT.data.length, label: 'All' }] }}
                />
            </div> */}

            <Dialog
                open={dialog.open}
                onClose={handleCloseAndCancelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
                {/* <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
                    </DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseAndCancelDialog} color="default" className={classes.buttonMargin}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleUploadDialog} color="primary" className={classes.buttonMargin}>
                        Yes, proceed to upload
                    </Button>
                </DialogActions>
            </Dialog>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    )
}
