import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { Card } from '@material-ui/core';
import moment from 'moment';
import { db } from '../../../config/fbConfig';
import Timeline from 'react-calendar-timeline'
import RefreshIcon from '@material-ui/icons/Refresh';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        marginTop: '30px',
        // marginBottom: '30px'
    },
    centerHeader: {
        textAlign: 'center',
        marginLeft: '48px',
        // marginBottom: '30px'
    },
    marginTopBottom: {
        margin: '30px',
    },
    formCard: {
        padding: '30px',
    },
    buttonRight: {
        float: 'right',
        margin: '35px',
    },
    buttonRightNoMargin: {
        float: 'right',
        // margin: '35px',
    },
    lowerTextfield: {
        marginTop: '25px',
    },
    calendarView: {
        sidebarContent: 'zsdfsdfsdf',
        Width: '220px'
    }
}));
const locations = [
    { value: '', label: '' },
    { value: 'Dublin Hill', label: 'Dublin Hill' },
    { value: 'Glanmire', label: 'Glanmire' },
]
const roomNames = [
    { value: '', label: '' },
    { value: 'VALENTIA Boardroom', label: 'VALENTIA Boardroom' },
    { value: 'GARNISH Room', label: 'GARNISH Room' },
    { value: 'SHERKIN Room', label: 'SHERKIN Room' },
]

export default function ViewRoomBookings() {
    const classes = useStyles();



    const [allBookings, setAllBookings] = useState({
        groups: [
            {
                id: 1,
                title: 'group 1'
            },
            {
                id: 2,
                title: 'group 2'
            },
            // { // all posible options
            //     id: 1,
            //     title: 'group 1',
            //     rightTitle: 'title in the right sidebar',
            //     stackItems: true,
            //     height: 30
            // }
        ],
        items: [
            {
                // start_time: moment().format(),
                // end_time: moment().add(23, 'hour').add(59, 'minute').format()
                id: 1,
                group: 1,
                title: 'item 1',
                start_time: moment().startOf('hour'),
                end_time: moment().startOf('hour').add(1.5, 'hour')
            },
            {
                id: 2,
                group: 2,
                title: 'item 2',
                start_time: moment().add(-0.5, 'hour'),
                end_time: moment().add(0.5, 'hour')
            },
            {
                id: 3,
                group: 1,
                title: 'item 3',
                start_time: moment().add(2, 'hour'),
                end_time: moment().add(3, 'hour')
            },
            // { // all posible options
            //     id: 1,
            //     group: 1,
            //     title: 'Random title',
            //     start_time: 1457902922261,
            //     end_time: 1457902922261 + 86400000,
            //     canMove: true,
            //     canResize: false,
            //     canChangeGroup: false,
            //     itemProps: {
            //         // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
            //         'data-custom-attribute': 'Random content',
            //         'aria-hidden': true,
            //         onDoubleClick: () => { console.log('You clicked double!') },
            //         className: 'weekend',
            //         style: {
            //             background: 'fuchsia'
            //         }
            //     }
            // }
        ],
        defaultTimeStart: moment().startOf('day'),
        defaultTimeEnd: moment().endOf('day'),
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function calculateCalendarVars(data) {
        var roomsGroup = [];
        var roomItems = [];
        var color = '';
        var borderColor = '';
        // console.log(data)
        data.forEach(booking => {
            const roomExists = roomsGroup.some(room => room.id === booking.RoomName)
            if (!roomExists) {
                roomsGroup.push({
                    id: booking.RoomName,
                    title: booking.RoomName,
                })
            }
            switch (booking.RoomName) {
                case 'VALENTIA Boardroom':
                    color = 'rgb(170, 150, 230)' // violet
                    borderColor = 'rgb(120, 110, 170)' // darker violet
                    break;
                case 'GARNISH Room':
                    color = 'rgb(95, 190, 125)'  // green
                    borderColor = 'rgb(80, 160, 100)' // darker green
                    break;
                case 'SHERKIN Room':
                    color = 'rgb(230, 140, 180)' // pink
                    borderColor = 'rgb(180, 110, 140)' // darker pink
                    break;
                // case 'GlanmireRoom1':
                //     color = 'rgb(190, 30, 20)' // red
                //     borderColor = 'rgb(145, 20, 15)' // darker red
                //     break;

                default:
                    color = 'rgb(35, 150, 245)' // blue
                    borderColor = 'rgb(25, 110, 180)' // darker blue
                    break;
            }
            roomItems.push({
                id: booking.id,
                group: booking.RoomName,
                title: booking.Host + ' - ' + booking.Subject,
                start_time: moment(booking.DateAndTime),
                end_time: moment(booking.DateAndTime).add(booking.Duration, 'minutes'),
                canMove: false,
                canResize: false,
                canSelect: false,
                canChangeGroup: false,
                itemProps: {
                    style: {
                        // color: color,
                        background: color,
                        borderColor: borderColor,
                        paddingLeft: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }
                }
            })
        })
        setAllBookings({
            ...allBookings,
            groups: roomsGroup.sort((a, b) => (a.id > b.id) ? 1 : -1),// sort rooms alphabetically
            items: roomItems
        })
    }

    const getAllBookings = () => {
        var today = new Date(moment(new Date()).startOf('day'))
        db.collection('roomBookings')
            .where('DateAndTime', '>=', today)
            .orderBy('DateAndTime', 'desc')
            .get()
            .then(snapshot => {
                var allBookings = []
                snapshot.forEach(doc => {
                    var isUpdatedDate = doc.data().dateUpdated === "" ? "" : moment(doc.data().dateUpdated.toDate()).format('DD MMMM YYYY hh:mm a')
                    // var duration = doc.data().Duration > 60 ? (Math.round((doc.data().Duration / 60) * 10) / 10) + ' hours' : doc.data().Duration + ' minutes' // to have one decimal
                    allBookings.push({
                        ...doc.data(),
                        id: doc.id,
                        DateAndTime: moment(doc.data().DateAndTime.toDate()).format('DD MMM YYYY hh:mm a'),
                        dateCreated: moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY hh:mm a'),
                        dateUpdated: isUpdatedDate,
                        // Duration: duration
                    })
                })
                calculateCalendarVars(allBookings)
            })
            .catch(function (error) {
                console.log("Error getting Room Bookings: ", error);
            })
    }

    const timerRef = useRef()

    function startTime() {
        const today = new Date();
        let h = today.getHours();
        let m = today.getMinutes();
        let s = today.getSeconds();
        m = checkTime(m);
        s = checkTime(s);
        var clock = document.getElementById('clock');
        if (clock != null) {
            document.getElementById('clock').innerHTML = h + ":" + m + ":" + s;
            timerRef.current = setTimeout(startTime, 1000);
        }
    }

    function checkTime(i) {
        if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
        return i;
    }

    useEffect(() => {
        getAllBookings()
        startTime()
        return () => {
            clearTimeout(timerRef.current)
        }
    }, [])

    return (
        <div>
            {/* <h1>Room Bookings - Add</h1> */}
            <Card>
                <h3 className={classes.centerHeader}>Current Bookings
                    {<Tooltip className={classes.buttonRightNoMargin} title="Refresh Data">
                        <IconButton onClick={getAllBookings} aria-label="Refresh Data">
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>}
                </h3>
                {/* bookingRoomsClock is on index.css */}
                <div ref={timerRef} id='clock' className='bookingRoomsClock'></div>
                <Timeline
                    sidebarWidth={200}
                    groups={allBookings.groups}
                    items={allBookings.items}
                    defaultTimeStart={allBookings.defaultTimeStart}
                    defaultTimeEnd={allBookings.defaultTimeEnd}
                />
            </Card>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    );
}