import React, { useState, useEffect, useContext } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { db } from '../../../../config/fbConfig';
import { InputBase, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { AuthContext } from '../../../../contexts/AuthContext';
import TicketEngineeringDetails from './TicketEngineeringDetails';

const useStyles = makeStyles(theme => ({
    leftMargin: {
        marginLeft: '30px',
    },
}))

export default function TicketsEngineering() {
    const classes = useStyles();

    const { firstName, username } = useContext(AuthContext);

    const [ticketsEngineering, setTicketsEngineering] = useState({
        columns: [
            { title: 'Status', field: 'Status', editable: 'never' },
            { title: 'Ticket Type', field: 'TicketType', editable: 'never' }, //filtering: false
            { title: 'Division', field: 'Division', editable: 'never' },
            { title: 'Category', field: 'Category', editable: 'never' },
            { title: 'Action', field: 'Action', editable: 'never' },
            { title: 'Process', field: 'Process', editable: 'never' },
            { title: 'Site', field: 'Site', editable: 'never' },
            { title: 'Assigned Eng', field: 'AssignedEng', editable: 'never' },
            { title: 'Raised By', field: 'RaisedBy', editable: 'never' },
            { title: 'Raised Date (yyyy/mm/dd)', field: 'DateRaised', type: 'date', editable: 'never', defaultSort: 'desc' },
            { title: 'Week', field: 'WeekNo', editable: 'never' },
            { title: 'Record UID', field: 'RecordUID', editable: 'never', filtering: false },
            {
                title: 'Description', field: 'Description', editable: 'never', render: rowData =>
                    <InputBase readOnly multiline maxRows={4} value={rowData.Description} style={{ minWidth: 250, fontSize: '1em' }} />,
                // editComponent: props => //console.log(props.rowData)
                //     <TextField multiline maxRows={6}
                //         value={props.rowData.Description}
                //         onChange={(e) => { props.onChange(e.target.value) }}
                //         style={{ minWidth: 400 }} inputProps={{ maxLength: 1000 }} />
            },
        ],
        data: [],
        openTickets: 0,
        closedTickets: 0,
    });

    const allTicketsEngineering = [];
    const allOpenTicketsEngineering = [];
    const allClosedTicketsEngineering = [];

    const getAllEngTickets = () => {
        db.collection('ticketsEngineering')
            // .where('Status', 'in', ['Open', 'WIP - Scope', 'WIP - Dev', 'WIP - UAT', 'WIP - Production Cut in', 'On Hold', 'Acknowledge'])
            .orderBy('DateRaised', 'desc')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    allTicketsEngineering.push({
                        ...doc.data(),
                        RecordUID: doc.id,
                        DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                        // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                    });
                    if (doc.data().Status === 'Open' || doc.data().Status === 'WIP' || doc.data().Status === 'On Hold') {
                        allOpenTicketsEngineering.push({
                            ...doc.data(),
                            RecordUID: doc.id,
                            DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                            // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                        });
                    }
                    if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                        allClosedTicketsEngineering.push({
                            ...doc.data(),
                            RecordUID: doc.id,
                            DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                            ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                        });
                    }
                })
                setTicketsEngineering({
                    ...ticketsEngineering,
                    data: allTicketsEngineering,
                    openTickets: allOpenTicketsEngineering.length,
                    closedTickets: allClosedTicketsEngineering.length,
                })
            })
            .catch(function (error) {
                console.log("Error getting Engineering tickets: ", error);
            });
    }

    useEffect(() => {
        getAllEngTickets()
        return () => { }
    }, []);

    return (
        <div >

            <MaterialTable
                title={"Engineering Tickets"}
                columns={ticketsEngineering.columns}
                data={ticketsEngineering.data}
                detailPanel={rowData => {
                    rowData.username = username; // assigning username for email purpose
                    // console.log(rowData)
                    return (
                        <TicketEngineeringDetails ticketData={rowData} />
                    )
                }}
                options={{
                    thirdSortClick: false, detailPanelType: "single", actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50, ticketsEngineering.data.length === 0 ? 100 : { value: ticketsEngineering.data.length, label: 'All' }]
                }}//, { value: ticketsEngineering.data.length, label: 'All' }] }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                actions={[{
                    icon: 'refresh',
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () => {
                        getAllEngTickets()
                        return () => { }
                    },
                }]}

                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div >
                                <p className={classes.leftMargin}>Hey {firstName}! We need to focus on those {ticketsEngineering.openTickets} open tickets.</p>
                            </div>
                        </div>
                    ),
                }}
            />
        </div>
    );
}
