import React, { useState, useContext, useEffect } from 'react'
import { TextField, makeStyles, Button, MenuItem, Grid, List, Tooltip, ListItem, ListItemText, InputAdornment, IconButton } from '@material-ui/core'
import { DataContext } from '../../../../contexts/DataContext'
import { functions, auth } from '../../../../config/fbConfig';
import moment from 'moment';
import { SimpleModal } from '../../ModalSnackbar/SimpleModal';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    minWidth: {
        minWidth: '200px'
    },
    body: {
        padding: '50px'
    },
    hidden: {
        display: 'none'
    },
    myForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '35ch',
        },
        paddingLeft: '35px',
        paddingTop: '35px',
        paddingRight: '50px',
        paddingBottom: '35px',
    },
    itAttachments: {
        margin: 24
    },
    marginLeft: {
        marginLeft: 24
    },
    buttonLeft: {
        float: 'left',
        marginLeft: '24px',
        marginTop: '24px',
        marginBottom: '35px',

    },
    buttonRight: {
        float: 'right',
        marginTop: '24px',
        marginBottom: '35px',

    },
    input: {
        display: 'none',
    },
    CardRoot: {
        width: 200,
        margin: '10px',
    },
    CardMedia: {
        height: 100,
        backgroundSize: 'contain',
    },
    marginRight: {
        marginRight: '15px'
    },
    attachments: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)'
        },
        height: '35px',
        background: '#5E89B4',
        color: 'white',
        borderRadius: '5px',
    },
    attList: {
        paddingLeft: '8px',
        paddingRight: '8px',
    }
}))

const TicketsITDetails = (props) => {
    const classes = useStyles()
    // console.log(props)
    const { updateTicket, addItAttachmentToBucketAndTicket, removeItAttachmentFromBucketAndTicket } = useContext(DataContext);

    const itTicketStatus = [
        { value: '', label: '' },
        { value: 'Open', label: 'Open' },
        { value: 'WIP - Scope', label: 'WIP - Scope' },
        { value: 'WIP - Dev', label: 'WIP - Dev' },
        { value: 'WIP - UAT', label: 'WIP - UAT' },
        { value: 'WIP - Production Cut in', label: 'WIP - Production Cut in' },
        { value: 'On Hold', label: 'On Hold' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Closed - Not Needed', label: 'Closed - Not Needed' },
        { value: 'Aknowledge', label: 'Aknowledge' },
    ];
    const assignedITs = [
        { value: '', label: '' },
        // { value: 'Claire Leonard', label: 'Claire Leonard' },
        { value: 'Darren O\'Hara', label: 'Darren O\'Hara' },
        { value: 'Eddie Galvin', label: 'Eddie Galvin' },
        { value: 'James O\'Sullivan', label: 'James O\'Sullivan' },
        { value: 'John-Paul Collins', label: 'John-Paul Collins' },
        { value: 'Komlan Djibrine', label: 'Komlan Djibrine' },
        // { value: 'Nick Micciuli', label: 'Nick Micciulli' },
        // { value: 'Michael Sheehan', label: 'Michael Sheehan' },
        { value: 'Pawel Gajewski', label: 'Pawel Gajewski' },
        { value: 'Rui Correia', label: 'Rui Correia' },
    ];
    const itRootCauseCategories = [
        { value: '', label: '' },
        { value: 'Training Gap', label: 'Training Gap' },
        { value: 'Documentation Gap', label: 'Documentation Gap' },
        { value: 'Production cutin not completed', label: 'Production cutin not completed' },
        { value: 'Process Gap', label: 'Process Gap' },
        { value: 'New Implementation UAT miss', label: 'New Implementation UAT miss' },
        { value: 'User Permission Set/Access setup', label: 'User Permission Set/Access setup' },
        { value: 'Development bug', label: 'Development bug' },
        { value: 'Reporting bug', label: 'Reporting bug' },
        { value: 'System Gap', label: 'System Gap' },
        { value: 'Hardware Component Issue', label: 'Hardware Component Issue' },
        { value: 'Hardware Driver/Software Issue', label: 'Hardware Driver/Software Issue' },
        { value: 'Hardware User Damage', label: 'Hardware User Damage' },
        { value: 'User Knowledge Gap', label: 'User Knowledge Gap' },
    ];
    const itFixTypes = [
        { value: '', label: '' },
        { value: 'System CI Required', label: 'System CI Required' },
        { value: 'Process CI Required', label: 'Process CI Required' },
        { value: 'New Report Required', label: 'New Report Required' },
        { value: 'No Fix Required', label: 'No Fix Required' },
        { value: 'Debug', label: 'Debug' },
        { value: 'Training Required', label: 'Training Required' },
        { value: 'Update Documentation', label: 'Update Documentation' },
        { value: 'Repair/Replace hardware', label: 'Repair/Replace hardware' },
        { value: 'Fixed Data & Post', label: 'Fixed Data & Post' },
        { value: 'Updated Access', label: 'Updated Access' },
    ];
    const itFailureCategories = [
        { value: '', label: '' },
        { value: 'TA/Delete Request', label: 'TA/Delete Request' },
        { value: 'BA/Delete Request', label: 'BA/Delete Request' },
        // { value: 'End-User', label: 'End-User' },
        // { value: 'System Issue', label: 'System Issue' },
        // { value: 'Process Issue', label: 'Process Issue' },
        // { value: 'No Issue Found', label: 'No Issue Found' },
        // { value: 'System Feature Not Implemented', label: 'System Feature Not Implemented' },
        // { value: 'Reporting Issue', label: 'Reporting Issue' },
        // { value: 'Report not implemented', label: 'Report not implemented' },
        // { value: 'Hardware malfunction', label: 'Hardware malfunction' },
    ];

    const [editValues, setEditValues] = useState({
        // issue error and change request values
        TicketType: '',
        RecordUID: '',
        Status: '',
        AssignedIt: '',
        RootCauseCategory: '',
        FixType: '',
        FailureCategory: '',
        FixUpdateDescription: '',
        FilesURLs: [],
        moreThanOnePlatform: [],

        itFilesURLs: [],
        itFiles: [],
        itFilesSize: 0,
        itFilesToStoreInDB: [],
    })

    useEffect(() => {
        // console.log('props.ticketData.itFilesURLs', props.ticketData.itFilesURLs)
        setEditValues({
            ...editValues,
            TicketType: props.ticketData.TicketType,
            RecordUID: props.ticketData.RecordUID,
            Status: props.ticketData.Status,
            AssignedIt: props.ticketData.AssignedIt,
            RootCauseCategory: props.ticketData.RootCauseCategory,
            FixType: props.ticketData.FixType,
            FailureCategory: props.ticketData.FailureCategory,
            FixUpdateDescription: props.ticketData.FixUpdateDescription,
            FilesURLs: props.ticketData.FilesURLs === undefined ? [] : props.ticketData.FilesURLs,
            itFilesURLs: props.ticketData.itFilesURLs === undefined ? [] : props.ticketData.itFilesURLs,
            moreThanOnePlatform: props.ticketData.moreThanOnePlatform === undefined ? [] : props.ticketData.moreThanOnePlatform,
        })
    }, [props.ticketData])

    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const [modal, setModal] = useState({
        open: false,
        url: ''
    });
    const displayModal = (urlToDisplay) => {
        // console.log(urlToDisplay)
        setModal({
            open: true,
            url: urlToDisplay,
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log('Updating Ticket')
        // console.log(editValues)
        updateTicket(editValues) // changed from props.ticketData
            .then(success => {
                //display success
                // console.log('back from db with success')
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                })
                setEditValues({
                    ...editValues,
                    itFiles: [],
                    itFilesSize: 0,
                    itFilesToStoreInDB: [],
                })
            })
            .catch(error => {
                //display error
                // console.log('back from db with error')
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                })
            })
    };

    function sendTicketResponse() {
        if (editValues.TicketType == 'Issue / Error') {
            if (editValues.Status == '' ||
                editValues.AssignedIt == '' ||
                editValues.RootCauseCategory == '' ||
                editValues.FixType == '' ||
                // editValues.FailureCategory == '' ||
                editValues.FixUpdateDescription == '') {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: '* Please fill in all required fields to send response.',
                })
            }
            else {
                const callable = functions.httpsCallable('sendTicketResponse');
                return callable(props.ticketData, auth)
                    .then(
                        setSnackbar({
                            open: true,
                            severity: 'success',
                            message: 'Respond sent successfully. User will be notified. Thank you.',
                        })
                    )
            }
        }
        else { //change request
            if (editValues.Status == '' ||
                editValues.AssignedIt == '' ||
                editValues.FixUpdateDescription == '') {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: '* Please fill in all required fields to send response.',
                })
            }
            else {
                const callable = functions.httpsCallable('sendTicketResponse');
                return callable(props.ticketData, auth)
                    .then(
                        setSnackbar({
                            open: true,
                            severity: 'success',
                            message: 'Respond sent successfully. User will be notified. Thank you.',
                        })
                    )
            }
        }
    }

    const handleChange = (e) => {
        props.ticketData[e.target.name] = e.target.value
        setEditValues({
            ...editValues,
            [e.target.name]: e.target.value
        })
    };
    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        // var attachments = e.target.files;
        // var filesToSend = [e.target.files[0]];
        // const filesToSend = [...files];
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...editValues.itFiles];
        var filesToStore = [...editValues.itFilesToStoreInDB];
        [...files].forEach(file => {
            console.log(file.type)
            if (file.type.toLowerCase().includes("audio/") ||
                file.type.toLowerCase().includes("video/") ||
                file.type.toLowerCase().includes("image/") ||
                file.type.toLowerCase().includes("application/pdf") ||
                file.type.toLowerCase().includes("application/vnd") || //for all office documents
                file.type.toLowerCase().includes("text/")) { //text/csv text/hml
                // storage
                //     .ref('ticketAttachments/' + '01Hk2AQqOOoHjy2LBXql/' + file.name)
                //     .put(file)
                // console.log(file)
                filesToStore.push(file)
                var convertResult = ''
                calculatedSize += file.size

                toBase64(file)
                    .then(result => {
                        convertResult = result
                        filesToSend.push({
                            content: convertResult,
                            filename: file.name,
                            type: file.type,
                            disposition: "attachment"
                        })
                        // console.log(calculatedSize)
                        if (editValues.itFilesSize >= maxSize || calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                        }
                        else {
                            if (editValues.itFilesSize + calculatedSize >= maxSize) {
                                setSnackbar({
                                    open: true,
                                    severity: 'warning',
                                    message: 'Selected files are too big to attach.',
                                })
                            }
                            else {
                                setEditValues({
                                    ...editValues,
                                    itFiles: filesToSend,
                                    itFilesSize: editValues.itFilesSize + calculatedSize,
                                    itFilesToStoreInDB: filesToStore,
                                });
                                // console.log(filesToSend)
                                handleAddItAttachment(filesToStore[0])
                            }
                        }
                    })
            } else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'File type not supported, please attach supported file: "audio/*, video/*, image/*, .pdf, .xlsx, .xls, .csv"',
                });
            }
        })
    }
    const handleFilePickerClear = (e) => {
        console.log('removing files')
        setEditValues({
            ...editValues,
            itFiles: [],
            itFilesSize: 0,
            itFilesToStoreInDB: [],
        });
    }

    const handleAddItAttachment = (file) => {
        // console.log('adding', file)
        var newItFilesURLs = editValues.itFilesURLs

        addItAttachmentToBucketAndTicket(file, editValues.RecordUID, newItFilesURLs)
            .then(succ => {
                // console.log(succ)
                setEditValues({
                    ...editValues,
                    itFilesURLs: succ.newItFilesURLs
                })
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: succ.message,
                });
            })
            .catch(err => {
                // console.log(`Unable to remove attachment: Code:'${err.code_}' Message: ${err.message}`)
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: `Unable to remove attachment: Code:'${err.code_}' Message: ${err.message}`,
                });
            })
    }
    const handleRemoveItAttachment = (file, index) => {
        // console.log('removing', file, 'position', index)
        var newItFilesURLs = editValues.itFilesURLs
        newItFilesURLs.splice(index, 1)
        setEditValues({
            ...editValues,
            itFilesURLs: newItFilesURLs
        })
        // console.log(newItFilesURLs)
        removeItAttachmentFromBucketAndTicket(file.url, editValues.RecordUID, newItFilesURLs)
            .then(succ => {
                // console.log(succ)
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: succ,
                });
            })
            .catch(err => {
                // console.log(`Unable to remove attachment: Code:'${err.code_}' Message: ${err.message}`)
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: `Unable to remove attachment: Code:'${err.code_}' Message: ${err.message}`,
                });
            })
    }

    const handleDownload = async (e, file) => {
        e.preventDefault();
        const response = await fetch(file.url);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }

    if (props.ticketData.TicketType == 'Issue / Error') {
        return (
            <div>

                <form className={classes.myForm} noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <TextField name="IssueCategory" label="Issue Category" variant="outlined" value={props.ticketData.IssueCategory} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField name="Platform" label="Platform" variant="outlined" value={props.ticketData.Platform} disabled size="small" InputLabelProps={{ shrink: true }} />
                    {props.ticketData.Platform === 'More then one selected:' && props.ticketData.moreThanOnePlatform.map(item => (
                        <TextField name="Platform" label="Platform selected" variant="outlined" value={item} disabled size="small" InputLabelProps={{ shrink: true }} />
                    ))}
                    <TextField name="NavErrorNo" label="Nav Error No" fullWidth multiline minRows={6} variant="outlined" value={props.ticketData.NavErrorNo} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField name="Site" label="Site" variant="outlined" value={props.ticketData.Site} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField name="Department" label="Department" variant="outlined" value={props.ticketData.Department} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField name="Process" label="Process" variant="outlined" value={props.ticketData.Process} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField name="Area" label="Area" variant="outlined" value={props.ticketData.Area} disabled size="small" InputLabelProps={{ shrink: true }} />

                    {editValues.FilesURLs === undefined || editValues.FilesURLs.length === 0 ? null :
                        <Grid container className={classes.marginLeftRight} direction="row" justifyContent="flex-start" alignItems="flex-start">
                            {editValues.FilesURLs.map((file, i) => (
                                <List key={i} className={classes.attList}>
                                    {/* <a href={file.url} download className={classes.attachments}> */}
                                    <Tooltip title="Download">
                                        <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                            <SaveAltIcon className={classes.marginRight} />
                                            <ListItemText primary={file.name} />
                                        </ListItem>
                                    </Tooltip>
                                    {/* </a> */}
                                </List>
                            ))}
                        </Grid>
                    }

                    <TextField name="RootCauseDescription" label="Root Cause Description" fullWidth multiline minRows={12} variant="outlined" value={props.ticketData.RootCauseDescription} disabled size="small" InputLabelProps={{ shrink: true }} />
                    {/* {editValues.AttachmentURLs.map(url => (
                        <img src={url}></img>
                    ))} */}
                    <TextField name="TicketSendFrom" label="Ticket Send From" variant="outlined" value={props.ticketData.TicketSendFrom} disabled size="small" InputLabelProps={{ shrink: true }} />
                    {/* no need to display closed date if ticket is open */}
                    <TextField name="ClosedDate" label="Closed Date" variant="outlined" value={moment(props.ticketData.ClosedDate.toDate()).format('YYYY/MM/DD')} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField
                        select
                        // required
                        name="Status"
                        label="Status"
                        value={editValues.Status}
                        onChange={handleChange}
                        error={editValues.Status === ""}
                        helperText={editValues.Status === "" ? 'Required field to send response.' : ' '}
                        variant="outlined"
                        size='small'
                        InputLabelProps={{ shrink: true }}>
                        {itTicketStatus.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        // required
                        name="AssignedIt"
                        label="Assigned IT"
                        value={editValues.AssignedIt}
                        onChange={handleChange}
                        error={editValues.AssignedIt === ""}
                        helperText={editValues.AssignedIt === "" ? 'Required field to send response.' : ' '}
                        variant="outlined"
                        size='small'
                        InputLabelProps={{ shrink: true }}>
                        {assignedITs.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        // required
                        name="RootCauseCategory"
                        label="Root Cause Category"
                        value={editValues.RootCauseCategory}
                        onChange={handleChange}
                        error={editValues.RootCauseCategory === ""}
                        helperText={editValues.RootCauseCategory === "" ? 'Required field to send response.' : ' '}
                        variant="outlined"
                        size='small'
                        InputLabelProps={{ shrink: true }}>
                        {itRootCauseCategories.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        // required
                        name="FixType"
                        label="Fix Type"
                        value={editValues.FixType}
                        onChange={handleChange}
                        error={editValues.FixType === ""}
                        helperText={editValues.FixType === "" ? 'Required field to send response.' : ' '}
                        variant="outlined"
                        size='small'
                        InputLabelProps={{ shrink: true }}>
                        {itFixTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        // required
                        name="FailureCategory"
                        label="Failure Category"
                        value={editValues.FailureCategory}
                        onChange={handleChange}
                        // error={editValues.FailureCategory === ""}
                        // helperText={editValues.FailureCategory === "" ? 'Required field to send response.' : ' '}
                        variant="outlined"
                        size='small'
                        InputLabelProps={{ shrink: true }}>
                        {itFailureCategories.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField name="FixUpdateDescription" label="Fix Update Description"
                        // required
                        fullWidth
                        multiline
                        minRows={6}
                        variant="outlined"
                        value={editValues.FixUpdateDescription}
                        onChange={handleChange}
                        error={editValues.FixUpdateDescription === ""}
                        helperText={editValues.FixUpdateDescription === "" ? 'Required field to send response.' : ' '}
                        size="small"
                        InputLabelProps={{ shrink: true }} />

                    <div style={{ width: '100%' }}>
                        {editValues.itFilesURLs === undefined || editValues.itFilesURLs.length === 0 ? null :
                            <div>
                                <p className={classes.marginLeft}>IT attachments:</p>
                                <Grid container className={classes.marginLeftRight} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                    {editValues.itFilesURLs.map((file, i) => (
                                        <Grid item key={i} className={classes.attList}>
                                            <Grid container direction='row' justifyContent='center' alignItems='center'>
                                                <Grid item>
                                                    <Tooltip title="Download">
                                                        <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                                            <InputAdornment position="start">
                                                                <SaveAltIcon />
                                                            </InputAdornment>
                                                            <ListItemText primary={file.name} />
                                                        </ListItem>
                                                    </Tooltip></Grid>
                                                <Grid item>
                                                    <Tooltip title='Remove'>
                                                        <IconButton aria-label="delete" color='secondary' className={classes.marginRight} onClick={() => handleRemoveItAttachment(file, i)}>
                                                            <ClearRoundedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        }
                        <div className={classes.itAttachments}>
                            <input
                                accept="audio/*,video/*,image/*,.xlsx,.xls"
                                className={classes.input}
                                id="add-contained-button-file"
                                // multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="add-contained-button-file">
                                <Button
                                    className={classes.marginRight}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add File
                                </Button>
                            </label>
                            <p>*Please note added files will not be sent with the response they are for IT purpose only.</p>
                        </div>
                    </div>

                    <Button
                        type="button"
                        className={classes.buttonLeft}
                        variant="contained"
                        style={{ backgroundColor: 'lightgreen' }}
                        onClick={sendTicketResponse}
                    >
                        Send response
                    </Button>
                    <Button
                        type="submit"
                        className={classes.buttonRight}
                        variant="contained"
                        color="primary"
                    >
                        Update ticket
                    </Button>
                </form>
                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                <SimpleModal modalState={modal} />
            </div >
        )
    }
    else { // request
        return (
            <div>
                <form className={classes.myForm} noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <TextField name="RequestCategory" label="Request Category" variant="outlined" value={props.ticketData.RequestCategory} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField name="Site" label="Site" variant="outlined" value={props.ticketData.Site} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField name="Department" label="Department" variant="outlined" value={props.ticketData.Department} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField name="Process" label="Process" variant="outlined" value={props.ticketData.Process} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField name="Platform" label="Platform" variant="outlined" value={props.ticketData.Platform} disabled size="small" InputLabelProps={{ shrink: true }} />
                    {props.ticketData.Platform === 'More then one selected:' && props.ticketData.moreThanOnePlatform.map(item => (
                        <TextField name="Platform" label="Platform selected" variant="outlined" value={item} disabled size="small" InputLabelProps={{ shrink: true }} />
                    ))}

                    {editValues.FilesURLs.length === undefined || editValues.FilesURLs.length === 0 ? null :
                        <Grid container className={classes.marginLeftRight} direction="row" justifyContent="flex-start" alignItems="flex-start">
                            {editValues.FilesURLs.map((file, i) => (
                                <List key={i} className={classes.attList}>
                                    <Tooltip title="Download">
                                        <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                            <SaveAltIcon className={classes.marginRight} />
                                            <ListItemText primary={file.name} />
                                        </ListItem>
                                    </Tooltip>
                                </List>
                            ))}
                        </Grid>
                    }

                    <TextField name="RootCauseDescription" label="Root Cause Description" fullWidth multiline minRows={12} variant="outlined" value={props.ticketData.RootCauseDescription} disabled size="small" InputLabelProps={{ shrink: true }} />
                    {/* {editValues.AttachmentURLs.map(url => (
                        <img src={url}></img>
                    ))} */}
                    <TextField name="TicketSendFrom" label="Ticket Send From" variant="outlined" value={props.ticketData.TicketSendFrom} disabled size="small" InputLabelProps={{ shrink: true }} />

                    {/* no need to display closed date if ticket is open */}
                    <TextField name="ClosedDate" label="Closed Date" variant="outlined" value={moment(props.ticketData.ClosedDate.toDate()).format('YYYY/MM/DD')} disabled size="small" InputLabelProps={{ shrink: true }} />
                    <TextField
                        select
                        // required
                        name="Status"
                        label="Status"
                        value={editValues.Status}
                        onChange={handleChange}
                        error={editValues.Status === ""}
                        helperText={editValues.Status === "" ? 'Required field to send response.' : ' '}
                        variant="outlined"
                        size='small'
                        InputLabelProps={{ shrink: true }}>
                        {itTicketStatus.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        // required
                        name="AssignedIt"
                        label="Assigned IT"
                        value={editValues.AssignedIt}
                        onChange={handleChange}
                        error={editValues.AssignedIt === ""}
                        helperText={editValues.AssignedIt === "" ? 'Required field to send response.' : ' '}
                        variant="outlined"
                        size='small'
                        InputLabelProps={{ shrink: true }}>
                        {assignedITs.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField name="FixUpdateDescription"
                        label="Fix Update Description"
                        // required 
                        fullWidth
                        multiline
                        minRows={6}
                        variant="outlined"
                        value={editValues.FixUpdateDescription}
                        onChange={handleChange}
                        error={editValues.FixUpdateDescription === ""}
                        helperText={editValues.FixUpdateDescription === "" ? 'Required field to send response.' : ' '}
                        size="small"
                        InputLabelProps={{ shrink: true }} />

                    <div style={{ width: '100%' }}>
                        {editValues.itFilesURLs === undefined || editValues.itFilesURLs.length === 0 ? null :
                            <div>
                                <p className={classes.marginLeft}>IT attachments:</p>
                                <Grid container className={classes.marginLeftRight} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                    {editValues.itFilesURLs.map((file, i) => (
                                        <Grid item key={i} className={classes.attList}>
                                            <Grid container direction='row' justifyContent='center' alignItems='center'>
                                                <Grid item>
                                                    <Tooltip title="Download">
                                                        <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                                            <InputAdornment position="start">
                                                                <SaveAltIcon />
                                                            </InputAdornment>
                                                            <ListItemText primary={file.name} />
                                                        </ListItem>
                                                    </Tooltip></Grid>
                                                <Grid item>
                                                    <Tooltip title='Remove'>
                                                        <IconButton aria-label="delete" color='secondary' className={classes.marginRight} onClick={() => handleRemoveItAttachment(file, i)}>
                                                            <ClearRoundedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        }
                        <div className={classes.itAttachments}>
                            <input
                                accept="audio/*,video/*,image/*,.xlsx,.xls"
                                className={classes.input}
                                id="add-contained-button-file"
                                // multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="add-contained-button-file">
                                <Button
                                    className={classes.marginRight}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add File
                                </Button>
                            </label>
                            <p>*Please note added files will not be sent with the response they are for IT purpose only.</p>
                        </div>
                    </div>

                    <Button
                        type="button"
                        className={classes.buttonLeft}
                        variant="contained"
                        style={{ backgroundColor: 'lightgreen' }}
                        onClick={sendTicketResponse}
                    >
                        Send response
                    </Button>
                    <Button
                        type="submit"
                        className={classes.buttonRight}
                        variant="contained"
                        color="primary"
                    >
                        Update ticket
                    </Button>
                </form>

                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

                <SimpleModal modalState={modal} />
            </div>
        )
    }

}

export default TicketsITDetails