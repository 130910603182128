import React, { useState, useContext, useEffect } from 'react'
import { makeStyles, TextField, MenuItem, Button, Grid, FormControlLabel, Checkbox, } from '@material-ui/core'
import { db } from '../../../../config/fbConfig';
import { DataContext } from '../../../../contexts/DataContext';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Container } from '@material-ui/core';
import { Slider } from '@material-ui/core';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import { AuthContext } from '../../../../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    checkboxGroup: {
        width: '100%',
        textAlign: 'left',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
    },
    buttonRight: {
        float: 'right',
        marginTop: '35px',
        // marginRight: '24px',
        // marginBottom: '24px',
    },
    filePicker: {
        float: 'left',
        marginLeft: '15px',
        marginTop: '35px',
    },
    input: {
        display: 'none',
    },
    marginRight: {
        marginRight: '15px'
    },
    marginLeftRight: {
        marginLeft: '15px',
        marginRight: '40px',
    },

}));

const budgetRanges = [
    { value: '', label: '' },
    { value: '20.000 - 24.999', label: '20.000 - 24.999' },
    { value: '25.000 - 29.999', label: '25.000 - 29.999' },
    { value: '30.000 - 34.999', label: '30.000 - 34.999' },
    { value: '35.000 - 39.999', label: '35.000 - 39.999' },
    { value: '40.000 - 44.999', label: '40.000 - 44.999' },
    { value: '45.000 - 49.999', label: '45.000 - 49.999' },
    { value: '50.000 - 54.999', label: '50.000 - 54.999' },
    { value: '55.000 - 59.999', label: '55.000 - 59.999' },
    { value: '60.000 - 64.999', label: '60.000 - 64.999' },
    { value: '65.000 - 69.999', label: '65.000 - 69.999' },
    { value: '70.000 - 74.999', label: '70.000 - 74.999' },
    { value: '75.000 - 79.999', label: '75.000 - 79.999' },
    { value: '80.000 - 84.999', label: '80.000 - 84.999' },
    { value: '85.000 - 89.999', label: '85.000 - 89.999' },
    { value: '90.000 - 94.999', label: '90.000 - 94.999' },
    { value: '95.000 - 99.999', label: '95.000 - 99.999' },
    { value: '100.000+', label: '100.000+' },
]

const TicketFormHR = (props) => {
    const classes = useStyles();

    const { createHiringRequisition, listDepartments } = useContext(DataContext);
    const { email } = useContext(AuthContext);

    const [recruitment, setRecruitment] = useState({
        HiringManager: '',
        HiringManagerEmail: '',
        Department: '',
        RoleTypeNew: false,
        RoleTypeReplacement: false,
        ReplacementFor: '',
        PleaseProvideDetails: '',
        JobTitle: '',
        ContractTypeFTC: false,
        ContractTypePermanent: false,
        ContractTypeDurationOfFTC: 0,
        JdFinalisedHRYes: false,
        JdFinalisedHRNo: false,
        CopyOfJdAttachedYes: false,
        CopyOfJdAttachedNo: false,
        // BudgetRange: '',
        RoleToBeAdvertisedInternal: false,
        RoleToBeAdvertisedExternal: false,
        RoleToBeAdvertisedBoth: false,
        FirstLevelApproval: '',
        FirstLevelApprovalEmail: '',
        SecondLevelApproval: '',
        SecondLevelApprovalEmail: '',
        CommentsNotes: '',
        dateCreated: '',
        status: 'Created',
        approvedLvl1: false,
        approvedLvl2: false,
        rejectedLvl1: false,
        rejectedLvl2: false,
        rejectedLvl1msg: '',
        rejectedLvl2msg: '',
        files: [],
        filesSize: 0,
        filesToStoreInDB: [],
        FilesURLs: [],
        hiringManagers: [],
        hiringManagersLvl1: [],
        hiringManagersLvl2: [],
        hiringManagersEmails: [],
        listDepartments: [],
    })

    useEffect(() => {
        var hiringManagers = [];
        var hiringManagersLvl1 = [];
        var hiringManagersLvl2 = [];
        var hiringManagersEmails = [];

        db.collection('hiringManager')
            .orderBy("label", "asc").get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    hiringManagers.push({
                        ...doc.data(),
                        id: doc.id
                    });
                    hiringManagersEmails.push(doc.data().email)
                })
                // console.log(hiringManagers)
            })
            .catch(error => {
                console.log("Error getting hiring managers: ", error);
            });
        db.collection('hiringManagerLvl1')
            .orderBy("label", "asc").get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    hiringManagersLvl1.push({
                        ...doc.data(),
                        id: doc.id
                    });
                })
                // console.log(hiringManagersLvl1)
            })
            .catch(error => {
                console.log("Error getting hiring managers Lvl 1: ", error);
            });
        db.collection('hiringManagerLvl2')
            .orderBy("label", "asc").get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    hiringManagersLvl2.push({
                        ...doc.data(),
                        id: doc.id
                    });
                })
                // console.log(hiringManagersLvl2)
                var listWithoutCEO = listDepartments.filter(listItem => listItem.label !== 'CEO')
                setRecruitment({
                    ...recruitment,
                    hiringManagers: hiringManagers,
                    hiringManagersLvl1: hiringManagersLvl1,
                    hiringManagersLvl2: hiringManagersLvl2,
                    hiringManagersEmails: hiringManagersEmails,
                    listDepartments: listWithoutCEO
                })
            })
            .catch(error => {
                console.log("Error getting hiring managers Lvl 2: ", error);
            });
        return () => { }
    }, [])

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleSliderChangeFTC = (e, newValue) => {
        setRecruitment({
            ...recruitment,
            ContractTypeDurationOfFTC: newValue
        });
    };

    function getKeyByValue(array, attr, value) {
        // console.log(array, attr, value)
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return array[i];
            }
        }
    };
    const handleChangeDropdowns = (e) => {
        var manager = {}
        switch (e.target.name) {
            case 'HiringManager':
                manager = getKeyByValue(recruitment.hiringManagers, 'value', e.target.value);
                // console.log(manager)
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.value,
                    HiringManagerEmail: manager.email,
                    approvedLvl1: manager.value === 'Paul Cogan' ? true : false,
                    approvedLvl2: manager.value === 'Tom Delahunty' ? true : false,
                });
                break;
            case 'FirstLevelApproval':
                manager = getKeyByValue(recruitment.hiringManagersLvl1, 'value', e.target.value);
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.value,
                    FirstLevelApprovalEmail: manager.email
                });
                break;
            case 'SecondLevelApproval':
                manager = getKeyByValue(recruitment.hiringManagersLvl2, 'value', e.target.value);
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.value,
                    SecondLevelApprovalEmail: manager.email
                });
                break;

            default:
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.value,
                });
                break;
        }
    }
    const handleChangeTexts = (e) => {
        setRecruitment({
            ...recruitment,
            [e.target.name]: e.target.value,
        })
    }
    const handleChangeCheckboxes = (e) => {
        // console.log(e.target.name, e.target.checked)
        switch (e.target.name) {
            case 'RoleTypeNew':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    RoleTypeReplacement: !e.target.checked,
                })
                break;
            case 'RoleTypeReplacement':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    RoleTypeNew: !e.target.checked,
                })
                break;
            case 'ContractTypeFTC':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    ContractTypePermanent: !e.target.checked,
                })
                break;
            case 'ContractTypePermanent':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    ContractTypeFTC: !e.target.checked,
                    ContractTypeDurationOfFTC: 0
                })
                break;
            case 'JdFinalisedHRYes':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    JdFinalisedHRNo: !e.target.checked,
                })
                break;
            case 'JdFinalisedHRNo':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    JdFinalisedHRYes: !e.target.checked,
                })
                break;
            case 'CopyOfJdAttachedYes':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    CopyOfJdAttachedNo: !e.target.checked,
                })
                break;
            case 'CopyOfJdAttachedNo':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    CopyOfJdAttachedYes: !e.target.checked,
                })
                break;
            case 'RoleToBeAdvertisedInternal':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    RoleToBeAdvertisedExternal: !e.target.checked,
                })
                break;
            case 'RoleToBeAdvertisedExternal':
                setRecruitment({
                    ...recruitment,
                    [e.target.name]: e.target.checked,
                    RoleToBeAdvertisedInternal: !e.target.checked,
                })
                break;

            default:
                break;
        }
    }
    const handleChangeCheckboxesBoth = (e) => {
        // console.log(e.target.checked)
        setRecruitment({
            ...recruitment,
            [e.target.name]: e.target.checked,
            RoleToBeAdvertisedInternal: e.target.checked,
            RoleToBeAdvertisedExternal: e.target.checked,
        })
    }
    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        // var attachments = e.target.files;
        // var filesToSend = [e.target.files[0]];
        // const filesToSend = [...files];
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...recruitment.files];
        var filesToStore = [...recruitment.filesToStoreInDB];
        [...files].forEach(file => {
            if (file.type.toLowerCase().includes("audio/") ||
                file.type.toLowerCase().includes("video/") ||
                file.type.toLowerCase().includes("image/") ||
                file.type.toLowerCase().includes("application/pdf") ||
                file.type.toLowerCase().includes("application/vnd") || //for all office documents
                file.type.toLowerCase().includes("text/")) { //text/csv text/hml
                // storage
                //     .ref('ticketAttachments/' + '01Hk2AQqOOoHjy2LBXql/' + file.name)
                //     .put(file)
                // console.log(file)
                filesToStore.push(file)
                var convertResult = ''
                calculatedSize += file.size

                toBase64(file)
                    .then(result => {
                        convertResult = result
                        filesToSend.push({
                            content: convertResult,
                            filename: file.name,
                            type: file.type,
                            disposition: "attachment"
                        })
                        // console.log(calculatedSize)
                        if (recruitment.filesSize >= maxSize || calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                        }
                        else {
                            if (recruitment.filesSize + calculatedSize >= maxSize) {
                                setSnackbar({
                                    open: true,
                                    severity: 'warning',
                                    message: 'Selected files are too big to attach.',
                                })
                            }
                            else {
                                setRecruitment({
                                    ...recruitment,
                                    files: filesToSend,
                                    filesSize: recruitment.filesSize + calculatedSize,
                                    filesToStoreInDB: filesToStore,
                                });
                                // console.log(filesToSend)
                            }
                        }
                    })
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'File type not supported, please attach supported file: "audio/*, video/*, image/*, .pdf, .xlsx, .xls, .csv"',
                });
            }
        })
        e.target.value = null // to allow selecting the same file over and over
    }
    const handleChangeFilePickerRemove = (e) => {
        setRecruitment({
            ...recruitment,
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
    }
    const ClearTheForm = (e) => {
        setRecruitment({
            ...recruitment,
            HiringManager: '',
            HiringManagerEmail: '',
            Department: '',
            RoleTypeNew: false,
            RoleTypeReplacement: false,
            ReplacementFor: '',
            PleaseProvideDetails: '',
            JobTitle: '',
            ContractTypeFTC: false,
            ContractTypePermanent: false,
            ContractTypeDurationOfFTC: 0,
            CopyOfJdAttachedYes: false,
            CopyOfJdAttachedNo: false,
            JdFinalisedHRYes: false,
            JdFinalisedHRNo: false,
            // BudgetRange: '',
            RoleToBeAdvertisedInternal: false,
            RoleToBeAdvertisedExternal: false,
            RoleToBeAdvertisedBoth: false,
            FirstLevelApproval: '',
            FirstLevelApprovalEmail: '',
            SecondLevelApproval: '',
            SecondLevelApprovalEmail: '',
            CommentsNotes: '',
            dateCreated: '',
            approvedLvl1: false,
            approvedLvl2: false,
            rejectedLvl1: false,
            rejectedLvl2: false,
            rejectedLvl1msg: '',
            rejectedLvl2msg: '',
            status: 'Created',
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
            FilesURLs: [],
        })
    }

    var isSubmitting = false
    const handleSubmit = (e) => {
        e.preventDefault();
        // Validation
        if (recruitment.HiringManager !== '' && !recruitment.hiringManagersEmails.includes(email.toLowerCase())) {
            // console.log(recruitment.hiringManagersEmails, email.toLowerCase())
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Only Hiring Manager is allowed to create new Recruitment Requisition.',
            })
            return
        }
        if (recruitment.HiringManager === '') {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Hiring Manager is not selected. Please select Hiring Manager.',
            })
            return
        }
        if (recruitment.Department === '') {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Department is not selected. Please select Department.',
            })
            return
        }
        if (!recruitment.RoleTypeNew && !recruitment.RoleTypeReplacement) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Role Type not selected. Please select Role Type.',
            })
            return
        }
        if (recruitment.RoleTypeReplacement && recruitment.ReplacementFor == '') {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: '"Replacement For" info not provided. Please fill in "Replacement For" field.',
            })
            return
        }
        if (recruitment.PleaseProvideDetails === '') {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please provide details "Justification" field is empty.',
            })
            return
        }
        if (recruitment.JobTitle === '') {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Job Title not provided. Please provide Job Title.',
            })
            return
        }
        if (!recruitment.ContractTypeFTC && !recruitment.ContractTypePermanent) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Contract Type not selected. Please select Contract Type.',
            })
            return
        }
        if (recruitment.ContractTypeFTC && recruitment.ContractTypeDurationOfFTC === 0) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Fixed Term Contract selected, but length not provided. Please provide length of the contract.',
            })
            return
        }
        if (!recruitment.CopyOfJdAttachedYes && !recruitment.CopyOfJdAttachedNo) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: '"Copy of job description attached?" Nothing selected. Please select at least one option.',
            })
            return
        }
        if (recruitment.CopyOfJdAttachedYes && recruitment.files.length === 0 || recruitment.CopyOfJdAttachedNo) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: '"Copy of job description attached?" is required. Please attach job description and change the checkbox to "Yes".',
            })
            return
        }
        // if (recruitment.BudgetRange === '') {
        //     setSnackbar({
        //         open: true,
        //         severity: 'warning',
        //         message: 'Budget range not selected.',
        //     })
        //     return
        // }
        if (!recruitment.RoleToBeAdvertisedInternal && !recruitment.RoleToBeAdvertisedExternal) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: '"Role to be advertised?" Nothing selected. Please select at least one option.',
            })
            return
        }
        if (recruitment.FirstLevelApproval === '') {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'First level approving manager not selected.',
            })
            return
        }
        if (recruitment.SecondLevelApproval === '') {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Second level approving manager not selected.',
            })
            return
        }
        if (!isSubmitting) {
            isSubmitting = true
            createHiringRequisition(recruitment)
                .then(success => {
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: success,
                    });
                    ClearTheForm();
                    isSubmitting = false
                })
                .catch(error => {
                    // console.log(error)                 
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: error,
                    });
                    isSubmitting = false
                })
        }
    }

    return (
        <div>

            <form id="recruitment" onSubmit={handleSubmit} autoComplete="off">
                <h3 className={classes.center}>Create New Recruitment Requisition</h3>

                <Container maxwidth="xs">

                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                // required
                                fullWidth
                                id="HiringManager"
                                label="Hiring Manager *"
                                name="HiringManager"
                                value={recruitment.HiringManager}
                                onChange={handleChangeDropdowns}>
                                {recruitment.hiringManagers.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                // required
                                fullWidth
                                id="Department"
                                label="Department *"
                                name="Department"
                                value={recruitment.Department}
                                onChange={handleChangeDropdowns}>
                                {recruitment.listDepartments.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                                <Grid container item xs={4} className={classes.marginLeftRight}>
                                    <p>Role Type *</p>
                                </Grid>
                                <Grid container item xs={4} sm={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.RoleTypeNew} onChange={handleChangeCheckboxes} name="RoleTypeNew" color="primary" />}
                                        label="New"
                                    />
                                </Grid>
                                <Grid container item xs={4} sm={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.RoleTypeReplacement} onChange={handleChangeCheckboxes} name="RoleTypeReplacement" color="primary" />}
                                        label="Replacement"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {recruitment.RoleTypeReplacement ?
                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    // required
                                    fullWidth
                                    id="ReplacementFor"
                                    label="Replacement For *"
                                    name="ReplacementFor"
                                    value={recruitment.ReplacementFor}
                                    inputProps={{ maxLength: 100 }}
                                    onChange={handleChangeTexts}   >
                                </TextField>
                            </Grid>
                            : null}
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <TextField
                                size="small"
                                variant="outlined"
                                multiline
                                rows="4"
                                // required
                                fullWidth
                                id="PleaseProvideDetails"
                                label="Justification *"
                                name="PleaseProvideDetails"
                                value={recruitment.PleaseProvideDetails}
                                inputProps={{ maxLength: 500 }}
                                onChange={handleChangeTexts}   >
                            </TextField>
                        </Grid>
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <TextField
                                size="small"
                                variant="outlined"
                                // required
                                fullWidth
                                id="JobTitle"
                                label="Job Title *"
                                name="JobTitle"
                                value={recruitment.JobTitle}
                                inputProps={{ maxLength: 100 }}
                                onChange={handleChangeTexts}   >
                            </TextField>
                        </Grid>
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                                <Grid container item xs={4} className={classes.marginLeftRight}>
                                    <p>Contract Type *</p>
                                </Grid>
                                <Grid container item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.ContractTypeFTC} onChange={handleChangeCheckboxes} name="ContractTypeFTC" color="primary" />}
                                        label="Fixed Term"
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.ContractTypePermanent} onChange={handleChangeCheckboxes} name="ContractTypePermanent" color="primary" />}
                                        label="Permanent"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {recruitment.ContractTypeFTC ?
                            <Grid container item xs={12} sm={10} spacing={1} >
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                                    <Grid container item xs={4} className={classes.marginLeftRight}>
                                        <p>Fixed term contract length? (in months) *</p>
                                    </Grid>
                                    <Grid container item xs={12} sm={6}>
                                        <Slider
                                            value={recruitment.ContractTypeDurationOfFTC}
                                            onChange={handleSliderChangeFTC}
                                            step={1}
                                            min={0}
                                            max={24}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="ftcDurationSlider"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null}
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                                <Grid container item xs={4} className={classes.marginLeftRight}>
                                    <p>Job description finalised with HR?? *</p>
                                </Grid>
                                <Grid container item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.JdFinalisedHRYes} onChange={handleChangeCheckboxes} name="JdFinalisedHRYes" color="primary" />}
                                        label="Yes"
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.JdFinalisedHRNo} onChange={handleChangeCheckboxes} name="JdFinalisedHRNo" color="primary" />}
                                        label="No"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                                <Grid container item xs={4} className={classes.marginLeftRight}>
                                    <p>Copy of job description attached? *</p>
                                </Grid>
                                <Grid container item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.CopyOfJdAttachedYes} onChange={handleChangeCheckboxes} name="CopyOfJdAttachedYes" color="primary" />}
                                        label="Yes"
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.CopyOfJdAttachedNo} onChange={handleChangeCheckboxes} name="CopyOfJdAttachedNo" color="primary" />}
                                        label="No"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid container item xs={12} sm={10} spacing={1}>
                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                // required
                                fullWidth
                                id="BudgetRange"
                                label="Budget Range *"
                                name="BudgetRange"
                                value={recruitment.BudgetRange}
                                onChange={handleChangeDropdowns}>
                                {budgetRanges.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid> */}
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                                <Grid container item xs={4} className={classes.marginLeftRight}>
                                    <p>Role to be advertised *</p>
                                </Grid>
                                <Grid container item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.RoleToBeAdvertisedInternal} onChange={handleChangeCheckboxes} name="RoleToBeAdvertisedInternal" color="primary" />}
                                        label="Internal"
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.RoleToBeAdvertisedExternal} onChange={handleChangeCheckboxes} name="RoleToBeAdvertisedExternal" color="primary" />}
                                        label="External"
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={recruitment.RoleToBeAdvertisedBoth} onChange={handleChangeCheckboxesBoth} name="RoleToBeAdvertisedBoth" color="primary" />}
                                        label="Both"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={5} spacing={1}>
                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                // required
                                fullWidth
                                id="FirstLevelApproval"
                                label="First Level Approving Manager *"
                                name="FirstLevelApproval"
                                value={recruitment.FirstLevelApproval}
                                onChange={handleChangeDropdowns}   >
                                {recruitment.hiringManagersLvl1.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid container item xs={12} sm={5} spacing={1}>
                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                // required
                                fullWidth
                                id="SecondLevelApproval"
                                label="Second Level Approving Manager *"
                                name="SecondLevelApproval"
                                value={recruitment.SecondLevelApproval}
                                onChange={handleChangeDropdowns}   >
                                {recruitment.hiringManagersLvl2.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <TextField
                                name="CommentsNotes"
                                fullWidth
                                // required
                                multiline
                                rows="4"
                                label="Comments / Notes"
                                variant="outlined"
                                onChange={handleChangeTexts}
                                value={recruitment.CommentsNotes}
                                inputProps={{ maxLength: 500 }}
                            />
                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        className={classes.buttonRight}
                        variant="contained"
                        color="primary">
                        Create ticket & send email
                    </Button>

                    <div className={classes.filePicker}>
                        <input
                            // accept="image/*"
                            accept="audio/*,video/*,image/*,.xlsx,.xls"
                            className={classes.input}
                            id="add-contained-button-file"
                            multiple
                            type="file"
                            onChange={handleChangeFilePicker}
                        />
                        <label htmlFor="add-contained-button-file">
                            <Button
                                className={classes.marginRight}
                                variant="contained"
                                color="default"
                                component="span"
                                startIcon={<AttachFileIcon />}>
                                Add Files
                            </Button>
                        </label>
                        <label htmlFor="remove-contained-button-file">
                            <Button
                                variant="contained"
                                color="secondary"
                                component="span"
                                startIcon={<DeleteOutlineIcon />}
                                onClick={handleChangeFilePickerRemove}>
                                Remove Files
                            </Button>
                        </label>
                        {recruitment.files.length === 0 ? null : <p>Files attached: {recruitment.files.length}</p>}
                    </div>

                </Container>

            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

        </div>

    )
}
export default TicketFormHR
