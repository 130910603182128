import React from 'react';
import { makeStyles } from '@material-ui/core';
// import summerBBQ2024 from '../../../../images/BBQ2024.jpg'
import healthyHeartDay from '../../../../images/HealthyHeartDay.jpg'
import healthyHeartTalk from '../../../../images/HealthyHeartTalk.mp4'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '60%',
        borderRadius: 20,
    },
}));

export default function HealthyHeartDay2024(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <div className={classes.textCentered}>
                <video id='healthyHeartTalk' className={classes.media} src={healthyHeartTalk}
                    type="video/mp4"
                    // poster={posterVol4}
                    controls
                    controlsList="nodownload">
                    Your browser does not support HTML video.
                </video>
                <br /><br />
            </div>
            <p>Thank you for joining Us!<br />
                Brian Burke | Wisetek™<br />
            </p>
            <br /><br />

            {/* <div className={classes.textCentered}>
                <iframe src="https://wisetek-my.sharepoint.com/personal/brianb_wisetek_net/_layouts/15/embed.aspx?UniqueId=cb37495a-0ab1-4a05-9e5f-5ac6a746ccb0&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen title="Mary Carmody_ Healthy Heart Talk and Top Tips-20240910_123729-Meeting Recording.mp4"></iframe>
                <br /><br />
                <h4>Thank you for joining Us!</h4>
                <br /><br />
            </div> */}

            <div className={classes.textCentered}>
                <img className={classes.media} src={healthyHeartDay} alt="Healthy Heart Day 2024" />
            </div>
            <br />
            <br />
            {/* <div className={classes.textCentered}>
                <img className={classes.media} src={summerBBQ2024} alt="Healthy Heart Day 2024" />
            </div>
            <br />
            <br /> */}

            <br />
            <p>Regards,<br />
                Eleanor Lester<br />
                HR Co-Ordinator | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}