import React, { useContext } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Footer from '../../Footer';
import { AuthContext } from '../../../contexts/AuthContext';
import Loading from '../Loading';
import { Grid } from '@material-ui/core';
import AssignTasks from './AssignTasks';
import Profile from './Profile';
import UserTasks from './UserTasks';
import ManagementActionTracker from './ManagementActionTracker';
import UsersTasksByDepartment from './UsersTasksByDepartment';
import SideMenuV2 from '../SideMenus/SideMenuV2';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
}));

export default function ProfileConsole() {
    const classes = useStyles();
    const { loading, displayName, isAuthenticated, roles, uid } = useContext(AuthContext);

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline >
                        <SideMenuV2 selectedIndex={48} />
                        <div className={classes.main}>
                            {/* <h1>My Profile</h1> */}

                            {/* {role === 'admin' ? */}
                            {roles.includes('admin') || roles.includes('teamManager') || roles.includes('adminIS&T') || roles.includes('adminAM') || roles.includes('adminHR') || roles.includes('adminENG') ?
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                                    <Grid item xs={12} sm={8} md={4} lg={3} xl={3}>
                                        <Profile />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                        <AssignTasks />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <UsersTasksByDepartment />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <UserTasks />
                                    </Grid>
                                </Grid>
                                :
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                                    <Grid item xs={12} sm={8} md={4} lg={3} xl={3}>
                                        <Profile />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                        <UserTasks />
                                    </Grid>
                                </Grid>}

                            <br />
                            {/* •	JP Collins
                            •	Derek Kelly
                            •	Christine Fitzgerald
                            •	Chris O Neill
                            •	Tom Murphy
                            •	Dave Walsh
                            •	me */}
                            {uid === 'mxBUvBxfb8PJS57QV8TaAMz5WJO2'
                                || uid === 'IiKGJ6kAEmOYrY1D9GOXVmLrrB03'
                                || uid === 'duO5D3smG7b1TK5TyKTnxg7WM6i1'
                                || uid === '0h3bXnk4r7Xi9z7UBDhZAPM5WWL2'
                                || uid === '1bkA0mEW2JbSlEO28cd1aCEAyFj2'
                                || uid === 'podN20ADpzTY0PtYVVgPGtGbo1v1'
                                || uid === '6swPjBlta5XPapuq4FIgZQLrDPB3'
                                ? <ManagementActionTracker />
                                : null}
                            {/* for testing */}
                            {/* <br /><br />
                            {displayName === 'Pawel Gajewski' ? <ManagementActionTracker /> : null} */}

                        </div>
                    </CssBaseline>
                    <Footer />


                </div >
            );
        }
    }
}
