import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import { db } from '../../../config/fbConfig';
import { AuthContext } from '../../../contexts/AuthContext';
import { DataContext } from '../../../contexts/DataContext';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    leftMargin: {
        marginLeft: '30px',
    },
}));

export default function ManagementActionTracker() {
    const classes = useStyles();
    const { displayName } = useContext(AuthContext)
    const { addManagementActionTracker, updateManagementActionTracker } = useContext(DataContext);

    const [actionTrackers, setActionTrackers] = useState({
        columns: [
            { title: 'Raised by', field: 'RaisedBy', initialEditValue: displayName },
            { title: 'Owner', field: 'Owner', initialEditValue: '' },
            { title: 'Status', field: 'Status', editable: 'onUpdate', initialEditValue: 'Open', lookup: { 'Open': 'Open', 'WIP': 'WIP', 'On Hold': 'On Hold', 'Closed': 'Closed' } },
            { title: 'Issue Description', field: 'IssueDescription', initialEditValue: '' },
            { title: 'Action', field: 'Action', initialEditValue: '' },
            { title: 'Root Cause', field: 'RootCause', initialEditValue: '' },
            { title: 'Update/Comment', field: 'UpdateComment', initialEditValue: '' },
            // { title: 'Expected completion date', field: 'ExpectedCompletionDate', type: 'date', render: row => <span>{row['ExpectedCompletionDate'], moment().format('dd MMMM YYYY')}</span> },
            { title: 'Expected completion date', field: 'DateExpectedCompletion', type: 'date', initialEditValue: new Date() },
            { title: 'Recommit date', field: 'DateRecommit', type: 'date', initialEditValue: null },
        ],
        data: []
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    // get data
    function getManagementActionTrackers() {
        var actionTrackersData = [];
        db.collection('managementActionTrackers')
            .orderBy('dateOpen', 'desc')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    actionTrackersData.push({
                        ...doc.data(),
                        id: doc.id,
                        DateExpectedCompletion: moment(doc.data().DateExpectedCompletion.toDate()).format("DD MMM YYYY"),
                        DateRecommit: doc.data().DateRecommit ? moment(doc.data().DateRecommit.toDate()).format("DD MMM YYYY") : null,
                    })
                })
                setActionTrackers({
                    ...actionTrackers,
                    data: actionTrackersData
                })
            })
    }
    useEffect(() => {
        getManagementActionTrackers()
        return () => { }
    }, [])

    return (

        <div>

            <MaterialTable
                title={"Management Action Tracker"}
                columns={actionTrackers.columns}
                data={actionTrackers.data}
                options={{
                    // actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50]
                    actionsColumnIndex: 0, pageSize: 5, pageSizeOptions: [5, 10, 50], filtering: true, addRowPosition: 'first',
                }}//, { value: ticketsIT.data.length, label: 'All' }] }}
                actions={
                    [{
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getManagementActionTrackers()
                        },
                    }]
                }
                // detailPanel={rowData => {
                //     // console.log(rowData)
                //     return (
                //         <UserTasksDetails userData={rowData} refreshTasks={getUserTasks} />
                //     )
                // }}
                // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                editable={{
                    onRowAdd: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                setActionTrackers(prevState => {
                                    const data = [...prevState.data];
                                    data.push(newData);
                                    addManagementActionTracker(newData)
                                        .then(resp => {
                                            setSnackbar({
                                                open: true,
                                                severity: 'success',
                                                message: resp,
                                            })
                                        })

                                    getManagementActionTrackers()
                                    return { ...prevState, data };
                                });
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                if (oldData) {
                                    setActionTrackers(prevState => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        console.log('Successfully updated record in management action tracker table.')
                                        // Update data
                                        updateManagementActionTracker(newData)
                                            .then(resp => {
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'success',
                                                    message: resp,
                                                })
                                                getManagementActionTrackers()
                                            })

                                        resolve();
                                        return { ...prevState, data };
                                    })
                                }
                            }, 600)
                        }),
                    // onRowDelete: oldData =>
                    //     new Promise(resolve => {
                    //         setTimeout(() => {
                    //             resolve();
                    //             setIssueError(prevState => {
                    //                 const data = [...prevState.data];
                    //                 data.splice(data.indexOf(oldData), 1);
                    //                 return { ...prevState, data };
                    //             });
                    //         }, 600);
                    //     }),
                }}
            // components={{
            //     Toolbar: props => (
            //         <div>
            //             <MTableToolbar {...props} />
            //             <div >
            //                 <p className={classes.leftMargin}>Hey {firstName}! This week you have {actionTrackers.assignedUserLeave} approved days of holidays. So 100% of your workload is {actionTrackers.weekTotalAvailableHours} hours.</p>
            //                 <p className={classes.leftMargin}>There are {actionTrackers.data.length} planned actionTrackers for you, this should take {actionTrackers.weekTotalPlannedHours} hours to accomplish.</p>
            //                 <p className={classes.leftMargin}>Capacity remaining {actionTrackers.weekTotalCapacityRemaining}%.</p>
            //             </div>
            //         </div>
            //     ),
            // }}
            />

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    )
}