import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthContextProvider from './contexts/AuthContext';
import DataContextProvider from './contexts/DataContext';
import SignIn from './components/layout/SignIn';
import SignUp from './components/layout/SignUp';
import ForgotPassword from './components/layout/ForgotPassword';
import Home from './components/layout/Dashboard/Home';
import RoomBookings from './components/layout/RoomBooking/RoomBookings';
import HolidayRequest from './components/layout/HolidayRequests/HolidayRequest';
import AdminConsole from './components/layout/admin/AdminConsole';
import ISandTConsole from './components/layout/IS&T/ISandTConsole';
import IncidentsISandT from './components/layout/IS&T/IncidentLog/IncidentsISandT';
import EngActionTracker from './components/layout/Engineering/EngActionTracker';
import EngDowntimeLog from './components/layout/Engineering/EngDowntimeLog';
import TicketingISandTConsole from './components/layout/Ticketing/IS&T/TicketingISandTConsole'
import TicketingFacilitiesConsole from './components/layout/Ticketing/Facilities/TicketingFacilitiesConsole';
import KnowledgeBoxConsole from './components/layout/IS&T/KnowledgeBox/KnowledgeBoxConsole';
import TicketingHRConsole from './components/layout/Ticketing/HR/TicketingHRConsole';
import TestingConsole from './components/layout/testing/TestingConsole';
import WeeklyKPIsConsole from './components/layout/Metrics/IS&T/WeeklyKPIsConsole';
import PartnerMatrixConsole from './components/layout/PartnerMatrix/PartnerMatrixConsole';
import ProfileConsole from './components/layout/UserProfile/ProfileConsole';
import FacilitiesConsole from './components/layout/Facilities/FacilitiesConsole';
import HRConsole from './components/layout/HR/HRConsole';
import MetricsCustomerSLAforIEandUK from './components/layout/Metrics/Quality&Compliance/MetricsCustomerSLAforIEandUK';
import MetricsCustomerSLAforUS from './components/layout/Metrics/Quality&Compliance/MetricsCustomerSLAforUS';
import MetricsChainOfCustodyDealIntegrity from './components/layout/Metrics/Quality&Compliance/MetricsChainOfCustodyDealIntegrity';
import MetricsChainOfCustodyVerificationWip from './components/layout/Metrics/Quality&Compliance/MetricsChainOfCustodyVerificationWip';
import MetricsChainOfCustodyDealsInSales from './components/layout/Metrics/Quality&Compliance/MetricsChainOfCustodyDealsInSales';
import MetricsQualityCapaVol from './components/layout/Metrics/Quality&Compliance/MetricsQualityCapaVol';
import MetricsQualityCustomerIssues from './components/layout/Metrics/Quality&Compliance/MetricsQualityCustomerIssues';
import MetricsQualityFPY from './components/layout/Metrics/Quality&Compliance/MetricsQualityFPY';
import MetricsInventory from './components/layout/Metrics/Quality&Compliance/MetricsInventory';
import ArchieKPIs from './components/layout/Metrics/Operations/ArchieKPIs';
import BookingConsole from './components/layout/MeetingRooms/BookingConsole';
import LBMemoryScan from './components/layout/Production/MemoryScan/LBMemoryScan';
import CustomerService from './components/layout/HowToVideos/NavTraining/CustomerService/CustomerService'
import Receiving from './components/layout/HowToVideos/NavTraining/Production/Receiving'
import BasicAudit from './components/layout/HowToVideos/NavTraining/Production/BasicAudit'
import TechAudit from './components/layout/HowToVideos/NavTraining/Production/TechAudit'
import Verify from './components/layout/HowToVideos/NavTraining/Production/Verify'
import Sales from './components/layout/HowToVideos/NavTraining/Sales/Sales'
import Shipping from './components/layout/HowToVideos/NavTraining/Production/Shipping'
import Finance from './components/layout/HowToVideos/NavTraining/Finance/Finance';
import Returntek from './components/layout/HowToVideos/Returntek/Returntek';
import InventoryManagement from './components/layout/HowToVideos/NavTraining/Production/InventoryManagement';
import Aiken from './components/layout/HowToVideos/Archie/Aiken/Aiken';
import DellBiosOwnership from './components/layout/HowToVideos/Archie/DellBiosOwnership/DellBiosOwnership';
import DEP from './components/layout/HowToVideos/Archie/DEP/DEP';
import DigitalLicense from './components/layout/HowToVideos/Archie/DigitalLicenses/DigitalLicenses';
import T2Workaround from './components/layout/HowToVideos/Archie/T2Workaround/T2Workaround';
import WhiteCanyon from './components/layout/HowToVideos/Archie/WhiteCanyon/WhiteCanyon';
import OperatingSystem from './components/layout/HowToVideos/Archie/OS/OperatingSystem';
import Blancco from './components/layout/HowToVideos/Archie/Blancco/Blancco';
import WiseAcademy from './components/layout/WiseAcademy/WiseAcademy';
import CourseDesigner from './components/layout/WiseAcademy/CourseDesigner';
import GoCourse from './components/layout/WiseAcademy/GoCourse';
import AttendanceConsole from './components/layout/Attendance/AttendanceConsole';
import ISTActionTracker from './components/layout/IS&T/ISTActionTracker';
import Factorial from './components/layout/HowToVideos/Factorial/Factorial';
import FeedbackConsole from './components/layout/FeedbackConsole';
import Careers from './components/layout/HrPublic/Careers';
import WiseStar from './components/layout/HrPublic/WiseStar';
import IST27001 from './components/layout/IS&T/KnowledgeBox/IST27001';
import NotFoundPage from './components/layout/NotFoundPage';
import TicketingEngineeringConsole from './components/layout/Ticketing/Engineering/TicketingEngineeringConsole';
import EngineeringConsole from './components/layout/Engineering/EngineeringConsole';
import HSconsole from './components/layout/H&S/HSconsole';
import GoogleAccessories from './components/layout/Production/GoogleAccessories/GoogleAccessories';
import ServerRecovery from './components/layout/Production/ServerRecovery/ServerRecovery';
import PpidLabel from './components/layout/Production/PpidLabel/PpidLabel';
import Ecommerce from './components/layout/HowToVideos/Ecommerce/Ecommerce';
import PurgeChecker from './components/layout/Production/PurgeChecked/PurgeChecked';
import FileFormatter from './components/layout/SalesOps/FileFormatter';
import PartnerCalculator from './components/layout/Production/PartnerCalculator/PartnerCalculator';
import TicketingCSConsole from './components/layout/Ticketing/CS/TicketingCSConsole';
import CSConsole from './components/layout/CS/CSConsole';

function App() {

  const minWidth = 600
  const paddingLeft = 250 // is same as drawerWidth = 250; on the SideMenuV2 component

  return (
    <div className="App">
      <AuthContextProvider>
        <DataContextProvider>
          <BrowserRouter>
            {/* <AuthContextProvider>
          <Route exact path="/"><SignIn /></Route>
          <Route exact path="/signup"><SignUp /></Route>
          <Route exact path="/forgotpassword"><ForgotPassword /></Route>
          <DataContextProvider>
            <div style={{ paddingLeft: 250 }}>
              <Route exact path="/home"><div style={{ minWidth: minWidth }}><Home /></div></Route>
              <Route exact path="/meetingRooms"><div style={{ minWidth: minWidth }}><BookingConsole /></div></Route>
              <Route exact path="/ticketingISandT"><div style={{ minWidth: minWidth }}><TicketingISandTConsole /></div></Route>
              <Route exact path="/ticketingFacilities"><div style={{ minWidth: minWidth }}><TicketingFacilitiesConsole /></div></Route>
              <Route exact path="/ticketingHR"><div style={{ minWidth: minWidth }}><TicketingHRConsole /></div></Route>
              <Route exact path="/careers"><div style={{ minWidth: minWidth }}><Careers /></div></Route>
              <Route exact path="/wisestars"><div style={{ minWidth: minWidth }}><WiseStar /></div></Route>
              <Route exact path="/holiday"><div style={{ minWidth: minWidth }}><HolidayRequest /></div></Route>
              <Route exact path="/admin"><div style={{ minWidth: minWidth }}><AdminConsole /></div></Route>
              <Route exact path="/ticketsIS&T"><div style={{ minWidth: minWidth }}><ISandTConsole /></div></Route>
              <Route exact path="/incidentsIS&T"><div style={{ minWidth: minWidth }}><IncidentsISandT /></div></Route>
              <Route exact path="/istActionTracker"><div style={{ minWidth: minWidth }}><ISTActionTracker /></div></Route>
              <Route exact path="/engActionTracker"><div style={{ minWidth: minWidth }}><EngActionTracker /></div></Route>
              <Route exact path="/engDowntimeLog"><div style={{ minWidth: minWidth }}><EngDowntimeLog /></div></Route>
              <Route exact path="/facilitiesconsole"><div style={{ minWidth: minWidth }}><FacilitiesConsole /></div></Route>
              <Route exact path="/hrconsole"><div style={{ minWidth: minWidth }}><HRConsole /></div></Route>
              <Route exact path="/testing"><div style={{ minWidth: minWidth }}><TestingConsole /></div></Route>
              <Route exact path="/kpis"><div style={{ minWidth: minWidth }}><WeeklyKPIsConsole /></div></Route>
              <Route exact path="/archiekpis"><div style={{ minWidth: minWidth }}><ArchieKPIs /></div></Route>
              <Route exact path="/knowledgebox"><div style={{ minWidth: minWidth }}><KnowledgeBoxConsole /></div></Route>
              <Route exact path="/partnermatrix"><div style={{ minWidth: minWidth }}><PartnerMatrixConsole /></div></Route>
              <Route exact path="/profile"><div style={{ minWidth: minWidth }}><ProfileConsole /></div></Route>
              <Route exact path="/metricsCustomerSLAforIEandUK"><div style={{ minWidth: minWidth }}><MetricsCustomerSLAforIEandUK /></div></Route>
              <Route exact path="/metricsCustomerSLAforUS"><div style={{ minWidth: minWidth }}><MetricsCustomerSLAforUS /></div></Route>
              <Route exact path="/metricsChainOfCustodyDealIntegrity"><div style={{ minWidth: minWidth }}><MetricsChainOfCustodyDealIntegrity /></div></Route>
              <Route exact path="/metricsChainOfCustodyVerificationWip"><div style={{ minWidth: minWidth }}><MetricsChainOfCustodyVerificationWip /></div></Route>
              <Route exact path="/metricsChainOfCustodyDealsInSales"><div style={{ minWidth: minWidth }}><MetricsChainOfCustodyDealsInSales /></div></Route>
              <Route exact path="/metricsQualityCapaVol"><div style={{ minWidth: minWidth }}><MetricsQualityCapaVol /></div></Route>
              <Route exact path="/metricsQualityCustomerIssues"><div style={{ minWidth: minWidth }}><MetricsQualityCustomerIssues /></div></Route>
              <Route exact path="/metricsQualityFPY"><div style={{ minWidth: minWidth }}><MetricsQualityFPY /></div></Route>
              <Route exact path="/metricsInventory"><div style={{ minWidth: minWidth }}><MetricsInventory /></div></Route>
              <Route exact path="/memoryScan"><div style={{ minWidth: minWidth }}><LBMemoryScan /></div></Route>
              <Route exact path="/knowledgeHubFinance"><div style={{ minWidth: minWidth }}><LBMemoryScan /></div></Route>
              <Route exact path="/navisionProductionBasicAudit"><div style={{ minWidth: minWidth }}><BasicAudit /></div></Route>
              <Route exact path="/navisionProductionInventoryManagement"><div style={{ minWidth: minWidth }}><InventoryManagement /></div></Route>
              <Route exact path="/navisionProductionReceiving"><div style={{ minWidth: minWidth }}><Receiving /></div></Route>
              <Route exact path="/navisionProductionShipping"><div style={{ minWidth: minWidth }}><Shipping /></div></Route>
              <Route exact path="/navisionProductionTechAudit"><div style={{ minWidth: minWidth }}><TechAudit /></div></Route>
              <Route exact path="/navisionProductionVerify"><div style={{ minWidth: minWidth }}><Verify /></div></Route>
              <Route exact path="/navisionCustomerService"><div style={{ minWidth: minWidth }}><CustomerService /></div></Route>
              <Route exact path="/navisionFinance"><div style={{ minWidth: minWidth }}><Finance /></div></Route>
              <Route exact path="/navisionSales"><div style={{ minWidth: minWidth }}><Sales /></div></Route>
              <Route exact path="/returntekTraining"><div style={{ minWidth: minWidth }}><Returntek /></div></Route>
              <Route exact path="/factorialTraining"><div style={{ minWidth: minWidth }}><Factorial /></div></Route>
              <Route exact path="/navisionArchieAiken"><div style={{ minWidth: minWidth }}><Aiken /></div></Route>
              <Route exact path="/navisionArchieBlancco"><div style={{ minWidth: minWidth }}><Blancco /></div></Route>
              <Route exact path="/navisionArchieDellBiosOwnership"><div style={{ minWidth: minWidth }}><DellBiosOwnership /></div></Route>
              <Route exact path="/navisionArchieDEP"><div style={{ minWidth: minWidth }}><DEP /></div></Route>
              <Route exact path="/navisionArchieDigitalLicenses"><div style={{ minWidth: minWidth }}><DigitalLicense /></div></Route>
              <Route exact path="/navisionArchieOperatingSystem"><div style={{ minWidth: minWidth }}><OperatingSystem /></div></Route>
              <Route exact path="/navisionArchieT2Workaround"><div style={{ minWidth: minWidth }}><T2Workaround /></div></Route>
              <Route exact path="/navisionArchieWhiteCanyon"><div style={{ minWidth: minWidth }}><WhiteCanyon /></div></Route>
              <Route exact path="/wiseAcademy"><div style={{ minWidth: minWidth }}><WiseAcademy /></div></Route>
              <Route exact path="/feedback"><div style={{ minWidth: minWidth }}><FeedbackConsole /></div></Route>
              <Route exact path="/ist27001"><div style={{ minWidth: minWidth }}><IST27001 /></div></Route>

            </div>
            <Route exact path="/roomBookings"><div style={{ minWidth: minWidth }}><RoomBookings /></div></Route>
            <Route exact path="/attendanceClock"><div style={{ minWidth: minWidth }}><AttendanceConsole /></div></Route>
            <Route exact path="/wiseCourse"><div style={{ minWidth: minWidth }}><GoCourse /></div></Route>
            <Route exact path="/courseDesigner"><div style={{ minWidth: minWidth }}><CourseDesigner /></div></Route>

          </DataContextProvider>
        </AuthContextProvider> */}


            <Switch>
              {/* <Route exact path="/api/sheetSurveys"></Route> */}
              <Route exact path="/"><SignIn /></Route>
              <Route exact path="/signup"><SignUp /></Route>
              <Route exact path="/forgotpassword"><ForgotPassword /></Route>
              {/* padding Left section for constant menu */}
              <Route exact path="/home"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Home /></div></Route>
              <Route exact path="/meetingRooms"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><BookingConsole /></div></Route>
              <Route exact path="/ticketingISandT"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><TicketingISandTConsole /></div></Route>
              <Route exact path="/ticketingEngineering"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><TicketingEngineeringConsole /></div></Route>
              <Route exact path="/ticketingFacilities"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><TicketingFacilitiesConsole /></div></Route>
              <Route exact path="/ticketingHR"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><TicketingHRConsole /></div></Route>
              <Route exact path="/ticketingCS"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><TicketingCSConsole /></div></Route>
              <Route exact path="/careers"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Careers /></div></Route>
              <Route exact path="/wisestars"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><WiseStar /></div></Route>
              <Route exact path="/eh&s"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><HSconsole /></div></Route>
              {/* <Route exact path="/holiday"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><HolidayRequest /></div></Route> */}
              <Route exact path="/admin"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><AdminConsole /></div></Route>
              <Route exact path="/ticketsIS&T"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><ISandTConsole /></div></Route>
              <Route exact path="/incidentsIS&T"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><IncidentsISandT /></div></Route>
              <Route exact path="/istActionTracker"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><ISTActionTracker /></div></Route>
              <Route exact path="/engineeringTickets"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><EngineeringConsole /></div></Route>
              <Route exact path="/engActionTracker"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><EngActionTracker /></div></Route>
              <Route exact path="/engDowntimeLog"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><EngDowntimeLog /></div></Route>
              <Route exact path="/facilitiesconsole"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><FacilitiesConsole /></div></Route>
              <Route exact path="/csconsole"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><CSConsole /></div></Route>
              <Route exact path="/hrconsole"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><HRConsole /></div></Route>
              <Route exact path="/testing"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><TestingConsole /></div></Route>
              <Route exact path="/kpis"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><WeeklyKPIsConsole /></div></Route>
              <Route exact path="/archiekpis"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><ArchieKPIs /></div></Route>
              <Route exact path="/knowledgebox"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><KnowledgeBoxConsole /></div></Route>
              <Route exact path="/partnermatrix"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><PartnerMatrixConsole /></div></Route>
              <Route exact path="/profile"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><ProfileConsole /></div></Route>
              <Route exact path="/metricsCustomerSLAforIEandUK"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><MetricsCustomerSLAforIEandUK /></div></Route>
              <Route exact path="/metricsCustomerSLAforUS"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><MetricsCustomerSLAforUS /></div></Route>
              <Route exact path="/metricsChainOfCustodyDealIntegrity"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><MetricsChainOfCustodyDealIntegrity /></div></Route>
              <Route exact path="/metricsChainOfCustodyVerificationWip"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><MetricsChainOfCustodyVerificationWip /></div></Route>
              <Route exact path="/metricsChainOfCustodyDealsInSales"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><MetricsChainOfCustodyDealsInSales /></div></Route>
              <Route exact path="/metricsQualityCapaVol"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><MetricsQualityCapaVol /></div></Route>
              <Route exact path="/metricsQualityCustomerIssues"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><MetricsQualityCustomerIssues /></div></Route>
              <Route exact path="/metricsQualityFPY"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><MetricsQualityFPY /></div></Route>
              <Route exact path="/metricsInventory"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><MetricsInventory /></div></Route>
              <Route exact path="/memoryScan"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><LBMemoryScan /></div></Route>
              <Route exact path="/googleAccessories"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><GoogleAccessories /></div></Route>
              <Route exact path="/serverRecovery"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><ServerRecovery /></div></Route>
              <Route exact path="/ppidLabel"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><PpidLabel /></div></Route>
              <Route exact path="/knowledgeHubFinance"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><LBMemoryScan /></div></Route>
              <Route exact path="/navisionProductionBasicAudit"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><BasicAudit /></div></Route>
              <Route exact path="/navisionProductionInventoryManagement"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><InventoryManagement /></div></Route>
              <Route exact path="/navisionProductionReceiving"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Receiving /></div></Route>
              <Route exact path="/navisionProductionShipping"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Shipping /></div></Route>
              <Route exact path="/navisionProductionTechAudit"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><TechAudit /></div></Route>
              <Route exact path="/navisionProductionVerify"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Verify /></div></Route>
              <Route exact path="/navisionCustomerService"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><CustomerService /></div></Route>
              <Route exact path="/navisionFinance"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Finance /></div></Route>
              <Route exact path="/navisionSales"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Sales /></div></Route>
              <Route exact path="/returntekTraining"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Returntek /></div></Route>
              <Route exact path="/ecommerceTraining"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Ecommerce /></div></Route>
              <Route exact path="/factorialTraining"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Factorial /></div></Route>
              <Route exact path="/navisionArchieAiken"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Aiken /></div></Route>
              <Route exact path="/navisionArchieBlancco"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><Blancco /></div></Route>
              <Route exact path="/navisionArchieDellBiosOwnership"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><DellBiosOwnership /></div></Route>
              <Route exact path="/navisionArchieDEP"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><DEP /></div></Route>
              <Route exact path="/navisionArchieDigitalLicenses"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><DigitalLicense /></div></Route>
              <Route exact path="/navisionArchieOperatingSystem"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><OperatingSystem /></div></Route>
              <Route exact path="/navisionArchieT2Workaround"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><T2Workaround /></div></Route>
              <Route exact path="/navisionArchieWhiteCanyon"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><WhiteCanyon /></div></Route>
              <Route exact path="/wiseAcademy"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><WiseAcademy /></div></Route>
              <Route exact path="/feedback"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><FeedbackConsole /></div></Route>
              <Route exact path="/ist27001"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><IST27001 /></div></Route>
              <Route exact path="/purgeChecker"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><PurgeChecker /></div></Route>
              <Route exact path="/fileFormatter"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><FileFormatter /></div></Route>
              <Route exact path="/partnerCalculator"><div style={{ minWidth: minWidth, paddingLeft: paddingLeft }}><PartnerCalculator /></div></Route>

              <Route exact path="/roomBookings"><div style={{ minWidth: minWidth }}><RoomBookings /></div></Route>
              <Route exact path="/attendanceClock"><div style={{ minWidth: minWidth }}><AttendanceConsole /></div></Route>
              <Route exact path="/wiseCourse"><div style={{ minWidth: minWidth }}><GoCourse /></div></Route>
              <Route exact path="/courseDesigner"><div style={{ minWidth: minWidth }}><CourseDesigner /></div></Route>

              <Route exact path="*"><NotFoundPage /></Route>
            </Switch>
          </BrowserRouter>
        </DataContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
