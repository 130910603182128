import React from 'react';
import { makeStyles } from '@material-ui/core';
import wellbeing from '../../../../images/WellbeingDay2024.JPG'
import { Link } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    textLeft: {
        textAlign: 'left',
        marginLeft: '18%',
        width: '60%'
    },
    linkStyle: {
        textDecoration: 'none',
    },
    mediaHead: {
        width: '70%',
        borderRadius: '20px 20px 0px 0px',
    },
    media: {
        width: '70%',
        borderRadius: 20,
    },

}));

export default function WellbeingDay2024() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <div className={classes.textCentered}>
                <img className={classes.mediaHead} src={wellbeing} alt="National Workplace Wellbeing Day - 26ᵗʰ April" />
            </div>

            <div className={classes.textLeft}>
                <h5 >Dear Colleagues,</h5>
                <p>As National Workplace Wellbeing Day takes place on Friday 26th April, we will be welcoming <a href="https://www.dennehys.ie" target="_blank" rel="noopener noreferrer">Dennehy’s gym Blackpool</a> to deliver a talk on the benefits of nutrition and movement.</p>

                <p>This talk will take place in the Dublin Hill HQ canteen from 12:30 sharp. To facilitate staff who cannot attend in person please use this <a href="https://wisetek-net.zoom.us/j/93014607369" target="_blank" rel="noopener noreferrer">Zoom Link</a> to access the talk.</p>

                <p>We will also have televisions set up in the canteens of the other buildings for those that don’t have access to a laptop.</p>

                <p>There will be time for a Q&A at the end of the presentation and the Dennehy’s team will remain onsite afterwards to answer any personal questions you may have.</p>

                <p>The talk will be recorded and if you would like to submit any questions in advance please <a href="mailto:brianb@wisetek.net">Email Brian</a> and I’ll gladly pass them on.</p>

                <br />
            </div>

            <br />
            <p>Regards,<br />
                Brian Burke<br />
                HR Manager | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}

