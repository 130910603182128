import { makeStyles } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles({
    tableContainer: {
        margin: '60px',
        width: '93%'
    },
    table: {
        minWidth: 650,
    },
    green: {
        backgroundColor: 'lightgreen'
    },
    orange: {
        backgroundColor: 'lightsalmon'
    },
    red: {
        backgroundColor: 'indianred'
    },
});


export default function HeatMap(dailyData) {
    // console.log('DailyData:', dailyData.incomingData);
    const classes = useStyles();

    if (!dailyData.incomingData) {
        return null
    }
    else {
        return (
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Location Code</TableCell>
                            {dailyData.incomingData.xLabels.map(column => (
                                <TableCell align="right" key={column.key}>{column.label}</TableCell>

                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dailyData.incomingData.yLabels.map(row => {
                            var counter = 0;
                            return (
                                <TableRow key={row.key} >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    {row.data.map(cell => {
                                        // console.log(cell)
                                        counter++;
                                        if (counter == 13) {
                                            return (
                                                cell >= 100 ?
                                                    <TableCell key={counter} align="center" className={classes.green}>{cell.toString() + '%'}</TableCell>
                                                    :
                                                    cell > 75 ?
                                                        <TableCell key={counter} align="center" className={classes.orange}>{cell.toString() + '%'}</TableCell>
                                                        :
                                                        <TableCell key={counter} align="center" className={classes.red}>{cell.toString() + '%'}</TableCell>
                                            )
                                        }
                                        if (counter >= 14) {
                                            return (
                                                cell >= 95 ?
                                                    <TableCell key={counter} align="center" className={classes.green}>{cell.toString() + '%'}</TableCell>
                                                    :
                                                    cell > 75 ?
                                                        <TableCell key={counter} align="center" className={classes.orange}>{cell.toString() + '%'}</TableCell>
                                                        :
                                                        <TableCell key={counter} align="center" className={classes.red}>{cell.toString() + '%'}</TableCell>
                                            )
                                        }
                                        return (
                                            <TableCell key={counter} align="center">{cell}</TableCell>
                                        )
                                    })
                                    }
                                </TableRow>
                            )
                        })}
                        {/* {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                {row.calories > 300 ?
                                    <TableCell align="right" className={classes.red}>{row.calories}</TableCell>
                                    :
                                    row.calories > 200 ?
                                        <TableCell align="right" className={classes.orange}>{row.calories}</TableCell>
                                        :
                                        <TableCell align="right" className={classes.green}>{row.calories}</TableCell>
                                }
                                {row.fat > 10 ?
                                    <TableCell align="right" className={classes.red}>{row.fat}</TableCell>
                                    :
                                    row.fat > 5 ?
                                        <TableCell align="right" className={classes.orange}>{row.fat}</TableCell>
                                        :
                                        <TableCell align="right" className={classes.green}>{row.fat}</TableCell>
                                }
                                <TableCell align="right">{row.carbs}</TableCell>
                                <TableCell align="right">{row.protein}</TableCell>
                            </TableRow>
                        ))} */}
                    </TableBody>
                </Table>
            </TableContainer >
        )

    }
}
