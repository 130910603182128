import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Button, Card, Grid, IconButton, makeStyles, MenuItem, Slider, TextField, Tooltip } from '@material-ui/core';
import moment from 'moment';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { AuthContext } from '../../../../contexts/AuthContext';
import { db } from '../../../../config/fbConfig';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import guyWalking from '../../../../images/guyWalking.gif'
import top3Podium from '../../../../images/topThreePodium.jpg'
import stepsWinner from '../../../../images/stepsWinner.gif'
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        marginLeft: '9%',
        width: '11%',
    },
    imgDiv: {
        textAlign: 'center',
        margin: '30px'
    },
    topThreePodium: {
        alignItems: 'center',
        width: '20%',
    },
    leaderboard: {
        width: '75%',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        paddingBottom: '40px'
    },
    challengeDay: {
        color: 'DarkSlateBlue',
        fontWeight: 'bold',
        fontSize: '1.2em'
    },
    challengeDayRow: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    userCard: {
        margin: '5px',
        backgroundColor: 'SeaShell'
    },
    avatar: {
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '10px',
        marginBottom: '10px'
    },
    userAvatar: {
        width: '70px',
        padding: '10px',
        marginLeft: '40px'
    },
    userName: {
        width: '300px',
        color: 'DarkOliveGreen',
        fontWeight: 'bold',
        fontSize: '1.2em',
        marginBottom: '0px'
    },
    userTotal: {
        color: 'Maroon',
        fontSize: '1.2em',
        marginTop: '0px'
    },
    userSteps: {
        width: '100px',
        color: 'DarkSlateGray',
        fontWeight: 'bold',
        fontSize: '1.2em',
        marginBottom: '0px'
    },
    userStepsToday: {
        color: 'DarkSlateGray',
        fontSize: '0.9em',
        marginTop: '0px'
    },
    datePickerCenter: {
        marginLeft: '15px',
    },
    hiddenResult: {
        visibility: 'hidden',
        textAlign: 'center',
        transition: "0.8s ease-out",
        fontSize: 18,
        transitionProperty: "background-color, color, opacity"
    }

}));

export default function StepChallenge() {
    const classes = useStyles();

    const { role } = useContext(AuthContext)

    const [challengeDay, setChallengeDay] = useState(moment('08 April 2022').format('DD MMMM YYYY'))

    const [participant, setParticipant] = useState({
        name: '',
        imgUrl: '',
        totalSteps: 0
    })
    const [dailyScore, setDailyScore] = useState({
        name: '',
        imgUrl: '',
        stepsDay: moment('17 March 2022'),
        steps: 0
    })
    const [allParticipants, setAllParticipants] = useState({
        count: 0,
        data: []
    })
    const [topThree, setTopThree] = useState([])

    const [allDailyScores, setAllDailyScores] = useState([])

    const [targetGoal, setTargetGoal] = useState(0)

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function getDailyScores(today) {
        // console.log(today)
        var todayStart = new Date(moment(today).startOf('day'))
        var todayEnd = new Date(moment(today).endOf('day'))
        var data = []
        // setAllDailyScores(data);
        db.collection('stepsChallengeDailyScores')
            .where('stepsDay', '>=', todayStart)
            .where('stepsDay', '<=', todayEnd)
            .orderBy('stepsDay', 'desc')
            .orderBy('steps', 'desc').get()
            .then(snap => {
                snap.forEach(doc => {
                    data.push({ ...doc.data(), id: doc.id })
                })
                // console.log(data)
                setAllDailyScores(data)
            })
    }
    function getParticipants() {
        var data = []
        var totSteps = 0
        var counter = 0
        var marginPercent = '9%'
        db.collection('stpsChallengeParticipants').orderBy('name').get()
            .then(snap => {
                snap.forEach(doc => {
                    counter++
                    data.push({ ...doc.data(), id: doc.id })
                    totSteps += doc.data().totalSteps
                })
                // get top three
                db.collection('stpsChallengeParticipants').orderBy('totalSteps', 'desc').limit(50).get()
                    .then(snap => {
                        var topThreeParticipants = []
                        snap.forEach(doc => {
                            topThreeParticipants.push({ ...doc.data(), id: doc.id })
                        })
                        setTopThree(topThreeParticipants)
                    })
                setAllParticipants({
                    ...allParticipants,
                    count: counter,
                    data: data
                })
                setTargetGoal(totSteps)
                //0 mil = 9%
                //1 mil = 27%
                //2 mil = 45%
                //3 mil = 62%
                //4 mil = 80%
                if (totSteps >= 100000) { marginPercent = '11%' }
                if (totSteps >= 200000) { marginPercent = '13.5%' }
                if (totSteps >= 300000) { marginPercent = '15%' }
                if (totSteps >= 400000) { marginPercent = '17%' }
                if (totSteps >= 500000) { marginPercent = '18.5%' }
                if (totSteps >= 600000) { marginPercent = '20%' }
                if (totSteps >= 700000) { marginPercent = '22%' }
                if (totSteps >= 800000) { marginPercent = '24%' }
                if (totSteps >= 900000) { marginPercent = '25.5%' }
                if (totSteps >= 1000000) { marginPercent = '27%' }
                if (totSteps >= 1100000) { marginPercent = '29%' }
                if (totSteps >= 1200000) { marginPercent = '31.5%' }
                if (totSteps >= 1300000) { marginPercent = '33%' }
                if (totSteps >= 1400000) { marginPercent = '35%' }
                if (totSteps >= 1500000) { marginPercent = '37%' }
                if (totSteps >= 1600000) { marginPercent = '38.5%' }
                if (totSteps >= 1700000) { marginPercent = '40%' }
                if (totSteps >= 1800000) { marginPercent = '41.5%' }
                if (totSteps >= 1900000) { marginPercent = '43%' }
                if (totSteps >= 2000000) { marginPercent = '45%' }
                if (totSteps >= 2100000) { marginPercent = '46.5%' }
                if (totSteps >= 2200000) { marginPercent = '48%' }
                if (totSteps >= 2300000) { marginPercent = '49.5%' }
                if (totSteps >= 2400000) { marginPercent = '51%' }
                if (totSteps >= 2500000) { marginPercent = '52.5%' }
                if (totSteps >= 2600000) { marginPercent = '54.5%' }
                if (totSteps >= 2700000) { marginPercent = '57%' }
                if (totSteps >= 2800000) { marginPercent = '58.5%' }
                if (totSteps >= 2900000) { marginPercent = '60%' }
                if (totSteps >= 3000000) { marginPercent = '62%' }
                if (totSteps >= 3100000) { marginPercent = '63.5%' }
                if (totSteps >= 3200000) { marginPercent = '65%' }
                if (totSteps >= 3300000) { marginPercent = '67%' }
                if (totSteps >= 3400000) { marginPercent = '68.5%' }
                if (totSteps >= 3500000) { marginPercent = '70.5%' }
                if (totSteps >= 3600000) { marginPercent = '72%' }
                if (totSteps >= 3700000) { marginPercent = '74%' }
                if (totSteps >= 3800000) { marginPercent = '76%' }
                if (totSteps >= 3900000) { marginPercent = '78%' }
                if (totSteps >= 4000000) { marginPercent = '80%' }
                document.getElementById('walker').style.marginLeft = marginPercent
            })
    }

    useEffect(() => {
        getParticipants()
        return () => {
        }
    }, [])

    useEffect(() => {
        getDailyScores(challengeDay)
        // console.log('loop!')
        return () => {
        }
    }, [challengeDay])


    const handleChangeParticipant = (e) => {
        setParticipant({
            ...participant,
            [e.target.name]: e.target.value
        })
    }
    const handleChangeDailyScore = (e) => {
        setDailyScore({
            ...dailyScore,
            [e.target.name]: e.target.value
        })
    }
    const handleChangeDailyScoreDate = (selectedDate) => {
        // console.log(moment(selectedDate).startOf('day'))
        setDailyScore({
            ...dailyScore,
            stepsDay: moment(selectedDate).startOf('day')
        })
    }
    function getKeyByValue(array, attr, value) {
        // console.log(array, attr, value)
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return array[i];
            }
        }
    };
    const handleChangeDailyScoreSelect = (e) => {
        var walker = getKeyByValue(allParticipants.data, 'name', e.target.value);
        setDailyScore({
            ...dailyScore,
            [e.target.name]: e.target.value,
            imgUrl: walker.imgUrl
        })
    }
    const handleChangeSlider = (e, newValue) => {
        setTargetGoal(newValue)
    };
    const minusDay = () => {
        setAllDailyScores([])

        setChallengeDay(moment(moment(challengeDay).add(-1, 'days')).format('DD MMMM YYYY'))
    }
    const plusDay = () => {
        setAllDailyScores([])

        setChallengeDay(moment(moment(challengeDay).add(1, 'days')).format('DD MMMM YYYY'))
    }
    function getTotalSteps(array, attr, value) {
        // console.log(array, attr, value)
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return array[i].totalSteps;
            }
        }
    };
    function getParticipantData(array, attr, value) {
        // console.log(array, attr, value)
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return array[i];
            }
        }
    };

    const handleSubmitParticipant = (e) => {
        e.preventDefault()
        db.collection('stpsChallengeParticipants').add({
            name: participant.name,
            imgUrl: participant.imgUrl,
            totalSteps: parseInt(participant.totalSteps)
        }).then(suc => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Successfully added new Participant',
            })
            setParticipant({
                ...participant,
                name: '',
                imgUrl: '',
                totalSteps: 0
            })
        })
    }
    const handleSubmitDailyScore = (e) => {
        e.preventDefault()
        if (dailyScore.steps <= 0) { return }
        db.collection('stepsChallengeDailyScores').add({
            name: dailyScore.name,
            imgUrl: dailyScore.imgUrl,
            stepsDay: new Date(dailyScore.stepsDay),
            steps: parseInt(dailyScore.steps)
        }).then(suc => {
            //update participant
            var oldData = getParticipantData(allParticipants.data, 'name', dailyScore.name)
            db.collection('stpsChallengeParticipants').doc(oldData.id).update({
                totalSteps: parseInt(oldData.totalSteps + parseInt(dailyScore.steps))
            })
                .then(suce => {
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: 'Successfully added Daily Score',
                    })
                    setDailyScore({
                        ...dailyScore,
                        name: '',
                        imgUrl: '',
                        stepsDay: moment(),
                        steps: 0
                    })
                    getParticipants()
                    getDailyScores(challengeDay)

                })
        })
    }
    function ValueLabelComponent(props) {
        const { children, open, value } = props;

        return (
            <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
                {children}
            </Tooltip>
        );
    }

    return (
        <div className={classes.root}>

            <div className={classes.textCentered}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d7372637.71056291!2d5.082659575385795!3d50.35670130902384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e2!4m5!1s0x4844900891beb961%3A0xa00c7a99731c5d0!2sCork!3m2!1d51.898514299999995!2d-8.4756035!4m5!1s0x40d4cf4ee15a4505%3A0x764931d2170146fe!2sKyiv%2C%20Ukraine%2C%2002000!3m2!1d50.4501!2d30.5234!5e0!3m2!1sen!2sie!4v1647433734300!5m2!1sen!2sie" width="100%" height="400" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
            </div>
            <div className={classes.textCentered}>
                {/* <Card className={classes.leaderboard}> */}

                <h2 className="stepChallengeLeaderboard" style={{ color: 'ForestGreen', marginBottom: '0px' }}>Team Wisetek Steps</h2>
                <h4 className={classes.textCentered}>From 17ᵗʰ March 2022 until 8ᵗʰ April 2022 we walked a total of {targetGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} steps..</h4>
                {/* controil the walker by setting marginLeft from 9% to 80% */}
                <img id="walker" className={classes.media} src={guyWalking} alt="owl walking" />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <p style={{ width: '15%' }}></p>
                    <Slider
                        style={{ width: '70%' }}
                        // onChange={handleChangeSlider}
                        ValueLabelComponent={ValueLabelComponent}
                        value={targetGoal}
                        min={0}
                        max={4000000}
                        step={1000000}
                        marks
                        // getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-always"
                        valueLabelDisplay="auto"
                    />
                    <p style={{ width: '15%' }}></p>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <p style={{ width: '30%', paddingLeft: '50px' }}>Start: Cork</p>
                    <div style={{ width: '40%' }}></div>
                    <p style={{ width: '30%', paddingLeft: '30px' }}>Finish: Kyiv ~4,000,000 steps</p>
                </Grid>

                <div id="counter" style={{
                    textAlign: 'center',
                    transition: "0.8s ease-out",
                    fontSize: 30,
                    transitionProperty: "background-color, color, opacity"
                }}></div>
                {/* <div className={classes.hiddenResult}>
                    <p id="fourMilStepPerson" >
                        Congratulations team we already reached our cumulative Goal <br />& a shout out to <b style={{ color: 'Green' }}>“maryc1”</b> who walked the final 4million step!
                    </p>
                    <br />
                </div> */}
                <div className={classes.textCentered}>
                    <img src={stepsWinner} alt="Person that reached the mark of 4 mil steps" />
                </div>

                {topThree.length > 0 ?
                    <div>
                        {/* {showPerson()} */}
                        <h2 className="stepChallengeLeaderboard" style={{ color: 'DarkGoldenRod', marginBottom: '0px' }}>Walkers Leaderboard</h2>
                        <div className={classes.imgDiv}>
                            <img className={classes.topThreePodium} src={top3Podium} alt="Top walkers" />
                        </div>
                        <ol>
                            {topThree.map(option => (
                                <li key={option.name} style={{ fontSize: '1.4em', marginLeft: '30%', whiteSpace: 'pre' }}><Grid container direction='row' justifyContent="flex-start" alignItems="center"><Avatar alt={option.name} src={option.imgUrl} className={classes.avatar} /><b>{option.name}</b>{' with ' + option.totalSteps.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' steps '}</Grid></li>
                            ))}

                        </ol>
                    </div> : null}

                <br /><br />

                <h2 className="stepChallengeLeaderboard">Steps Challenge Daily</h2>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    className={classes.challengeDayRow}
                >
                    <Grid item xs className={classes.challengeDayButtons} >
                        {moment(challengeDay).isSameOrBefore('17 March 2022') ? null :
                            <Tooltip title="Previous Day" aria-label="previous day" >
                                <IconButton color="primary" id="beforeBtn" style={{ float: 'right' }} onClick={minusDay}>
                                    <NavigateBeforeIcon />
                                </IconButton>
                            </Tooltip>}
                    </Grid>
                    <Grid item xs className={classes.textCentered}>
                        <p className={classes.challengeDay}>{challengeDay}</p>
                    </Grid>
                    <Grid item xs className={classes.challengeDayButtons}>
                        {moment(challengeDay).isSameOrAfter('08 April 2022') ? null :
                            <Tooltip title="Next Day" aria-label="next day">
                                <IconButton color="primary" id="afterBtn" style={{ float: 'left' }} onClick={plusDay}>
                                    <NavigateNextIcon />
                                </IconButton>
                            </Tooltip>}
                    </Grid>

                </Grid>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >

                    {allDailyScores.length > 0 ?
                        allDailyScores.map(option => (
                            <Card key={option.name} className={classes.userCard}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <div className={classes.userAvatar}><Avatar alt={option.name} src={option.imgUrl} /></div>
                                    <div>
                                        <p className={classes.userName}>{option.name}</p>
                                        <p className={classes.userTotal}>total steps: <b>{getTotalSteps(allParticipants.data, 'name', option.name)}</b></p>
                                    </div>
                                    <div>
                                        <p className={classes.userSteps}>{option.steps}</p>
                                        <p className={classes.userStepsToday}>steps today</p>
                                    </div>
                                </Grid>
                            </Card>
                        ))
                        : <p className={classes.textCentered}>no data yet...</p>}

                </Grid>

                {role === 'admin' ?
                    <div>
                        <form id='addUserToChallenge' onSubmit={handleSubmitParticipant}>
                            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                <p>Total number of registered participants: {allParticipants.count}</p>
                                <p>Add new participant to the group</p>
                                <Grid container item xs={12} sm={5} spacing={0}>
                                    <TextField
                                        size="small"
                                        name="name"
                                        id="name"
                                        label="Full Name"
                                        variant="outlined"
                                        // defaultValue={displayName}
                                        value={participant.name}
                                        required
                                        fullWidth
                                        // autoFocus
                                        className={classes.lowerTextfield}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={handleChangeParticipant}
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={5} spacing={0}>
                                    <TextField
                                        size="small"
                                        name="imgUrl"
                                        id="imgUrl"
                                        label="Image URL"
                                        variant="outlined"
                                        // defaultValue={displayName}
                                        value={participant.imgUrl}
                                        required
                                        fullWidth
                                        // autoFocus
                                        className={classes.lowerTextfield}
                                        inputProps={{ maxLength: 250 }}
                                        onChange={handleChangeParticipant}
                                    />
                                </Grid>
                                <br /><br /><br />
                                <Button
                                    type="submit"
                                    className={classes.buttonRight}
                                    variant="contained"
                                    color="primary"
                                >
                                    Add new participant
                                </Button>
                            </Grid>
                        </form>
                        <br /><br /><br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <form id='addDailyData' onSubmit={handleSubmitDailyScore}>
                                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                    <p>Add daily data for each user</p>
                                    <Grid container item xs={12} sm={5} spacing={0}>
                                        <DatePicker
                                            margin="normal"
                                            id="stepsDay"
                                            label="Day:"
                                            name="stepsDay"
                                            format="dd MMMM yyyy"
                                            // error // makes text red
                                            // helperText="Inclusive in your holiday request. Check before submitting **"
                                            required
                                            className={classes.datePickerCenter}
                                            value={dailyScore.stepsDay}
                                            onChange={handleChangeDailyScoreDate}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} sm={5} spacing={0}>
                                        <TextField
                                            select
                                            size="small"
                                            name="name"
                                            id="name"
                                            label="Full Name"
                                            variant="outlined"
                                            // defaultValue={displayName}
                                            value={dailyScore.name}
                                            required
                                            fullWidth
                                            // autoFocus
                                            className={classes.lowerTextfield}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={handleChangeDailyScoreSelect}
                                        >
                                            {allParticipants.data.map((option) => (
                                                <MenuItem key={option.imgUrl} value={option.name}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid container item xs={12} sm={5} spacing={0}>
                                        <TextField
                                            size="small"
                                            name="steps"
                                            id="steps"
                                            label="Steps"
                                            variant="outlined"
                                            // defaultValue={displayName}
                                            type="number"
                                            value={dailyScore.steps}
                                            required
                                            fullWidth
                                            // autoFocus
                                            className={classes.lowerTextfield}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={handleChangeDailyScore}
                                        />
                                    </Grid>
                                    <br /><br /><br />
                                    <Button
                                        type="submit"
                                        className={classes.buttonRight}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Add daily data
                                    </Button>
                                </Grid>
                            </form>
                        </MuiPickersUtilsProvider>
                    </div>
                    : null}

                {/* </Card> */}
            </div>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    )
}