import React, { useState } from 'react'
import { Card, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { db } from '../../../../config/fbConfig';
import DateFnsUtils from '@date-io/date-fns';
import { Typography } from '@material-ui/core';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist'
import ctPointLabels from 'chartist-plugin-pointlabels'
import HeatMap from '../../../HeatMap';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({

    searchProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    graphHeader: {
        marginLeft: '80px'
    },
    center: {
        textAlign: 'center',
        marginTop: '50px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    centerParagraph: {
        textAlign: 'center',
        width: '80%',
        padding: '50px',
        marginTop: '30px',
        marginBottom: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
        border: '1px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
    },
    marginLeftRight: {
        paddingLeft: '50px',
        paddingRight: '50px',
    },
    marginTopLeftRight: {
        marginTop: '50px',
        paddingLeft: '50px',
        paddingRight: '50px',
    },
    marginTop: {
        marginTop: '50px'
    },
    marginTopAndBottom: {
        marginTop: '50px',
        marginBottom: '50px'
    },
    searchGraphs: {
        display: 'none',
    },
    blue: {
        width: '20px',
        color: 'rgb(50, 50, 240)',
        backgroundColor: 'rgb(50, 50, 240)',
        borderRadius: '10%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    orange: {
        width: '20px',
        color: 'rgb(200, 110, 10)',
        backgroundColor: 'rgb(200, 110, 10)',
        borderRadius: '10%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    green: {
        width: '20px',
        color: 'rgb(20, 150, 30)',
        backgroundColor: 'rgb(20, 150, 30)',
        borderRadius: '10%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    yellow: {
        width: '20px',
        color: 'rgb(210, 180, 30)',
        backgroundColor: 'rgb(210, 180, 30)',
        borderRadius: '10%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    grey: {
        width: '20px',
        color: 'rgb(110, 110, 110)',
        backgroundColor: 'rgb(110, 110, 110)',
        borderRadius: '10%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    purple: {
        width: '20px',
        color: 'rgb(130, 10, 200)',
        backgroundColor: 'rgb(130, 10, 200)',
        borderRadius: '10%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    table: {
        marginBottom: '100px'
    },
    heatmap: {
        width: '90%',
        alignItems: 'center'
    },
    ticks: {
        marginLeft: '35px',
        marginRight: '10px',
    }
}));

export default function KPIsCustomerSLAforUS() {
    const classes = useStyles();

    const [searchDate, setSearchDate] = useState(new Date());

    const [allWeekDurations, setAllWeekDurations] = useState([]);

    const [recCompGraph, setRecCompGraph] = useState({
        data: {
            labels: ['Week ' + 1, 'Week ' + 2, 'Week ' + 3, 'Week ' + 4, 'Week ' + 5, 'Week ' + 6, 'Week ' + 7, 'Week ' + 8, 'Week ' + 9, 'Week ' + 10, 'Week ' + 11, 'Week ' + 12],
            series: [
                [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
                [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]
            ]
        },
        type: 'Line',
        options: {
            // width: 300,
            height: 600,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                type: Chartist.FixedScaleAxis,
                ticks: [-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
                high: 110,
                low: -10,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                }),
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    });

    const [baCompGraph, setBaCompGraph] = useState({
        data: {
            labels: ['Week ' + 1, 'Week ' + 2, 'Week ' + 3, 'Week ' + 4, 'Week ' + 5, 'Week ' + 6, 'Week ' + 7, 'Week ' + 8, 'Week ' + 9, 'Week ' + 10, 'Week ' + 11, 'Week ' + 12],
            series: [
                [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
                [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]
            ]
        },
        type: 'Line',
        options: {
            // width: 300,
            height: 600,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                type: Chartist.FixedScaleAxis,
                ticks: [-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
                high: 110,
                low: -10,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                }),
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    });

    const [taCompGraph, setTaCompGraph] = useState({
        data: {
            labels: ['Week ' + 1, 'Week ' + 2, 'Week ' + 3, 'Week ' + 4, 'Week ' + 5, 'Week ' + 6, 'Week ' + 7, 'Week ' + 8, 'Week ' + 9, 'Week ' + 10, 'Week ' + 11, 'Week ' + 12],
            series: [
                [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
                [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]
            ]
        },
        type: 'Line',
        options: {
            // width: 300,
            height: 600,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                type: Chartist.FixedScaleAxis,
                ticks: [-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
                high: 110,
                low: -10,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    });

    const [verCompGraph, setVerCompGraph] = useState({
        data: {
            labels: ['Week ' + 1, 'Week ' + 2, 'Week ' + 3, 'Week ' + 4, 'Week ' + 5, 'Week ' + 6, 'Week ' + 7, 'Week ' + 8, 'Week ' + 9, 'Week ' + 10, 'Week ' + 11, 'Week ' + 12],
            series: [
                [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
                [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
                [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]
            ]
        },
        type: 'Line',
        options: {
            // width: 300,
            height: 600,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                type: Chartist.FixedScaleAxis,
                ticks: [-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
                high: 110,
                low: -10,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    });

    const [dailyData, setDailyData] = useState({
        isEmpty: true,
        xLabels: [{ key: 1, label: 'Rec Wip' }, { key: 2, label: 'Compliant' }, { key: 3, label: 'Basic Audit Wip' }, { key: 4, label: 'Compliant' }, { key: 5, label: 'Tech Audit Wip' }, { key: 6, label: 'Compliant' }, { key: 7, label: 'Verification' }, { key: 8, label: 'Compliant' }, { key: 9, label: 'Wip Turnout Required' }, { key: 10, label: 'WIP Turnout Compliant' }, { key: 11, label: 'CS Turnout' }, { key: 12, label: 'CS Turnout Compliant' }, { key: 13, label: 'Rec Compliant' }, { key: 14, label: 'BA Compliant' }, { key: 15, label: 'TA Compliant' }, { key: 16, label: 'Ver Compliant' }, { key: 17, label: 'WIP Turnout Compliant' }, { key: 18, label: 'CS Turnout Compliant' }],
        yLabels: [],
        boston: {},
        md: {},
        sac: {},
        sm: {},
        tx: {},
        va: {}
    });

    const [cellWipGraph, setCellWipGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                // high: 11,
                // low: -1,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        },
    });

    const [cellWipCompGraph, setCellWipCompGraph] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                type: Chartist.FixedScaleAxis,
                ticks: [-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
                high: 110,
                low: -10,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    });

    //#region Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    //#region calculating functions
    var bostonCode = 'ITADBOSTON';
    var mdCode = 'ITADMD';
    var sacCode = 'ITADSAC';
    var smCode = 'ITADSM';
    var txCode = 'ITADTX';
    var vaCode = 'ITADVA';

    class WeekDuration {
        constructor(number, date) {
            this.weekStart = moment(date).subtract(number, 'weeks').startOf('isoWeek').format();
            this.weekEnd = moment(date).subtract(number, 'weeks').endOf('isoWeek').format();
            this.weekNumber = moment(this.weekStart).isoWeek();
            this.weekArrayPosition = 11 - number;
            // console.log('Week Number', this.weekNumber, 'START', this.weekStart);
            // console.log('Week Number', this.weekNumber, 'END', this.weekEnd);
        }
    }

    function CalculateWeeks(date) {
        var weekDurations = [];
        for (let i = 11; i > -1; i--) {
            var weekDuration = new WeekDuration(i, date)
            weekDurations.push(weekDuration)
        }
        return weekDurations
    }

    class WeeklyDataObject {
        // if "Arrived" in "DealWIPStatus"
        Arrived = 0;
        // if "CompliantToRecSLA" true
        ArrivedCompliant = 0;
        // if "Basic Audit WIP" or "Offloaded" in "DealWIPStatus" and
        // everything else except "SORT" in "DispositionRouting"
        BasicWip = 0;
        // if "CompliantToBasicAuditSLA" true
        BasicWipCompliant = 0;
        // if "Tech Audit WIP" in "DealWIPStatus" and "AUDIT" "PICK" in "DispositionRouting"
        TechAuditWip = 0;
        // if "CompliantToTechAuditSLA" true
        TechAuditWipCompliant = 0;
        // if "Verification WIP" in "DealWIPStatus" and "AUDIT"  "PICK" in "DispositionRouting"
        Verification = 0;
        // if "VerificationCompliantToSLA" true
        VerificationCompliant = 0;
        // "Arrived" "Basic Audit WIP" "Offloaded" "Tech Audit WIP" "Verification WIP" in "DealWIPStatus"
        WipTurnoutRequired = 0;
        // if "CompliantTurnoutSLA" true
        WipTurnoutCompliant = 0;
        // "Sales" in "DealWIPStatus"
        Sales = 0;
        // if "CompliantTurnoutSLA" true
        SalesCompliant = 0;
        //  var ...ArrivedCompliant / ...Arrived
        RecComp = 0;
        // var ...BasicWipCompliant / ...BasicWip
        BaComp = 0;
        // var ...TechAuditWipCompliant / ...TechAuditWip
        TaComp = 0;
        // var ...VerificationCompliant / Verification
        VerComp = 0;
        // var ...WipTurnoutCompliant / WipTurnoutRequired
        WipTurnComp = 0;
        // var ...SalesCompliant / ...Sales
        CsTurnComp = 0;

        constructor(week) {
            this.weekStart = week.weekStart;
            this.weekEnd = week.weekEnd;
            this.weekNumber = week.weekNumber;
        }
        //  var ...ArrivedCompliant / Arrived
        getRecComp() {
            this.RecComp = this.ArrivedCompliant == 0 && this.Arrived == 0 ? 100 : this.ArrivedCompliant == 0 || this.Arrived == 0 ? 0 : Math.round(((this.ArrivedCompliant / this.Arrived) * 100) * 10) / 10; //to have decimal percentage
            return this.RecComp;
        }
        // var ...BasicWipCompliant / BasicWip
        getBaComp() {
            this.BaComp = this.BasicWipCompliant == 0 && this.BasicWip == 0 ? 100 : this.BasicWipCompliant == 0 || this.BasicWip == 0 ? 0 : Math.round(((this.BasicWipCompliant / this.BasicWip) * 100) * 10) / 10;
            return this.BaComp;
        }
        // var ...TechAuditWipCompliant / TechAuditWip
        getTaComp() {
            this.TaComp = this.TechAuditWipCompliant == 0 && this.TechAuditWip == 0 ? 100 : this.TechAuditWipCompliant == 0 || this.TechAuditWip == 0 ? 0 : Math.round(((this.TechAuditWipCompliant / this.TechAuditWip) * 100) * 10) / 10;
            return this.TaComp;
        }
        // var ...VerificationCompliant / Verification
        getVerComp() {
            this.VerComp = this.VerificationCompliant == 0 && this.Verification == 0 ? 100 : this.VerificationCompliant == 0 || this.Verification == 0 ? 0 : Math.round(((this.VerificationCompliant / this.Verification) * 100) * 10) / 10;
            return this.VerComp;
        }
        // var ...WipTurnoutCompliant / WipTurnoutRequired
        getWipTurnComp() {
            this.WipTurnComp = this.WipTurnoutCompliant == 0 && this.WipTurnoutRequired == 0 ? 100 : this.WipTurnoutCompliant == 0 || this.WipTurnoutRequired == 0 ? 0 : Math.round(((this.WipTurnoutCompliant / this.WipTurnoutRequired) * 100) * 10) / 10;
            return this.WipTurnComp;
        }
        // var ...SalesCompliant / Sales
        getCsTurnComp() {
            this.CsTurnComp = this.SalesCompliant == 0 && this.Sales == 0 ? 100 : this.SalesCompliant == 0 || this.Sales == 0 ? 0 : Math.round(((this.SalesCompliant / this.Sales) * 100) * 10) / 10;
            return this.CsTurnComp;
        }

    }

    class LocationWeeklyData {
        WeeklyDataObjects = []
        constructor(weeks, location) {
            this.location = location;
            weeks.forEach(week => {
                this.WeeklyDataObjects.push(new WeeklyDataObject(week))
            });
        }
    }

    class DataObject {
        constructor(weeks) {
            this.boston = new LocationWeeklyData(weeks, 'boston');
            this.md = new LocationWeeklyData(weeks, 'md');
            this.sac = new LocationWeeklyData(weeks, 'sac');
            this.sm = new LocationWeeklyData(weeks, 'sm');
            this.tx = new LocationWeeklyData(weeks, 'tx');
            this.va = new LocationWeeklyData(weeks, 'va');
        }

        getRecCompGraphData() {
            var graphData = { labels: [], series: [], high: 100, low: 0 }
            var bostonSeries = []
            var mdSeries = []
            var sacSeries = []
            var txSeries = []
            var vaSeries = []
            var smSeries = []
            var labels = []
            var recCompAll = []
            this.boston.WeeklyDataObjects.forEach(weeklyData => {
                bostonSeries.push(weeklyData.getRecComp());
            });
            this.md.WeeklyDataObjects.forEach(weeklyData => {
                mdSeries.push(weeklyData.getRecComp());
            });
            this.sac.WeeklyDataObjects.forEach(weeklyData => {
                sacSeries.push(weeklyData.getRecComp());
            });
            this.tx.WeeklyDataObjects.forEach(weeklyData => {
                txSeries.push(weeklyData.getRecComp());
            });
            this.va.WeeklyDataObjects.forEach(weeklyData => {
                vaSeries.push(weeklyData.getRecComp())
            });
            this.sm.WeeklyDataObjects.forEach(weeklyData => {
                smSeries.push(weeklyData.getRecComp())
            });
            this.boston.WeeklyDataObjects.forEach(element => {
                labels.push('Week ' + element.weekNumber)
            });
            recCompAll = recCompAll.concat(bostonSeries);
            recCompAll = recCompAll.concat(mdSeries);
            recCompAll = recCompAll.concat(sacSeries);
            recCompAll = recCompAll.concat(txSeries);
            recCompAll = recCompAll.concat(vaSeries);
            recCompAll = recCompAll.concat(smSeries);
            // console.log(recCompAll)
            graphData.labels = labels;
            graphData.series.push(bostonSeries);
            graphData.series.push(mdSeries);
            graphData.series.push(sacSeries);
            graphData.series.push(txSeries);
            graphData.series.push(vaSeries);
            graphData.series.push(smSeries);
            graphData.high = Math.max.apply(null, recCompAll) + 2
            graphData.low = Math.min.apply(null, recCompAll) - 1
            return graphData
        }

        getBaCompGraphData() {
            var graphData = { labels: [], series: [], high: 100, low: 0 }
            var bostonSeries = []
            var mdSeries = []
            var sacSeries = []
            var txSeries = []
            var vaSeries = []
            var smSeries = []
            var labels = []
            var recCompAll = []
            this.boston.WeeklyDataObjects.forEach(weeklyData => {
                bostonSeries.push(weeklyData.getBaComp())
            });
            this.md.WeeklyDataObjects.forEach(weeklyData => {
                mdSeries.push(weeklyData.getBaComp())
            });
            this.sac.WeeklyDataObjects.forEach(weeklyData => {
                sacSeries.push(weeklyData.getBaComp())
            });
            this.tx.WeeklyDataObjects.forEach(weeklyData => {
                txSeries.push(weeklyData.getBaComp())
            });
            this.va.WeeklyDataObjects.forEach(weeklyData => {
                vaSeries.push(weeklyData.getBaComp())
            });
            this.sm.WeeklyDataObjects.forEach(weeklyData => {
                smSeries.push(weeklyData.getBaComp())
            });
            this.boston.WeeklyDataObjects.forEach(element => {
                labels.push('Week ' + element.weekNumber)
            });
            recCompAll = recCompAll.concat(bostonSeries);
            recCompAll = recCompAll.concat(mdSeries);
            recCompAll = recCompAll.concat(sacSeries);
            recCompAll = recCompAll.concat(txSeries);
            recCompAll = recCompAll.concat(vaSeries);
            recCompAll = recCompAll.concat(smSeries);
            graphData.labels = labels;
            graphData.series.push(bostonSeries);
            graphData.series.push(mdSeries);
            graphData.series.push(sacSeries);
            graphData.series.push(txSeries);
            graphData.series.push(vaSeries);
            graphData.series.push(smSeries);
            graphData.high = Math.max.apply(null, recCompAll) + 2
            graphData.low = Math.min.apply(null, recCompAll) - 1
            return graphData
        }

        getTaCompGraphData() {
            var graphData = { labels: [], series: [], high: 100, low: 0 }
            var bostonSeries = []
            var mdSeries = []
            var sacSeries = []
            var txSeries = []
            var vaSeries = []
            var smSeries = []
            var labels = []
            var recCompAll = []
            this.boston.WeeklyDataObjects.forEach(weeklyData => {
                bostonSeries.push(weeklyData.getTaComp())
            });
            this.md.WeeklyDataObjects.forEach(weeklyData => {
                mdSeries.push(weeklyData.getTaComp())
            });
            this.sac.WeeklyDataObjects.forEach(weeklyData => {
                sacSeries.push(weeklyData.getTaComp())
            });
            this.tx.WeeklyDataObjects.forEach(weeklyData => {
                txSeries.push(weeklyData.getTaComp())
            });
            this.va.WeeklyDataObjects.forEach(weeklyData => {
                vaSeries.push(weeklyData.getTaComp())
            });
            this.sm.WeeklyDataObjects.forEach(weeklyData => {
                smSeries.push(weeklyData.getTaComp())
            });
            this.boston.WeeklyDataObjects.forEach(element => {
                labels.push('Week ' + element.weekNumber)
            });
            recCompAll = recCompAll.concat(bostonSeries);
            recCompAll = recCompAll.concat(mdSeries);
            recCompAll = recCompAll.concat(sacSeries);
            recCompAll = recCompAll.concat(txSeries);
            recCompAll = recCompAll.concat(vaSeries);
            recCompAll = recCompAll.concat(smSeries);
            graphData.labels = labels;
            graphData.series.push(bostonSeries);
            graphData.series.push(mdSeries);
            graphData.series.push(sacSeries);
            graphData.series.push(txSeries);
            graphData.series.push(vaSeries);
            graphData.series.push(smSeries);
            graphData.high = Math.max.apply(null, recCompAll) + 2
            graphData.low = Math.min.apply(null, recCompAll) - 1
            return graphData
        }

        getVerCompGraphData() {
            var graphData = { labels: [], series: [], high: 100, low: 0 }
            var bostonSeries = []
            var mdSeries = []
            var sacSeries = []
            var txSeries = []
            var vaSeries = []
            var smSeries = []
            var labels = []
            var recCompAll = []
            this.boston.WeeklyDataObjects.forEach(weeklyData => {
                bostonSeries.push(weeklyData.getVerComp())
            });
            this.md.WeeklyDataObjects.forEach(weeklyData => {
                mdSeries.push(weeklyData.getVerComp())
            });
            this.sac.WeeklyDataObjects.forEach(weeklyData => {
                sacSeries.push(weeklyData.getVerComp())
            });
            this.tx.WeeklyDataObjects.forEach(weeklyData => {
                txSeries.push(weeklyData.getVerComp())
            });
            this.va.WeeklyDataObjects.forEach(weeklyData => {
                vaSeries.push(weeklyData.getVerComp())
            });
            this.sm.WeeklyDataObjects.forEach(weeklyData => {
                smSeries.push(weeklyData.getVerComp())
            });
            this.boston.WeeklyDataObjects.forEach(element => {
                labels.push('Week ' + element.weekNumber)
            });
            recCompAll = recCompAll.concat(bostonSeries);
            recCompAll = recCompAll.concat(mdSeries);
            recCompAll = recCompAll.concat(sacSeries);
            recCompAll = recCompAll.concat(txSeries);
            recCompAll = recCompAll.concat(vaSeries);
            recCompAll = recCompAll.concat(smSeries);
            graphData.labels = labels;
            graphData.series.push(bostonSeries);
            graphData.series.push(mdSeries);
            graphData.series.push(sacSeries);
            graphData.series.push(txSeries);
            graphData.series.push(vaSeries);
            graphData.series.push(smSeries);
            graphData.high = Math.max.apply(null, recCompAll) + 2
            graphData.low = Math.min.apply(null, recCompAll) - 1
            return graphData
        }
    }

    function UpdateWeeklyLocationData(docData, location, dataObject, week) {
        switch (docData.DealWIPStatus) {
            case 'Arrived':
                dataObject[location].WeeklyDataObjects[week.weekArrayPosition].Arrived++;
                if (docData.CompliantToRecSLA) {
                    dataObject[location].WeeklyDataObjects[week.weekArrayPosition].ArrivedCompliant++;
                }
                break;
            case 'Basic Audit WIP':
            case 'Offloaded':
                if (docData.DispositionRouting != 'SORT') {
                    dataObject[location].WeeklyDataObjects[week.weekArrayPosition].BasicWip++;
                    if (docData.CompliantToBasicAuditSLA) {
                        dataObject[location].WeeklyDataObjects[week.weekArrayPosition].BasicWipCompliant++;
                    }
                }
                break;
            case 'Tech Audit WIP':
                if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                    dataObject[location].WeeklyDataObjects[week.weekArrayPosition].TechAuditWip++;
                    if (docData.CompliantToTechAuditSLA) {
                        dataObject[location].WeeklyDataObjects[week.weekArrayPosition].TechAuditWipCompliant++;
                    }
                }
                break;
            case 'Verification WIP':
                if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                    if (docData.CustomerPending === false) {
                        dataObject[location].WeeklyDataObjects[week.weekArrayPosition].Verification++;
                        if (docData.VerificationCompliantToSLA) {
                            dataObject[location].WeeklyDataObjects[week.weekArrayPosition].VerificationCompliant++;
                        }
                    }
                    else {
                        if (docData.CustomerReleased) {
                            dataObject[location].WeeklyDataObjects[week.weekArrayPosition].Verification++;
                            if (docData.VerificationCompliantToSLA) {
                                dataObject[location].WeeklyDataObjects[week.weekArrayPosition].VerificationCompliant++;
                            }
                        }
                    }
                }
                break;
            case 'Sales':
                dataObject[location].WeeklyDataObjects[week.weekArrayPosition].Sales++;
                if (docData.CompliantTurnoutSLA) {
                    dataObject[location].WeeklyDataObjects[week.weekArrayPosition].SalesCompliant++;
                }
                break;

            default:
                break;
        }
        if (docData.DealWIPStatus != 'Sales') {
            dataObject[location].WeeklyDataObjects[week.weekArrayPosition].WipTurnoutRequired++;
            if (docData.CompliantTurnoutSLA) {
                dataObject[location].WeeklyDataObjects[week.weekArrayPosition].WipTurnoutCompliant++;
            }
        }
    }

    function CalculateAllVariables(snap, weeks, dataObject) {
        // console.log('before', dataObject);

        snap.forEach(doc => {
            var docData = doc.data();
            // console.log(docData)
            weeks.forEach(week => {
                if (moment(docData.dateCreated.toDate()).isBetween(week.weekStart, week.weekEnd)) {
                    switch (docData.LocationCode) {
                        case bostonCode:
                            UpdateWeeklyLocationData(docData, 'boston', dataObject, week)
                            break;

                        case mdCode:
                            UpdateWeeklyLocationData(docData, 'md', dataObject, week)
                            break;

                        case sacCode:
                            UpdateWeeklyLocationData(docData, 'sac', dataObject, week)
                            break;

                        case smCode:
                            UpdateWeeklyLocationData(docData, 'sm', dataObject, week)
                            break;

                        case txCode:
                            UpdateWeeklyLocationData(docData, 'tx', dataObject, week)
                            break;

                        case vaCode:
                            UpdateWeeklyLocationData(docData, 'va', dataObject, week)
                            break;

                        default:
                            break;
                    }
                }
            });
        })
        // console.log('after', dataObject)
        return dataObject
    }

    class DailyData {
        // if "Arrived" in "DealWIPStatus"
        Arrived = 0;
        // if "CompliantToRecSLA" true
        ArrivedCompliant = 0;
        // if "Basic Audit WIP" or "Offloaded" in "DealWIPStatus" and
        // everything else except "SORT" in "DispositionRouting"
        BasicWip = 0;
        // if "CompliantToBasicAuditSLA" true
        BasicWipCompliant = 0;
        // if "Tech Audit WIP" in "DealWIPStatus" and "AUDIT" "PICK" in "DispositionRouting"
        TechAuditWip = 0;
        // if "CompliantToTechAuditSLA" true
        TechAuditWipCompliant = 0;
        // if "Verification WIP" in "DealWIPStatus" and "AUDIT"  "PICK" in "DispositionRouting"
        Verification = 0;
        // if "VerificationCompliantToSLA" true
        VerificationCompliant = 0;
        // "Arrived" "Basic Audit WIP" "Offloaded" "Tech Audit WIP" "Verification WIP" in "DealWIPStatus"
        WipTurnoutRequired = 0;
        // if "CompliantTurnoutSLA" true
        WipTurnoutCompliant = 0;
        // "Sales" in "DealWIPStatus"
        Sales = 0;
        // if "CompliantTurnoutSLA" true
        SalesCompliant = 0;
        //  var ...ArrivedCompliant / ...Arrived
        RecComp = 0;
        // var ...BasicWipCompliant / ...BasicWip
        BaComp = 0;
        // var ...TechAuditWipCompliant / ...TechAuditWip
        TaComp = 0;
        // var ...VerificationCompliant / Verification
        VerComp = 0;
        // var ...WipTurnoutCompliant / WipTurnoutRequired
        WipTurnComp = 0;
        // var ...SalesCompliant / ...Sales
        CsTurnComp = 0;

        constructor() {

        }
        getDataArray() {
            //'Rec Wip' }, { key: 2, label: 'Compliant' }, { key: 3, label: 'Basic Audit Wip' }, { key: 4, label: 'Compliant' }, 
            //{ key: 5, label: 'Tech Audit Wip' }, { key: 6, label: 'Compliant' }, { key: 7, label: 'Verification' }, { key: 8, label: 'Compliant' }, { key: 9, label: 'Wip Turnout Required' }, { key: 10, label: 'WIP Turnout Compliant' }, { key: 11, label: 'CS Turnout' }, { key: 12, label: 'CS Turnout Compliant' }, { key: 13, label: 'Rec Compliant' }, { key: 14, label: 'BA Compliant' }, { key: 15, label: 'TA Compliant' }, { key: 16, label: 'Ver Compliant' }, { key: 17, label: 'WIP Turnout Compliant' }, { key: 18, label: 'CS Turnout Compliant'
            var dataArray = [this.Arrived, this.ArrivedCompliant, this.BasicWip, this.BasicWipCompliant, this.TechAuditWip, this.TechAuditWipCompliant, this.Verification, this.VerificationCompliant, this.WipTurnoutRequired, this.WipTurnoutCompliant, this.Sales, this.SalesCompliant, this.getRecComp(), this.getBaComp(), this.getTaComp(), this.getVerComp(), this.getWipTurnComp(), this.getCsTurnComp()]
            return dataArray
        }
        //  var ...ArrivedCompliant / Arrived
        getRecComp() {
            this.RecComp = this.ArrivedCompliant == 0 && this.Arrived == 0 ? 100 : this.ArrivedCompliant == 0 || this.Arrived == 0 ? 0 : Math.round(((this.ArrivedCompliant / this.Arrived) * 100) * 10) / 10; //to have decimal percentage
            return this.RecComp;
        }
        // var ...BasicWipCompliant / BasicWip
        getBaComp() {
            this.BaComp = this.BasicWipCompliant == 0 && this.BasicWip == 0 ? 100 : this.BasicWipCompliant == 0 || this.BasicWip == 0 ? 0 : Math.round(((this.BasicWipCompliant / this.BasicWip) * 100) * 10) / 10;
            return this.BaComp;
        }
        // var ...TechAuditWipCompliant / TechAuditWip
        getTaComp() {
            this.TaComp = this.TechAuditWipCompliant == 0 && this.TechAuditWip == 0 ? 100 : this.TechAuditWipCompliant == 0 || this.TechAuditWip == 0 ? 0 : Math.round(((this.TechAuditWipCompliant / this.TechAuditWip) * 100) * 10) / 10;
            return this.TaComp;
        }
        // var ...VerificationCompliant / Verification
        getVerComp() {
            this.VerComp = this.VerificationCompliant == 0 && this.Verification == 0 ? 100 : this.VerificationCompliant == 0 || this.Verification == 0 ? 0 : Math.round(((this.VerificationCompliant / this.Verification) * 100) * 10) / 10;
            return this.VerComp;
        }
        // var ...WipTurnoutCompliant / WipTurnoutRequired
        getWipTurnComp() {
            this.WipTurnComp = this.WipTurnoutCompliant == 0 && this.WipTurnoutRequired == 0 ? 100 : this.WipTurnoutCompliant == 0 || this.WipTurnoutRequired == 0 ? 0 : Math.round(((this.WipTurnoutCompliant / this.WipTurnoutRequired) * 100) * 10) / 10;
            return this.WipTurnComp;
        }
        // var ...SalesCompliant / Sales
        getCsTurnComp() {
            this.CsTurnComp = this.SalesCompliant == 0 && this.Sales == 0 ? 100 : this.SalesCompliant == 0 || this.Sales == 0 ? 0 : Math.round(((this.SalesCompliant / this.Sales) * 100) * 10) / 10;
            return this.CsTurnComp;
        }
    }

    class LocationDailyData {

        constructor(location) {
            this.location = location;
            this.dailyData = new DailyData();
        }
    }

    class DailyDataObject {
        constructor() {
            this.boston = new LocationDailyData('boston');
            this.md = new LocationDailyData('md');
            this.sac = new LocationDailyData('sac');
            this.sm = new LocationDailyData('sm');
            this.tx = new LocationDailyData('tx');
            this.va = new LocationDailyData('va');
        }

        getDailyRecCompData() {
            var dailyRecCompData = {
                boston: this.boston.dailyData.getRecComp(),
                md: this.md.dailyData.getRecComp(),
                sac: this.sac.dailyData.getRecComp(),
                tx: this.tx.dailyData.getRecComp(),
                va: this.va.dailyData.getRecComp(),
                sm: this.sm.dailyData.getRecComp()
            }
            return dailyRecCompData
        }

        getDailyBaCompData() {
            var dailyBaCompData = {
                boston: this.boston.dailyData.getBaComp(),
                md: this.md.dailyData.getBaComp(),
                sac: this.sac.dailyData.getBaComp(),
                tx: this.tx.dailyData.getBaComp(),
                va: this.va.dailyData.getBaComp(),
                sm: this.sm.dailyData.getBaComp()
            }
            return dailyBaCompData
        }

        getDailyTaCompData() {
            var dailyTaCompData = {
                boston: this.boston.dailyData.getTaComp(),
                md: this.md.dailyData.getTaComp(),
                sac: this.sac.dailyData.getTaComp(),
                tx: this.tx.dailyData.getTaComp(),
                va: this.va.dailyData.getTaComp(),
                sm: this.sm.dailyData.getTaComp()
            }
            return dailyTaCompData
        }

        getDailyVerCompData() {
            var dailyVerCompData = {
                boston: this.boston.dailyData.getVerComp(),
                md: this.md.dailyData.getVerComp(),
                sac: this.sac.dailyData.getVerComp(),
                tx: this.tx.dailyData.getVerComp(),
                va: this.va.dailyData.getVerComp(),
                sm: this.sm.dailyData.getVerComp()
            }
            return dailyVerCompData
        }

        getDailyWipTurnCompData() {
            var dailyWipTurnCompData = {
                boston: this.boston.dailyData.getWipTurnComp(),
                md: this.md.dailyData.getWipTurnComp(),
                sac: this.sac.dailyData.getWipTurnComp(),
                tx: this.tx.dailyData.getWipTurnComp(),
                va: this.va.dailyData.getWipTurnComp(),
                sm: this.sm.dailyData.getWipTurnComp()
            }
            return dailyWipTurnCompData
        }

        getDailyCsTurnCompData() {
            var dailyCsTurnCompData = {
                boston: this.boston.dailyData.getCsTurnComp(),
                md: this.md.dailyData.getCsTurnComp(),
                sac: this.sac.dailyData.getCsTurnComp(),
                tx: this.tx.dailyData.getCsTurnComp(),
                va: this.va.dailyData.getCsTurnComp(),
                sm: this.sm.dailyData.getCsTurnComp()
            }
            return dailyCsTurnCompData
        }
    }

    function UpdateDailyLocationData(docData, location, dataObject) {
        switch (docData.DealWIPStatus) {
            case 'Arrived':
                if (docData.CustomerPending === false) {
                    dataObject[location].dailyData.Arrived++;
                    if (docData.CompliantToRecSLA) {
                        dataObject[location].dailyData.ArrivedCompliant++;
                    }
                }
                else {
                    if (docData.CustomerReleased) {
                        dataObject[location].dailyData.Arrived++;
                        if (docData.CompliantToRecSLA) {
                            dataObject[location].dailyData.ArrivedCompliant++;
                        }
                    }
                }
                break;
            case 'Basic Audit WIP':
            case 'Offloaded':
                if (docData.DispositionRouting != 'SORT') {
                    if (docData.CustomerPending === false) {
                        dataObject[location].dailyData.BasicWip++;
                        if (docData.CompliantToBasicAuditSLA) {
                            dataObject[location].dailyData.BasicWipCompliant++;
                        }
                    }
                    else {
                        if (docData.CustomerReleased) {
                            dataObject[location].dailyData.BasicWip++;
                            if (docData.CompliantToBasicAuditSLA) {
                                dataObject[location].dailyData.BasicWipCompliant++;
                            }
                        }
                    }
                }
                break;
            case 'Tech Audit WIP':
                if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                    if (docData.CustomerPending === false) {
                        dataObject[location].dailyData.TechAuditWip++;
                        if (docData.CompliantToTechAuditSLA) {
                            dataObject[location].dailyData.TechAuditWipCompliant++;
                        }
                    }
                    else {
                        if (docData.CustomerReleased) {
                            dataObject[location].dailyData.TechAuditWip++;
                            if (docData.CompliantToTechAuditSLA) {
                                dataObject[location].dailyData.TechAuditWipCompliant++;
                            }
                        }
                    }
                }
                break;
            case 'Verification WIP':
                if (docData.DispositionRouting === 'AUDIT' || docData.DispositionRouting === 'PICK') {
                    if (docData.CustomerPending === false) {
                        dataObject[location].dailyData.Verification++;
                        if (docData.VerificationCompliantToSLA) {
                            dataObject[location].dailyData.VerificationCompliant++;
                        }
                    }
                    else {
                        if (docData.CustomerReleased) {
                            dataObject[location].dailyData.Verification++;
                            if (docData.VerificationCompliantToSLA) {
                                dataObject[location].dailyData.VerificationCompliant++;
                            }
                        }
                    }
                }
                break;
            case 'Sales':
                if (docData.CustomerPending === false) {
                    dataObject[location].dailyData.Sales++;
                    if (docData.CompliantTurnoutSLA) {
                        dataObject[location].dailyData.SalesCompliant++;
                    }
                }
                else {
                    if (docData.CustomerReleased) {
                        dataObject[location].dailyData.Sales++;
                        if (docData.CompliantTurnoutSLA) {
                            dataObject[location].dailyData.SalesCompliant++;
                        }
                    }
                }
                break;

            default:
                break;
        }
        if (docData.DealWIPStatus != 'Sales') {
            if (docData.CustomerPending === false) {
                dataObject[location].dailyData.WipTurnoutRequired++;
                if (docData.CompliantTurnoutSLA) {
                    dataObject[location].dailyData.WipTurnoutCompliant++;
                }
            }
            else {
                if (docData.CustomerReleased) {
                    dataObject[location].dailyData.WipTurnoutRequired++;
                    if (docData.CompliantTurnoutSLA) {
                        dataObject[location].dailyData.WipTurnoutCompliant++;
                    }
                }
            }
        }
    }

    function CalculateDailyVariables(snap, dataObject, date) {
        // console.log('before', dataObject);
        var startDateTime = new Date(date.setHours(0, 0, 0, 0));
        var endDateTime = new Date(date.setHours(23, 59, 59));
        snap.forEach(doc => {
            var docData = doc.data();
            // console.log(docData)

            if (moment(docData.dateCreated.toDate()).isBetween(startDateTime, endDateTime)) {
                switch (docData.LocationCode) {
                    case bostonCode:
                        UpdateDailyLocationData(docData, 'boston', dataObject)
                        break;

                    case mdCode:
                        UpdateDailyLocationData(docData, 'md', dataObject)
                        break;

                    case sacCode:
                        UpdateDailyLocationData(docData, 'sac', dataObject)
                        break;

                    case smCode:
                        UpdateDailyLocationData(docData, 'sm', dataObject)
                        break;

                    case txCode:
                        UpdateDailyLocationData(docData, 'tx', dataObject)
                        break;

                    case vaCode:
                        UpdateDailyLocationData(docData, 'va', dataObject)
                        break;

                    default:
                        break;
                }
            }
        })
        // console.log('after', dataObject)
        return dataObject
    }

    function ArraySum(array) {
        var sum = 0;
        array.forEach(num => {
            sum = sum + num
        })
        // console.log('sumReturned', sum)
        return sum
    }
    //#endregion

    const handleSearchDateData = (date) => {
        if (moment(date).isValid()) {
            console.log('Going to search data for:', moment.utc(date).format('DD-MMM-YY'))
            setSearchDate(date)
            var dateFormatted = moment.utc(date).format('DD-MMM-YY');
            var searchGraphs = document.getElementById('searchGraphs');
            var searchProgress = document.getElementById('searchGraphProgress');
            searchProgress.style.visibility = 'visible';
            // var startDateTime = new Date(date.setHours(0, 0, 0, 0));
            // var endDateTime = new Date(date.setHours(23, 59, 59));
            var weeks = CalculateWeeks(date);
            var startDateTime = new Date(weeks[0].weekStart);
            var endDateTime = new Date(weeks[11].weekEnd);
            var weekNumber = moment(date).isoWeek();
            var selectedWeekStart = moment(date).startOf('isoWeek').format('DD-MMM-YY');
            var selectedWeekEnd = moment(date).endOf('isoWeek').format('DD-MMM-YY');
            // console.log('startDateTime', startDateTime)
            // console.log('endDateTime', endDateTime)
            var dataObject = new DataObject(weeks)
            var dailyDataObject = new DailyDataObject();

            db.collection("metrics")
                .where("dateCreated", ">=", startDateTime)
                .where("dateCreated", "<=", endDateTime)
                .get()
                .then(snap => {
                    if (snap.empty) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'There was no results for week ' + weekNumber + '. Between ' + selectedWeekStart + ' and ' + selectedWeekEnd,
                        })
                        searchProgress.style.visibility = 'hidden';
                        // searchGraphs.style.display = 'none';
                        searchGraphs.style.display = 'block';
                        return
                    }

                    var returnedDataObject = CalculateAllVariables(snap, weeks, dataObject);
                    // console.log(returnedDataObject)
                    var recCompDataResult = returnedDataObject.getRecCompGraphData();
                    // console.log(recCompDataResult)
                    var baCompDataResult = returnedDataObject.getBaCompGraphData();
                    var taCompDataResult = returnedDataObject.getTaCompGraphData();
                    var verCompDataResult = returnedDataObject.getVerCompGraphData();

                    var returnedDailyData = CalculateDailyVariables(snap, dailyDataObject, date)
                    // console.log('DailyData:', returnedDailyData);
                    var dailyRecComp = returnedDailyData.getDailyRecCompData();
                    var dailyBaComp = returnedDailyData.getDailyBaCompData();
                    var dailyTaComp = returnedDailyData.getDailyTaCompData();
                    var dailyVerComp = returnedDailyData.getDailyVerCompData();
                    var dailyWipTurnComp = returnedDailyData.getDailyWipTurnCompData();
                    var dailyCsTurnComp = returnedDailyData.getDailyCsTurnCompData();
                    // var dailyRecComp = returnedDailyData.getDailyRecCompData();
                    // var dailyBaComp = returnedDailyData.getDailyBaCompData();
                    // var dailyTaComp = returnedDailyData.getDailyTaCompData();
                    // var dailyVerComp = returnedDailyData.getDailyVerCompData();
                    // var dailyWipTurnComp = returnedDailyData.getDailyWipTurnCompData();
                    // var dailyCsTurnComp = returnedDailyData.getDailyCsTurnCompData();
                    var dailyBoston = returnedDailyData.boston.dailyData.getDataArray();
                    var dailyMD = returnedDailyData.md.dailyData.getDataArray();
                    var dailySAC = returnedDailyData.sac.dailyData.getDataArray();
                    var dailySM = returnedDailyData.sm.dailyData.getDataArray();
                    var dailyTX = returnedDailyData.tx.dailyData.getDataArray();
                    var dailyVA = returnedDailyData.va.dailyData.getDataArray();

                    var wipHighAll = []
                    wipHighAll = wipHighAll.concat(dailyBoston)
                    wipHighAll = wipHighAll.concat(dailyMD)
                    wipHighAll = wipHighAll.concat(dailySAC)
                    wipHighAll = wipHighAll.concat(dailySM)
                    wipHighAll = wipHighAll.concat(dailyTX)
                    wipHighAll = wipHighAll.concat(dailyVA)
                    var cellWipHigh = Math.max.apply(null, wipHighAll) + 1
                    var cellWipLow = -1

                    //checking if daily is empty then hide table and graph
                    var sumInArray = 600;
                    var isEmpty = true;
                    if (ArraySum(dailyBoston) === sumInArray && ArraySum(dailyMD) === sumInArray && ArraySum(dailySAC) === sumInArray && ArraySum(dailySM) === sumInArray && ArraySum(dailyTX) === sumInArray && ArraySum(dailyVA) === sumInArray) {
                        isEmpty = true;
                    }
                    else {
                        isEmpty = false;
                    }
                    // console.log(isEmpty)

                    searchGraphs.style.display = 'block';
                    searchProgress.style.visibility = 'hidden';

                    setAllWeekDurations(weeks);
                    setRecCompGraph({
                        ...recCompGraph,
                        data: recCompDataResult,
                        // options: {
                        //     high: recCompDataResult.high,
                        //     low: recCompDataResult.low,
                        // }
                    });
                    setBaCompGraph({
                        ...baCompGraph,
                        data: baCompDataResult,
                        // options: {
                        //     high: baCompDataResult.high,
                        //     low: baCompDataResult.low,
                        // }
                    });
                    setTaCompGraph({
                        ...taCompGraph,
                        data: taCompDataResult,
                        // options: {
                        //     high: taCompDataResult.high,
                        //     low: taCompDataResult.low,
                        // }
                    });
                    setVerCompGraph({
                        ...verCompGraph,
                        data: verCompDataResult,
                        // options: {
                        //     high: verCompDataResult.high,
                        //     low: verCompDataResult.low,
                        // }
                    });
                    setDailyData({
                        ...dailyData,
                        isEmpty: isEmpty,
                        boston: returnedDailyData.boston.dailyData,
                        md: returnedDailyData.md.dailyData,
                        sac: returnedDailyData.sac.dailyData,
                        sm: returnedDailyData.sm.dailyData,
                        tx: returnedDailyData.tx.dailyData,
                        va: returnedDailyData.va.dailyData,
                        yLabels: [
                            { key: 1, name: 'ITADBOSTON', data: dailyBoston },
                            { key: 2, name: 'ITADMD', data: dailyMD },
                            { key: 3, name: 'ITADSAC', data: dailySAC },
                            { key: 4, name: 'ITADSM', data: dailySM },
                            { key: 5, name: 'ITADTX', data: dailyTX },
                            { key: 6, name: 'ITADVA', data: dailyVA }
                        ],
                    });
                    setCellWipGraph({
                        ...cellWipGraph,
                        data: {
                            // labels: ['Rec  |  BA  |  TA  |  Verify  |  Wip TO  |  CS TO\nBOSTON', 'Rec  |  BA  |  TA  |  Verify  |  Wip TO  |  CS TO\nMD', 'Rec  |  BA  |  TA  |  Verify  |  Wip TO  |  CS TO\nSAC', 'Rec  |  BA  |  TA  |  Verify  |  Wip TO  |  CS TO\nSM', 'Rec  |  BA  |  TA  |  Verify  |  Wip TO  |  CS TO\nTX', 'Rec  |  BA  |  TA  |  Verify  |  Wip TO  |  CS TO\nVA'],
                            labels: ['BOSTON', 'MD', 'SAC', 'SM', 'TX', 'VA'],
                            series: [
                                [dailyBoston[0], dailyMD[0], dailySAC[0], dailySM[0], dailyTX[0], dailyVA[0]],
                                [dailyBoston[2], dailyMD[2], dailySAC[2], dailySM[2], dailyTX[2], dailyVA[2]],
                                [dailyBoston[4], dailyMD[4], dailySAC[4], dailySM[4], dailyTX[4], dailyVA[4]],
                                [dailyBoston[6], dailyMD[6], dailySAC[6], dailySM[6], dailyTX[6], dailyVA[6]],
                                [dailyBoston[8], dailyMD[8], dailySAC[8], dailySM[8], dailyTX[8], dailyVA[8]],
                                [dailyBoston[10], dailyMD[10], dailySAC[10], dailySM[10], dailyTX[10], dailyVA[10]]
                            ],
                        },
                        options: {
                            high: cellWipHigh,
                            low: cellWipLow,
                        }
                    });
                    setCellWipCompGraph({
                        ...cellWipCompGraph,
                        data: {
                            labels: ['Receiving', 'Basic Audit', 'Tech Audit', 'Verification', 'Wip Turnout', 'CS Turnout'],
                            series: [
                                [dailyBoston[12], dailyBoston[13], dailyBoston[14], dailyBoston[15], dailyBoston[16], dailyBoston[17]],
                                [dailyMD[12], dailyMD[13], dailyMD[14], dailyMD[15], dailyMD[16], dailyMD[17]],
                                [dailySAC[12], dailySAC[13], dailySAC[14], dailySAC[15], dailySAC[16], dailySAC[17]],
                                [dailySM[12], dailySM[13], dailySM[14], dailySM[15], dailySM[16], dailySM[17]],
                                [dailyTX[12], dailyTX[13], dailyTX[14], dailyTX[15], dailyTX[16], dailyTX[17]],
                                [dailyVA[12], dailyVA[13], dailyVA[14], dailyVA[15], dailyVA[16], dailyVA[17]]
                            ]
                        },
                    })
                })
        }
        else {
            setSearchDate(new Date())
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Invalid date. Please select date.',
            })
        }
    }

    const handleGraphChange = (e) => {
        var process = e.target.id.slice(0, 2)
        // console.log(process)
        var location = e.target.id.slice(2, 4)
        // console.log(location)
        var number = 0;
        switch (process) {
            case 're':
                number = 0;
                break;
            case 'ba':
                number = 1;
                break;
            case 'ta':
                number = 2;
                break;
            case 've':
                number = 3;
                break;
            case 'ce':
                number = 5; // 4 is bars graph
                break;

            default:
                break;
        }
        switch (location) {
            case 'BO':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[number].style.visibility == '' || graph[number].style.visibility == 'visible' ? graph[number].style.visibility = 'hidden' : graph[number].style.visibility = 'visible'
                break;
            case 'MD':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[number].style.visibility == '' || graph[number].style.visibility == 'visible' ? graph[number].style.visibility = 'hidden' : graph[number].style.visibility = 'visible'
                break;
            case 'SA':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[number].style.visibility == '' || graph[number].style.visibility == 'visible' ? graph[number].style.visibility = 'hidden' : graph[number].style.visibility = 'visible'
                break;
            case 'TX':
                var graph = document.getElementsByClassName('ct-series-d');
                graph[number].style.visibility == '' || graph[number].style.visibility == 'visible' ? graph[number].style.visibility = 'hidden' : graph[number].style.visibility = 'visible'
                break;
            case 'VA':
                var graph = document.getElementsByClassName('ct-series-e');
                graph[number].style.visibility == '' || graph[number].style.visibility == 'visible' ? graph[number].style.visibility = 'hidden' : graph[number].style.visibility = 'visible'
                break;
            case 'SM':
                var graph = document.getElementsByClassName('ct-series-f');
                graph[number].style.visibility == '' || graph[number].style.visibility == 'visible' ? graph[number].style.visibility = 'hidden' : graph[number].style.visibility = 'visible'
                break;

            default:
                break;
        }
    }

    return (
        <div>
            <Card>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <KeyboardDatePicker
                            margin="normal"
                            id="searchDate"
                            disableFuture
                            // label="From Date:"
                            name="searchDate"
                            format="dd-MMM-yy"
                            // error // makes text red
                            helperText="Please select Date to display graphs"
                            // required
                            // type="date"
                            className={classes.datePickerCenter}
                            value={searchDate}
                            onChange={handleSearchDateData}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
                <LinearProgress id='searchGraphProgress' className={classes.searchProgress} />
                <div className={classes.searchGraphs} id='searchGraphs'>
                    {allWeekDurations.length === 0 ? null :
                        <Typography className={classes.center} variant="h6">
                            Weekly Data Results from week {allWeekDurations[0].weekNumber} to week {allWeekDurations[11].weekNumber}
                        </Typography>
                    }
                    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.marginTop}>
                        <Grid item xs={6} className={classes.marginLeftRight}>
                            <Typography className={classes.graphHeader} variant="h6">
                                Receiving Wip % Compliant
                            </Typography>

                            <ChartistGraph
                                id='recCompGraph'
                                data={recCompGraph.data}
                                type={recCompGraph.type}
                                options={recCompGraph.options}
                                listener={recCompGraph.animation}
                                plugins={recCompGraph.plugins}
                            />
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="reBO" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(50, 50, 240)',
                                        '&$checked': {
                                            color: 'rgb(50, 50, 240)',
                                        }
                                    }} />}
                                    label="BOSTON"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="reMD" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(200, 110, 10)',
                                        '&$checked': {
                                            color: 'rgb(200, 110, 10)',
                                        }
                                    }} />}
                                    label="MD"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="reSA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(20, 150, 30)',
                                        '&$checked': {
                                            color: 'rgb(20, 150, 30)',
                                        }
                                    }} />}
                                    label="SAC"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="reTX" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(210, 180, 30)',
                                        '&$checked': {
                                            color: 'rgb(210, 180, 30)',
                                        }
                                    }} />}
                                    label="TX"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="reVA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(110, 110, 110)',
                                        '&$checked': {
                                            color: 'rgb(110, 110, 110)',
                                        }
                                    }} />}
                                    label="VA"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="reSM" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(130, 10, 200)',
                                        '&$checked': {
                                            color: 'rgb(130, 10, 200)',
                                        }
                                    }} />}
                                    label="SM"
                                />

                            </Grid>
                        </Grid>

                        <Grid item xs={6} className={classes.marginLeftRight}>
                            <Typography className={classes.graphHeader} variant="h6">
                                Basic Audit Wip % Compliant
                            </Typography>

                            <ChartistGraph
                                id='baCompGraph'
                                data={baCompGraph.data}
                                type={baCompGraph.type}
                                options={baCompGraph.options}
                                listener={baCompGraph.animation}
                                plugins={baCompGraph.plugins}
                            />
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="baBO" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(50, 50, 240)',
                                        '&$checked': {
                                            color: 'rgb(50, 50, 240)',
                                        }
                                    }} />}
                                    label="BOSTON"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="baMD" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(200, 110, 10)',
                                        '&$checked': {
                                            color: 'rgb(200, 110, 10)',
                                        }
                                    }} />}
                                    label="MD"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="baSA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(20, 150, 30)',
                                        '&$checked': {
                                            color: 'rgb(20, 150, 30)',
                                        }
                                    }} />}
                                    label="SAC"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="baTX" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(210, 180, 30)',
                                        '&$checked': {
                                            color: 'rgb(210, 180, 30)',
                                        }
                                    }} />}
                                    label="TX"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="baVA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(110, 110, 110)',
                                        '&$checked': {
                                            color: 'rgb(110, 110, 110)',
                                        }
                                    }} />}
                                    label="VA"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="baSM" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(130, 10, 200)',
                                        '&$checked': {
                                            color: 'rgb(130, 10, 200)',
                                        }
                                    }} />}
                                    label="SM"
                                />

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.marginTop}>
                        <Grid item xs={6} className={classes.marginLeftRight}>
                            <Typography className={classes.graphHeader} variant="h6">
                                Tech Audit Wip % Compliant
                            </Typography>

                            <ChartistGraph
                                id='taCompGraph'
                                data={taCompGraph.data}
                                type={taCompGraph.type}
                                options={taCompGraph.options}
                                listener={taCompGraph.animation}
                                plugins={taCompGraph.plugins}
                            />
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="taBO" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(50, 50, 240)',
                                        '&$checked': {
                                            color: 'rgb(50, 50, 240)',
                                        }
                                    }} />}
                                    label="BOSTON"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="taMD" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(200, 110, 10)',
                                        '&$checked': {
                                            color: 'rgb(200, 110, 10)',
                                        }
                                    }} />}
                                    label="MD"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="taSA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(20, 150, 30)',
                                        '&$checked': {
                                            color: 'rgb(20, 150, 30)',
                                        }
                                    }} />}
                                    label="SAC"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="taTX" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(210, 180, 30)',
                                        '&$checked': {
                                            color: 'rgb(210, 180, 30)',
                                        }
                                    }} />}
                                    label="TX"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="taVA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(110, 110, 110)',
                                        '&$checked': {
                                            color: 'rgb(110, 110, 110)',
                                        }
                                    }} />}
                                    label="VA"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="taSM" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(130, 10, 200)',
                                        '&$checked': {
                                            color: 'rgb(130, 10, 200)',
                                        }
                                    }} />}
                                    label="SM"
                                />

                            </Grid>
                        </Grid>

                        <Grid item xs={6} className={classes.marginLeftRight}>
                            <Typography className={classes.graphHeader} variant="h6">
                                Verification % Compliant
                            </Typography>

                            <ChartistGraph
                                id='verCompGraph'
                                data={verCompGraph.data}
                                type={verCompGraph.type}
                                options={verCompGraph.options}
                                listener={verCompGraph.animation}
                                plugins={verCompGraph.plugins}
                            />
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="veBO" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(50, 50, 240)',
                                        '&$checked': {
                                            color: 'rgb(50, 50, 240)',
                                        }
                                    }} />}
                                    label="BOSTON"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="veMD" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(200, 110, 10)',
                                        '&$checked': {
                                            color: 'rgb(200, 110, 10)',
                                        }
                                    }} />}
                                    label="MD"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="veSA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(20, 150, 30)',
                                        '&$checked': {
                                            color: 'rgb(20, 150, 30)',
                                        }
                                    }} />}
                                    label="SAC"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="veTX" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(210, 180, 30)',
                                        '&$checked': {
                                            color: 'rgb(210, 180, 30)',
                                        }
                                    }} />}
                                    label="TX"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="veVA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(110, 110, 110)',
                                        '&$checked': {
                                            color: 'rgb(110, 110, 110)',
                                        }
                                    }} />}
                                    label="VA"
                                />
                                <FormControlLabel className={classes.ticks}
                                    control={<Checkbox onChange={handleGraphChange} id="veSM" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                        color: 'rgb(130, 10, 200)',
                                        '&$checked': {
                                            color: 'rgb(130, 10, 200)',
                                        }
                                    }} />}
                                    label="SM"
                                />

                            </Grid>
                        </Grid>
                    </Grid>

                    <Typography className={classes.center} variant="h6">
                        Daily Data Results for {moment(searchDate).format('DD MMMM YYYY')}
                    </Typography>

                    {dailyData.isEmpty ?
                        <Typography className={classes.centerParagraph} variant="body2">
                            There was no uploads on {moment(searchDate).format('DD MMMM YYYY')}
                        </Typography>
                        :
                        <div>
                            <HeatMap incomingData={dailyData} />

                            <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.marginTopAndBottom}>
                                <Grid item xs={12} className={classes.marginLeftRight}>
                                    <Typography className={classes.graphHeader} variant="h6">
                                        Cell Wip by Site
                                    </Typography>

                                    <ChartistGraph
                                        id='cellWipGraph'
                                        data={cellWipGraph.data}
                                        type={cellWipGraph.type}
                                        options={cellWipGraph.options}
                                        // listener={cellWipGraph.animation}
                                        plugins={cellWipGraph.plugins}
                                    />
                                    <Grid container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center">

                                        <div className={classes.green}>.</div><p>Receiving</p>
                                        <div className={classes.blue}>.</div><p>Basic Audit</p>
                                        <div className={classes.grey}>.</div><p>Tech Audit</p>
                                        <div className={classes.yellow}>.</div><p>Verification</p>
                                        <div className={classes.orange}>.</div><p>Wip Turnout</p>
                                        <div className={classes.purple}>.</div><p>CS Tournout</p>

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={classes.marginTopLeftRight}>
                                    <Typography className={classes.graphHeader} variant="h6">
                                        Cell Wip % Waybill Compliant
                                    </Typography>

                                    <ChartistGraph
                                        id='cellWipCompGraph'
                                        data={cellWipCompGraph.data}
                                        type={cellWipCompGraph.type}
                                        options={cellWipCompGraph.options}
                                        listener={cellWipCompGraph.animation}
                                        plugins={cellWipCompGraph.plugins}
                                    />
                                    <Grid container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center">

                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChange} id="ceBO" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(50, 50, 240)',
                                                '&$checked': {
                                                    color: 'rgb(50, 50, 240)',
                                                }
                                            }} />}
                                            label="BOSTON"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChange} id="ceMD" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(200, 110, 10)',
                                                '&$checked': {
                                                    color: 'rgb(200, 110, 10)',
                                                }
                                            }} />}
                                            label="MD"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChange} id="ceSA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(20, 150, 30)',
                                                '&$checked': {
                                                    color: 'rgb(20, 150, 30)',
                                                }
                                            }} />}
                                            label="SAC"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChange} id="ceTX" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(210, 180, 30)',
                                                '&$checked': {
                                                    color: 'rgb(210, 180, 30)',
                                                }
                                            }} />}
                                            label="TX"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChange} id="ceVA" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(110, 110, 110)',
                                                '&$checked': {
                                                    color: 'rgb(110, 110, 110)',
                                                }
                                            }} />}
                                            label="VA"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChange} id="ceSM" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(130, 10, 200)',
                                                '&$checked': {
                                                    color: 'rgb(130, 10, 200)',
                                                }
                                            }} />}
                                            label="SM"
                                        />

                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    }

                </div>
            </Card>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    )
}
