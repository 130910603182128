import { makeStyles } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { db } from '../../../config/fbConfig';
import { AuthContext } from '../../../contexts/AuthContext';
import UserTasksDetails from './UserTasksDetails';

const useStyles = makeStyles(theme => ({
    userTasks: {
        padding: '15px'

    },
    leftMargin: {
        marginLeft: '30px',
    },
}));

export default function UserTasks() {
    const classes = useStyles();
    const { firstName, uid } = useContext(AuthContext);

    const [tasks, setTasks] = useState({
        assignedUserLeave: 0,
        weekTotalAvailableHours: 0,
        weekTotalCapacityRemaining: 0,
        weekTotalPlannedHours: 0,
        columns: [
            { title: 'Week #', field: 'weekNo', editable: 'never' },
            { title: 'Assigned By', field: 'assignedBy', editable: 'never' },
            { title: 'Task Category', field: 'taskCategory', editable: 'never' },
            { title: 'Manager Comment', field: 'additionalComment', editable: 'never' },
            { title: 'User Comment', field: 'additionalUserComment' },
            { title: 'Allocated %', field: 'allocatedPercent', editable: 'never' },
            { title: 'Allocated h', field: 'allocatedHours', editable: 'never' },
            { title: 'Actual h', field: 'actualHours', editable: 'never' },
        ],
        data: [
            // { actualHours: 0, allocatedHours: 9 + ' h', allocatedPercent: 30 + ' %', assignedBy: "John-Paul Collins", assignedTo: "6swPjBlta5XPapuq4FIgZQLrDPB3", assignedUserLeave:1, taskCategory: "Issues", weekNo: 3,weekTotalAvailableHours:30 },
            // { actualHours: 0, allocatedHours: 9 + ' h', allocatedPercent: 30 + ' %', assignedBy: "John-Paul Collins", assignedTo: "6swPjBlta5XPapuq4FIgZQLrDPB3", assignedUserLeave:1, taskCategory: "Change Requests", weekNo: 3,weekTotalAvailableHours:30 },
            // { actualHours: 0, allocatedHours: 9 + ' h', allocatedPercent: 30 + ' %', assignedBy: "John-Paul Collins", assignedTo: "6swPjBlta5XPapuq4FIgZQLrDPB3", assignedUserLeave:1, taskCategory: "Planned Projects", weekNo: 3,weekTotalAvailableHours:30 },
            // { actualHours: 0, allocatedHours: 3 + ' h', allocatedPercent: 10 + ' %', assignedBy: "John-Paul Collins", assignedTo: "6swPjBlta5XPapuq4FIgZQLrDPB3", assignedUserLeave:1, taskCategory: "Calls & Meetings", weekNo: 3,weekTotalAvailableHours:30 }
        ]
    })

    // get data
    const getUserTasks = async () => {
        // var gotUID = await uid
        // console.log(gotUID)
        const weekNo = moment(new Date(), 'MM-DD-YYYY').isoWeek();
        const dataItems = db.collection("userTasks")
            .where("weekNo", "==", weekNo)

        const data = await dataItems.get()
        if (data.empty) {
            return
        }

        var userTasks = [];
        var userPlannedHours = 0
        var userCapacityRemaining = 0
        data.docs.forEach(doc => {
            if (doc.data().assignedTo === uid) {
                const docData = doc.data();
                docData.id = doc.id;
                userTasks.push(docData)
                userPlannedHours += docData.allocatedHours
            }
        })
        // user leave is max number from this weeks tasks array
        var userLeave = Math.max(...userTasks.map(obj => obj.assignedUserLeave), 0);
        var userAvailableHoursRounded = (37.5 - (userLeave * 7.5)).toFixed(1);
        // calculate plannedHours by adding all allocated hours for this user
        var userPlannedHoursRounded = userPlannedHours.toFixed(1);
        // calculate remaining % of user workload(capacity)
        userCapacityRemaining = ((userAvailableHoursRounded - userPlannedHoursRounded) / userAvailableHoursRounded) * 100
        // console.log('Capcity remaining is:', userCapacityRemaining)
        // console.log('userPlanedHours is:', userPlannedHours)
        // console.log('userAvailableHoursRounded is:', userAvailableHoursRounded)

        setTasks({
            ...tasks,
            assignedUserLeave: userLeave,
            weekTotalAvailableHours: userAvailableHoursRounded,
            weekTotalCapacityRemaining: userCapacityRemaining,
            weekTotalPlannedHours: userPlannedHoursRounded,
            data: userTasks,
        })
    }

    useEffect(() => {
        // console.log('loop!')
        getUserTasks()
        return () => { }
    }, [uid])

    return (

        // <Grid container spacing={3}
        //     direction="row"
        //     justifyContent="center"
        //     alignItems="center"
        // >
        //     <Grid item xs={12}>
        <MaterialTable
            title={"My weekly tasks"}
            columns={tasks.columns}
            data={tasks.data}
            options={{
                // actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50]
                actionsColumnIndex: -1, pageSize: 5, pageSizeOptions: [5, 10, 50]
            }}//, { value: ticketsIT.data.length, label: 'All' }] }}
            actions={
                [{
                    icon: 'refresh',
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () => {
                        getUserTasks()
                    },
                }]
            }
            detailPanel={rowData => {
                // console.log(rowData)
                return (
                    <UserTasksDetails userData={rowData} refreshTasks={getUserTasks} />
                )
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
            editable={{
                // onRowAdd: newData =>
                //     new Promise(resolve => {
                //         // setTimeout(() => {
                //         //     resolve();
                //         //     setActionTrackers(prevState => {
                //         //         const data = [...prevState.data];
                //         //         data.push(newData);
                //         //         addManagementActionTracker(newData)
                //         //             .then(resp => {
                //         //                 setSnackbar({
                //         //                     open: true,
                //         //                     severity: 'success',
                //         //                     message: resp,
                //         //                 })
                //         //             })

                //         //         getManagementActionTrackers()
                //         //         return { ...prevState, data };
                //         //     });
                //         // }, 600);
                //     }),
                // onRowUpdate: (newData, oldData) =>
                //     new Promise(resolve => {
                //         setTimeout(() => {
                //             if (oldData) {
                //                 setTicketsIT(prevState => {
                //                     const data = [...prevState.data];
                //                     data[data.indexOf(oldData)] = newData;
                //                     if (newData.Status == 'Completed' || newData.Status == 'Closed - Not Needed') {
                //                         var date = new Date();
                //                         var today = date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();
                //                         newData.ClosedDate = today;
                //                     }
                //                     console.log('Updated issue error ticket in DB')
                //                     // Update data
                //                     // if closed or not needed then add closing date
                //                     db.collection("issueErrorTickets").doc(newData.ticketId).update({
                //                         Status: newData.Status,
                //                         AssignedIT: newData.AssignedIT,
                //                         RootCauseCategory: newData.RootCauseCategory,
                //                         FixType: newData.FixType,
                //                         FailureCategory: newData.FailureCategory,
                //                         FixUpdateDescription: newData.FixUpdateDescription,
                //                         ClosedDate: newData.ClosedDate,
                //                     });
                //                     resolve();
                //                     return { ...prevState, data };
                //                 })
                //             }
                //         }, 600)
                //     }),
                // onRowDelete: oldData =>
                //     new Promise(resolve => {
                //         setTimeout(() => {
                //             resolve();
                //             setIssueError(prevState => {
                //                 const data = [...prevState.data];
                //                 data.splice(data.indexOf(oldData), 1);
                //                 return { ...prevState, data };
                //             });
                //         }, 600);
                //     }),
            }}
            components={{
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div >
                            <p className={classes.leftMargin}>Hey {firstName}! This week you have {tasks.assignedUserLeave} approved days of holidays. So 100% of your workload is {tasks.weekTotalAvailableHours} hours.</p>
                            <p className={classes.leftMargin}>There are {tasks.data.length} planned tasks for you, this should take {tasks.weekTotalPlannedHours} hours to accomplish.</p>
                            <p className={classes.leftMargin}>Capacity remaining {Number(tasks.weekTotalCapacityRemaining).toFixed(0)}%.</p>
                        </div>
                    </div>
                ),
            }}
        />
        //     </Grid>
        // </Grid>
    )
}