import React, { useContext, useEffect, useState } from 'react';
import Loading from '../../Loading';
import Footer from '../../../Footer'
import { AuthContext } from '../../../../contexts/AuthContext';
import { makeStyles, CssBaseline, } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { db } from '../../../../config/fbConfig';
import moment from 'moment';
import MaterialTable from 'material-table';
import IncidentDetails from './IncidentDetails';
import { DataContext } from '../../../../contexts/DataContext';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },

}));
const categoryLookup = {
    // '': '',
    'Phishing link Clicked': 'Phishing link Clicked',
    'Phishing link not Clicked': 'Phishing link not Clicked',
    'Hardware Asset Theft': 'Hardware Asset Theft',
    'Malicious Software Downloaded/Installed': 'Malicious Software Downloaded/Installed',
    'Phishing link Clicked, Username, password entered': 'Phishing link Clicked, Username, password entered',
    'Other': 'Other'
};
const scanLookup = {
    // '':''
    'Quick Scan': 'Quick Scan',
    'Full Scan': 'Full Scan',
    'Full and Offline Scan': 'Full and Offline Scan',
    'Quick Scan NW Discon': 'Quick Scan NW Discon',
    'Full Scan NW Discon': 'Full Scan NW Discon',
    'Full Scan NW Discon Offline Scan': 'Full Scan NW Discon Offline Scan'
};
const riskLookup = {
    'No Risk': 'No Risk',
    'Low Risk': 'Low Risk',
    'Medium Risk': 'Medium Risk',
    'High Risk': 'High Risk',
}
const statusLookup = {
    'Open': 'Open',
    'Closed': 'Closed',
}

const IncidentsISandT = (props) => {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);
    const { addIncidentLog, updateIncidentLog, deleteIncidentLog } = useContext(DataContext);
    const [incidentsIT, setIncidentsIT] = useState({
        columns: [
            { title: 'Reported Date', field: 'ReportedDate', type: 'date', initialEditeValue: 'N/A', filtering: false },
            { title: 'Status', field: 'Status', initialEditeValue: 'Open', lookup: statusLookup, filtering: false },
            { title: 'Category', field: 'Category', lookup: categoryLookup },
            { title: 'Reported By', field: 'ReportedBy' }, //filtering: false
            { title: 'Responder', field: 'Responder' },
            { title: 'User System Scan Type', field: 'UserSystemScanType', lookup: scanLookup },
            { title: 'Risk Severity', field: 'RiskSeverity', lookup: riskLookup },
            { title: 'EPO Monitor', field: 'EPOMonitor' },
            { title: 'Data Loss', field: 'DataLoss', type: 'boolean', initialEditValue: true, filtering: false },
            { title: 'Malware Detected', field: 'MalwareDetected', type: 'boolean', initialEditValue: true, filtering: false },
            { title: 'Sandbox Check', field: 'SandboxCheck', type: 'boolean', initialEditValue: true, filtering: false },
            { title: 'Log Check', field: 'LogCheck', type: 'boolean', initialEditValue: true, filtering: false },
            { title: 'ISMS Team Notified', field: 'ISMSTeamNotified', type: 'boolean', initialEditValue: true, filtering: false },
            { title: '3rd Party Notified', field: 'ThirdPartNotified', type: 'boolean', initialEditValue: true, filtering: false },
            { title: 'NSC Notified', field: 'NSCNotified', type: 'boolean', initialEditValue: true, filtering: false },
            { title: 'CAPA No', field: 'CapaNo' },
            { title: 'Description', field: 'Description', cellStyle: { minWidth: 200, maxWidth: 200 } },
            { title: 'Comments', field: 'Comments', cellStyle: { minWidth: 200, maxWidth: 200 } },
            { title: 'Further Actions', field: 'FurtherActions', cellStyle: { minWidth: 200, maxWidth: 200 } },
            { title: 'Pending Actions', field: 'PendingActions', cellStyle: { minWidth: 200, maxWidth: 200 } },
            { title: 'Closed Date', field: 'dateClosed', type: 'date', initialEditeValue: 'N/A', filtering: false, editable: 'never' },
            { title: 'Created Date', field: 'dateCreated', editable: 'never', hidden: true }
        ],
        data: [],
    });

    //#region Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    function getIncidents() {
        var allIncidents = [];

        db.collection('incidentLogsIT')
            .orderBy("dateCreated", "desc")
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    var creationDate = doc.data().dateCreated ? moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY') : 'N/A'
                    var reportedDate = doc.data().ReportedDate ? moment(doc.data().ReportedDate.toDate()).format('DD MMM YYYY') : 'N/A'
                    var closedDate = doc.data().dateClosed ? moment(doc.data().dateClosed.toDate()).format('DD MMM YYYY') : 'N/A'
                    allIncidents.push({
                        ...doc.data(),
                        id: doc.id,
                        ReportedDate: reportedDate,
                        dateClosed: closedDate,
                        dateCreated: creationDate,
                    });
                })
                setIncidentsIT({
                    ...incidentsIT,
                    data: allIncidents,
                })
            })
            .catch(function (error) {
                console.log("Error getting incidents data: ", error);
            });
    }

    useEffect(() => {
        getIncidents()
        return () => { }
    }, []);

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('memberISandT') || roles.includes('adminISandT') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={39} openISandTColapse={true} />
                            <div className={classes.main}>

                                {/* <h1>Incident Log IS&T</h1> */}

                                <MaterialTable
                                    title={"Incidents"}
                                    columns={incidentsIT.columns}
                                    data={incidentsIT.data}
                                    onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    detailPanel={rowData => {
                                        // console.log(rowData)
                                        return (
                                            <IncidentDetails rowData={rowData} refreshData={getIncidents} />
                                        )
                                    }}
                                    options={{
                                        addRowPosition: 'first',
                                        detailPanelType: "single",
                                        actionsColumnIndex: -1,
                                        exportButton: true,
                                        filtering: false,
                                        pageSize: 5,
                                        pageSizeOptions: [5, 10, 50]
                                    }}//, { value: ticketsIT.data.length, label: 'All' }] }}                                
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getIncidents()
                                                return () => { }
                                            },
                                        }]}
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    addIncidentLog(newData)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            // resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    getIncidents()
                                                    resolve();
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    if (oldData) {
                                                        setIncidentsIT(prevState => {
                                                            const data = [...prevState.data];
                                                            //newData.dateCreated = moment(newData.dateCreated).format('DD MMM YYYY')
                                                            data[data.indexOf(oldData)] = newData;
                                                            console.log('Updated Incident Log row in DB')
                                                            // Update data
                                                            updateIncidentLog(newData)
                                                                .then(success => {
                                                                    //display success
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'success',
                                                                        message: success,
                                                                    });
                                                                    resolve();
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                    //display error
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'error',
                                                                        message: error,
                                                                    });
                                                                })
                                                            getIncidents();
                                                            return { ...prevState, newData };
                                                        })
                                                    }
                                                }, 600)
                                            }),
                                        // onRowDelete: oldData =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             // console.log(oldData.id)
                                        //             deleteIncidentLog(oldData.id)
                                        //                 .then(success => {
                                        //                     //display success
                                        //                     setSnackbar({
                                        //                         open: true,
                                        //                         severity: 'success',
                                        //                         message: success,
                                        //                     });
                                        //                     setIncidentsIT(prevState => {
                                        //                         const data = [...prevState.data];
                                        //                         data.splice(data.indexOf(oldData), 1);
                                        //                         return { ...prevState, data };
                                        //                     });
                                        //                     resolve();
                                        //                 })
                                        //                 .catch(error => {
                                        //                     console.log(error)
                                        //                     //display error
                                        //                     setSnackbar({
                                        //                         open: true,
                                        //                         severity: 'error',
                                        //                         message: error,
                                        //                     });
                                        //                 })
                                        //             // getIncidents();
                                        //         }, 600);
                                        //     }),
                                    }}
                                // components={{
                                //     Toolbar: props => (
                                //         <div>
                                //             <MTableToolbar {...props} />
                                //             <div >
                                //                 <p className={classes.leftMargin}>Hey {firstName}! We need to focus on those {ticketsIT.openTickets} open tickets.</p>
                                //             </div>
                                //         </div>
                                //     ),
                                // }}
                                />

                            </div>
                        </CssBaseline>
                        <Footer />

                        <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}

export default IncidentsISandT