import React from 'react'
import halloween2023 from '../../../../images/HalloweenPoster2023.jpg'
import { makeStyles } from '@material-ui/core';
import halloweenBackground from '../../../../images/halloweenBackground2023.jpg'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // marginLeft: '10%',
        // marginRight: '10%',
        width: '100%',
        backgroundImage: `url(${halloweenBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.9,
        overflow: 'hidden',
        position: 'relative',
        color: '#fff',
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaVideoSlide: {
        width: '100%',
        borderRadius: 20,
    },
    media: {
        width: '40%',
        borderRadius: 20,
        border: '5px solid orange'
    },
    marginLeft: {
        marginLeft: '10%',
    }
}));

export default function Halloween2023() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header>
                <h2 className={classes.textCentered}>☾⋆⁺₊👻🎃🦇´₊⁺~ Halloween - Pumpkin Painting ☾⋆⁺₊🎃👻🦇´₊⁺~</h2>
            </header>
            <br />

            <h3 className={classes.marginLeft}>☾⋆⁺₊🦇´₊⁺ Dear Colleagues, ☾⋆⁺₊🦇´₊⁺</h3>
            <h4 className={classes.marginLeft}>Please join us for some <b>spook-tacular</b> Pumpkin Painting on <b>Friday 27ᵗʰ October</b> to celebrate Halloween at Wisetek.</h4>
            <h4 className={classes.marginLeft}>This will be taking place in your canteen. Times will be allocated accordingly closer to the day.</h4>
            {/* <h4 className={classes.marginLeft}>Join us for a  time of pumpkin painting and creativity as our HR Team hosts a Halloween-themed event! Get ready to unleash your inner artist and give those pumpkins a ghoulish makeover.</h4> */}
            <br />

            <div className={classes.textCentered}>
                <img className={classes.media} src={halloween2023} alt="Halloween 2023" />
                {/* <video className={classes.media} src={EhsWeekCanteenVideo}
                type="video/mp4"
                // poster={posterEHSWeek}
                loop
                controls
                controlsList="nodownload">
                Your browser does not support HTML video.
            </video> */}
            </div>

            <br />
            <h4 className={classes.marginLeft}>All supplies will be provided, so just bring your Halloween spirit and imagination. Let's have a fang-tastic time together! Don't miss out on the fun.</h4>
            <h4 className={classes.marginLeft}>We’ve provided sign-up sheets on all the sites, so if you’re interested, pop your name down!! Looking forward to it!!</h4>

            <h3 className={classes.marginLeft}>☾⋆⁺₊🦇´₊⁺ See you then! ☾⋆⁺₊🦇´₊⁺</h3>

            <p className={classes.marginLeft}>Regards,<br /><br />
                Social Club | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}
