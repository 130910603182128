import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, Card, CssBaseline, IconButton, Input, InputAdornment, makeStyles, createStyles, Tooltip, Typography, Grid, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../contexts/AuthContext';
import { DataContext } from '../../../../contexts/DataContext';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import { db } from '../../../../config/fbConfig';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import SendIcon from '@material-ui/icons/Send';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelIcon from '@material-ui/icons/Cancel';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Redirect } from 'react-router-dom';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';


const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            // backgroundColor: '#cfd8dc',
            backgroundColor: '#f0f5f4',
        },
        main: {
            margin: theme.spacing(2)
        },
        cardContent: {
            padding: '50px'
        },
        textCenter: {
            textAlign: 'center'
        },
        accordionDiv: {
            '*::-webkit-scrollbar': {
                display: 'none'
            },
            maxWidth: '60%',
            marginTop: '50px',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '5px',
            backgroundColor: '#f2f2f2',
            maxHeight: '500px',
            boxSizing: 'borderBox',
            overflow: 'auto',
            scrollbars: { display: 'none' }
        },
        lightGreen: {
            backgroundColor: 'rgba(150, 250, 195, 0.6)',
        },
        lightOrange: {
            backgroundColor: 'rgba(250, 200, 150, 0.6)'
        },
        accordionItem: {
            margin: '10px'
        },
        cardGrid: {
            '*::-webkit-scrollbar': {
                display: 'none'
            },
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            backgroundColor: '#f2f2f2',
            maxHeight: '500px',
            boxSizing: 'borderBox',
            overflow: 'auto',
            scrollbars: { display: 'none' }
        },
        hiddenLoader: {
            visibility: 'hidden'
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            // color: theme.palette.text.secondary,
        },
        mediaVid: {
            alignItems: 'center',
            width: '60%',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: 10,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        addItem: {
            height: '70%',
            width: '50%',
            overflowY: 'auto'
        },
        cancelButton: {
            float: 'right',
            height: '60px',
            width: '60px',
        },
        filePicker: {
            float: 'left',
            marginLeft: '35px',
            marginTop: '35px',
        },
        input: {
            display: 'none',
        },
        green: {
            color: '#048713',
            fontStyle: 'bold'
        },
        marginRight: {
            marginRight: '15px'
        },
        marginTop: {
            marginTop: '70px'
        },
        marginBottom: {
            marginBottom: '100px'
        },
        buttonRight: {
            float: 'right',
            marginTop: '35px',
            marginRight: '35px',
            marginBottom: '35px',
        },
    }));


const Returntek = (props) => {
    const classes = useStyles();

    const { howToVideosAddNew } = useContext(DataContext);
    const { isAuthenticated, loading, roles } = useContext(AuthContext);
    const [videoData, setVideoData] = useState({
        searchFor: '',
        data: []
    });
    const [expanded, setExpanded] = React.useState(false);
    const [wentSearching, setWentSearching] = useState(false);
    const [addBackdropOpen, setAddBackdropOpen] = React.useState(false);
    const [itemToAdd, setItemToAdd] = useState({
        Title: '',
        files: [],
        filesSize: 0,
        filesToStoreInDB: [],
    });

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleAddBackdropOpen = () => {
        setAddBackdropOpen(!addBackdropOpen);
        // document.getElementById('Title').focus();
    };
    const handleAddBackdropClose = () => {
        setAddBackdropOpen(false);
        document.getElementById('loader').style.visibility = 'hidden';
        setItemToAdd({
            ...itemToAdd,
            Title: '',
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        })
    };
    const handleChange = (e) => {
        setVideoData({
            ...videoData,
            [e.target.name]: e.target.value
        })
        setWentSearching(false);
    };
    const handleChangeAddVideo = (e) => {
        setItemToAdd({
            ...itemToAdd,
            [e.target.name]: e.target.value
        })
        setWentSearching(false);
    };
    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    function getVideos() {
        db.collection('howToVideosReturntek')
            .orderBy('dateCreated', 'desc').get()
            .then(snap => {
                var allVideoClips = []
                snap.forEach(clip => {
                    var vid = clip.data()
                    vid.id = clip.id
                    allVideoClips.push(vid)
                })
                setVideoData({
                    ...videoData,
                    searchFor: '',
                    data: allVideoClips
                })
            })
    }
    useEffect(() => {
        console.log('loop!');
        getVideos();
        return () => { };
    }, []);

    const clearSearchTitle = (e) => {
        getVideos()
        setWentSearching(false);
    };

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        var maxSize = 255000000
        var calculatedSize = 0
        var filesToSend = [...itemToAdd.files];
        var filesToStore = [...itemToAdd.filesToStoreInDB];
        [...files].forEach(file => {

            filesToStore.push(file)
            var convertResult = ''
            calculatedSize += file.size

            toBase64(file)
                .then(result => {
                    convertResult = result
                    filesToSend.push({
                        content: convertResult,
                        filename: file.name,
                        type: file.type,
                        disposition: "attachment"
                    })
                    // console.log(calculatedSize)
                    if (itemToAdd.filesSize >= maxSize || calculatedSize >= maxSize) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'Selected files are too big to attach.',
                        })
                    }
                    else {
                        if (itemToAdd.filesSize + calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                        }
                        else {
                            setItemToAdd({
                                ...itemToAdd,
                                files: filesToSend,
                                filesSize: itemToAdd.filesSize + calculatedSize,
                                filesToStoreInDB: filesToStore,
                            });
                            // console.log(filesToSend)
                        }
                    }
                })
        })
    }
    const handleChangeFilePickerRemove = (e) => {
        setItemToAdd({
            ...itemToAdd,
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
    }


    const goSearchTitle = (e) => {
        e.preventDefault();
        setWentSearching(true)
        var returnedData = []
        var arrayOfWords = videoData.searchFor.toLowerCase().trim().split(' ');
        // console.log(arrayOfWords);
        if (videoData.searchFor === '') {
            //display error
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please type in title to search.',
            });
        }
        else {
            db.collection("howToVideosReturntek")
                .where("KeyWords", "array-contains-any", arrayOfWords).get()
                .then(snapshot => {
                    snapshot.forEach((doc) => {
                        returnedData.push({
                            ...doc.data(),
                            id: doc.id
                        })
                    })
                    setVideoData({
                        ...videoData,
                        data: returnedData
                    })
                })
        }
    }

    const handleSubmitAddingNewVideo = (e) => {
        e.preventDefault();
        if (itemToAdd.filesToStoreInDB.length === 0) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please attach video format file.',
            });
            return
        }
        document.getElementById('loader').style.visibility = 'visible';
        howToVideosAddNew(itemToAdd, 'howToVideosReturntek', 'howToVideos/returntek/')
            .then(async (success) => {
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                });
                handleAddBackdropClose();
                // var latestData = await getFiveLastAdded();
                // setKnowledgeBox({
                //     ...knowledgeBox,
                //     data: latestData
                // })
            })
            .catch(error => {
                console.log(error)
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                });
            })
    }


    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={26} openHowToVideosColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Returntek Console</h1> */}

                            <Card className={classes.cardContent}>
                                <Grid container spacing={5} direction="column" justifyContent="center" alignItems="center">
                                    <Grid item>
                                        <form id="searchTitle" className={classes.textCenter} onSubmit={goSearchTitle}>
                                            <Input
                                                placeholder="Search"
                                                aria-label="search"
                                                name="searchFor"
                                                value={videoData.searchFor}
                                                onChange={handleChange}
                                                startAdornment={
                                                    <IconButton disabled aria-label="clear">
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    </IconButton>
                                                }
                                                endAdornment={
                                                    videoData.searchFor === '' ? null :
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Clear">
                                                                <IconButton
                                                                    className={classes.blackButton}
                                                                    aria-label="clear"
                                                                    onClick={clearSearchTitle}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Tooltip>

                                                            <Tooltip title="Search">
                                                                <IconButton
                                                                    className={classes.blackButton}
                                                                    aria-label="search"
                                                                    type="submit">
                                                                    <SendIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                }
                                            />
                                        </form>
                                    </Grid>

                                    {roles.includes('admin') ?
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                onClick={handleAddBackdropOpen}
                                                startIcon={<AddBoxIcon />}
                                            >
                                                Add new video
                                            </Button>
                                        </Grid>
                                        : null}
                                </Grid>
                                {videoData.data.length > 0 ?

                                    <div className={classes.accordionDiv}>
                                        {videoData.data.map(vid => (
                                            // console.log(vid.title);
                                            // <p key={vid.id}>{vid.title}</p>
                                            <Accordion key={vid.id} className={classes.accordionItem} expanded={expanded === vid.id} onChange={handleChangeAccordion(vid.id)}>
                                                <AccordionSummary className={classes.lightGreen}
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={vid.id}
                                                    id={vid.id}
                                                >
                                                    <Typography className={classes.heading}></Typography>
                                                    <Typography className={classes.secondaryHeading}>{vid.Title}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <video className={classes.mediaVid} src={vid.Url}
                                                        type="video/mp4"
                                                        controls
                                                        controlsList="nodownload">
                                                        Your browser does not support HTML video.
                                                    </video>
                                                </AccordionDetails>
                                            </Accordion>

                                        ))}
                                    </div>
                                    :
                                    wentSearching && videoData.data.length === 0 && videoData.searchFor.length > 0 ?
                                        <div className={classes.accordionDiv}><Accordion><AccordionSummary className={classes.lightOrange}><Typography className={classes.secondaryHeading}>There was no results for "{videoData.searchFor}" in our database.</Typography></AccordionSummary></Accordion></div>
                                        : null
                                }

                            </Card>

                            <Backdrop className={classes.backdrop} open={addBackdropOpen} >
                                <Card className={classes.addItem}>
                                    <Tooltip title='Cancel'>
                                        <IconButton className={classes.cancelButton} onClick={handleAddBackdropClose} color='secondary'>
                                            <CancelIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <form className={classes.addItemForm} onSubmit={handleSubmitAddingNewVideo}>
                                        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={4}>
                                            <Typography className={classes.marginTop} variant="h5" align="center" paragraph>
                                                Create New Guide
                                            </Typography>
                                            <Typography className={classes.marginBottom} variant="h6" align="center" paragraph>
                                                Add a Title and upload a video file.
                                            </Typography>
                                        </Grid>
                                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                            <Grid container item xs={12} sm={10} spacing={1}>
                                                <TextField
                                                    className={classes.marginBottom}
                                                    size="small"
                                                    variant="outlined"
                                                    focused
                                                    required
                                                    fullWidth
                                                    id="Title"
                                                    label="Title"
                                                    helperText="(max 100 characters)"
                                                    name="Title"
                                                    value={itemToAdd.Title}
                                                    onChange={handleChangeAddVideo}
                                                    inputProps={{ maxLength: 100 }}
                                                />
                                            </Grid>

                                        </Grid>
                                        <div id="loader" className={classes.hiddenLoader}>
                                            <Loading />
                                        </div>
                                        <Button
                                            type="submit"
                                            className={classes.buttonRight}
                                            variant="contained"
                                            color="primary">
                                            Start uploading
                                        </Button>

                                        <div className={classes.filePicker}>
                                            <input
                                                accept="video/*"
                                                className={classes.input}
                                                id="add-contained-button-file"
                                                // multiple
                                                type="file"
                                                onChange={handleChangeFilePicker}
                                            />
                                            <label htmlFor="add-contained-button-file">
                                                <Button
                                                    className={classes.marginRight}
                                                    variant="contained"
                                                    color="default"
                                                    component="span"
                                                    startIcon={<AttachFileIcon />}>
                                                    Add Files
                                                </Button>
                                            </label>
                                            <label htmlFor="remove-contained-button-file">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    component="span"
                                                    startIcon={<DeleteOutlineIcon />}
                                                    onClick={handleChangeFilePickerRemove}>
                                                    Remove Files
                                                </Button>
                                            </label>
                                            {itemToAdd.files.length === 0 ? null : <p className={classes.green}>File attached</p>}
                                            {/* {itemToAdd.files.length === 0 ? null : <p className={classes.green}>File attached: {itemToAdd.files.length}</p>} */}
                                        </div>
                                    </form>
                                </Card>
                            </Backdrop>

                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div >
            )
        }
    }
}
export default Returntek