import React, { useState, useEffect, useContext } from 'react';
import { db } from '../../../../config/fbConfig';
import { makeStyles, Card, Typography, Container, Grid, TextField, MenuItem, LinearProgress } from '@material-ui/core';
import ChartistGraph from 'react-chartist'
import Chartist from 'chartist'
import ctPointLabels from 'chartist-plugin-pointlabels'
import Loading from '../../Loading';
import moment from 'moment';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    minWidth: {
        minWidth: '200px'
    },
    cardPadding: {
        padding: '20px'
    },
    margin20: {
        margin: '20px',
    },
    textCenter: {
        textAlign: 'center',
        marginBottom: '25px'
    },
    paddingBottom100: {
        paddingBottom: '100px',
    },
    centerSelect: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '50px',
    },
    openIssues: {
        color: 'rgb(50, 50, 240)',
        backgroundColor: 'rgb(50, 50, 240)',
        borderRadius: '50%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    closedIssues: {
        color: 'rgb(200, 110, 10)',
        backgroundColor: 'rgb(200, 110, 10)',
        borderRadius: '50%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    openRequests: {
        color: 'rgb(20, 150, 30)',
        backgroundColor: 'rgb(20, 150, 30)',
        borderRadius: '50%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    closedRequests: {
        color: 'rgb(210, 180, 30)',
        backgroundColor: 'rgb(210, 180, 30)',
        borderRadius: '50%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    resolutionDaysPie: {
        maxWidth: '550px',
    },
    searchProgress: {
        marginBottom: '10px'
    },
    Green: {
        width: '20px',
        color: 'rgb(20, 150, 30)',  // green
        backgroundColor: 'rgb(20, 150, 30)',  // green
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    Blue: {
        width: '20px',
        color: 'rgb(50, 50, 240)', // blue
        backgroundColor: 'rgb(50, 50, 240)', // blue
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    Grey: {
        width: '20px',
        color: 'rgb(110, 110, 110)', // grey
        backgroundColor: 'rgb(110, 110, 110)', // grey
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    Yellow: {
        width: '20px',
        color: 'rgb(210, 180, 30)', // yellow
        backgroundColor: 'rgb(210, 180, 30)', // yellow
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    Orange: {
        width: '20px',
        color: 'rgb(200, 110, 10)', // orange
        backgroundColor: 'rgb(200, 110, 10)', // orange
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    Violet: {
        width: '20px',
        color: 'rgb(130, 10, 200)', // violet
        backgroundColor: 'rgb(130, 10, 200)', // violet
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    Red: {
        width: '20px',
        color: 'rgb(190, 30, 20)', // red
        backgroundColor: 'rgb(190, 30, 20)', // red
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    DarkViolet: {
        width: '20px',
        color: 'rgb(50, 10, 100)', // dark violet
        backgroundColor: 'rgb(50, 10, 100)', // dark violet
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    LightGreen: {
        width: '20px',
        color: 'rgb(20, 190, 40)', // light green
        backgroundColor: 'rgb(20, 190, 40)', // light green
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    LightBlue: {
        width: '20px',
        color: 'rgb(30, 120, 210)', // light blue
        backgroundColor: 'rgb(30, 120, 210)', // light blue
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    LightYellow: {
        width: '20px',
        color: 'rgb(240, 240, 100)', // light yellow
        backgroundColor: 'rgb(240, 240, 100)', // light yellow
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    Pink: {
        width: '20px',
        color: 'rgb(200, 30, 240)', // pink
        backgroundColor: 'rgb(200, 30, 240)', // pink
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
}))

export default function WeeklyKPIticketsIT() {
    const classes = useStyles();

    const [calculating, setCalculating] = useState(true)

    const [weeklyTickets, setWeeklyTickets] = useState({
        data: {},
        type: 'Line',
        options: {
            // width: 300,
            height: 600,

            high: 15,
            low: 0,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        },


    });

    const [resolutionDaysPercent, setResolutionDaysPercent] = useState({
        data: {},
        type: 'Pie',
        options: {
            // labelInterpolationFnc: function (value) {
            //     // return Math.round(value / resolutionDaysPercent.series.reduce(sum) * 100) + '%';
            //     return Math.round(value / 7 * 10) + '%'; // changed to seven hardcoded
            // }
        }
    });

    const [ticketResolutionDays, setTicketResolutionDays] = useState({
        data: {
            // // labels: ['Week ' + weekOne, 'Week ' + weekTwo, 'Week ' + weekThree],
            // series: [
            //     [weekOneSameDay, weekTwoSameDay, weekThreeSameDay],
            //     [weekOne2Days, weekTwo2Days, weekThree2Days],
            //     [weekOne3Days, weekTwo3Days, weekThree3Days],
            //     [weekOne4Days, weekTwo4Days, weekThree4Days],
            //     [weekOne5Days, weekTwo5Days, weekThree5Days],
            //     [weekOne6to10Days, weekTwo6to10Days, weekThree6to10Days],
            //     [weekOneOver10Days, weekTwoOver10Days, weekThreeOver10Days]
            // ]
        },
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            seriesBarDistance: 25,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        }
    });

    const [processAndSiteTrendIssue, setProcessAndSiteTrendIssue] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: true,
            height: 500,

            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 0,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: -20,
                        y: -5
                    },
                    // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                    labelInterpolationFnc: v => v ? v : '' // fix to dont display 0
                })
            ]
        }
    });

    const [processAndSiteTrendChangeRequest, setProcessAndSiteTrendChangeRequest] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: true,
            height: 500,

            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 0,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: -20,
                        y: -5
                    },
                    labelInterpolationFnc: v => v ? v : '', // fix to dont display 0
                    // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        }
    });

    const [weeklyBaDeleteRequest, setWeeklyBaDeleteRequest] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: true,
            height: 500,

            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 0,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: -20,
                        y: -5
                    },
                    labelInterpolationFnc: v => v ? v : '', // fix to dont display 0
                    // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        }
    });

    const [weeklyTaDeleteRequest, setWeeklyTaDeleteRequest] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: true,
            height: 500,

            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 0,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: -20,
                        y: -5
                    },
                    labelInterpolationFnc: v => v ? v : '', // fix to dont display 0
                    // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        }
    });

    const [siteDepartmentBreakdownIssue, setSiteDepartmentBreakdownIssue] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: true,
            height: 400,

            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 0,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: -20,
                        y: -5
                    },
                    labelInterpolationFnc: v => v ? v : '', // fix to dont display 0
                    // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        },
        // not working animation
        // animation: {
        //     draw: function (data) {
        //         if (data.type === "bar") {
        //             data.element.attr({
        //                 style: 'stroke-width: 0px'
        //             });
        //             var strokeWidth = 10;
        //             data.element.animate({
        //                 y2: {
        //                     begin: 0,
        //                     dur: 500,
        //                     from: data.y1,
        //                     to: data.y2,
        //                     easing: Chartist.Svg.Easing.easeOutSine,
        //                 },
        //                 'stroke-width': {
        //                     begin: 0,
        //                     dur: 1,
        //                     from: 1,
        //                     to: strokeWidth,
        //                     fill: 'freeze',
        //                 }
        //             }, false);
        //         }
        //     }
        // },
    });

    const [siteDepartmentBreakdownChangeRequest, setSiteDepartmentBreakdownChangeRequest] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: true,
            height: 400,

            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 0,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: -20,
                        y: -5
                    },
                    labelInterpolationFnc: v => v ? v : '', // fix to dont display 0
                    // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        }
    });

    const [weeklyIssueFixes, setWeeklyIssueFixes] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            distributeSeries: true,
            height: 350,

            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 0,
                // int on Y axis
                onlyInteger: true,
            },
            // plugins: [
            //     ctPointLabels({
            //         textAnchor: 'middle',
            //         labelOffset: {
            //             x: -20,
            //             y: -5
            //         },
            //         labelInterpolationFnc: v => v ? v : '', // fix to dont display 0
            //         // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
            //     })
            // ]
        }
    });

    const [weeklyRequestTypes, setWeeklyRequestTypes] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            distributeSeries: true,
            height: 350,

            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                    // return value.split(/\s+/).map(function (word) {
                    //     return word[0];
                    // }).join('');
                }
            },
            axisY: {
                offset: 0,
                // int on Y axis
                onlyInteger: true,
            },
            // plugins: [
            //     ctPointLabels({
            //         textAnchor: 'middle',
            //         labelOffset: {
            //             x: -20,
            //             y: -5
            //         },
            //         labelInterpolationFnc: v => v ? v : '', // fix to dont display 0
            //         // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
            //     })
            // ]
        }
    });

    function getWeekNumbers(branyInt) {
        return moment().add(branyInt, 'weeks').isoWeek()
    }
    function createWeekDropdownLabels() {
        var weekLabels = []
        for (let i = 0; i >= -15; i--) {
            weekLabels.push({ value: getWeekNumbers(i), label: 'Week ' + getWeekNumbers(i) })
        }
        return weekLabels
    }
    function createWeekGraphLabels(number) {
        var weeks = 0 - number
        var weekLabels = []
        for (let i = 0; i >= weeks; i--) {
            weekLabels.push('Week ' + getWeekNumbers(i))
        }
        return weekLabels.reverse()
    }
    function createWeekNumbers(number) {
        var weeks = 0 - number
        var weekNumbers = []
        for (let i = 0; i >= weeks; i--) {
            weekNumbers.push(getWeekNumbers(i))
        }
        return weekNumbers.reverse()
    }
    function getMaxFrom2dAarray(a) {
        return Math.max(...a.map(e => Array.isArray(e) ? getMaxFrom2dAarray(e) : e));
    }
    function getMinFrom2dAarray(a) {
        return Math.min(...a.map(e => Array.isArray(e) ? getMinFrom2dAarray(e) : e));
    }
    function getMaxColumnSumFrom2dAarray(a) {
        var onedimarr = []
        for (let i = 0; i < a.length; i++) {
            for (let j = 0; j < a[i].length; j++) {
                if (isNaN(onedimarr[j])) {
                    onedimarr[j] = a[i][j]
                }
                else {
                    onedimarr[j] = onedimarr[j] + a[i][j]
                }
            }
        }
        return Math.max.apply(null, onedimarr)
    }
    function createEmpty3dArray() {
        var week = []
        for (let i = 0; i < 16; i++) {
            var site = []
            for (let j = 0; j < sites.length; j++) {
                var department = []
                for (let k = 0; k < departments.length; k++) {
                    department.push(0)
                }
                site.push(department)
            }
            week.push(site)
        }
        return week
    }
    function createEmpty2dArray(weeks, arrayLength) {
        var week = []
        for (let i = 0; i < weeks; i++) {
            var item = []
            for (let j = 0; j < arrayLength; j++) {
                item.push(0)
            }
            week.push(item)
        }
        return week
    }

    const [weekSelected, setWeekSelected] = useState({

        selectedWeekNumber: moment().isoWeek(), // current week
        weekSelect: createWeekDropdownLabels(),

        issues3dArray: null,
        changeRequests3dArray: null,
        issueFixTypes2dArray: null,
        changeRequestTypes2dArray: null,

    });

    // { value: 'Kilbarry', label: 'Kilbarry' },
    // { value: 'K4 / K5', label: 'K4 / K5' },
    // { value: 'Carrigtohill', label: 'Carrigtohill' },
    // { value: 'UK', label: 'UK' },
    // { value: 'Thailand', label: 'Thailand' },
    // { value: 'MD - Maryland', label: 'MD - Maryland' },
    // { value: 'TX - Texas', label: 'TX - Texas' },
    // { value: 'VA - Virginia', label: 'VA - Virginia' },
    // { value: 'SAC - Sacramento', label: 'SAC - Sacramento' },
    // { value: 'MA - Massachusetts', label: 'MA - Massachusetts' },
    // { value: 'Dubai', label: 'Dubai' },
    // { value: 'All sites', label: 'All Sites' },

    const sites = ['Kilbarry', 'K4 / K5', 'Carrigtohill', 'UK', 'Thailand', 'MD - Maryland', 'TX - Texas', 'VA - Virginia', 'SAC - Sacramento', 'MA - Massachusetts', 'Dubai', 'All sites']
    const departments = ['Customer Service', 'Engineering', 'Finance', 'HR', 'IT', 'Logistics', 'Operations', 'Procurement', 'Quality', 'R&D', 'Sales', 'TRMA']
    const issueFixTypes = ['System CI Required', 'Process CI Required', 'New Report Required', 'No Fix Required', 'Debug', 'Training Required', 'Update Documentation', 'Repair/Replace hardware', 'Fixed Data & Post', 'Updated Access']
    const changeRequestTypes = ['Access Needed', 'New Report Needed', 'System Change Request', 'Hardware Required', 'Software Required', 'New Hire', 'Termination of employment']

    const handleGraphChangeClick = (e) => {

        var lineID = e.target.id

        switch (lineID) {
            case 'OI':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'CI':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'OR':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'CR':
                var graph = document.getElementsByClassName('ct-series-d');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;

            default:
                break;
        }
    }

    const handleChangeWeek = (e) => {
        console.log('handling week change')
        var weekPosition = createWeekNumbers(15).findIndex(week => week === e.target.value)
        var newIssuesSeries = weekSelected.issues3dArray[weekPosition]
        var newChangeRequestsSeries = weekSelected.changeRequests3dArray[weekPosition]
        var newIssueFixesDataSeries = weekSelected.issueFixTypes2dArray[weekPosition]
        var newChangeRequestsDataSeries = weekSelected.changeRequestTypes2dArray[weekPosition]
        var verticalHighStackBarsBreakdownIssues = getMaxColumnSumFrom2dAarray(newIssuesSeries) + 2
        var verticalHighStackBarsBreakdownChangeRequests = getMaxColumnSumFrom2dAarray(newChangeRequestsSeries) + 2
        var verticalHighIssueFixes = Math.max.apply(null, newIssueFixesDataSeries) + 2
        var verticalHighChangeRequestTypes = Math.max.apply(null, newChangeRequestsDataSeries) + 2
        // console.log(weekSelected, newIssuesSeries, weekPosition)
        setWeekSelected({
            ...weekSelected,
            selectedWeekNumber: e.target.value
        })
        setSiteDepartmentBreakdownIssue({
            ...siteDepartmentBreakdownIssue,
            data: {
                labels: siteDepartmentBreakdownIssue.data.labels,
                series: newIssuesSeries
            },
            options: {
                stackBars: true,
                height: 400,
                high: verticalHighStackBarsBreakdownIssues,
                low: 0,

                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                    }
                },
                axisY: {
                    offset: 0,
                }
            }
        })
        setSiteDepartmentBreakdownChangeRequest({
            ...siteDepartmentBreakdownChangeRequest,
            data: {
                labels: siteDepartmentBreakdownChangeRequest.data.labels,
                series: newChangeRequestsSeries
            },
            options: {
                stackBars: true,
                height: 400,
                high: verticalHighStackBarsBreakdownChangeRequests,
                low: 0,

                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                    }
                },
                axisY: {
                    offset: 0,
                }
            }
        })
        setWeeklyIssueFixes({
            ...weeklyIssueFixes,
            data: {
                labels: newIssueFixesDataSeries,
                series: newIssueFixesDataSeries
                //series: [issueSystemCIRequiredWeek15, issueProcessCIRequiredWeek15, issueNewReportRequiredWeek15, issueNoFixRequiredWeek15, issueDebugWeek15, issueTrainingRequiredWeek15, issueUpdateDocumentationWeek15, issueRepairReplaceHardwareWeek15, issueFixDataAndPostWeek15, issueUpdateAccessWeek15]
            },
            options: {
                high: verticalHighIssueFixes,
                low: 0
            }
        })
        setWeeklyRequestTypes({
            ...weeklyRequestTypes,
            data: {
                labels: newChangeRequestsDataSeries,
                series: newChangeRequestsDataSeries
                //series: [requestAccessNeededWeek15, requestNewReportNeededWeek15, requestSystemChangeWeek15, requestHardwareRequiredWeek15, requestSoftwareRequiredWeek15, requestNewHireWeek15, requestTerminationOfEmploymentWeek15]
            },
            options: {
                high: verticalHighChangeRequestTypes,
                low: 0
            }
        })
        // document.getElementsByName("issueErrorTicketsLabel", "changeRequestTicketsLabel", "changeRequestTypeLabel", "issueFixesLabel").innerHTML = "sdfsfdsdf"
        document.getElementById("issueErrorTicketsLabel").innerHTML = "Issue Error Tickets - Week " + e.target.value
        document.getElementById("changeRequestTicketsLabel").innerHTML = "Change Request Tickets - Week " + e.target.value
        document.getElementById("changeRequestTypeLabel").innerHTML = "Change Request Type - Week " + e.target.value
        document.getElementById("issueFixesLabel").innerHTML = "Issue Fixes - Week " + e.target.value
    }

    function calcWeeklyVars(data) {
        var lastSixteenWeeks = createWeekNumbers(15)
        var weeklyTicketsDataLabels = createWeekGraphLabels(15);
        var weeklyTicketsDataSeries = [
            //week-15 to current week
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//open issues
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//closed issues
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//open requests
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //closed requests
        ];
        var resolutionDaysPercentSeries = []
        var resolutionDaysGraphLabels = []
        var resolutionDaysGraphSeries = [
            //unresolved, week-4, week-3, week-2, week-1
            [0, 0, 0, 0, 0],//sameDay
            [0, 0, 0, 0, 0],//twoDays
            [0, 0, 0, 0, 0],//threeDays
            [0, 0, 0, 0, 0],//fourDays
            [0, 0, 0, 0, 0],//fiveDays
            [0, 0, 0, 0, 0],//sixToTenDays
            [0, 0, 0, 0, 0] //overTenDays
        ]

        var processAndSiteTrendIssueGraphLabels = createWeekGraphLabels(5)
        var processAndSiteTrendIssueGraphSeries = [
            //week-6, week-5, week-4, week-3, week-2, week-1
            [0, 0, 0, 0, 0, 0],//ITAD
            [0, 0, 0, 0, 0, 0],//AVR
            [0, 0, 0, 0, 0, 0],//Forcepoint
            [0, 0, 0, 0, 0, 0],//LB
            [0, 0, 0, 0, 0, 0],//Dexgreen
            [0, 0, 0, 0, 0, 0],//Protek
            [0, 0, 0, 0, 0, 0],//OnsiteServices
            [0, 0, 0, 0, 0, 0] //All
        ]
        var processAndSiteTrendChangeRequestGraphLabels = createWeekGraphLabels(5)
        var processAndSiteTrendChangeRequestGraphSeries = [
            //week-6, week-5, week-4, week-3, week-2, week-1
            [0, 0, 0, 0, 0, 0],//ITAD
            [0, 0, 0, 0, 0, 0],//AVR
            [0, 0, 0, 0, 0, 0],//Forcepoint
            [0, 0, 0, 0, 0, 0],//LB
            [0, 0, 0, 0, 0, 0],//Dexgreen
            [0, 0, 0, 0, 0, 0],//Protek
            [0, 0, 0, 0, 0, 0],//OnsiteServices
            [0, 0, 0, 0, 0, 0] //All
        ]
        var baDeleteRequestGraphLabels = createWeekGraphLabels(15)
        var baDeleteRequestGraphSeries = [
            //week-15 to current week
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Kilbarry
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//K4 / K5
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Carrigtohill
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//UK
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Thailand
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//MD - Maryland
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//TX - Texas
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//VA - Virginia
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//SAC - Sacramento
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//MA - Massachusetts
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Dubai
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //All Sites
        ]
        var taDeleteRequestGraphLabels = createWeekGraphLabels(15)
        var taDeleteRequestGraphSeries = [
            //week-15 to current week
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Kilbarry
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//K4 / K5
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Carrigtohill
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//UK
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Thailand
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//MD - Maryland
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//TX - Texas
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//VA - Virginia
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//SAC - Sacramento
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//MA - Massachusetts
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Dubai
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //All Sites
        ]

        var issueAndChangeRequestLabels = ['Kilbarry', 'K4 / K5', 'Carrigtohill', 'UK', 'Thailand', 'MD - Maryland', 'TX - Texas', 'VA - Virginia', 'SAC - Sacramento', 'MA - Massachusetts', 'Dubai', 'All Sites']

        var siteDepartmentBreakdownIssueDataSeries3d = createEmpty3dArray()
        var siteDepartmentBreakdownChangeRequestDataSeries3d = createEmpty3dArray()

        var siteDepartmentBreakdownIssueDataSeries = [
            //Kilbarry, Glanmire, UK, Thailand, Maryland, Texas, Virginia, Sacramento, Massachusetts, Dubai, All
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//CustomerService
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Engineering
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Finance
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//HR
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//IT
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Logistics
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Operations
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Procurement
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Quality
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//RandD
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Sales
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //TRMA
        ]
        var siteDepartmentBreakdownChangeRequestDataSeries = [
            //Kilbarry, Glanmire, UK, Thailand, Maryland, Texas, Virginia, Sacramento, Massachusetts, Dubai, All
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//CustomerService
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Engineering
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Finance
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//HR
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//IT
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Logistics
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Operations
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Procurement
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Quality
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//RandD
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//Sales
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //TRMA
        ]


        var issueFixesDataSeries2d = createEmpty2dArray(16, issueFixTypes.length)
        var changeRequestsDataSeries2d = createEmpty2dArray(16, changeRequestTypes.length)

        var issueFixesDataSeries = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var changeRequestsDataSeries = [0, 0, 0, 0, 0, 0, 0]

        // 1st GRAPH WEEKLY TREND CALCULATIONS - open vs closed // added here 6th & 7th GRAPH - BA/Delete Request and TA/Deleted Request
        var dataRangeWeeklyTrendStart = moment().add(-15, 'weeks').startOf('isoWeek')
        var dataRangeWeeklyTrendEnd = moment().endOf('isoWeek')
        // console.log(dataRangeWeeklyTrendStart.format('DD MMM YYYY HH:mm'))
        // console.log(dataRangeWeeklyTrendEnd.format('DD MMM YYYY HH:mm'))
        var arrayOpenIssues = 0
        var arrayClosedIssues = 1
        var arrayOpenRequests = 2
        var arrayClosedRequests = 3

        // 2nd PIE & 3rd GRAPH CALCULATIONS - resolution days percent
        var resolutionSameDay = 0
        var resolution2Days = 0
        var resolution3Days = 0
        var resolution4Days = 0
        var resolution5Days = 0
        var resolution6to10Days = 0
        var resolutionOver10Days = 0
        var lastMonthWeeks = ['Unresolved', lastSixteenWeeks[12], lastSixteenWeeks[13], lastSixteenWeeks[14], lastSixteenWeeks[15]]

        // 4th & 5th GRAPH CALCULATIONS - issues per process and change requests per process
        var dataRangeProcessIssueStart = moment().add(-5, 'weeks').startOf('isoWeek')
        var dataRangeProcessIssueEnd = moment().endOf('isoWeek')
        var lastSixWeeks = createWeekNumbers(5)

        // 8th & 9th GRAPH CALCULATIONS - site & department breakdown
        var dataRangeIssueAndChangeRequestsStart = moment().add(-15, 'weeks').startOf('isoWeek')
        var dataRangeIssueAndChangeRequestsEnd = moment().endOf('isoWeek')
        var lastSixteenWeeksIssueAndChangeRequests = createWeekNumbers(15)

        data.forEach(doc => {
            var weekOpenPosition = lastSixteenWeeks.findIndex(week => week === doc.data().WeekNo)
            var weekClosedPosition = lastSixteenWeeks.findIndex(week => week === moment(doc.data().ClosedDate.toDate()).isoWeek())

            if (moment(doc.data().DateRaised.toDate()).isBetween(dataRangeWeeklyTrendStart, dataRangeWeeklyTrendEnd)) {
                if (doc.data().TicketType === 'Issue / Error') {
                    weeklyTicketsDataSeries[arrayOpenIssues][weekOpenPosition] = weeklyTicketsDataSeries[arrayOpenIssues][weekOpenPosition] + 1
                }
                if (doc.data().TicketType === 'Change Request') {
                    weeklyTicketsDataSeries[arrayOpenRequests][weekOpenPosition] = weeklyTicketsDataSeries[arrayOpenRequests][weekOpenPosition] + 1
                }

                // 6th & 7th GRAPH CALCULATIONS - BA/Delete Request & TA/Delete Request
                if (doc.data().FailureCategory === 'BA/Delete Request') {
                    switch (doc.data().Site) {
                        case 'Kilbarry':
                            baDeleteRequestGraphSeries[0][weekOpenPosition] = baDeleteRequestGraphSeries[0][weekOpenPosition] + 1
                            break;
                        case 'K4 / K5':
                            baDeleteRequestGraphSeries[1][weekOpenPosition] = baDeleteRequestGraphSeries[1][weekOpenPosition] + 1
                            break;
                        case 'Carrigtohill':
                            baDeleteRequestGraphSeries[2][weekOpenPosition] = baDeleteRequestGraphSeries[2][weekOpenPosition] + 1
                            break;
                        case 'UK':
                            baDeleteRequestGraphSeries[3][weekOpenPosition] = baDeleteRequestGraphSeries[3][weekOpenPosition] + 1
                            break;
                        case 'Thailand':
                            baDeleteRequestGraphSeries[4][weekOpenPosition] = baDeleteRequestGraphSeries[4][weekOpenPosition] + 1
                            break;
                        case 'MD - Maryland':
                            baDeleteRequestGraphSeries[5][weekOpenPosition] = baDeleteRequestGraphSeries[5][weekOpenPosition] + 1
                            break;
                        case 'TX - Texas':
                            baDeleteRequestGraphSeries[6][weekOpenPosition] = baDeleteRequestGraphSeries[6][weekOpenPosition] + 1
                            break;
                        case 'VA - Virginia':
                            baDeleteRequestGraphSeries[7][weekOpenPosition] = baDeleteRequestGraphSeries[7][weekOpenPosition] + 1
                            break;
                        case 'SAC - Sacramento':
                            baDeleteRequestGraphSeries[8][weekOpenPosition] = baDeleteRequestGraphSeries[8][weekOpenPosition] + 1
                            break;
                        case 'MA - Massachusetts':
                            baDeleteRequestGraphSeries[9][weekOpenPosition] = baDeleteRequestGraphSeries[9][weekOpenPosition] + 1
                            break;
                        case 'Dubai':
                            baDeleteRequestGraphSeries[10][weekOpenPosition] = baDeleteRequestGraphSeries[10][weekOpenPosition] + 1
                            break;
                        case 'All Sites':
                            baDeleteRequestGraphSeries[11][weekOpenPosition] = baDeleteRequestGraphSeries[11][weekOpenPosition] + 1
                            break;
                        default:
                            break;
                    }
                }
                if (doc.data().FailureCategory === 'TA/Delete Request') {
                    switch (doc.data().Site) {
                        case 'Kilbarry':
                            taDeleteRequestGraphSeries[0][weekOpenPosition] = taDeleteRequestGraphSeries[0][weekOpenPosition] + 1
                            break;
                        case 'K4 / K5':
                            taDeleteRequestGraphSeries[1][weekOpenPosition] = taDeleteRequestGraphSeries[1][weekOpenPosition] + 1
                            break;
                        case 'Carrigtohill':
                            taDeleteRequestGraphSeries[2][weekOpenPosition] = taDeleteRequestGraphSeries[2][weekOpenPosition] + 1
                            break;
                        case 'UK':
                            taDeleteRequestGraphSeries[3][weekOpenPosition] = taDeleteRequestGraphSeries[3][weekOpenPosition] + 1
                            break;
                        case 'Thailand':
                            taDeleteRequestGraphSeries[4][weekOpenPosition] = taDeleteRequestGraphSeries[4][weekOpenPosition] + 1
                            break;
                        case 'MD - Maryland':
                            taDeleteRequestGraphSeries[5][weekOpenPosition] = taDeleteRequestGraphSeries[5][weekOpenPosition] + 1
                            break;
                        case 'TX - Texas':
                            taDeleteRequestGraphSeries[6][weekOpenPosition] = taDeleteRequestGraphSeries[6][weekOpenPosition] + 1
                            break;
                        case 'VA - Virginia':
                            taDeleteRequestGraphSeries[7][weekOpenPosition] = taDeleteRequestGraphSeries[7][weekOpenPosition] + 1
                            break;
                        case 'SAC - Sacramento':
                            taDeleteRequestGraphSeries[8][weekOpenPosition] = taDeleteRequestGraphSeries[8][weekOpenPosition] + 1
                            break;
                        case 'MA - Massachusetts':
                            taDeleteRequestGraphSeries[9][weekOpenPosition] = taDeleteRequestGraphSeries[9][weekOpenPosition] + 1
                            break;
                        case 'Dubai':
                            taDeleteRequestGraphSeries[10][weekOpenPosition] = taDeleteRequestGraphSeries[10][weekOpenPosition] + 1
                            break;
                        case 'All Sites':
                            taDeleteRequestGraphSeries[11][weekOpenPosition] = taDeleteRequestGraphSeries[11][weekOpenPosition] + 1
                            break;
                        default:
                            break;
                    }
                }

            }
            if (moment(doc.data().ClosedDate.toDate()).isBetween(dataRangeWeeklyTrendStart, dataRangeWeeklyTrendEnd)) {
                if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                    if (doc.data().TicketType === 'Issue / Error') {
                        // console.log('array position: ', weekClosedPosition, 'week number : ', moment(doc.data().ClosedDate.toDate()).isoWeek())
                        weeklyTicketsDataSeries[arrayClosedIssues][weekClosedPosition] = weeklyTicketsDataSeries[arrayClosedIssues][weekClosedPosition] + 1
                    }
                    if (doc.data().TicketType === 'Change Request') {
                        weeklyTicketsDataSeries[arrayClosedRequests][weekClosedPosition] = weeklyTicketsDataSeries[arrayClosedRequests][weekClosedPosition] + 1
                    }
                }
            }
            // PIE CALCULATIONS
            if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                var startDate = moment(doc.data().DateRaised.toDate())
                var endDate = moment(doc.data().ClosedDate.toDate())
                switch (endDate.diff(startDate, 'days')) {
                    case 0:
                        resolutionSameDay++
                        break;
                    case 1:
                        resolution2Days++
                        break;
                    case 2:
                        resolution3Days++
                        break;
                    case 3:
                        resolution4Days++
                        break;
                    case 4:
                        resolution5Days++
                        break;
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        resolution6to10Days++
                        break;
                    default:
                        resolutionOver10Days++
                        break;
                }
            }
            // 3rd GRAPH BARS CALCULATIONS
            if (doc.data().Status === 'Open' ||
                doc.data().Status === 'WIP - Scope' ||
                doc.data().Status === 'WIP - Dev' ||
                doc.data().Status === 'WIP - UAT' ||
                doc.data().Status === 'WIP - Production cut in' ||
                doc.data().Status === 'On Hold' ||
                doc.data().Status === 'Aknowledge') {

                var startDate = moment(doc.data().DateRaised.toDate())

                switch (moment().diff(startDate, 'days')) {
                    case 0:
                        resolutionDaysGraphSeries[0][0] = resolutionDaysGraphSeries[0][0] + 1
                        break;
                    case 1:
                        resolutionDaysGraphSeries[1][0] = resolutionDaysGraphSeries[1][0] + 1
                        break;
                    case 2:
                        resolutionDaysGraphSeries[2][0] = resolutionDaysGraphSeries[2][0] + 1
                        break;
                    case 3:
                        resolutionDaysGraphSeries[3][0] = resolutionDaysGraphSeries[3][0] + 1
                        break;
                    case 4:
                        resolutionDaysGraphSeries[4][0] = resolutionDaysGraphSeries[4][0] + 1
                        break;
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        resolutionDaysGraphSeries[5][0] = resolutionDaysGraphSeries[5][0] + 1
                        break;
                    default:
                        resolutionDaysGraphSeries[6][0] = resolutionDaysGraphSeries[6][0] + 1
                        break;
                }
            }

            var dataRange4WeeksStart = moment().add(-3, 'weeks').startOf('isoWeek')
            var dataRange4WeeksEnd = moment().endOf('isoWeek')

            if (moment(doc.data().ClosedDate.toDate()).isBetween(dataRange4WeeksStart, dataRange4WeeksEnd)) {
                if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                    var weekClosedPosition = lastMonthWeeks.findIndex(week => week === moment(doc.data().ClosedDate.toDate()).isoWeek())
                    var startDate = moment(doc.data().DateRaised.toDate())
                    var endDate = moment(doc.data().ClosedDate.toDate())
                    switch (endDate.diff(startDate, 'days')) {
                        case 0:
                            resolutionDaysGraphSeries[0][weekClosedPosition] = resolutionDaysGraphSeries[0][weekClosedPosition] + 1
                            break;
                        case 1:
                            resolutionDaysGraphSeries[1][weekClosedPosition] = resolutionDaysGraphSeries[1][weekClosedPosition] + 1
                            break;
                        case 2:
                            resolutionDaysGraphSeries[2][weekClosedPosition] = resolutionDaysGraphSeries[2][weekClosedPosition] + 1
                            break;
                        case 3:
                            resolutionDaysGraphSeries[3][weekClosedPosition] = resolutionDaysGraphSeries[3][weekClosedPosition] + 1
                            break;
                        case 4:
                            resolutionDaysGraphSeries[4][weekClosedPosition] = resolutionDaysGraphSeries[4][weekClosedPosition] + 1
                            break;
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            resolutionDaysGraphSeries[5][weekClosedPosition] = resolutionDaysGraphSeries[5][weekClosedPosition] + 1
                            break;
                        default:
                            resolutionDaysGraphSeries[6][weekClosedPosition] = resolutionDaysGraphSeries[6][weekClosedPosition] + 1
                            break;
                    }
                }
            }
            // 4th & 5th GRAPH CALCULATIONS - issues per process and change requests per process
            var weekOpenPositionSixWeeks = lastSixWeeks.findIndex(week => week === doc.data().WeekNo)
            // var weekClosedPosition = lastSixWeeks.findIndex(week => week === moment(doc.data().ClosedDate.toDate()).isoWeek())
            if (moment(doc.data().DateRaised.toDate()).isBetween(dataRangeProcessIssueStart, dataRangeProcessIssueEnd)) {
                if (doc.data().TicketType === 'Issue / Error') {
                    switch (doc.data().Process) {
                        case 'ITAD':
                            processAndSiteTrendIssueGraphSeries[0][weekOpenPositionSixWeeks] = processAndSiteTrendIssueGraphSeries[0][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'AVR':
                            processAndSiteTrendIssueGraphSeries[1][weekOpenPositionSixWeeks] = processAndSiteTrendIssueGraphSeries[1][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'Forcepoint':
                            processAndSiteTrendIssueGraphSeries[2][weekOpenPositionSixWeeks] = processAndSiteTrendIssueGraphSeries[2][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'LB':
                            processAndSiteTrendIssueGraphSeries[3][weekOpenPositionSixWeeks] = processAndSiteTrendIssueGraphSeries[3][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'Dexgreen':
                            processAndSiteTrendIssueGraphSeries[4][weekOpenPositionSixWeeks] = processAndSiteTrendIssueGraphSeries[4][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'Protek':
                            processAndSiteTrendIssueGraphSeries[5][weekOpenPositionSixWeeks] = processAndSiteTrendIssueGraphSeries[5][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'Onsite Service':
                            processAndSiteTrendIssueGraphSeries[6][weekOpenPositionSixWeeks] = processAndSiteTrendIssueGraphSeries[6][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'All':
                            processAndSiteTrendIssueGraphSeries[7][weekOpenPositionSixWeeks] = processAndSiteTrendIssueGraphSeries[7][weekOpenPositionSixWeeks] + 1
                            break;
                        default:
                            break;
                    }
                }
                if (doc.data().TicketType === 'Change Request') {
                    switch (doc.data().Process) {
                        case 'ITAD':
                            processAndSiteTrendChangeRequestGraphSeries[0][weekOpenPositionSixWeeks] = processAndSiteTrendChangeRequestGraphSeries[0][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'AVR':
                            processAndSiteTrendChangeRequestGraphSeries[1][weekOpenPositionSixWeeks] = processAndSiteTrendChangeRequestGraphSeries[1][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'Forcepoint':
                            processAndSiteTrendChangeRequestGraphSeries[2][weekOpenPositionSixWeeks] = processAndSiteTrendChangeRequestGraphSeries[2][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'LB':
                            processAndSiteTrendChangeRequestGraphSeries[3][weekOpenPositionSixWeeks] = processAndSiteTrendChangeRequestGraphSeries[3][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'Dexgreen':
                            processAndSiteTrendChangeRequestGraphSeries[4][weekOpenPositionSixWeeks] = processAndSiteTrendChangeRequestGraphSeries[4][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'Protek':
                            processAndSiteTrendChangeRequestGraphSeries[5][weekOpenPositionSixWeeks] = processAndSiteTrendChangeRequestGraphSeries[5][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'Onsite Service':
                            processAndSiteTrendChangeRequestGraphSeries[6][weekOpenPositionSixWeeks] = processAndSiteTrendChangeRequestGraphSeries[6][weekOpenPositionSixWeeks] + 1
                            break;
                        case 'All':
                            processAndSiteTrendChangeRequestGraphSeries[7][weekOpenPositionSixWeeks] = processAndSiteTrendChangeRequestGraphSeries[7][weekOpenPositionSixWeeks] + 1
                            break;
                        default:
                            break;
                    }
                }
            }
            // 8th & 9th GRAPH CALCULATIONS - site & department breakdown
            if (moment(doc.data().DateRaised.toDate()).isBetween(dataRangeIssueAndChangeRequestsStart, dataRangeIssueAndChangeRequestsEnd)) {
                var weekPosition = lastSixteenWeeksIssueAndChangeRequests.findIndex(week => week === doc.data().WeekNo)
                var departmentPosition = departments.findIndex(dep => dep === doc.data().Department)
                var sitePosition = sites.findIndex(site => site === doc.data().Site)
                if (doc.data().TicketType === 'Issue / Error') {
                    // if (weekPosition === 15) {
                    //     console.log(doc.data().WeekNo, doc.data().Department, doc.data().Site, doc.id)
                    //     console.log('__________3d__:', weekPosition, departmentPosition, sitePosition)
                    // }
                    siteDepartmentBreakdownIssueDataSeries3d[weekPosition][departmentPosition][sitePosition] = siteDepartmentBreakdownIssueDataSeries3d[weekPosition][departmentPosition][sitePosition] + 1
                }
                if (doc.data().TicketType === 'Change Request') {
                    // if (weekPosition === 15) {
                    //     console.log(doc.data().WeekNo, doc.data().Department, doc.data().Site, doc.id)
                    //     console.log('__________3d__:', weekPosition, departmentPosition, sitePosition)
                    // }
                    siteDepartmentBreakdownChangeRequestDataSeries3d[weekPosition][departmentPosition][sitePosition] = siteDepartmentBreakdownChangeRequestDataSeries3d[weekPosition][departmentPosition][sitePosition] + 1
                }

                // 10th & 11th GRAPH CALCULATIONS - Issue Fixes & Change Request Types
                if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                    var fixTypePosition = issueFixTypes.findIndex(fix => fix === doc.data().FixType)
                    var requestCategoryPosition = changeRequestTypes.findIndex(req => req === doc.data().RequestCategory)
                    if (doc.data().TicketType === 'Issue / Error') {
                        // if (weekPosition === 15) {
                        // console.log('issue', doc.data().WeekNo, doc.data().FixType, doc.id)
                        // console.log('__________2d__:', weekPosition, fixTypePosition)
                        // }
                        issueFixesDataSeries2d[weekPosition][fixTypePosition] = issueFixesDataSeries2d[weekPosition][fixTypePosition] + 1
                    }
                    if (doc.data().TicketType === 'Change Request') {
                        // if (weekPosition === 15) {
                        // console.log('change', doc.data().WeekNo, doc.data().RequestCategory, doc.id)
                        // console.log('________________2d__:', weekPosition, requestCategoryPosition)
                        // }
                        changeRequestsDataSeries2d[weekPosition][requestCategoryPosition] = changeRequestsDataSeries2d[weekPosition][requestCategoryPosition] + 1
                    }
                }
            }
        })

        resolutionDaysPercentSeries = [resolutionSameDay, resolution2Days, resolution3Days, resolution4Days, resolution5Days, resolution6to10Days, resolutionOver10Days]
        resolutionDaysGraphLabels = ['Unresolved', 'Week ' + lastSixteenWeeks[12], 'Week ' + lastSixteenWeeks[13], 'Week ' + lastSixteenWeeks[14], 'Week' + lastSixteenWeeks[15]]
        siteDepartmentBreakdownIssueDataSeries = siteDepartmentBreakdownIssueDataSeries3d[15]
        siteDepartmentBreakdownChangeRequestDataSeries = siteDepartmentBreakdownChangeRequestDataSeries3d[15]
        issueFixesDataSeries = issueFixesDataSeries2d[15]
        changeRequestsDataSeries = changeRequestsDataSeries2d[15]

        var issueFixesDataLabels = issueFixesDataSeries
        var changeRequestsDataLabels = changeRequestsDataSeries

        var weeklyTicketsVerticalHigh = getMaxFrom2dAarray(weeklyTicketsDataSeries) + 2
        var verticalHighBars = getMaxFrom2dAarray(resolutionDaysGraphSeries) + 5
        var verticalHighStackBarsProcessIssues = getMaxColumnSumFrom2dAarray(processAndSiteTrendIssueGraphSeries) + 2
        var verticalHighStackBarsProcessChangeRequests = getMaxColumnSumFrom2dAarray(processAndSiteTrendChangeRequestGraphSeries) + 2
        var verticalHighStackBarsBaDeleteRequests = getMaxColumnSumFrom2dAarray(baDeleteRequestGraphSeries) + 2 >= 10 ? getMaxColumnSumFrom2dAarray(baDeleteRequestGraphSeries) + 2 : 10
        var verticalHighStackBarsTaDeleteRequests = getMaxColumnSumFrom2dAarray(taDeleteRequestGraphSeries) + 2 >= 10 ? getMaxColumnSumFrom2dAarray(taDeleteRequestGraphSeries) + 2 : 10
        // var verticalHighStackBarsProcessIssues = getSumFrom2dAarray(processAndSiteTrendIssueGraphSeries) + 2
        // var verticalHighStackBarsProcessChangeRequests = getSumFrom2dAarray(processAndSiteTrendChangeRequestGraphSeries) + 2
        var verticalHighStackBarsBreakdownIssues = getMaxColumnSumFrom2dAarray(siteDepartmentBreakdownIssueDataSeries) + 2
        var verticalHighStackBarsBreakdownChangeRequests = getMaxColumnSumFrom2dAarray(siteDepartmentBreakdownChangeRequestDataSeries) + 2
        var verticalHighIssueFixes = Math.max.apply(null, issueFixesDataSeries) + 2
        var verticalHighChangeRequestTypes = Math.max.apply(null, changeRequestsDataSeries) + 2

        setWeeklyTickets({
            ...weeklyTickets,
            data: {
                labels: weeklyTicketsDataLabels,
                series: weeklyTicketsDataSeries
            },
            options: {
                high: weeklyTicketsVerticalHigh,
                low: 0,
            }
        })
        // data displays for pie
        setResolutionDaysPercent({
            ...resolutionDaysPercent,
            data: {
                series: resolutionDaysPercentSeries
            },
            options: {
                labelInterpolationFnc: function (value) {
                    var total = resolutionDaysPercentSeries.reduce(function (a, b) { return a + b; })
                    return Math.round((value / total) * 100) + '%'; // passed total from updated array values added by reduce() method
                }
            }
        })
        // data display for bars
        setTicketResolutionDays({
            ...ticketResolutionDays,
            data: {
                labels: resolutionDaysGraphLabels,
                series: resolutionDaysGraphSeries
                // series: [
                //     [unresolvedSameDay, weekOneSameDay, weekTwoSameDay, weekThreeSameDay],
                //     [unresolved2Days, weekOne2Days, weekTwo2Days, weekThree2Days],
                //     [unresolved3Days, weekOne3Days, weekTwo3Days, weekThree3Days],
                //     [unresolved4Days, weekOne4Days, weekTwo4Days, weekThree4Days],
                //     [unresolved5Days, weekOne5Days, weekTwo5Days, weekThree5Days],
                //     [unresolved6to10Days, weekOne6to10Days, weekTwo6to10Days, weekThree6to10Days],
                //     [unresolvedOver10Days, weekOneOver10Days, weekTwoOver10Days, weekThreeOver10Days]
                // ]
            },
            options: {
                stackBars: false,
                height: 300,
                high: verticalHighBars,
                low: -5,

                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                        // return value.split(/\s+/).map(function (word) {
                        //     return word[0];
                        // }).join('');
                    }
                },
                axisY: {
                    offset: 30,
                },
                plugins: [
                    ctPointLabels({
                        textAnchor: 'middle',
                        // labelOffset: {
                        //     x: 0,
                        //     y: 0
                        // },
                        // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                        labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                    })
                ]
            },
        })
        // data displays for stack bars weekly trend
        setProcessAndSiteTrendIssue({
            ...processAndSiteTrendIssue,
            data: {
                labels: processAndSiteTrendIssueGraphLabels,
                series: processAndSiteTrendIssueGraphSeries
                // series: [
                //     //week-6, week-5, week-4, week-3, week-2, week-1
                //     [processIssueWeekOneITAD, processIssueWeekTwoITAD, processIssueWeekThreeITAD, processIssueWeekFourITAD, processIssueWeekFiveITAD],//ITAD
                //     [processIssueWeekOneAVR, processIssueWeekTwoAVR, processIssueWeekThreeAVR, processIssueWeekFourAVR, processIssueWeekFiveAVR],//AVR
                //     [processIssueWeekOneForcepoint, processIssueWeekTwoForcepoint, processIssueWeekThreeForcepoint, processIssueWeekFourForcepoint, processIssueWeekFiveForcepoint],//Forcepoint
                //     [processIssueWeekOneLB, processIssueWeekTwoLB, processIssueWeekThreeLB, processIssueWeekFourLB, processIssueWeekFiveLB],//LB
                //     [processIssueWeekOneDexgreen, processIssueWeekTwoDexgreen, processIssueWeekThreeDexgreen, processIssueWeekFourDexgreen, processIssueWeekFiveDexgreen],//Dexgreen
                //     [processIssueWeekOneProtek, processIssueWeekTwoProtek, processIssueWeekThreeProtek, processIssueWeekFourProtek, processIssueWeekFiveProtek],//Protek
                //     [processIssueWeekOneOnsiteServices, processIssueWeekTwoOnsiteServices, processIssueWeekThreeOnsiteServices, processIssueWeekFourOnsiteServices, processIssueWeekFiveOnsiteServices],//OnsiteServices
                //     [processIssueWeekOneAll, processIssueWeekTwoAll, processIssueWeekThreeAll, processIssueWeekFourAll, processIssueWeekFiveAll] //All
                // ]
            },
            options: {
                stackBars: true,
                height: 500,
                high: verticalHighStackBarsProcessIssues,
                low: 0,

                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                        // return value.split(/\s+/).map(function (word) {
                        //     return word[0];
                        // }).join('');
                    }
                },
                axisY: {
                    offset: 0,
                },
                // plugins: [
                //     ctPointLabels({
                //         textAnchor: 'middle',
                //         labelOffset: {
                //             x: -20,
                //             y: 0
                //         },
                //         // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                //         labelInterpolationFnc: v => v ? v : '' // fix to dont display 0
                //     })
                // ]
            }
        })
        setProcessAndSiteTrendChangeRequest({
            ...processAndSiteTrendChangeRequest,
            data: {
                labels: processAndSiteTrendChangeRequestGraphLabels,
                series: processAndSiteTrendChangeRequestGraphSeries
                // series: [
                //     [processChangeRequestWeekOneITAD, processChangeRequestWeekTwoITAD, processChangeRequestWeekThreeITAD, processChangeRequestWeekFourITAD, processChangeRequestWeekFiveITAD],
                //     [processChangeRequestWeekOneAVR, processChangeRequestWeekTwoAVR, processChangeRequestWeekThreeAVR, processChangeRequestWeekFourAVR, processChangeRequestWeekFiveAVR],
                //     [processChangeRequestWeekOneForcepoint, processChangeRequestWeekTwoForcepoint, processChangeRequestWeekThreeForcepoint, processChangeRequestWeekFourForcepoint, processChangeRequestWeekFiveForcepoint],
                //     [processChangeRequestWeekOneLB, processChangeRequestWeekTwoLB, processChangeRequestWeekThreeLB, processChangeRequestWeekFourLB, processChangeRequestWeekFiveLB],
                //     [processChangeRequestWeekOneDexgreen, processChangeRequestWeekTwoDexgreen, processChangeRequestWeekThreeDexgreen, processChangeRequestWeekFourDexgreen, processChangeRequestWeekFiveDexgreen],
                //     [processChangeRequestWeekOneProtek, processChangeRequestWeekTwoProtek, processChangeRequestWeekThreeProtek, processChangeRequestWeekFourProtek, processChangeRequestWeekFiveProtek],
                //     [processChangeRequestWeekOneOnsiteServices, processChangeRequestWeekTwoOnsiteServices, processChangeRequestWeekThreeOnsiteServices, processChangeRequestWeekFourOnsiteServices, processChangeRequestWeekFiveOnsiteServices],
                //     [processChangeRequestWeekOneAll, processChangeRequestWeekTwoAll, processChangeRequestWeekThreeAll, processChangeRequestWeekFourAll, processChangeRequestWeekFiveAll]
                // ]
            },
            options: {
                stackBars: true,
                height: 500,
                high: verticalHighStackBarsProcessChangeRequests,
                low: 0,

                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                        // return value.split(/\s+/).map(function (word) {
                        //     return word[0];
                        // }).join('');
                    }
                },
                axisY: {
                    offset: 0,
                },
                // plugins: [
                //     ctPointLabels({
                //         textAnchor: 'middle',
                //         labelOffset: {
                //             x: -20,
                //             y: 0
                //         },
                //         labelInterpolationFnc: v => v ? v : '' // fix to dont display 0
                //         // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                //     })
                // ]
            }
        })
        // display stackbars for BA/Delete Request and TA/Delete Request
        setWeeklyBaDeleteRequest({
            ...weeklyBaDeleteRequest,
            data: {
                labels: baDeleteRequestGraphLabels,
                series: baDeleteRequestGraphSeries
            },
            options: {
                stackBars: true,
                height: 500,
                high: verticalHighStackBarsBaDeleteRequests,
                low: 0,

                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                        // return value.split(/\s+/).map(function (word) {
                        //     return word[0];
                        // }).join('');
                    }
                },
                axisY: {
                    offset: 0,
                },
                // plugins: [
                //     ctPointLabels({
                //         textAnchor: 'middle',
                //         labelOffset: {
                //             x: -20,
                //             y: 0
                //         },
                //         labelInterpolationFnc: v => v ? v : '' // fix to dont display 0
                //         // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                //     })
                // ]
            }
        })
        setWeeklyTaDeleteRequest({
            ...weeklyTaDeleteRequest,
            data: {
                labels: taDeleteRequestGraphLabels,
                series: taDeleteRequestGraphSeries
            },
            options: {
                stackBars: true,
                height: 500,
                high: verticalHighStackBarsTaDeleteRequests,
                low: 0,

                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                        // return value.split(/\s+/).map(function (word) {
                        //     return word[0];
                        // }).join('');
                    }
                },
                axisY: {
                    offset: 0,
                },
                // plugins: [
                //     ctPointLabels({
                //         textAnchor: 'middle',
                //         labelOffset: {
                //             x: -20,
                //             y: 0
                //         },
                //         labelInterpolationFnc: v => v ? v : '' // fix to dont display 0
                //         // labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                //     })
                // ]
            }
        })
        // data displays for stackbars site department breakdown
        setSiteDepartmentBreakdownIssue({
            ...siteDepartmentBreakdownIssue,
            data: {
                labels: issueAndChangeRequestLabels,
                series: siteDepartmentBreakdownIssueDataSeries
                // series: [
                //     [siteDepartmentIssueKilbarryCustomerServiceWeek15, siteDepartmentIssueGlanmireCustomerServiceWeek15, siteDepartmentIssueUKCustomerServiceWeek15, siteDepartmentIssueThailandCustomerServiceWeek15, siteDepartmentIssueMarylandCustomerServiceWeek15, siteDepartmentIssueTexasCustomerServiceWeek15, siteDepartmentIssueVirginiaCustomerServiceWeek15, siteDepartmentIssueSacramentoCustomerServiceWeek15, siteDepartmentIssueMassachusettsCustomerServiceWeek15, siteDepartmentIssueDubaiCustomerServiceWeek15, siteDepartmentIssueAllCustomerServiceWeek15],
                //     [siteDepartmentIssueKilbarryEngineeringWeek15, siteDepartmentIssueGlanmireEngineeringWeek15, siteDepartmentIssueUKEngineeringWeek15, siteDepartmentIssueThailandEngineeringWeek15, siteDepartmentIssueMarylandEngineeringWeek15, siteDepartmentIssueTexasEngineeringWeek15, siteDepartmentIssueVirginiaEngineeringWeek15, siteDepartmentIssueSacramentoEngineeringWeek15, siteDepartmentIssueMassachusettsEngineeringWeek15, siteDepartmentIssueDubaiEngineeringWeek15, siteDepartmentIssueAllEngineeringWeek15],
                //     [siteDepartmentIssueKilbarryFinanceWeek15, siteDepartmentIssueGlanmireFinanceWeek15, siteDepartmentIssueUKFinanceWeek15, siteDepartmentIssueThailandFinanceWeek15, siteDepartmentIssueMarylandFinanceWeek15, siteDepartmentIssueTexasFinanceWeek15, siteDepartmentIssueVirginiaFinanceWeek15, siteDepartmentIssueSacramentoFinanceWeek15, siteDepartmentIssueMassachusettsFinanceWeek15, siteDepartmentIssueDubaiFinanceWeek15, siteDepartmentIssueAllFinanceWeek15],
                //     [siteDepartmentIssueKilbarryHRWeek15, siteDepartmentIssueGlanmireHRWeek15, siteDepartmentIssueUKHRWeek15, siteDepartmentIssueThailandHRWeek15, siteDepartmentIssueMarylandHRWeek15, siteDepartmentIssueTexasHRWeek15, siteDepartmentIssueVirginiaHRWeek15, siteDepartmentIssueSacramentoHRWeek15, siteDepartmentIssueMassachusettsHRWeek15, siteDepartmentIssueDubaiHRWeek15, siteDepartmentIssueAllHRWeek15],
                //     [siteDepartmentIssueKilbarryITWeek15, siteDepartmentIssueGlanmireITWeek15, siteDepartmentIssueUKITWeek15, siteDepartmentIssueThailandITWeek15, siteDepartmentIssueMarylandITWeek15, siteDepartmentIssueTexasITWeek15, siteDepartmentIssueVirginiaITWeek15, siteDepartmentIssueSacramentoITWeek15, siteDepartmentIssueMassachusettsITWeek15, siteDepartmentIssueDubaiITWeek15, siteDepartmentIssueAllITWeek15],
                //     [siteDepartmentIssueKilbarryLogisticsWeek15, siteDepartmentIssueGlanmireLogisticsWeek15, siteDepartmentIssueUKLogisticsWeek15, siteDepartmentIssueThailandLogisticsWeek15, siteDepartmentIssueMarylandLogisticsWeek15, siteDepartmentIssueTexasLogisticsWeek15, siteDepartmentIssueVirginiaLogisticsWeek15, siteDepartmentIssueSacramentoLogisticsWeek15, siteDepartmentIssueMassachusettsLogisticsWeek15, siteDepartmentIssueDubaiLogisticsWeek15, siteDepartmentIssueAllLogisticsWeek15],
                //     [siteDepartmentIssueKilbarryOperationsWeek15, siteDepartmentIssueGlanmireOperationsWeek15, siteDepartmentIssueUKOperationsWeek15, siteDepartmentIssueThailandOperationsWeek15, siteDepartmentIssueMarylandOperationsWeek15, siteDepartmentIssueTexasOperationsWeek15, siteDepartmentIssueVirginiaOperationsWeek15, siteDepartmentIssueSacramentoOperationsWeek15, siteDepartmentIssueMassachusettsOperationsWeek15, siteDepartmentIssueDubaiOperationsWeek15, siteDepartmentIssueAllOperationsWeek15],
                //     [siteDepartmentIssueKilbarryProcurementWeek15, siteDepartmentIssueGlanmireProcurementWeek15, siteDepartmentIssueUKProcurementWeek15, siteDepartmentIssueThailandProcurementWeek15, siteDepartmentIssueMarylandProcurementWeek15, siteDepartmentIssueTexasProcurementWeek15, siteDepartmentIssueVirginiaProcurementWeek15, siteDepartmentIssueSacramentoProcurementWeek15, siteDepartmentIssueMassachusettsProcurementWeek15, siteDepartmentIssueDubaiProcurementWeek15, siteDepartmentIssueAllProcurementWeek15],
                //     [siteDepartmentIssueKilbarryQualityWeek15, siteDepartmentIssueGlanmireQualityWeek15, siteDepartmentIssueUKQualityWeek15, siteDepartmentIssueThailandQualityWeek15, siteDepartmentIssueMarylandQualityWeek15, siteDepartmentIssueTexasQualityWeek15, siteDepartmentIssueVirginiaQualityWeek15, siteDepartmentIssueSacramentoQualityWeek15, siteDepartmentIssueMassachusettsQualityWeek15, siteDepartmentIssueDubaiQualityWeek15, siteDepartmentIssueAllQualityWeek15],
                //     [siteDepartmentIssueKilbarryRandDWeek15, siteDepartmentIssueGlanmireRandDWeek15, siteDepartmentIssueUKRandDWeek15, siteDepartmentIssueThailandRandDWeek15, siteDepartmentIssueMarylandRandDWeek15, siteDepartmentIssueTexasRandDWeek15, siteDepartmentIssueVirginiaRandDWeek15, siteDepartmentIssueSacramentoRandDWeek15, siteDepartmentIssueMassachusettsRandDWeek15, siteDepartmentIssueDubaiRandDWeek15, siteDepartmentIssueAllRandDWeek15],
                //     [siteDepartmentIssueKilbarrySalesWeek15, siteDepartmentIssueGlanmireSalesWeek15, siteDepartmentIssueUKSalesWeek15, siteDepartmentIssueThailandSalesWeek15, siteDepartmentIssueMarylandSalesWeek15, siteDepartmentIssueTexasSalesWeek15, siteDepartmentIssueVirginiaSalesWeek15, siteDepartmentIssueSacramentoSalesWeek15, siteDepartmentIssueMassachusettsSalesWeek15, siteDepartmentIssueDubaiSalesWeek15, siteDepartmentIssueAllSalesWeek15],
                //     [siteDepartmentIssueKilbarryTRMAWeek15, siteDepartmentIssueGlanmireTRMAWeek15, siteDepartmentIssueUKTRMAWeek15, siteDepartmentIssueThailandTRMAWeek15, siteDepartmentIssueMarylandTRMAWeek15, siteDepartmentIssueTexasTRMAWeek15, siteDepartmentIssueVirginiaTRMAWeek15, siteDepartmentIssueSacramentoTRMAWeek15, siteDepartmentIssueMassachusettsTRMAWeek15, siteDepartmentIssueDubaiTRMAWeek15, siteDepartmentIssueAllTRMAWeek15]
                // ]
            },
            options: {
                stackBars: true,
                height: 400,
                high: verticalHighStackBarsBreakdownIssues,
                low: 0,

                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                    }
                },
                axisY: {
                    offset: 0,
                }
            }
        })
        setSiteDepartmentBreakdownChangeRequest({
            ...siteDepartmentBreakdownChangeRequest,
            data: {
                labels: issueAndChangeRequestLabels,
                series: siteDepartmentBreakdownChangeRequestDataSeries
                // series: [
                //     [siteDepartmentChangeRequestKilbarryCustomerServiceWeek15, siteDepartmentChangeRequestGlanmireCustomerServiceWeek15, siteDepartmentChangeRequestUKCustomerServiceWeek15, siteDepartmentChangeRequestThailandCustomerServiceWeek15, siteDepartmentChangeRequestMarylandCustomerServiceWeek15, siteDepartmentChangeRequestTexasCustomerServiceWeek15, siteDepartmentChangeRequestVirginiaCustomerServiceWeek15, siteDepartmentChangeRequestSacramentoCustomerServiceWeek15, siteDepartmentChangeRequestMassachusettsCustomerServiceWeek15, siteDepartmentChangeRequestDubaiCustomerServiceWeek15, siteDepartmentChangeRequestAllCustomerServiceWeek15],
                //     [siteDepartmentChangeRequestKilbarryEngineeringWeek15, siteDepartmentChangeRequestGlanmireEngineeringWeek15, siteDepartmentChangeRequestUKEngineeringWeek15, siteDepartmentChangeRequestThailandEngineeringWeek15, siteDepartmentChangeRequestMarylandEngineeringWeek15, siteDepartmentChangeRequestTexasEngineeringWeek15, siteDepartmentChangeRequestVirginiaEngineeringWeek15, siteDepartmentChangeRequestSacramentoEngineeringWeek15, siteDepartmentChangeRequestMassachusettsEngineeringWeek15, siteDepartmentChangeRequestDubaiEngineeringWeek15, siteDepartmentChangeRequestAllEngineeringWeek15],
                //     [siteDepartmentChangeRequestKilbarryFinanceWeek15, siteDepartmentChangeRequestGlanmireFinanceWeek15, siteDepartmentChangeRequestUKFinanceWeek15, siteDepartmentChangeRequestThailandFinanceWeek15, siteDepartmentChangeRequestMarylandFinanceWeek15, siteDepartmentChangeRequestTexasFinanceWeek15, siteDepartmentChangeRequestVirginiaFinanceWeek15, siteDepartmentChangeRequestSacramentoFinanceWeek15, siteDepartmentChangeRequestMassachusettsFinanceWeek15, siteDepartmentChangeRequestDubaiFinanceWeek15, siteDepartmentChangeRequestAllFinanceWeek15],
                //     [siteDepartmentChangeRequestKilbarryHRWeek15, siteDepartmentChangeRequestGlanmireHRWeek15, siteDepartmentChangeRequestUKHRWeek15, siteDepartmentChangeRequestThailandHRWeek15, siteDepartmentChangeRequestMarylandHRWeek15, siteDepartmentChangeRequestTexasHRWeek15, siteDepartmentChangeRequestVirginiaHRWeek15, siteDepartmentChangeRequestSacramentoHRWeek15, siteDepartmentChangeRequestMassachusettsHRWeek15, siteDepartmentChangeRequestDubaiHRWeek15, siteDepartmentChangeRequestAllHRWeek15],
                //     [siteDepartmentChangeRequestKilbarryITWeek15, siteDepartmentChangeRequestGlanmireITWeek15, siteDepartmentChangeRequestUKITWeek15, siteDepartmentChangeRequestThailandITWeek15, siteDepartmentChangeRequestMarylandITWeek15, siteDepartmentChangeRequestTexasITWeek15, siteDepartmentChangeRequestVirginiaITWeek15, siteDepartmentChangeRequestSacramentoITWeek15, siteDepartmentChangeRequestMassachusettsITWeek15, siteDepartmentChangeRequestDubaiITWeek15, siteDepartmentChangeRequestAllITWeek15],
                //     [siteDepartmentChangeRequestKilbarryLogisticsWeek15, siteDepartmentChangeRequestGlanmireLogisticsWeek15, siteDepartmentChangeRequestUKLogisticsWeek15, siteDepartmentChangeRequestThailandLogisticsWeek15, siteDepartmentChangeRequestMarylandLogisticsWeek15, siteDepartmentChangeRequestTexasLogisticsWeek15, siteDepartmentChangeRequestVirginiaLogisticsWeek15, siteDepartmentChangeRequestSacramentoLogisticsWeek15, siteDepartmentChangeRequestMassachusettsLogisticsWeek15, siteDepartmentChangeRequestDubaiLogisticsWeek15, siteDepartmentChangeRequestAllLogisticsWeek15],
                //     [siteDepartmentChangeRequestKilbarryOperationsWeek15, siteDepartmentChangeRequestGlanmireOperationsWeek15, siteDepartmentChangeRequestUKOperationsWeek15, siteDepartmentChangeRequestThailandOperationsWeek15, siteDepartmentChangeRequestMarylandOperationsWeek15, siteDepartmentChangeRequestTexasOperationsWeek15, siteDepartmentChangeRequestVirginiaOperationsWeek15, siteDepartmentChangeRequestSacramentoOperationsWeek15, siteDepartmentChangeRequestMassachusettsOperationsWeek15, siteDepartmentChangeRequestDubaiOperationsWeek15, siteDepartmentChangeRequestAllOperationsWeek15],
                //     [siteDepartmentChangeRequestKilbarryProcurementWeek15, siteDepartmentChangeRequestGlanmireProcurementWeek15, siteDepartmentChangeRequestUKProcurementWeek15, siteDepartmentChangeRequestThailandProcurementWeek15, siteDepartmentChangeRequestMarylandProcurementWeek15, siteDepartmentChangeRequestTexasProcurementWeek15, siteDepartmentChangeRequestVirginiaProcurementWeek15, siteDepartmentChangeRequestSacramentoProcurementWeek15, siteDepartmentChangeRequestMassachusettsProcurementWeek15, siteDepartmentChangeRequestDubaiProcurementWeek15, siteDepartmentChangeRequestAllProcurementWeek15],
                //     [siteDepartmentChangeRequestKilbarryQualityWeek15, siteDepartmentChangeRequestGlanmireQualityWeek15, siteDepartmentChangeRequestUKQualityWeek15, siteDepartmentChangeRequestThailandQualityWeek15, siteDepartmentChangeRequestMarylandQualityWeek15, siteDepartmentChangeRequestTexasQualityWeek15, siteDepartmentChangeRequestVirginiaQualityWeek15, siteDepartmentChangeRequestSacramentoQualityWeek15, siteDepartmentChangeRequestMassachusettsQualityWeek15, siteDepartmentChangeRequestDubaiQualityWeek15, siteDepartmentChangeRequestAllQualityWeek15],
                //     [siteDepartmentChangeRequestKilbarryRandDWeek15, siteDepartmentChangeRequestGlanmireRandDWeek15, siteDepartmentChangeRequestUKRandDWeek15, siteDepartmentChangeRequestThailandRandDWeek15, siteDepartmentChangeRequestMarylandRandDWeek15, siteDepartmentChangeRequestTexasRandDWeek15, siteDepartmentChangeRequestVirginiaRandDWeek15, siteDepartmentChangeRequestSacramentoRandDWeek15, siteDepartmentChangeRequestMassachusettsRandDWeek15, siteDepartmentChangeRequestDubaiRandDWeek15, siteDepartmentChangeRequestAllRandDWeek15],
                //     [siteDepartmentChangeRequestKilbarrySalesWeek15, siteDepartmentChangeRequestGlanmireSalesWeek15, siteDepartmentChangeRequestUKSalesWeek15, siteDepartmentChangeRequestThailandSalesWeek15, siteDepartmentChangeRequestMarylandSalesWeek15, siteDepartmentChangeRequestTexasSalesWeek15, siteDepartmentChangeRequestVirginiaSalesWeek15, siteDepartmentChangeRequestSacramentoSalesWeek15, siteDepartmentChangeRequestMassachusettsSalesWeek15, siteDepartmentChangeRequestDubaiSalesWeek15, siteDepartmentChangeRequestAllSalesWeek15],
                //     [siteDepartmentChangeRequestKilbarryTRMAWeek15, siteDepartmentChangeRequestGlanmireTRMAWeek15, siteDepartmentChangeRequestUKTRMAWeek15, siteDepartmentChangeRequestThailandTRMAWeek15, siteDepartmentChangeRequestMarylandTRMAWeek15, siteDepartmentChangeRequestTexasTRMAWeek15, siteDepartmentChangeRequestVirginiaTRMAWeek15, siteDepartmentChangeRequestSacramentoTRMAWeek15, siteDepartmentChangeRequestMassachusettsTRMAWeek15, siteDepartmentChangeRequestDubaiTRMAWeek15, siteDepartmentChangeRequestAllTRMAWeek15]
                // ]
            },
            options: {
                stackBars: true,
                height: 400,
                high: verticalHighStackBarsBreakdownChangeRequests,
                low: 0,

                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value;
                    }
                },
                axisY: {
                    offset: 0,
                }
            }
        })
        //data displays for change request types and issue fixes
        setWeeklyIssueFixes({
            ...weeklyIssueFixes,
            data: {
                labels: issueFixesDataLabels,
                series: issueFixesDataSeries
                //series: [issueSystemCIRequiredWeek15, issueProcessCIRequiredWeek15, issueNewReportRequiredWeek15, issueNoFixRequiredWeek15, issueDebugWeek15, issueTrainingRequiredWeek15, issueUpdateDocumentationWeek15, issueRepairReplaceHardwareWeek15, issueFixDataAndPostWeek15, issueUpdateAccessWeek15]
            },
            options: {
                high: verticalHighIssueFixes,
                low: 0
            }
        })
        setWeeklyRequestTypes({
            ...weeklyRequestTypes,
            data: {
                labels: changeRequestsDataLabels,
                series: changeRequestsDataSeries
                //series: [requestAccessNeededWeek15, requestNewReportNeededWeek15, requestSystemChangeWeek15, requestHardwareRequiredWeek15, requestSoftwareRequiredWeek15, requestNewHireWeek15, requestTerminationOfEmploymentWeek15]
            },
            options: {
                high: verticalHighChangeRequestTypes,
                low: 0
            }
        })
        setWeekSelected({
            ...weekSelected,
            issues3dArray: siteDepartmentBreakdownIssueDataSeries3d,
            changeRequests3dArray: siteDepartmentBreakdownChangeRequestDataSeries3d,
            issueFixTypes2dArray: issueFixesDataSeries2d,
            changeRequestTypes2dArray: changeRequestsDataSeries2d
        })
        setCalculating(false)
    }
    const getGraphsData = () => {
        // let dataRangeWeeklyTrendStart = new Date(moment().add(-15, 'weeks').startOf('isoWeek'))
        // console.log('dataRangeWeeklyTrendStart', dataRangeWeeklyTrendStart)
        db.collection('ticketsIT')
            // .where("DateRaised", ">=", dataRangeWeeklyTrendStart)
            .orderBy("DateRaised", "asc")
            // .limit(13)
            .get()
            .then(snapshot => {
                console.log('snapshot.size', snapshot.size)
                calcWeeklyVars(snapshot)
            })
            .catch(function (error) {
                console.log("Error getting IT tickets: ", error);
            });
    }

    useEffect(() => {
        getGraphsData()
        return () => { }
    }, [])

    return (
        <Card className={classes.cardPadding}>

            {calculating ? <LinearProgress id='searchGraphProgress' className={classes.searchProgress} /> : null}

            <Typography className={classes.textCenter} variant="h6">
                Weekly Trend Open Vs Closed
            </Typography>

            <ChartistGraph
                data={weeklyTickets.data}
                type={weeklyTickets.type}
                options={weeklyTickets.options}
                listener={weeklyTickets.animation}
                plugins={weeklyTickets.plugins}
            />
            <Grid container className={classes.paddingBottom100}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <FormControlLabel className={classes.ticks}
                    control={<Checkbox onChange={handleGraphChangeClick} id="OI" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                        color: 'rgb(50, 50, 240)',
                        '&$checked': {
                            color: 'rgb(50, 50, 240)',
                        },
                        marginLeft: '35px',
                    }} />}
                    label="Open Issues"
                />
                <FormControlLabel className={classes.ticks}
                    control={<Checkbox onChange={handleGraphChangeClick} id="CI" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                        color: 'rgb(200, 110, 10)',
                        '&$checked': {
                            color: 'rgb(200, 110, 10)',
                        },
                        marginLeft: '35px',
                    }} />}
                    label="Closed Issues"
                />
                <FormControlLabel className={classes.ticks}
                    control={<Checkbox onChange={handleGraphChangeClick} id="OR" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                        color: 'rgb(20, 150, 30)',
                        '&$checked': {
                            color: 'rgb(20, 150, 30)',
                        },
                        marginLeft: '35px',
                    }} />}
                    label="Open Change Requests"
                />
                <FormControlLabel className={classes.ticks}
                    control={<Checkbox onChange={handleGraphChangeClick} id="CR" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                        color: 'rgb(210, 180, 30)',
                        '&$checked': {
                            color: 'rgb(210, 180, 30)',
                        },
                        marginLeft: '35px',
                    }} />}
                    label="Closed Change Requests"
                />

            </Grid>

            <Typography className={classes.textCenter} variant="h6">
                Resolution Days percent to date
            </Typography>

            <Grid container className={classes.paddingBottom100}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <Container className={classes.resolutionDaysPie}>
                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center">

                        <Grid container xs={8}
                            item={true}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">

                            <ChartistGraph className="ct-chart ct-square" // to make pie bigger
                                data={resolutionDaysPercent.data}
                                type={resolutionDaysPercent.type}
                                options={resolutionDaysPercent.options}
                            // options={resolutionDays.options}
                            // listener={resolutionDays.animation}
                            />

                        </Grid>

                        <Grid container item xs={4}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center">

                            <div className={classes.Green}>.</div><p>Same Day</p>
                            <div className={classes.Blue}>.</div><p>2 Days</p>
                            <div className={classes.Grey}>.</div><p>3 Days</p>
                            <div className={classes.Yellow}>.</div><p>4 Days</p>
                            <div className={classes.Orange}>.</div><p>5 Days</p>
                            <div className={classes.Violet}>.</div><p>6 - 10 Days</p>
                            <div className={classes.Red}>.</div><p>Over 10 Days</p>

                        </Grid>

                    </Grid>
                </Container>
            </Grid>

            <Typography className={classes.textCenter} variant="h6">
                Ticket Resolution Days
            </Typography>

            <ChartistGraph
                data={ticketResolutionDays.data}
                type={ticketResolutionDays.type}
                options={ticketResolutionDays.options}
                // listener={ticketResolutionDays.animation}
                plugins={ticketResolutionDays.options.plugins}
            />

            <Grid container className={classes.paddingBottom100}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Green}>.</div><p>Same Day</p>
                <div className={classes.Blue}>.</div><p>2 Days</p>
                <div className={classes.Grey}>.</div><p>3 Days</p>
                <div className={classes.Yellow}>.</div><p>4 Days</p>
                <div className={classes.Orange}>.</div><p>5 Days</p>
                <div className={classes.Violet}>.</div><p>6 - 10 Days</p>
                <div className={classes.Red}>.</div><p>Over 10 Days</p>

            </Grid>

            <Grid container className={classes.paddingBottom100}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <Typography className={classes.textCenter} variant="h6">
                    Tickets Weekly Trend
                </Typography>

                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Grid item xs={6} className={classes.textCenter}>
                        <Typography variant="caption">
                            Issue Raised Per Process
                        </Typography>

                        <ChartistGraph className={classes.margin20}
                            data={processAndSiteTrendIssue.data}
                            type={processAndSiteTrendIssue.type}
                            options={processAndSiteTrendIssue.options}
                            // listener={processAndSiteTrendIssue.animation}
                            plugins={processAndSiteTrendIssue.options.plugins}
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.textCenter}>
                        <Typography variant="caption">
                            Change Request Raised Per Process
                        </Typography>

                        <ChartistGraph className={classes.margin20}
                            data={processAndSiteTrendChangeRequest.data}
                            type={processAndSiteTrendChangeRequest.type}
                            options={processAndSiteTrendChangeRequest.options}
                            // listener={processAndSiteTrendIssue.animation}
                            plugins={processAndSiteTrendChangeRequest.options.plugins}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">

                    <div className={classes.Green}>.</div><p>ITAD</p>
                    <div className={classes.Blue}>.</div><p>AVR</p>
                    <div className={classes.Grey}>.</div><p>Forcepoint</p>
                    <div className={classes.Yellow}>.</div><p>LB</p>
                    <div className={classes.Orange}>.</div><p>Dexgreen</p>
                    <div className={classes.Violet}>.</div><p>Protek</p>
                    <div className={classes.Red}>.</div><p>Onsite Service</p>
                    <div className={classes.DarkViolet}>.</div><p>All</p>

                </Grid>
            </Grid>

            <div className={classes.textCenter}>
                <Typography variant="caption">
                    Basic Audit Delete Requests
                </Typography>
            </div>

            <ChartistGraph className={classes.margin20}
                data={weeklyBaDeleteRequest.data}
                type={weeklyBaDeleteRequest.type}
                options={weeklyBaDeleteRequest.options}
                // listener={weeklyBaDeleteRequest.animation}
                plugins={weeklyBaDeleteRequest.options.plugins}
            />
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Green}>.</div><p>Kilbarry</p>
                <div className={classes.Blue}>.</div><p>K4 / K5</p>
                <div className={classes.Grey}>.</div><p>Carrigtohill</p>
                <div className={classes.Yellow}>.</div><p>UK</p>
                <div className={classes.Orange}>.</div><p>Thailand</p>
                <div className={classes.Violet}>.</div><p>MD - Maryland</p>
            </Grid>

            <Grid container className={classes.paddingBottom100}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Red}>.</div><p>TX - Texas</p>
                <div className={classes.DarkViolet}>.</div><p>VA - Virginia</p>
                <div className={classes.LightGreen}>.</div><p>SAC - Sacramento</p>
                <div className={classes.LightBlue}>.</div><p>MA - Massachusetts</p>
                <div className={classes.LightYellow}>.</div><p>Dubai</p>
                <div className={classes.Pink}>.</div><p>All Sites</p>
            </Grid>

            <div className={classes.textCenter}>
                <Typography variant="caption">
                    Tech Audit Delete Requests
                </Typography>
            </div>

            <ChartistGraph className={classes.margin20}
                data={weeklyTaDeleteRequest.data}
                type={weeklyTaDeleteRequest.type}
                options={weeklyTaDeleteRequest.options}
                // listener={weeklyTaDeleteRequest.animation}
                plugins={weeklyTaDeleteRequest.options.plugins}
            />

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Green}>.</div><p>Kilbarry</p>
                <div className={classes.Blue}>.</div><p>K4 / K5</p>
                <div className={classes.Grey}>.</div><p>Carrigtohill</p>
                <div className={classes.Yellow}>.</div><p>UK</p>
                <div className={classes.Orange}>.</div><p>Thailand</p>
                <div className={classes.Violet}>.</div><p>MD - Maryland</p>
            </Grid>

            <Grid container className={classes.paddingBottom100}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Red}>.</div><p>TX - Texas</p>
                <div className={classes.DarkViolet}>.</div><p>VA - Virginia</p>
                <div className={classes.LightGreen}>.</div><p>SAC - Sacramento</p>
                <div className={classes.LightBlue}>.</div><p>MA - Massachusetts</p>
                <div className={classes.LightYellow}>.</div><p>Dubai</p>
                <div className={classes.Pink}>.</div><p>All Sites</p>
            </Grid>

            <Typography className={classes.textCenter} variant="h6">
                Site & Department Breakdown
            </Typography>

            <Grid container item xs={12} sm={3} spacing={1} className={classes.centerSelect}>
                <TextField
                    // disabled={holidayRequestForm.PartDay ? false : true}
                    select
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    id="weekSelect"
                    label="Please select week"
                    name="weekSelect"
                    inputProps={{ maxLength: 50 }}
                    onChange={handleChangeWeek}
                    InputLabelProps={{ shrink: true }}
                    value={weekSelected.selectedWeekNumber}>
                    {weekSelected.weekSelect.map((element) => (
                        <MenuItem key={element.value} value={element.value}>
                            {element.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <div className={classes.textCenter}>
                <Typography variant="caption" id="issueErrorTicketsLabel">
                    Issue Error Tickets - Week {moment().isoWeek()}
                </Typography>
            </div>

            <ChartistGraph className={classes.margin20}
                data={siteDepartmentBreakdownIssue.data}
                type={siteDepartmentBreakdownIssue.type}
                options={siteDepartmentBreakdownIssue.options}
                // listener={processAndSiteTrendIssue.animation}
                plugins={siteDepartmentBreakdownIssue.options.plugins}
            />

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Green}>.</div><p>Customer Service</p>
                <div className={classes.Blue}>.</div><p>Engineering</p>
                <div className={classes.Grey}>.</div><p>Finance</p>
                <div className={classes.Yellow}>.</div><p>HR</p>
                <div className={classes.Orange}>.</div><p>IT</p>
                <div className={classes.Violet}>.</div><p>Logistics</p>
            </Grid>
            <Grid container className={classes.paddingBottom100}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Red}>.</div><p>Operations</p>
                <div className={classes.DarkViolet}>.</div><p>Procurement</p>
                <div className={classes.LightGreen}>.</div><p>Quality</p>
                <div className={classes.LightBlue}>.</div><p>R&D</p>
                <div className={classes.LightYellow}>.</div><p>Sales</p>
                <div className={classes.Pink}>.</div><p>TRMA</p>
            </Grid>

            <div className={classes.textCenter}>
                <Typography variant="caption" id="changeRequestTicketsLabel">
                    Change Request Tickets - Week {moment().isoWeek()}
                </Typography>
            </div>

            <ChartistGraph className={classes.margin20}
                data={siteDepartmentBreakdownChangeRequest.data}
                type={siteDepartmentBreakdownChangeRequest.type}
                options={siteDepartmentBreakdownChangeRequest.options}
                // listener={processAndSiteTrendIssue.animation}
                plugins={siteDepartmentBreakdownChangeRequest.options.plugins}
            />

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Green}>.</div><p>Customer Service</p>
                <div className={classes.Blue}>.</div><p>Engineering</p>
                <div className={classes.Grey}>.</div><p>Finance</p>
                <div className={classes.Yellow}>.</div><p>HR</p>
                <div className={classes.Orange}>.</div><p>IT</p>
                <div className={classes.Violet}>.</div><p>Logistics</p>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" className={classes.paddingBottom100}>

                <div className={classes.Red}>.</div><p>Operations</p>
                <div className={classes.DarkViolet}>.</div><p>Procurement</p>
                <div className={classes.LightGreen}>.</div><p>Quality</p>
                <div className={classes.LightBlue}>.</div><p>R&D</p>
                <div className={classes.LightYellow}>.</div><p>Sales</p>
                <div className={classes.Pink}>.</div><p>TRMA</p>
            </Grid>

            <Typography className={classes.textCenter} variant="h6" >
                Issue Fixes & Change Request Types
            </Typography>

            <div className={classes.textCenter}>
                <Typography variant="caption" id="issueFixesLabel">
                    Issue Fixes - Week {moment().isoWeek()}
                </Typography>
            </div>

            <ChartistGraph className={classes.margin20}
                data={weeklyIssueFixes.data}
                type={weeklyIssueFixes.type}
                options={weeklyIssueFixes.options}
            // listener={processAndSiteTrendIssue.animation}
            // plugins={weeklyIssueFixes.options.plugins}
            />

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Green}>.</div><p>System CI Required</p>
                <div className={classes.Blue}>.</div><p>Process CI Required</p>
                <div className={classes.Grey}>.</div><p>New Report Required</p>
                <div className={classes.Yellow}>.</div><p>No Fix Required</p>
                <div className={classes.Orange}>.</div><p>Debug</p>
            </Grid>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" className={classes.paddingBottom100}>

                <div className={classes.Violet}>.</div><p>Training Required</p>
                <div className={classes.Red}>.</div><p>Update Documentation</p>
                <div className={classes.DarkViolet}>.</div><p>Repair/Replace Hardware</p>
                <div className={classes.LightGreen}>.</div><p>Fixed Data & Post</p>
                <div className={classes.LightBlue}>.</div><p>Update Access</p>
                {/* <div className={classes.LightYellow}>.</div><p>Sales</p>
                    <div className={classes.Pink}>.</div><p>TRMA</p> */}
            </Grid>

            <div className={classes.textCenter}>
                <Typography variant="caption" id="changeRequestTypeLabel">
                    Change Request Types - Week {moment().isoWeek()}
                </Typography>
            </div>

            <ChartistGraph className={classes.margin20}
                data={weeklyRequestTypes.data}
                type={weeklyRequestTypes.type}
                options={weeklyRequestTypes.options}
            // listener={processAndSiteTrendIssue.animation}
            // plugins={weeklyRequestTypes.options.plugins}
            />

            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.Green}>.</div><p>Access Needed</p>
                <div className={classes.Blue}>.</div><p>New Report Needed</p>
                <div className={classes.Grey}>.</div><p>System Change Request</p>
                <div className={classes.Yellow}>.</div><p>Hardware Required</p>
                <div className={classes.Orange}>.</div><p>Software Required</p>
                <div className={classes.Violet}>.</div><p>New Hire</p>
                <div className={classes.Red}>.</div><p>Termination of employment</p>
            </Grid>

        </Card >
    )
}