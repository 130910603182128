import React, { useState, useEffect, useContext } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles } from '@material-ui/core';
import { AuthContext } from '../../../contexts/AuthContext';
import { db } from '../../../config/fbConfig';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    minWidth: {
        minWidth: '200px'
    },
    body: {
        padding: '50px'
    },
    leftMargin: {
        marginLeft: '30px',
    },
}))

export default function UsersTasksByDepartment() {
    const classes = useStyles();

    const { firstName, uid } = useContext(AuthContext);

    const [userTasks, setUserTasks] = useState({
        columns: [
            { title: 'Week No', field: 'weekNo', editable: 'never' },
            // { title: 'Department', field: 'department', editable: 'never' },
            { title: 'Assigned By', field: 'assignedBy', editable: 'never' },
            { title: 'Task Category', field: 'taskCategory', editable: 'never' },
            // { title: 'Assigned To', field: 'assignedTo', editable: 'never' },
            { title: 'Assigned To Full Name', field: 'assignedToFullName', editable: 'never' },
            { title: 'Manager Comment', field: 'additionalComment', editable: 'never' },
            { title: 'Allocated (h)', field: 'allocatedHours', editable: 'never' },
            { title: 'Actual (h)', field: 'actualHours', editable: 'never' },
            { title: 'User Comment', field: 'additionalUserComment', editable: 'never' }, //filtering: false
            { title: 'Allocated (%)', field: 'allocatedPercent', editable: 'never' },
            { title: 'User Leave', field: 'assignedUserLeave', editable: 'never' },
            { title: 'Available (h)', field: 'availableHours', editable: 'never' },
            { title: 'Created', field: 'dateCreated', editable: 'never' },
            // { title: 'dateWorkEnded', field: 'dateWorkEnded', editable: 'never' },
            // { title: 'dateWorkStarted', field: 'dateWorkStarted', editable: 'never' },

            // { title: 'Root Cause Description', field: 'RootCauseDescription', cellStyle: { whiteSpace: "nowrap", maxWidth: "50px" }, editable: 'never' },  
        ],
        data: [],
    });

    const getUserTasks = async () => {
        var allTasks = []
        var departmentTasks = []
        var task = {}
        var userDetails = await db.collection('users').doc(uid).get()
        // console.log(userDetails.data())
        db.collection('userTasks')
            .where('department', '==', userDetails.data().department)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    task = doc.data();
                    task.id = doc.id
                    task.dateCreated = moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY')
                    departmentTasks.push(task);
                    // console.log(task)
                    // allTasks.push(task);
                    // if (department === task.department) {
                    //     departmentTasks.push(task)
                    // }
                })
                setUserTasks({
                    ...userTasks,
                    data: departmentTasks,
                })
            })
            .catch(function (error) {
                console.log("Error getting user tasks: ", error);
            });
    }

    useEffect(() => {
        getUserTasks()
        return () => { }
    }, []);

    return (
        <div >

            <MaterialTable
                title={"User Tasks"}
                columns={userTasks.columns}
                data={userTasks.data}
                // detailPanel={rowData => {
                //     rowData.username = username; // assigning username for email purpose
                //     // console.log(rowData)
                //     return (
                //         <TicketsITDetails ticketData={rowData} />
                //     )
                // }}
                options={{
                    actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50, userTasks.data.length === 0 ? 100 : { value: userTasks.data.length, label: 'All' }]
                }}//, { value: userTasks.data.length, label: 'All' }] }}
                // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                actions={
                    [{
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getUserTasks()
                            return () => { }
                        },
                    }]}
                // editable={{
                //     // onRowAdd: newData =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             resolve();
                //     //             setIssueError(prevState => {
                //     //                 const data = [...prevState.data];
                //     //                 data.push(newData);
                //     //                 return { ...prevState, data };
                //     //             });
                //     //         }, 600);
                //     //     }),
                //     // onRowUpdate: (newData, oldData) =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             if (oldData) {
                //     //                 setUserTasks(prevState => {
                //     //                     const data = [...prevState.data];
                //     //                     data[data.indexOf(oldData)] = newData;
                //     //                     if (newData.Status == 'Completed' || newData.Status == 'Closed - Not Needed') {
                //     //                         var date = new Date();
                //     //                         var today = date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();
                //     //                         newData.ClosedDate = today;
                //     //                     }
                //     //                     console.log('Updated issue error ticket in DB')
                //     //                     // Update data
                //     //                     // if closed or not needed then add closing date
                //     //                     db.collection("issueErrorTickets").doc(newData.ticketId).update({
                //     //                         Status: newData.Status,
                //     //                         AssignedIT: newData.AssignedIT,
                //     //                         RootCauseCategory: newData.RootCauseCategory,
                //     //                         FixType: newData.FixType,
                //     //                         FailureCategory: newData.FailureCategory,
                //     //                         FixUpdateDescription: newData.FixUpdateDescription,
                //     //                         ClosedDate: newData.ClosedDate,
                //     //                     });
                //     //                     resolve();
                //     //                     return { ...prevState, data };
                //     //                 })
                //     //             }
                //     //         }, 600)
                //     //     }),
                //     // onRowDelete: oldData =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             resolve();
                //     //             setIssueError(prevState => {
                //     //                 const data = [...prevState.data];
                //     //                 data.splice(data.indexOf(oldData), 1);
                //     //                 return { ...prevState, data };
                //     //             });
                //     //         }, 600);
                //     //     }),
                // }}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div >
                                <p className={classes.leftMargin}>Hey {firstName}! Here you can view and download your team data.</p>
                            </div>
                        </div>
                    ),
                }}
            />
        </div>
    );
}
