import { Container, CssBaseline, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import SideMenu from '../../SideMenus/SideMenu';
import UnderConstruction from '../../UnderConstruction';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
    },
}));

export default function MetricsQualityFPY(props) {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenu />
                        <Container component="main" className={classes.main} maxWidth="xl">

                            <h1>Metrics - FPY</h1>

                            <UnderConstruction />

                        </Container>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}