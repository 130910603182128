import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Grid, TextField, Typography } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { AuthContext } from '../../../contexts/AuthContext';
import moment from 'moment';
import { functions } from '../../../config/fbConfig';
import sucAudio from '../../../sounds/successSound.mp3'
import errAudio from '../../../sounds/errorSound.mp3'
import Loading from '../../layout/Loading'
import { useRef } from 'react';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        marginTop: '30px',
    },
    clockMargin: {
        margin: '50px 48px 50px 0px'
    },
    calcInput: {
        margin: '50px'
    },
    calcBtns: {
        fontSize: '1.5em',
        backgroundColor: '#e2f7cd',
        height: '80px',
        width: '80px',
        margin: '10px',
    },
    calcEnterBtn: {
        backgroundColor: '#cbe2f2',
        height: '80px',
        width: '180px',
        margin: '10px'
    },
    calcBackOneBtn: {
        backgroundColor: '#f7dfcd',
        height: '40px',
        width: '80px',
        margin: '10px'
    },
    calcClearBtn: {
        backgroundColor: '#f7dfcd',
        height: '40px',
        width: '180px',
        margin: '10px'
    },
    bigDialog: {
        width: 500,
        height: 500,
        // height: '80%',
        // height: '90%',
    }
}));

export default function AttendanceClock() {
    const classes = useStyles();

    const { auth } = useContext(AuthContext)

    const [scanInfo, setScanInfo] = useState({
        BadgeID: '',
        ClockTime: '',
        error: false,
        helperText: '',
        allEmployees: [],
        disabledInputs: true,
        fullName: '',
        type: '',
        clockMessage: '',
    })
    const [open, setOpen] = useState(false);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const timerRef = useRef()

    function startTime() {
        const today = new Date();
        let h = today.getHours();
        let m = today.getMinutes();
        let s = today.getSeconds();
        m = checkTime(m);
        s = checkTime(s);
        var clock = document.getElementById('clock');
        if (clock != null) {
            document.getElementById('clock').innerHTML = h + ":" + m + ":" + s;
            timerRef.current = setTimeout(startTime, 1000);
        }
    }
    function checkTime(i) {
        if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
        return i;
    }
    useEffect(() => {
        startTime()
        return () => {
            clearTimeout(timerRef.current)
        }
    }, [])

    const handleChange = (e) => {
        scanInfo.BadgeID.length > 6 ?
            setScanInfo({
                ...scanInfo,
                error: true,
                helperText: 'Badge ID character limit reached'
            })
            :
            setScanInfo({
                ...scanInfo,
                BadgeID: e.target.value,
                ClockTime: moment().format('YYYY-MM-DDTHH:mm:ssZ') // ISO format with locale value
            })
    }
    const handleTyping = (numberString) => {
        scanInfo.BadgeID.length > 6 ?
            setScanInfo({
                ...scanInfo,
                error: true,
                helperText: 'Badge ID character limit reached'
            })
            :
            setScanInfo({
                ...scanInfo,
                BadgeID: scanInfo.BadgeID + numberString,
                ClockTime: moment().format('YYYY-MM-DDTHH:mm:ssZ') // ISO format with locale value
            })
    }
    const handleBackOneTyping = () => {
        var newBadgeId = scanInfo.BadgeID.slice(0, scanInfo.BadgeID.length - 1);
        setScanInfo({
            ...scanInfo,
            BadgeID: newBadgeId
        })
    }
    function focusOnCardInput() {
        var scan = document.getElementById('BadgeID')
        scan.focus();
    }
    const handleClearForm = () => {
        setScanInfo({
            ...scanInfo,
            BadgeID: '',
            ClockTime: '',
            error: false,
            helperText: '',
            // allEmployees: [],
            disabledInputs: false,
            fullName: '',
            type: '',
            clockMessage: '',
        })
        setOpen(false)
        focusOnCardInput();
    }
    const timeBetweenUpdates = 86400000 //24hours
    // const timeBetweenUpdates = 10000 //10sec
    const callRef = useRef(null)
    var callingMethod = null
    function refreshFactorialDataSet() {
        console.log('Getting factorial data from api')
        handleGetingFactorialEmployees()

        callingMethod = setTimeout(refreshFactorialDataSet, timeBetweenUpdates)
    }
    useEffect(() => {
        refreshFactorialDataSet()
        return () => {
            clearTimeout(callingMethod)
        }
    }, [])

    const handleGetingFactorialEmployees = () => {
        const callable = functions.httpsCallable('getFactorialEmployees');
        var data = { text: 'empty string' }
        return callable(data, auth)
            .then(sucess => {
                // console.log('Get Factorial Employees trigerred....')
                // console.log(JSON.parse(sucess.data))
                // console.log(sucess.data)
                setScanInfo({
                    ...scanInfo,
                    allEmployees: JSON.parse(sucess.data),
                    disabledInputs: false,
                })
                // callRef.current.innerHTML = `Last successfull api call was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`
                console.log(`Last successfull api call was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`)
                focusOnCardInput();
            })
            .catch(err => {
                console.log('Error GET Factorial Employees...', err.message)
                console.log(`Last FAILED api call was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`, err)
                focusOnCardInput();
            })
    }
    useEffect(() => {
        handleGetingFactorialEmployees();
        return () => { }
    }, [])

    const handleClockInOrOutToggle = () => {
        const callable = functions.httpsCallable('employeeClockingToggle');
        var audioSuc = new Audio(sucAudio)
        var audioErr = new Audio(errAudio)
        var gotUser = false
        var data = {}
        const allEmployees = scanInfo.allEmployees
        if (allEmployees === null) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'There is a problem with accessing Factorial DB to get all employees.',
            })
            handleClearForm()
            return false;
        }
        allEmployees.forEach(employee => {
            if (employee.identifier === scanInfo.BadgeID) {
                gotUser = true
                data = { now: moment().format('YYYY-MM-DDTHH:mm:ssZ'), employee_id: employee.id }

                return callable(data, auth)
                    .then(sucess => {
                        var clockInMessage = 'Welcome back on site, hope you will have a great day today!'
                        var clockOutMessage = 'You accomplished some phenomenal work today. Now get back home safe! Thank You.'
                        console.log(moment(scanInfo.ClockTime).weekday())
                        if (moment(scanInfo.ClockTime).weekday() === 5) {//friday
                            clockInMessage = 'Welcome back on site. Happy Friday!'
                            clockOutMessage = 'Phenomenal work today. Have a great Weekend!'
                        }
                        // console.log('Clcok IN or OUT trigerred....')
                        var resp = JSON.parse(sucess.data)
                        audioSuc.play()
                        setOpen(true)
                        setScanInfo({
                            ...scanInfo,
                            fullName: employee.full_name,
                            type: resp.clock_out ? 'CLOCK-OUT' : 'CLOCK-IN',
                            color: resp.clock_out ? '#2196F3' : '#4CAF50',// blue & green from snackbar
                            clockMessage: resp.clock_out ? clockOutMessage : clockInMessage,
                        })

                        setTimeout(() => { handleClearForm() }, 4000)

                    })
                    .catch(err => {
                        console.log('Error while Clcok IN or OUT Employee....', err.message)
                        handleClearForm();
                    })
            }
        })
        if (!gotUser) {
            // console.log('BadgeID not found in Factorial DB', counter, allEmployees.length)
            audioErr.play()
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'BadgeID not found in Factorial DB',
            })
            handleClearForm()
            return false;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setScanInfo({
            ...scanInfo,
            disabledInputs: true
        })
        // var audioSuc = new Audio(sucAudio)
        var audioErr = new Audio(errAudio)
        if (scanInfo.BadgeID.length > 5 && scanInfo.BadgeID.length < 7) {
            handleClockInOrOutToggle();
        }
        else {
            audioErr.play()
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Incorrect Badge ID',
            })
            handleClearForm();
        }
    }

    return (
        <div>
            <Card style={{ minHeight: '83vh' }}>

                <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.center}>{moment().format('DD MMM YYYY')}</Typography>
                <div className={classes.clockMargin}>
                    <div ref={timerRef} id='clock' className='bookingRoomsClock'></div>
                </div>

                <form id="badgeScan" onSubmit={handleSubmit}>

                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                        <Grid container item xs={12} sm={6} spacing={1}>

                            {scanInfo.disabledInputs ? <Loading /> : null}

                            <TextField
                                disabled={scanInfo.disabledInputs}
                                size="medium"
                                variant="outlined"
                                margin="normal"
                                type="number"
                                name='BadgeID'
                                id='BadgeID'
                                label="Badge ID"
                                fullWidth
                                // autoFocus={true}
                                // focused={scanInfo.focused}
                                // helperText="up to 10 characters"
                                error={scanInfo.error}
                                helperText={scanInfo.helperText}
                                inputProps={{ style: { fontSize: '1.5em' } }}
                                value={scanInfo.BadgeID}
                                onChange={handleChange} />

                        </Grid>

                        {/* <div className={classes.calcInput}>
                            <div>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcClearBtn}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClearForm}>
                                    Clear
                                </Button>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBackOneBtn}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleBackOneTyping}>
                                    ←
                                </Button>
                            </div>
                            <div>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handleTyping('7')}>
                                    7
                                </Button>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handleTyping('8')}>
                                    8
                                </Button>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    onClick={() => handleTyping('9')}>
                                    9
                                </Button>
                            </div>
                            <div>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    value='4'
                                    onClick={() => handleTyping('4')}>
                                    4
                                </Button>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    value='5'
                                    onClick={() => handleTyping('5')}>
                                    5
                                </Button>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    value='6'
                                    onClick={() => handleTyping('6')}>
                                    6
                                </Button>
                            </div>
                            <div>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    value='1'
                                    onClick={() => handleTyping('1')}>
                                    1
                                </Button>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    value='2'
                                    onClick={() => handleTyping('2')}>
                                    2
                                </Button>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    value='3'
                                    onClick={() => handleTyping('3')}>
                                    3
                                </Button>
                            </div>
                            <div>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcBtns}
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    value='0'
                                    onClick={() => handleTyping('0')}>
                                    0
                                </Button>
                                <Button
                                    disabled={scanInfo.disabledInputs}
                                    className={classes.calcEnterBtn}
                                    type="submit"
                                    size="small"
                                    variant="outlined"
                                    color="primary">
                                    Enter
                                </Button>
                            </div>
                        </div> */}
                    </Grid>
                </form>
                <br /><br />
                <Dialog open={open}>
                    <Card className={classes.bigDialog} style={{ backgroundColor: scanInfo.color, textAlign: 'center', color: 'white', padding: 100 }}>
                        <Typography variant='h3'>{scanInfo.type}</Typography><br /><br />
                        <Typography variant='h4'>{moment(scanInfo.ClockTime).format('HH:mm:ss')}</Typography><br />
                        <Typography variant='h4'>{scanInfo.fullName}</Typography><br />
                        <Typography display='block' variant='h5'>{scanInfo.clockMessage}</Typography>
                    </Card>
                </Dialog>
            </Card >
            <div ref={callRef}></div>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    );
}