import { Avatar, Collapse, Grid, Snackbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MailIcon from '@material-ui/icons/Mail';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BuildIcon from '@material-ui/icons/Build';
import AdjustIcon from '@material-ui/icons/Adjust';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import React, { useContext, useState } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import FaceIcon from '@material-ui/icons/Face';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { AuthContext } from '../../../contexts/AuthContext';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#182751',
        color: 'lightblue'
        // color: '#5E89B4'
        // backgroundColor: '#cfd8dc'
    },
    dividerColor: {
        // backgroundColor: '#5E89B4'
        backgroundColor: 'lightblue'
    },
    iconColor: {
        // color: '#5E89B4'
        color: 'lightblue'
    },
    userLogo: {
        minHeight: 100
    },
    largeAvatar: {
        // backgroundColor: '#182751',
        margin: 10,
        backgroundColor: '#5E89B4',
    },

}));

export default function SideMenuSignOutOnlyV2() {
    const classes = useStyles();
    const { signOut, initials, displayName } = useContext(AuthContext);

    return (
        <div>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <Grid className={classes.userLogo}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Avatar className={classes.largeAvatar}>{initials}</Avatar>
                    <Typography align='center' variant="subtitle2" style={{ color: 'white' }}>{displayName}</Typography>
                </Grid>
                <Divider className={classes.dividerColor} />

                <List>
                    <ListItem button onClick={signOut}><ListItemIcon><ExitToAppIcon className={classes.iconColor} /></ListItemIcon><ListItemText>Sign out</ListItemText></ListItem>
                </List>
            </Drawer>
        </div>
    );
}