import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import SideMenu from '../../SideMenus/SideMenu';
import Footer from '../../../Footer';
import Loading from '../../../layout/Loading';
import eMail from '../../../../images/eMail.gif'
import TicketFormCS from './TicketFormCS';
import { Grid } from '@material-ui/core';
import { Card } from '@material-ui/core';
import SideMenuV2 from '../../SideMenus/SideMenuV2';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc'
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    ticketCard: {
        padding: '10px',
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        // backgroundColor:
        //     theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    },
    formControl: {
        margin: theme.spacing(1),
        maxWidth: 260,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '15%',
    },
    cardTicket: {
        padding: '30px',
    },
    margin15: {
        margin: '15px',
    },
    marginBottom: {
        marginBottom: '25px'
    },
}));

const TicketingCSConsole = (props) => {

    const classes = useStyles();
    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline >
                        <SideMenuV2 selectedIndex={62} openTicketingColapse={true} />
                        <div className={classes.main}>

                            <Card className={classes.cardTicket}>

                                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                    <img className={classes.media} src={eMail} alt="send ticket" />
                                </Grid>

                                <TicketFormCS />

                            </Card>
                        </div>

                    </CssBaseline>
                    <Footer />
                </div>
            );
        }
    }
}

export default TicketingCSConsole