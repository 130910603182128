import React, { useContext, useState } from 'react';
import Loading from '../Loading';
import Footer from '../../Footer'
import { AuthContext } from '../../../contexts/AuthContext';
import { makeStyles, CssBaseline, LinearProgress, Button, Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import SideMenuV2 from '../SideMenus/SideMenuV2';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import csvToJson from 'csvtojson'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),

    },
    dropzone: {
        // backgroundColor: 'rgb(169,169,169)',
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center',
        // border: '2px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        padding: '20px',
    },
    input: {
        display: 'none',
    },
    buttonMargin: {
        marginLeft: '10px',
    },
    marginBottom: {
        marginBottom: '15px',
    },
    extractionBottom: {
        // display: 'none',
    },
    dropProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    searchProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    searchDataTable: {
        display: 'none',
    },
}));

export default function FileFormatter(props) {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);


    const [csvData, setCsvData] = useState({
        raw: [],
        extracted: []
    })
    const [showDownloadResultButton, setShowDownloadResultButton] = useState(false)

    const [isDragging, setIsDragging] = useState(false)

    // Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    // Dialog
    const [dialog, setDialog] = useState({
        open: false,
        title: '',
    });

    const handleCloseAndCancelDialog = (e) => {
        setCsvData({
            ...csvData,
            raw: [],
            extracted: []
        });
        setDialog({
            open: false,
            title: '',
        });
    };

    const extractUniqueAssetID = () => {
        return new Promise((resolve) => {
            const assetIdData = [];
            csvData.raw.forEach((rawData) => {
                const assetId = rawData.ASST_ID; // Accessing the ASST_ID field from each row

                if (assetId && !assetIdData.some((item) => item.UnitID === assetId)) {
                    assetIdData.push({
                        UnitID: assetId,
                        ProductType: '',
                        Manufacturer: '',
                        Model: '',
                        Processor: '',
                        Ram: '',
                        StorageSize: '',
                        Grade: 'B'
                    });
                }
            });
            resolve(assetIdData);
        });
    }
    const handleDataExtraction = (e) => {
        setDialog({
            open: false,
            title: '',
        });
        console.log('raw csv data:', csvData.raw)
        // TODO: do whatever you need to do with the data...   and snackbar success
        // UnitID   ProductType  Manufacturer    Model   Processor   Ram Storage1Size    Grade        
        extractUniqueAssetID()
            .then(uniqueAssetIDs => {
                console.log('uniqueAssetIDs', uniqueAssetIDs)

                csvData.raw.forEach(rawData => {
                    // console.log('rawData.COMMODITY_DESC', rawData.COMMODITY_DESC)
                    //    uniqueAssetIDs.forEach(uniqueAssetID=>{
                    //     if(rawData.ASST_ID === uniqueAssetID.UnitID){

                    //     }
                    //    })
                    const assetIdMatch = uniqueAssetIDs.find(uniqueAssetID => rawData.ASST_ID === uniqueAssetID.UnitID);

                    if (assetIdMatch) {
                        // Update values in extractedData if ASST_ID matches
                        const indexToUpdate = uniqueAssetIDs.findIndex(item => item.UnitID === assetIdMatch.UnitID);
                        // console.log('indexToUpdate', indexToUpdate)
                        if (indexToUpdate !== -1) {

                            var itemDescStringArray = rawData.ITEM_DESC.split(/\s*,\s*/)
                            console.log(`ASST_ID: ${rawData.ASST_ID} itemDescStringArray:' ${itemDescStringArray}`)
                            switch (rawData.COMMODITY_DESC.toLowerCase()) {
                                case 'aged/invalid':
                                    // uniqueAssetIDs[indexToUpdate].UnitID = assetId
                                    uniqueAssetIDs[indexToUpdate].ProductType = 'Aged/Invalid'
                                    uniqueAssetIDs[indexToUpdate].Manufacturer = 'Aged/Invalid'
                                    uniqueAssetIDs[indexToUpdate].Model = 'Aged/Invalid'
                                    uniqueAssetIDs[indexToUpdate].Processor = 'Aged/Invalid'
                                    uniqueAssetIDs[indexToUpdate].Ram = 'Aged/Invalid'
                                    uniqueAssetIDs[indexToUpdate].StorageSize = 'Aged/Invalid'
                                    uniqueAssetIDs[indexToUpdate].Grade = 'Aged/Invalid'

                                    break;
                                case 'base unit':
                                    // console.log('it was base unit', { ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: '', Grade: 'B' })
                                    // extractedData.push({ ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: '', Grade: 'B' })
                                    var procModel = ''
                                    var procGen = itemDescStringArray[3].toLowerCase().includes('g') ? itemDescStringArray[3].slice(1) + itemDescStringArray[3].charAt(0) : ''

                                    for (let i = 0; i < itemDescStringArray.length; i++) {
                                        const index = itemDescStringArray[i].toLowerCase().indexOf('i');
                                        if (index !== -1 && index !== itemDescStringArray[i].length - 1 && index + 2 <= itemDescStringArray[i].length) {
                                            procModel = 'Intel Core ' + itemDescStringArray[i].substr(index, 2).toLowerCase();
                                            break; // Break the loop after finding the first occurrence
                                        }
                                    }
                                    uniqueAssetIDs[indexToUpdate].Model = rawData.BRAND_DESC
                                    uniqueAssetIDs[indexToUpdate].Processor = procModel + ' ' + procGen
                                    break;
                                case 'controller cards/hba':
                                    // console.log('it was controller cards/hba', { ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: '', Grade: 'B' })
                                    // extractedData.push({ ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: '', Grade: 'B' })
                                    uniqueAssetIDs[indexToUpdate].Model = rawData.BRAND_DESC
                                    break;
                                case 'hard drive':
                                    var storageValue = parseInt(itemDescStringArray[1].replace(/[a-zA-Z]/g, ''))
                                    if (storageValue === 1, storageValue === 2, storageValue === 3, storageValue === 4, storageValue === 5, storageValue === 6) { storageValue = storageValue + ' TB' }
                                    else { storageValue = storageValue + ' GB' }
                                    // console.log('it was hard drive', { ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: storageValue, Grade: 'B' })
                                    // extractedData.push({ ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: storageValue, Grade: 'B' })
                                    uniqueAssetIDs[indexToUpdate].Model = rawData.BRAND_DESC
                                    uniqueAssetIDs[indexToUpdate].StorageSize = storageValue
                                    break;
                                case 'lcd panel':
                                    // console.log('it was lcd panel', { ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: '', Grade: 'B' })
                                    // extractedData.push({ ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: '', Grade: 'B' })
                                    uniqueAssetIDs[indexToUpdate].Model = rawData.BRAND_DESC
                                    break;
                                case 'memory':
                                    var ramValue = itemDescStringArray[1].slice(0, -2) + ' ' + itemDescStringArray[1].slice(-2)
                                    // console.log('it was memory', { ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: ramValue, StorageSize: '', Grade: 'B' })
                                    // extractedData.push({ ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: ramValue, StorageSize: '', Grade: 'B' })
                                    uniqueAssetIDs[indexToUpdate].Model = rawData.BRAND_DESC
                                    uniqueAssetIDs[indexToUpdate].Ram = ramValue
                                    break;
                                case 'motherboard':
                                    // console.log('it was motherboard', { ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: '', Grade: 'B' })
                                    // extractedData.push({ ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: '', Ram: '', StorageSize: '', Grade: 'B' })
                                    uniqueAssetIDs[indexToUpdate].Model = rawData.BRAND_DESC
                                    break;
                                case 'processor':
                                    var procModel = ''
                                    var procGen = itemDescStringArray[3].slice(1) + itemDescStringArray[3].charAt(0)

                                    for (let i = 0; i < itemDescStringArray.length; i++) {
                                        const index = itemDescStringArray[i].toLowerCase().indexOf('i');
                                        if (index !== -1 && index !== itemDescStringArray[i].length - 1 && index + 2 <= itemDescStringArray[i].length) {
                                            procModel = 'Intel Core ' + itemDescStringArray[i].substr(index, 2).toLowerCase();
                                            break; // Break the loop after finding the first occurrence
                                        }
                                    }

                                    // console.log('it was processor', { ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: procModel + ' ' + procGen, Ram: '', StorageSize: '', Grade: 'B' })
                                    // console.log('itemDescStringArray', itemDescStringArray)
                                    // extractedData.push({ ProductType: '', Manufacturer: '', Model: rawData.BRAND_DESC, Processor: procModel + ' ' + procGen, Ram: '', StorageSize: '', Grade: 'B' })
                                    uniqueAssetIDs[indexToUpdate].Model = rawData.BRAND_DESC
                                    uniqueAssetIDs[indexToUpdate].Processor = procModel + ' ' + procGen
                                    break;


                                default:
                                    break;
                            }
                        }
                    }

                })
                console.log('uniqueAssetIDs', uniqueAssetIDs)
                setCsvData({
                    ...csvData,
                    extracted: uniqueAssetIDs
                });
                setShowDownloadResultButton(true)
            })
    };

    function fileTypes(fileName) {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const dragOver = (e) => {
        e.preventDefault();
        // console.log('dragOver')
        setIsDragging(true)
    }
    const dragEnter = (e) => {
        e.preventDefault();
        // console.log('dragEnter')
    }
    const dragLeave = (e) => {
        e.preventDefault();
        // console.log('dragLeave')
        setIsDragging(false)
    }
    const readCSVFile = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        // reader.readAsBinaryString(file);
        reader.readAsText(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        // reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onload = () => resolve((reader.result));
        // reader.onload = () => {
        //     // Parse CSV file
        //     csv.parse(reader.result, (err, data) => {
        //         console.log("Parsed CSV data: ", data);
        //         resolve(data);
        //     });
        // };
        reader.onerror = error => reject(error);
    });

    const fileDrop = (e) => {
        e.preventDefault();
        setIsDragging(false)
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        // for ref please check this article here: https://blog.logrocket.com/create-a-drag-and-drop-component-with-react-dropzone/
        const files = e.dataTransfer.files;
        if (files.length > 1) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Only one file allowed.',
            })
            dropProgress.style.visibility = 'hidden';
        }
        else {
            [...files].forEach(file => {
                var fileType = String(fileTypes(file.name));
                const maxSize = 25000000
                // var header = "Dell_Item_Num,Description,Mfg_Item_Num"
                var header = "RANK,FISCAL_WEEK,ASST_ID,CNTRCT_STRT_DTS,CNTRCT_END_DTS,CNTRCT_STRT_GMT_DT,CNTRCT_END_GMT_DT,SHIPD_DTS,ITM_CLS_CD,REGION,COUNTRY,PRODUCT_DESC,BRAND_DESC,PRODUCT_GROUP,PRODUCT_TYPE,PART_NBR,ITEM_DESC,COMMODITY_DESC,BOM_QTY,WARRANTY_STATUS,ASSET_AGE"
                var numberOfColumns = 21
                // console.log(header)
                if (fileType.toLowerCase() === 'csv') {
                    // console.log(file)
                    if (file.size >= maxSize) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'Selected file is too big to process.',
                        })
                        dropProgress.style.visibility = 'hidden';
                    }
                    else {
                        readCSVFile(file)
                            .then(result => {
                                // console.log(result.split('\n')[0])
                                var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                                // console.log('header', header)
                                // console.log('uploadedHeader', uploadedHeader)
                                if (header === uploadedHeader) {
                                    // console.log('headers are equal')
                                    csvToJson().fromString(result)
                                        .then((jsonObj) => {
                                            // console.log(jsonObj);
                                            var processData = [];
                                            //change true false fields
                                            jsonObj.forEach(dataObject => {
                                                var calculatedDataObject = {};
                                                if (Object.keys(dataObject).length > numberOfColumns) {
                                                    setSnackbar({
                                                        open: true,
                                                        severity: 'error',
                                                        message: 'Found additional columns, please use the correct template.',
                                                    })
                                                    dropProgress.style.visibility = 'hidden';
                                                    return
                                                }
                                                // console.log(dataObject)
                                                processData.push(dataObject)
                                            })
                                            // console.log('processData:______', processData)
                                            setCsvData({
                                                ...csvData,
                                                raw: processData
                                            })
                                            setDialog({
                                                open: true,
                                                title: `Found ${processData.length === 0 ? 0 : processData.length + 1} rows to upload, would you like to proceed?`
                                            })
                                            dropProgress.style.visibility = 'hidden';

                                        })
                                }
                                else {
                                    setSnackbar({
                                        open: true,
                                        severity: 'error',
                                        message: 'Template does not match. Please use correct template.',
                                    })
                                    dropProgress.style.visibility = 'hidden';
                                }
                            })
                    }
                }
                else {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: 'Please make sure that your are uploading a CSV file.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
            })
        }
    }

    const fileSelect = (e) => {
        var { target: { files } } = e;
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        // var onlyCSVs = [];
        // [...files].forEach(file => {
        //     var fileType = String(fileTypes(file.name));
        //     // console.log(fileType.toLowerCase())
        //     if (fileType.toLowerCase() === 'csv') {
        //         onlyCSVs.push(file)
        //     }
        // })
        // console.log(onlyCSVs)
        [...files].forEach(file => {
            var fileType = String(fileTypes(file.name));
            const maxSize = 25000000
            // var header = "Dell_Item_Num,Description,Mfg_Item_Num"
            var header = "RANK,FISCAL_WEEK,ASST_ID,CNTRCT_STRT_DTS,CNTRCT_END_DTS,CNTRCT_STRT_GMT_DT,CNTRCT_END_GMT_DT,SHIPD_DTS,ITM_CLS_CD,REGION,COUNTRY,PRODUCT_DESC,BRAND_DESC,PRODUCT_GROUP,PRODUCT_TYPE,PART_NBR,ITEM_DESC,COMMODITY_DESC,BOM_QTY,WARRANTY_STATUS,ASSET_AGE"
            var numberOfColumns = 21
            // console.log(header)
            if (fileType.toLowerCase() === 'csv') {
                // console.log(file)
                if (file.size >= maxSize) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'Selected file is too big to process.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
                else {
                    readCSVFile(file)
                        .then(result => {
                            // console.log(result.split('\n')[0])
                            var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                            if (header === uploadedHeader) {
                                csvToJson().fromString(result)
                                    .then((jsonObj) => {
                                        // console.log(jsonObj);
                                        var processData = [];
                                        //change true false fields
                                        jsonObj.forEach(dataObject => {
                                            // var calculatedDataObject = {};
                                            if (Object.keys(dataObject).length > numberOfColumns) {
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'error',
                                                    message: 'Found additional columns, please use the correct template.',
                                                })
                                                dropProgress.style.visibility = 'hidden';
                                                return
                                            }
                                            // console.log(dataObject)
                                            processData.push(dataObject)
                                        })
                                        // console.log('processData:______', processData)
                                        setCsvData({
                                            ...csvData,
                                            raw: processData
                                        })
                                        setDialog({
                                            open: true,
                                            title: `Found ${processData.length === 0 ? 0 : processData.length + 1} rows to upload, would you like to proceed?`
                                        })
                                        dropProgress.style.visibility = 'hidden';
                                    })
                            }
                            else {
                                setSnackbar({
                                    open: true,
                                    severity: 'error',
                                    message: 'Template does not match. Please use correct template.',
                                })
                                dropProgress.style.visibility = 'hidden';
                            }
                        })
                }
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'Please make sure that your are uploading a CSV file.',
                })
                dropProgress.style.visibility = 'hidden';
            }
        })
    }

    const handleDownloadTemplate = async (e) => {
        e.preventDefault();
        const templateURL = 'https://storage.googleapis.com/wisetekappappspotcom/docTemplates/SalesOps/Upload%20template.csv';
        const response = await fetch(templateURL);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Upload template.csv";
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }
    // Function to convert extractedData to CSV content
    const convertToCSV = (data) => {
        const header = Object.keys(data[0]).join(',') + '\n';
        const rows = data.map(item =>
            Object.values(item).map(val => `"${val}"`).join(',')
        ).join('\n');

        return header + rows;
    };
    const handleDownloadResult = async (e) => {
        e.preventDefault()
        const csvContent = convertToCSV(csvData.extracted);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'extracted_data_result.csv';
        // link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        link.remove();
        return { success: true };
    }

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('memberSalesOps') || roles.includes('adminSalesOps') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={60} openSalesOpsColapse={true} />
                            <div className={classes.main}>

                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <p>Functionality: You can drop customers file, and in return, get a formatted file that works seamlessly with the pricing database.</p>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <p><b>Note: </b>Please upload data in CSV format. You can download template by clicking "CSV Upload Template" button</p>
                                    <button className={classes.buttonMargin} onClick={handleDownloadTemplate}>CSV Upload Template</button>
                                </Grid>

                                <div className={classes.dropzone} style={{ border: `2px dashed ${isDragging ? 'rgb(20, 190, 40)' : 'rgba(0, 0, 0, 0.25)'}` }}
                                    onDragOver={dragOver}
                                    onDragEnter={dragEnter}
                                    onDragLeave={dragLeave}
                                    onDrop={fileDrop}>
                                    <LinearProgress id='dropProgress' className={classes.dropProgress} />
                                    <GetAppIcon />
                                    <p>Drag & Drop CSV file here</p>
                                    <h4>Or</h4>
                                    <input type="file" accept=".csv" id="addFiles" className={classes.input} onChange={fileSelect} />
                                    <label htmlFor="addFiles">
                                        <Button
                                            className={classes.marginBottom}
                                            variant="contained"
                                            color="default"
                                            component="span"
                                            startIcon={<AttachFileIcon />}>
                                            Select CSV file
                                        </Button>
                                    </label>
                                </div>

                                {showDownloadResultButton &&
                                    <Grid container direction="column" justifyContent="center" alignItems="center">
                                        <p>To download the results, please click the button below:</p>
                                        <Button
                                            className={classes.extractionBottom}
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                            onClick={handleDownloadResult}
                                        >
                                            Data Extraction Result
                                        </Button>
                                    </Grid>
                                }

                                <Dialog
                                    open={dialog.open}
                                    onClose={handleCloseAndCancelDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
                                    <DialogActions>
                                        <Button variant="contained" onClick={handleCloseAndCancelDialog} color="default" className={classes.buttonMargin}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" onClick={handleDataExtraction} color="primary" className={classes.buttonMargin}>
                                            Yes, proceed to upload
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

                            </div>
                        </CssBaseline >
                        <Footer />

                    </div >
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}

// export default FileFormatter