import React, { useState, useContext } from 'react'
import { makeStyles, TextField, MenuItem, Button, Grid, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { DataContext } from '../../../../contexts/DataContext';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Container } from '@material-ui/core';
import { AuthContext } from '../../../../contexts/AuthContext';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import { auth, functions } from '../../../../config/fbConfig';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    buttonRight: {
        float: 'right',
        marginTop: '35px',
        marginRight: '15%',
        // marginBottom: '24px',
    },
    margin15: {
        margin: '15px',
    },
    filePicker: {
        float: 'left',
        marginLeft: '16%',
        marginTop: '35px',
    },
    input: {
        display: 'none',
    },
    marginRight: {
        marginRight: '15px'
    },
    groupSelect: {
        width: '100%',
        // height: 200,
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: 5,
        margin: 5,
    },
    checkboxLabel: {
        margin: 5,
        width: 180
    }
}));

const TicketFormCS = (props) => {
    const classes = useStyles();

    const { getTRMAdetails, createTicketCustomerService } = useContext(DataContext);
    const { email, displayName } = useContext(AuthContext);

    const defaultTicketForm = {
        userEmail: '',
        userFullName: '',
        // username: '',
        TicketType: '',
        LockType: '',
        Site: '',
        TRMA: 'TRMA00',
        Customer: '',
        Description: '',
        moreThanOneLockType: [],
        trmaTicketCountNumber: 0,
        trmaTicketCountError: '',
        trmaTicketCount: '',
        files: [],
        filesSize: 0,
        filesToStoreInDB: [],
    }
    const [ticketForm, setTicketForm] = useState(defaultTicketForm);

    const defaultCheckedState = {
        AppleActivatIcloud: false,
        AppleFindMyMac: false,
        AppleFirmwareLock: false,
        AppleMdmLock: false,
        ChromeEnrollment: false,
        WinAutopilotIntune: false,
        WindowsAbsoluteMdm: false,
        WindowsBiosLock: false,
    }
    const [checkedState, setCheckedState] = useState(defaultCheckedState);

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleChangeChecked = (e) => {
        setCheckedState({
            ...checkedState,
            [e.target.name]: e.target.checked
        })
    }
    function clearCheckedState() {
        setCheckedState(defaultCheckedState)
    }
    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const ticketTypeCS = [
        { value: '', label: '' },
        // { value: 'Issue / Error', label: 'Issue / Error' },
        { value: 'Unlock Request', label: 'Unlock Request' },
    ];

    const customers = [
        { value: '', label: '' },
        { value: 'Amazon Data Services Ireland Ltd', label: 'Amazon Data Services Ireland Ltd' },
        { value: 'Amazon Data Services Ireland Ltd (E-waste)', label: 'Amazon Data Services Ireland Ltd (E-waste)' },
        { value: 'Campari', label: 'Campari' },
        { value: 'Close Brother Technology Services Ltd', label: 'Close Brother Technology Services Ltd' },
        { value: 'Cloud Kitchens', label: 'Cloud Kitchens' },
        { value: 'Coinbase Inc.', label: 'Coinbase Inc.' },
        { value: 'Danone', label: 'Danone' },
        { value: 'Dell ARR', label: 'Dell ARR' },
        { value: 'DMD Salesforce', label: 'DMD Salesforce' },
        { value: 'Econocom', label: 'Econocom' },
        { value: 'Google', label: 'Google' },
        { value: 'Indeed', label: 'Indeed' },
        { value: 'Jazz Pharma (Camara)', label: 'Jazz Pharma (Camara)' },
        { value: 'Laya Healthcare', label: 'Laya Healthcare' },
        { value: 'MTU', label: 'MTU' },
        { value: 'Northrup Grumman', label: 'Northrup Grumman' },
        { value: 'Onfido Ltd', label: 'Onfido Ltd' },
        { value: 'Paramount', label: 'Paramount' },
        { value: 'PFH (ITAD)', label: 'PFH (ITAD)' },
        { value: 'PFH Boston Scientific', label: 'PFH Boston Scientific' },
        { value: 'PFH Technology Group (HSE)', label: 'PFH Technology Group (HSE)' },
        { value: 'Qualcomm Ireland', label: 'Qualcomm Ireland' },
        { value: 'ReuseIT', label: 'ReuseIT' },
        { value: 'Revolut', label: 'Revolut' },
        { value: 'Snap Group Ltd', label: 'Snap Group Ltd' },
        { value: 'Springer Nature', label: 'Springer Nature' },
        { value: 'Teamwork Crew Ltd', label: 'Teamwork Crew Ltd' },
        { value: 'Trussle', label: 'Trussle' },
        { value: 'Uber', label: 'Uber' },
        { value: 'UCC', label: 'UCC' },
        { value: 'US Bank', label: 'US Bank' },
        { value: 'Visa', label: 'Visa' },
        { value: 'WeWork International', label: 'WeWork International' },
        { value: 'Zevas Communications', label: 'Zevas Communications' },
    ];
    const lockTypes = [
        { value: 'More then one selected:', label: 'More then one from the list...' },
        { value: 'APPLE ACTIVAT/ICLOUD', label: 'APPLE ACTIVAT/ICLOUD' },
        { value: 'APPLE FIND MY MAC', label: 'APPLE FIND MY MAC' },
        { value: 'APPLE FIRMWARE LOCK', label: 'APPLE FIRMWARE LOCK' },
        { value: 'APPLE MDM LOCK', label: 'APPLE MDM LOCK' },
        { value: 'CHROME ENROLLMENT', label: 'CHROME ENROLLMENT' },
        { value: 'WIN AUTOPILOT/INTUNE', label: 'WIN AUTOPILOT/INTUNE' },
        { value: 'WINDOWS ABSOLUTE MDM', label: 'WINDOWS ABSOLUTE MDM' },
        { value: 'WINDOWS BIOS LOCK', label: 'WINDOWS BIOS LOCK' },
    ];
    const site = [
        { value: 'Kilbarry', label: 'Kilbarry' },
        { value: 'K4 / K5', label: 'K4 / K5' },
        { value: 'Carrigtohill', label: 'Carrigtohill' },
        { value: 'UK', label: 'UK' },
        { value: 'Thailand', label: 'Thailand' },
        // { value: 'MD - Maryland', label: 'MD - Maryland' },
        { value: 'TX - Texas', label: 'TX - Texas' },
        { value: 'VA - Virginia', label: 'VA - Virginia' },
        { value: 'SAC - Sacramento', label: 'SAC - Sacramento' },
        { value: 'MA - Massachusetts', label: 'MA - Massachusetts' },
        { value: 'Dubai', label: 'Dubai' },
        { value: 'All sites', label: 'All Sites' },
    ];



    const handleChangeTicketTypeCS = (e) => {

        setTicketForm({
            ...ticketForm,
            [e.target.name]: e.target.value,
            userEmail: email, //adding email to data when it will be send to function createTicket
            userFullName: displayName, //adding FullName to data when it will be send to function createTicket
            // username: username, //adding username to data when it will be send to function createTicket
            // reset other fields
            LockType: '',
            Site: '',
            TRMA: 'TRMA00',
            Customer: '',
            Description: '',
            moreThanOneLockType: [],
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        })
        clearCheckedState()
    }
    const handleChange = (e) => {
        let newTRMA = '';
        let newTrmaTicketCount = ''
        let ticketsAlreadyInDB = 0
        let notAllowedText

        if (e.target.name === 'TRMA') {
            // Ensure the value matches regex pattern TRMA00xxxxx
            newTRMA = e.target.value.match(/^TRMA00\d{0,5}$/) ? e.target.value : 'TRMA00';
            // Ensure the value has at most 11 characters
            newTRMA = newTRMA.slice(0, 11);

            if (newTRMA.length === 11) {
                // console.log('search TRMA:', newTRMA)
                getTRMAdetails(newTRMA)
                    .then(res => {
                        ticketsAlreadyInDB = res.result.length
                        notAllowedText = `There are ${ticketsAlreadyInDB} tickets for ${newTRMA} already created!`
                        // console.log('ticketsAlreadyInDB', ticketsAlreadyInDB)
                        if (ticketsAlreadyInDB > 0) {
                            // console.log('successfully returned data', res.result)
                            newTrmaTicketCount = `Found ${newTRMA} in the database, ${ticketsAlreadyInDB} record/s`
                            setTicketForm({
                                ...ticketForm,
                                TRMA: newTRMA,
                                trmaTicketCountNumber: ticketsAlreadyInDB,
                                trmaTicketCountError: ticketsAlreadyInDB >= 3 ? true : false,
                                trmaTicketCount: ticketsAlreadyInDB >= 3 ? notAllowedText : newTrmaTicketCount
                            })
                        } else {
                            // console.log('successfully returned empty')
                            setTicketForm({
                                ...ticketForm,
                                TRMA: newTRMA,
                                trmaTicketCountNumber: ticketsAlreadyInDB,
                                trmaTicketCountError: ticketsAlreadyInDB >= 3 ? true : false,
                                trmaTicketCount: newTrmaTicketCount
                            })
                        }
                    })
                    .catch(err => {
                        console.log('Error:', err.message)
                    })
            }
        }

        // console.log('setting this earlier than returned db')
        setTicketForm({
            ...ticketForm,
            [e.target.name]: e.target.name === 'TRMA' ? newTRMA : e.target.value,
            trmaTicketCountNumber: e.target.name === 'TRMA' ? ticketsAlreadyInDB : ticketForm.trmaTicketCountNumber,
            trmaTicketCountError: e.target.name === 'TRMA' ? ticketsAlreadyInDB >= 3 ? true : false : ticketForm.trmaTicketCountNumber >= 3 ? true : false,
            trmaTicketCount: e.target.name === 'TRMA' ? newTrmaTicketCount : ticketForm.trmaTicketCount

        });
    }
    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        // var attachments = e.target.files;
        // var filesToSend = [e.target.files[0]];
        // const filesToSend = [...files];
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...ticketForm.files];
        var filesToStore = [...ticketForm.filesToStoreInDB];
        [...files].forEach(file => {
            console.log(file.type)
            if (file.type.toLowerCase().includes("audio/") ||
                file.type.toLowerCase().includes("video/") ||
                file.type.toLowerCase().includes("image/") ||
                file.type.toLowerCase().includes("application/pdf") ||
                file.type.toLowerCase().includes("application/vnd") || //for all office documents
                file.type.toLowerCase().includes("text/")) { //text/csv text/hml
                // storage
                //     .ref('ticketAttachments/' + '01Hk2AQqOOoHjy2LBXql/' + file.name)
                //     .put(file)
                // console.log(file)
                filesToStore.push(file)
                var convertResult = ''
                calculatedSize += file.size

                toBase64(file)
                    .then(result => {
                        convertResult = result
                        filesToSend.push({
                            content: convertResult,
                            filename: file.name,
                            type: file.type,
                            disposition: "attachment"
                        })
                        // console.log(calculatedSize)
                        if (ticketForm.filesSize >= maxSize || calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                        }
                        else {
                            if (ticketForm.filesSize + calculatedSize >= maxSize) {
                                setSnackbar({
                                    open: true,
                                    severity: 'warning',
                                    message: 'Selected files are too big to attach.',
                                })
                            }
                            else {
                                setTicketForm({
                                    ...ticketForm,
                                    files: filesToSend,
                                    filesSize: ticketForm.filesSize + calculatedSize,
                                    filesToStoreInDB: filesToStore,
                                });
                                // console.log(filesToSend)
                            }
                        }
                    })
            } else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'File type not supported, please attach supported file: "audio/*, video/*, image/*, .pdf, .xlsx, .xls, .csv"',
                });
            }
        })
        e.target.value = null // to allow selecting the same file over and over
    }
    const handleChangeFilePickerRemove = (e) => {
        setTicketForm({
            ...ticketForm,
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
    }

    const clearStateAndFields = () => {

        setTicketForm(defaultTicketForm);
        clearCheckedState()
        // get form and reset all inputs
        console.log("resetting form");
    }

    function sendNotAllowedNotifications() {
        console.log(`send notification that ticket was created ${ticketForm.trmaTicketCountNumber} times already...`)

        const callable = functions.httpsCallable('sendCSTicketNotAllowedNotifications');
        return callable(ticketForm, auth)
            .then(succ => {
                console.log('notification email was sent....', succ)
                setIsSubmitting(false)
                clearStateAndFields()
                // setSnackbar({
                //     open: true,
                //     severity: 'success',
                //     message: 'Respond sent successfully. User will be notified. Thank you.',
                // })
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isSubmitting) {
            setIsSubmitting(true)

            // When same TRMA is used for a 4th attempt notification is sent explaining devices can be sold for parts or scrapped.
            if (ticketForm.trmaTicketCountNumber >= 3) {

                sendNotAllowedNotifications()
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: `Not Allowed to create ticket. ${ticketForm.trmaTicketCountNumber} tickets for ${ticketForm.TRMA} already created! Passwords/un-enrolment has been requested ${ticketForm.trmaTicketCountNumber} times for this deal. Please proceed to sell for parts or scrap the device if below the resale cutline. Notification email was sent.`,
                });
            } else {
                var newLockTypes = []
                if (checkedState.AppleActivatIcloud) newLockTypes.push('APPLE ACTIVAT/ICLOUD')
                if (checkedState.AppleFindMyMac) newLockTypes.push('APPLE FIND MY MAC')
                if (checkedState.AppleFirmwareLock) newLockTypes.push('APPLE FIRMWARE LOCK')
                if (checkedState.AppleMdmLock) newLockTypes.push('APPLE MDM LOCK')
                if (checkedState.ChromeEnrollment) newLockTypes.push('CHROME ENROLLMENT')
                if (checkedState.WinAutopilotIntune) newLockTypes.push('WIN AUTOPILOT/INTUNE')
                if (checkedState.WindowsAbsoluteMdm) newLockTypes.push('WINDOWS ABSOLUTE MDM')
                if (checkedState.WindowsBiosLock) newLockTypes.push('WINDOWS BIOS LOCK')

                ticketForm.moreThanOneLockType = newLockTypes

                console.log('ticketForm:', ticketForm)

                if (ticketForm.TicketType === 'Unlock Request') {
                    if (ticketForm.Customer !== '' &&
                        ticketForm.LockType !== '' &&
                        ticketForm.Site !== '' &&
                        ticketForm.TRMA !== 'TRMA00' &&
                        ticketForm.Description !== '') {
                        // console.log('Trying to submit form...', ticketForm);
                        let isValid = false
                        if (ticketForm.LockType === 'More then one selected:') {
                            // Use some() to check if any item in checkedState is true
                            isValid = Object.values(checkedState).some(value => value === true)
                        }
                        if (ticketForm.LockType !== 'More then one selected:' && ticketForm.LockType.length > 0) {
                            isValid = true
                        }
                        if (isValid) {
                            createTicketCustomerService(ticketForm)
                                .then(success => {
                                    // console.log('added new unlock request ticket')
                                    //display success
                                    setSnackbar({
                                        open: true,
                                        severity: 'success',
                                        message: success,
                                    });
                                    clearStateAndFields();
                                    setIsSubmitting(false)
                                })
                                .catch(error => {
                                    console.log(error)
                                    //display error
                                    setSnackbar({
                                        open: true,
                                        severity: 'error',
                                        message: error,
                                    });
                                    setIsSubmitting(false)
                                })
                        } else {
                            //display error
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: '* Please select Lock Type from dropdown or mark multiple checkbox fields',
                            })
                            setIsSubmitting(false)
                        }
                    }
                    else {
                        //display error
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: '* Please select all required fields, provide TRMA number and add description.',
                        })
                        setIsSubmitting(false)
                    }
                }
            }
        }
    }

    return (
        <div>
            <form id="ticketFormCS" onSubmit={handleSubmit}>
                <h3 className={classes.center}>Create New Customer Service Ticket</h3>

                <Container maxwidth="xs">
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} >
                        <Grid container item xs={12} sm={4} spacing={1} >

                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                fullWidth
                                required
                                id="TicketType"
                                label="CS Ticket Type"
                                name="TicketType"
                                className={classes.margin15}
                                inputProps={{ maxLength: 50 }}
                                value={ticketForm.TicketType}
                                // InputLabelProps={{ shrink: true }}
                                onChange={handleChangeTicketTypeCS}>
                                {ticketTypeCS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Container>

                {ticketForm.TicketType === 'Unlock Request' ?
                    <Container maxwidth="xs">

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Customer"
                                    label="Customer"
                                    name="Customer"
                                    value={ticketForm.Customer}
                                    onChange={handleChange}   >
                                    {customers.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="LockType"
                                    label="Lock Type"
                                    name="LockType"
                                    value={ticketForm.LockType}
                                    onChange={handleChange}   >
                                    {lockTypes.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            {ticketForm.LockType === 'More then one selected:' ?
                                <Grid container item xs={12} sm={10} spacing={1}>
                                    <div className={classes.groupSelect}>
                                        <FormGroup>
                                            <Grid container direction='row'>

                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.AppleActivatIcloud} name='AppleActivatIcloud' onChange={handleChangeChecked} color='primary' />} label="APPLE ACTIVAT/ICLOUD" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.AppleFindMyMac} name='AppleFindMyMac' onChange={handleChangeChecked} color='primary' />} label="APPLE FIND MY MAC" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.AppleFirmwareLock} name='AppleFirmwareLock' onChange={handleChangeChecked} color='primary' />} label="APPLE FIRMWARE LOCK" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.AppleMdmLock} name='AppleMdmLock' onChange={handleChangeChecked} color='primary' />} label="APPLE MDM LOCK" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.ChromeEnrollment} name='ChromeEnrollment' onChange={handleChangeChecked} color='primary' />} label="CHROME ENROLLMENT" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.WinAutopilotIntune} name='WinAutopilotIntune' onChange={handleChangeChecked} color='primary' />} label="WIN AUTOPILOT/INTUNE" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.WindowsAbsoluteMdm} name='WindowsAbsoluteMdm' onChange={handleChangeChecked} color='primary' />} label="WINDOWS ABSOLUTE MDM" />
                                                <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={checkedState.WindowsBiosLock} name='WindowsBiosLock' onChange={handleChangeChecked} color='primary' />} label="WINDOWS BIOS LOCK" />

                                            </Grid>
                                        </FormGroup>
                                    </div>
                                </Grid>
                                : null}

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField style={{ marginBottom: ticketForm.trmaTicketCount.length > 0 ? 35 : 0 }}
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Site"
                                    label="Site"
                                    name="Site"
                                    value={ticketForm.Site}
                                    onChange={handleChange}   >
                                    {site.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField style={{ marginBottom: ticketForm.trmaTicketCount.length > 0 ? 10 : 0 }}
                                    // type='number'
                                    size="small"
                                    variant="outlined"
                                    autoComplete='off'
                                    required
                                    fullWidth
                                    id="TRMA"
                                    label="TRMA"
                                    name="TRMA"
                                    error={ticketForm.trmaTicketCountError}
                                    helperText={ticketForm.trmaTicketCount}
                                    inputProps={{ maxLength: 11 }}
                                    value={ticketForm.TRMA}
                                    onChange={handleChange}
                                />

                            </Grid>

                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    name="Description"
                                    multiline
                                    fullWidth
                                    required
                                    minRows="4"
                                    label="Description"
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={ticketForm.Description}
                                    inputProps={{ maxLength: 500 }}
                                />
                            </Grid>
                        </Grid>

                        {/* TODO; uncomment buttons */}
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            className={classes.buttonRight}
                            variant="contained"
                            color="primary">
                            Create ticket & send email
                        </Button>

                        <div className={classes.filePicker}>
                            <input
                                // accept="image/*"
                                accept="audio/*,video/*,image/*,.xlsx,.xls"
                                className={classes.input}
                                id="add-contained-button-file"
                                multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="add-contained-button-file">
                                <Button
                                    className={classes.marginRight}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Files
                                </Button>
                            </label>
                            <label htmlFor="remove-contained-button-file">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component="span"
                                    startIcon={<DeleteOutlineIcon />}
                                    onClick={handleChangeFilePickerRemove}>
                                    Remove Files
                                </Button>
                            </label>
                            {ticketForm.files.length === 0 ? null : <p>Files attached: {ticketForm.files.length}</p>}
                        </div>

                    </Container> : null}

            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    )
}

export default TicketFormCS
