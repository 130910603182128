import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Loading from '../../Loading';
import Footer from '../../../Footer';
import { AuthContext } from '../../../../contexts/AuthContext';
import { DataContext } from '../../../../contexts/DataContext';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import { makeStyles, CssBaseline, LinearProgress, Button, Grid, TextField, MenuItem, Chip, Card, DialogContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Toolbar, Tooltip, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import csvToJson from 'csvtojson'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';



const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    txtCenter: {
        textAlign: 'center'
    },
    dropzone: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center',
        // border: '2px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        padding: '20px',
    },
    input: {
        display: 'none',
    },
    buttonMargin: {
        marginLeft: '10px',
    },
    buttonMarginDialog: {
        margin: 25,
    },
    marginBottom: {
        marginBottom: '15px',
    },
    dropProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    filterProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    filterDataTable: {
        display: 'none',
    },
    chipMargin: {
        margin: 10
    },
    chipGrid: {
        marginTop: 20,
        marginBottom: 20,
    },
    resultCard: {
        margin: 10,
        padding: 30
    },
    selectedGrid: {
        margin: '0px auto 40px auto',
        // marginBottom: 20,
        maxWidth: '70%'
    },
    tableCell: {
        minWidth: 100,
    },
    tableCellMultiline: {
        minWidth: 250,
        maxWidth: 400,
    },
}));

const availableFilters = [
    { value: 'Country', label: 'Country' },
    { value: 'Vendor', label: 'Vendor' },
    { value: 'Partner', label: 'Partner' },
    // { value: 'TRMA', label: 'TRMA' },
    { value: 'Category', label: 'Category' },
    // { value: 'Make', label: 'Make' },
    // { value: 'Model', label: 'Model' },
    { value: 'Date', label: 'Date' },
];
// const countries = [ thats in database under countryCodes
//     { value: '', label: '' },
//     { value: 'Country1', label: 'Country1' },
//     { value: 'Country2', label: 'Country2' },
//     { value: 'Country3', label: 'Country3' },
//     { value: 'Country4', label: 'Country4' },
//     { value: 'Country5', label: 'Country5' },
// ];
// const vendors = [
//     // { value: '', label: '' },
//     { value: 'Google', label: 'Google' },
//     { value: 'Indeed', label: 'Indeed' },
//     { value: 'Snapchat', label: 'Snapchat' },
//     { value: 'Uber', label: 'Uber' },
// ];
// const partners = [
//     // { value: '', label: '' },
//     { value: 'CTC', label: 'CTC' },
//     { value: 'IQTEC', label: 'IQTEC' },
//     { value: 'Reversee', label: 'Reversee' },
//     { value: 'Anchor', label: 'Anchor' },
//     { value: 'JHIT', label: 'JHIT' },
//     { value: 'Shuru', label: 'Shuru' },
//     { value: 'SPW', label: 'SPW' },
//     { value: 'Mainstream Global', label: 'Mainstream Global' },
//     { value: 'PROAMBI', label: 'PROAMBI' },
//     { value: 'Greenbox', label: 'Greenbox' },
//     { value: 'UGUR Metals', label: 'UGUR Metals' },
//     { value: 'Quantum', label: 'Quantum' },
//     { value: 'E-Parasaraa', label: 'E-Parasaraa' },
//     { value: 'Xperian', label: 'Xperian' },
//     { value: 'Envirokare', label: 'Envirokare' },
//     { value: 'YYST-Global', label: 'YYST-Global' },
// ];
// const categories = [
//     // { value: '', label: '' },
//     { value: 'CPU', label: 'CPU' },
//     { value: 'CPU-SNR', label: 'CPU-SNR' },
//     { value: 'DESKTOP', label: 'DESKTOP' },
//     { value: 'DRIVE', label: 'DRIVE' },
//     { value: 'LAPTOP', label: 'LAPTOP' },
//     { value: 'LCD', label: 'LCD' },
//     { value: 'MEMORY', label: 'MEMORY' },
//     { value: 'MISC', label: 'MISC' },
//     { value: 'NETWORK', label: 'NETWORK' },
//     { value: 'PHONE', label: 'PHONE' },
//     { value: 'PRINTER', label: 'PRINTER' },
//     { value: 'SCANNER', label: 'SCANNER' },
//     { value: 'SERVER', label: 'SERVER' },
//     { value: 'TABLET', label: 'TABLET' },
// ];
const makes = [
    { value: '', label: '' },
    { value: 'Make1', label: 'Make1' },
    { value: 'Make2', label: 'Make2' },
    { value: 'Make3', label: 'Make3' },
    { value: 'Make4', label: 'Make4' },
    { value: 'Make5', label: 'Make5' },
];
const models = [
    { value: '', label: '' },
    { value: 'Model1', label: 'Model1' },
    { value: 'Model2', label: 'Model2' },
    { value: 'Model3', label: 'Model3' },
    { value: 'Model4', label: 'Model4' },
    { value: 'Model5', label: 'Model5' },
];
const grades = [
    { value: '', label: '' },
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
]

export default function PartnerCalculator(props) {
    const classes = useStyles();

    const { isAuthenticated, roles, loading } = useContext(AuthContext);
    const { addPartnerCalculatorRecords, getPartnerCalculatorData, getPartnerCalculatorDateFilterData, countryCodes, partnerCalculatorCategories, partnerCalculatorPartners, partnerCalculatorVendors } = useContext(DataContext);

    const dialogGradeMaxStringLength = 1
    const dialogMaxStringLength = 30
    const dialogCommentMaxStringLength = 250

    const defaultCsvData = {
        columns: [
            'Country Code',
            'Vendor',
            'Partner',
            'TRMA',
            'Category',
            'Make',
            'Model',
            'Serial',
            'Memory',
            'Cpu',
            'Storage',
            'Price',
            'Cost',
            'Grade',
            'Comment',
        ],
        resultColumns: [
            'Country Code',
            'Vendor',
            'Partner',
            'TRMA',
            'Category',
            'Make',
            'Model',
            'Serial',
            'Memory',
            'Cpu',
            'Storage',
            'Price',
            'Cost',
            'Grade',
            'Comment',
            'Insert Date',
            'ID',
        ],
        resultFields: [
            'Country',
            'Vendor',
            'Partner',
            'TRMA',
            'Category',
            'Make',
            'Model',
            'Serial',
            'Memory',
            'Cpu',
            'Storage',
            'Price',
            'Cost',
            'Grade',
            'Comment',
            'insertDate',
            'id',
        ],
        data: []
    }
    const [csvData, setCsvData] = useState(defaultCsvData)

    const [isDragging, setIsDragging] = useState(false)

    const [showAsp, setShowAsp] = useState(false)

    const [calculateASPbtn, setCalculateASPbtn] = useState(false)

    const [showASPbtn, setShowASPbtn] = useState(false)

    const defaultFilters = {
        Country: false,
        Vendor: false,
        Partner: false,
        // TRMA:false,
        Category: false,
        // Make:false,
        // Model:false,
        // Serial:false,
        // Memory:false,
        // Cpu:false,
        // Storage:false,
        // Price:false,
        // Cost:false,
        // Grade:false,
        // Comment:false,
        Date: false,
    }
    const [showAvailableFilters, setShowAvailableFilters] = useState(defaultFilters)

    const defaultCalcs = {
        Country: '',
        Vendor: '',
        Partner: '',
        TRMA: '',
        Category: '',
        Make: '',
        Model: '',
        Serial: '',
        Memory: '',
        Cpu: '',
        Storage: '',
        Price: '',
        Cost: '',
        Grade: '',
        Comment: '',
        Date: '',
        FromDate: new Date(new Date().setHours(0, 0, 0, 0)),
        ToDate: new Date(new Date().setHours(23, 59, 59, 999)),
        filterColumn: '',
        filterValue: '',
        filterCountry: [],
        filterVendor: [],
        filterPartner: [],
        // filterTRMA: [],
        filterCategory: [],
        // filterMake: [],
        // filterModel: [],
        // filterSerial: [],
        filterDate: [],
        filterCombinedResults: [],
        asp: 0, // asp - average sales price
        qtyMultiplication: 1,
        qtyMultiplicationResult: 0,
    }
    const [calcs, setCalcs] = useState(defaultCalcs)

    const [showMultiplicationTotal, setShowMultiplicationTotal] = useState(false)

    // Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    // Dialog
    const [dialog, setDialog] = useState({
        open: false,
        title: '',
    });

    const handleCloseAndCancelDialog = (e) => {
        setCsvData(defaultCsvData);
        setDialog({
            open: false,
            title: '',
        });
    };

    function fileTypes(fileName) {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const dragOver = (e) => {
        e.preventDefault();
        // console.log('dragOver')
        setIsDragging(true)
    }
    const dragEnter = (e) => {
        e.preventDefault();
        // console.log('dragEnter')
    }
    const dragLeave = (e) => {
        e.preventDefault();
        // console.log('dragLeave')
        setIsDragging(false)
    }

    const readCSVFile = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        // reader.readAsBinaryString(file);
        reader.readAsText(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        // reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onload = () => resolve((reader.result));
        // reader.onload = () => {
        //     // Parse CSV file
        //     csv.parse(reader.result, (err, data) => {
        //         console.log("Parsed CSV data: ", data);
        //         resolve(data);
        //     });
        // };
        reader.onerror = error => reject(error);
    });

    const fileDrop = (e) => {
        e.preventDefault();
        setIsDragging(false)
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        // for ref please check this article here: https://blog.logrocket.com/create-a-drag-and-drop-component-with-react-dropzone/
        const files = e.dataTransfer.files;
        if (files.length > 1) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Only one file allowed.',
            })
            dropProgress.style.visibility = 'hidden';
        }
        else {
            [...files].forEach(file => {
                var fileType = String(fileTypes(file.name));
                const maxSize = 25000000

                var header = "Country,Vendor,Partner,TRMA,Category,Make,Model,Serial,Memory,Cpu,Storage,Price,Cost,Grade,Comment"
                var numberOfColumns = 15
                // console.log(header)
                if (fileType.toLowerCase() === 'csv') {
                    // console.log(file)
                    if (file.size >= maxSize) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'Selected file is too big to process.',
                        })
                        dropProgress.style.visibility = 'hidden';
                    }
                    else {
                        readCSVFile(file)
                            .then(result => {
                                // console.log(result.split('\n')[0])
                                var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                                // console.log('header', header)
                                // console.log('uploadedHeader', uploadedHeader)
                                if (header.toLowerCase() === uploadedHeader.toLowerCase()) {
                                    // console.log('headers are equal')
                                    csvToJson().fromString(result)
                                        .then((jsonObj) => {
                                            // console.log(jsonObj);
                                            var processData = [];
                                            //change true false fields
                                            jsonObj.forEach(dataObject => {

                                                if (Object.keys(dataObject).length > numberOfColumns) {
                                                    setSnackbar({
                                                        open: true,
                                                        severity: 'error',
                                                        message: 'Found additional columns, please use the correct template.',
                                                    })
                                                    dropProgress.style.visibility = 'hidden';
                                                    return
                                                }
                                                // console.log('dataObject: ', dataObject)

                                                dataObject.TRMA = dataObject.TRMA.toUpperCase()
                                                dataObject.Country = dataObject.Country.toUpperCase()
                                                let price = ''
                                                price = isNaN(Number(dataObject.Price)) ? 'value was NaN' : dataObject.Price = Number(dataObject.Price)
                                                let cost = ''
                                                cost = isNaN(Number(dataObject.Cost)) ? 'value was NaN' : dataObject.Cost = Number(dataObject.Cost)
                                                // console.log('price: ', price)
                                                dataObject.Grade = dataObject.Grade.toUpperCase()
                                                processData.push(dataObject)
                                            })
                                            // console.log('processData:______', processData)
                                            setCsvData({ ...csvData, data: processData })
                                            setDialog({
                                                open: true,
                                                title: `Found ${processData.length === 0 ? 0 : processData.length} rows to upload, please check below for any errors and if everything is correct proceed to upload.`
                                            })
                                            dropProgress.style.visibility = 'hidden';

                                        })
                                }
                                else {
                                    setSnackbar({
                                        open: true,
                                        severity: 'error',
                                        message: 'Template does not match. Please use correct template.',
                                    })
                                    dropProgress.style.visibility = 'hidden';
                                }
                            })
                    }
                }
                else {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: 'Please make sure that your are uploading a CSV file.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
            })
        }
    }

    const fileSelect = (e) => {
        var { target: { files } } = e;
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        // var onlyCSVs = [];
        // [...files].forEach(file => {
        //     var fileType = String(fileTypes(file.name));
        //     // console.log(fileType.toLowerCase())
        //     if (fileType.toLowerCase() === 'csv') {
        //         onlyCSVs.push(file)
        //     }
        // })
        // console.log(onlyCSVs)
        [...files].forEach(file => {
            var fileType = String(fileTypes(file.name));
            const maxSize = 25000000

            var header = "Country,Vendor,Partner,TRMA,Category,Make,Model,Serial,Memory,Cpu,Storage,Price,Cost,Grade,Comment"
            var numberOfColumns = 15
            // console.log(header)
            if (fileType.toLowerCase() === 'csv') {
                // console.log(file)
                if (file.size >= maxSize) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'Selected file is too big to process.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
                else {
                    readCSVFile(file)
                        .then(result => {
                            // console.log(result.split('\n')[0])
                            var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                            if (header === uploadedHeader) {
                                csvToJson().fromString(result)
                                    .then((jsonObj) => {
                                        // console.log(jsonObj);
                                        var processData = [];
                                        //change true false fields
                                        jsonObj.forEach(dataObject => {
                                            // var calculatedDataObject = {};
                                            if (Object.keys(dataObject).length > numberOfColumns) {
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'error',
                                                    message: 'Found additional columns, please use the correct template.',
                                                })
                                                dropProgress.style.visibility = 'hidden';
                                                return
                                            }
                                            // console.log('dataObject: ', dataObject)

                                            dataObject.TRMA = dataObject.TRMA.toUpperCase()
                                            dataObject.Country = dataObject.Country.toUpperCase()
                                            let price = ''
                                            price = isNaN(Number(dataObject.Price)) ? 'value was NaN' : dataObject.Price = Number(dataObject.Price)
                                            let cost = ''
                                            cost = isNaN(Number(dataObject.Cost)) ? 'value was NaN' : dataObject.Cost = Number(dataObject.Cost)
                                            // console.log('price: ', price)
                                            dataObject.Grade = dataObject.Grade.toUpperCase()
                                            processData.push(dataObject)
                                        })
                                        // console.log('processData:______', processData)
                                        setCsvData({ ...csvData, data: processData })
                                        setDialog({
                                            open: true,
                                            title: `Found ${processData.length === 0 ? 0 : processData.length} rows to upload, please check below for any errors and if everything is correct proceed to upload.`
                                        })
                                        dropProgress.style.visibility = 'hidden';
                                    })
                            }
                            else {
                                setSnackbar({
                                    open: true,
                                    severity: 'error',
                                    message: 'Template does not match. Please use correct template.',
                                })
                                dropProgress.style.visibility = 'hidden';
                            }
                        })
                }
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'Please make sure that your are uploading a CSV file.',
                })
                dropProgress.style.visibility = 'hidden';
            }
        })
    }

    const handleDownloadTemplate = async (e) => {
        e.preventDefault();
        const templateURL = 'https://storage.googleapis.com/wisetekappappspotcom/docTemplates/PartnerCalculator/Upload%20Template.csv';
        const response = await fetch(templateURL);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Upload template.csv";
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }
    // Function to convert extractedData to CSV content
    const convertToCSV = (data) => {
        const dataArray = Object.values(data);

        if (dataArray.length === 0) {
            return '';
        }

        // Define the order of columns
        const columnOrder = [
            'Country',
            'Vendor',
            'Partner',
            'TRMA',
            'Category',
            'Make',
            'Model',
            'Serial',
            'Memory',
            'Cpu',
            'Storage',
            'Price',
            'Cost',
            'Grade',
            'Comment',
            'insertDate',
            'id',];

        const header = columnOrder.join(',') + '\n';
        const rows = dataArray.map(item =>
            columnOrder.map(key => `"${item[key] || ''}"`).join(',')
        ).join('\n');

        return header + rows;
    };
    const handleDownloadResult = async (e) => {
        e.preventDefault()
        const csvContent = convertToCSV(calcs.filterCombinedResults);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'filtered_data_result.csv';
        // link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        link.remove();
        return { success: true };
    }

    const handleUploadDialog = (e) => {

        // TODO: validate all the columns and rows before submission
        var isValid = true;
        var errorMsg = 'Please make sure that all the errors are corrected...'

        for (let i = 0; i < csvData.data.length; i++) {
            const item = csvData.data[i];

            if (item.Country.length !== 2) {
                console.log('Country Cell is not two chars', item)
                errorMsg = 'Please make sure that all the errors are corrected. One or more cells in Country column are not equal to two characters.'
                isValid = false;
                break;
            }
            if (item.Vendor.length > dialogMaxStringLength) {
                console.log(`Vendor Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Vendor column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            if (item.Vendor === '') {
                console.log('Vendor Cell is empty', item.Vendor)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Vendor column are empty.`
                isValid = false;
                break;
            }
            if (!partnerCalculatorVendors.some(ven => ven.value.toUpperCase() === item.Vendor.toUpperCase())) {
                console.log('Vendor Cell value is not included in available options', item.Vendor)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Vendor column has incorrect option value provided.`
                isValid = false;
                break;
            }
            if (item.Partner.length > dialogMaxStringLength) {
                console.log(`Partner Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Partner column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            if (item.Partner === '') {
                console.log('Partner Cell is empty', item.Partner)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Partner column are empty.`
                isValid = false;
                break;
            }
            if (!partnerCalculatorPartners.some(par => par.value.toUpperCase() === item.Partner.toUpperCase())) {
                console.log('Partner Cell value is not included in available options', item.Partner)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Partner column has incorrect option value provided.`
                isValid = false;
                break;
            }
            if (item.TRMA.length > dialogMaxStringLength) {
                console.log(`TRMA Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in TRMA column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            // if (item.TRMA === '') {
            //     console.log('TRMA Cell is empty', item.TRMA)
            //     errorMsg = `Please make sure that all the errors are corrected. One or more cells in TRMA column are empty.`
            //     isValid = false;
            //     break;
            // }
            if (item.Category.length > dialogMaxStringLength) {
                console.log(`Category Cell is more than ${dialogMaxStringLength} chars`, item.Category)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Category column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            if (item.Category === '') {
                console.log('Category Cell is empty', item.Category)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Category column are empty.`
                isValid = false;
                break;
            }
            if (!partnerCalculatorCategories.some(cat => cat.value === item.Category.toUpperCase())) {
                console.log('Category Cell value is not included in available options', item.Category)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Category column has incorrect option value provided.`
                isValid = false;
                break;
            }
            if (item.Make.length > dialogMaxStringLength) {
                console.log(`Make Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Make column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            // if (item.Make === '') {
            //     console.log('Make Cell is empty', item.Make)
            //     errorMsg = `Please make sure that all the errors are corrected. One or more cells in Make column are empty.`
            //     isValid = false;
            //     break;
            // }
            if (item.Model.length > dialogMaxStringLength) {
                console.log(`Model Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Model column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            // if (item.Model === '') {
            //     console.log('Model Cell is empty', item.Model)
            //     errorMsg = `Please make sure that all the errors are corrected. One or more cells in Model column are empty.`
            //     isValid = false;
            //     break;
            // }
            if (item.Serial.length > dialogMaxStringLength) {
                console.log(`Serial Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Serial column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            // if (item.Serial === '') {
            //     console.log('Serial Cell is empty', item.Serial)
            //     errorMsg = `Please make sure that all the errors are corrected. One or more cells in Serial column are empty.`
            //     isValid = false;
            //     break;
            // }
            if (item.Memory.length > dialogMaxStringLength) {
                console.log(`Memory Cell is more than ${dialogMaxStringLength} chars`, item.Memory)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Memory column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            // if (item.Memory === '') {
            //     console.log('Memory Cell is empty', item.Memory)
            //     errorMsg = `Please make sure that all the errors are corrected. One or more cells in Memory column are empty.`
            //     isValid = false;
            //     break;
            // }
            if (item.Cpu.length > dialogMaxStringLength) {
                console.log(`CPU Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Cpu column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            // if (item.Cpu === '') {
            //     console.log('Cpu Cell is empty', item.Cpu)
            //     errorMsg = `Please make sure that all the errors are corrected. One or more cells in Cpu column are empty.`
            //     isValid = false;
            //     break;
            // }
            if (item.Storage.length > dialogMaxStringLength) {
                console.log(`Storage Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Storage column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            // if (item.Storage === '') {
            //     console.log('Storage Cell is empty', item.Storage)
            //     errorMsg = `Please make sure that all the errors are corrected. One or more cells in Storage column are empty.`
            //     isValid = false;
            //     break;
            // }
            if (item.Price.length > dialogMaxStringLength) {
                console.log(`Price Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Price column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            if (isNaN(item.Price)) {
                console.log('Price Cell is NaN', item.Price)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Price column are not a number(NaN).`
                isValid = false;
                break;
            }
            if (item.Price.toString().includes('e')) {
                console.log('Price Cell is Number in scientific notation format', item.Price)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Price column are Number in scientific notation format.`
                isValid = false;
                break;
            }
            if (item.Cost.length > dialogMaxStringLength) {
                console.log(`Cost Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Cost column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            if (isNaN(item.Cost)) {
                console.log('Cost Cell is NaN', item.Cost)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Cost column are not a number(NaN).`
                isValid = false;
                break;
            }
            if (item.Cost.toString().includes('e')) {
                console.log('Cost Cell is Number in scientific notation format', item.Cost)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Cost column are Number in scientific notation format.`
                isValid = false;
                break;
            }
            if (item.Grade.length > dialogMaxStringLength) {
                console.log(`Grade Cell is more than ${dialogMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Grade column are more than ${dialogMaxStringLength} characters.`
                isValid = false;
                break;
            }
            // if (item.Grade === '') {
            //     console.log('Grade Cell is empty', item.Grade)
            //     errorMsg = `Please make sure that all the errors are corrected. One or more cells in Grade column are empty.`
            //     isValid = false;
            //     break;
            // }
            if (item.Grade.length > dialogGradeMaxStringLength) {
                console.log(`Grade Cell is more than ${dialogGradeMaxStringLength}`, item.Grade)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Grade column are not correct. Required: A, B, C or empty field.`
                isValid = false;
                break;
            }
            if (!grades.some(gra => gra.value === item.Grade.toUpperCase())) {
                console.log('Grade Cell value is not included in available options', item.Grade)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Grade column has incorrect option value provided. Required: A, B, C or empty field.`
                isValid = false;
                break;
            }
            if (item.Comment.length > dialogCommentMaxStringLength) {
                console.log(`Comment Cell is more than ${dialogCommentMaxStringLength} chars`, item)
                errorMsg = `Please make sure that all the errors are corrected. One or more cells in Comment column are more than ${dialogCommentMaxStringLength} characters.`
                isValid = false;
                break;
            }
            // if (item.Comment === '') {
            //     console.log('Comment Cell is empty', item.Comment)
            //     errorMsg = `Please make sure that all the errors are corrected. One or more cells in Comment column are empty.`
            //     isValid = false;
            //     break;
            // }
        }
        if (isValid) {
            console.log('All Rows and Columns are valid')

            // upload data to db and snackbar success
            // TODO: uncomment to add csv data to db
            addPartnerCalculatorRecords(csvData.data)
                .then(success => {
                    // console.log('added ' + csvData.data.length + ' new rows to partner calculator table')
                    setCsvData(defaultCsvData)
                    setDialog({
                        open: false,
                        title: '',
                    });
                    //display success
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: success,
                    });

                })
                .catch(error => {
                    // console.log(error)
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: error,
                    });
                })
        } else {
            setSnackbar({
                open: true,
                severity: 'error',
                message: errorMsg,
            })
        }

    };

    const handleAddFilter = (eTargetName) => {
        // console.log('e.target.name', eTargetName)
        setShowAvailableFilters({
            ...showAvailableFilters,
            [eTargetName]: true
        })
    }

    const handleRemoveFilter = (eTargetName) => {
        // console.log('e.target.name', eTargetName)
        if (eTargetName === 'clearAllFilters') {
            setShowAvailableFilters(defaultFilters)
            setCalcs(defaultCalcs)
            setShowAsp(false)
            setShowASPbtn(false)
        } else {
            setShowAvailableFilters({
                ...showAvailableFilters,
                [eTargetName]: false
            })
            setCalcs({ ...calcs, [eTargetName]: '', ['filter' + eTargetName]: [] })
            setShowAsp(false)
            // setShowASPbtn(false)
        }
    }

    const handleChangeAutocomplete = (e, newValueObject) => {
        var filterProgress = document.getElementById('filterProgress');
        filterProgress.style.visibility = 'visible';
        // console.log('this is e:', e)
        // console.log('this is newValueObject:', newValueObject)
        var newValue = ''
        if (newValueObject && newValueObject.value) {
            // Accessing newValueObject.value safely
            newValue = newValueObject.value;
        } else {
            // Handle the case where newValueObject or newValueObject.value is null
            newValue = ''
        }

        var showButton = checkAllFilterCombinations('Country', newValue);

        console.log('showButton:', showButton)
        setShowASPbtn(showButton)

        setCalcs({
            ...calcs,
            Country: newValue,
            filterColumn: 'Country',
            filterValue: newValue,
        })

        getPartnerCalculatorData({ filterColumn: 'Country', filterValue: newValue, })
            .then(res => {
                console.log('success:', res.message)
                console.log('success:', res.result)
                setCalcs({
                    ...calcs,
                    Country: newValue,
                    filterColumn: 'Country',
                    filterValue: newValue,
                    filterCountry: res.result,
                })
                filterProgress.style.visibility = 'hidden';
                setShowAsp(false)
            })
            .catch(err => {
                // console.log('there was an error:', err.message, ' and an empty array:', err.result)
                filterProgress.style.visibility = 'hidden';
                setShowAsp(false)
            })

    }

    const checkAllFilterCombinations = (eTargetName, eTargetValue) => {
        const filters = Object.keys(showAvailableFilters);
        const numFilters = filters.length;
        let showButton = false

        for (let i = 0; i < 2 ** numFilters; i++) {
            const currentCombination = {};
            for (let j = 0; j < numFilters; j++) {
                currentCombination[filters[j]] = Boolean((i >> j) & 1);
            }
            // console.log(currentCombination);
            // checking conditions, check showAvailableFilters match this combination and if there is a match then check the corresponding string values for example
            // if currentCombination = {Country: false, Vendor: false, Partner: false, Category: true, Date: false} then only check calcs.Category.length > 0 and if its longer than zero then showButton = true 

            // Check if showAvailableFilters match this combination
            const isMatch = Object.keys(showAvailableFilters).every((filter) => showAvailableFilters[filter] === currentCombination[filter])

            // If there is a match, check the corresponding condition for each selected filter
            if (isMatch) {
                console.log('currentCombination', currentCombination)
                const selectedFilters = filters.filter((filter) => currentCombination[filter]);
                // console.log('selectedFilters', selectedFilters)

                let allOk = []
                selectedFilters.forEach((selectedFilter) => {
                    console.log('selectedFilter', selectedFilter)

                    console.log('eTargetName', eTargetName)
                    // console.log('eTargetValue', eTargetValue)
                    if (selectedFilter === eTargetName) {
                        // console.log('selectedFilter === eTargetName', selectedFilter, eTargetName)
                        eTargetValue.length > 0 ? allOk.push(true) : allOk.push(false)
                    } else {
                        switch (selectedFilter) {
                            case 'Country':

                                calcs.Country.length > 0 ? allOk.push(true) : allOk.push(false)

                                break;
                            case 'Vendor':

                                calcs.Vendor.length > 0 ? allOk.push(true) : allOk.push(false)

                                break;
                            case 'Partner':

                                calcs.Partner.length > 0 ? allOk.push(true) : allOk.push(false)

                                break;
                            case 'Category':

                                calcs.Category.length > 0 ? allOk.push(true) : allOk.push(false)

                                break;
                            case 'Date':
                                if (eTargetName === 'FromDate' && eTargetValue.constructor === Date ||
                                    eTargetName === 'ToDate' && eTargetValue.constructor === Date) {
                                    allOk.push(true)
                                } else {
                                    // allOk.push(false)
                                    calcs.Date.length > 0 ? allOk.push(true) : allOk.push(false)
                                }
                                break;

                            default:
                                break;
                        }
                    }
                })

                console.log('allOk', allOk)
                if (allOk.every((isOk) => isOk)) {
                    showButton = true
                    console.log('all matching array :', allOk)
                    console.log('all matching show button:', showButton)
                    break; // No need to continue checking other combinations
                }
            }

        }

        return showButton
    };
    const handleChange = (e) => {
        setCalculateASPbtn(true)
        var filterProgress = document.getElementById('filterProgress');
        filterProgress.style.visibility = 'visible';
        // console.log('this is e:', e.target.name)

        // TODO: Validate filters if filter selected it needs to have value else display error

        var showButton = checkAllFilterCombinations(e.target.name, e.target.value);

        console.log('showButton:', showButton)
        setShowASPbtn(showButton)

        setCalcs({
            ...calcs,
            [e.target.name]: e.target.value,
            filterColumn: e.target.name,
            filterValue: e.target.value,
            qtyMultiplication: 1,
            qtyMultiplicationResult: 1 * calcs.asp
        })

        getPartnerCalculatorData({ filterColumn: e.target.name, filterValue: e.target.value, })
            .then(res => {
                // console.log('success:', res.message)
                // console.log('success:', res.result)
                setCalcs({
                    ...calcs,
                    [e.target.name]: e.target.value,
                    filterColumn: e.target.name,
                    filterValue: e.target.value,
                    ['filter' + e.target.name]: res.result,
                    qtyMultiplication: 1,
                    qtyMultiplicationResult: 1 * calcs.asp
                })
                filterProgress.style.visibility = 'hidden';
                setShowAsp(false)
                setCalculateASPbtn(false)
            })
            .catch(err => {
                // console.log('there was an error:', err.message, ' and an empty array:', err.result)
                filterProgress.style.visibility = 'hidden';
                setShowAsp(false)
                setCalculateASPbtn(false)
            })
    }

    const handleChangeMultiplication = (e) => {
        e.preventDefault()

        if (e.target.value < 1) {
            setCalcs({
                ...calcs,
                qtyMultiplication: 1,
                qtyMultiplicationResult: 1 * calcs.asp
            })
            setShowMultiplicationTotal(false)
        } else {
            setCalcs({
                ...calcs,
                qtyMultiplication: e.target.value,
                qtyMultiplicationResult: Number(e.target.value * calcs.asp).toFixed(2)
            })
            setShowMultiplicationTotal(true)
        }
    }

    const handleChangeDatePicker = (newDate, eTargetName) => {
        setCalculateASPbtn(true)
        var filterProgress = document.getElementById('filterProgress');
        filterProgress.style.visibility = 'visible';
        // // console.log('this is eTargetName: ', eTargetName)
        console.log('this is newDate: ', newDate)

        var showButton = checkAllFilterCombinations(eTargetName, newDate);

        console.log('showButton:', showButton)
        setShowASPbtn(showButton)

        const updatedCalcs = { ...calcs };

        switch (eTargetName) {
            case 'FromDate':
                updatedCalcs.FromDate = new Date(newDate.setHours(0, 0, 0, 0));
                break;
            case 'ToDate':
                updatedCalcs.ToDate = new Date(newDate.setHours(23, 59, 59, 999));
                break;
            default:
                break;
        }

        // Call the function to fetch data with the updated dates
        const fromDate = eTargetName === 'FromDate' ? newDate : calcs.FromDate
        const toDate = eTargetName === 'ToDate' ? newDate : calcs.ToDate

        getPartnerCalculatorDateFilterData(fromDate, toDate)
            .then(res => {
                console.log('success:', res.message)
                console.log('success:', res.result)

                updatedCalcs.filterDate = res.result
                updatedCalcs.qtyMultiplication = 1
                updatedCalcs.qtyMultiplicationResult = 1 * calcs.asp
                updatedCalcs.Date = 'show ASP button now'
                // Set the updated state
                setCalcs(updatedCalcs)


                filterProgress.style.visibility = 'hidden';
                setShowAsp(false)
                setCalculateASPbtn(false)
            })
            .catch(err => {
                console.log('there was an error:', err.message, ' and an empty array:', err.result)
                filterProgress.style.visibility = 'hidden';
                setShowAsp(false)
                setCalculateASPbtn(false)
            })
    }

    const handleChangeCells = (e, i) => {
        // console.log('this is current state csvData:', csvData.data)
        // console.log('e.target.name: ', e.target.name)
        // console.log('e.target.value: ', e.target.value)
        // console.log('row index: ', i)

        const clonedData = csvData.data
        var newValue = e.target.value
        var columnName = e.target.name

        // for Country only take first two letters and make them capital
        if (columnName === 'Country') {
            console.log('was Country and the new value is:', newValue)
            var firstTwoLetters = ''
            if (newValue.length >= 2) {
                console.log('was o 1 or 2 chars', newValue.length)
                firstTwoLetters = newValue.substring(0, 2).toUpperCase()
            } else {
                console.log('was more than two chars', newValue.length)
                firstTwoLetters = newValue.toUpperCase()
            }
            clonedData[i][columnName] = firstTwoLetters
            // console.log('clonedData:', clonedData)
        }
        // for Vendor
        if (columnName === 'Vendor') {
            clonedData[i][columnName] = newValue
        }
        // for Partner
        if (columnName === 'Partner') {
            clonedData[i][columnName] = newValue
        }
        // for TRMA
        if (columnName === 'TRMA') {
            clonedData[i][columnName] = newValue
        }
        // for Category
        if (columnName === 'Category') {
            clonedData[i][columnName] = newValue
        }
        // for Make
        if (columnName === 'Make') {
            clonedData[i][columnName] = newValue
        }
        // for Model
        if (columnName === 'Model') {
            clonedData[i][columnName] = newValue
        }
        // for Serial
        if (columnName === 'Serial') {
            clonedData[i][columnName] = newValue
        }
        // for Memory
        if (columnName === 'Memory') {
            clonedData[i][columnName] = newValue
        }
        // for Cpu
        if (columnName === 'Cpu') {
            clonedData[i][columnName] = newValue
        }
        // for Storage
        if (columnName === 'Storage') {
            clonedData[i][columnName] = newValue
        }
        // for Price
        if (columnName === 'Price') {
            console.log('only two decimal places value allowed, parsed newValue: ', parseFloat(Number(newValue.toString()).toFixed(2)))
            if (newValue < 1) {
                clonedData[i][columnName] = 1
            } else {
                clonedData[i][columnName] = parseFloat(Number(newValue.toString()).toFixed(2))
            }
        }
        // for Cost
        if (columnName === 'Cost') {
            console.log('only two decimal places value allowed, parsed newValue: ', parseFloat(Number(newValue.toString()).toFixed(2)))
            if (newValue < 1) {
                clonedData[i][columnName] = 1
            } else {
                clonedData[i][columnName] = parseFloat(Number(newValue.toString()).toFixed(2))
            }
        }
        // for Grade
        if (columnName === 'Grade') {
            clonedData[i][columnName] = newValue
        }
        // for Comment
        if (columnName === 'Comment') {
            clonedData[i][columnName] = newValue
        }

        setCsvData({ ...csvData, data: clonedData })

    }


    const handleCalculator = (e) => {
        e.preventDefault();

        var finalFilteredData = [];
        let startDate = moment(calcs.FromDate.setHours(0, 0, 0, 0))
        let endDate = moment(calcs.ToDate.setHours(23, 59, 59, 999))
        // console.log('startDate', startDate)
        // console.log('endDate', endDate)

        // if (showAvailableFilters.Country && calcs.filterCountry.length > 0) {
        if (showAvailableFilters.Country) {

            finalFilteredData = finalFilteredData.concat(calcs.filterCountry)

            if (showAvailableFilters.Vendor) {
                finalFilteredData = finalFilteredData.filter(item => item.Vendor === calcs.Vendor)

                if (showAvailableFilters.Partner) {
                    finalFilteredData = finalFilteredData.filter(item => item.Partner === calcs.Partner)

                    if (showAvailableFilters.Category) {
                        finalFilteredData = finalFilteredData.filter(item => item.Category === calcs.Category)

                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    } else {
                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    }

                } else {
                    if (showAvailableFilters.Category) {
                        finalFilteredData = finalFilteredData.filter(item => item.Category === calcs.Category)

                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    } else {
                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    }
                }
            } else {
                if (showAvailableFilters.Partner) {
                    finalFilteredData = finalFilteredData.filter(item => item.Partner === calcs.Partner)

                    if (showAvailableFilters.Category) {
                        finalFilteredData = finalFilteredData.filter(item => item.Category === calcs.Category)

                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    } else {

                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    }
                } else {
                    if (showAvailableFilters.Category) {
                        finalFilteredData = finalFilteredData.filter(item => item.Category === calcs.Category)

                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    } else {
                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    }
                }
            }
        } else {
            // if (showAvailableFilters.Vendor && calcs.filterVendor.length > 0) {
            if (showAvailableFilters.Vendor) {
                finalFilteredData = finalFilteredData.concat(calcs.filterVendor)

                // Check if Partner filter is also true
                if (showAvailableFilters.Partner) {
                    finalFilteredData = finalFilteredData.filter(item => item.Partner === calcs.Partner)

                    // Check if Category filter is also true
                    if (showAvailableFilters.Category) {
                        finalFilteredData = finalFilteredData.filter(item => item.Category === calcs.Category)

                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    } else {
                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    }
                } else {
                    // Check if Category filter is also true
                    if (showAvailableFilters.Category) {
                        finalFilteredData = finalFilteredData.filter(item => item.Category === calcs.Category)

                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    } else {
                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    }
                }
            } else {
                // Check if Partner filter is also true
                // if (showAvailableFilters.Partner && calcs.filterPartner.length > 0) {
                if (showAvailableFilters.Partner) {
                    finalFilteredData = finalFilteredData.concat(calcs.filterPartner)

                    // Check if Category filter is also true
                    if (showAvailableFilters.Category) {
                        finalFilteredData = finalFilteredData.filter(item => item.Category === calcs.Category)

                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    } else {
                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    }
                } else {
                    // Check if Category filter is also true
                    // if (showAvailableFilters.Category && calcs.filterCategory.length > 0) {
                    if (showAvailableFilters.Category) {
                        finalFilteredData = finalFilteredData.concat(calcs.filterCategory)

                        // Check if Date filter is also true
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.filter(item => moment(item.insertDate).isBetween(startDate, endDate))
                        }
                    } else {
                        // Check if Date filter is also true
                        // if (showAvailableFilters.Date && calcs.filterDate.length > 0) {
                        if (showAvailableFilters.Date) {
                            finalFilteredData = finalFilteredData.concat(calcs.filterDate)
                        }
                    }
                }
            }
        }


        // Use finalFilteredData for further calculations
        console.log('FINAL FilteredData', finalFilteredData)

        // Calculate total price and ASP for new dataset
        var totalPrice = 0
        var averageSalesPrice = 0
        finalFilteredData.forEach((item) => {
            totalPrice += item.Price
        })
        averageSalesPrice = Number(totalPrice / finalFilteredData.length).toFixed(2)
        // check if NAN
        var finalASP = isNaN(averageSalesPrice) ? 0 : averageSalesPrice
        setCalcs({
            ...calcs,
            filterCombinedResults: finalFilteredData,
            qtyMultiplication: 1,
            qtyMultiplicationResult: 0,
            asp: finalASP
        })
        // and then show asp
        setShowAsp(true)
    }

    const setRowBackgroundColor = (row, i) => {
        let color = i % 2 === 0 ? '' : 'rgba(0, 0, 0, 0.04)'

        if (row.Country.length !== 2 ||
            row.Vendor.length > dialogMaxStringLength ||
            row.Vendor === '' ||
            !partnerCalculatorVendors.some(item => item.value.toUpperCase() === row.Vendor.toUpperCase()) ||
            row.Partner.length > dialogMaxStringLength ||
            row.Partner === '' ||
            !partnerCalculatorPartners.some(item => item.value.toUpperCase() === row.Partner.toUpperCase()) ||
            row.TRMA.length > dialogMaxStringLength ||
            // row.TRMA === '' ||
            row.Category.length > dialogMaxStringLength ||
            row.Category === '' ||
            !partnerCalculatorCategories.some(item => item.value === row.Category.toUpperCase()) ||
            row.Make.length > dialogMaxStringLength ||
            // row.Make === '' ||
            row.Model.length > dialogMaxStringLength ||
            // row.Model === '' ||
            row.Serial.length > dialogMaxStringLength ||
            // row.Serial === '' ||
            row.Memory.length > dialogMaxStringLength ||
            // row.Memory === '' ||
            row.Cpu.length > dialogMaxStringLength ||
            // row.Cpu === '' ||
            row.Storage.length > dialogMaxStringLength ||
            // row.Storage === '' ||
            row.Price.length > dialogMaxStringLength ||
            typeof row.Price === 'string' ||
            row.Price.toString().includes('e') || // scientific notation detection for Price field
            row.Cost.length > dialogMaxStringLength ||
            typeof row.Cost === 'string' ||
            row.Cost.toString().includes('e') || // scientific notation detection for Cost field
            row.Grade.length > dialogMaxStringLength ||
            row.Grade.length > dialogGradeMaxStringLength ||
            !grades.some(item => item.value === row.Grade.toUpperCase()) ||
            // row.Grade === '' ||
            row.Comment.length > dialogCommentMaxStringLength) {

            color = 'rgba(250, 200, 200, 0.4)'
        }
        return color
    }


    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('partnerManager') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={61} openProductionColapse={true} />
                            <div className={classes.main}>

                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <p>Functionality description: Below you can drop CSV file to add records to database, once records are added then you will also be able to calculate the average sales price(ASP) if you provide some information as a input to the calculations.</p>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <p><b>Note: </b>Please upload data in CSV format. You can download template by clicking "CSV Upload Template" button</p>
                                    <button className={classes.buttonMargin} onClick={handleDownloadTemplate}>CSV Upload Template</button>
                                </Grid>

                                <div className={classes.dropzone} style={{ border: `2px dashed ${isDragging ? 'rgb(20, 190, 40)' : 'rgba(0, 0, 0, 0.25)'}` }}
                                    onDragOver={dragOver}
                                    onDragEnter={dragEnter}
                                    onDragLeave={dragLeave}
                                    onDrop={fileDrop}>
                                    <LinearProgress id='dropProgress' className={classes.dropProgress} />
                                    <GetAppIcon />
                                    <p>Drag & Drop CSV file here</p>
                                    <h4>Or</h4>
                                    <input type="file" accept=".csv" id="addFiles" className={classes.input} onChange={fileSelect} />
                                    <label htmlFor="addFiles">
                                        <Button
                                            className={classes.marginBottom}
                                            variant="contained"
                                            color="default"
                                            component="span"
                                            startIcon={<AttachFileIcon />}>
                                            Select CSV file
                                        </Button>
                                    </label>
                                </div>

                                <Card className={classes.resultCard}>

                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        <p>Functionality description: To calculate the average sales price (ASP) please add some filters to narrow the calculations data and click "Calculate ASP" button.</p>
                                    </Grid>

                                    <LinearProgress id='filterProgress' className={classes.filterProgress} />

                                    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.chipGrid}>
                                        {availableFilters.map(filter => (
                                            <Chip key={filter.value} className={classes.chipMargin}
                                                icon={<AddCircleOutlineIcon />}
                                                label={`${filter.value} filter`}
                                                id={filter.value}
                                                name={filter.value}
                                                clickable
                                                color="primary"
                                                onClick={() => handleAddFilter(filter.value)}
                                                onDelete={showAvailableFilters[filter.value] ? () => handleRemoveFilter(filter.value) : null}
                                                // onDelete={calcs[filter.value].length > 0 ? () => handleRemoveFilter(filter.value) : null}
                                                deleteIcon={showAvailableFilters[filter.value] ? <RemoveCircleOutlineIcon style={{ color: 'red' }} /> : null}
                                                // deleteIcon={calcs[filter.value].length > 0 ? <RemoveCircleOutlineIcon style={{ color: 'red' }} /> : null}
                                                variant="outlined"
                                            />
                                        ))}
                                        <Chip className={classes.chipMargin}
                                            // icon={<AddCircleOutlineIcon />}
                                            label='Clear All'
                                            id='clearAllFilters'
                                            name='clearAllFilters'
                                            clickable
                                            color="secondary"
                                            onClick={() => handleRemoveFilter('clearAllFilters')}
                                            onDelete={() => handleRemoveFilter('clearAllFilters')}
                                            deleteIcon={<RemoveCircleOutlineIcon style={{ color: 'red' }} />}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3} className={classes.selectedGrid}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                            {showAvailableFilters.Country && (
                                                <Grid container item >

                                                    <Autocomplete
                                                        id="Country"
                                                        name="Country"
                                                        fullWidth
                                                        size="small"
                                                        options={countryCodes}
                                                        autoHighlight
                                                        // value={calcs.Country}
                                                        onChange={handleChangeAutocomplete}
                                                        // getOptionLabel={(option) => option.value}
                                                        getOptionLabel={(option) => option.value === '' ? '' : option.value + ' - ' + option.label}
                                                        renderOption={(option) => (
                                                            <React.Fragment>
                                                                {option.value === '' ? '' : option.value + ' - ' + option.label}
                                                            </React.Fragment>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Country"
                                                                variant="outlined"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                </Grid>
                                            )}
                                            {/* {showAvailableFilters.Country && (
                                        <Grid container item >
                                            <TextField
                                                select
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                id="Country"
                                                label="Select Country"
                                                name="Country"
                                                inputProps={{ maxLength: 50 }}
                                                value={calcs.Country}
                                                // InputLabelProps={{ shrink: true }}
                                                onChange={handleChange}>
                                                {countries.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    )} */}

                                            {showAvailableFilters.Vendor && (
                                                <Grid container item>
                                                    <TextField
                                                        select
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        id="Vendor"
                                                        label="Select Vendor"
                                                        name="Vendor"
                                                        inputProps={{ maxLength: 50 }}
                                                        value={calcs.Vendor}
                                                        // InputLabelProps={{ shrink: true }}
                                                        onChange={handleChange}>
                                                        {partnerCalculatorVendors.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            )}

                                            {showAvailableFilters.Partner && (
                                                <Grid container item>
                                                    <TextField
                                                        select
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        id="Partner"
                                                        label="Select Partner"
                                                        name="Partner"
                                                        inputProps={{ maxLength: 50 }}
                                                        value={calcs.Partner}
                                                        // InputLabelProps={{ shrink: true }}
                                                        onChange={handleChange}>
                                                        {partnerCalculatorPartners.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            )}

                                            {showAvailableFilters.Category && (
                                                <Grid container item>
                                                    <TextField
                                                        select
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        id="Category"
                                                        label="Select Category"
                                                        name="Category"
                                                        inputProps={{ maxLength: 50 }}
                                                        value={calcs.Category}
                                                        // InputLabelProps={{ shrink: true }}
                                                        onChange={handleChange}>
                                                        {partnerCalculatorCategories.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            )}

                                            {showAvailableFilters.Date && (
                                                <Grid container item direction="row" justifyContent="center" alignItems="center" spacing={3}>

                                                    <DatePicker
                                                        margin="normal"
                                                        id="FromDate"
                                                        label="From Date:"
                                                        name="FromDate"
                                                        format="dd MMMM yyyy"
                                                        // error // makes text red
                                                        // helperText="Inclusive in your holiday request. Check before submitting **"
                                                        // required
                                                        className={classes.datePickerCenter}
                                                        value={calcs.FromDate}
                                                        onChange={(e) => handleChangeDatePicker(e, 'FromDate')}
                                                    />
                                                    &emsp;&emsp;&emsp;
                                                    <DatePicker
                                                        margin="normal"
                                                        id="ToDate"
                                                        label="To Date:"
                                                        name="ToDate"
                                                        format="dd MMMM yyyy"
                                                        // error // makes text red
                                                        // helperText="Inclusive in your holiday request. Check before submitting **"
                                                        // required
                                                        className={classes.datePickerCenter}
                                                        value={calcs.ToDate}
                                                        onChange={(e) => handleChangeDatePicker(e, 'ToDate')}
                                                    />

                                                </Grid>
                                            )}

                                            {/* {showAvailableFilters.Make && (
                                        <Grid container item>
                                            <TextField
                                                select
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                id="Make"
                                                label="Select Make"
                                                name="Make"
                                                inputProps={{ maxLength: 50 }}
                                                value={calcs.Make}
                                                // InputLabelProps={{ shrink: true }}
                                                onChange={handleChange}>
                                                {makes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    )} */}

                                            {/* {showAvailableFilters.Model && (
                                        <Grid container item>
                                            <TextField
                                                select
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                id="Model"
                                                label="Select Model"
                                                name="Model"
                                                inputProps={{ maxLength: 50 }}
                                                value={calcs.Model}
                                                // InputLabelProps={{ shrink: true }}
                                                onChange={handleChange}>
                                                {models.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    )} */}

                                            {showASPbtn ?
                                                <Grid item>
                                                    <Button
                                                        // type="submit"
                                                        id='calculateASP'
                                                        disabled={calculateASPbtn}
                                                        className={classes.chipMargin}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleCalculator}
                                                    >
                                                        Calculate ASP
                                                    </Button>
                                                </Grid> : null
                                            }



                                            {/* {calcs.Country.length > 0 ||
                                            calcs.Vendor.length > 0 ||
                                            calcs.Partner.length > 0 ||
                                            calcs.Category.length > 0 ||
                                            // calcs.Make.length > 0 ||
                                            // calcs.Model.length > 0 ||
                                            // calcs.Serial.length > 0 ||
                                            calcs.Date.length > 0 ?
                                            <Grid item>
                                                <Button
                                                    // type="submit"
                                                    id='calculateASP'
                                                    disabled={calculateASPbtn}
                                                    className={classes.chipMargin}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleCalculator}
                                                >
                                                    Calculate ASP
                                                </Button>
                                            </Grid> : null
                                        } */}
                                        </MuiPickersUtilsProvider>
                                    </Grid>

                                    {showAsp &&
                                        <Grid container direction="column" justifyContent="center" alignItems="center">
                                            {calcs.asp != 0 ?
                                                <Grid item>
                                                    <Grid container item direction="row" justifyContent="center" alignItems="flex-end">
                                                        <h4>{`Average Sales Price for above criteria is:`}&nbsp;</h4>
                                                        <h2 style={{ color: 'green' }}>{`${calcs.asp}`}&nbsp;</h2>
                                                        <h4>{`per item.`}</h4>
                                                    </Grid>
                                                    <Grid container item direction="row" justifyContent="center" alignItems="center">
                                                        <p>Quantity:&nbsp;&nbsp;</p>
                                                        <TextField style={{ maxWidth: 100, fontSize: '1.5em' }}
                                                            type='number'
                                                            size="small"
                                                            variant="outlined"
                                                            autoComplete='off'
                                                            id="qtyMultiplicationBox"
                                                            name="qtyMultiplicationBox"
                                                            inputProps={{ maxLength: 6 }}
                                                            value={calcs.qtyMultiplication}
                                                            onChange={handleChangeMultiplication} />
                                                    </Grid>
                                                    {calcs.qtyMultiplication != 1 && showMultiplicationTotal && <Grid container item direction="row" justifyContent="center" alignItems="center">
                                                        <h4>Total:&nbsp;&nbsp;</h4>
                                                        <h1 style={{ color: 'green' }}>&nbsp;{`${calcs.qtyMultiplicationResult}`}</h1>
                                                    </Grid>}



                                                </Grid>
                                                :
                                                <div>
                                                    <h3>{`There was nothing to calculate for above criteria`}</h3>
                                                </div>
                                            }

                                            {calcs.filterCombinedResults.length > 0 &&
                                                <TableContainer component={Paper} style={{ width: '100%' }}>
                                                    <Toolbar style={{ marginTop: 50 }}>
                                                        {/* <h4>create a button to download here...</h4> */}
                                                        <Tooltip title="Download filter result" placement="right">
                                                            <IconButton aria-label="Download filter result" onClick={handleDownloadResult}>
                                                                <GetAppIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Toolbar>
                                                    <Table className={classes.table} aria-label="filter results">
                                                        <TableHead>
                                                            <TableRow>
                                                                {csvData.resultColumns.map((col, i) => (
                                                                    <TableCell key={i} className={classes.txtCenter}><b>{col}</b></TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {calcs.filterCombinedResults.map((row, i) => (
                                                                <TableRow key={i} style={{ backgroundColor: i % 2 === 0 ? '' : 'rgba(0, 0, 0, 0.04)' }}>

                                                                    {csvData.resultFields.map((field, i) => (
                                                                        <TableCell key={i} className={classes.tableCell}>
                                                                            {/* <TextField
                                                                            className={classes.tableCell}
                                                                            fullWidth
                                                                            size="small"
                                                                            variant="outlined"
                                                                            autoComplete='off'
                                                                            id={row.id}
                                                                            name={row.id}
                                                                            value={row[field]}
                                                                        /> */}
                                                                            {field === 'insertDate' ? moment(row[field]).format('DD MMM YYYY') : row[field]}
                                                                        </TableCell>
                                                                    ))}

                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            }
                                        </Grid>
                                    }

                                </Card>

                                {/* TODO: display table with the results data set */}

                                <Dialog
                                    open={dialog.open}
                                    onClose={handleCloseAndCancelDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    fullWidth
                                    maxWidth="xl"
                                >
                                    {/* <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle> */}
                                    <DialogContent>
                                        <TableContainer component={Paper}>
                                            <Toolbar>
                                                <h4 style={{ marginBottom: 50 }}>{dialog.title}</h4>
                                            </Toolbar>
                                            <Table className={classes.table} aria-label="csv results">
                                                <TableHead>
                                                    <TableRow>
                                                        {csvData.columns.map((col, i) => (
                                                            <TableCell key={i} className={classes.txtCenter}><b>{col}</b></TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {csvData.data.map((row, i) => (
                                                        // <TableRow key={i} style={{ backgroundColor: i % 2 === 0 ? '' : 'rgba(0, 0, 0, 0.04)' }}>

                                                        <TableRow key={i} style={{ backgroundColor: setRowBackgroundColor(row, i, dialogMaxStringLength, dialogCommentMaxStringLength) }}>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    select
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Country"
                                                                    name="Country"
                                                                    error={row.Country.length !== 2 ? true : false}
                                                                    helperText={row.Country.length !== 2 ? row.Country === '' ? 'Select Country code' : `"${row.Country}" invalid option` : ''}
                                                                    inputProps={{ maxLength: 2 }}
                                                                    value={row.Country}
                                                                    onChange={(e) => handleChangeCells(e, i)} >
                                                                    {countryCodes.map((option) => (
                                                                        <MenuItem key={option.value} value={option.value}>
                                                                            {option.value}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    select
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Vendor"
                                                                    name="Vendor"
                                                                    error={row.Vendor.length > dialogMaxStringLength ? true : false ||
                                                                        !partnerCalculatorVendors.some(item => item.value.toUpperCase() === row.Vendor.toUpperCase()) ||
                                                                        row.Vendor === ''}
                                                                    helperText={row.Vendor.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : '' ||
                                                                        !partnerCalculatorVendors.some(item => item.value.toUpperCase() === row.Vendor.toUpperCase()) ? `"${row.Vendor}" invalid option` : '' ||
                                                                            row.Vendor === '' ? 'Select Vendor' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Vendor}
                                                                    onChange={(e) => handleChangeCells(e, i)} >
                                                                    {partnerCalculatorVendors.map((option) => (
                                                                        <MenuItem key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    select
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Partner"
                                                                    name="Partner"
                                                                    error={row.Partner.length > dialogMaxStringLength ? true : false ||
                                                                        !partnerCalculatorPartners.some(item => item.value.toUpperCase() === row.Partner.toUpperCase()) ||
                                                                        row.Partner === ''}
                                                                    helperText={row.Partner.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : '' ||
                                                                        !partnerCalculatorPartners.some(item => item.value.toUpperCase() === row.Partner.toUpperCase()) ? `"${row.Partner}" invalid option` : '' ||
                                                                            row.Partner === '' ? 'Select Partner' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Partner}
                                                                    onChange={(e) => handleChangeCells(e, i)} >
                                                                    {partnerCalculatorPartners.map((option) => (
                                                                        <MenuItem key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="TRMA"
                                                                    name="TRMA"
                                                                    error={row.TRMA.length > dialogMaxStringLength ? true : false} // row.TRMA === ''}
                                                                    helperText={row.TRMA.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : ''}// row.TRMA === '' ? 'Field Empty' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.TRMA}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    select
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Category"
                                                                    name="Category"
                                                                    error={row.Category.length > dialogMaxStringLength ? true : false ||
                                                                        !partnerCalculatorCategories.some(item => item.value === row.Category.toUpperCase())} // row.Category === ''
                                                                    helperText={row.Category.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : '' ||
                                                                        !partnerCalculatorCategories.some(item => item.value === row.Category.toUpperCase()) ? `"${row.Category}" invalid option` : ''} // row.Category === '' ? 'Select Category' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Category}
                                                                    onChange={(e) => handleChangeCells(e, i)} >
                                                                    {partnerCalculatorCategories.map((option) => (
                                                                        <MenuItem key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Make"
                                                                    name="Make"
                                                                    error={row.Make.length > dialogMaxStringLength ? true : false} // row.Make === ''}
                                                                    helperText={row.Make.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : ''} // row.Make === '' ? 'Field Empty' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Make}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Model"
                                                                    name="Model"
                                                                    error={row.Model.length > dialogMaxStringLength ? true : false} // row.Model === ''}
                                                                    helperText={row.Model.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : ''} // row.Model === '' ? 'Field Empty' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Model}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Serial"
                                                                    name="Serial"
                                                                    error={row.Serial.length > dialogMaxStringLength ? true : false} // row.Serial === ''}
                                                                    helperText={row.Serial.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : ''} // row.Serial === '' ? 'Field Empty' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Serial}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Memory"
                                                                    name="Memory"
                                                                    error={row.Memory.length > dialogMaxStringLength ? true : false} // row.Memory === ''}
                                                                    helperText={row.Memory.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : ''} // row.Memory === '' ? 'Field Empty' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Memory}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Cpu"
                                                                    name="Cpu"
                                                                    error={row.Cpu.length > dialogMaxStringLength ? true : false} // row.Cpu === ''}
                                                                    helperText={row.Cpu.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : ''} // row.Cpu === '' ? 'Field Empty' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Cpu}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Storage"
                                                                    name="Storage"
                                                                    error={row.Storage.length > dialogMaxStringLength ? true : false} // row.Storage === ''}
                                                                    helperText={row.Storage.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : ''} // row.Storage === '' ? 'Field Empty' : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Storage}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    fullWidth
                                                                    type='number'
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Price"
                                                                    name="Price"
                                                                    error={row.Price.length > dialogMaxStringLength ? true : false ||
                                                                        typeof row.Price === 'string' ||
                                                                        row.Price.toString().includes('e')}
                                                                    helperText={row.Price.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : '' ||
                                                                        typeof row.Price === 'string' ? `${row.Price} is NaN` : '' ||
                                                                            row.Price.toString().includes('e') ? `${row.Price}, scientific notation detected` : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Price}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    fullWidth
                                                                    type='number'
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Cost"
                                                                    name="Cost"
                                                                    error={row.Cost.length > dialogMaxStringLength ? true : false ||
                                                                        typeof row.Cost === 'string' ||
                                                                        row.Cost.toString().includes('e')}
                                                                    helperText={row.Cost.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : '' ||
                                                                        typeof row.Cost === 'string' ? `${row.Cost} is NaN` : '' ||
                                                                            row.Cost.toString().includes('e') ? `${row.Cost}, scientific notation detected` : ''}
                                                                    inputProps={{ maxLength: dialogMaxStringLength }}
                                                                    value={row.Cost}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCell}
                                                                    select
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Grade"
                                                                    name="Grade"
                                                                    error={row.Grade.length > dialogMaxStringLength ? true : false ||
                                                                        // row.Grade.length > 1}
                                                                        !grades.some(item => item.value === row.Grade.toUpperCase()) ||
                                                                        // row.Grade === '' ||
                                                                        row.Grade.length > dialogGradeMaxStringLength}
                                                                    helperText={row.Grade.length > dialogMaxStringLength ? `Max ${dialogMaxStringLength} characters` : '' ||
                                                                        // row.Grade.length > 1 ? 'Only one letter allowed' : ''}
                                                                        !grades.some(item => item.value === row.Grade.toUpperCase()) ? `"${row.Grade}" invalid option` : '' ||
                                                                            // row.Grade === '' ? 'Field Empty' : '' ||
                                                                            row.Grade.length > 1 ? `"${row.Grade}" invalid option` : ''}
                                                                    inputProps={{ maxLength: 1 }}
                                                                    value={row.Grade}
                                                                    onChange={(e) => handleChangeCells(e, i)} >
                                                                    {grades.map((option) => (
                                                                        <MenuItem key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </TableCell>

                                                            <TableCell>
                                                                <TextField
                                                                    className={classes.tableCellMultiline}
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={1}
                                                                    maxRows={4}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    autoComplete='off'
                                                                    id="Comment"
                                                                    name="Comment"
                                                                    error={row.Comment.length > dialogCommentMaxStringLength ? true : false}
                                                                    helperText={row.Comment.length > dialogCommentMaxStringLength ? `Max ${dialogCommentMaxStringLength} characters` : ''}
                                                                    inputProps={{ maxLength: dialogCommentMaxStringLength }}
                                                                    value={row.Comment}
                                                                    onChange={(e) => handleChangeCells(e, i)} />
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant="contained" onClick={handleCloseAndCancelDialog} color="default" className={classes.buttonMarginDialog}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" onClick={handleUploadDialog} color="primary" className={classes.buttonMarginDialog}>
                                            Yes, proceed to upload
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

                            </div>
                        </CssBaseline >
                        <Footer />

                    </div >
                )
            } else {
                return <Redirect to='/' />
            }
        }
    }
}