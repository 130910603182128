import React from 'react'
import { makeStyles } from '@material-ui/core';
import happyEaster from '../../../../images/HappyEaster.jpg'
import easter from '../../../../images/Easter2023.png'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaHeader: {
        width: '100%',
        // borderRadius: 20,
    },
    media: {
        width: '70%',
        borderRadius: 20,
    },


}));

export default function Easter2023() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                {/* <h2 className={classes.textCentered}>Happy Easter ! ! !</h2> */}

            </header>
            <div className={classes.textCentered}>
                <img className={classes.mediaHeader} src={happyEaster} alt="Happy Easter" />
            </div>
            <div className={classes.textCentered}>
                <img className={classes.media} src={easter} alt="Happy Easter" />
            </div>

            <article >
                <h5 >Dear Colleagues,</h5>
                <section>
                    Wishing you all the best this Easter.
                    <br />
                    May your day be filled with laughter, good cheer, and great adventure during the egg hunt.
                    <br />
                    Happy Easter to all our amazing employees
                </section>
                <br />

            </article>

            <h4 className={classes.textCentered}>
                Enjoy some delicious Chocolate Eggs
            </h4>

            <br />
            <p>Regards,<br /><br />

                Evelyn Wiacek<br />
                {/* Global Head of HR<br />
        Wisetek™<br /> */}
            </p>
            <br />
        </div>
    )
}
