import React, { useState, useContext } from 'react'
import { auth, functions } from '../../../config/fbConfig';
import { DataContext } from '../../../contexts/DataContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Card, makeStyles, TextField, Grid, Button, FormGroup, FormControlLabel, Checkbox, MenuItem, Container } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    formCard: {
        padding: '30px',
    },
    buttonRight: {
        float: 'right',
        marginTop: '35px',
    },
    datePickerCenter: {
        marginLeft: '30px',
    },
    lowerTextfield: {
        marginTop: '25px',
    },
    lowerCheckboxes: {
        marginTop: '25px',
        marginLeft: '30px',
    },
}));

export const HolidayForm = (props) => {

    const classes = useStyles();
    // const { shiftWorker, displayName, department, holidayApprovingManager, username } = useContext(AuthContext);
    const { shiftWorker, displayName, department, username, holidayApprovingManager } = useContext(AuthContext);

    const { listDepartments, listManagers, createHolidayRequest, calculateDaysRequested } = useContext(DataContext);

    var manager = getKeyByValue(listManagers, 'value', holidayApprovingManager)
    const [holidayRequestForm, setHolidayRequestForm] = useState({
        DateOfRequest: new Date(),
        FullName: displayName,
        Department: department,
        Manager: holidayApprovingManager,
        // HalfDay: false,
        // ShiftDay: false,
        // SingleDay: true,
        FromDate: new Date(),
        ToDate: new Date(),
        TotalDaysRequested: 1,
        HoursRequested: 0,
        AdditionalComment: '',
        Status: 'Pending',
        ResponseComment: '',
        ResponseDate: '',
        UserEmail: username,
        // const manager = getKeyByValue(listManagers, 'value', holidayApprovingManager);
        ManagerEmail: manager == undefined ? '' : manager.email,
        FromDateString: moment(new Date()).format('DD MMMM YYYY'),
        ToDateString: moment(new Date()).format('DD MMMM YYYY'),
        WasRequestApproved: false,
        AvailableDays: '',
        PartDay: false,
        UnpaidLeave: false,
        ShiftWorker: false,
    });

    const holidayHours = [
        { value: 0, label: '' },
        { value: 2, label: '2 hours' },
        { value: 4, label: '4 hours' },
        { value: 6, label: '6 hours' },
        { value: 8, label: '8 hours' },
        { value: 10, label: '10 hours' },
    ];

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleDateChangeFromDate = (fromDate) => {

        var daysRequested = calculateDaysRequested(new Date(fromDate), new Date(fromDate))

        setHolidayRequestForm({
            ...holidayRequestForm,
            FromDate: new Date(fromDate),
            FromDateString: moment(fromDate).format('DD MMMM YYYY'),
            ToDate: new Date(fromDate),
            ToDateString: moment(fromDate).format('DD MMMM YYYY'),
            TotalDaysRequested: daysRequested,
            HoursRequested: 0,
            // SingleDay: daysRequested == 1 ? true : false,
            // ShiftDay: false,
            // HalfDay: false,
            PartDay: false,
            UnpaidLeave: false,
            ShiftWorker: shiftWorker,
        })
    };
    const handleDateChangeToDate = (toDate) => {
        var daysRequested = calculateDaysRequested(new Date(holidayRequestForm.FromDateString), new Date(toDate))
        console.log(daysRequested)
        console.log(new Date(holidayRequestForm.FromDateString))
        console.log(new Date(toDate))

        if (new Date(holidayRequestForm.FromDateString) > new Date(toDate)) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Wait what ?! "From Date" filed has to be equal or higher than "To Date" field.',
            })
            return
        }
        setHolidayRequestForm({
            ...holidayRequestForm,
            ToDate: new Date(toDate),
            ToDateString: moment(toDate).format('DD MMMM YYYY'),
            TotalDaysRequested: daysRequested,
            HoursRequested: 0,
            // SingleDay: daysRequested == 1 ? true : false,
            // ShiftDay: false,
            // HalfDay: false,
            PartDay: false,
            UnpaidLeave: false,
            ShiftWorker: shiftWorker,
        })
    };
    const handleChangeCheckboxPartDay = (e) => {
        // var daysRequested = calculateDaysRequested(new Date(holidayRequestForm.FromDateString), new Date(holidayRequestForm.ToDateString))
        // if (holidayRequestForm.TotalDaysRequested > 1) {

        if (e.target.checked) {

            setSnackbar({
                open: true,
                severity: 'info',
                message: 'For how many hours you wish to apply?',
            })
        }

        setHolidayRequestForm({
            ...holidayRequestForm,
            [e.target.name]: e.target.checked,
            // TotalDaysRequested: e.target.checked ? 0 : daysRequested,
            TotalDaysRequested: e.target.checked ? holidayRequestForm.TotalDaysRequested - 1 : holidayRequestForm.TotalDaysRequested + 1,
            HoursRequested: 0,
            // SingleDay: false,
            // ShiftDay: false,
            UnpaidLeave: false,
        })

    };

    const handleChangeCheckboxUnpaidLeave = (e) => {
        setHolidayRequestForm({
            ...holidayRequestForm,
            [e.target.name]: e.target.checked,

        })
    };
    const handleChange = (e) => {
        setHolidayRequestForm({
            ...holidayRequestForm,
            [e.target.name]: e.target.value,
            ShiftWorker: shiftWorker,
        })
    };
    const handleChangeHours = (e) => {
        setHolidayRequestForm({
            ...holidayRequestForm,
            [e.target.name]: e.target.value,
            ShiftWorker: shiftWorker,
        })
    };
    const handleChangeOnlyInt = (e) => {
        var theEvent = e || window.event;

        // Handle paste
        // if (theEvent.type === 'paste') {
        //     key = e.clipboardData.getData('text/plain');
        // } else {
        //     // Handle key press
        //     var key = theEvent.keyCode || theEvent.which;
        //     key = String.fromCharCode(key);
        // }
        var regex = new RegExp('^[0-9]+$');

        if (regex.test(e.target.value)) {
            theEvent.returnValue = false;

            if (theEvent.preventDefault) {
                setHolidayRequestForm({
                    ...holidayRequestForm,
                    // TotalDaysRequested
                    [e.target.name]: parseInt(e.target.value),
                    PartDay: false
                })
            }
        }
        else {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Only Integer value is accepted.',
            })
        }
    };

    function getKeyByValue(array, attr, value) {
        // console.log(array, attr, value)
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return array[i];
            }
        }
    };

    const handleChangeManager = (e) => {
        var manager = getKeyByValue(listManagers, 'value', e.target.value);

        setHolidayRequestForm({
            ...holidayRequestForm,
            [e.target.name]: e.target.value,
            ManagerEmail: manager.email,
        })
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (holidayRequestForm.PartDay && holidayRequestForm.HoursRequested === 0) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please select amount of hours or change the "Part of the Day" checkbox.',
            })
            return
        }

        var nowW = new Date();
        var currentYear = nowW.getFullYear();
        var followingYear = currentYear + 1;
        var nextFollowingYear = followingYear + 1;
        var december1st = currentYear + '/12/01';
        var january1stNextYear = followingYear + '/01/01';
        var december1stNextYear = followingYear + '/12/01';
        var january1stInTwoYears = nextFollowingYear + '/01/01';
        // console.log(nextFollowingYear)

        //add validation if date is less than december 1st - you can book to 31 dec current year
        if (new Date() < new Date(december1st)) {
            if (new Date(holidayRequestForm.ToDate) < new Date(january1stNextYear)) {
                // console.log('this year:', holidayRequestForm)
                createHolidayRequest(holidayRequestForm)
                    .then(success => {
                        //display success
                        setSnackbar({
                            open: true,
                            severity: 'success',
                            message: success,
                        })
                        setHolidayRequestForm({
                            DateOfRequest: new Date(),
                            FullName: displayName,
                            Department: department,
                            Manager: holidayApprovingManager,
                            FromDate: new Date(),
                            ToDate: new Date(),
                            AvailableDays: 0,
                            TotalDaysRequested: 1,
                            HoursRequested: 0,
                            AdditionalComment: '',
                            Status: 'Pending',
                            ResponseComment: '',
                            ResponseDate: '',
                            UserEmail: username,
                            ManagerEmail: manager == undefined ? '' : manager.email,
                            FromDateString: moment(new Date()).format('DD MMMM YYYY'),
                            ToDateString: moment(new Date()).format('DD MMMM YYYY'),
                            WasRequestApproved: false,
                            PartDay: false,
                            UnpaidLeave: false,
                        })
                        // TODO: uncoment for production
                        sendEmailToApplicant();
                        sendEmailToManager();
                    })
                    .catch(error => {
                        //display error
                        setSnackbar({
                            open: true,
                            severity: 'error',
                            message: error,
                        })
                    })
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: 'You can only book holidays until 31st of December ' + currentYear + '. To book next year holidays please wait until 1st of December ' + currentYear,
                })
            }
        }
        //ad validation if date is more than december 1st - you can book to 31 dec following year
        else {
            if (new Date(holidayRequestForm.ToDate) < new Date(january1stInTwoYears) && new Date(holidayRequestForm.ToDate) < new Date(december1stNextYear)) {
                // console.log('dec or next year up to dec 1st:', holidayRequestForm)
                createHolidayRequest(holidayRequestForm)
                    .then(success => {
                        //display success
                        setSnackbar({
                            open: true,
                            severity: 'success',
                            message: success,
                        })
                        setHolidayRequestForm({
                            DateOfRequest: new Date(),
                            FullName: displayName,
                            Department: department,
                            Manager: holidayApprovingManager,
                            FromDate: new Date(),
                            ToDate: new Date(),
                            AvailableDays: 0,
                            TotalDaysRequested: 1,
                            HoursRequested: 0,
                            AdditionalComment: '',
                            Status: 'Pending',
                            ResponseComment: '',
                            ResponseDate: '',
                            UserEmail: username,
                            ManagerEmail: manager == undefined ? '' : manager.email,
                            FromDateString: moment(new Date()).format('DD MMMM YYYY'),
                            ToDateString: moment(new Date()).format('DD MMMM YYYY'),
                            WasRequestApproved: false,
                            PartDay: false,
                            UnpaidLeave: false,
                        })
                        // TODO: uncoment for production
                        sendEmailToApplicant();
                        sendEmailToManager();
                    })
                    .catch(error => {
                        //display error
                        setSnackbar({
                            open: true,
                            severity: 'error',
                            message: error,
                        })
                    })
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: 'You can only book holidays until 31st of December ' + followingYear,
                })
            }
        }
    };

    function sendEmailToApplicant() {

        if (username.toLowerCase().includes('@wisetek')) {
            const callable = functions.httpsCallable('sendHolidayEmailToApplicant');
            //console.log(holidayRequestForm.FromDateString);
            //console.log(holidayRequestForm.ToDateString);
            // return callable(holidayRequestForm, auth).then(console.log)
            return callable(holidayRequestForm, auth)
                .then(
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: 'Request Submitted. You will receive an email confirmation shortly.',
                    })
                )
        }
    }

    function sendEmailToManager() {
        const callable = functions.httpsCallable('sendHolidayEmailToManager');
        return callable(holidayRequestForm, auth)
            .then(
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Success. You and your manager will receive emails shortly.',
                })
            )
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Container component="main" className={classes.main} maxWidth="lg">
                <Card className={classes.formCard} >
                    <form id="holidayForm" onSubmit={handleSubmit}>
                        <h3 className={classes.center}>Holiday Request Form</h3>

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    size="small"
                                    name="FullName"
                                    id="FullName"
                                    label="Full Name"
                                    variant="outlined"
                                    defaultValue={displayName}
                                    required
                                    fullWidth
                                    // autoFocus
                                    className={classes.lowerTextfield}
                                    inputProps={{ maxLength: 50 }}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Department"
                                    label="Department"
                                    name="Department"
                                    className={classes.lowerTextfield}
                                    inputProps={{ maxLength: 50 }}
                                    value={holidayRequestForm.Department}
                                    onChange={handleChange}
                                // InputLabelProps={{ shrink: true }}
                                >
                                    {listDepartments.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <DatePicker
                                    margin="normal"
                                    id="FromDate"
                                    label="From Date:"
                                    name="FromDate"
                                    format="dd MMMM yyyy"
                                    error // makes text red
                                    helperText="Inclusive in your holiday request. Check before submitting **"
                                    required
                                    className={classes.datePickerCenter}
                                    value={holidayRequestForm.FromDate}
                                    onChange={handleDateChangeFromDate}
                                />
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Manager"
                                    label="Manager"
                                    name="Manager"
                                    className={classes.lowerTextfield}
                                    inputProps={{ maxLength: 50 }}
                                    value={holidayRequestForm.Manager}
                                    onChange={handleChangeManager}
                                // InputLabelProps={{ shrink: true }}
                                >
                                    {listManagers.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <DatePicker
                                    margin="normal"
                                    id="ToDate"
                                    label="To Date:"
                                    name="ToDate"
                                    format="dd MMMM yyyy"
                                    error // makes text red
                                    helperText="Inclusive in your holiday request. Check before submitting **"
                                    required
                                    className={classes.datePickerCenter}
                                    value={holidayRequestForm.ToDate}
                                    onChange={handleDateChangeToDate}
                                />
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <FormGroup row className={classes.lowerCheckboxes}>
                                    {/* <FormControlLabel
                                    control={<Checkbox color="primary" checked={holidayRequestForm.HalfDay} onChange={handleChangeCheckboxHalfDay} name="HalfDay" />}
                                    label="Half Day"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={holidayRequestForm.SingleDay} onChange={handleChangeCheckboxSingleDay} name="SingleDay" />}
                                    label="Single Day"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={holidayRequestForm.ShiftDay} onChange={handleChangeCheckboxShiftDay} name="ShiftDay" />}
                                    label="Shift Day"
                                /> */}
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={shiftWorker} name="ShiftWorker" disabled />}
                                        label="Shift Worker"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={holidayRequestForm.PartDay} onChange={handleChangeCheckboxPartDay} name="PartDay" />}
                                        label="Part of the Day"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={holidayRequestForm.UnpaidLeave} onChange={handleChangeCheckboxUnpaidLeave} name="UnpaidLeave" />}
                                        label="Unpaid Leave"
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid container item xs={12} sm={3} spacing={1}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    type="text"
                                    id="TotalDaysRequested"
                                    label="Requested Days"
                                    name="TotalDaysRequested"
                                    className={classes.lowerTextfield}
                                    inputProps={{ maxLength: 50 }}
                                    value={holidayRequestForm.TotalDaysRequested}
                                    onChange={handleChangeOnlyInt}
                                    InputLabelProps={{ shrink: true }}>
                                </TextField>

                            </Grid>

                            <Grid container item xs={12} sm={3} spacing={1}>
                                <TextField
                                    // disabled={!this.state.value}
                                    disabled={holidayRequestForm.PartDay ? false : true}
                                    select
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    id="HoursRequested"
                                    label="Requested Hours"
                                    name="HoursRequested"
                                    className={classes.lowerTextfield}
                                    inputProps={{ maxLength: 50 }}
                                    value={holidayRequestForm.HoursRequested}
                                    onChange={handleChangeHours}
                                    InputLabelProps={{ shrink: true }}>
                                    {holidayHours.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={10} sm={10} spacing={1}>
                                <TextField
                                    name="AdditionalComment"
                                    label="Additional Comment"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={6}
                                    inputProps={{ maxLength: 500 }}
                                    className={classes.lowerTextfield}
                                    value={holidayRequestForm.AdditionalComment}
                                    onChange={handleChange}
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="submit"
                            className={classes.buttonRight}
                            variant="contained"
                            color="primary"
                        >
                            Submit request
                        </Button>

                    </form>
                </Card>
            </Container>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </MuiPickersUtilsProvider>
    )
}
