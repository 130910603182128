import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Container, TextField, CssBaseline, Grid, Card, Button, InputBase, Dialog } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Loading from '../../Loading';
import Footer from '../../../Footer';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import Barcode from 'react-barcode';
import { DataContext } from '../../../../contexts/DataContext';
import ServerRecoveryAddRecord from './ServerRecoveryAddRecord';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    card: {
        // minHeight: '100vh'
    },
    textFieldBox: {
        width: '100%',
        height: '65px',
        marginTop: '0px',
        marginBottom: '15px',
        // textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
    },
    inputBaseScan: {
        padding: '10px',
        fontSize: '1.5em',
    },
    marginBottom: {
        marginBottom: '45px'
    },
    submitBtn: {
        marginLeft: 'auto',
        // marginBottom: 25,
    },
    clearInputButtonMargin: {
        marginRight: '15px'
    },

}));


export default function ServerRecovery() {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);
    const { getServerRecoveryData, getServerRecoveryByPartNumber } = useContext(DataContext);

    const [scanInfo, setScanInfo] = useState({
        boxUrl: '',
        partNo: '',
        partNoUrl: '',
        serverUrl: '',
    });

    const [srData, setSrData] = useState([])

    const [isDialogOpen, setIsDialogOpen] = useState(false)

    //Snackbar
    //#region 
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    const handleChange = (e) => {
        setScanInfo({
            ...scanInfo,
            [e.target.name]: e.target.value.toUpperCase()
        })
        setSrData([])
    }

    const handleClearForm = (e) => {
        setScanInfo({
            ...scanInfo,
            boxUrl: '',
            partNo: '',
            partNoUrl: '',
            serverUrl: '',
        })
        setSrData([])
        document.getElementById('partNo').focus();
    }

    const handleAddRecordOpenDialog = () => {
        setIsDialogOpen(true)
    }

    var isSubmitting = false;
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isSubmitting) {
            isSubmitting = true;
            getServerRecoveryByPartNumber(scanInfo.partNo)
                .then(succ => {
                    console.log('srData: ', succ)
                    if (succ.length === 0) {
                        setSnackbar({
                            open: true,
                            severity: 'info',
                            message: `${scanInfo.partNo} not found in Part Numbers`,
                        })
                    }
                    setSrData(succ)
                    isSubmitting = false;
                })
                .catch(err => {
                    console.log('err', err)
                    isSubmitting = false;
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: err,
                    })
                })
        }
    }

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    {/* <CssBaseline /> */}
                    <SideMenuV2 selectedIndex={56} openProductionColapse={true} />

                    <div className={classes.main}>

                        <Card className={classes.card}>

                            <form id="serverRecovery" onSubmit={handleSubmit}>

                                <h3 className={classes.center}>Server Recovery</h3>

                                <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4} className={classes.marginBottom}>

                                    <Grid container item xs={12} sm={6} spacing={1}>
                                        <p>Scan Part Number below</p>
                                        <div className={classes.textFieldBox}>
                                            <InputBase
                                                className={classes.inputBaseScan}
                                                autoFocus={true}
                                                autoComplete="off"
                                                variant="standard"
                                                fullWidth
                                                id="partNo"
                                                name="partNo"
                                                value={scanInfo.partNo}
                                                onChange={handleChange}
                                                inputProps={{ maxLength: 50 }}
                                            >
                                            </InputBase>
                                        </div>

                                        <Button
                                            className={classes.clearInputButtonMargin}
                                            type="button"
                                            size="small"
                                            variant="outlined"
                                            onClick={handleClearForm}>
                                            Clear Input
                                        </Button>
                                        <Button
                                            type="button"
                                            size="small"
                                            color="primary"
                                            variant="outlined"
                                            onClick={handleAddRecordOpenDialog}>
                                            Add Record
                                        </Button>

                                        <Button
                                            type="submit"
                                            size="small"
                                            className={classes.submitBtn}
                                            variant="outlined"
                                            color="primary">
                                            Submit
                                        </Button>

                                    </Grid>

                                </Grid>
                                {isSubmitting ? <Loading /> : null}
                            </form>

                            {srData.length > 0 ? <h4 style={{ color: 'green', textAlign: 'center' }}>Found:</h4> : null}
                            {srData ? srData.map(item => {
                                if (item.partNo === scanInfo.partNo) {
                                    return (
                                        <Grid container key={item.id} direction="row" justifyContent="center" alignItems="flex-start" style={{ marginBottom: 300 }}>
                                            <Grid item xs={12} sm={4} className={classes.center}>
                                                <p>Part Location</p>
                                                <img src={item.serverUrl} id='serverImg' width='40%' height='20%' className='zoomInImage imgA' />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className={classes.center}>
                                                <p>Part: {item.partNo}</p>
                                                <img src={item.partNoUrl} id='partImg' width='40%' eight='20%' className='zoomInImage imgB' />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className={classes.center}>
                                                <p>Box</p>
                                                <img src={item.boxUrl} id='boxImg' width='40%' height='20%' className='zoomInImage imgC' />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            })
                                : null}

                            {/* {srData ? srData.map(item => (
                                <div key={item.id} style={{ border: '1px solid grey', margin: 50 }}>
                                    doc id :{item.id}<br />
                                    Part No.:{item.partNo}<br />
                                    Server Img Url:{item.serverUrl}<br />
                                    Part No. Img Url:{item.partNoUrl}<br />
                                    Box Img Url:{item.boxUrl}<br />
                                </div>
                            ))
                                : null} */}

                        </Card>

                    </div>


                    <ServerRecoveryAddRecord isOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} setSnackbar={setSnackbar} />


                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div >
            )
        }
    }
}