import React, { useContext, useState } from 'react';
import logo from '../../images/wisetek_owl.png';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { AuthContext } from '../../contexts/AuthContext';
import { Card, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Footer from '../Footer';
import SnackbarComponent from '../customComponents/SnackbarComponent';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f0f5f4'
    },
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    card: {
        padding: '24px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp() {
    const classes = useStyles();
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        department: '',
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const history = useHistory();

    const { signUp } = useContext(AuthContext);

    const departments = [
        // { value: '', label: '' },
        { value: 'CS/Logistics/Purchasing', label: 'CS/Logistics/Purchasing' },
        { value: 'Engineering', label: 'Engineering' },
        { value: 'Finance', label: 'Finance' },
        { value: 'HR', label: 'HR' },
        { value: 'IS&T', label: 'IS&T' },
        { value: 'Marketing', label: 'Marketing' },
        { value: 'Operations', label: 'Operations' },
        { value: 'Quality and Compliance', label: 'Quality and Compliance' },
        { value: 'Sales', label: 'Sales' },
    ];

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Signup Submission for: ' + state.email)

        //check if it is wisetek email address and then signup
        if (state.email.includes('@wisetek.net') ||
            state.email.includes('@wisetekusa.com') ||
            state.email.includes('@wisetek.co.uk') ||
            state.email.includes('@wisetekmarket.com')) {
            // SignUp new user
            signUp(state)
                .then(success => {
                    //console.log(success);
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: success,
                    })
                    setTimeout(() => {
                        history.push('/')
                    }, 6000)
                })
                .catch(error => {
                    //console.log(error);
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: error,
                    })
                })
        }
        else {
            //user outside wisetek not allowed to signup
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Only company members are allowed to signup.',
            })
        }
        document.getElementById('signup-form').reset();
    }


    return (
        <div className={classes.root}>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <div>
                        <img id="logo" src={logo} alt="Logo" />
                    </div>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                </div>

                <Card className={classes.card}>

                    <form id="signup-form" className={classes.form} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    inputProps={{ maxLength: 30 }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="lname"
                                    inputProps={{ maxLength: 30 }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="department"
                                    label="Department"
                                    name="department"
                                    autoComplete="department"
                                    value={state.department}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                >
                                    {departments.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign Up
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </form>

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

                </Card>

            </Container>
            <Footer />
        </div>
    );
}