import { Button, Card, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { DataContext } from '../../../contexts/DataContext';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import { db } from '../../../config/fbConfig';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        backgroundColor: '#f0f5f4',
    },
    center: {
        // color: 'White',
        textAlign: 'center',
        padding: '30px',
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    marginOne: {
        margin: 20
    },
    marginTwo: {
        margin: 40
    },
}));


export default function EditAllUsers() {
    const classes = useStyles();
    const { isAuthenticated } = useContext(AuthContext);
    const { getAllUsers } = useContext(DataContext);

    const [users, setUsers] = useState([])

    //#region Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    useEffect(() => {
        getAllUsers()
            .then(succ => {
                // console.log(succ)
                setUsers(succ)
            })
            .catch(err => {
                console.log(err)
            })
        return () => { }
    }, [])

    function addSomethingToAllUsers() {
        return new Promise((resolve, reject) => {
            var counter = 0
            users.forEach(user => {
                counter++
                var roles = ['member']
                if (user.role != 'member') {
                    if (user.role === 'viewAcc') {
                        roles = ['viewAcc']
                    }
                    else {
                        roles.push(user.role)
                    }
                }
                // db.collection('users').doc(user.id).update({
                //     roles: roles,
                // })
            })
            if (counter === users.length) {
                resolve(counter)
            } else {
                reject(counter)
            }
        })
            .then(succ => {
                setSnackbar({
                    open: true,
                    severity: 'info',
                    message: `Successfully updated ${succ} users.`,
                })
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: `Error, ${err} records updated.`,
                })
            })
    }

    // const addColumn = () => {
    //     db.collection('ticketsIT').get()
    //         .then(snap => {
    //             var counter = 0
    //             snap.forEach(doc => {
    //                 counter++
    //                 db.collection('ticketsIT').doc(doc.id).update({
    //                     isArchive: false,
    //                 })
    //             })
    //             console.log('updated: ', counter)
    //         })
    //         .catch(err => {
    //             console.log('there was an error:', err)
    //         })
    // }

    if (!isAuthenticated) {

        return <Redirect to='/' />
    }
    else {
        return (
            <Card>
                <Typography variant="caption" className={classes.center}>
                    Add field for all users in collection, as example i added roles['member'] for each user.
                    Just retrived all {users.length} users.
                </Typography>
                <Button className={classes.marginTwo}
                    onClick={addSomethingToAllUsers}
                    style={{ width: '50%' }}
                    variant="contained"
                    color="primary"
                    size="small">
                    add something to all users
                </Button>
                {/* <br />
                <Button className={classes.marginTwo}
                    onClick={addColumn}
                    style={{ width: '50%' }}
                    variant="contained"
                    color="primary"
                    size="small">
                    add column to something...
                </Button> */}

                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
            </Card>
        )
    }
}