import React, { useContext, useState } from 'react'
import { Button, LinearProgress, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import csvToJson from 'csvtojson'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import { DataContext } from '../../../contexts/DataContext';
import { countries } from '../../../helpers/hardcodedVars';


const useStyles = makeStyles(theme => ({
    dropzone: {
        backgroundColor: 'rgb(169,169,169)',
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '30px',
        textAlign: 'center',
        border: '1px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        padding: '20px',
    },
    input: {
        display: 'none',
    },
    buttonMargin: {
        marginLeft: '10px',
    },
    marginBottom: {
        marginBottom: '15px',
    },
    dropProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    searchProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    searchDataTable: {
        display: 'none',
    },

}));


export default function CSVManipulator() {
    const classes = useStyles();

    const { addRecordsToDatabase } = useContext(DataContext);

    const [csvForm, setCsvForm] = useState({
        columns: [],
        data: []
    })

    // Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    // Dialog
    const [dialog, setDialog] = useState({
        open: false,
        title: '',
    });


    const handleCloseAndCancelDialog = (e) => {
        setCsvForm({
            ...csvForm,
            data: []
        });
        setDialog({
            open: false,
            title: '',
        });
    };

    const handleUploadDialog = (e) => {
        setDialog({
            open: false,
            title: '',
        });

        // TODO: do whatever you need to do with the data...  upload data to db and snackbar success
        addRecordsToDatabase(countries)
            .then(success => {
                // console.log(`Added ${csvForm.data.length + 1} new rows to db`)
                console.log(success)
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                });
            })
            .catch(error => {
                console.log(error)
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                });
            })
    };

    function fileTypes(fileName) {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }
    const dragOver = (e) => {
        e.preventDefault();
    }
    const dragEnter = (e) => {
        e.preventDefault();
    }
    const dragLeave = (e) => {
        e.preventDefault();
    }
    const readCSVFile = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        // reader.readAsBinaryString(file);
        reader.readAsText(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        // reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onload = () => resolve((reader.result));
        // reader.onload = () => {
        //     // Parse CSV file
        //     csv.parse(reader.result, (err, data) => {
        //         console.log("Parsed CSV data: ", data);
        //         resolve(data);
        //     });
        // };
        reader.onerror = error => reject(error);
    });

    const fileDrop = (e) => {
        e.preventDefault();
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        // for ref please check this article here: https://blog.logrocket.com/create-a-drag-and-drop-component-with-react-dropzone/
        const files = e.dataTransfer.files;
        if (files.length > 1) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Only one file allowed.',
            })
            dropProgress.style.visibility = 'hidden';
        }
        else {
            [...files].forEach(file => {
                var fileType = String(fileTypes(file.name));
                const maxSize = 25000000
                // var header = "Dell_Item_Num,Description,Mfg_Item_Num"
                var header = "something"
                var numberOfColumns = 1
                // console.log(header)
                if (fileType.toLowerCase() === 'csv') {
                    // console.log(file)
                    if (file.size >= maxSize) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'Selected file is too big to process.',
                        })
                        dropProgress.style.visibility = 'hidden';
                    }
                    else {
                        readCSVFile(file)
                            .then(result => {
                                // console.log(result.split('\n')[0])
                                var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                                if (header === uploadedHeader) {
                                    csvToJson().fromString(result)
                                        .then((jsonObj) => {
                                            // console.log(jsonObj);
                                            var processData = [];
                                            //change true false fiedls
                                            jsonObj.forEach(dataObject => {
                                                var calculatedDataObject = {};
                                                if (Object.keys(dataObject).length > numberOfColumns) {
                                                    setSnackbar({
                                                        open: true,
                                                        severity: 'error',
                                                        message: 'Found additional columns, please use the correct template.',
                                                    })
                                                    dropProgress.style.visibility = 'hidden';
                                                    return
                                                }
                                                // console.log(dataObject)
                                                calculatedDataObject = customerPendingReleaseCalc(dataObject);

                                                processData.push(calculatedDataObject)
                                            })
                                            // console.log('processData:______', processData)                            
                                            setCsvForm({
                                                ...csvForm,
                                                data: processData
                                            })
                                            setDialog({
                                                open: true,
                                                title: `Found ${processData.length === 0 ? 0 : processData.length + 1} rows to upload, would you like to proceed?`
                                            })
                                            dropProgress.style.visibility = 'hidden';

                                        })
                                }
                                else {
                                    setSnackbar({
                                        open: true,
                                        severity: 'error',
                                        message: 'Template does not match. Please use correct template.',
                                    })
                                    dropProgress.style.visibility = 'hidden';
                                }
                            })
                    }
                }
                else {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: 'Please make sure that your are uploading a CSV file.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
            })
        }
    }

    const fileSelect = (e) => {
        var { target: { files } } = e;
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        // var onlyCSVs = [];
        // [...files].forEach(file => {
        //     var fileType = String(fileTypes(file.name));
        //     // console.log(fileType.toLowerCase())
        //     if (fileType.toLowerCase() === 'csv') {
        //         onlyCSVs.push(file)
        //     }
        // })
        // console.log(onlyCSVs)
        [...files].forEach(file => {
            var fileType = String(fileTypes(file.name));
            const maxSize = 25000000
            var header = "Dell_Item_Num,Description,Mfg_Item_Num"
            var numberOfColumns = 3
            // console.log(header)
            if (fileType.toLowerCase() === 'csv') {
                // console.log(file)
                if (file.size >= maxSize) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'Selected file is too big to process.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
                else {
                    readCSVFile(file)
                        .then(result => {
                            // console.log(result.split('\n')[0])
                            var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                            if (header === uploadedHeader) {
                                csvToJson().fromString(result)
                                    .then((jsonObj) => {
                                        // console.log(jsonObj);
                                        var processData = [];
                                        //change true false fiedls
                                        jsonObj.forEach(dataObject => {
                                            var calculatedDataObject = {};
                                            if (Object.keys(dataObject).length > numberOfColumns) {
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'error',
                                                    message: 'Found additional columns, please use the correct template.',
                                                })
                                                dropProgress.style.visibility = 'hidden';
                                                return
                                            }
                                            // console.log(dataObject)
                                            calculatedDataObject = customerPendingReleaseCalc(dataObject);

                                            processData.push(calculatedDataObject)
                                        })
                                        // console.log(processData)
                                        setDialog({
                                            open: true,
                                            title: `Found ${processData.length === 0 ? 0 : processData.length + 1} rows to upload, would you like to proceed?`
                                        })
                                        dropProgress.style.visibility = 'hidden';
                                    })
                            }
                            else {
                                setSnackbar({
                                    open: true,
                                    severity: 'error',
                                    message: 'Template does not match. Please use correct template.',
                                })
                                dropProgress.style.visibility = 'hidden';
                            }
                        })
                }
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'Please make sure that your are uploading a CSV file.',
                })
                dropProgress.style.visibility = 'hidden';
            }
        })
    }

    const customerPendingReleaseCalc = (dataObject) => {
        var calculatedDataObject = {};
        // console.log(dataObject)
        // // calculations only if custoemr pending true and customer released true
        // if (dataObject.CustomerPending === 'TRUE' && dataObject.CustomerReleased === 'TRUE' ||
        //     dataObject.CustomerPending === true && dataObject.CustomerReleased === true) {
        //     console.log(dataObject)
        //     var daysToAdd = 0;
        //     daysToAdd = moment(dataObject.CustomerReleasedDate).diff(moment(dataObject.CustomerPendingDate), 'days') + 1
        //     console.log('days to add', daysToAdd)
        //     switch (dataObject.DealWIPStatus) {
        //         case 'Basic Audit WIP':
        //             var calcVerificationDueDate = ''
        //             var calcSLATurnoutDueDate = ''
        //             var calcTechAuditSLADueDate = ''
        //             var calcBasicAuditSLADueDate = ''
        //             calcVerificationDueDate = moment(dataObject.VerificationDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
        //             calcSLATurnoutDueDate = moment(dataObject.SLATurnoutDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
        //             calcTechAuditSLADueDate = moment(dataObject.TechAuditSLADueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
        //             calcBasicAuditSLADueDate = moment(dataObject.BASLADueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
        //             console.log(dataObject.VerificationDueDate, 'Days added: ' + daysToAdd, calcVerificationDueDate)
        //             console.log(dataObject.SLATurnoutDueDate, 'Days added: ' + daysToAdd, calcSLATurnoutDueDate)
        //             console.log(dataObject.TechAuditSLADueDate, 'Days added: ' + daysToAdd, calcTechAuditSLADueDate)
        //             console.log(dataObject.BASLADueDate, 'Days added: ' + daysToAdd, calcBasicAuditSLADueDate)
        //             dataObject.VerificationDueDate = calcVerificationDueDate
        //             dataObject.SLATurnoutDueDate = calcSLATurnoutDueDate
        //             dataObject.TechAuditSLADueDate = calcTechAuditSLADueDate
        //             dataObject.BASLADueDate = calcBasicAuditSLADueDate
        //             break;
        //         case 'Tech Audit WIP':
        //             var calcVerificationDueDate = ''
        //             var calcSLATurnoutDueDate = ''
        //             var calcTechAuditSLADueDate = ''
        //             calcVerificationDueDate = moment(dataObject.VerificationDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
        //             calcSLATurnoutDueDate = moment(dataObject.SLATurnoutDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
        //             calcTechAuditSLADueDate = moment(dataObject.TechAuditSLADueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
        //             console.log(dataObject.VerificationDueDate, 'Days added: ' + daysToAdd, calcVerificationDueDate)
        //             console.log(dataObject.SLATurnoutDueDate, 'Days added: ' + daysToAdd, calcSLATurnoutDueDate)
        //             console.log(dataObject.TechAuditSLADueDate, 'Days added: ' + daysToAdd, calcTechAuditSLADueDate)
        //             dataObject.VerificationDueDate = calcVerificationDueDate
        //             dataObject.SLATurnoutDueDate = calcSLATurnoutDueDate
        //             dataObject.TechAuditSLADueDate = calcTechAuditSLADueDate
        //             break;
        //         case 'Verification WIP':
        //             var calcVerificationDueDate = ''
        //             var calcSLATurnoutDueDate = ''
        //             calcVerificationDueDate = moment(dataObject.VerificationDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
        //             calcSLATurnoutDueDate = moment(dataObject.SLATurnoutDueDate, 'DD-MMM-YY').add(daysToAdd, 'days').format('DD-MMM-YY');
        //             console.log(dataObject.VerificationDueDate, 'Days added: ' + daysToAdd, calcVerificationDueDate)
        //             console.log(dataObject.SLATurnoutDueDate, 'Days added: ' + daysToAdd, calcSLATurnoutDueDate)
        //             dataObject.VerificationDueDate = calcVerificationDueDate
        //             dataObject.SLATurnoutDueDate = calcSLATurnoutDueDate
        //             break;

        //         default:
        //             break;
        //     }
        // }

        // boolean fields are:
        // var booleanKeysToCheck = ['CompliantToBasicAuditSLA', 'CompliantToRecSLA', 'CompliantToTechAuditSLA', 'CompliantTurnoutSLA', 'VerificationCompliantToSLA', 'CustomerPending', 'CustomerReleased'];
        // // for (var key of Object.keys(dataObject)) {
        // for (var key of booleanKeysToCheck) {
        //     // console.log(key + " -> " + dataObject[key])
        //     // console.log(dataObject[key])
        //     if (dataObject[key] == undefined) {
        //         dataObject[key] = false
        //     }
        //     else {
        //         if (dataObject[key].toLowerCase() === "ok" || dataObject[key].toLowerCase() === "yes" || dataObject[key].toLowerCase() === "true") {
        //             dataObject[key] = true
        //         }
        //         else {
        //             dataObject[key] = false
        //         }
        //     }
        // }
        calculatedDataObject = dataObject;
        return calculatedDataObject
    }


    return (
        <div>
            <p>Here you can use this pre made code to do stuff with CSV data, like adding to database columns etc. <b>Note:</b> change in code is needed to make this work thats why its only available in admin section...</p>
            <div className={classes.dropzone}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}>
                <LinearProgress id='dropProgress' className={classes.dropProgress} />
                <GetAppIcon />
                <p>Drag & Drop CSV file here</p>
                <h4>Or</h4>
                <input type="file" accept=".csv" id="addFiles" className={classes.input} onChange={fileSelect} />
                <label htmlFor="addFiles">
                    <Button
                        className={classes.marginBottom}
                        variant="contained"
                        color="default"
                        component="span"
                        startIcon={<AttachFileIcon />}>
                        Select CSV file
                    </Button>
                </label>
            </div>


            <Dialog
                open={dialog.open}
                onClose={handleCloseAndCancelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
                {/* <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
                    </DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseAndCancelDialog} color="default" className={classes.buttonMargin}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleUploadDialog} color="primary" className={classes.buttonMargin}>
                        Yes, proceed to upload
                    </Button>
                </DialogActions>
            </Dialog>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

        </div>
    )
}
