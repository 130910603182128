import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';
import Footer from '../../Footer';
import SideMenuSignOutOnly from '../SideMenus/SideMenuSignOutOnly';
import { Redirect } from 'react-router';
import { AuthContext } from '../../../contexts/AuthContext';
import ViewRoomBookings from './ViewRoomBookings';
import Loading from '../Loading';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    center: {
        textAlign: 'center'
    }
}));

export default function RoomBookings() {
    const classes = useStyles();
    const { isAuthenticated, loading } = useContext(AuthContext)

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuSignOutOnly />
                        <Container component="main" className={classes.main} maxWidth="xl">

                            <h1 className={classes.center}>Room Bookings</h1>
                            <ViewRoomBookings />

                        </Container>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}