import React, { useContext, useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import { db } from '../../../config/fbConfig';
import { DataContext } from '../../../contexts/DataContext';
import { AuthContext } from '../../../contexts/AuthContext';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

export default function PartnerMatrix() {

    const { role } = useContext(AuthContext);

    const { createPartnerMatrix, updatePartnerMatrixAsAdmin, updatePartnerMatrixAsMember } = useContext(DataContext);

    const [edit, setEdit] = useState({
        never: 'never',
        always: 'always',
    });

    const [partnerMatrix, setPartnerMatrix] = useState({
        columns: [
            { title: 'Region*', field: 'region', initialEditValue: '', editable: edit.never },
            { title: 'Country*', field: 'country', initialEditValue: '', editable: edit.never },
            { title: 'Partner*', field: 'partner', initialEditValue: '', editable: edit.never },
            // { title: 'Collection (Reverse Logistics)', field: 'collection', type: 'currency', currencySetting: { locale: 'hu', currencyCode: 'huf', minimumFractionDigits: 0, maximumFractionDigits: 2 } },
            { title: 'Collection (Reverse Logistics)*', field: 'collection', type: 'currency', initialEditValue: 0, editable: edit.never },
            { title: 'Decomission Services*', field: 'decomission', type: 'currency', initialEditValue: 0, editable: edit.never },
            { title: 'Offsite Data Destruction*', field: 'offsiteDD', type: 'currency', initialEditValue: 0, editable: edit.never },
            { title: 'Onsite Data Destruction*', field: 'onsiteDD', type: 'currency', initialEditValue: 0, editable: edit.never },
            { title: 'Offsite Data Erasure*', field: 'offsiteDE', initialEditValue: '', editable: edit.never },
            { title: 'Onsite Data Erasure*', field: 'onsiteDE', initialEditValue: '', editable: edit.never },
            { title: 'Recycling*', field: 'recycling', type: 'currency', initialEditValue: 0, editable: edit.never },
            { title: 'Refurbish*', field: 'refurbish', type: 'boolean', initialEditValue: false, editable: edit.never },
            { title: 'Resale*', field: 'resale', type: 'boolean', initialEditValue: false, editable: edit.never },
            { title: 'Export Waste from Country*', field: 'exportWaste', type: 'boolean', initialEditValue: false, editable: edit.never },
            { title: 'WEEElabex*', field: 'weee', type: 'boolean', initialEditValue: false, editable: edit.never },
            { title: 'R2*', field: 'r2', type: 'boolean', initialEditValue: false, editable: edit.never },
            { title: 'eStewards*', field: 'eStewards', type: 'boolean', initialEditValue: false, editable: edit.never },
            { title: 'ISO9001*', field: 'iso9001', type: 'boolean', initialEditValue: false, editable: edit.never },
            { title: 'ISO14001*', field: 'iso14001', type: 'boolean', initialEditValue: false, editable: edit.never },
            { title: 'ISO18001*', field: 'iso18001', type: 'boolean', initialEditValue: false, editable: edit.never },
            { title: 'Physical Address*', field: 'address', initialEditValue: '', editable: edit.never },
            { title: 'Cities/Regions covered*', field: 'coverage', initialEditValue: '', editable: edit.never },
            { title: 'Contact*', field: 'contact', initialEditValue: '', editable: edit.never },
            { title: 'Email*', field: 'email', initialEditValue: '', editable: edit.never },
            { title: 'Phone*', field: 'phone', initialEditValue: '', editable: edit.never },
            {
                title: 'Volume*', field: 'volume', lookup: {
                    'Low': 'Low',
                    'Medium': 'Medium',
                    'High': 'High'
                }, initialEditValue: 'Low', editable: edit.never
            },
            { title: 'CSAT Score*', field: 'csatScore', type: 'numeric', initialEditValue: 0, editable: edit.never },
            { title: 'Feedback Comments', field: 'feedback', initialEditValue: '', editable: edit.always },
        ],
        data: [],
    })

    const setEditable = async (role) => {
        var ro = await role;
        console.log(ro)
        if (ro === "adminAM" || ro === "admin") {
            setPartnerMatrix({
                ...partnerMatrix,
                columns: [
                    { title: 'Region*', field: 'region', initialEditValue: '', editable: edit.always },
                    { title: 'Country*', field: 'country', initialEditValue: '', editable: edit.always },
                    { title: 'Partner*', field: 'partner', initialEditValue: '', editable: edit.always },
                    // { title: 'Collection (Reverse Logistics)', field: 'collection', type: 'currency', currencySetting: { locale: 'hu', currencyCode: 'huf', minimumFractionDigits: 0, maximumFractionDigits: 2 } },
                    { title: 'Collection (Reverse Logistics)*', field: 'collection', type: 'currency', initialEditValue: 0, editable: edit.always },
                    { title: 'Decomission Services*', field: 'decomission', type: 'currency', initialEditValue: 0, editable: edit.always },
                    { title: 'Offsite Data Destruction*', field: 'offsiteDD', type: 'currency', initialEditValue: 0, editable: edit.always },
                    { title: 'Onsite Data Destruction*', field: 'onsiteDD', type: 'currency', initialEditValue: 0, editable: edit.always },
                    { title: 'Offsite Data Erasure*', field: 'offsiteDE', initialEditValue: '', editable: edit.always },
                    { title: 'Onsite Data Erasure*', field: 'onsiteDE', initialEditValue: '', editable: edit.always },
                    { title: 'Recycling*', field: 'recycling', type: 'currency', initialEditValue: 0, editable: edit.always },
                    { title: 'Refurbish*', field: 'refurbish', type: 'boolean', initialEditValue: false, editable: edit.always },
                    { title: 'Resale*', field: 'resale', type: 'boolean', initialEditValue: false, editable: edit.always },
                    { title: 'Export Waste from Country*', field: 'exportWaste', type: 'boolean', initialEditValue: false, editable: edit.always },
                    { title: 'WEEElabex*', field: 'weee', type: 'boolean', initialEditValue: false, editable: edit.always },
                    { title: 'R2*', field: 'r2', type: 'boolean', initialEditValue: false, editable: edit.always },
                    { title: 'eStewards*', field: 'eStewards', type: 'boolean', initialEditValue: false, editable: edit.always },
                    { title: 'ISO9001*', field: 'iso9001', type: 'boolean', initialEditValue: false, editable: edit.always },
                    { title: 'ISO14001*', field: 'iso14001', type: 'boolean', initialEditValue: false, editable: edit.always },
                    { title: 'ISO18001*', field: 'iso18001', type: 'boolean', initialEditValue: false, editable: edit.always },
                    { title: 'Physical Address*', field: 'address', initialEditValue: '', editable: edit.always },
                    { title: 'Cities/Regions covered*', field: 'coverage', initialEditValue: '', editable: edit.always },
                    { title: 'Contact*', field: 'contact', initialEditValue: '', editable: edit.always },
                    { title: 'Email*', field: 'email', initialEditValue: '', editable: edit.always },
                    { title: 'Phone*', field: 'phone', initialEditValue: '', editable: edit.always },
                    {
                        title: 'Volume*', field: 'volume', lookup: {
                            'Low': 'Low',
                            'Medium': 'Medium',
                            'High': 'High'
                        }, initialEditValue: 'Low', editable: edit.always
                    },
                    { title: 'CSAT Score*', field: 'csatScore', type: 'numeric', initialEditValue: 0, editable: edit.always },
                    { title: 'Feedback Comments', field: 'feedback', initialEditValue: '', editable: edit.always },
                ]
            })
        }
    }
    useEffect(() => {
        setEditable(role)
        return () => { }
    }, [role])

    var allPartnerMatrix = []

    useEffect(() => {
        db.collection('partnerMatrix').orderBy("dateCreated", "desc").get()
            .then(snpshot => {
                snpshot.forEach(doc => {
                    allPartnerMatrix.push({
                        ...doc.data(),
                        docId: doc.id,
                        role: role
                    })
                    setPartnerMatrix({
                        ...partnerMatrix,
                        data: allPartnerMatrix,
                    })
                })
            })
            .catch(function (error) {
                console.log("Error getting partner matrix data: ", error);
            });
        return () => { }
    }, [])

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const refreshData = () => {
        db.collection('partnerMatrix').orderBy("dateCreated", "desc").get()
            .then(snpshot => {
                snpshot.forEach(doc => {
                    allPartnerMatrix.push({
                        ...doc.data(),
                        docId: doc.id,
                        role: role
                    })
                    setPartnerMatrix({
                        ...partnerMatrix,
                        data: allPartnerMatrix,
                    })
                })
            })
            .catch(function (error) {
                console.log("Error getting partner matrix data: ", error);
            });
    }

    return (
        <div>
            <MaterialTable
                title={"Partner Matrix"}
                columns={partnerMatrix.columns}
                data={partnerMatrix.data}
                // options={{ detailPanelType: "single", actionsColumnIndex: -1,exportButton: true, filtering: true, pageSize: 15, pageSizeOptions: [5, 15, 50, { value: holidayEntitlements.data.length, label: 'All' }] }}
                //options={{ detailPanelType: "single", actionsColumnIndex: 0,fixedColumns: {left: 0, right: 0}, exportButton: true, pageSize: 5, pageSizeOptions: [5, 15, 50, { value: partnerMatrix.data.length, label: 'All' }] }}
                options={{
                    addRowPosition: 'first',
                    detailPanelType: "single",
                    actionsColumnIndex: -1,
                    exportButton: true,
                    pageSize: 5,
                    pageSizeOptions: [5, 15, 50, { value: partnerMatrix.data.length, label: 'All' }]
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => refreshData()
                    }
                ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                setPartnerMatrix(prevState => {
                                    const data = [...prevState.data];
                                    data.push(newData);

                                    createPartnerMatrix(newData)
                                        .then(success => {
                                            console.log('Added new partner matrix to DB')
                                            //display success
                                            setSnackbar({
                                                open: true,
                                                severity: 'success',
                                                message: success,
                                            })
                                        })
                                        .catch(error => {
                                            console.log(error)
                                            //display error
                                            reject()
                                            refreshData() // added to refresh table without showing empty erroed fields
                                            setSnackbar({
                                                open: true,
                                                severity: 'error',
                                                message: error,
                                            })
                                        });
                                    resolve();
                                    return { ...prevState, data };
                                });
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>

                        new Promise((resolve, reject) => {
                            console.log(oldData);
                            console.log(newData);
                            setTimeout(() => {
                                if (role === 'admin' || role === 'adminAM') {
                                    console.log('OnRowUpdate was:', role)
                                    if (oldData) {
                                        setPartnerMatrix(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;

                                            updatePartnerMatrixAsAdmin(newData)
                                                .then(success => {
                                                    console.log('Updated partner matrix record in DB as admin.')
                                                    //display success
                                                    setSnackbar({
                                                        open: true,
                                                        severity: 'success',
                                                        message: success,
                                                    })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                    //display error
                                                    setSnackbar({
                                                        open: true,
                                                        severity: 'error',
                                                        message: error,
                                                    })
                                                });
                                            resolve();
                                            return { ...prevState, data };
                                        })
                                    }
                                }
                                else if (role === 'memberAM') {
                                    console.log('OnRowUpdate was:', role)
                                    if (oldData) {
                                        setPartnerMatrix(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;

                                            updatePartnerMatrixAsMember(newData)
                                                .then(success => {
                                                    console.log('Updated partner matrix record in DB as member.')
                                                    //display success
                                                    setSnackbar({
                                                        open: true,
                                                        severity: 'success',
                                                        message: success,
                                                    })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                    //display error
                                                    setSnackbar({
                                                        open: true,
                                                        severity: 'error',
                                                        message: error,
                                                    })
                                                });
                                            resolve();
                                            return { ...prevState, data };
                                        })
                                    }

                                }
                                else {
                                    //display error
                                    setSnackbar({
                                        open: true,
                                        severity: 'error',
                                        message: 'Only Admin can update all fields, and member can update "feedback" fields in this record.',
                                    })
                                    reject()
                                }
                            }, 600)
                        }),
                    // onRowDelete: oldData =>
                    //     new Promise(resolve => {
                    //         setTimeout(() => {
                    //             resolve();
                    //             setTracker(prevState => {
                    //                 const data = [...prevState.data];
                    //                 data.splice(data.indexOf(oldData), 1);
                    //                 return { ...prevState, data };
                    //             });
                    //         }, 600);
                    //     }),
                }}
            />

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    )
}