import { Grid } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';

export default function MapContainer(props) {
    const mapRef = useRef(null);
    const [markers, setMarkers] = useState([]);

    const blue = '#0000FF'
    const yellow = '#FFFF00'
    const green = '#00FF00'

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDYN5ArCqxJRSpxDmrxxHaPg6ZiehU4zq0&libraries=places`;
        script.defer = true;
        script.async = true;

        script.onload = () => {
            const mapInstance = new window.google.maps.Map(mapRef.current, {
                center: { lat: 40, lng: -20 },
                zoom: 4,
            });

            // Initialize markers based on initial markersData
            updateMarkers(mapInstance, props.markersData);
        };

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [props.markersData]);

    const updateMarkers = (map, data) => {
        // Remove existing markers
        markers.forEach((marker) => {
            marker.setMap(null);
        });

        // Add new markers based on the updated data
        const newMarkers = []
        data.map((mark) => {
            if (mark.Latitude !== 0 && mark.Longitude !== 0) {
                newMarkers.push(mark)
                return addMarker(map, { lat: mark.Latitude, lng: mark.Longitude }, mark.Status_Code, mark.ID);
            }
        });

        setMarkers(newMarkers);
    };

    const addMarker = (map, position, status, trma) => {
        // Change the background color using svg
        var icon = {
            path: "M20 0C31.0457 0 40 8.9543 40 20C40 30.2043 21.5907 59.05 20 60C18.4093 59.05 0 30.2043 0 20C0 8.9543 8.9543 0 20 0ZM20 28C23.3137 28 26 25.3137 26 22C26 18.6863 23.3137 16 20 16C16.6863 16 14 18.6863 14 22C14 25.3137 16.6863 28 20 28Z",
            fillColor: `${status === 'RMACNT003' ? blue : status === 'RMACNT011' ? yellow : green}`,
            fillOpacity: 1,
            size: new window.google.maps.Size(21, 34),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(10, 34),
            strokeWeight: 1,
            scale: 0.5
        }

        const marker = new window.google.maps.Marker({
            position: position,
            map: map,
            title: `${trma} - ${status}`,
            icon: icon,
        });

        return marker;
    };

    const PinIcon = ({ fillColor }) => (
        <svg width="21" height="34" viewBox="0 0 40 60" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
            <path d="M20 0C31.0457 0 40 8.9543 40 20C40 30.2043 21.5907 59.05 20 60C18.4093 59.05 0 30.2043 0 20C0 8.9543 8.9543 0 20 0ZM20 28C23.3137 28 26 25.3137 26 22C26 18.6863 23.3137 16 20 16C16.6863 16 14 18.6863 14 22C14 25.3137 16.6863 28 20 28Z" stroke="#000" strokeWidth="1" />
        </svg>
    );

    return <div>
        {markers.length > 0 && <p>{`Found coordinates for ${markers.length} of ${props.markersData.length} TRMAs. Please check updated map view.`}</p>}
        <div ref={mapRef} style={{ height: '700px', width: '100%' }} />
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            style={{ margin: 20 }}
        >
            <Grid item xs={12} sm={2} container direction="column" justifyContent="space-evenly" alignItems="center">
                <PinIcon fillColor={blue} />
                <p>RMACNT003 - Request submitted</p>
            </Grid>
            <Grid item xs={12} sm={2} container direction="column" justifyContent="space-evenly" alignItems="center">
                <PinIcon fillColor={yellow} />
                <p>RMACNT011 - Pick Up Scheduled</p>
            </Grid>
            <Grid item xs={12} sm={2} container direction="column" justifyContent="space-evenly" alignItems="center">
                <PinIcon fillColor={green} />
                <p>RMACNT012 - In Transit</p>
            </Grid>
        </Grid>
    </div>;
}
