import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
    },
    textCentered: {
        textAlign: 'center'
    }
}));

export default function TemporaryWFH() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                <h2 className={classes.textCentered}>Short Term Policy regarding Working from home (WFH)</h2>
            </header>

            {/* <h5 >Dear Colleagues,</h5> */}

            <h4 >The recent outbreak of the Coronavirus (Covid-19) is an unprecedented situation. As a direct result, we have had to implement remote working where possible. The health and safety of the workforce and the wider community must be the key priority during this situation. There will be job roles and situations where this form of working is not practical or cannot be deployed in such a short time frame.  However, for those who can work from home with the permission of their Manager, employees are expected to be available and working as they would be in the office environment.
            </h4>

            {/* <h3 className={classes.textCentered}>We will update you when any new developments happen that require us as a Company to take additional action</h3> */}
            <article >

                <br />
                <p>Please note the following;</p>
                <ul >
                    <li>
                        <strong>Communication - </strong>
                        Ensure you have up to date contact details of all team members and aim to have at least two Zoom calls per day in order to keep up to date with the team.

                    </li><br />
                    <li>
                        <strong>Workstation - </strong>
                        Due to the current emergency, the normal preparations around establishing workstations in the home cannot occur. Please read guidance from the Health and Safety Authority on how to arrange your home work station.
                        <a href="https://www.hsa.ie/eng/Workplace_Health/Manual_Handling_Display_Screen_Equipment/FAQs/Display_Screen_Equipment_FAQs/Display_Screen_Equipment1.html" target="_blank" rel="noopener noreferrer">How to arrange your home work station.</a>
                    </li><br />
                    <li>
                        <strong>Health and Safety - </strong>
                        Any accident or incident that takes place outside of the office while the employee is conducting normal work duties, should be reported to the company as normal.
                    </li><br />
                    <li>
                        <strong>Data Protection - </strong>
                        Wisetek needs to ensure that data security is maintained where staff are working from their own homes. Where remote working involves the transmission of confidential data outside of the workplace, please ensure that all confidential information is shared in a password protected document. Please keep any hard copy documents out of sight of anyone else in the home and stored in an organised and safe manor.
                    </li><br />
                    <li>
                        <strong>Working Time - </strong>
                        Where possible, employees should stick to their usual working hours including their usual rest breaks. If changes need to be made to these working hours due to childcare requirements etc, please agree alternative start and finish times with your Manager/Supervisor.
                    </li><br />
                </ul>
                <br />
            </article>

            {/* <h3 className={classes.textCentered}>
                Supervisors please share this communication with your Teams whom don’t have email access.
                </h3> */}
            <h4 className={classes.textCentered}>
                We want to significantly reduce the number of people in our buildings at any one time. For the employees that are unable to work from home, please note the following;
            </h4>
            <br />
            <ul>
                <li>
                    As previously advised, there should be no travel between any Wisetek sites
                </li>
                <li>
                    We will be as flexible as possible to ensure people stay safe while endeavouring to continue to service our clients
                </li>
                <li>
                    If you are using public transport please avoid rush hour and arrange suitable start and finish times with your Manager/Supervisor/Team Lead
                </li>
                <li>
                    While in work, maintain your personal space, do not work too closely with others
                </li>
                <li>
                    Agree break times with your Manager/Supervisor/Team Lead – again maintain your personal space, bring your own lunch, and use your own utensils. If possible, avoid using microwaves, toasters and grills
                </li>
            </ul>
            <br />
            <h4 className={classes.textCentered}>
                As the situation continues to evolve there may be further changes and we will communicate accordingly.
            </h4>
            <p>Regards,<br /><br />

                Geni D’Arcy<br />
                Global Head of HR<br />
                Wisetek™<br />
            </p>
            <br />

        </div>
    )
}