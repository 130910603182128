import React, { useContext, useState } from 'react';
import Loading from '../Loading';
import Footer from '../../Footer'
import { AuthContext } from '../../../contexts/AuthContext';
import { makeStyles, CssBaseline, } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import SoftwareReleaseInfo from './SoftwareReleaseInfo';
import TicketsIT from './Tickets/TicketsIT';
import SideMenuV2 from '../SideMenus/SideMenuV2';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
        // marginLeft: '70px',
        // marginRight: '70px',

    },
    myTable: {
        padding: '0px',
    },

    // overrides: {
    //     MuiTableCell: {
    //         root: {
    //             paddingTop: '2px',
    //             paddingBottom: '2px',
    //             "&:last-child": {
    //                 paddingRight: '2px',
    //                 paddingLeft: '2px'
    //             }
    //         }
    //     }
    // }
}));

const ISandTConsole = (props) => {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });


    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('memberISandT') || roles.includes('adminISandT') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={37} openISandTColapse={true} />
                            <div className={classes.main}>

                                {/* <h1>Tickets IS&T</h1> */}

                                <TicketsIT />
                                <br />
                                <SoftwareReleaseInfo />
                                {/* <br />
                                <ISTTracker /> */}

                            </div>
                        </CssBaseline>
                        <Footer />

                        <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}

export default ISandTConsole