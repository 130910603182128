import React from 'react'
import WiseStarsWinnersCards from '../../../../customComponents/WiseStarsWinnersCards'

export default function Quarter42022(props) {
    return (
        <div>
            <div style={{
                marginLeft: '10%',
                marginRight: '10%'
            }}>
                <p>Dear Colleagues,</p>
                <p>Please see below the winners for q4 2022 and remember that you can now vote for q1 "WiseStars". If you wish to vote please go to "Human Resources" section where you can submit your vote as well as find open positions we currently have in Wisetek.</p>
                <p>Vote for WiseStars now and let the best win!</p>
            </div>
            <br />
            <WiseStarsWinnersCards storageRefString='NewsAndAnnouncements/WiseStarsWinners/q42022/' headerText='WiseStars Winners Q4 2022' getDataNow={props.getDataNow} />
        </div>
    )
}
