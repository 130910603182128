import React, { useContext, useState } from 'react';
import Loading from '../../Loading';
import Footer from '../../../Footer'
import { AuthContext } from '../../../../contexts/AuthContext';
import { makeStyles, CssBaseline } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import WeeklyKPIticketsIT from './WeeklyKPIticketsIT';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
    myTable: {
        padding: '0px',
    },
}));

const WeeklyKPIsConsole = (props) => {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('adminISandT') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={28} openMetricsColapse={true} openMetricsISandTColapse={true} />
                            <div className={classes.main}>

                                {/* <h1>KPI's Console</h1> */}

                                <WeeklyKPIticketsIT />

                            </div>
                        </CssBaseline>
                        <Footer />

                        <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}

export default WeeklyKPIsConsole