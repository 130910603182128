import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MetricsAdmin from './MetricsAdmin';
import ReassignHols from './ReassignHols';
import NoEmailUser from './NoEmailUser';
import WiseStarsList from './WiseStarsList';
import EditAllUsers from './EditAllUsers';
import WiseAcademyCerts from './WiseAcademyCerts';
import CSVManipulator from './CSVManipulator';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    center: {
        textAlign: 'center',
        padding: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function AdminActivities() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.center}>
                Admin Activities...
            </Typography>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs"
                >
                    <Tab label="Description" {...a11yProps(0)} />
                    <Tab label="Metrics DB" {...a11yProps(1)} />
                    <Tab label="Holiday requests" {...a11yProps(2)} />
                    <Tab label="No Email User" {...a11yProps(3)} />
                    <Tab label="WiseStars List" {...a11yProps(4)} />
                    <Tab label="All Users Utilities" {...a11yProps(5)} />
                    <Tab label="WiseAcademy Certs" {...a11yProps(6)} />
                    <Tab label="CSV Manipulator" {...a11yProps(7)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Typography>
                    Here you can create, update or delete different data sets within "Connect" all with admin privileges. Proceed with caution!
                </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MetricsAdmin />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ReassignHols />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <NoEmailUser />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <WiseStarsList />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <EditAllUsers />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <WiseAcademyCerts />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <CSVManipulator />
            </TabPanel>
        </div>
    );
}
