import React, { useState, useContext, useEffect } from 'react'
import { TextField, makeStyles, Button, MenuItem, Grid, List, Tooltip, ListItem, ListItemText } from '@material-ui/core'
import { DataContext } from '../../../../contexts/DataContext'
import { functions, auth } from '../../../../config/fbConfig';
import moment from 'moment';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    body: {
        padding: '50px'
    },
    myForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '35ch',
        },
        paddingLeft: '35px',
        paddingTop: '35px',
        paddingRight: '50px',
        paddingBottom: '35px',
    },
    input: {
        display: 'none',
    },
    filePicker: {
        margin: '0px 35px 35px 35px'
        // float: 'left',
        // marginLeft: '16%',
        // marginTop: '35px',
        // marginBottom: '35px',
    },
    buttonLeft: {
        float: 'left',
        marginLeft: '24px',
        marginTop: '24px',
        marginBottom: '35px',

    },
    buttonRight: {
        float: 'right',
        // marginTop: '24px',
        // marginBottom: '35px',

    },
    marginLeft: {
        marginLeft: 15
    },
    marginRight: {
        marginRight: 15
    },
    attachments: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)'
        },
        height: '35px',
        background: '#5E89B4',
        color: 'white',
        borderRadius: '5px',
    },
    attachedText: {
        color: 'green'
    },
    attList: {
        paddingLeft: '8px',
        paddingRight: '8px',
    }
}))

const ticketsStatus = [
    { value: '', label: '' },
    { value: 'Open', label: 'Open' },
    // { value: 'WIP - Scope', label: 'WIP - Scope' },
    // { value: 'WIP - Dev', label: 'WIP - Dev' },
    // { value: 'WIP - UAT', label: 'WIP - UAT' },
    // { value: 'WIP - Production Cut in', label: 'WIP - Production Cut in' },
    // { value: 'On Hold', label: 'On Hold' },
    // { value: 'Completed', label: 'Completed' },
    // { value: 'Closed - Not Needed', label: 'Closed - Not Needed' },
    { value: 'Closed - Passwords/un-enrolment provided', label: 'Closed - Passwords/un-enrolment provided' },
    { value: 'Closed - No passwords/un-enrolment provided', label: 'Closed - No passwords/un-enrolment provided' },
    // { value: 'Acknowledge', label: 'Acknowledge' },
];
const assignedCS = [
    { value: '', label: '' },
    { value: 'Anna Farrell', label: 'Anna Farrell' },
    { value: 'Anne-Marie Hourihan', label: 'Anne-Marie Hourihan' },
    { value: 'Brendan Reilly', label: 'Brendan Reilly' },
    { value: 'Ciara O\'Connell', label: 'Ciara O\'Connell' },
    { value: 'Felicity Davies', label: 'Felicity Davies' },
    { value: 'Grace Moore', label: 'Grace Moore' },
    { value: 'James Grant', label: 'James Grant' },
    { value: 'Louise Burke', label: 'Louise Burke' },
    { value: 'Michelle Miotto', label: 'Michelle Miotto' },
    { value: 'Thamires Santos', label: 'Thamires Santos' },
];


export default function TicketCSDetails(props) {
    const classes = useStyles()
    // console.log('props.ticketData', props.ticketData)
    // console.log('this is props.ticketData.tableData.showDetailPanel: ', props.ticketData.tableData.showDetailPanel)
    const { updateTicketCustomerService, delay } = useContext(DataContext);

    const [editValues, setEditValues] = useState({
        // issue error and change request values
        RecordUID: '',
        TicketType: '',
        LockType: '',
        Site: '',
        TRMA: '',
        Customer: '',
        Description: '',
        moreThanOneLockType: [],
        FilesURLs: [],
        ResponseFilesURLs: [],
        Status: '',
        AssignedCS: '',
        FixUpdateDescription: '',
        files: [],
        filesSize: 0,
        filesToStoreInDB: [],
    })

    useEffect(() => {
        // console.log('props.ticketData.engFilesURLs', props.ticketData.engFilesURLs)
        setEditValues({
            ...editValues,
            dateOpen: props.ticketData.dateOpen,
            dateClosed: props.ticketData.dateClosed,
            dateAssigned: props.ticketData.dateAssigned,
            userEmail: props.ticketData.userEmail,
            userFullName: props.ticketData.userFullName,
            RecordUID: props.ticketData.RecordUID,
            TicketType: props.ticketData.TicketType,
            LockType: props.ticketData.LockType,
            Site: props.ticketData.Site,
            TRMA: props.ticketData.TRMA,
            Customer: props.ticketData.Customer,
            Description: props.ticketData.Description,
            moreThanOneLockType: props.ticketData.moreThanOneLockType,
            FilesURLs: props.ticketData.FilesURLs,
            ResponseFilesURLs: props.ticketData.ResponseFilesURLs,
            Status: props.ticketData.Status,
            AssignedCS: props.ticketData.AssignedCS,
            FixUpdateDescription: props.ticketData.FixUpdateDescription,

        })
    }, [props.ticketData])

    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function sendTicketResponse() {
        console.log('send the response now')

        const callable = functions.httpsCallable('sendCSTicketResponse');
        return callable(editValues, auth) //changed from props.ticketData
            .then(
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Respond sent successfully. User will be notified. Thank you.',
                })
            )
    }

    const handleChange = (e) => {
        props.ticketData[e.target.name] = e.target.value
        setEditValues({
            ...editValues,
            [e.target.name]: e.target.value
        })
    }

    const handleDownload = async (e, file) => {
        e.preventDefault();
        const response = await fetch(file.url);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        // var attachments = e.target.files;
        // var filesToSend = [e.target.files[0]];
        // const filesToSend = [...files];
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...editValues.files];
        var filesToStore = [...editValues.filesToStoreInDB];
        [...files].forEach(file => {
            // console.log(file.type)
            if (file.type.toLowerCase().includes("audio/") ||
                file.type.toLowerCase().includes("video/") ||
                file.type.toLowerCase().includes("image/") ||
                file.type.toLowerCase().includes("application/pdf") ||
                file.type.toLowerCase().includes("application/vnd") || //for all office documents
                file.type.toLowerCase().includes("text/")) { //text/csv text/hml
                // storage
                //     .ref('ticketAttachments/' + '01Hk2AQqOOoHjy2LBXql/' + file.name)
                //     .put(file)
                // console.log(file)
                filesToStore.push(file)
                var convertResult = ''
                calculatedSize += file.size

                toBase64(file)
                    .then(result => {
                        convertResult = result
                        filesToSend.push({
                            content: convertResult,
                            filename: file.name,
                            type: file.type,
                            disposition: "attachment"
                        })
                        // console.log(calculatedSize)
                        if (editValues.filesSize >= maxSize || calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                        }
                        else {
                            if (editValues.filesSize + calculatedSize >= maxSize) {
                                setSnackbar({
                                    open: true,
                                    severity: 'warning',
                                    message: 'Selected files are too big to attach.',
                                })
                            }
                            else {
                                setEditValues({
                                    ...editValues,
                                    files: filesToSend,
                                    filesSize: editValues.filesSize + calculatedSize,
                                    filesToStoreInDB: filesToStore,
                                });
                                // console.log(filesToSend)
                            }
                        }
                    })
            } else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'File type not supported, please attach supported file: "audio/*, video/*, image/*, .pdf, .xlsx, .xls, .csv"',
                });
            }
        })
        e.target.value = null // to allow selecting the same file over and over
    }
    const handleChangeFilePickerRemove = (e) => {
        setEditValues({
            ...editValues,
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
    }


    var isSubmitting = false

    const handleSubmit = (e) => {
        e.preventDefault();

        if (editValues.Status !== '' &&
            editValues.AssignedCS !== '' &&
            editValues.FixUpdateDescription !== '') {

            updateTicketCustomerService(editValues) // changed from props.ticketData
                .then(success => {
                    //display success
                    // console.log('back from db with success')
                    // sendTicketResponse()
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: success,
                    })
                    // delay(2000).then(() => { window.location.reload() })
                    delay(5000).then(() => props.refreshTickets())
                    setEditValues({
                        ...editValues,
                        files: [],
                        filesSize: 0,
                        filesToStoreInDB: [],
                    })
                    isSubmitting = false
                })
                .catch(error => {
                    //display error
                    // console.log('back from db with error')
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: error,
                    })
                    isSubmitting = false
                })
        } else {
            //display error
            setSnackbar({
                open: true,
                severity: 'warning',
                message: '* Please select all required fields and add description.',
            })
            isSubmitting = false
        }
    };

    return (
        <div>

            <form className={classes.myForm} noValidate autoComplete="off" onSubmit={handleSubmit}>

                <TextField name="Customer" label="Customer" variant="outlined" value={props.ticketData.Customer} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="LockType" label="Lock Type" variant="outlined" value={props.ticketData.LockType} disabled size="small" InputLabelProps={{ shrink: true }} />
                {props.ticketData.LockType === 'More then one selected:' && props.ticketData.moreThanOneLockType.map(item => (
                    <TextField name="LockType" label="Lock Type selected" variant="outlined" value={item} disabled size="small" InputLabelProps={{ shrink: true }} />
                ))}
                <TextField name="Site" label="Site" variant="outlined" value={props.ticketData.Site} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="TRMA" label="TRMA" variant="outlined" value={props.ticketData.TRMA} disabled size="small" InputLabelProps={{ shrink: true }} />
                {/* no need to display closed date if ticket is open */}
                {props.ticketData.Status === 'Closed - Passwords/un-enrolment provided' || props.ticketData.Status === 'Closed - No passwords/un-enrolment provided' ?
                    <TextField name="dateClosed" label="Closed Date" variant="outlined" value={props.ticketData.dateClosed} disabled size="small" InputLabelProps={{ shrink: true }} />
                    : null}

                {editValues.FilesURLs === undefined || editValues.FilesURLs.length === 0 ? null :
                    <Grid container className={classes.marginLeftRight} direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <p className={classes.marginLeft}>Request files:</p>
                        {editValues.FilesURLs.map((file, i) => (
                            <List key={i} className={classes.attList}>
                                <Tooltip title="Download">
                                    <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                        <SaveAltIcon className={classes.marginRight} />
                                        <ListItemText primary={file.name} />
                                    </ListItem>
                                </Tooltip>
                            </List>
                        ))}
                    </Grid>
                }

                <TextField name="Description" label="Description" fullWidth multiline minRows={6} variant="outlined" value={props.ticketData.Description} disabled size="small" InputLabelProps={{ shrink: true }} />

                {editValues.TicketType === 'Unlock Request' &&

                    <div>
                        <TextField
                            select
                            required
                            name="Status"
                            label="Status"
                            value={editValues.Status}
                            onChange={handleChange}
                            error={editValues.Status === ""}
                            helperText={editValues.Status === "" ? 'Required field to send response.' : ' '}
                            variant="outlined"
                            size='small'
                            InputLabelProps={{ shrink: true }}>
                            {ticketsStatus.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            select
                            required
                            name="AssignedCS"
                            label="Assigned CS"
                            value={editValues.AssignedCS}
                            onChange={handleChange}
                            error={editValues.AssignedCS === ""}
                            helperText={editValues.AssignedCS === "" ? 'Required field to send response.' : ' '}
                            variant="outlined"
                            size='small'
                            InputLabelProps={{ shrink: true }}>
                            {assignedCS.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        {editValues.ResponseFilesURLs === undefined || editValues.ResponseFilesURLs.length === 0 ? null :
                            <Grid container className={classes.marginLeftRight} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <p className={classes.marginLeft}>Response files:</p>
                                {editValues.ResponseFilesURLs.map((file, i) => (
                                    <List key={i} className={classes.attList}>
                                        <Tooltip title="Download">
                                            <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                                <SaveAltIcon className={classes.marginRight} />
                                                <ListItemText primary={file.name} />
                                            </ListItem>
                                        </Tooltip>
                                    </List>
                                ))}
                            </Grid>
                        }

                        <TextField name="FixUpdateDescription" label="Fix Update Description"
                            required
                            fullWidth
                            multiline
                            minRows={6}
                            variant="outlined"
                            inputProps={{ maxLength: 500 }}
                            value={editValues.FixUpdateDescription}
                            onChange={handleChange}
                            error={editValues.FixUpdateDescription === ""}
                            helperText={editValues.FixUpdateDescription === "" ? 'Required field to send response.' : ' '}
                            size="small"
                            InputLabelProps={{ shrink: true }} />
                    </div>
                }

                {/* <Button
                    type="button"
                    disabled={isSubmitting}
                    className={classes.buttonLeft}
                    variant="contained"
                    style={{ backgroundColor: 'lightgreen' }}
                    onClick={sendTicketResponse}
                >
                    Send response
                </Button> */}


                <div className={classes.filePicker}>
                    <input
                        // accept="image/*"
                        accept="audio/*,video/*,image/*,.xlsx,.xls"
                        className={classes.input}
                        id="add-contained-button-file"
                        multiple
                        type="file"
                        onChange={handleChangeFilePicker}
                    />
                    <label htmlFor="add-contained-button-file">
                        <Button
                            className={classes.marginRight}
                            variant="contained"
                            color="default"
                            component="span"
                            startIcon={<AttachFileIcon />}>
                            Add Files
                        </Button>
                    </label>
                    {/* <label htmlFor="remove-contained-button-file"> */}
                    <Button
                        variant="contained"
                        color="secondary"
                        component="span"
                        startIcon={<DeleteOutlineIcon />}
                        onClick={handleChangeFilePickerRemove}>
                        Remove Files
                    </Button>
                    {/* </label> */}

                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={classes.buttonRight}
                        variant="contained"
                        color="primary"
                    >
                        Update ticket
                    </Button>

                    <p className={classes.marginLeft}><b>Note: </b>Only currently attached files will be sent with response message, previously attached files are displayed above only for reference.</p>
                    {editValues.files.length === 0 ? null : <p className={classes.attachedText}>Files attached: {editValues.files.length}</p>}
                </div>

            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

        </div >
    )
}