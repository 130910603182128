import React, { useContext, useState } from 'react';
import Loading from '../Loading';
import SideMenu from '../SideMenus/SideMenu';
import Footer from '../../Footer'
import { AuthContext } from '../../../contexts/AuthContext';
import { makeStyles, Container, CssBaseline } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import PartnerMatrix from './PartnerMatrix';
import SnackbarComponent from '../../customComponents/SnackbarComponent';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    myTable: {
        padding: '0px',
    },
}));

const PartnerMatrixConsole = (props) => {

    const classes = useStyles();

    const { isAuthenticated, loading, role } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    if (loading) {
        //empty div
        return (
            <Loading />
        )
    }
    else {
        if (!isAuthenticated || role === 'member'
            || role === 'memberISandT'
            || role === 'memberHR'
            || role === 'memberENG'
            // || role === 'memberAM'
            || role === 'memberFacilities'
            || role === 'adminISandT'
            || role === 'adminHR'
            || role === 'adminENG'
            // || role === 'adminAM'
            || role === 'adminFacilities'
            || role === 'teamManager') {

            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenu />
                        <Container component="main" className={classes.main} maxWidth="xl">

                            <h1>Partner Matrix Console</h1>

                            <PartnerMatrix />

                        </Container>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}

export default PartnerMatrixConsole