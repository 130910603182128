import React from 'react';
import { makeStyles } from '@material-ui/core';
import WisetekNewsVol1part1 from '../../../../images/WisetekNewsVol1part1.png'
import WisetekNewsVol1part2 from '../../../../images/WisetekNewsVol1part2.png'
import WisetekNewsVol1part3 from '../../../../images/WisetekNewsVol1part3.png'
import WisetekNewsVol1part4 from '../../../../images/WisetekNewsVol1part4.png'
import WisetekNewsVol1part5 from '../../../../images/WisetekNewsVol1part5.png'
import WisetekNewsVol1part6 from '../../../../images/WisetekNewsVol1part6.png'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: '5%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '70%',
        borderRadius: 10,
    },
}));

export default function WisetekNewsVol1() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <div className={classes.textCentered}>
                <img className={classes.media} src={WisetekNewsVol1part1} alt="WisetekNewsVol1part1" />

                <img className={classes.media} src={WisetekNewsVol1part2} alt="WisetekNewsVol1part2" />

                <img className={classes.media} src={WisetekNewsVol1part3} alt="WisetekNewsVol1part3" />

                <img className={classes.media} src={WisetekNewsVol1part4} alt="WisetekNewsVol1part4" />

                <img className={classes.media} src={WisetekNewsVol1part5} alt="WisetekNewsVol1part5" />

                <img className={classes.media} src={WisetekNewsVol1part6} alt="WisetekNewsVol1part6" />
            </div>

        </div>
    )
}