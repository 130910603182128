import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { auth, db, functions } from '../../../config/fbConfig';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import moment from 'moment';
import { AuthContext } from '../../../contexts/AuthContext';
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { Button, Card, Grid, TextField, makeStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const useStyles = makeStyles(theme => ({
    card: {
        padding: 100
    },

}))

export default function WiseAcademyCerts() {
    const classes = useStyles();
    const { roles } = useContext(AuthContext)

    const [completedCourses, setCompletedCourses] = useState({
        columns: [
            { title: 'Location', field: 'Location' },
            { title: 'Site', field: 'Site' },
            { title: 'Category', field: 'Category' },
            { title: 'Course Title', field: 'Title' },
            { title: 'Date', field: 'displayDate' },
            { title: 'Completed by', field: 'TraineeFullName' },
            { title: 'Supervised by', field: 'TraineeManagerFullName' },
        ],
        data: [],
    });

    const [inProgressCourses, setInProgressCourses] = useState({
        columns: [
            { title: 'Location', field: 'Location' },
            { title: 'Site', field: 'Site' },
            { title: 'Category', field: 'Category' },
            { title: 'Course Title', field: 'Title' },
            { title: 'Progress %', field: 'TotalProgress' },
            { title: 'Trainee Name', field: 'TraineeFullName' },
            { title: 'Supervisor Name', field: 'TraineeManagerFullName' },
            { title: 'Trainee Email', field: 'TraineeEmail' },
            { title: 'Supervisor Email', field: 'TraineeManagerEmail' },
            { title: 'Last Saved At', field: 'lastSaved' },
        ],
        data: [],
    })

    const [resendEmail, setResendEmail] = useState({
        isResend: true,
        Title: '',
        TraineeFullName: '',
        TraineeEmail: '',
        Date: moment(new Date()).format('dd MMMM YYYY'),
        DateD: new Date(),
        cert: 'https://storage.googleapis.com/wisetekappappspotcom/WiseAcademy/CourseCerts/WiseAcademyCertificateV2.pdf',
        fontURL: 'https://storage.googleapis.com/wisetekappappspotcom/WiseAcademy/CourseFonts/MySoul-Regular.ttf'
    })

    const [isSending, setIsSending] = useState(false)

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function getUsersThatCompletedCourse() {
        const teamManagers = []
        db.collection('wiseAcademyCompleted').orderBy('Date', 'desc').get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    teamManagers.push({
                        ...doc.data(),
                        id: doc.id,
                        displayDate: moment(doc.data().Date.toDate()).format('DD MMM YYYY')
                    });
                })
                //console.log(teamManagers)
                setCompletedCourses({
                    ...completedCourses,
                    data: teamManagers
                })
            })
            .catch(error => {
                console.log("Error getting data for completed courses: ", error);
            });
    }
    function getInProgressCourses() {
        const inProgressCoursesData = []
        db.collection('wiseAcademyInProgress').get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    inProgressCoursesData.push({
                        ...doc.data(),
                        id: doc.id,
                        TotalProgress: doc.data().TotalProgress + '%',
                        lastSaved: doc.data().lastSaved ? moment(doc.data().lastSaved.toDate()).format('YYYY-MM-DD') : 'N/A',
                    })
                })
                setInProgressCourses({
                    ...inProgressCourses,
                    data: inProgressCoursesData
                })
            })
            .catch(error => {
                console.log('Error getting data for courses in progress: ', error);
            })
    }


    useEffect(() => {
        getUsersThatCompletedCourse();
        getInProgressCourses();
        return () => { }
    }, [])


    const handleChange = (e) => {
        setResendEmail({ ...resendEmail, [e.target.name]: e.target.value })
    }
    const handleChangeDate = (newDate) => {
        setResendEmail({ ...resendEmail, DateD: new Date(newDate), Date: moment(new Date(newDate)).format('DD MMMM YYYY') })
    }

    const sendEmailCertification = (data) => {
        const callable = functions.httpsCallable('sendWiseAcademyCertification');
        return callable(data, auth)
            .then(resp => {
                console.log('Certification successfully sent by email.')
                console.log('resp', resp)
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Certification successfully sent by email.',
                })
                setIsSending(false)
            })
            .catch((err) => {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: err,
                })
                setIsSending(false)
            })
    }
    const handleCertResend = async (e) => {
        e.preventDefault();
        if (!isSending) {
            setIsSending(true)
            try {
                // Fetch an existing PDF document
                const url = resendEmail.cert
                const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
                // Load a PDFDocument from the existing PDF bytes
                const pdfDoc = await PDFDocument.load(existingPdfBytes)
                // Register a `fontkit` instance with `PDFDocument.registerFontkit(...)` before embedding custom fonts.
                pdfDoc.registerFontkit(fontkit);
                const fontUrl = resendEmail.fontURL
                const mySoulBytes = await fetch(fontUrl).then(res => res.arrayBuffer())
                const mySoulFont = await pdfDoc.embedFont(mySoulBytes);
                // Get the form containing all the fields
                const form = pdfDoc.getForm()

                // Get all fields in the PDF by their names
                const trainee = form.getTextField('participantHeader')
                const courseTitle = form.getTextField('courseTitle')
                // const supervisor = form.getTextField('supervisorSignature')
                const courseDate = form.getTextField('courseDate')

                trainee.setText(resendEmail.TraineeFullName)
                courseTitle.setText(resendEmail.Title)
                // supervisor.setText(resendEmail.TraineeManagerFullName)   // removed supervisor population and in template this field is "Thomas Delahunty"
                courseDate.setText(resendEmail.Date)

                // update appearances
                trainee.setFontSize(40);
                trainee.updateAppearances(mySoulFont);
                // // form flatten is available from v1.16.0 and it makes form read-only (not editable)
                // form.flatten();
                // Serialize the PDFDocument to base64
                const pdfBytes = await pdfDoc.saveAsBase64()

                var files = []
                var fileToSend = {
                    content: pdfBytes,
                    filename: 'WiseAcademy_Certification.pdf',
                    type: 'application/pdf',
                    disposition: "attachment"
                }
                files.push(fileToSend)
                // formData.Files = files
                resendEmail.Files = files
                // console.log(resendEmail)
                sendEmailCertification(resendEmail)


            } catch (error) {
                // Handle the error, e.g., show a message to the user
                console.error("Error fetching or processing PDF:", error);
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: `Error fetching or processing PDF: ${error}`,
                })
                setIsSending(false)
            }
        }
    }


    return (
        <div style={{ margin: 15 }}>
            <MaterialTable
                title={'Completed WiseAcademy courses'}
                columns={completedCourses.columns}
                data={completedCourses.data}
                // options={{ actionsColumnIndex: -1 }}
                options={{ addRowPosition: 'first', exportButton: true, filtering: true, pageSize: 5, pageSizeOptions: [5, 15, 50, { value: completedCourses.data.length, label: 'All' }] }}//paging: true, pageSize: 100, pageSizeOptions: [5, 10, 100] 
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getUsersThatCompletedCourse();
                            return () => { }
                        },
                    }
                ]}
            />
            <br /><br />
            <MaterialTable
                title={'WiseAcademy courses in progress'}
                columns={inProgressCourses.columns}
                data={inProgressCourses.data}
                // options={{ actionsColumnIndex: -1 }}
                options={{ addRowPosition: 'first', exportButton: true, filtering: true, pageSize: 5, pageSizeOptions: [5, 15, 50, { value: inProgressCourses.data.length, label: 'All' }] }}//paging: true, pageSize: 100, pageSizeOptions: [5, 10, 100] 
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getInProgressCourses();
                            return () => { }
                        },
                    }
                ]}
            />

            {roles.includes('admin') ?
                <div>
                    <br /><br />
                    <Card className={classes.card}>
                        <p><b>Note:</b> Here you can resend certificate to the users, please fill in Title, Trainee Full Name, Trainee Email and Completion Date.</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <form onSubmit={handleCertResend}>

                                <TextField
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    name='Title'
                                    label="Course Title"
                                    fullWidth
                                    required
                                    helperText="up to 50 characters"
                                    inputProps={{ maxLength: 50 }}
                                    value={resendEmail.Title}
                                    onChange={handleChange} />
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    name='TraineeFullName'
                                    label="Trainee Full Name"
                                    fullWidth
                                    required
                                    helperText="up to 25 characters"
                                    inputProps={{ maxLength: 25 }}
                                    value={resendEmail.TraineeFullName}
                                    onChange={handleChange} />
                                <TextField
                                    type="email"
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    name='TraineeEmail'
                                    label="Trainee Email"
                                    fullWidth
                                    required
                                    // autoComplete='off'
                                    helperText="up to 50 characters"
                                    inputProps={{ maxLength: 50 }}
                                    value={resendEmail.TraineeEmail}
                                    onChange={handleChange} />

                                <Grid container direction="column" justifyContent="center" alignItems="center" >
                                    <Grid item >
                                        <DatePicker
                                            className={classes.datepickerMargin}
                                            margin="normal"
                                            id="Date"
                                            label="Completion Date"
                                            name="DateD"
                                            format="dd MMMM yyyy"
                                            // error // makes text red
                                            // helperText="Inclusive in your holiday request. Check before submitting **"
                                            required
                                            value={resendEmail.DateD}
                                            onChange={handleChangeDate}
                                        />
                                    </Grid>
                                    <br /><br /><br />

                                    <Button
                                        disabled={isSending}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    // startIcon={<PlayArrowRoundedIcon />}
                                    >
                                        {isSending ? 'Sending...' : 'Resend Certification'}
                                    </Button>
                                </Grid>
                            </form>
                        </MuiPickersUtilsProvider>
                    </Card>
                </div>
                : null}
            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    );
}
