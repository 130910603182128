import React from 'react';
import { makeStyles } from '@material-ui/core';
import wisetekStoreLIVE from '../../../../images/WeAreLive.gif'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '90%',
        borderRadius: 10,
    },
}));

export default function WisetekStoreLive() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            {/* 1ˢᵗ, 2ⁿᵈ, 3ʳᵈ, 4ᵗʰ */}
            <header >
                <h2 className={classes.textCentered}>WisetekStore.com is LIVE! 4ᵗʰ  October 2021</h2>
            </header>

            <br />
            <div className={classes.textCentered}>
                <img className={classes.media} src={wisetekStoreLIVE} alt="WisetekStore is LIVE!" />
                {/* <video className={classes.media} src={BusinessUpdateVideoVol2}
                    type="video/mp4"
                    poster={posterVol2}
                    controls
                    controlsList="nodownload">
                    Your browser does not support HTML video.
                </video> */}
            </div>


            {/* <h4 className={classes.textCentered}>Recording of Employee Business Update 28ᵗʰ April 2021</h4> */}


            <article >
                <h5 >Dear Wisetek Employees,</h5>
                <section>
                    We are beyond thrilled to announce that after months and months of hard work from all involved, Wiseteks brand new eCommerce platform, Wisetek Store, has launched today!
                </section>
                <br />
                <section>
                    Wisetek Store was created to give our customers access to high quality, reliable and affordable refurbished devices, to an “as new” standard. Refurbished devices are 100% more sustainable than buying a brand-new device and so this new site serves as an outlet to encourage consumers to choose refurbished over new devices. To mark the new e-commerce business, we are offering Wisetek employees a €20 discount code on orders over €100 that can be used any time in the month of October! Simply use discount code: <b>Employee20</b> at the checkout and please ensure to use the first and last name that appears on your Wisetek employee badge to activate your discount code.

                </section>
                <br />



            </article>

            <section>
                <b>Sean Sheehan, CEO of Wisetek</b> said “The launch of the Wisetek Store is an exciting development for Wisetek. This new brand will be focussed on the sale of IT equipment to consumers. Wisetek has traditionally focussed on the business-to-business market, and this new venture is an indication of the rapid pace we have experienced in the growth of the company. We are continually exploring new ideas and markets while maintaining the high quality of service our customers are used to.”
            </section>
            <br />
            {/* <h2 className={classes.textCentered}>
                <a href="https://www.internationalwomensday.com/Influencers">Please see some statements from some progressive leaders (including some of our customers) in support for a #EachforEqual world</a>
            </h2> */}
            <section>
                We would also encourage employees to please support Wisetek Store by
                <ul>
                    <li>Checking out our website: <a href="https://www.wisetekstore.com" target="_blank" rel="noopener noreferrer">www.WisetekStore.com</a></li>
                    <li>And by following us on social media: <b>Instagram</b> <a href="https://www.instagram.com/wisetek_store/" target="_blank" rel="noopener noreferrer">@wisetek_store</a> and <b>Facebook</b> <a href="https://www.facebook.com/Wisetek-Store-104782598390771" target="_blank" rel="noopener noreferrer">Wisetek Store</a></li>
                </ul>
            </section>

            <br />
            <p>Kind Regards,<br /><br />

                Eloïse Tobler<br />
                {/* CEO<br /> */}
                Marketing | Wisetek™<br />
            </p>
            <br />

        </div >
    )
}