import React, { useState } from 'react';
import { db } from '../../../config/fbConfig';
import { Button, makeStyles, TextField, Typography, Grid } from '@material-ui/core';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        padding: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    marginOne: {
        margin: theme.spacing(1),
    },
    marginTwo: {
        margin: theme.spacing(2),
    }
}));

export default function ReassignHols() {
    const classes = useStyles();

    const [holidayRequestUpdate, setHolidayRequestUpdate] = useState({
        ManagerFullName: '',
        ManagerEmail: '',
        UserEmail: ''
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });


    const handleChangeNewManager = (e) => {
        setHolidayRequestUpdate({
            ...holidayRequestUpdate,
            [e.target.name]: e.target.value,
        })
    }
    function clearholidayRequestUpdate() {
        setHolidayRequestUpdate({
            ...holidayRequestUpdate,
            ManagerFullName: '',
            ManagerEmail: '',
            UserEmail: ''
        })
    }

    function reasignHolidaysToNewTeamManager(e) {
        e.preventDefault()
        db.collection("holidayRequests")
            .where("UserEmail", "==", holidayRequestUpdate.UserEmail).get()
            .then(snap => {
                if (snap.empty) {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: 'There was no records for ' + holidayRequestUpdate.UserEmail,
                    })
                    return
                }
                snap.forEach(req => {
                    db.collection("holidayRequests")
                        .doc(req.id).update({
                            Manager: holidayRequestUpdate.ManagerFullName,
                            ManagerEmail: holidayRequestUpdate.ManagerEmail,
                        })
                        .then(success => {
                            setSnackbar({
                                open: true,
                                severity: 'success',
                                message: 'Updated all holidays for ' + holidayRequestUpdate.UserEmail,
                            })
                            clearholidayRequestUpdate()
                        })
                        .catch(err => {
                            setSnackbar({
                                open: true,
                                severity: 'error',
                                message: err,
                            })
                        })
                })
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: err,
                })
            })
    }

    return (
        <div>
            <Typography variant="h6" className={classes.center}>
                Reassign holiday requests to new team manager
            </Typography>
            <Typography variant="caption" className={classes.center}>
                To reassign all holiday requests for provided user email address we need to tell exact Full Name of the team manager and his email address. Clicking the reassign button will go to db and look for all holiday requests for given "User Email" if it finds it then it will update the "Manager" and "ManagerEmail" fields.
            </Typography>
            <br />
            <form id="reasignHolidaysToNewTeamManager" onSubmit={reasignHolidaysToNewTeamManager} >
                <Grid container direction="column" justifyContent="center" alignItems="center">

                    <TextField
                        className={classes.marginOne}
                        size="small"
                        name="ManagerFullName"
                        id="ManagerFullName"
                        label="New Manager Full Name"
                        variant="outlined"
                        value={holidayRequestUpdate.ManagerFullName}
                        required
                        inputProps={{ maxLength: 50 }}
                        onChange={handleChangeNewManager}
                    />

                    <TextField
                        className={classes.marginOne}
                        size="small"
                        name="ManagerEmail"
                        id="ManagerEmail"
                        label="New Manager Email"
                        variant="outlined"
                        value={holidayRequestUpdate.ManagerEmail}
                        required
                        inputProps={{ maxLength: 50 }}
                        onChange={handleChangeNewManager}
                    />

                    <TextField
                        className={classes.marginOne}
                        size="small"
                        name="UserEmail"
                        id="UserEmail"
                        label="User Email"
                        variant="outlined"
                        value={holidayRequestUpdate.UserEmail}
                        required
                        inputProps={{ maxLength: 50 }}
                        onChange={handleChangeNewManager}
                    />

                    <Button className={classes.marginTwo}
                        type="submit"
                        variant="contained"
                        color="secondary">
                        Re asign holiday requests to new team manager
                    </Button>

                </Grid>
            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    );
}