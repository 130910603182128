import { CssBaseline, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import KPIsCustomerSLAforUS from './KPIsCustomerSLAforUS';
import UploadCustomerSLAforUS from './UploadCustomerSLAforUS';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
}));

const MetricsCustomerSLAforUS = (props) => {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={36} openMetricsColapse={true} openMetricsCustomerSLAColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Metrics - US SLA</h1> */}

                            {roles.includes('teamManager') || roles.includes('adminAM') || roles.includes('admin') ?
                                <div><UploadCustomerSLAforUS /><br /></div>
                                : null}

                            <KPIsCustomerSLAforUS />

                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}
export default MetricsCustomerSLAforUS