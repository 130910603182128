import React, { useState, useEffect, useContext } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { db } from '../../../../config/fbConfig';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import TicketFacilitiesDetails from './TicketFacilitiesDetails';
import { AuthContext } from '../../../../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    minWidth: {
        minWidth: '200px'
    },
    body: {
        padding: '50px'
    },
    leftMargin: {
        marginLeft: '30px',
    },
}))

export default function TicketsFacilities() {
    const classes = useStyles();

    const { firstName } = useContext(AuthContext);

    const [ticketsFacilities, setTicketsFacilities] = useState({
        columns: [
            { title: 'Raised Date', field: 'dateRaised', type: 'date', editable: 'never', filtering: false },
            { title: 'Closed Date', field: 'dateClosed', editable: 'never', filtering: false },
            { title: 'Status', field: 'Status', editable: 'never' },
            { title: 'Ticket Type', field: 'TicketType', editable: 'never' }, //filtering: false
            { title: 'Category', field: 'Category', editable: 'never' },
            { title: 'Department', field: 'Department', editable: 'never' },
            { title: 'Assigned To', field: 'AssignedTo', editable: 'never' },
            { title: 'Raised By', field: 'raisedBy', editable: 'never' },
            // { title: 'Week', field: 'WeekNo', editable: 'never' },
            // { title: 'Record UID', field: 'RecordUID', editable: 'never', filtering: false },
            // { title: 'Department', field: 'Department', editable: 'never' },
            // { title: 'Division', field: 'Division', editable: 'never' },
            // { title: 'Fix Description', field: 'FixDescription', editable: 'never' },
            // { title: 'Site', field: 'Site', editable: 'never' },
            // { title: 'Description', field: 'Description', cellStyle: { whiteSpace: "nowrap", maxWidth: "50px" }, editable: 'never' },
        ],
        data: [],
        openTickets: 0,
        closedTickets: 0,
    });

    const allTicketsFacilities = [];
    const allOpenTicketsFacilities = [];
    const allClosedTicketsFacilities = [];

    function getTicketsFacilities() {

        db.collection('ticketsFacilities')
            .orderBy("dateRaised", "desc")
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    var completionDate = ''
                    var startedDate = moment(doc.data().dateRaised.toDate()).format('DD-MMM-YYYY')
                    if (doc.data().dateClosed === '') {
                        // console.log('invalid date')
                    }
                    else {
                        completionDate = moment.utc(doc.data().dateClosed.toDate()).format('DD-MMM-YYYY')
                        // console.log('valid date:', completionDate)
                    }
                    // checking if FilesURLs are undefined from outlook add-in
                    if (doc.get('FilesURLs') === undefined) {
                        console.log('there are FilesURLs => ' + doc.id, doc.data())
                        allTicketsFacilities.push({
                            ...doc.data(),
                            RecordUID: doc.id,
                            dateRaised: startedDate,
                            dateClosed: completionDate,
                            FilesURLs: [],
                        });
                        if (doc.data().Status === 'Open' || doc.data().Status === 'WIP - Scope' || doc.data().Status === 'WIP - Dev' || doc.data().Status === 'WIP - UAT' || doc.data().Status === 'WIP - Production Cut in' || doc.data().Status === 'On Hold' || doc.data().Status === 'Aknowledge') {
                            allOpenTicketsFacilities.push({
                                ...doc.data(),
                                RecordUID: doc.id,
                                dateRaised: startedDate,
                                // dateClosed: '',
                                FilesURLs: [],
                            });
                        }
                        if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                            allClosedTicketsFacilities.push({
                                ...doc.data(),
                                RecordUID: doc.id,
                                dateRaised: startedDate,
                                dateClosed: completionDate,
                                FilesURLs: [],
                            });
                        }
                        db.collection("ticketsFacilities").doc(doc.id).update({
                            FilesURLs: []
                        })
                    }
                    else {
                        allTicketsFacilities.push({
                            ...doc.data(),
                            RecordUID: doc.id,
                            dateRaised: startedDate,
                            dateClosed: completionDate,
                        });
                        if (doc.data().Status === 'Open' || doc.data().Status === 'WIP - Scope' || doc.data().Status === 'WIP - Dev' || doc.data().Status === 'WIP - UAT' || doc.data().Status === 'WIP - Production Cut in' || doc.data().Status === 'On Hold' || doc.data().Status === 'Aknowledge') {
                            allOpenTicketsFacilities.push({
                                ...doc.data(),
                                RecordUID: doc.id,
                                dateRaised: startedDate,
                                // dateClosed: '',
                            });
                        }
                        if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                            allClosedTicketsFacilities.push({
                                ...doc.data(),
                                RecordUID: doc.id,
                                dateRaised: startedDate,
                                dateClosed: completionDate,
                            });
                        }
                    }
                })
                setTicketsFacilities({
                    ...ticketsFacilities,
                    data: allTicketsFacilities,
                    openTickets: allOpenTicketsFacilities.length,
                    closedTickets: allClosedTicketsFacilities.length,
                })
            })
            .catch(function (error) {
                console.log("Error getting Facilities tickets: ", error);
            });
    }


    useEffect(() => {
        getTicketsFacilities()
        return () => { }
    }, []);

    return (
        <div >

            <MaterialTable
                title={"Facilities Tickets"}
                columns={ticketsFacilities.columns}
                data={ticketsFacilities.data}
                detailPanel={rowData => {
                    // console.log(rowData)
                    return (
                        <TicketFacilitiesDetails ticketData={rowData} />
                    )
                }}
                options={{
                    detailPanelType: "single", actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50]
                }}//, { value: ticketsFacilities.data.length, label: 'All' }] }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                actions={
                    [{
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getTicketsFacilities()
                            return () => { }
                        },
                    }]}
                // editable={{
                //     // onRowAdd: newData =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             resolve();
                //     //             setIssueError(prevState => {
                //     //                 const data = [...prevState.data];
                //     //                 data.push(newData);
                //     //                 return { ...prevState, data };
                //     //             });
                //     //         }, 600);
                //     //     }),
                //     // onRowUpdate: (newData, oldData) =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             if (oldData) {
                //     //                 setTicketsFacilities(prevState => {
                //     //                     const data = [...prevState.data];
                //     //                     data[data.indexOf(oldData)] = newData;
                //     //                     if (newData.Status == 'Completed' || newData.Status == 'Closed - Not Needed') {
                //     //                         var date = new Date();
                //     //                         var today = date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();
                //     //                         newData.ClosedDate = today;
                //     //                     }
                //     //                     console.log('Updated issue error ticket in DB')
                //     //                     // Update data
                //     //                     // if closed or not needed then add closing date
                //     //                     db.collection("issueErrorTickets").doc(newData.ticketId).update({
                //     //                         Status: newData.Status,
                //     //                         AssignedIT: newData.AssignedIT,
                //     //                         RootCauseCategory: newData.RootCauseCategory,
                //     //                         FixType: newData.FixType,
                //     //                         FailureCategory: newData.FailureCategory,
                //     //                         FixUpdateDescription: newData.FixUpdateDescription,
                //     //                         ClosedDate: newData.ClosedDate,
                //     //                     });
                //     //                     resolve();
                //     //                     return { ...prevState, data };
                //     //                 })
                //     //             }
                //     //         }, 600)
                //     //     }),
                //     // onRowDelete: oldData =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             resolve();
                //     //             setIssueError(prevState => {
                //     //                 const data = [...prevState.data];
                //     //                 data.splice(data.indexOf(oldData), 1);
                //     //                 return { ...prevState, data };
                //     //             });
                //     //         }, 600);
                //     //     }),
                // }}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div >
                                <p className={classes.leftMargin}>Hey {firstName}! We need to focus on those {ticketsFacilities.openTickets} open tickets.</p>
                            </div>
                        </div>
                    ),
                }}
            />
        </div>
    );
}
