import { CssBaseline, InputBase, makeStyles, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { db } from '../../../../config/fbConfig';
import { AuthContext } from '../../../../contexts/AuthContext';
import { DataContext } from '../../../../contexts/DataContext';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import SideMenuV2 from '../../SideMenus/SideMenuV2';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    overrides: {
        MuiTableCell: {
            root: {
                paddingTop: '2px',
                paddingBottom: '2px',
                "&:last-child": {
                    paddingRight: '2px',
                    paddingLeft: '2px'
                }
            }
        }
    }
}));

export default function IST27001() {
    const classes = useStyles();

    const { addIST27001, updateIST27001, deleteFromIST27001 } = useContext(DataContext);

    const { isAuthenticated, loading, roles, displayName } = useContext(AuthContext);

    const [questionAnswer, setQuestionAnswer] = useState({
        columns: [
            {
                title: '27001 Section#', field: 'Section', initialEditValue: 'Information Security Policies (A5)', lookup: {
                    'Information Security Policies (A5)': 'Information Security Policies (A5)',
                    'Organization of information security (A6)': 'Organization of information security (A6)',
                    'Human Resource Security (A7)': 'Human Resource Security (A7)',
                    'Asset Management (A8)': 'Asset Management (A8)',
                    'Access Control (A9)': 'Access Control (A9)',
                    'Cryptography (A10)': 'Cryptography (A10)',
                    'Physical and Environmental Security (A11)': 'Physical and Environmental Security (A11)',
                    'Operations Security (A12)': 'Operations Security (A12)',
                    'Communications Security (A13)': 'Communications Security (A13)',
                    'Systems Acquisition, Development and Maintenance (A14)': 'Systems Acquisition, Development and Maintenance (A14)',
                    'Supplier relationships (A15)': 'Supplier relationships (A15)',
                    'Incident Management (A16)': 'Incident Management (A16)',
                    'Information Security aspects of business continuity management (A17)': 'Information Security aspects of business continuity management (A17)',
                    'Compliance (A18)': 'Compliance (A18)'
                }
            },
            {
                title: 'Section Sub-Category', field: 'SubSection', initialEditValue: '',
                render: rowData =>
                    <InputBase readOnly multiline maxRows={6} value={rowData.SubSection} style={{ minWidth: 300 }} />,
                editComponent: props =>
                    <TextField multiline maxRows={6}
                        value={props.rowData.SubSection}
                        onChange={(e) => { props.onChange(e.target.value) }}
                        style={{ minWidth: 300 }} inputProps={{ maxLength: 1000 }} />
            },
            {
                title: 'Question', field: 'Question', initialEditValue: '',
                render: rowData =>
                    <InputBase readOnly multiline maxRows={6} value={rowData.Question} style={{ minWidth: 300 }} />,
                editComponent: props =>
                    <TextField multiline maxRows={6}
                        value={props.rowData.Question}
                        onChange={(e) => { props.onChange(e.target.value) }}
                        style={{ minWidth: 300 }} inputProps={{ maxLength: 1000 }} />
            },
            {
                title: 'Response', field: 'Response', initialEditValue: '',
                render: rowData =>
                    <InputBase readOnly multiline maxRows={6} value={rowData.Response} style={{ minWidth: 400 }} />,
                editComponent: props =>
                    <TextField multiline maxRows={6}
                        value={props.rowData.Response}
                        onChange={(e) => { props.onChange(e.target.value) }}
                        style={{ minWidth: 400 }} inputProps={{ maxLength: 1000 }} />
            },
            { title: 'Is Compliant?', field: 'Compliant', initialEditValue: 'No', lookup: { 'Yes': 'Yes', 'No': 'No' } },
            {
                title: 'Pending Actions', field: 'PendingAction', initialEditValue: '',
                render: rowData =>
                    <InputBase readOnly multiline maxRows={6} value={rowData.PendingAction} style={{ minWidth: 300 }} />,
                editComponent: props =>
                    <TextField multiline maxRows={6}
                        value={props.rowData.PendingAction}
                        onChange={(e) => { props.onChange(e.target.value) }}
                        style={{ minWidth: 300 }} inputProps={{ maxLength: 1000 }} />
            },

            { title: 'Submitted By', field: 'raisedBy', initialEditValue: displayName, editable: 'never', hidden: true },
            { title: 'Updated By', field: 'updatedBy', initialEditValue: displayName, editable: 'never', hidden: true },
            { title: 'date created', field: 'dateCreated', editable: 'never', hidden: true },
            { title: 'date updated', field: 'dateUpdated', editable: 'never', hidden: true },
            { title: 'ID', field: 'id', editable: 'never', hidden: true },

        ],
        data: []
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const allISOdata = [];
    function getAllisoData() {
        db.collection('ist27001').orderBy('dateCreated', 'desc').get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    allISOdata.push({
                        ...doc.data(),
                        id: doc.id,
                        dateCreated: moment(doc.data().dateCreated.toDate()).format('DD-MMM-YYYY'),
                        dateUpdated: doc.data().dateUpdated ? moment(doc.data().dateUpdated.toDate()).format('DD-MMM-YYYY') : null,
                    });
                })
                setQuestionAnswer({
                    ...questionAnswer,
                    data: allISOdata
                })
            })
            .catch(function (error) {
                console.log("Error getting iso 27001 data: ", error);
            });
    }
    useEffect(() => {
        getAllisoData()
        return () => { }
    }, []);

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('memberISandT') || roles.includes('adminISandT') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={51} openISandTColapse={true} />

                            <div className={classes.main}>

                                <MaterialTable
                                    title={"ISO 27001 Questionnaire"}
                                    //tableRef={this.tableRef}
                                    columns={questionAnswer.columns}
                                    data={questionAnswer.data}
                                    options={{
                                        //actionsColumnIndex: -1,
                                        addRowPosition: "first",
                                        detailPanelType: "single",
                                        exportButton: true,
                                        filtering: true,
                                        pageSizeOptions: [5, 10, 50, questionAnswer.data.length === 0 ? 100 : { value: questionAnswer.data.length, label: 'All' }]
                                    }}
                                    actions={[
                                        {
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => getAllisoData(),
                                        }
                                    ]}
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    setQuestionAnswer(prevState => {
                                                        const data = [...prevState.data];
                                                        data.unshift(newData);

                                                        addIST27001(newData)
                                                            .then(success => {
                                                                // console.log('Added new questionAnswer to ENG DB')
                                                                //display success
                                                                setSnackbar({
                                                                    open: true,
                                                                    severity: 'success',
                                                                    message: success,
                                                                })
                                                                getAllisoData()
                                                            })
                                                            .catch(error => {
                                                                console.log(error)
                                                                //display error
                                                                reject()
                                                                setSnackbar({
                                                                    open: true,
                                                                    severity: 'error',
                                                                    message: error,
                                                                })
                                                            });
                                                        resolve();
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                // console.log(oldData);
                                                // console.log(newData);
                                                setTimeout(() => {
                                                    if (oldData) {
                                                        setQuestionAnswer(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;

                                                            // Update data
                                                            updateIST27001(newData)
                                                                .then(success => {
                                                                    console.log('Updated iso 27001 in DB')
                                                                    //display success
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'success',
                                                                        message: success,
                                                                    })
                                                                    getAllisoData()
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                    //display error
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'error',
                                                                        message: error,
                                                                    })
                                                                });
                                                            resolve();
                                                            return { ...prevState, data };
                                                        })
                                                    }
                                                }, 600)
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    deleteFromIST27001(oldData.id)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            setQuestionAnswer(prevState => {
                                                                const data = [...prevState.data];
                                                                data.splice(data.indexOf(oldData), 1);
                                                                return { ...prevState, data };
                                                            });
                                                            resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                }, 600);
                                            }),
                                    }}
                                />
                            </div>

                        </CssBaseline>
                        <Footer />

                        <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }

}
