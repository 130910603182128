import { Grid, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { DataContext } from '../../../../../contexts/DataContext';
import CalendarCard from './CalendarCard'
import marinaMarketLogo from '../../../../../images/MarinaMarketLogo1.png'
import wisetekVerticalLogo from '../../../../../images/WisetekLogoVerticalTM.png'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '30%',
        borderRadius: 10,
    },
    media1: {
        width: '20%',
        borderRadius: 10,
    },
}));
const allBookings = [
    { date: '2022-10-29', teamManager: 'John-Paul Colllllllinski werwrewerwrwerwerwr', plusOne: 'DarrenSki', isBookedForAM: true, isBookedForPM: true },
    { date: '2022-10-30', teamManager: 'John-Paul Collins', plusOne: 'DarrenSki', isBookedForAM: false, isBookedForPM: true },
    { date: '2022-10-31', teamManager: 'John-Paullll Collins', plusOne: 'DarrenSki', isBookedForAM: true, isBookedForPM: false },
    { date: '2022-10-31', teamManager: 'John-Paullll Collins', plusOne: 'DarrenSki', isBookedForAM: false, isBookedForPM: false },
]

//get all bookings and display it correctly

export default function MarinaMarketBooking() {
    const classes = useStyles();
    const { getMarinaMarketBookings } = useContext(DataContext)

    const [allBookings, setAllBookings] = useState([])

    useEffect(() => {
        getMarinaMarketBookings()
            .then(all => {
                setAllBookings(all)
            })
            .catch(err => {
                console.log(err)
            })
        return () => { }
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.textCentered}>
                <h4>Wisetek stall on Marina Market</h4>
                <br /><br />
                <img className={classes.media} src={marinaMarketLogo} alt="Marin Market Logo" />

                <img className={classes.media1} src={wisetekVerticalLogo} alt="Wisetek Logo" />
                <br /><br /><br />
                <p>Approching Christmas we will have a great oportunity to display what we do on a stall in <a href='https://www.marinamarket.ie/' target="_blank" rel="noopener noreferrer">Marina Market</a>, our goal is to increase <a href='https://www.wisetekstore.com/' target="_blank" rel="noopener noreferrer">WisetekStore</a> sales and promote our brand. Wisetek is kindly asking for your help to support this campaign. We will need coverage of one manager and one extra person to cover two sesions of 3,5h each over eight weekends starting from 5th November to 17th December.
                    <br />Below on the calendar you can book your place.... </p>
                <br /><br />
            </div>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">
                {allBookings.map((booking, index) => (
                    <div key={index}>
                        <CalendarCard booking={booking} setAllBookings={setAllBookings} />
                    </div>
                ))}
            </Grid>

            <br /><br />
            <p>Regards,<br /><br />

                Evelyn Wiacek<br />
                HR Manager – Ireland/UK | Wisetek™<br />
            </p>
            <br />
        </div>
    )
}
