import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import moment from 'moment';
import { db } from '../../../config/fbConfig';
import MaterialTable from 'material-table';
import { AuthContext } from '../../../contexts/AuthContext';
import AssignTasksDetails from './AssignTasksDetails';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    assignTasks: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    leftMargin: {
        marginLeft: '50px',
    },
    usersAvailable: {
        maxWidth: '60%',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}));

export default function AssignTasks() {
    const classes = useStyles();

    const { displayName, department, shiftWorker } = useContext(AuthContext);

    const [taskCategory, setTaskCategory] = useState({
        selected: { '': '' },
    });

    const [myTeam, setMyTeam] = useState({
        columns: [
            { title: 'Name', field: 'assignedTo', defaultSort: 'asc' },
            // { title: 'Approved Leave', field: 'holidaysDays', align: 'right' },
            { title: 'Approved Leave (days)', field: 'holidaysDays' },
            { title: 'Available (hours)', field: 'availableHours' },
            { title: 'Allocated (%)', field: 'allocatedPercent' },
            { title: 'Available (%)', field: 'availablePercent' },
            { title: 'assignedToFullName', field: 'assignedToFullName' },
        ],
        data: []
    })

    const [weeklyTasks, setWeeklyTasks] = useState({
        weekNo: moment().isoWeek(),
        columns: [],
        data: []
    });

    const [gotTeamDetails, setGotTeamDetails] = useState(false);

    //#region Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    function createTeam(data) {
        // console.log(data)
        var team = [];
        var sortedTeam = {};
        for (let i = 0; i < data.length; i++) {
            team.push({ key: data[i].id, name: data[i].assignedTo })
        }
        // sort by name
        var sorted = team.sort(function (a, b) {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        sorted.forEach(item => {
            sortedTeam[item.key] = item.name
        })
        // console.log('Team:', sortedTeam)
        return sortedTeam
    }

    // Get members for this teamManager and fill in two tables "Available this week" and "My team weekly Tasks"
    const getMyTeamDetails = async () => {
        var dataItemsUsers = db.collection("users")
            .where("holidayApprovingManager", "==", displayName)
        // .where("holidayApprovingManager", "==", "John-Paul Collins") // TODO
        var dataItemsHolidays = db.collection("holidayRequests")
            .where("Department", "==", department)
        var dataItemsTasks = db.collection("userTasks")
            .where("assignedBy", "==", displayName)
            // .where("assignedBy", "==", "John-Paul Collins") // TODO
            .where("weekNo", "==", weeklyTasks.weekNo)

        var departmentUsers = await dataItemsUsers.get()
        var departmentHolidays = await dataItemsHolidays.get()
        var departmentTasks = await dataItemsTasks.get()
        setGotTeamDetails(true)
        if (departmentUsers.empty || departmentHolidays.empty) {
            return
        }
        var usersData = [];

        departmentUsers.forEach(doc => {
            var user = {}
            user.id = doc.id
            user.assignedTo = doc.data().firstName + ' ' + doc.data().lastName
            user.holidaysDays = 0
            user.availableHours = 37.5
            user.allocatedPercent = 0
            user.availablePercent = 100
            usersData.push(user)
        })
        departmentHolidays.forEach(doc => {
            // console.log(doc.data())
            var holidaysThisWeek = 0;
            var holsStart = moment(doc.data().FromDate.toDate()).startOf('day');
            var holsEnd = moment(doc.data().ToDate.toDate()).endOf('day');
            // console.log('holiday start', holsStart)
            // console.log('holiday end', holsEnd)
            var monday = moment().day(1);
            var tuesday = moment().day(2);
            var wednesday = moment().day(3);
            var thursday = moment().day(4);
            var friday = moment().day(5);

            usersData.forEach(user => {
                if (user.id === doc.data().UserId && doc.data().Status === 'Approved') {

                    if (moment(monday).isBetween(holsStart, holsEnd)) {
                        holidaysThisWeek++;
                        // console.log(doc.data().FullName, 'has monday off this week')
                    }
                    if (moment(tuesday).isBetween(holsStart, holsEnd)) {
                        holidaysThisWeek++;
                        // console.log(doc.data().FullName, 'has tuesday off this week')
                    }
                    if (moment(wednesday).isBetween(holsStart, holsEnd)) {
                        holidaysThisWeek++;
                        // console.log(doc.data().FullName, 'has wednesday off this week')
                    }
                    if (moment(thursday).isBetween(holsStart, holsEnd)) {
                        holidaysThisWeek++;
                        // console.log(doc.data().FullName, 'has thursday off this week')
                    }
                    if (moment(friday).isBetween(holsStart, holsEnd)) {
                        holidaysThisWeek++;
                        // console.log(doc.data().FullName, 'has friday off this week')
                    }

                    if (holidaysThisWeek > 0) {
                        // console.log(doc.data().FullName, 'has', holidaysThisWeek, 'days of holidays this week')

                        for (let i = 0; i < usersData.length; i++) {
                            const elementId = usersData[i].id;
                            if (user.id === elementId) {
                                // console.log(usersData[i])
                                var totalHoursLeave = 0
                                var totalDaysLeave = 0
                                var availableHoursThisWeek = 0
                                var requestedHours = doc.data().HoursRequested;
                                // var requestedHours = 6;
                                if (shiftWorker) {
                                    // i dunnnnno ask JP
                                    totalHoursLeave = (holidaysThisWeek * 12);
                                    availableHoursThisWeek = 60 - totalHoursLeave - requestedHours;
                                    var hoursToDay = 0;
                                    switch (requestedHours) {
                                        case 0:
                                            hoursToDay = 0;
                                            break;
                                        case 2:
                                            hoursToDay = 0.16;
                                            break;
                                        case 4:
                                            hoursToDay = 0.32;
                                            break;
                                        case 6:
                                            hoursToDay = 0.5;
                                            break;
                                        case 8:
                                            hoursToDay = 0.66;
                                            break;
                                        case 10:
                                            hoursToDay = 0.82;
                                            break;
                                        case 12:
                                            hoursToDay = 1;
                                            break;
                                        default:
                                            break;
                                    }
                                    totalDaysLeave = (totalHoursLeave / 12) + hoursToDay;
                                }
                                else {
                                    totalHoursLeave = (holidaysThisWeek * 7.5);
                                    availableHoursThisWeek = 37.5 - totalHoursLeave - requestedHours;
                                    // hours calulation to days...
                                    var hoursToDay = 0;
                                    switch (requestedHours) {
                                        case 0:
                                            hoursToDay = 0;
                                            break;
                                        case 2:
                                            hoursToDay = 0.25;
                                            break;
                                        case 4:
                                            hoursToDay = 0.5;
                                            break;
                                        case 6:
                                            hoursToDay = 0.75;
                                            break;
                                        case 8:
                                            hoursToDay = 1;
                                            break;
                                        default:
                                            break;
                                    }
                                    totalDaysLeave = (totalHoursLeave / 7.5) + hoursToDay;
                                }
                                usersData[i].holidaysDays = totalDaysLeave
                                usersData[i].availableHours = availableHoursThisWeek
                            }
                        }
                    }
                }
            })
        })

        for (let i = 0; i < usersData.length; i++) {
            // console.log(usersData[i])
            const elementId = usersData[i].id;
            var userTasks = [];
            var totalAllocatedPercent = 0;
            var totalAllocatedHours = 0;
            var totalActualHours = 0;
            var hoursAvailable = []
            departmentTasks.forEach(task => {
                var taskDate = moment(task.data().dateCreated.toDate());

                if (taskDate.isoWeek() === moment().isoWeek()) {

                    if (task.data().assignedTo === elementId) {
                        userTasks.push(task.data())
                    }
                }
            })
            // console.log(userTasks)
            for (let j = 0; j < userTasks.length; j++) {
                totalAllocatedPercent = totalAllocatedPercent + userTasks[j].allocatedPercent
                // totalAllocatedHours = totalAllocatedHours + userTasks[j].allocatedHours
                totalAllocatedHours = userTasks[j].actualHours ? totalAllocatedHours + userTasks[j].actualHours : totalAllocatedHours + userTasks[j].allocatedHours
                totalActualHours = userTasks[j].actualHours ? totalActualHours + userTasks[j].actualHours : totalActualHours + 0
                // hoursAvailable.push(userTasks[j].availableHours)
            }
            // console.log(totalAllocatedHours, usersData[i].availableHours)
            usersData[i].allocatedPercent = Number(totalAllocatedPercent).toFixed(0)
            usersData[i].availableHours = (usersData[i].availableHours - totalAllocatedHours)
            usersData[i].availablePercent = usersData[i].availableHours === 0 ? 0 : (100 - Number(totalAllocatedPercent).toFixed(0))
            usersData[i].actualHours = Number(totalActualHours).toFixed(1)
        }

        // Calculations for second table "My team weekly task"
        var weeklyTasksData = [];
        var weeklyTasksColumns = [];
        // var taskCategory = taskCategoryISandT;
        var myTeamMembers = createTeam(usersData);

        departmentTasks.forEach(task => {
            var taskDate = moment(task.data().dateCreated.toDate());

            if (taskDate.year() === moment().year()) {
                task.dateCreated = moment(taskDate).format('YYMMDD');
                weeklyTasksData.push(task.data())
            }
        })
        weeklyTasksColumns = [
            { title: 'Name', field: 'assignedTo', lookup: myTeamMembers },
            // { title: 'Available (hours)', field: 'availableHours', editable: 'never', initialEditValue: 'calculating...' },
            { title: 'Task Category', field: 'taskCategory', lookup: taskCategory.selected },
            // { title: 'Allocated (hours)', field: 'allocatedHours', editable: 'never', initialEditValue: 'calculating...' },
            { title: 'Allocated (%)', field: 'allocatedPercent', },
            { title: 'Created', field: 'dateCreated', defaultSort: 'desc', hidden: true }
        ]
        setMyTeam({
            ...myTeam,
            data: usersData,
        });
        setWeeklyTasks({
            ...weeklyTasks,
            columns: weeklyTasksColumns,
            data: weeklyTasksData
        });
    }

    //get userTaskCategories from DB
    const getTaskCategories = () => {
        var selected = { '': '' }
        var allocatedPercent = { 0: '' }
        db.collection('userTaskCategories').get()
            .then(snap => {
                var taskCategories = null
                snap.forEach(doc => {
                    taskCategories = doc.data()
                })
                switch (department) {
                    case "CS/Logistics/Purchasing":
                        selected = taskCategories.taskCategoryCSLogisticsPurchasing
                        allocatedPercent = taskCategories.allocatedPercent
                        break;
                    case "Engineering":
                        selected = taskCategories.taskCategoryEngineering
                        allocatedPercent = taskCategories.allocatedPercent
                        break;
                    case "Finance":
                        selected = taskCategories.taskCategoryFinance
                        allocatedPercent = taskCategories.allocatedPercent
                        break;
                    case "HR":
                        selected = taskCategories.taskCategoryHR
                        allocatedPercent = taskCategories.allocatedPercent
                        break;
                    case "IS&T":
                        selected = taskCategories.taskCategoryISandT
                        allocatedPercent = taskCategories.allocatedPercent
                        break;
                    case "Marketing":
                        selected = taskCategories.taskCategoryMarketing
                        allocatedPercent = taskCategories.allocatedPercent
                        break;
                    case "Operations":
                        selected = taskCategories.taskCategoryOperations
                        allocatedPercent = taskCategories.allocatedPercent
                        break;
                    case "Quality and Compliance":
                        selected = taskCategories.taskCategoryQualityAndCompliance
                        allocatedPercent = taskCategories.allocatedPercent
                        break;
                    case "Sales":
                        selected = taskCategories.taskCategorySales
                        allocatedPercent = taskCategories.allocatedPercent
                        break;

                    default:
                        break;
                }
                setTaskCategory({
                    selected: selected,
                    allocatedPercent: allocatedPercent
                })
                // console.log(taskCategory)
            })
    };

    useEffect(() => {
        // console.log('loop!')
        getMyTeamDetails();
        getTaskCategories();
        return () => { }
    }, [gotTeamDetails]);

    return (

        <div>
            <MaterialTable
                title={"Available this week"}
                columns={myTeam.columns}
                data={myTeam.data}
                actions={
                    [{
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getMyTeamDetails()
                        },
                    }]
                }
                detailPanel={rowData => {
                    // console.log(rowData)
                    rowData.department = department;
                    return (
                        <AssignTasksDetails userData={rowData} tasksData={weeklyTasks.data} taskCategory={taskCategory} refreshTeamDetails={getMyTeamDetails} />
                    )
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
            // options={{ search: false }}
            >
            </MaterialTable>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    )
}
