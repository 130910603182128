import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Container, TextField, CssBaseline, Grid, Card, Button, InputBase, Select, MenuItem } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Loading from '../../Loading';
import { db } from '../../../../config/fbConfig';
import { DataContext } from '../../../../contexts/DataContext';
import Footer from '../../../Footer';
import moment from 'moment';
import MaterialTable from 'material-table';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import Barcode from 'react-barcode';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    marginAutoRight: {
        // display: 'none',
        marginRight: 'auto'
    },
    marginAutoLeft: {
        marginLeft: 'auto'
    },
    textFieldBox: {
        width: '100%',
        height: '55px',
        marginTop: '0px',
        marginBottom: '25px',
        // textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
    },
    textFieldBoxSelect: {
        width: '100%',
        height: '65px',
        marginTop: '0px',
        marginBottom: '15px',
        // textAlign: 'center',
        // border: '1px solid rgba(0, 0, 0, 0.25)',
        // borderRadius: '5px',
        // fontSize: '1.5em',
    },
    inputBaseSelect: {
        // padding: '10px',
        fontSize: '1.5em',
    },
    inputBaseScan: {
        padding: '10px',
        // fontSize: '1.5em',
    },
    outputBinText: {
        marginTop: '35px',
        marginBottom: '0px',
    },
    outputTrayText: {
        margin: '10px',
        fontSize: '3em',
        color: 'green'
    },
    clearInputButtonMargin: {
        marginRight: '15px'
    },
    labelText: {
        fontSize: '11px',
        marginBottom: '0px'
    },
    labelTextSpace: {
        fontSize: '11px',
        marginRight: '15px',
        marginBottom: '0px'
    },
    labelCard: {
        padding: '20px',
        // position: 'absolute',
        // // top: '50%',
        // // left: '50%',
        // // transform: 'translate(-50%, -50%)',
        // // transform: 'translate(50%, 50%)',
        // width: '40%',
        // maxHeight: '90%',
        // overflow: 'hidden',
        // // width: '265px', //70mm
        // // height: '151px', //40mm
        top: 0,
        left: 0,
        // // width: 400,
    },
    // sectionToPrint: {
    //     position: 'absolute',
    //     width: '265px', //70mm
    //     height: '151px', //40mm
    //     overflow: 'hidden',
    //     // left: '0',
    //     // top: '0',
    //     display: 'none'
    // },
    marginBottom: {
        marginBottom: '45px'
    },
    partsAndBinsTable: {
        marginTop: '25px',
        // paddingLeft: '25px',
        // paddingRight: '25px'
    },

}));

const productCodesVariation9100008079 = [
    { value: 'UKKB', label: 'UKKB' },
]
const productCodesVariation9100008093 = [
    { value: 'ADAPFATBAR', label: 'ADAPFATBAR' },
]
const productCodesVariation9100008100 = [
    { value: 'MOUSE', label: 'MOUSE' },
]
const productCodesVariation9100008107 = [
    { value: '', label: '' },
    { value: 'DSP2DSP', label: 'DSP2DSP' },
    { value: 'ETHCAB5', label: 'ETHCAB5' },
    { value: 'ETHCAB5A', label: 'ETHCAB5A' },
    { value: 'ETHCAB6', label: 'ETHCAB6' },
    { value: 'ETHCAB6A', label: 'ETHCAB6A' },
    { value: 'HDMIMICRO', label: 'HDMIMICRO' },
    { value: 'HDMIMINI', label: 'HDMIMINI' },
    { value: 'HDMISTD', label: 'HDMISTD' },
    { value: 'JKCABM2F', label: 'JKCABM2F' },
    { value: 'JKCABM2M', label: 'JKCABM2M' },
    { value: 'USB2SRL', label: 'USB2SRL' },
    { value: 'USBA2A', label: 'USBA2A' },
    { value: 'USBA2B', label: 'USBA2B' },
    { value: 'USBA2C', label: 'USBA2C' },
    { value: 'USBA2FA', label: 'USBA2FA' },
    { value: 'USBA2MICB', label: 'USBA2MICB' },
    { value: 'USBB2B', label: 'USBB2B' },
    { value: 'USBB2C', label: 'USBB2C' },
    { value: 'USBC2C', label: 'USBC2C' },
]
const productCodesVariation9100008108 = [
    { value: '', label: '' },
    { value: 'APPEXTCEU', label: 'APPEXTCEU' },
    { value: 'APPEXTCUK', label: 'APPEXTCUK' },
    { value: 'APPPLUGEU', label: 'APPPLUGEU' },
    { value: 'APPPLUGUK', label: 'APPPLUGUK' },
    { value: 'APPPLUGUS', label: 'APPPLUGUS' },
    { value: 'EUPLUGC', label: 'EUPLUGC' },
    { value: 'PCC13EU', label: 'PCC13EU' },
    { value: 'PCC13UK', label: 'PCC13UK' },
    { value: 'PCC5EU', label: 'PCC5EU' },
    { value: 'PCC5NEMA', label: 'PCC5NEMA' },
    { value: 'PCC5UK', label: 'PCC5UK' },
    { value: 'PCC7EU', label: 'PCC7EU' },
    { value: 'PCC7UK', label: 'PCC7UK' },

]
const productCodesVariation9100008986 = [
    { value: '', label: '' },
    { value: 'ADAPTYPEC', label: 'ADAPTYPEC' },
    { value: 'APPADAP85W', label: 'APPADAP85W' },
]
const productCodesVariation9100008993 = [
    { value: '', label: '' },
    { value: 'APPADAP60W', label: 'APPADAP60W' },
    { value: 'APPADAP61W', label: 'APPADAP61W' },
]
const productCodesVariation9100009000 = [
    { value: 'DOCKSTATIN', label: 'DOCKSTATIN' },
]
const productCodesVariationNEWPRODCODE = [
    { value: 'NEWPRODCODE', label: 'NEWPRODCODE' },
]

export default function GoogleAccessories() {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    const [scanInfo, setScanInfo] = useState({
        PartNo: '',
        PartNoBarcode: '',
        BinCode: '',
        BinCodeBarcode: '',
        ProductCode: '',
        ProductCodeBarcode: '',
        Description: '',
        Qty: 0,
    });

    const [showDropdown, setShowDropdown] = useState(false)

    const [productCodeVariation, setProductCodeVariation] = useState([])

    //Snackbar
    //#region 
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    const handleChange = (e) => {
        var desc = ''
        var productCode = ''
        switch (e.target.name) {
            case 'PartNo':
                setShowDropdown(false)
                switch (e.target.value) {
                    case '9100008079':
                        desc = 'USED KEYBOARDS'     // ONLY ONE OPTION
                        productCode = 'UKKB'
                        setProductCodeVariation(productCodesVariation9100008079)
                        setShowDropdown(true)
                        break;
                    case '9100008093':
                        desc = 'USED POWER ADAPTERS (Rest)'     // ONLY ONE OPTION
                        productCode = 'ADAPFATBAR'
                        setProductCodeVariation(productCodesVariation9100008093)
                        setShowDropdown(true)
                        break;
                    case '9100008100':
                        desc = 'USED MICE'      // ONLY ONE OPTION
                        productCode = 'MOUSE'
                        setProductCodeVariation(productCodesVariation9100008100)
                        setShowDropdown(true)
                        break;
                    case '9100008107':
                        desc = 'USED CABLES'        // HAS 19 OPTIONS
                        // productCode = 'DSP2DSP'     // SELECT FIRST
                        setProductCodeVariation(productCodesVariation9100008107)
                        setShowDropdown(true)
                        break;
                    case '9100008108':
                        desc = 'USED POWER CORDS'       // HAS 13 OPTIONS
                        // productCode = 'APPEXTCEU'       // SELECT FIRST
                        setProductCodeVariation(productCodesVariation9100008108)
                        setShowDropdown(true)
                        break;
                    case '9100008986':
                        desc = 'USED 85W POWER ADAPTERS'    // HAS 2 OPTIONS
                        // productCode = 'ADAPTYPEC'           // SELECT FIRST
                        setProductCodeVariation(productCodesVariation9100008986)
                        setShowDropdown(true)
                        break;
                    case '9100008993':
                        desc = 'USED 65W POWER ADAPTERS'    // HAS 2 OPTIONS
                        // productCode = 'APPADAP60W'          // SELECT FIRST
                        setProductCodeVariation(productCodesVariation9100008993)
                        setShowDropdown(true)
                        break;
                    case '9100009000':
                        desc = 'USED USB HUBS'          // ONLY ONE OPTION
                        productCode = 'DOCKSTATIN'
                        setProductCodeVariation(productCodesVariation9100009000)
                        setShowDropdown(true)
                        break;

                    default:
                        desc = 'NEW PART# / DESC UNAVAILABLE'
                        break;
                }
                setScanInfo({
                    ...scanInfo,
                    [e.target.name]: e.target.value.toUpperCase(),
                    PartNoBarcode: e.target.value.toUpperCase(),
                    Description: desc,
                    ProductCode: productCode,
                    ProductCodeBarcode: productCode,
                })
                break;
            case 'BinCode':
                setScanInfo({
                    ...scanInfo,
                    [e.target.name]: e.target.value.toUpperCase(),
                    BinCodeBarcode: e.target.value.toUpperCase(),
                })
                break;
            case 'ProductCode':
                setScanInfo({
                    ...scanInfo,
                    [e.target.name]: e.target.value.toUpperCase(),
                    ProductCodeBarcode: e.target.value.toUpperCase(),
                })
                break;

            default:
                setScanInfo({
                    ...scanInfo,
                    [e.target.name]: e.target.value.toUpperCase(),
                })
                break;
        }
    }

    const handleClearForm = (e) => {
        setScanInfo({
            ...scanInfo,
            PartNo: '',
            PartNoBarcode: '',
            BinCode: '',
            BinCodeBarcode: '',
            ProductCode: '',
            ProductCodeBarcode: '',
            Description: '',
            Qty: 0,
        })
        setShowDropdown(false)
        document.getElementById('PartNo').focus();
    }

    function handlePrintLabel(scaninfo) {
        var srcDocNoProductCode = '<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style> #barcodeText { font-family: "Helvetica", "Arial", sans-serif; font-size: 11px; margin-top: 0px; margin-bottom: 5px;} #barcode { font-family: "Libre Barcode 39"; font-size: 24px; margin: 0px 10px 0px 10px;}</style></head><body><div><p id="barcodeText">Part No: ' + scaninfo.PartNo + '</p><p id="barcode">*' + scaninfo.PartNo + '*</p><p id="barcodeText">Bin Code: ' + scaninfo.BinCode + '</p><p id="barcode">*' + scaninfo.BinCode + '*</p><p id="barcodeText">Desc: ' + scaninfo.Description + '</p><p id="barcodeText">Qty: ' + scaninfo.Qty + '</p></div></body></html>'

        //updated version with product code
        var srcDocWithProductCode = '<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style> #barcodeText { font-family: "Helvetica", "Arial", sans-serif; font-size: 11px; margin-top: 0px; margin-bottom: 5px;} #barcode { font-family: "Libre Barcode 39"; font-size: 24px; margin: 0px 10px 0px 10px;}#binCodeAndProductCodeText { font-family: "Helvetica", "Arial", sans-serif; font-size: 11px; margin-top: 0px; margin-bottom: 5px;}</style></head><body><div><p id="barcodeText">Part No: ' + scaninfo.PartNo + '</p><p id="barcode">*' + scaninfo.PartNo + '*</p><p id="binCodeAndProductCodeText">Bin Code: ' + scaninfo.BinCode + '</p><p id="barcode">*' + scaninfo.BinCode + '*</p><p id="binCodeAndProductCodeText">Product Code: ' + scaninfo.ProductCode + '</p><p id="barcode">*' + scaninfo.ProductCode + '*</p><p id="barcodeText">Desc: ' + scaninfo.Description + '&emsp;&emsp;Qty: ' + scaninfo.Qty + '</p></div></body></html>'


        var iframe = document.createElement('iframe');
        iframe.srcdoc = showDropdown ? srcDocWithProductCode : srcDocNoProductCode
        iframe.style.display = "none"
        document.body.appendChild(iframe)
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log('submiting scan')
    // }

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={55} openProductionColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Production - Wise Label</h1> */}

                            <Card>
                                <Container component="main" className={classes.main} maxWidth="xl">

                                    {/* <form id="googleAccessories" onSubmit={handleSubmit}> */}
                                    <form id="googleAccessories" >

                                        <h3 className={classes.center}>Wise Label</h3>
                                        <p><b>Note: </b>Part Number and Bin Code will be translated to capital letters for consistency purpose.</p>
                                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>

                                            <Grid container item xs={12} sm={6} spacing={1}>
                                                <p>Scan Part Number below</p>
                                                <div className={classes.textFieldBox}>
                                                    <InputBase
                                                        className={classes.inputBaseScan}
                                                        autoFocus={true}
                                                        // select
                                                        // required
                                                        autoComplete="off"
                                                        variant="standard"
                                                        fullWidth
                                                        id="PartNo"
                                                        label="Scan part number here"
                                                        name="PartNo"
                                                        value={scanInfo.PartNo}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 30 }}
                                                    >
                                                    </InputBase>
                                                </div>
                                                <p>Scan Bin Code below</p>
                                                <div className={classes.textFieldBox}>
                                                    <InputBase
                                                        className={classes.inputBaseScan}
                                                        // autoFocus={true}
                                                        // select
                                                        // required
                                                        autoComplete="off"
                                                        variant="standard"
                                                        fullWidth
                                                        id="BinCode"
                                                        label="Scan Bin Code here"
                                                        name="BinCode"
                                                        value={scanInfo.BinCode}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 30 }}
                                                    >
                                                    </InputBase>
                                                </div>
                                                {showDropdown &&
                                                    <>
                                                        <p>Select Product Code below</p>
                                                        <TextField
                                                            variant="outlined"
                                                            className={classes.inputBaseSelect}
                                                            select
                                                            fullWidth
                                                            id="ProductCode"
                                                            // label="Select Product Code here"
                                                            name="ProductCode"
                                                            value={scanInfo.ProductCode}
                                                            onChange={handleChange}
                                                            inputProps={{ maxLength: 30 }}
                                                        >
                                                            {productCodeVariation.map(option => (
                                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </>
                                                }

                                                <p style={{ marginTop: showDropdown ? '38px' : '' }}>Enter Quantity below</p>
                                                <div className={classes.textFieldBox}>
                                                    <InputBase
                                                        className={classes.inputBaseScan}
                                                        type='number'
                                                        // autoFocus={true}
                                                        // select
                                                        // required
                                                        autoComplete="off"
                                                        variant="standard"
                                                        fullWidth
                                                        id="Qty"
                                                        label="Enter Quantity here"
                                                        name="Qty"
                                                        value={scanInfo.Qty}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 30 }}
                                                    >
                                                    </InputBase>
                                                </div>
                                                <Button
                                                    className={classes.clearInputButtonMargin}
                                                    type="button"
                                                    size="small"
                                                    // className={classes.buttonHidden}
                                                    // variant="contained"
                                                    // color="primary"
                                                    variant="outlined"
                                                    onClick={handleClearForm}>
                                                    Clear Input
                                                </Button>

                                                <Button
                                                    className={classes.marginAutoRight}
                                                    type="button"
                                                    size="small"
                                                    // className={classes.buttonHidden}
                                                    // variant="contained"
                                                    // color="primary"
                                                    variant="outlined"
                                                    onClick={() => handlePrintLabel(scanInfo)}
                                                >
                                                    Label
                                                </Button>

                                                {/* <Button
                                                    type="submit"
                                                    size="small"
                                                    className={classes.marginAutoLeft}
                                                    variant="outlined"
                                                    color="primary">
                                                    Submit
                                                </Button> */}

                                                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                                                    <h2 className={classes.outputBinText}>{scanInfo.binHelperText}</h2>
                                                </Grid>
                                                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                                                    <p className={classes.outputTrayText}>
                                                        {scanInfo.binTrayText}
                                                    </p>
                                                </Grid>

                                            </Grid>

                                            <Grid container item xs={12} sm={6} spacing={1}>

                                                <Grid container item style={{ marginTop: 44 }}>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        disabled
                                                        // required
                                                        fullWidth
                                                        id="PartNo"
                                                        label="Part No"
                                                        name="PartNo"
                                                        value={scanInfo.PartNo}
                                                        onChange={handleChange}>
                                                    </TextField>
                                                </Grid>
                                                <Grid container item style={{ height: 60, marginBottom: 12 }} >

                                                    {scanInfo.PartNoBarcode ? <Barcode value={scanInfo.PartNoBarcode} format='CODE39' height={25} displayValue={false} />
                                                        :
                                                        <div style={{ height: 25 }}></div>}
                                                    {/* those are available attributes for Barcode
                                                        width: 2,
                                                        height: 100,
                                                        format: "CODE128",
                                                        displayValue: true,
                                                        fontOptions: "",
                                                        font: "monospace",
                                                        textAlign: "center",
                                                        textPosition: "bottom",
                                                        textMargin: 2,
                                                        fontSize: 20,
                                                        background: "#ffffff",
                                                        lineColor: "#000000",
                                                        margin: 10,
                                                        marginTop: undefined,
                                                        marginBottom: undefined,
                                                        marginLeft: undefined,
                                                        marginRight: undefined */}
                                                </Grid>
                                                <Grid container item style={{ marginTop: 8 }}>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        disabled
                                                        // required
                                                        fullWidth
                                                        id="BinCode"
                                                        label="Bin Code"
                                                        name="BinCode"
                                                        value={scanInfo.BinCode}
                                                        onChange={handleChange}   >
                                                    </TextField>
                                                </Grid>

                                                <Grid container item style={{ height: 60, marginBottom: 12 }}>
                                                    {scanInfo.BinCodeBarcode ? <Barcode value={scanInfo.BinCodeBarcode} format='CODE39' height={25} displayValue={false} /> :
                                                        <div style={{ height: 25 }}></div>}
                                                </Grid>

                                                {showDropdown &&
                                                    <>
                                                        <Grid container item style={{ marginTop: 8 }}>
                                                            <TextField
                                                                size="small"
                                                                variant="outlined"
                                                                disabled
                                                                // required
                                                                fullWidth
                                                                id="ProductCode"
                                                                label="Product Code"
                                                                name="ProductCode"
                                                                value={scanInfo.ProductCode}
                                                                onChange={handleChange}   >
                                                            </TextField>
                                                        </Grid>

                                                        <Grid container item style={{ height: 60, marginBottom: 12 }}>
                                                            {scanInfo.ProductCodeBarcode ? <Barcode value={scanInfo.ProductCodeBarcode} format='CODE39' height={25} displayValue={false} /> :
                                                                <div style={{ height: 25 }}></div>}
                                                        </Grid>
                                                    </>}

                                                <Grid container item style={{ marginTop: 8 }}>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        disabled
                                                        // required
                                                        fullWidth
                                                        id="Description"
                                                        label="Description"
                                                        name="Description"
                                                        value={scanInfo.Description}
                                                        onChange={handleChange}   >
                                                    </TextField>
                                                </Grid>
                                                <Grid container item >
                                                    <TextField
                                                        className={classes.marginBottom}
                                                        size="small"
                                                        variant="outlined"
                                                        disabled
                                                        // required
                                                        fullWidth
                                                        id="Qty"
                                                        label="Qty"
                                                        name="Qty"
                                                        inputProps={{ maxLength: 2 }}
                                                        value={scanInfo.Qty}
                                                        onChange={handleChange}   >
                                                    </TextField>
                                                </Grid>


                                            </Grid>
                                        </Grid>

                                    </form>

                                </Container>
                            </Card>

                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div >
            )
        }
    }
}