import React, { useContext, useEffect, useState } from 'react'
import { Grid, Chip, CssBaseline, LinearProgress, makeStyles, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import { DataContext } from '../../../../contexts/DataContext';
import { db } from '../../../../config/fbConfig';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table';
import ChartistGraph from 'react-chartist';
import ctPointLabels from 'chartist-plugin-pointlabels'
import Chartist from 'chartist';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MetricsChainOfCustodyVerificationWipDetails from './MetricsChainOfCustodyVerificationWipDetails';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
    },
    searchProgress: {
        // visibility: 'hidden',
        marginBottom: '15px'
    },
    graphHeader: {
        marginTop: '100px',
        textAlign: 'center'
    },
    green: {
        width: '20px',
        color: 'rgb(20, 150, 30)',  // green
        backgroundColor: 'rgb(20, 150, 30)',  // green
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    blue: {
        width: '20px',
        color: 'rgb(50, 50, 240)', // blue
        backgroundColor: 'rgb(50, 50, 240)', // blue
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },

}));

const MetricsChainOfCustodyVerificationWip = (props) => {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);
    const { addVerificationWip, updateVerificationWip, deleteVerificationWip } = useContext(DataContext);

    const todaysDate = new Date();

    const [verificationWip, setVerificationWip] = useState({
        // dateCreated:new Date(),
        // totalIE:0,
        // discrepantIE:0,
        // compliantIE:0,
        // compliantPercIE:0,
        // totalUS:0,
        // discrepantUS:0,
        // compliantUS:0,
        // compliantPercUS:0,
        // totalUK:0,
        // discrepantUK:0,
        // compliantUK:0,
        // compliantPercUK:0,
        columns: [
            { title: 'Date', field: 'dateCreated', type: 'date', filtering: false, initialEditValue: todaysDate },
            { title: 'IRE Total', field: 'totalIE', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'IRE Discrepant', field: 'discrepantIE', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'IRE Compliant', field: 'compliantIE', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'IRE Percent Compliant', field: 'compliantPercentIE', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'US Total', field: 'totalUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'US Discrepant', field: 'discrepantUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'US Compliant', field: 'compliantUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'US Percent Compliant', field: 'compliantPercentUS', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'UK Total', field: 'totalUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'UK Discrepant', field: 'discrepantUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'UK Compliant', field: 'compliantUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'UK Percent Compliant', field: 'compliantPercentUK', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            // {
            //     title: 'Attachment', field: 'attachments', filtering: false, render: rowData => <div className={classes.filePicker}>
            //         <input
            //             // accept="image/*"
            //             accept=".xlsx, .xls, .csv"
            //             className={classes.input}
            //             id="add-contained-button-file"
            //             multiple
            //             type="file"
            //             onChange={attachFile}
            //         />
            //         <label htmlFor="add-contained-button-file">
            //             <Tooltip className={classes.buttonRightNoMargin} title="Attach File">
            //                 <IconButton className={classes.marginRight}
            //                     variant="contained"
            //                     color="default"
            //                     component="span"
            //                 >
            //                     <AttachFileIcon />
            //                 </IconButton>
            //             </Tooltip>
            //         </label>
            //         <p>I was here</p>
            //         {attachments.files.length === 0 ? null : <p>Files attached: {attachments.files.length}</p>}
            //     </div>
            // },//hidden:true
        ],
        data: []
    });

    const [verWipGraphIE, setVerWipGraphIE] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    })
    const [verWipGraphUS, setVerWipGraphUS] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    })
    const [verWipGraphUK, setVerWipGraphUK] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    })
    const [verWipPercentageGraph, setVerWipPercentageGraph] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleGraphChangeClick = (e) => {

        var optionId = e.target.id

        switch (optionId) {
            case 'ie':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'us':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'uk':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;

            default:
                break;
        }
    }

    function getMaxFrom2dAarray(a) {
        return Math.max(...a.map(e => Array.isArray(e) ? getMaxFrom2dAarray(e) : e));
    }
    function getMinFrom2dAarray(a) {
        return Math.min(...a.map(e => Array.isArray(e) ? getMinFrom2dAarray(e) : e));
    }

    function calcWeeklyVars(data) {
        var verificationWipData = [];
        var verificationWipLabels = [];

        var verWipIE = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//totalIE
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //discrepantIE
        ]
        var verWipUS = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//totalUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //discrepantUS
        ]
        var verWipUK = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//totalUK
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //discrepantUK
        ]
        var verWipPercent = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//compliantPercIE
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//compliantPercUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //compliantPercUK
        ]

        data.forEach(doc => {
            // console.log(doc.id)
            var row = doc.data()
            row.id = doc.id
            row.compliantPercentIE = doc.data().compliantPercIE + '%'
            row.compliantPercentUS = doc.data().compliantPercUS + '%'
            row.compliantPercentUK = doc.data().compliantPercUK + '%'
            row.dateCreated = moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY')

            verificationWipData.push(row)
            verificationWipLabels.push(moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY'))
        })
        var labelsLength = verificationWipLabels.length
        if (labelsLength < 13) {
            // console.log('We have ' + labelsLength + ' labels')
            var noLabel = 'n/a'
            var calculatedLabels = []
            for (let i = labelsLength; i < 13; i++) {
                calculatedLabels.push(noLabel)
            }
            for (let j = 0; j < labelsLength; j++) {
                calculatedLabels.push(verificationWipLabels.pop())
            }
        }
        else {
            calculatedLabels = verificationWipLabels.reverse()
        }
        data.forEach(doc => {
            var positionDate = calculatedLabels.findIndex(dateCreated => dateCreated === moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY'))

            if (positionDate >= 0) {
                var totalIRELAND = doc.data().totalIE
                var discrepantIRELAND = doc.data().discrepantIE
                var totalUSA = doc.data().totalUS
                var discrepantUSA = doc.data().discrepantUS
                var totalUNITEDKINGDOM = doc.data().totalUK
                var discrepantUNITEDKINGDOM = doc.data().discrepantUK
                var percCompIE = doc.data().compliantPercIE
                var percCompUS = doc.data().compliantPercUS
                var percCompUK = doc.data().compliantPercUK
                verWipIE[0][positionDate] = totalIRELAND
                verWipIE[1][positionDate] = discrepantIRELAND
                verWipUS[0][positionDate] = totalUSA
                verWipUS[1][positionDate] = discrepantUSA
                verWipUK[0][positionDate] = totalUNITEDKINGDOM
                verWipUK[1][positionDate] = discrepantUNITEDKINGDOM
                verWipPercent[0][positionDate] = percCompIE
                verWipPercent[1][positionDate] = percCompUS
                verWipPercent[2][positionDate] = percCompUK
            }
        })

        var verWipIEHigh = getMaxFrom2dAarray(verWipIE) + 5
        var verWipUSHigh = getMaxFrom2dAarray(verWipUS) + 5
        var verWipUKHigh = getMaxFrom2dAarray(verWipUK) + 5
        var verWipPercentageHigh = getMaxFrom2dAarray(verWipPercent) + 5
        setVerificationWip({
            ...verificationWip,
            data: verificationWipData
        })
        setVerWipGraphIE({
            ...verWipGraphIE,
            data: {
                labels: calculatedLabels,
                series: verWipIE
            },
            options: {
                high: verWipIEHigh,
                low: -5
            }
        })
        setVerWipGraphUS({
            ...verWipGraphUS,
            data: {
                labels: calculatedLabels,
                series: verWipUS
            },
            options: {
                high: verWipUSHigh,
                low: -5
            }
        })
        setVerWipGraphUK({
            ...verWipGraphUK,
            data: {
                labels: calculatedLabels,
                series: verWipUK
            },
            options: {
                high: verWipUKHigh,
                low: -5
            }
        })
        setVerWipPercentageGraph({
            ...verWipPercentageGraph,
            data: {
                labels: calculatedLabels,
                series: verWipPercent
            },
            options: {
                high: verWipPercentageHigh,
                low: -5
            }
        })
        var searchProgress = document.getElementById('searchGraphProgress');
        searchProgress.style.visibility = 'hidden';
    }

    const getGraphsData = () => {
        // searchProgress.style.visibility = 'visible';

        db.collection('qualityVerificationWip')
            .orderBy("dateCreated", "desc")
            .limit(13)
            .get()
            .then(snapshot => {
                calcWeeklyVars(snapshot)
            })
            .catch(function (error) {
                console.log("Error getting Verification Wip data: ", error);
            });
    }

    useEffect(() => {
        getGraphsData()
        return () => { }
    }, [])


    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={31} openMetricsColapse={true} openMetricsQandCColapse={true} openMetricsQandCChainColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Metrics - Verification Wip</h1> */}
                            <LinearProgress id='searchGraphProgress' className={classes.searchProgress} />

                            {roles.includes('admin') ?
                                <MaterialTable
                                    title={"Deals in Verification Wip & Percent Compliant"}
                                    columns={verificationWip.columns}
                                    data={verificationWip.data}
                                    detailPanel={rowData => {
                                        // console.log(rowData)
                                        return (
                                            <MetricsChainOfCustodyVerificationWipDetails rowData={rowData} refreshData={getGraphsData} />
                                        )
                                    }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="Ireland" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="United States" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                    <Chip label="United Kingdom" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                        // Action: props => (
                                        //     <Button
                                        //         onClick={(event) => props.action.onClick(event, props.data)}
                                        //         color="primary"
                                        //         variant="contained"
                                        //         style={{ textTransform: 'none' }}
                                        //         size="small"
                                        //     >
                                        //         My Button
                                        //     </Button>
                                        // ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15],
                                        headerStyle: {
                                            textAlign: 'center'
                                        },
                                        cellStyle: {
                                            textAlign: 'center'
                                        }
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    addVerificationWip(newData)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    getGraphsData()
                                                    resolve();
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    if (oldData) {
                                                        setVerificationWip(prevState => {
                                                            const data = [...prevState.data];
                                                            newData.dateCreated = moment(newData.dateCreated).format('DD MMM YYYY')
                                                            data[data.indexOf(oldData)] = newData;
                                                            console.log('Updated Verification Wip row in DB')
                                                            // Update data
                                                            updateVerificationWip(newData)
                                                                .then(success => {
                                                                    //display success
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'success',
                                                                        message: success,
                                                                    });
                                                                    resolve();
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                    //display error
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'error',
                                                                        message: error,
                                                                    });
                                                                })
                                                            getGraphsData();
                                                            return { ...prevState, newData };
                                                        })
                                                    }
                                                }, 600)
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    console.log(oldData.id)
                                                    deleteVerificationWip(oldData.id)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            resolve();
                                                            setVerificationWip(prevState => {
                                                                const data = [...prevState.data];
                                                                data.splice(data.indexOf(oldData), 1);
                                                                return { ...prevState, data };
                                                            });
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    // getGraphsData();
                                                }, 600);
                                            }),
                                    }}
                                />
                                :
                                <MaterialTable
                                    title={"Deals in Verification Wip & Percent Compliant"}
                                    columns={verificationWip.columns}
                                    data={verificationWip.data}
                                    detailPanel={rowData => {
                                        // console.log(rowData)
                                        return (
                                            <MetricsChainOfCustodyVerificationWipDetails rowData={rowData} refreshData={getGraphsData} />
                                        )
                                    }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="Ireland" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="United States" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                    <Chip label="United Kingdom" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                        // Action: props => (
                                        //     <Button
                                        //         onClick={(event) => props.action.onClick(event, props.data)}
                                        //         color="primary"
                                        //         variant="contained"
                                        //         style={{ textTransform: 'none' }}
                                        //         size="small"
                                        //     >
                                        //         My Button
                                        //     </Button>
                                        // ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15],
                                        headerStyle: {
                                            textAlign: 'center'
                                        },
                                        cellStyle: {
                                            textAlign: 'center'
                                        }
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    addVerificationWip(newData)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    getGraphsData()
                                                    resolve();
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        // onRowUpdate: (newData, oldData) =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             if (oldData) {
                                        //                 setVerificationWip(prevState => {
                                        //                     const data = [...prevState.data];
                                        //                     newData.dateCreated = moment(newData.dateCreated).format('DD MMM YYYY')
                                        //                     data[data.indexOf(oldData)] = newData;
                                        //                     console.log('Updated Verification Wip row in DB')
                                        //                     // Update data
                                        //                     updateVerificationWip(newData)
                                        //                         .then(success => {
                                        //                             //display success
                                        //                             setSnackbar({
                                        //                                 open: true,
                                        //                                 severity: 'success',
                                        //                                 message: success,
                                        //                             });
                                        //                             resolve();
                                        //                         })
                                        //                         .catch(error => {
                                        //                             console.log(error)
                                        //                             //display error
                                        //                             setSnackbar({
                                        //                                 open: true,
                                        //                                 severity: 'error',
                                        //                                 message: error,
                                        //                             });
                                        //                         })
                                        //                     getGraphsData();
                                        //                     return { ...prevState, newData };
                                        //                 })
                                        //             }
                                        //         }, 600)
                                        //     }),
                                        // onRowDelete: oldData =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             console.log(oldData.id)
                                        //             deleteVerificationWip(oldData.id)
                                        //                 .then(success => {
                                        //                     //display success
                                        //                     setSnackbar({
                                        //                         open: true,
                                        //                         severity: 'success',
                                        //                         message: success,
                                        //                     });
                                        //                     resolve();
                                        //                     setVerificationWip(prevState => {
                                        //                         const data = [...prevState.data];
                                        //                         data.splice(data.indexOf(oldData), 1);
                                        //                         return { ...prevState, data };
                                        //                     });
                                        //                 })
                                        //                 .catch(error => {
                                        //                     console.log(error)
                                        //                     //display error
                                        //                     setSnackbar({
                                        //                         open: true,
                                        //                         severity: 'error',
                                        //                         message: error,
                                        //                     });
                                        //                 })
                                        //             // getGraphsData();
                                        //         }, 600);
                                        //     }),
                                    }}
                                />
                            }

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Percent Compliant - IRE, USA & UK
                                </Typography>

                                <ChartistGraph
                                    id='averageIre'
                                    data={verWipPercentageGraph.data}
                                    type={verWipPercentageGraph.type}
                                    options={verWipPercentageGraph.options}
                                    // listener={verWipPercentageGraph.animation}
                                    plugins={verWipPercentageGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="ie" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Ireland"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="us" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="United States"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="uk" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="United Kingdom"
                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Deals in Verification Wip - IRE
                                </Typography>

                                <ChartistGraph
                                    id='verWipGraphIE'
                                    data={verWipGraphIE.data}
                                    type={verWipGraphIE.type}
                                    options={verWipGraphIE.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={verWipGraphIE.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Total IRE</p>
                                    <div className={classes.blue}>.</div><p>Discrepant IRE</p>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Deals in Verification Wip - USA
                                </Typography>

                                <ChartistGraph
                                    id='verWipGraphUS'
                                    data={verWipGraphUS.data}
                                    type={verWipGraphUS.type}
                                    options={verWipGraphUS.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={verWipGraphUS.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Total USA</p>
                                    <div className={classes.blue}>.</div><p>Discrepant USA</p>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Deals in Verification Wip - UK
                                </Typography>

                                <ChartistGraph
                                    id='verWipGraphUK'
                                    data={verWipGraphUK.data}
                                    type={verWipGraphUK.type}
                                    options={verWipGraphUK.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={verWipGraphUK.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Total UK</p>
                                    <div className={classes.blue}>.</div><p>Discrepant UK</p>

                                </Grid>
                            </Grid>

                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}
export default MetricsChainOfCustodyVerificationWip