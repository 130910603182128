import { CssBaseline, makeStyles, LinearProgress, Typography, Chip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import { Grid } from '@material-ui/core';
import ChartistGraph from 'react-chartist'
import ctPointLabels from 'chartist-plugin-pointlabels'
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import { DataContext } from '../../../../contexts/DataContext';
import { db } from '../../../../config/fbConfig';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
    },
    searchProgress: {
        // visibility: 'hidden',
        marginBottom: '15px'
    },
    graphHeader: {
        marginTop: '100px',
        textAlign: 'center'
    },
    green: {
        width: '20px',
        color: 'rgb(20, 150, 30)',  // green
        backgroundColor: 'rgb(20, 150, 30)',  // green
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    blue: {
        width: '20px',
        color: 'rgb(50, 50, 240)', // blue
        backgroundColor: 'rgb(50, 50, 240)', // blue
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    grey: {
        width: '20px',
        color: 'rgb(110, 110, 110)', // grey
        backgroundColor: 'rgb(110, 110, 110)', // grey
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    yellow: {
        width: '20px',
        color: 'rgb(210, 180, 30)', // yellow
        backgroundColor: 'rgb(210, 180, 30)', // yellow
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
}));

export default function MetricsQualityCustomerIssues(props) {
    const classes = useStyles();
    const { addCustomerComplaintsStatus, updateCustomerComplaintsStatus, deleteCustomerComplaintsStatus } = useContext(DataContext);
    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    const year = moment().get('year');
    const week = moment().get('isoWeek');

    const [customerComplaintsStatus, setCustomerComplaintsStatus] = useState({
        // Year: 0,
        // Week: 0,
        // Open: 0,
        // New: 0,
        // Closed: 0,
        // CCOpen: 0,
        // CSATOpen: 0,
        // CCClosed: 0,
        // CSATClosed: 0,
        // OpenValid: 0,
        // ToBeValidated: 0,
        // ToBeValidatedIre: 0,
        // ToBeValidatedUK: 0,
        // ToBeValidatedUS: 0,
        // ToBeValidatedTH: 0,
        // ToBeValidatedCC: 0,
        // ToBeValidatedCSAT: 0,
        // ValidCC: 0,
        // ValidCSAT: 0,
        // ValidIre: 0,
        // ValidUK: 0,
        // ValidUS: 0,
        // ValidTH: 0,
        // CCIre: 0,
        // CCUS: 0,
        // CSATIre: 0,
        // CSATUS: 0
        columns: [
            { title: 'Year', field: 'Year', type: 'numeric', filtering: false, initialEditValue: year },
            { title: 'Week', field: 'Week', type: 'numeric', filtering: false, initialEditValue: week },
            { title: 'Open', field: 'Open', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'New', field: 'New', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'Closed', field: 'Closed', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'CC Open', field: 'CCOpen', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'CSAT Open', field: 'CSATOpen', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'CC Closed', field: 'CCClosed', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'CSAT Closed', field: 'CSATClosed', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'Open Valid', field: 'OpenValid', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'To Be Validated', field: 'ToBeValidated', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'Valid CC', field: 'ValidCC', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'Valid CSAT', field: 'ValidCSAT', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'To Be Validated CC', field: 'ToBeValidatedCC', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'To Be Validated CSAT', field: 'ToBeValidatedCSAT', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'Valid Ire', field: 'ValidIre', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'To Be Validated Ire', field: 'ToBeValidatedIre', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'CC Ire', field: 'CCIre', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'CSAT Ire', field: 'CSATIre', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'Valid US', field: 'ValidUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'To Be Validated US', field: 'ToBeValidatedUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'CC US', field: 'CCUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'CSAT US', field: 'CSATUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'To Be Validated TH', field: 'ToBeValidatedTH', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(110, 110, 110, 0.2)' } },
            { title: 'Valid TH', field: 'ValidTH', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(110, 110, 110, 0.2)' } },
            { title: 'To Be Validated UK', field: 'ToBeValidatedUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'Valid UK', field: 'ValidUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
        ],
        data: [],
    });
    const [openNewClosedGraph, setOpenNewClosedGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    });
    const [openValidToBeValidatedGraph, setOpenValidToBeValidatedGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    });
    const [validOutboundGraph, setValidOutboundGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    });
    const [closedCustomerIssuesGraph, setClosedCustomerIssuesGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    });
    const [validCustomerIssuesGraph, setValidCustomerIssuesGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    });
    const [validIssueByRegionGraph, setValidIssueByRegionGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    });
    const [toBeValidatedByRegionGraph, setToBeValidatedByRegionGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    });
    const [csatByRegionGraph, setCsatByRegionGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    });

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    // Calculations for 1d array
    // Array.prototype.max = function () {
    //     return Math.max.apply(null, this);
    // };

    // Array.prototype.min = function () {
    //     return Math.min.apply(null, this);
    // };
    function getMaxFrom2dAarray(a) {
        return Math.max(...a.map(e => Array.isArray(e) ? getMaxFrom2dAarray(e) : e));
    }
    function getMinFrom2dAarray(a) {
        return Math.min(...a.map(e => Array.isArray(e) ? getMinFrom2dAarray(e) : e));
    }

    const weekNumbers = [
        moment().day(-77).isoWeek(),
        moment().day(-70).isoWeek(),
        moment().day(-63).isoWeek(),
        moment().day(-56).isoWeek(),
        moment().day(-49).isoWeek(),
        moment().day(-42).isoWeek(),
        moment().day(-35).isoWeek(),
        moment().day(-28).isoWeek(),
        moment().day(-21).isoWeek(),
        moment().day(-14).isoWeek(),
        moment().day(-7).isoWeek(),
        moment().day(0).isoWeek(),
        moment().day(7).isoWeek() // current isoWeek
    ]

    function getCalculatedLabels() {
        return [
            'Week ' + weekNumbers[0],
            'Week ' + weekNumbers[1],
            'Week ' + weekNumbers[2],
            'Week ' + weekNumbers[3],
            'Week ' + weekNumbers[4],
            'Week ' + weekNumbers[5],
            'Week ' + weekNumbers[6],
            'Week ' + weekNumbers[7],
            'Week ' + weekNumbers[8],
            'Week ' + weekNumbers[9],
            'Week ' + weekNumbers[10],
            'Week ' + weekNumbers[11],
            'Week ' + weekNumbers[12]
        ]
    }

    function calcWeeklyVars(data, weekNumbers) {
        // console.log(weekNumbers)

        var customerComplaints = []
        var openNewClosedGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //Open
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //New
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //Closed
        ]
        var openValidToBeValidatedGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //Open
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OpenValid
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //ToBeValidated
        ]
        var validOutboundGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ValidCC
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ValidCSAT
        ]
        var closedCustomerIssuesGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //CCClosed
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //CSATClosed
        ]
        var validCustomerIssuesGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OpenValid
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ValidCC
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //ValidCSAT
        ]
        var validIssueByRegionGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ValidIre
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ValidUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ValidTH
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ValidUK
        ]
        var toBeValidatedByRegionGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ToBeValidatedIre
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ToBeValidatedUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ToBeValidatedTH
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //ToBeValidatedUK
        ]
        var csatByRegionGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //CSATIre
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //CSATUS
        ]

        data.forEach(doc => {
            // console.log(doc.data())
            var customerComplaintRecord = doc.data()
            customerComplaintRecord.id = doc.id
            customerComplaints.push(customerComplaintRecord)
            var positionWeek = weekNumbers.findIndex(week => week === doc.data().Week)
            // console.log(positionWeek)
            if (positionWeek >= 0) {
                var openCCS = doc.data().Open
                var newCCS = doc.data().New
                var closedCCS = doc.data().Closed
                openNewClosedGraphCalc[0][positionWeek] = openCCS
                openNewClosedGraphCalc[1][positionWeek] = newCCS
                openNewClosedGraphCalc[2][positionWeek] = closedCCS
                //openCCS // takes again open for this graph
                var openValidCCS = doc.data().OpenValid
                var toBeValidatedCCS = doc.data().ToBeValidated
                openValidToBeValidatedGraphCalc[0][positionWeek] = openCCS  // takes again open for this graph
                openValidToBeValidatedGraphCalc[1][positionWeek] = openValidCCS
                openValidToBeValidatedGraphCalc[2][positionWeek] = toBeValidatedCCS
                var validCCCCS = doc.data().ValidCC
                var validCSATCCS = doc.data().ValidCSAT
                validOutboundGraphCalc[0][positionWeek] = validCCCCS
                validOutboundGraphCalc[1][positionWeek] = validCSATCCS
                var cCClosedCCS = doc.data().CCClosed
                var cSATClosedCCS = doc.data().CSATClosed
                closedCustomerIssuesGraphCalc[0][positionWeek] = cCClosedCCS
                closedCustomerIssuesGraphCalc[1][positionWeek] = cSATClosedCCS
                validCustomerIssuesGraphCalc[0][positionWeek] = openValidCCS  // takes that variable again for this graph
                validCustomerIssuesGraphCalc[1][positionWeek] = validCCCCS  // takes that variable again for this graph
                validCustomerIssuesGraphCalc[2][positionWeek] = validCSATCCS  // takes that variable again for this graph
                var validIreCCS = doc.data().ValidIre
                var validUSCCS = doc.data().ValidUS
                var validTHCCS = doc.data().ValidTH
                var validUKCCS = doc.data().ValidUK
                validIssueByRegionGraphCalc[0][positionWeek] = validIreCCS
                validIssueByRegionGraphCalc[1][positionWeek] = validUSCCS
                validIssueByRegionGraphCalc[2][positionWeek] = validTHCCS
                validIssueByRegionGraphCalc[3][positionWeek] = validUKCCS
                var toBeValidatedIreCCS = doc.data().ToBeValidatedIre
                var toBeValidatedUSCCS = doc.data().ToBeValidatedUS
                var toBeValidatedTHCCS = doc.data().ToBeValidatedTH
                var toBeValidatedUKCCS = doc.data().ToBeValidatedUK
                toBeValidatedByRegionGraphCalc[0][positionWeek] = toBeValidatedIreCCS
                toBeValidatedByRegionGraphCalc[1][positionWeek] = toBeValidatedUSCCS
                toBeValidatedByRegionGraphCalc[2][positionWeek] = toBeValidatedTHCCS
                toBeValidatedByRegionGraphCalc[3][positionWeek] = toBeValidatedUKCCS
                var cSATIreCCS = doc.data().CSATIre
                var cSATUSCCS = doc.data().CSATUS
                csatByRegionGraphCalc[0][positionWeek] = cSATIreCCS
                csatByRegionGraphCalc[1][positionWeek] = cSATUSCCS
            }
        })

        var openNewClosedGraphCalcHigh = getMaxFrom2dAarray(openNewClosedGraphCalc) + 5
        var openNewClosedGraphCalcLow = getMinFrom2dAarray(openNewClosedGraphCalc) - 5
        var openValidToBeValidatedGraphCalcHigh = getMaxFrom2dAarray(openValidToBeValidatedGraphCalc) + 5
        var openValidToBeValidatedGraphCalcLow = getMinFrom2dAarray(openValidToBeValidatedGraphCalc) - 5
        var validOutboundGraphCalcHigh = getMaxFrom2dAarray(validOutboundGraphCalc) + 5
        var validOutboundGraphCalcLow = getMinFrom2dAarray(validOutboundGraphCalc) - 5
        var closedCustomerIssuesGraphCalcHigh = getMaxFrom2dAarray(closedCustomerIssuesGraphCalc) + 5
        var closedCustomerIssuesGraphCalcLow = getMinFrom2dAarray(closedCustomerIssuesGraphCalc) - 5
        var validCustomerIssuesGraphCalcHigh = getMaxFrom2dAarray(validCustomerIssuesGraphCalc) + 5
        var validCustomerIssuesGraphCalcLow = getMinFrom2dAarray(validCustomerIssuesGraphCalc) - 5
        var validIssueByRegionGraphCalcHigh = getMaxFrom2dAarray(validIssueByRegionGraphCalc) + 5
        var validIssueByRegionGraphCalcLow = getMinFrom2dAarray(validIssueByRegionGraphCalc) - 5
        var toBeValidatedByRegionGraphCalcHigh = getMaxFrom2dAarray(toBeValidatedByRegionGraphCalc) + 5
        var toBeValidatedByRegionGraphCalcLow = getMinFrom2dAarray(toBeValidatedByRegionGraphCalc) - 5
        var csatByRegionGraphCalcHigh = getMaxFrom2dAarray(csatByRegionGraphCalc) + 5
        var csatByRegionGraphCalcLow = getMinFrom2dAarray(csatByRegionGraphCalc) - 5

        setCustomerComplaintsStatus({
            ...customerComplaintsStatus,
            data: customerComplaints
        })
        setOpenNewClosedGraph({
            ...openNewClosedGraph,
            data: {
                labels: getCalculatedLabels(),
                series: openNewClosedGraphCalc
            },
            options: {
                high: openNewClosedGraphCalcHigh,
                low: -5
                // low: openNewClosedGraphCalcLow
            }
        })
        setOpenValidToBeValidatedGraph({
            ...openValidToBeValidatedGraph,
            data: {
                labels: getCalculatedLabels(),
                series: openValidToBeValidatedGraphCalc
            },
            options: {
                high: openValidToBeValidatedGraphCalcHigh,
                low: -5
                // low: openValidToBeValidatedGraphCalcLow
            }
        })
        setValidOutboundGraph({
            ...validOutboundGraph,
            data: {
                labels: getCalculatedLabels(),
                series: validOutboundGraphCalc
            },
            options: {
                high: validOutboundGraphCalcHigh,
                low: -5
                // low: validOutboundGraphCalcLow
            }
        })
        setClosedCustomerIssuesGraph({
            ...closedCustomerIssuesGraph,
            data: {
                labels: getCalculatedLabels(),
                series: closedCustomerIssuesGraphCalc
            },
            options: {
                high: closedCustomerIssuesGraphCalcHigh,
                low: -5
                // low: closedCustomerIssuesGraphCalcLow
            }
        })
        setValidCustomerIssuesGraph({
            ...validCustomerIssuesGraph,
            data: {
                labels: getCalculatedLabels(),
                series: validCustomerIssuesGraphCalc
            },
            options: {
                high: validCustomerIssuesGraphCalcHigh,
                low: -5
                // low: validCustomerIssuesGraphCalcLow
            }
        })
        setValidIssueByRegionGraph({
            ...validIssueByRegionGraph,
            data: {
                labels: getCalculatedLabels(),
                series: validIssueByRegionGraphCalc
            },
            options: {
                high: validIssueByRegionGraphCalcHigh,
                low: -5
                // low: validIssueByRegionGraphCalcLow
            }
        })
        setToBeValidatedByRegionGraph({
            ...toBeValidatedByRegionGraph,
            data: {
                labels: getCalculatedLabels(),
                series: toBeValidatedByRegionGraphCalc
            },
            options: {
                high: toBeValidatedByRegionGraphCalcHigh,
                low: -5
                // low: toBeValidatedByRegionGraphCalcLow
            }
        })
        setCsatByRegionGraph({
            ...csatByRegionGraph,
            data: {
                labels: getCalculatedLabels(),
                series: csatByRegionGraphCalc
            },
            options: {
                high: csatByRegionGraphCalcHigh,
                low: -5
                // low: csatByRegionGraphCalcLow
            }
        })
        var searchProgress = document.getElementById('searchGraphProgress');
        searchProgress.style.visibility = 'hidden';
    }

    const getGraphsData = () => {
        // searchProgress.style.visibility = 'visible';

        db.collection('customerComplaintsStatus')
            .where("Year", "<=", year)
            .orderBy("Year", "desc")
            .orderBy("Week", "desc")
            .limit(155)
            .get()
            .then(snapshot => {
                calcWeeklyVars(snapshot, weekNumbers)
            })
            .catch(function (error) {
                console.log("Error getting Customer Complaints: ", error);
            });
    }

    useEffect(() => {
        getGraphsData()
        return () => { }
    }, [])

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={34} openMetricsColapse={true} openMetricsQandCColapse={true} openMetricsQandCQualityColapse={true} />
                        <div className={classes.main}>

                            <h1>Metrics - Customer Issues</h1>

                            <LinearProgress id='searchGraphProgress' className={classes.searchProgress} />
                            {/* <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <img className={classes.media} src={underConstruction} alt="Under Construction" />
                            </Grid> */}

                            {roles.includes('admin') ? <MaterialTable
                                title={"Customer Complaints Status"}
                                columns={customerComplaintsStatus.columns}
                                data={customerComplaintsStatus.data}
                                // detailPanel={rowData => {
                                //     // console.log(rowData)
                                //     return (
                                //         <TicketHRDetails rowData={rowData} />
                                //     )
                                // }}
                                components={{
                                    Toolbar: props => (
                                        <div>
                                            <MTableToolbar {...props} />
                                            <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                <Chip label="Ireland" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                <Chip label="United States" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                <Chip label="Thailand" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(110, 110, 110, 0.2)', color: '#000' }} />
                                                <Chip label="United Kingdom" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(210, 180, 30, 0.2)', color: '#000' }} />
                                            </div>
                                        </div>
                                    ),
                                }}
                                options={{
                                    actionsColumnIndex: -1,
                                    // exportButton: true,
                                    // filtering: true,
                                    addRowPosition: 'first',
                                    pageSize: 5,
                                    pageSizeOptions: [5, 15]
                                    //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                }}
                                // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                actions={
                                    [{
                                        icon: 'refresh',
                                        tooltip: 'Refresh Data',
                                        isFreeAction: true,
                                        onClick: () => {
                                            getGraphsData()
                                            return () => { }
                                        },
                                    }]
                                }
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                addCustomerComplaintsStatus(newData);
                                                getGraphsData()
                                                // setCustomerComplaintsStatus(prevState => {
                                                //     const data = [...prevState.data];
                                                //     data.push(newData);
                                                //     // return { ...prevState, data };
                                                // });
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                if (oldData) {
                                                    setCustomerComplaintsStatus(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;

                                                        console.log('Updated in DB')
                                                        // Update data
                                                        // if closed or not needed then add closing date
                                                        updateCustomerComplaintsStatus(newData)
                                                        resolve();
                                                        return { ...prevState, data };
                                                    })
                                                }
                                            }, 600)
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                deleteCustomerComplaintsStatus(oldData.id)
                                                setCustomerComplaintsStatus(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}
                            />
                                :
                                <MaterialTable
                                    title={"Customer Complaints Status"}
                                    columns={customerComplaintsStatus.columns}
                                    data={customerComplaintsStatus.data}
                                    // detailPanel={rowData => {
                                    //     // console.log(rowData)
                                    //     return (
                                    //         <TicketHRDetails rowData={rowData} />
                                    //     )
                                    // }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="Ireland" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                    <Chip label="United States" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="Thailand" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(110, 110, 110, 0.2)', color: '#000' }} />
                                                    <Chip label="United Kingdom" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(210, 180, 30, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15]
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    addCustomerComplaintsStatus(newData);
                                                    getGraphsData()
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        // onRowUpdate: (newData, oldData) =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             if (oldData) {
                                        //                 setCustomerComplaintsStatus(prevState => {
                                        //                     const data = [...prevState.data];
                                        //                     data[data.indexOf(oldData)] = newData;

                                        //                     console.log('Updated in DB')
                                        //                     // Update data
                                        //                     // if closed or not needed then add closing date
                                        //                     updateCustomerComplaintsStatus(newData)
                                        //                     resolve();
                                        //                     return { ...prevState, data };
                                        //                 })
                                        //             }
                                        //         }, 600)
                                        //     }),
                                        // onRowDelete: oldData =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             resolve();
                                        //             deleteCustomerComplaintsStatus(oldData.id)
                                        //             setCustomerComplaintsStatus(prevState => {
                                        //                 const data = [...prevState.data];
                                        //                 data.splice(data.indexOf(oldData), 1);
                                        //                 return { ...prevState, data };
                                        //             });
                                        //         }, 600);
                                        //     }),
                                    }}
                                />
                            }

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Open, New & Closed Customer Issues by Week
                                </Typography>

                                <ChartistGraph
                                    id='openNewClosedGraph'
                                    data={openNewClosedGraph.data}
                                    type={openNewClosedGraph.type}
                                    options={openNewClosedGraph.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={openNewClosedGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Open</p>
                                    <div className={classes.blue}>.</div><p>New</p>
                                    <div className={classes.grey}>.</div><p>Closed</p>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Open Customer Issues, Valid & To Be Validated
                                </Typography>

                                <ChartistGraph
                                    id='openValidToBeValidatedGraph'
                                    data={openValidToBeValidatedGraph.data}
                                    type={openValidToBeValidatedGraph.type}
                                    options={openValidToBeValidatedGraph.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={openValidToBeValidatedGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Open</p>
                                    <div className={classes.blue}>.</div><p>Open Valid</p>
                                    <div className={classes.grey}>.</div><p>To Be Validated</p>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Valid Outbound (CC) & Valid Outbound (CSAT)
                                </Typography>

                                <ChartistGraph
                                    id='validOutboundGraph'
                                    data={validOutboundGraph.data}
                                    type={validOutboundGraph.type}
                                    options={validOutboundGraph.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={validOutboundGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Valid CC</p>
                                    <div className={classes.blue}>.</div><p>Valid CSAT</p>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Closed Customer Issues (CC & CSAT)
                                </Typography>

                                <ChartistGraph
                                    id='closedCustomerIssuesGraph'
                                    data={closedCustomerIssuesGraph.data}
                                    type={closedCustomerIssuesGraph.type}
                                    options={closedCustomerIssuesGraph.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={closedCustomerIssuesGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Closed CC</p>
                                    <div className={classes.blue}>.</div><p>Closed CSAT</p>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Valid Customer Issues - Outbound / Inbound
                                </Typography>

                                <ChartistGraph
                                    id='validCustomerIssuesGraph'
                                    data={validCustomerIssuesGraph.data}
                                    type={validCustomerIssuesGraph.type}
                                    options={validCustomerIssuesGraph.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={validCustomerIssuesGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Open Valid</p>
                                    <div className={classes.blue}>.</div><p>Valid CC</p>
                                    <div className={classes.grey}>.</div><p>Valid CSAT</p>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Valid CC Issue by Region
                                </Typography>

                                <ChartistGraph
                                    id='validIssueByRegionGraph'
                                    data={validIssueByRegionGraph.data}
                                    type={validIssueByRegionGraph.type}
                                    options={validIssueByRegionGraph.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={validIssueByRegionGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Valid Ire</p>
                                    <div className={classes.blue}>.</div><p>Valid US</p>
                                    <div className={classes.grey}>.</div><p>Valid TH</p>
                                    <div className={classes.yellow}>.</div><p>Valid UK</p>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    To Be Validated By Region
                                </Typography>

                                <ChartistGraph
                                    id='toBeValidatedByRegionGraph'
                                    data={toBeValidatedByRegionGraph.data}
                                    type={toBeValidatedByRegionGraph.type}
                                    options={toBeValidatedByRegionGraph.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={toBeValidatedByRegionGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>To Be Validated Ire</p>
                                    <div className={classes.blue}>.</div><p>To Be Validated US</p>
                                    <div className={classes.grey}>.</div><p>To Be Validated TH</p>
                                    <div className={classes.yellow}>.</div><p>To Be Validated UK</p>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    CSAT By Region
                                </Typography>

                                <ChartistGraph
                                    id='csatByRegionGraph'
                                    data={csatByRegionGraph.data}
                                    type={csatByRegionGraph.type}
                                    options={csatByRegionGraph.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={csatByRegionGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>CSAT Ire</p>
                                    <div className={classes.blue}>.</div><p>CSAT US</p>

                                </Grid>
                            </Grid>

                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}