import firebase from 'firebase/app';
// import * as admin from 'firebase-admin';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/storage';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDyyY3LxxYYIwL9shKIaV98-5uGtQJbuWk",
    authDomain: "wisetekapp.firebaseapp.com",
    databaseURL: "https://wisetekapp.firebaseio.com",
    projectId: "wisetekapp",
    // storageBucket: "wisetekapp.appspot.com",
    storageBucket: "wisetekappappspotcom",
    messagingSenderId: "398238547152",
    appId: "1:398238547152:web:9c24188359fe71b438b2d5",
    measurementId: "G-G9S63QZ8XV"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var serviceAccount = {
    "type": "service_account",
    "project_id": "wisetekapp",
    "private_key_id": "f73b4145d1033136f3eb67f9b781cd3194941c42",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDhbSAMirMRC/xG\nRu01xx5B2x24OeLDHhIKqf6CyCfmjTI+EmBTQYA86ptUa26ZbLsFbSwocj1NpFSY\n77CSdRTjJREDWcwGmDa2kc7bK/t4eJh6nPZ0SFSC6D/C6AafM04fPieFvdVWX/yh\nq5/PCIFsCmcH5NwtSutHrnrGQLXQo0eN6h189eD9n75U9wor2hn/apQ1PC47aNwV\nXRTx1snetgfYV0x5ZkyQYz34cPLAM9KLxgZA8yZAGpTimSUaMDk4W0o9glvu425G\no3Caz3jY7THS0NlL212RABDDv/R56Zw2a7BHg5EzkS/ayFWs07kWiDDmW6CiAC2O\n70sNTDbrAgMBAAECggEAAltJsJAx/cBuKhRQibgGXwf0qIqd5Tcq8MvWympqDStx\nL9G7mahVUb5ZKaxJCr8APnl6f5ROUGWil6YQOJFV5FL24AHFwJJL6RbuaL9ZcLBU\nnYBUApre9/FKu+jN2Y7FMBBYhE5edJND/VHNB6Wuy7kRZYxenlGi0sCI1sKx8K/t\nWJQOvwtJj113E1sxehHtDOz3pAaYmgcKQZvIijQiRigBGU6XRHyTb54d7oq3VXfR\ndWMqJ6cRAP6z57cT7a1X6A4c8OzhY+ti9G+mQED8ZAytYPqg6w3o0OrfQcDumsGm\nKOOJSukoKtKh54vf517OkOdfq03j0og6JfefRO/rwQKBgQD0qqYFkw+o+Clv9QBY\nMAfgwoM3YqovX/lRFTI5Ordpm61xb3xMZeue2XuqnI540KYcfGzuci1cWBZtlVBy\nnuu7KhMv9Qias9JvfeK852x9cxBiBD0qmikqvh/Gk8RiImjtYb4HfFn3vmfSrvDu\nRyjdY7PnU8C4rKPf6lTSSIKgNQKBgQDr3lFCZdnqn/PW+db/LCvfCGA3Q91FDRJZ\n+T9WybQAtNmitrEs11WFaCAXatP3DpmXTGUZ+8DCtYwv5329f7HYd2gS/WwYdREO\nojwNDk5auh6FHUBZtVzQYJ+oHX+QIGfUJR0ATS07bFfnatkLT6uCfNX8hnvnrmCI\nbcbJ7WKenwKBgA/+R7P9lPx6nFrRLSCWoy0yCdnI/U7zexytwgazq+GfZ4sOupVT\n5D8SAF96tGV5XiJmj8hWdlie+XhYVlXnxYkKiw6LefCC6LKZDAVFK3ZX2y5NEKKE\nSUSeAP2+EwxHK4y44u8iVc84BxlijiVFSYqPRedNNj/cKSot6zWDv+YxAoGAG9h2\nLWmKzXkut01thVh4oW8CtOONJs7BQN/9C0jquD6hhuUci5esYACH8VvKRFtz5Hm/\nYc7n1dTLBQjGPm3p2A1Yuw0yCr0fU2PxxPTQaUOZwMtQQob3ux39G0LIQYKh31i2\nUMfLtD05L3fcwcoZDGGifZp+3D4o5VJYapRc1S8CgYEAxWwUIsNNnSzIOaVh/p2u\n9XYr1HybNGpA+L443UyXhDR5YePxksDOmCZV0DqbyZLtjIkkTDfSRorU/wysMr6E\nTiAvtT4QoEHLNoG90rDnvFgNd6H7loEdxSPZC0o4uzm2PNd+ahrhDQxzldcsP309\ntsvL/PX4BPPiztj5R84a25U=\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-9wply@wisetekapp.iam.gserviceaccount.com",
    "client_id": "115843168953576496177",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-9wply%40wisetekapp.iam.gserviceaccount.com"
};

// admin.initializeApp({
//     credential: admin.credential.cert(serviceAccount),
//     databaseURL: "https://wisetekapp.firebaseio.com"
// });
// export const adminAuth = admin.auth();

export const anal = firebase.analytics();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export default firebase;