import React from 'react';
import { makeStyles } from '@material-ui/core';
import BusinessUpdateVideoVol5 from '../../../../images/BusinessUpdateVol5.mp4'
// import posterVol4 from '../../../../images/posterVol4.png'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '90%',
        borderRadius: 10,
    },
}));

export default function BusinessUpdateVol5(props) {
    const classes = useStyles();
    if (props.stopVideo) document.getElementById('businessUpdateV5').pause();
    return (
        <div className={classes.root}>

            {/* <header >
                <h2 className={classes.textCentered}>Recording of Employee Business Update 1ˢᵗ December 2020</h2>
            </header> */}

            <br />
            <div className={classes.textCentered}>
                <video id='businessUpdateV5' className={classes.media} src={BusinessUpdateVideoVol5}
                    type="video/mp4"
                    // poster={posterVol4}
                    controls
                    controlsList="nodownload">
                    Your browser does not support HTML video.
                </video>
            </div>

            <h4 className={classes.textCentered}>Recording of Employee Business Update 21ˢᵗ December 2022</h4>

            {/*<article >
                <h5 >Dear Colleagues,</h5>
                <section>
                    Sunday the 8th of March is international Women’s Day. If you would like to take steps to combat gender inequality in the workplace, a good place to start is with bias. Bias is not limited to gender but whether conscious or unconscious, women are often subject to bias.

                    To give you something to think about, an example of this from another company.  Someone suggests that a female on your team be given a big, high profile project, and a colleague says, ‘I don’t think this is a good time for her since she just had a baby’, while the colleague most likely had good intentions in this situation it works against this woman by denying her opportunities that could lead to advancement.  In this situation a suggested approach to the colleague is that this will be a career changing project for whoever gets it, so it’s better to let the new mother decide for herself if she wants to take it on.
                </section>
                <br />


            </article>

            <h4 className={classes.textCentered}>
                Lastly Christine Fitzgerald, Production Manager for Ireland was recognised on behalf of Wisetek for her contribution to their business as a Partner at an International Women’s Day event in Dell EMC yesterday evening.  Well done Christine.
            </h4>
            <h2 className={classes.textCentered}>
                <a href="https://www.internationalwomensday.com/Influencers" target="_blank" rel="noopener noreferrer">Please see some statements from some progressive leaders (including some of our customers) in support for a #EachforEqual world</a>
            </h2>*/}

            <br />
            <p>Regards,<br /><br />

                Tom Delahunty<br />
                COO<br />
                Wisetek™<br />
            </p>
            <br />

        </div>
    )
}