import { Button, Card, CardActions, CardContent, Typography, makeStyles } from '@material-ui/core';
import React from 'react'


const useStyles = makeStyles(theme => ({
    center: {
        // color: 'White',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    courseCard: {
        margin: theme.spacing(2),
        // height: 670,
        width: '100%'
    },
    gameContent: {
        //original game width="485" height="402" 
        height: 530,
        width: 640,
        margin: '40px auto 20px auto'
    }

}));

export default function Sustainability(props) {
    const classes = useStyles()
    return (
        <Card className={classes.courseCard}>
            <CardContent >
                <Typography variant="h5" component="h5">
                    Waste Segregation Game
                </Typography>
                <div style={{ marginBottom: 10 }}>
                    <Typography variant="caption" color="textSecondary" >{'by Emily Conway'}</Typography>
                </div>
                <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                    <b>Game rules:</b> Drag and drop all the items to correct bins, you will get 10 points if you are correct but be careful you will be deducted 5 points if you make an incorrect choice.
                    You have two minutes to accomplish the waste segregation.
                </Typography>
                <div>
                    <div className={classes.gameContent}>
                        <iframe src="https://scratch.mit.edu/projects/872746206/embed" width="640" height="530" allowtransparency="true" frameborder="0" scrolling="no" allowfullscreen></iframe>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
