import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { db } from '../../../config/fbConfig';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

export default function TeamManagers() {

    const [managers, setManagers] = useState({
        columns: [
            { title: 'Label', field: 'label' },
            { title: 'Value', field: 'value' },
            { title: 'Email', field: 'email' },
        ],
        data: [],
    });

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function getTeamManagers() {
        const teamManagers = []
        db.collection('teamManagers').orderBy('label').get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    teamManagers.push({
                        ...doc.data(),
                        id: doc.id
                    });
                })
                //console.log(teamManagers)
                setManagers({
                    ...managers,
                    data: teamManagers
                })
            })
            .catch(error => {
                console.log("Error getting team managers: ", error);
            });
    }

    function addTeamManager(newData) {
        db.collection("teamManagers").add({
            label: newData.label,
            value: newData.value,
            email: newData.email
        })
            .then(success => {
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Team Manager added successfully.',
                })
            })
            .catch(error => {
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error.message,
                })
            })
    }
    function updateTeamManager(newData) {
        db.collection("teamManagers").doc(newData.id).update({
            label: newData.label,
            value: newData.value,
            email: newData.email
        })
            .then(success => {
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Team Manager updated successfully.',
                })
            })
            .catch(error => {
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error.message,
                })
            })
    }
    function deleteTeamManager(teamManagerToDelete) {
        console.log('deleting team manager ' + teamManagerToDelete);
        db.collection('teamManagers').doc(teamManagerToDelete).delete()
            .then(success => {
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Team Manager deleted successfully.',
                })
            })
            .catch(error => {
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error.message,
                })
            })
    }

    useEffect(() => {
        getTeamManagers();
        return () => { }
    }, [])

    return (
        <div>
            <MaterialTable
                title={"Team Managers"}
                columns={managers.columns}
                data={managers.data}
                // options={{ actionsColumnIndex: -1 }}
                options={{ addRowPosition: 'first', exportButton: true, filtering: true, pageSize: 5, pageSizeOptions: [5, 15, 50, { value: managers.data.length, label: 'All' }] }}//paging: true, pageSize: 100, pageSizeOptions: [5, 10, 100] 
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getTeamManagers();
                            return () => { }
                        },
                    }
                ]}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                setManagers(prevState => {
                                    const data = [...prevState.data];
                                    data.push(newData);
                                    return { ...prevState, data };
                                });
                                // //add data
                                addTeamManager(newData);
                                resolve();
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                if (oldData) {
                                    setManagers(prevState => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        return { ...prevState, data };
                                    });
                                }
                            }, 600);
                            // Update data
                            // console.log(newData)
                            updateTeamManager(newData)
                            resolve();
                        }),
                    onRowDelete: oldData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                setManagers(prevState => {
                                    const data = [...prevState.data];
                                    data.splice(data.indexOf(oldData), 1);
                                    return { ...prevState, data };
                                });
                            }, 600);
                            // delete user
                            var teamManagerToDelete = oldData.id
                            db.collection("teamManagers").doc(teamManagerToDelete).delete()
                                .then(
                                    deleteTeamManager(teamManagerToDelete)
                                )
                                .catch(error => {
                                    //display error
                                    setSnackbar({
                                        open: true,
                                        severity: 'error',
                                        message: error.message,
                                    })
                                })
                            resolve();
                        }),
                }}
            />
            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    );
}
