import React, { useContext, useState } from 'react';
import Loading from '../../layout/Loading';
import SideMenu from '../SideMenus/SideMenu';
import Footer from '../../Footer'
import { AuthContext } from '../../../contexts/AuthContext';
import { makeStyles, Container, CssBaseline, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import TicketsFacilities from './Tickets/TicketsFacilities';
import SideMenuV2 from '../SideMenus/SideMenuV2';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    myTable: {
        padding: '0px',
    },
}));

export default function FacilitiesConsole() {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({
            open: false,
            severity: 'info',
            message: '',
        })
    };



    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('memberFacilities') || roles.includes('adminFacilities') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={43} />
                            <div className={classes.main}>

                                {/* <h1>Facilities Console</h1> */}

                                <TicketsFacilities />

                            </div>
                        </CssBaseline>
                        <Footer />

                        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={snackbar.severity}>
                                {snackbar.message}
                            </Alert>
                        </Snackbar>
                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}
