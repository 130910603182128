import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Checkbox, Container, FormControlLabel, Grid, IconButton, LinearProgress, makeStyles, MenuItem, Paper, Slide, Step, StepButton, Stepper, TextField, Tooltip, Typography } from '@material-ui/core';
import { AuthContext } from '../../../contexts/AuthContext';
import { DataContext } from '../../../contexts/DataContext';
import { storage } from '../../../config/fbConfig';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Footer from '../../Footer';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Loading from '../Loading'
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import videoPlaceholder from '../../../images/videoPlaceholder.png'
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#cfd8dc'
    },
    center: {
        // color: 'White',
        textAlign: 'center',
        // padding: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    courseTitle: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 20,
    },
    cardContent: {
        backgroundColor: '#ededed',
        padding: 20,
    },
    acordionMenu: {
        backgroundColor: '#ebfaf9'
    },
    xs3Grid: {
        minHeight: '57vh',
        paddingTop: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        // backgroundColor: '#2e2e2e',//'DarkGrey',
    },
    xs9GridHeader: {
        padding: '10px'
        // backgroundColor: 'Black',
    },
    xs9Grid: {
        // backgroundColor: 'Black',
        minHeight: '70vh'
    },
    input: {
        display: 'none',
    },
    cardMedia: { // course logo
        margin: '20px',
        height: 200,
    },
    chaptersCard: {
        // color: 'White',
        // backgroundColor: 'Black',
        minHeight: '50vh',
        // padding: '20px',
    },
    textfield: {
        maxWidth: '70%',
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: '20px',
        marginBottom: '20px',
    },
    buttonAddChoiceMarginAround: {
        margin: '20px',
        marginLeft: '7%'
    },
    buttonMarginAround: {
        margin: '20px',
    },
    removeBtn: {
        margin: 5
    },
    addingMenuButton: {
        textTransform: 'none',
        justifyContent: 'start',
        margin: '10px'
    },
    addingMenuIcons: {
        maxWidth: '50px',
        height: '45px',
        backgroundColor: '#ededed',
        border: '1px solid lightgreen',
        borderRadius: '5px',
        margin: '15px 15px 15px 0px',
        padding: '10px'
    },
    addingMenuDescription: {
        width: '100%'
    },
    addingMenuDiv: {
        padding: '10px',
    },
    pdfDiv: {
        textAlign: 'center',
        marginTop: '50px',
        marginBottom: '30px',
    },
    pdfDropProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    pdfDropzone: {
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '30px',
        textAlign: 'center',
        border: '1px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        padding: '20px',
        backgroundColor: '#ebfaf9'
    },
    videoDiv: {
        textAlign: 'center',
        marginTop: '30px',
        marginBottom: '30px',
    },
    videoSize: {
        width: '40%',
    },
    dropProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    dropzone: {
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '30px',
        textAlign: 'center',
        border: '1px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        padding: '20px',
        backgroundColor: '#ebfaf9'
    },
    input: {
        display: 'none',
    },
    textOptionalMargin: {
        margin: '20px'
    },
    questionCheckbox: {
        marginLeft: '10%'
    },
    questionAndChoice: {
        marginTop: '50px',
        marginLeft: '7%'
    },
    questionsAcordion: {
        backgroundColor: '#ebfaf9'
    },
    questionAcordionTextDiv: {
        width: '95%'
    },
    marginOne: {
        margin: theme.spacing(1),
    },
    marginTwo: {
        margin: theme.spacing(2),
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CourseDesigner(props) {
    const classes = useStyles();
    const { isAuthenticated, uid, displayName, role } = useContext(AuthContext)
    const { updateCourse, updateCourseLogo, removePdfAttachmentFromWiseAcademyCourseBucket } = useContext(DataContext);
    const history = useHistory();

    var propData = useLocation().state;
    // console.log(propData);

    const [courseEditor, setCourseEditor] = useState({
        dialogOpen: false,
        Id: propData.Id,
        Category: propData.Category,
        Title: propData.Title,
        LogoUrl: propData.LogoUrl,
        Description: propData.Description,
        Status: propData.Status,
        chapters: propData.chapters,
        createdBy: propData.createdBy,
        filesToStoreInDB: [],
        filesSize: 0,
        files: [],
        newStatus: propData.Status
    });
    const [activeExpanded, setActiveExpanded] = useState({
        chapExpanded: -1, stepActive: -1
    });
    const [expandedQuestion, setExpandedQuestion] = React.useState('panel0');

    const handleChangeExpandedQuestion = (panel) => (event, newExpandedQuestion) => {
        // console.log(event, newExpandedQuestion)
        setExpandedQuestion(newExpandedQuestion ? panel : false);
    };
    const [showAddingMenu, setShowAddingMenu] = useState(false);
    const [lessonVideo, setLessonVideo] = useState({
        isVideoLesson: false,
        videoUrl: '',
        pdf: null,
        textOptional: ''
    });
    const [lessonText, setLessonText] = useState({
        isTextLesson: false,
        text: ''
    });
    const [lessonQuiz, setLessonQuiz] = useState({
        isQuizLesson: false,
        questions: [],
    });

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    // default lesson states
    const defaultVideo = {
        isVideoLesson: true,
        videoUrl: '',
        pdf: null,
        textOptional: ''
    }
    const defaultText = {
        isTextLesson: true,
        text: ''
    }
    const defaultQuiz = {
        isQuizLesson: true,
        questions: []
        // questions: [{
        //     type: 'Single',
        //     question: '',
        //     choices: ['Yes', 'No'],
        //     answers: [0]
        // }]
    }
    const courseCategory = [
        { value: 'ITAD', label: 'ITAD' },
        { value: 'OEM', label: 'OEM' },
        { value: 'Manufacturing', label: 'Manufacturing' },
        { value: 'General', label: 'General' },
        { value: 'Compliance', label: 'Compliance' },
    ]
    const courseStatus = [
        { value: 'Draft', label: 'Draft' },
        { value: 'Published', label: 'Published' },
    ]
    const questionTypes = [
        { value: 'Single', label: 'One correct answer' },
        { value: 'Multiple', label: 'Multiple correct answers' },
    ]
    const defaultQuestion = {
        type: 'Single',
        question: '',
        choices: ['Yes', 'No'],
        answers: [true, false]
    }
    const defaultChoice = ''
    const defaultAnswer = false

    // helper method
    function clearLessonContent() {
        // set lessons content to empty
        setLessonVideo({
            ...lessonVideo,
            isVideoLesson: false
        })
        setLessonText({
            ...lessonText,
            isTextLesson: false
        })
        setLessonQuiz({
            ...lessonQuiz,
            isQuizLesson: false
        })
    }
    function setLessonContent(chapIndex, stepIndex) {
        if (courseEditor.chapters[chapIndex].chapStepsContent) {
            setLessonVideo(courseEditor.chapters[chapIndex].chapStepsContent[stepIndex])
            setLessonText(courseEditor.chapters[chapIndex].chapStepsContent[stepIndex])
            setLessonQuiz(courseEditor.chapters[chapIndex].chapStepsContent[stepIndex])
        }
    }

    const handleDownload = async (e, file) => {
        e.preventDefault();
        const response = await fetch(file.url);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }

    const cancelEditing = () => {
        // TODO: Ask is progress saved?
        history.push('/WiseAcademy')
    }
    const handleAddChapter = (e) => {
        handleHideAddingMenu();
        setCourseEditor({
            ...courseEditor,
            chapters: [...courseEditor.chapters, {
                chapTitle: 'set chapter title...',
            }],
        })
        var chapIndex = courseEditor.chapters.length
        // var lessonIndex = -1
        setActiveExpanded({
            ...activeExpanded,
            chapExpanded: chapIndex,
            // stepActive: lessonIndex
        })
        clearLessonContent()
    }
    const handleAddLesson = (lesson) => {
        handleHideAddingMenu();
        var newChapters = courseEditor.chapters;
        // console.log(chapIndex)
        newChapters[activeExpanded.chapExpanded].chapSteps ? newChapters[activeExpanded.chapExpanded].chapSteps.push('set lesson title...') : newChapters[activeExpanded.chapExpanded].chapSteps = ['set lesson title...']

        var lessonIndex = newChapters[activeExpanded.chapExpanded].chapSteps.length - 1

        // setLessonType(lesson)
        switch (lesson) {
            case 'videoLesson':
                setLessonVideo({ ...lessonVideo, isVideoLesson: true });
                // add steps content to default video state
                newChapters[activeExpanded.chapExpanded].chapStepsContent ? newChapters[activeExpanded.chapExpanded].chapStepsContent.push(defaultVideo) : newChapters[activeExpanded.chapExpanded].chapStepsContent = [defaultVideo]
                break;
            case 'textLesson':
                setLessonText({ ...lessonText, isTextLesson: true });
                // add steps content to default text state
                newChapters[activeExpanded.chapExpanded].chapStepsContent ? newChapters[activeExpanded.chapExpanded].chapStepsContent.push(defaultText) : newChapters[activeExpanded.chapExpanded].chapStepsContent = [defaultText]
                break;
            case 'quizLesson':
                setLessonQuiz({ ...lessonQuiz, isQuizLesson: true });
                // add steps content to default video state
                newChapters[activeExpanded.chapExpanded].chapStepsContent ? newChapters[activeExpanded.chapExpanded].chapStepsContent.push(defaultQuiz) : newChapters[activeExpanded.chapExpanded].chapStepsContent = [defaultQuiz]
                break;

            default:
                break;
        }
        setCourseEditor({
            ...courseEditor,
            chapters: newChapters
        })
        setActiveExpanded({
            ...activeExpanded,
            // chapExpanded: activeExpanded.chapExpanded,
            stepActive: lessonIndex
        })
        //clearLessonContent()
        setLessonContent(activeExpanded.chapExpanded, lessonIndex)
    }
    const handleAddStep = (e, chapIndex) => {
        var newChapters = courseEditor.chapters;
        // console.log(chapIndex)
        newChapters[chapIndex].chapSteps ? newChapters[chapIndex].chapSteps.push('set lesson title...') : newChapters[chapIndex].chapSteps = ['set lesson title...']
        var lessonIndex = newChapters[chapIndex].chapSteps.length - 1
        setCourseEditor({
            ...courseEditor,
            chapters: newChapters
        })
        setActiveExpanded({
            ...activeExpanded,
            chapExpanded: chapIndex,
            stepActive: lessonIndex
        })
    }
    const handelRemoveItem = (rem, chapIndex, stepIndex, questionIndex, choiceIndex) => {
        console.log('removeItem:', rem, 'chapIndex:', chapIndex, 'stepIndex:', stepIndex, 'questionIndex:', questionIndex, 'choiceIndex:', choiceIndex)
        switch (rem) {
            case 'removeChapter':
                // console.log('remove chapter:', chapIndex)
                var newChaptersArray = courseEditor.chapters
                newChaptersArray.splice(chapIndex, 1)
                var chapIndexNew = newChaptersArray.length - 1
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChaptersArray
                })
                setActiveExpanded({
                    ...activeExpanded,
                    chapExpanded: chapIndexNew,
                });
                clearLessonContent()
                break;
            case 'removeLesson':
                // console.log('remove lesson:', stepIndex)
                var newChaptersArray = courseEditor.chapters
                newChaptersArray[chapIndex].chapSteps.splice(stepIndex, 1)
                newChaptersArray[chapIndex].chapStepsContent.splice(stepIndex, 1)
                var lessonIndexNew = newChaptersArray[chapIndex].chapSteps.length - 1
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChaptersArray
                });
                setActiveExpanded({
                    ...activeExpanded,
                    chapExpanded: chapIndex,
                    stepActive: lessonIndexNew
                });
                clearLessonContent()
                break;
            case 'removeQuestion':
                // console.log('remove question:', questionIndex)
                var newChaptersArray = courseEditor.chapters
                newChaptersArray[chapIndex].chapStepsContent[stepIndex].questions.splice(questionIndex, 1)
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChaptersArray
                })
                break;
            case 'removeChoice':
                // console.log('remove choice:', choiceIndex)
                var newChaptersArray = courseEditor.chapters
                newChaptersArray[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].choices.splice(choiceIndex, 1)
                newChaptersArray[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].answers.splice(choiceIndex, 1)
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChaptersArray
                });
                break;
            case 'removePdf':
                // console.log('remove question:', questionIndex)
                var newChaptersArray = courseEditor.chapters
                newChaptersArray[chapIndex].chapStepsContent[stepIndex].pdf = null
                removePdfAttachmentFromWiseAcademyCourseBucket()
                    .then(succ => {
                        console.log('Succ:', succ)
                        setCourseEditor({
                            ...courseEditor,
                            chapters: newChaptersArray
                        })
                    })
                    .catch(err => {
                        console.log('Err:', err)
                        setCourseEditor({
                            ...courseEditor,
                            chapters: newChaptersArray
                        })
                    })
                break;

            default:
                break;
        }
    }

    const handleChange = (e, chapIndex, stepIndex, questionIndex, choiceIndex) => {
        // console.log(e.target.value)
        // console.log(e.target.name)
        var newChapters = courseEditor.chapters;
        switch (e.target.name) {
            case 'courseCategory':
                setCourseEditor({
                    ...courseEditor,
                    Category: e.target.value
                })
                break;
            case 'courseTitle':
                setCourseEditor({
                    ...courseEditor,
                    Title: e.target.value
                })
                break;
            case 'courseDescription':
                setCourseEditor({
                    ...courseEditor,
                    Description: e.target.value
                })
                break;
            case 'courseStatus':
                setCourseEditor({
                    ...courseEditor,
                    newStatus: e.target.value
                })
                break;
            case 'chapTitle':
                newChapters[chapIndex].chapTitle = e.target.value
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChapters
                })
                break;
            case 'chapStep':
                newChapters[chapIndex].chapSteps[stepIndex] = e.target.value
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChapters
                })
                break;
            case 'videoTextOptional':
                newChapters[chapIndex].chapStepsContent[stepIndex].textOptional = e.target.value
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChapters
                })
                break;
            case 'questionType':
                newChapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].type = e.target.value
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChapters
                })
                break;
            case 'question':
                newChapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].question = e.target.value
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChapters
                })
                break;
            case 'choice':
                newChapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].choices[choiceIndex] = e.target.value
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChapters
                })
                break;
            case 'textFieldLesson':
                console.log(e.target.value)
                newChapters[chapIndex].chapStepsContent[stepIndex].text = e.target.value
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChapters
                })
                break;

            default:
                break;
        }
    }
    const handleCheckboxChange = (e, chapIndex, stepIndex, questionIndex, choiceIndex, questionType) => {
        // console.log(e.target.checked)
        // console.log(e.target.name)
        var newChapters = courseEditor.chapters;
        switch (questionType) {
            case 'Single':
                // console.log('single so set only one answer')
                newChapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].answers.forEach((answer, answerIndex) => {
                    newChapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].answers.splice(answerIndex, 1, false)
                })
                // console.log('before update', newChapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].answers)
                newChapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].answers.splice(choiceIndex, 1, e.target.checked)
                // console.log('after update', newChapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].answers)    
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChapters
                })
                break;
            case 'Multiple':
                // console.log('multiple so set multiple answers')
                newChapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].answers.splice(choiceIndex, 1, e.target.checked)
                setCourseEditor({
                    ...courseEditor,
                    chapters: newChapters
                })
                break;

            default:
                break;
        }
    }
    const handleChangeStep = (e, chapIndex, stepIndex) => {
        setActiveExpanded({
            ...activeExpanded,
            chapExpanded: chapIndex,
            stepActive: stepIndex
        })
        // set lesson content here...
        setLessonContent(chapIndex, stepIndex)
    }
    const handleChangeAcordion = (e, chapIndex) => {
        // console.log(chapIndex)
        handleHideAddingMenu();
        setActiveExpanded({
            ...activeExpanded,
            chapExpanded: activeExpanded.chapExpanded === chapIndex ? -1 : chapIndex,
            stepActive: 0
        })
        // set lesson content here...
        setLessonContent(chapIndex, 0)
    };
    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        console.log('change image logo')
        var loader = document.getElementById('logoLoading').style
        loader.display = 'block'
        var prevState = courseEditor

        var { target: { files } } = e
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...courseEditor.files];
        var filesToStore = [...courseEditor.filesToStoreInDB];
        [...files].forEach(file => {
            filesToStore.push(file)
            var convertResult = ''
            calculatedSize += file.size

            toBase64(file)
                .then(result => {
                    convertResult = result
                    filesToSend.push({
                        content: convertResult,
                        filename: file.name,
                        type: file.type,
                        disposition: "attachment"
                    })
                    // console.log(calculatedSize)
                    if (courseEditor.filesSize >= maxSize || calculatedSize >= maxSize) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'Selected files are too big to attach.',
                        })
                        loader.display = 'none'
                    }
                    else {
                        if (courseEditor.filesSize + calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                            loader.display = 'none'
                        }
                        else {
                            // setCourseEditor({
                            //     ...courseEditor,
                            //     files: filesToSend,
                            //     filesSize: courseEditor.filesSize + calculatedSize,
                            //     filesToStoreInDB: filesToStore,
                            // });
                            // console.log(filesToSend)
                            prevState.files = filesToSend
                            prevState.filesSize = prevState.filesSize + calculatedSize
                            prevState.filesToStoreInDB = filesToStore
                            updateCourseLogo(prevState)
                                .then(successUrl => {
                                    console.log('changed logo', successUrl)
                                    setCourseEditor({
                                        ...courseEditor,
                                        files: filesToSend,
                                        filesSize: courseEditor.filesSize + calculatedSize,
                                        filesToStoreInDB: filesToStore,
                                        LogoUrl: successUrl
                                    });
                                    loader.display = 'none'
                                })
                                .catch(err => {
                                    console.log('error occurred')
                                    loader.display = 'none'
                                })
                        }
                    }
                })
        })
    }
    const handleShowAddingMenu = (e, chapIndex) => {
        setShowAddingMenu(true);
    }
    const handleHideAddingMenu = (e, chapIndex) => {
        setShowAddingMenu(false);
    }

    const dragOver = (e) => {
        e.preventDefault();
    }
    const dragEnter = (e) => {
        e.preventDefault();
    }
    const dragLeave = (e) => {
        e.preventDefault();
    }
    const fileDrop = (e) => {
        e.preventDefault();
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        // for ref please check this article here: https://blog.logrocket.com/create-a-drag-and-drop-component-with-react-dropzone/
        var files = e.dataTransfer.files;
        var maxFileSize = 1000000000 // 1gb
        if (files.length > 1) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Only one file allowed.',
            })
            dropProgress.style.visibility = 'hidden';
        }
        else {
            [...files].forEach(file => {
                if (file.size > maxFileSize) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'Selected file is too big to process. Please reduce the size of the file.',
                    })
                    dropProgress.style.visibility = 'hidden';
                } else {
                    // console.log(file)
                    if (file.type !== 'video/mp4') {
                        setSnackbar({
                            open: true,
                            severity: 'error',
                            message: 'Please make sure that your are uploading a Video file.',
                        })
                        dropProgress.style.visibility = 'hidden';
                    }
                    else {
                        storage
                            .ref('WiseAcademy/CourseVideos/' + courseEditor.Title + '/Chapter' + activeExpanded.chapExpanded + '/Step' + activeExpanded.stepActive)
                            .put(file)
                            .then((snap) => {
                                snap.ref.getDownloadURL().then(vidUrl => {
                                    // console.log(vidUrl)
                                    var newChapters = courseEditor.chapters
                                    newChapters[activeExpanded.chapExpanded].chapStepsContent[activeExpanded.stepActive].videoUrl = vidUrl
                                    setCourseEditor({
                                        ...courseEditor,
                                        chapters: newChapters
                                    })
                                    setLessonContent(activeExpanded.chapExpanded, activeExpanded.stepActive)

                                    dropProgress.style.visibility = 'hidden';
                                })
                            })
                            .catch(err => {
                                console.log(err);
                                dropProgress.style.visibility = 'hidden';
                            })
                    }
                }
            })
        }
    }
    const fileSelect = (e) => {
        var { target: { files } } = e;
        var maxFileSize = 1000000000 // 1gb
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';

        [...files].forEach(file => {
            if (file.size > maxFileSize) {
                setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: 'Selected file is too big to process.',
                })
                dropProgress.style.visibility = 'hidden';
            } else {
                console.log(file)
                storage
                    .ref('WiseAcademy/CourseVideos/' + courseEditor.Title + '/Chapter' + activeExpanded.chapExpanded + '/Step' + activeExpanded.stepActive)
                    .put(file)
                    .then((snap) => {
                        snap.ref.getDownloadURL().then(vidUrl => {
                            console.log(vidUrl)
                            var newChapters = courseEditor.chapters
                            newChapters[activeExpanded.chapExpanded].chapStepsContent[activeExpanded.stepActive].videoUrl = vidUrl
                            setCourseEditor({
                                ...courseEditor,
                                chapters: newChapters
                            })
                            setLessonContent(activeExpanded.chapExpanded, activeExpanded.stepActive)

                            dropProgress.style.visibility = 'hidden';
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        dropProgress.style.visibility = 'hidden';
                    })
            }
        })
    }
    const pdfFileDrop = (e) => {
        e.preventDefault();
        var dropProgress = document.getElementById('pdfDropProgress');
        dropProgress.style.visibility = 'visible';
        // for ref please check this article here: https://blog.logrocket.com/create-a-drag-and-drop-component-with-react-dropzone/
        var files = e.dataTransfer.files;
        var maxFileSize = 500000000 // 500mb
        if (files.length > 1) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Only one file allowed.',
            })
            dropProgress.style.visibility = 'hidden';
        }
        else {
            [...files].forEach(file => {
                if (file.size > maxFileSize) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'Selected file is too big to process. Please reduce the size of the file.',
                    })
                    dropProgress.style.visibility = 'hidden';
                } else {
                    // console.log(file)
                    if (file.type !== 'application/pdf') {
                        setSnackbar({
                            open: true,
                            severity: 'error',
                            message: 'Please make sure that your are uploading a PDF file.',
                        })
                        dropProgress.style.visibility = 'hidden';
                    }
                    else {
                        storage
                            .ref('WiseAcademy/CoursePdfs/' + courseEditor.Title + '/Chapter' + activeExpanded.chapExpanded + '/Step' + activeExpanded.stepActive + '/' + file.name)
                            .put(file)
                            .then((snap) => {
                                snap.ref.getDownloadURL().then(fileUrl => {
                                    // console.log(fileUrl)
                                    var newChapters = courseEditor.chapters
                                    newChapters[activeExpanded.chapExpanded].chapStepsContent[activeExpanded.stepActive].pdf = { name: file.name, url: fileUrl }
                                    setCourseEditor({
                                        ...courseEditor,
                                        chapters: newChapters
                                    })
                                    setLessonContent(activeExpanded.chapExpanded, activeExpanded.stepActive)

                                    dropProgress.style.visibility = 'hidden';
                                })
                            })
                            .catch(err => {
                                console.log(err);
                                dropProgress.style.visibility = 'hidden';
                            })
                    }
                }
            })
        }
    }
    const pdfFileSelect = (e) => {
        var { target: { files } } = e;
        var maxFileSize = 500000000 // 500mb
        var dropProgress = document.getElementById('pdfDropProgress');
        dropProgress.style.visibility = 'visible';

        [...files].forEach(file => {
            if (file.size > maxFileSize) {
                setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: 'Selected file is too big to process.',
                })
                dropProgress.style.visibility = 'hidden';
            } else {
                // console.log(file)
                storage
                    .ref('WiseAcademy/CoursePdfs/' + courseEditor.Title + '/Chapter' + activeExpanded.chapExpanded + '/Step' + activeExpanded.stepActive + '/' + file.name)
                    .put(file)
                    .then((snap) => {
                        snap.ref.getDownloadURL().then(fileUrl => {
                            // console.log(fileUrl)
                            var newChapters = courseEditor.chapters
                            newChapters[activeExpanded.chapExpanded].chapStepsContent[activeExpanded.stepActive].pdf = { name: file.name, url: fileUrl }
                            setCourseEditor({
                                ...courseEditor,
                                chapters: newChapters
                            })
                            setLessonContent(activeExpanded.chapExpanded, activeExpanded.stepActive)

                            dropProgress.style.visibility = 'hidden';
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        dropProgress.style.visibility = 'hidden';
                    })
            }
        })
    }
    const handleAddQuestion = () => {
        var newChapters = courseEditor.chapters
        newChapters[activeExpanded.chapExpanded].chapStepsContent[activeExpanded.stepActive].questions ? newChapters[activeExpanded.chapExpanded].chapStepsContent[activeExpanded.stepActive].questions.push(defaultQuestion) : newChapters[activeExpanded.chapExpanded].chapStepsContent[activeExpanded.stepActive].questions = [defaultQuiz]
        setCourseEditor({
            ...courseEditor,
            chapters: newChapters
        })
    }
    const handleAddChoice = (questionIndex) => {
        var newChapters = courseEditor.chapters
        newChapters[activeExpanded.chapExpanded].chapStepsContent[activeExpanded.stepActive].questions[questionIndex].choices.push(defaultChoice)
        newChapters[activeExpanded.chapExpanded].chapStepsContent[activeExpanded.stepActive].questions[questionIndex].answers.push(defaultAnswer)
        setCourseEditor({
            ...courseEditor,
            chapters: newChapters
        })
    }


    const saveCourse = (e) => {
        e.preventDefault()
        updateCourse(courseEditor)
            .then(success => {
                console.log('course updated')
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                });
                if (courseEditor.Status !== courseEditor.newStatus) {
                    setCourseEditor({
                        ...courseEditor,
                        Status: courseEditor.newStatus
                    })
                }
            })
            .catch(error => {
                console.log(error)
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                });
            })
    }

    if (!isAuthenticated || role === 'viewAcc') {

        return <Redirect to='/' />
    }
    else {
        return (
            <div className={classes.root}>
                {/* <CssBaseline > */}
                {/* <SideMenu /> */}

                <Container component="main" className={classes.main} maxWidth="xl">

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center" className={classes.marginOne}>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <EditIcon fontSize="large" style={{ marginRight: 10 }} />
                            <h3>Course designer</h3>
                        </div>

                        <div>
                            <Button className={classes.buttonMarginAround}
                                onClick={cancelEditing}
                                startIcon={<ClearIcon />}
                                size='medium'
                                variant='contained'
                                color='secondary'>
                                Cancel Edit
                            </Button>
                            <Button className={classes.buttonMarginAround}
                                onClick={saveCourse}
                                startIcon={<SaveOutlinedIcon />}
                                size="medium"
                                variant="contained"
                                // fullWidth
                                // color="primary"
                                style={{ backgroundColor: 'lightgreen' }}
                            >
                                Save Course
                            </Button>
                        </div>
                    </Grid>

                </Container>

                <Card className={classes.cardContent}>
                    <Typography variant="h4" className={classes.courseTitle}>
                        {courseEditor.Title}
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <Paper className={classes.xs3Grid}>
                                <div id='chaptersDiv' className={classes.chaptersCard}>

                                    {courseEditor.chapters.map((chapter, chapIndex) => (
                                        <Accordion key={chapIndex} expanded={activeExpanded.chapExpanded === chapIndex ? true : false} onChange={(e) => handleChangeAcordion(e, chapIndex)} className={classes.acordionMenu}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={"panel" + chapIndex + "Content"}
                                                id={chapIndex}
                                            >
                                                <Typography className={classes.heading}>{chapter.chapTitle}</Typography>
                                                {/* <Typography className={classes.secondaryHeading}>{completedChapter1Steps(course.Chapter1Completed) + '/' + totalChapter1Steps()}</Typography> */}
                                            </AccordionSummary>
                                            <AccordionDetails >
                                                {chapter.chapSteps ?
                                                    <Stepper nonLinear activeStep={activeExpanded.stepActive} orientation="vertical" className={classes.acordionMenu}>
                                                        {chapter.chapSteps.map((label, stepIndex) => (
                                                            <Step key={stepIndex}>
                                                                {/* <StepButton onClick={(e) => handleChangeStep(e, chapIndex, stepIndex)} completed={chapter.chapCompleted[stepIndex]}> */}
                                                                <StepButton onClick={(e) => handleChangeStep(e, chapIndex, stepIndex)} >
                                                                    {label}
                                                                </StepButton>
                                                            </Step>
                                                        ))}
                                                    </Stepper> : null}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                    {activeExpanded.chapExpanded >= 0 ?
                                        <div className={classes.center}>
                                            <Button className={classes.buttonMarginAround}
                                                // onClick={(e) => handleAddStep(e, activeExpanded.chapExpanded)}
                                                onClick={(e) => handleShowAddingMenu(e, activeExpanded.chapExpanded)}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<AddIcon />}>
                                                Add Lesson
                                            </Button>
                                        </div>
                                        : null}
                                </div>

                                <div className={classes.center}>
                                    <Button className={classes.buttonMarginAround}
                                        onClick={handleAddChapter}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<AddIcon />}>
                                        Add Chapter
                                    </Button>
                                </div>

                            </Paper>
                        </Grid>
                        <Grid item xs={9} >
                            <Paper className={classes.xs9Grid}>
                                {activeExpanded.chapExpanded === -1 ?
                                    <div>
                                        <div style={{ textAlign: 'right' }}>
                                            {courseEditor.Status === 'Draft' ?
                                                <Typography variant="button" style={{ color: 'red', margin: 8 }}>
                                                    {courseEditor.Status}
                                                </Typography>
                                                :
                                                <Typography variant="button" style={{ color: 'green', margin: 8 }}>
                                                    {courseEditor.Status}
                                                </Typography>}
                                        </div>
                                        <div className={classes.center}>
                                            <img className={classes.cardMedia}
                                                src={courseEditor.LogoUrl}
                                                alt={courseEditor.Title}
                                            />
                                            <div id='logoLoading' style={{ width: 200, marginLeft: 'auto', marginRight: 'auto', display: 'none' }} >
                                                <Loading />
                                            </div>
                                        </div>
                                        <div className={classes.center}>
                                            <input
                                                accept="image/*"
                                                className={classes.input}
                                                id="add-contained-button-file"
                                                // multiple
                                                type="file"
                                                onChange={handleChangeFilePicker}
                                            />
                                            <label htmlFor="add-contained-button-file">
                                                <Button className={classes.buttonMarginAround}
                                                    // onClick={handleChangeFilePicker}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    component="span"
                                                    startIcon={<AttachFileIcon />}>
                                                    Change Logo
                                                </Button>
                                            </label>
                                        </div>
                                        <TextField
                                            select
                                            fullWidth
                                            id="courseCategory"
                                            name="courseCategory"
                                            label="Course Category"
                                            className={classes.textfield}
                                            value={courseEditor.Category}
                                            onChange={(e) => handleChange(e, 999, 999, 999, 999)}
                                            // helperText='Save course status as "Draft" or "Published"'
                                            InputProps={{
                                                startAdornment: <EditIcon />
                                            }}
                                        >
                                            {courseCategory.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            size="small"
                                            name="courseTitle"
                                            id="CourseTitle"
                                            label="Course Title"
                                            value={courseEditor.Title}
                                            InputProps={{
                                                startAdornment: <EditIcon />
                                            }}
                                            fullWidth
                                            className={classes.textfield}
                                            inputProps={{ maxLength: 100 }}
                                            helperText='* Up to 50 characters'
                                            onChange={(e) => handleChange(e, 999, 999, 999, 999)}
                                        />
                                        <TextField
                                            size="small"
                                            name="courseDescription"
                                            id="CourseDescription"
                                            label="Course Description"
                                            multiline
                                            fullWidth
                                            minRows={5}
                                            maxRows={5}
                                            value={courseEditor.Description}
                                            helperText='* Up to 500 characters'
                                            inputProps={{ maxLength: 500 }}
                                            InputProps={{ startAdornment: <EditIcon /> }}
                                            className={classes.textfield}
                                            onChange={(e) => handleChange(e, 999, 999, 999, 999)}
                                        />
                                        <TextField
                                            select
                                            fullWidth
                                            id="CourseStatus"
                                            name="courseStatus"
                                            label="Course Status"
                                            className={classes.textfield}
                                            value={courseEditor.newStatus}
                                            onChange={(e) => handleChange(e, 999, 999, 999, 999)}
                                            helperText='Save course status as "Draft" or "Published"'
                                            InputProps={{
                                                startAdornment: <EditIcon />
                                            }}
                                        >
                                            {courseStatus.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div style={{ textAlign: 'right' }}>
                                            {/* <Button className={classes.buttonMarginAround}
                                                    // onClick={(e) => handleAddStep(e, chapIndex)}
                                                    variant="outlined"
                                                    // fullWidth
                                                    color="secondary"
                                                    size="small">
                                                    Discard changes
                                                </Button> */}
                                            <Button className={classes.buttonMarginAround}
                                                onClick={saveCourse}
                                                startIcon={<SaveOutlinedIcon />}
                                                size="medium"
                                                variant="contained"
                                                // fullWidth
                                                // color="primary"
                                                style={{ backgroundColor: 'lightgreen' }}
                                            >
                                                Save Course
                                            </Button>
                                        </div>
                                        {/* <Tooltip title="Remove Item">
                                            <IconButton aria-label="delete" onClick={(e)=>handelRemoveItem(e,hapIndex, stepIndex,999,999)} className={classes.removeBtn}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </Tooltip> */}
                                    </div>
                                    : null}

                                {activeExpanded.chapExpanded >= 0 ?
                                    courseEditor.chapters.map((chapter, chapIndex) => (
                                        activeExpanded.chapExpanded === chapIndex ?
                                            <div key={chapIndex}>
                                                <TextField
                                                    size="small"
                                                    name="chapTitle"
                                                    id={"ChapterTitle" + chapIndex}
                                                    label="Chapter Title"
                                                    // variant="outlined"
                                                    value={courseEditor.chapters[chapIndex].chapTitle}
                                                    InputProps={{
                                                        startAdornment: <EditIcon />
                                                    }}
                                                    fullWidth
                                                    className={classes.textfield}
                                                    inputProps={{ maxLength: 100 }}
                                                    onChange={(e) => handleChange(e, chapIndex, 999, 999, 999)}
                                                />
                                                <Tooltip title="Remove Item">
                                                    <IconButton aria-label="delete" name="removeChapter" onClick={(e) => handelRemoveItem('removeChapter', chapIndex, 999, 999, 999)} className={classes.removeBtn}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </Tooltip>

                                                {/* adding menu options */}
                                                {showAddingMenu ?
                                                    <Grid container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        className={classes.addingMenuDiv}
                                                    >
                                                        <Grid container item xs={6}
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                        >
                                                            <Button variant="outlined" color="default" disableElevation fullWidth onClick={() => handleAddLesson('videoLesson', chapIndex)} className={classes.addingMenuButton}>
                                                                <Grid item className={classes.addingMenuIcons}>
                                                                    <VideocamOutlinedIcon />
                                                                </Grid>
                                                                <Grid item className={classes.addingMenuDescription}>
                                                                    <Typography variant="button" display="block" gutterBottom color="textSecondary">Video:</Typography>
                                                                    <Typography variant="body2" color="textSecondary" component="p">Easily upload and display your video content.</Typography>
                                                                </Grid>
                                                            </Button>
                                                        </Grid>

                                                        <Grid container item xs={6}
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                        >
                                                            <Button variant="outlined" color="default" disableElevation fullWidth onClick={() => handleAddLesson('textLesson', chapIndex)} className={classes.addingMenuButton}>
                                                                <Grid item className={classes.addingMenuIcons}>
                                                                    <TextFieldsOutlinedIcon />
                                                                </Grid>
                                                                <Grid item className={classes.addingMenuDescription}>
                                                                    <Typography variant="button" display="block" gutterBottom color="textSecondary">Text:</Typography>
                                                                    <Typography variant="body2" color="textSecondary" component="p">Include lines of text as well as images or external links.</Typography>
                                                                </Grid>
                                                            </Button>
                                                        </Grid>

                                                        <Grid container item xs={6}
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                        >
                                                            <Button variant="outlined" color="default" disableElevation fullWidth onClick={() => handleAddLesson('quizLesson', chapIndex)} className={classes.addingMenuButton}>
                                                                <Grid item className={classes.addingMenuIcons}>
                                                                    <AssignmentTurnedInOutlinedIcon />
                                                                </Grid>
                                                                <Grid item className={classes.addingMenuDescription}>
                                                                    <Typography variant="button" display="block" gutterBottom color="textSecondary">Quiz:</Typography>
                                                                    <Typography variant="body2" color="textSecondary" component="p">Allow students to interact with material that was just taught or presented.</Typography>
                                                                </Grid>
                                                            </Button>
                                                        </Grid>

                                                    </Grid>
                                                    : null}

                                                {chapter.chapSteps ? chapter.chapSteps.map((label, stepIndex) => (
                                                    activeExpanded.stepActive === stepIndex ?
                                                        <div key={stepIndex}>
                                                            <div key={stepIndex}>
                                                                <TextField
                                                                    size="small"
                                                                    name="chapStep"
                                                                    id={"ChapterStep" + stepIndex}
                                                                    label="Lesson Title"
                                                                    // variant="outlined"
                                                                    value={courseEditor.chapters[chapIndex].chapSteps[stepIndex]}
                                                                    InputProps={{
                                                                        startAdornment: <EditIcon />
                                                                    }}
                                                                    fullWidth
                                                                    className={classes.textfield}
                                                                    inputProps={{ maxLength: 100 }}
                                                                    onChange={(e) => handleChange(e, chapIndex, stepIndex, 999, 999)}
                                                                />
                                                                <Tooltip title="Remove Item">
                                                                    <IconButton aria-label="delete" name="removeLesson" onClick={(e) => handelRemoveItem('removeLesson', chapIndex, stepIndex, 999, 999)} className={classes.removeBtn}>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>

                                                            {activeExpanded.chapExpanded === chapIndex && activeExpanded.stepActive === stepIndex ?
                                                                <div>
                                                                    {lessonVideo.isVideoLesson ?
                                                                        <div>
                                                                            {lessonVideo.videoUrl === '' ?
                                                                                <div className={classes.videoDiv}>
                                                                                    <img className={classes.videoSize} src={videoPlaceholder} />
                                                                                    <p>Please add your video below.</p>
                                                                                </div>
                                                                                :
                                                                                <div className={classes.videoDiv}>
                                                                                    <video className={classes.videoSize} src={courseEditor.chapters[chapIndex].chapStepsContent[stepIndex].videoUrl}
                                                                                        type="video/mp4"
                                                                                        // poster={poster}
                                                                                        controls
                                                                                        controlsList="nodownload">
                                                                                        Your browser does not support HTML video.
                                                                                    </video>
                                                                                </div>}
                                                                            <div className={classes.dropzone}
                                                                                onDragOver={dragOver}
                                                                                onDragEnter={dragEnter}
                                                                                onDragLeave={dragLeave}
                                                                                onDrop={fileDrop}>
                                                                                <LinearProgress id='dropProgress' className={classes.dropProgress} />
                                                                                <GetAppIcon />
                                                                                <p>Drag & Drop Video file here</p>
                                                                                <h4>Or</h4>
                                                                                <input type="file" accept="video/*" id="addFiles" className={classes.input} onChange={fileSelect} />
                                                                                <label htmlFor="addFiles">
                                                                                    <Button
                                                                                        className={classes.marginBottom}
                                                                                        variant="contained"
                                                                                        color="default"
                                                                                        component="span"
                                                                                        startIcon={<AttachFileIcon />}>
                                                                                        Select Video file
                                                                                    </Button>
                                                                                </label>
                                                                            </div>


                                                                            {/* TODO: uncomment to add pdf option to video lesson
                                                                            {lessonVideo.pdf ?
                                                                                <div className={classes.pdfDiv}>
                                                                                    <Tooltip title='Download'>
                                                                                        <Button
                                                                                            variant='outlined'
                                                                                            startIcon={<PictureAsPdfIcon />}
                                                                                            onClick={e => handleDownload(e, lessonVideo.pdf)}>
                                                                                            {lessonVideo.pdf.name}
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Remove Item">
                                                                                        <IconButton aria-label="delete" name="removeLesson"
                                                                                            onClick={(e) => handelRemoveItem('removePdf', chapIndex, stepIndex, 999, 999)}
                                                                                            className={classes.removeBtn}>
                                                                                            <DeleteForeverIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </div>
                                                                                :
                                                                                <div className={classes.pdfDiv}>
                                                                                    <p>Please add your PDF file below (optional).</p>
                                                                                </div>}
                                                                            <div className={classes.pdfDropzone}
                                                                                onDragOver={dragOver}
                                                                                onDragEnter={dragEnter}
                                                                                onDragLeave={dragLeave}
                                                                                onDrop={pdfFileDrop}>
                                                                                <LinearProgress id='pdfDropProgress' className={classes.pdfDropProgress} />
                                                                                <GetAppIcon />
                                                                                <p>Drag & Drop PDF file here</p>
                                                                                <h4>Or</h4>
                                                                                <input type="file" accept="application/pdf" id="addPdfFiles" className={classes.input} onChange={pdfFileSelect} />
                                                                                <label htmlFor="addPdfFiles">
                                                                                    <Button
                                                                                        className={classes.marginBottom}
                                                                                        variant="contained"
                                                                                        color="default"
                                                                                        component="span"
                                                                                        startIcon={<AttachFileIcon />}>
                                                                                        Select PDF file
                                                                                    </Button>
                                                                                </label>
                                                                            </div> */}




                                                                            <div className={classes.textOptionalMargin}>
                                                                                <TextField
                                                                                    name="videoTextOptional"
                                                                                    label="Add Text (optional)"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    multiline
                                                                                    rows={6}
                                                                                    helperText='* Up to 500 characters'
                                                                                    InputProps={{ startAdornment: <EditIcon /> }}
                                                                                    inputProps={{ maxLength: 500 }}
                                                                                    value={courseEditor.chapters[chapIndex].chapStepsContent[stepIndex].textOptional}
                                                                                    onChange={(e) => handleChange(e, chapIndex, stepIndex, 999, 999)}
                                                                                />
                                                                            </div>
                                                                            <br />
                                                                            <br />
                                                                            <br />
                                                                            {/* <br />
                                                                                <div style={{ textAlign: 'right' }}>
                                                                                    <Button className={classes.buttonMarginAround}
                                                                                        onClick={saveCourse}
                                                                                        variant="contained"
                                                                                        // fullWidth
                                                                                        color="primary"
                                                                                        size="small">
                                                                                        Save Course
                                                                                    </Button>
                                                                                </div> */}
                                                                        </div>
                                                                        : null}

                                                                    {lessonText.isTextLesson ?
                                                                        <div className={classes.textOptionalMargin}>
                                                                            <TextField
                                                                                size="small"
                                                                                name="textFieldLesson"
                                                                                id="textFieldLesson"
                                                                                label="Add Text"
                                                                                variant="outlined"
                                                                                multiline
                                                                                rows={10}
                                                                                helperText='* Up to 1000 characters'
                                                                                value={courseEditor.chapters[chapIndex].chapStepsContent[stepIndex].text}
                                                                                InputProps={{ startAdornment: <EditIcon /> }}
                                                                                inputProps={{ maxLength: 1000 }}
                                                                                fullWidth
                                                                                // className={classes.textfield}
                                                                                onChange={(e) => handleChange(e, chapIndex, stepIndex, 999, 999)}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {lessonQuiz.isQuizLesson ?
                                                                        <div>
                                                                            {chapter.chapStepsContent[stepIndex].questions.map((question, questionIndex) => (

                                                                                <div key={questionIndex} className={classes.marginTwo}>
                                                                                    <Accordion square key={questionIndex} expanded={expandedQuestion === 'panel' + questionIndex} onChange={handleChangeExpandedQuestion('panel' + questionIndex)} className={classes.questionsAcordion}>
                                                                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                                                            <Grid container className={classes.questionAcordionTextDiv}
                                                                                                direction="row"
                                                                                                justifyContent="flex-start"
                                                                                                alignItems="center"                                                                                                    >
                                                                                                <Typography variant="h6" display="block" gutterBottom className={classes.questionHeader}>Question #{questionIndex + 1}</Typography>
                                                                                            </Grid>
                                                                                            <Tooltip title="Remove Item">
                                                                                                <IconButton aria-label="delete" name="removeQuestion" onClick={(e) => handelRemoveItem('removeQuestion', chapIndex, stepIndex, questionIndex, 9999)} className={classes.removeBtn}>
                                                                                                    <DeleteForeverIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <Grid container
                                                                                                direction="column"
                                                                                                justifyContent="center"
                                                                                                alignItems="center"                                                                                                >
                                                                                                <TextField
                                                                                                    select
                                                                                                    fullWidth
                                                                                                    id="QuestionType"
                                                                                                    name="questionType"
                                                                                                    label="Question Type"
                                                                                                    className={classes.textfield}
                                                                                                    value={courseEditor.chapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].type}
                                                                                                    onChange={(e) => handleChange(e, chapIndex, stepIndex, questionIndex, 999)}
                                                                                                    InputProps={{
                                                                                                        startAdornment: <EditIcon />
                                                                                                    }}
                                                                                                >
                                                                                                    {questionTypes.map((option) => (
                                                                                                        <MenuItem key={option.value} value={option.value}>
                                                                                                            {option.label}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </TextField>
                                                                                                <TextField
                                                                                                    size="small"
                                                                                                    name="question"
                                                                                                    id={"Question" + questionIndex}
                                                                                                    label="Question"
                                                                                                    // variant="outlined"
                                                                                                    value={courseEditor.chapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].question}
                                                                                                    InputProps={{
                                                                                                        startAdornment: <EditIcon />
                                                                                                    }}
                                                                                                    fullWidth
                                                                                                    className={classes.textfield}
                                                                                                    inputProps={{ maxLength: 500 }}
                                                                                                    onChange={(e) => handleChange(e, chapIndex, stepIndex, questionIndex, 999)}
                                                                                                />
                                                                                                {question.choices.map((choice, choiceIndex) => (
                                                                                                    <div key={choiceIndex}>
                                                                                                        <Typography variant="button" display="block" gutterBottom className={classes.questionAndChoice}>Choice #{choiceIndex + 1}</Typography>
                                                                                                        <TextField
                                                                                                            size="small"
                                                                                                            name="choice"
                                                                                                            id={"Choice" + choiceIndex}
                                                                                                            label="Choice"
                                                                                                            // variant="outlined"
                                                                                                            value={courseEditor.chapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].choices[choiceIndex]}
                                                                                                            InputProps={{
                                                                                                                startAdornment: <EditIcon />
                                                                                                            }}
                                                                                                            fullWidth
                                                                                                            className={classes.textfield}
                                                                                                            inputProps={{ maxLength: 500 }}
                                                                                                            onChange={(e) => handleChange(e, chapIndex, stepIndex, questionIndex, choiceIndex)}
                                                                                                        />
                                                                                                        <Tooltip title="Remove Item">
                                                                                                            <IconButton aria-label="delete" name="removeChoice" onClick={(e) => handelRemoveItem('removeChoice', chapIndex, stepIndex, questionIndex, choiceIndex)} className={classes.removeBtn}>
                                                                                                                <DeleteForeverIcon />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                        <FormControlLabel className={classes.questionCheckbox}
                                                                                                            control={
                                                                                                                <Checkbox
                                                                                                                    checked={courseEditor.chapters[chapIndex].chapStepsContent[stepIndex].questions[questionIndex].answers[choiceIndex]}
                                                                                                                    onChange={(e) => handleCheckboxChange(e, chapIndex, stepIndex, questionIndex, choiceIndex, question.type)}
                                                                                                                    name={"check" + choiceIndex}
                                                                                                                    color="primary"
                                                                                                                />
                                                                                                            }
                                                                                                            label="This is the correct answer"
                                                                                                        />
                                                                                                    </div>
                                                                                                ))}
                                                                                                {/* <div style={{ textAlign: 'left' }}> */}
                                                                                                <Button className={classes.buttonAddChoiceMarginAround}
                                                                                                    onClick={() => handleAddChoice(questionIndex)}
                                                                                                    variant="contained"
                                                                                                    // fullWidth
                                                                                                    color="primary"
                                                                                                    size="small">
                                                                                                    Add Choice
                                                                                                </Button>
                                                                                                {/* </div> */}
                                                                                            </Grid>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </div>
                                                                            ))}
                                                                            <div style={{ textAlign: 'right' }}>
                                                                                <Button className={classes.buttonMarginAround}
                                                                                    onClick={handleAddQuestion}
                                                                                    variant="contained"
                                                                                    // fullWidth
                                                                                    color="primary"
                                                                                    size="small">
                                                                                    Add Question
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                                : null}

                                                        </div>
                                                        : null
                                                )) : null}

                                            </div>
                                            : null
                                    ))
                                    : null}
                            </Paper>
                        </Grid>
                    </Grid>

                </Card>

                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                <Footer />
            </div>
        )
    }
}