import React from 'react';
import { makeStyles } from '@material-ui/core';
import summerBBQ2024 from '../../../../images/BBQ2024.jpg'
import summerBBQ2024Winners from '../../../../images/BBQ2024Winners.png'
// import bbq2023slidesVideo from '../../../../images/bbq2023slidesVideo.mp4'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaVideoSlide: {
        width: '100%',
        borderRadius: 20,
    },
    media: {
        width: '60%',
        borderRadius: 20,
    },
}));

export default function BBQ2024(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <div className={classes.textCentered}>
                <img className={classes.media} src={summerBBQ2024Winners} alt="Annual Summer BBQ Winners" />
            </div>
            <br />
            <br />
            <div className={classes.textCentered}>
                <img className={classes.media} src={summerBBQ2024} alt="Annual Summer BBQ" />
            </div>
            <br />
            <br />
            <div className={classes.textCentered}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2461.9878682668373!2d-8.4674791!3d51.897685499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4844900f84eee3e5%3A0xe7c5da5b14b0c4f8!2sMaldron%20Hotel%20South%20Mall%20Cork%20City!5e0!3m2!1sen!2sie!4v1715159221137!5m2!1sen!2sie" width="90%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <br /><br />
                <h4>Thank you for joining Us!</h4>
                <br /><br />
            </div>

            <br />
            <p>Regards,<br />
                Eleanor Lester<br />
                HR Co-Ordinator | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}