import React, { Component, createContext } from 'react';
import moment from 'moment';
import firebase, { auth, db, functions, storage } from '../config/fbConfig';

export const DataContext = createContext();

class DataContextProvider extends Component {
    state = {
        // issueErrorListData: {},
        // changeRequestListData: {},
        wiseStarsLocations: [],
        listLocations: [],
        listDepartments: [],
        listManagers: [],
        listSalespersons: [],
        countryCodes: [],
        partnerCalculatorCategories: [],
        partnerCalculatorPartners: [],
        partnerCalculatorVendors: [],
    }

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if (user) {

                this.getDropdownLists();

            }
        })
    }

    getDropdownLists = () => {
        db.collection('wiseStarsLocations').orderBy('label').get()
            .then(snap => {
                var tempArray = [{ label: '', value: '' }]
                snap.forEach(doc => {
                    tempArray.push({ ...doc.data(), id: doc.id })
                })
                this.setState({
                    ...this.state,
                    wiseStarsLocations: tempArray
                })
            })
            .catch(err => { console.log('Error getting WiseStars locations dropdown lists:', err.message) })
        db.collection('listLocations').orderBy('label').get()
            .then(snap => {
                var tempArray = []
                snap.forEach(doc => {
                    tempArray.push({ ...doc.data(), id: doc.id })
                })
                this.setState({
                    ...this.state,
                    listLocations: tempArray
                })
            })
            .catch(err => { console.log('Error getting locations dropdown lists:', err.message) })
        db.collection('listDepartments').orderBy('label').get()
            .then(snap => {
                var tempArray = []
                snap.forEach(doc => {
                    tempArray.push({ ...doc.data(), id: doc.id })
                })
                this.setState({
                    ...this.state,
                    listDepartments: tempArray
                })
            })
            .catch(err => { console.log('Error getting departments dropdown lists:', err.message) })
        db.collection('teamManagers').orderBy('label').get()
            .then(snap => {
                var tempArray = []
                snap.forEach(doc => {
                    tempArray.push({ ...doc.data(), id: doc.id })
                })
                this.setState({
                    ...this.state,
                    listManagers: tempArray
                })
            })
            .catch(err => { console.log('Error getting managers dropdown lists:', err.message) })
        db.collection('listSalespersonsPickAlert').orderBy('label').get()
            .then(snap => {
                var tempArray = []
                snap.forEach(doc => {
                    tempArray.push({ ...doc.data(), id: doc.id })
                })
                this.setState({
                    ...this.state,
                    listSalespersons: tempArray
                })
            })
            .catch(err => { console.log('Error getting salespersons dropdown lists:', err.message) })
        db.collection('countryCodes').orderBy('value').get()
            .then(snap => {
                // var tempArray = []
                var tempArray = [{ label: '', value: '' }]
                snap.forEach(doc => {
                    tempArray.push({ ...doc.data(), id: doc.id })
                })
                this.setState({
                    ...this.state,
                    countryCodes: tempArray
                })
            })
            .catch(err => { console.log('Error getting country codes dropdown list:', err.message) })
        db.collection('partnerCalculatorCategories').orderBy('value').get()
            .then(snap => {
                var tempArray = []
                // var tempArray = [{ label: '', value: '' }]
                snap.forEach(doc => {
                    tempArray.push({ ...doc.data(), id: doc.id })
                })
                this.setState({
                    ...this.state,
                    partnerCalculatorCategories: tempArray
                })
            })
            .catch(err => { console.log('Error getting partnerCalculatorCategories list:', err.message) })
        db.collection('partnerCalculatorPartners').orderBy('value').get()
            .then(snap => {
                var tempArray = []
                // var tempArray = [{ label: '', value: '' }]
                snap.forEach(doc => {
                    tempArray.push({ ...doc.data(), id: doc.id })
                })
                this.setState({
                    ...this.state,
                    partnerCalculatorPartners: tempArray
                })
            })
            .catch(err => { console.log('Error getting partnerCalculatorPartners list:', err.message) })
        db.collection('partnerCalculatorVendors').orderBy('value').get()
            .then(snap => {
                var tempArray = []
                // var tempArray = [{ label: '', value: '' }]
                snap.forEach(doc => {
                    tempArray.push({ ...doc.data(), id: doc.id })
                })
                this.setState({
                    ...this.state,
                    partnerCalculatorVendors: tempArray
                })
            })
            .catch(err => { console.log('Error getting partnerCalculatorVendors list:', err.message) })
    }

    sendTicketSubmission = (data) => {
        const callable = functions.httpsCallable('sendTicketSubmission');
        return callable(data, auth)
        // .then(succ => {
        //     console.log('Ticket created, email sent.')
        //     return succ;
        // })
        // .catch(err => {
        //     console.log('Error occurred.')
        //     return err
        // })
    }
    sendEngTicketSubmission = (data) => {
        const callable = functions.httpsCallable('sendEngTicketSubmission');
        return callable(data, auth)
            .then(
                console.log('Ticket created, email sent.')
            )
        // .catch(
        //     console.log('Error occurred.')
        // )
    }
    sendFacilitiesTicketSubmission = (data) => {
        const callable = functions.httpsCallable('sendFacilitiesTicketSubmission');
        return callable(data, auth)
            .then(
                console.log('Facilities Ticket created, email sent.')
            )
    }
    sendCSTicketSubmission = (data) => {
        const callable = functions.httpsCallable('sendCSTicketSubmission');
        return callable(data, auth)
            .then(
                console.log('Customer Service Ticket created, email sent.')
            )
    }
    sendCSTicketResponse = (data) => {
        const callable = functions.httpsCallable('sendCSTicketResponse');
        console.log('data:', data)
        return callable(data, auth)
            .then(
                console.log('Customer Service Ticket updated, email sent.')
            )
    }
    sendHiringRequisitionForHR = (data) => {
        const callable = functions.httpsCallable('sendHiringRequisitionForHR');
        return callable(data, auth)
            .then(
                console.log('Hiring requisition created, email sent.')
            )
    }
    sendHiringRequisitionForHROneApproved = (data) => {
        const callable = functions.httpsCallable('sendHiringRequisitionForHROneApproved');
        return callable(data, auth)
            .then(
                console.log('Hiring requisition - one manager approved, email sent.')
            )
    }
    sendHiringRequisitionForHRBothApproved = (data) => {
        const callable = functions.httpsCallable('sendHiringRequisitionForHRBothApproved');
        return callable(data, auth)
            .then(
                console.log('Hiring requisition - both managers approved, email sent.')
            )
    }
    sendHiringRequisitionForHRrejected = (data) => {
        const callable = functions.httpsCallable('sendHiringRequisitionForHRrejected');
        return callable(data, auth)
            .then(
                console.log('Hiring requisition - request rejected, email sent.')
            )
    }
    sendRoomBookingConfirmation = (data) => {
        const callable = functions.httpsCallable('sendRoomBookingConfirmation');
        return callable(data, auth)
            .then(
                console.log('Room booked, email sent.')
            )
        // .catch(
        //     console.log('Error occurred.')
        // )
    }
    sendBackToOfficeForm = (data) => {
        const callable = functions.httpsCallable('sendBackToOfficeForm');
        return callable(data, auth)
            .then(
                console.log('Back to office email sent to H&S advisor.')
            )
    }
    sendFeedback = (data) => {
        const callable = functions.httpsCallable('sendFeedback');
        return callable(data, auth)
            .then(
                console.log('Feedback successfully submitted.')
            )
    }
    sendInternalPositionEmail = (data) => {
        const callable = functions.httpsCallable('sendInternalPositionEmail');
        return callable(data, auth)
            .then(
                console.log('Internal position email successfully submitted.')
            )
    }
    // getFactorialEmployees = (data) => {
    //     const callable = functions.httpsCallable('getFactorialEmployees');
    //     return callable(data, auth)
    //         .then(success => {
    //             console.log('used: ', success)
    //             console.log('data is: ', data.id, data.name)
    //         })
    // }

    //Utility Function
    delay = (ms) => new Promise(res => setTimeout(res, ms))

    createTicket = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser
            var date = new Date();
            var week = moment(date, 'MM-DD-YYYY').isoWeek();
            var tempFiles = []

            if (user) {
                this.sendTicketSubmission(data)
                    .then(() => {
                        // console.log('resp:', resp)
                        if (data.TicketType === 'Issue / Error') {

                            console.log(data)
                            db.collection('ticketsIT').add({

                                DateRaised: new Date(),
                                ClosedDate: new Date('9999/01/01'),
                                RaisedBy: data.userEmail,
                                RaisedByFullName: data.userFullName,
                                WeekNo: week,
                                IssueCategory: data.issueCategory,
                                RequestCategory: '',
                                Platform: data.Platform,
                                moreThanOnePlatform: data.moreThanOnePlatform,
                                Department: data.Department,
                                Site: data.Site,
                                Process: data.Process,
                                Area: data.issueArea,
                                NavErrorNo: data.issueNavErrorNo,
                                FailureCategory: '',
                                RootCauseCategory: '',
                                RootCauseDescription: data.issueDescription,
                                FixType: '',
                                FixUpdateDescription: '',
                                Status: 'Open',
                                AssignedIt: '',
                                TicketType: data.TicketType,
                                TicketSendFrom: 'Connect',
                                FilesURLs: [],
                                itFilesURLs: [],
                                isArchive: false,
                            })
                                .then(docRef => {
                                    // console.log(docRef.id)
                                    data.RecordUID = docRef.id;
                                    // save attachments if any added save in bucket ticketAttachments
                                    if (data.filesToStoreInDB.length > 0) {
                                        data.filesToStoreInDB.forEach(file => {
                                            console.log(file)
                                            storage
                                                .ref('ticketAttachments/' + data.RecordUID + '/' + file.name)
                                                .put(file)
                                                .then((snap) => {
                                                    snap.ref.getDownloadURL().then(imgUrl => {
                                                        console.log(imgUrl)
                                                        tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                                        db.collection("ticketsIT").doc(data.RecordUID).update({
                                                            FilesURLs: tempFiles,
                                                        })
                                                    })
                                                })
                                                .catch(err => {
                                                    console.log(err);
                                                    reject({ success: false, message: err.message, error: err })
                                                })
                                        })
                                        // this.sendTicketSubmission(data)
                                        resolve({ success: true, message: 'Ticket created successfully. Thank you.' })
                                    }
                                    else {
                                        // this.sendTicketSubmission(data)
                                        resolve({ success: true, message: 'Ticket created successfully. Thank you.' })
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                    reject({ success: false, message: err.message, error: err })
                                })
                        }
                        else { // change request
                            db.collection('ticketsIT').add({

                                DateRaised: new Date(moment.utc()),
                                ClosedDate: new Date('9999/01/01'),
                                RaisedBy: data.userEmail,
                                RaisedByFullName: data.userFullName,
                                WeekNo: week,
                                IssueCategory: '',
                                RequestCategory: data.requestCategory,
                                Platform: data.Platform,
                                moreThanOnePlatform: data.moreThanOnePlatform,
                                Department: data.Department,
                                Site: data.Site,
                                Process: data.Process,
                                Area: '',
                                NavErrorNo: '',
                                FailureCategory: '',
                                RootCauseCategory: '',
                                RootCauseDescription: data.requestDescription,
                                FixType: '',
                                FixUpdateDescription: '',
                                Status: 'Open',
                                AssignedIt: '',
                                TicketType: data.TicketType,
                                TicketSendFrom: 'Connect',
                                FilesURLs: [],
                                itFilesURLs: [],
                                isArchive: false,
                            })
                                .then(docRef => {
                                    // console.log(docRef.id)
                                    data.RecordUID = docRef.id;
                                    // save attachments if any added save in bucket ticketAttachments
                                    if (data.filesToStoreInDB.length > 0) {
                                        data.filesToStoreInDB.forEach(file => {
                                            console.log(file)
                                            storage
                                                .ref('ticketAttachments/' + data.RecordUID + '/' + file.name)
                                                .put(file)
                                                .then((snap) => {
                                                    snap.ref.getDownloadURL().then(imgUrl => {
                                                        console.log(imgUrl)
                                                        tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                                        db.collection("ticketsIT").doc(data.RecordUID).update({
                                                            FilesURLs: tempFiles,
                                                        })
                                                    })
                                                })
                                                .catch(err => {
                                                    console.log(err);
                                                    reject({ success: false, message: err.message, error: err })
                                                })
                                        })
                                        // this.sendTicketSubmission(data)
                                        resolve({ success: true, message: 'Ticket created successfully. Thank you.' })
                                    }
                                    else {
                                        // this.sendTicketSubmission(data)
                                        resolve({ success: true, message: 'Ticket created successfully. Thank you.' })
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                    reject({ success: false, message: err.message, error: err })
                                })
                        }
                    })
                    .catch((error) => {
                        console.warn('error', error);
                        console.warn('error message', error.message);
                        reject({ success: false, error: error, message: error.message })
                    })

            }
            else {
                console.log('Session expired please log back in.')
                reject({ success: false, message: 'Session expired please log back in.', error: new Error("Session expired please log back in.") });
            }
        })
    }

    updateTicket = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser
            var completionDate = new Date(moment.utc())

            if (user) {
                // if issue error update 7 fields including close date if ticket closed
                if (data.TicketType == 'Issue / Error') {

                    if (data.Status == 'Completed' || data.Status == 'Closed - Not Needed') {
                        db.collection("ticketsIT").doc(data.RecordUID).update({
                            //all variables tu update
                            Status: data.Status,
                            AssignedIt: data.AssignedIt,
                            RootCauseCategory: data.RootCauseCategory,
                            FixType: data.FixType,
                            FailureCategory: data.FailureCategory,
                            FixUpdateDescription: data.FixUpdateDescription,
                            ClosedDate: completionDate,
                        }).then(() => {
                            resolve('Ticket "Completed" or "Closed" successfully. Thank you.')
                        }).catch(err => {
                            console.log(err);
                            reject(err.message)
                        })
                    }
                    else {
                        db.collection("ticketsIT").doc(data.RecordUID).update({
                            //all variables to update
                            Status: data.Status,
                            AssignedIt: data.AssignedIt,
                            RootCauseCategory: data.RootCauseCategory,
                            FixType: data.FixType,
                            FailureCategory: data.FailureCategory,
                            FixUpdateDescription: data.FixUpdateDescription,
                        }).then(() => {
                            resolve('Ticket updated successfully. Thank you.')
                        }).catch(err => {
                            console.log(err);
                            reject(err.message)
                        })
                    }
                    // }
                }
                // else request update 4 fields including close date if ticket closed 
                else {

                    if (data.Status == 'Completed' || data.Status == 'Closed - Not Needed') {
                        db.collection("ticketsIT").doc(data.RecordUID).update({
                            Status: data.Status,
                            AssignedIt: data.AssignedIt,
                            FixUpdateDescription: data.FixUpdateDescription,
                            ClosedDate: completionDate,
                        }).then(() => {
                            resolve('Ticket "Completed" or "Closed" successfully. Thank you.')
                        }).catch(err => {
                            console.log(err);
                            reject(err.message)
                        })
                    }
                    else {
                        db.collection("ticketsIT").doc(data.RecordUID).update({
                            Status: data.Status,
                            AssignedIt: data.AssignedIt,
                            FixUpdateDescription: data.FixUpdateDescription,
                        }).then(() => {
                            resolve('Ticket updated successfully. Thank you.')
                        }).catch(err => {
                            console.log(err);
                            reject(err.message)
                        })
                    }
                }
            }
        })
    }
    addItAttachmentToBucketAndTicket = (file, recordID, newItFilesURLs) => {
        console.log(file, recordID, newItFilesURLs)
        return new Promise((resolve, reject) => {
            storage
                .ref('ticketAttachmentsITonly/' + recordID + '/' + file.name)
                .put(file)
                .then((snap) => {
                    snap.ref.getDownloadURL().then(imgUrl => {
                        // console.log(imgUrl)
                        newItFilesURLs.push({ name: file.name, url: imgUrl, type: file.type })
                        db.collection("ticketsIT").doc(recordID).update({
                            itFilesURLs: newItFilesURLs,
                        })
                    }).then(() => {
                        resolve({ newItFilesURLs: newItFilesURLs, message: 'Attachment added successfully' })
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
                })
                .catch((err) => {
                    reject(`Unable to add attachment: Code:${err.code_} Message: ${err.message}`)

                })
        })
    }
    removeItAttachmentFromBucketAndTicket = (url, recordID, newItFilesURLs) => {
        // console.log(url, newItFilesURLs)
        return new Promise((resolve, reject) => {
            var attachmentRef = storage.refFromURL(url);
            attachmentRef.delete()
                .then(() => {
                    db.collection("ticketsIT").doc(recordID).update({
                        itFilesURLs: newItFilesURLs,
                    }).then(() => {
                        resolve('Attachment removed successfully')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
                })
                .catch((err) => {
                    reject(`Unable to remove attachment: Code:${err.code_} Message: ${err.message}`)
                });
        })
    }

    createTicketEngineering = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser
            var date = new Date();
            var week = moment(date, 'MM-DD-YYYY').isoWeek();
            var tempFiles = []

            if (user) {
                console.log(data)
                db.collection('ticketsEngineering').add({

                    DateRaised: new Date(),
                    ClosedDate: new Date('9999/01/01'),
                    RaisedBy: data.userEmail,
                    RaisedByFullName: data.userFullName,
                    WeekNo: week,
                    TicketType: data.TicketType,
                    Division: data.Division,
                    Category: data.Category,
                    Action: data.Action,
                    Process: data.Process,
                    Site: data.Site,
                    Description: data.Description,
                    // response fields
                    AssignedEng: data.AssignedEng,//  coming in from auto assign or empty string
                    RootCauseCategory: '',
                    FixType: '',
                    FixUpdateDescription: '',
                    Status: 'Open',
                    FilesURLs: [],
                    engFilesURLs: []
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        data.RecordUID = docRef.id;
                        // save attachments if any added save in bucket ticketAttachments
                        if (data.filesToStoreInDB.length > 0) {
                            data.filesToStoreInDB.forEach(file => {
                                console.log(file)
                                storage
                                    .ref('ticketAttachmentsEng/' + data.RecordUID + '/' + file.name)
                                    .put(file)
                                    .then((snap) => {
                                        snap.ref.getDownloadURL().then(imgUrl => {
                                            console.log(imgUrl)
                                            tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                            db.collection("ticketsEngineering").doc(data.RecordUID).update({
                                                FilesURLs: tempFiles,
                                            })
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        reject(err.message)
                                    })
                            })
                            this.sendEngTicketSubmission(data)
                            resolve('Ticket created successfully. Thank you.')
                        }
                        else {
                            this.sendEngTicketSubmission(data)
                            resolve('Ticket created successfully. Thank you.')
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })

            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }

    updateTicketEngineering = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser
            var completionDate = new Date(data.ClosedDate.toDate())
            var resolveMessage = 'Ticket updated successfully. Thank you.'

            if (user) {

                if (data.Status == 'Completed' || data.Status == 'Closed - Not Needed') {
                    completionDate = new Date(moment.utc())
                    resolveMessage = 'Ticket "Completed" or "Closed" successfully. Thank you.'
                }

                db.collection("ticketsEngineering").doc(data.RecordUID).update({
                    //all variables tu update
                    Status: data.Status,
                    AssignedEng: data.AssignedEng,
                    RootCauseCategory: data.RootCauseCategory,
                    FixType: data.FixType,
                    FixUpdateDescription: data.FixUpdateDescription,
                    ClosedDate: completionDate,
                }).then(() => {
                    resolve(resolveMessage)
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
        })
    }
    addEngAttachmentToBucketAndTicket = (file, recordID, newEngFilesURLs) => {
        console.log(file, recordID, newEngFilesURLs)
        return new Promise((resolve, reject) => {
            storage
                .ref('ticketAttachmentsENGonly/' + recordID + '/' + file.name)
                .put(file)
                .then((snap) => {
                    snap.ref.getDownloadURL().then(imgUrl => {
                        // console.log(imgUrl)
                        newEngFilesURLs.push({ name: file.name, url: imgUrl, type: file.type })
                        db.collection("ticketsEngineering").doc(recordID).update({
                            engFilesURLs: newEngFilesURLs,
                        })
                    }).then(() => {
                        resolve({ newEngFilesURLs: newEngFilesURLs, message: 'Attachment added successfully' })
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
                })
                .catch((err) => {
                    reject(`Unable to add attachment: Code:${err.code_} Message: ${err.message}`)

                })
        })
    }
    removeEngAttachmentFromBucketAndTicket = (url, recordID, newEngFilesURLs) => {
        // console.log(url, newEngFilesURLs)
        return new Promise((resolve, reject) => {
            var attachmentRef = storage.refFromURL(url);
            attachmentRef.delete()
                .then(() => {
                    db.collection("ticketsEngineering").doc(recordID).update({
                        engFilesURLs: newEngFilesURLs,
                    }).then(() => {
                        resolve('Attachment removed successfully')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
                })
                .catch((err) => {
                    reject(`Unable to remove attachment: Code:${err.code_} Message: ${err.message}`)
                });
        })
    }

    createTicketFacilities = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var week = moment().isoWeek();
            var tempFiles = [];
            var description = '';

            if (user) {
                if (data.TicketType === 'Immediate Attention') {
                    description = data.ImmediateAttentionDescription
                }
                if (data.TicketType === 'Project Request') {
                    description = data.ProjectRequestDescription
                }
                // console.log(data)
                db.collection('ticketsFacilities').add({

                    dateRaised: new Date(moment.utc()),
                    dateClosed: '',
                    raisedBy: data.userEmail,
                    raisedByFullName: data.userFullName,
                    WeekNo: week,
                    TicketType: data.TicketType,
                    Category: data.Category,
                    Site: data.Site,
                    Department: data.Department,
                    Division: data.Division,
                    Description: description,
                    FixDescription: '',
                    Status: 'Open',
                    AssignedTo: '',
                    FilesURLs: [],
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        data.RecordUID = docRef.id;
                        // save attachments if any added save in bucket ticketAttachments
                        if (data.filesToStoreInDB.length > 0) {
                            data.filesToStoreInDB.forEach(file => {
                                console.log(file)
                                storage
                                    .ref('ticketFacilitiesAttachments/' + data.RecordUID + '/' + file.name)
                                    .put(file)
                                    .then((snap) => {
                                        snap.ref.getDownloadURL().then(imgUrl => {
                                            console.log(imgUrl)
                                            tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                            db.collection("ticketsFacilities").doc(data.RecordUID).update({
                                                FilesURLs: tempFiles,
                                            })
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        reject(err.message)
                                    })
                            })
                            this.sendFacilitiesTicketSubmission(data)
                            resolve('Ticket created successfully. Thank you.')
                        }
                        else {
                            this.sendFacilitiesTicketSubmission(data)
                            resolve('Ticket created successfully. Thank you.')
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }

    updateTicketFacilities = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser
            var completionDate = ''

            if (user) {
                if (data.Status == 'Completed' || data.Status === 'Closed - Not Needed') {
                    completionDate = new Date(moment.utc())
                }
                db.collection("ticketsFacilities").doc(data.RecordUID).update({
                    Status: data.Status,
                    AssignedTo: data.AssignedTo,
                    // RootCauseCategory: data.RootCauseCategory,
                    // FixType: data.FixType,
                    // FailureCategory: data.FailureCategory,
                    FixDescription: data.FixDescription,
                    dateClosed: completionDate,
                }).then(() => {
                    resolve('Ticket "Completed" or "Closed" successfully. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
        })
    }

    getTRMAdetails = (trmaSearch) => {
        return new Promise((resolve, reject) => {
            console.log('coming in trma: ', trmaSearch)

            db.collection("ticketsCustomerService")
                .where("TRMA", "==", trmaSearch)
                .get()
                .then(snapshot => {
                    let res = []
                    if (snapshot.empty) {
                        resolve({ result: res, message: `There was no details for ${trmaSearch} in the database.` })
                    } else {
                        snapshot.forEach(doc => {
                            console.log('doc.id => ' + doc.id, doc.data())
                            res.push({ ...doc.data(), id: doc.id, })
                        })
                        resolve({ result: res, message: `Successfully retrieved ${trmaSearch} details from the database.` })
                    }
                })
                .catch(error => {
                    console.log("Error getting trma details: ", error);
                    reject({ result: [], message: `Error getting trma details: ${error}` })
                });
        })
    }
    createTicketCustomerService = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser
            var date = new Date();
            var week = moment(date, 'MM-DD-YYYY').isoWeek();
            var tempFiles = []

            // TODO: auto assignment cs users based on whatever Donnacha will provide and add addresses to the data

            if (user) {
                console.log(data)
                db.collection('ticketsCustomerService').add({
                    //request fields
                    dateOpen: new Date(),
                    dateClosed: new Date('9999/01/01'),
                    dateAssigned: new Date('9999/01/01'),
                    userEmail: data.userEmail,
                    userFullName: data.userFullName,
                    // username: data.username,        
                    TicketType: data.TicketType,
                    LockType: data.LockType,
                    Site: data.Site,
                    TRMA: data.TRMA,
                    Customer: data.Customer,
                    Description: data.Description,
                    moreThanOneLockType: data.moreThanOneLockType,
                    FilesURLs: [],
                    ResponseFilesURLs: [],
                    //response and status fields
                    Status: 'Open',
                    AssignedCS: '',
                    FixUpdateDescription: '',
                    // files: [],
                    // filesSize: 0,
                    // filesToStoreInDB: [],
                    // // response fields
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        data.RecordUID = docRef.id;
                        // save attachments if any added save in bucket ticketAttachments
                        if (data.filesToStoreInDB.length > 0) {
                            data.filesToStoreInDB.forEach(file => {
                                console.log(file)
                                storage
                                    .ref('ticketAttachmentsCS/' + data.RecordUID + '/' + file.name)
                                    .put(file)
                                    .then((snap) => {
                                        snap.ref.getDownloadURL().then(imgUrl => {
                                            console.log(imgUrl)
                                            tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                            db.collection("ticketsCustomerService").doc(data.RecordUID).update({
                                                FilesURLs: tempFiles,
                                            })
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        reject(err.message)
                                    })
                            })
                            this.sendCSTicketSubmission(data)
                            resolve('Ticket created successfully. Thank you.')
                        }
                        else {
                            this.sendCSTicketSubmission(data)
                            resolve('Ticket created successfully. Thank you.')
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })

            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }
    updateTicketCustomerService = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser
            var completionDate = new Date(moment.utc())
            var tempFiles = data.ResponseFilesURLs
            let dummyDate = '9999/01/01'
            let currentAssignedDate = data.dateAssigned

            if (user) {

                if (data.Status === 'Closed - Passwords/un-enrolment provided' || data.Status === 'Closed - No passwords/un-enrolment provided') {
                    db.collection("ticketsCustomerService").doc(data.RecordUID).update({
                        Status: data.Status,
                        AssignedCS: data.AssignedCS,
                        FixUpdateDescription: data.FixUpdateDescription,
                        dateClosed: completionDate,
                        dateAssigned: data.AssignedCS.length > 0 && currentAssignedDate === dummyDate ? new Date(moment.utc()) : new Date(currentAssignedDate),
                    }).then(() => {
                        // save attachments if any added save in bucket ticketAttachments/responseAttachments/
                        if (data.filesToStoreInDB.length > 0) {



                            data.filesToStoreInDB.forEach(file => {
                                console.log(file)
                                storage
                                    .ref('ticketAttachmentsCS/responseAttachments/' + data.RecordUID + '/' + file.name)
                                    .put(file)
                                    .then((snap) => {
                                        snap.ref.getDownloadURL().then(imgUrl => {
                                            console.log(imgUrl)
                                            tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                            db.collection("ticketsCustomerService").doc(data.RecordUID).update({
                                                ResponseFilesURLs: tempFiles,
                                            })
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        reject(err.message)
                                    })
                            })
                            this.sendCSTicketResponse(data)
                            resolve('Ticket "Completed" or "Closed" successfully. Thank you.')
                        }
                        else {
                            this.sendCSTicketResponse(data)
                            resolve('Ticket "Completed" or "Closed" successfully. Thank you.')
                        }
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
                }
                else {
                    db.collection("ticketsCustomerService").doc(data.RecordUID).update({
                        Status: data.Status,
                        AssignedCS: data.AssignedCS,
                        FixUpdateDescription: data.FixUpdateDescription,
                        dateAssigned: data.AssignedCS.length > 0 && currentAssignedDate === dummyDate ? new Date(moment.utc()) : new Date(currentAssignedDate),
                    }).then(() => {
                        // save attachments if any added save in bucket ticketAttachments/responseAttachments/
                        if (data.filesToStoreInDB.length > 0) {
                            data.filesToStoreInDB.forEach(file => {
                                console.log(file)
                                storage
                                    .ref('ticketAttachmentsCS/responseAttachments/' + data.RecordUID + '/' + file.name)
                                    .put(file)
                                    .then((snap) => {
                                        snap.ref.getDownloadURL().then(imgUrl => {
                                            console.log(imgUrl)
                                            tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                            db.collection("ticketsCustomerService").doc(data.RecordUID).update({
                                                ResponseFilesURLs: tempFiles,
                                            })
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        reject(err.message)
                                    })
                            })
                            this.sendCSTicketResponse(data)
                            resolve('Ticket updated successfully. Thank you.')
                        }
                        else {
                            this.sendCSTicketResponse(data)
                            resolve('Ticket updated successfully. Thank you.')
                        }
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
                }
            }
        })
    }

    getUsers = () => {
        db.collection('users').get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    console.log('doc.id => ' + doc.id, doc.data())
                })
            })
            .catch(error => {
                console.log("Error getting users: ", error);
            });
        // db.collection('dropdowns').get()
        //     .then(snapshot => {
        //         snapshot.forEach(doc => {
        //             console.log(doc.id, '=>', doc.data());
        //         });
        //     })
        //     .catch(function (error) {
        //         console.log("Error getting document: ", error);
        //     });
    }
    getAllUsers = () => {
        return new Promise((resolve, reject) => {
            db.collection('users').get()
                .then(snapshot => {
                    var allUsers = []
                    snapshot.forEach(doc => {
                        // console.log('doc.id => ' + doc.id, doc.data())                       
                        allUsers.push({ ...doc.data(), id: doc.id })
                    })
                    resolve(allUsers)
                })
                .catch(error => {
                    console.log("Error getting all users: ", error);
                    reject(`Error getting all users: ${error}`)
                });
        })
    }
    updateUser = (data) => {
        return new Promise((resolve, reject) => {
            db.collection("users").doc(data.userId).update({
                firstName: data.firstName,
                lastName: data.lastName,
                department: data.department,
                roles: data.roles,
                availableDays: data.availableDays,
                holidayApprovingManager: data.holidayApprovingManager,
                shiftWorker: data.shiftWorker,
                userEmail: data.userEmail,
                accumulatedUnpaidLeaveTaken: data.accumulatedUnpaidLeaveTaken,
                accumulatedRemainingDays: data.accumulatedRemainingDays,
                accumulatedApprovedDays: data.accumulatedApprovedDays,
                location: data.location
            })
                .then(succ => {
                    resolve(succ)
                })
                .catch(error => {
                    console.log("Error updating user: ", error.message);
                    reject(`Error updating user: ${error.message}`)
                });
        })

    }

    createNewTracker = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser

            // console.log('state before ' + data.itemNumber
            //     + data.description
            //     + data.requestedBy
            //     + data.assignedIST
            //     + data.commentAction
            //     + data.priority)
            // when typing == then we check for undefined nad for null
            if (data.itemNumber == null) data.itemNumber = '';
            if (data.description == null) data.description = '';
            if (data.requestedBy == null) data.requestedBy = '';
            if (data.assignedIST == null) data.assignedIST = '';
            if (data.commentAction == null) data.commentAction = '';
            if (data.priority == null) data.priority = '';
            // if (data.startDate == null) data.startDate = '';
            // if (data.completionDate == null) data.completionDate = '';
            if (data.startDate == null) {
                reject('Please provide Planned Start Date.');
            }
            if (data.completionDate == null) {
                reject('Please provide Estimated Completion Date.');
            }
            // console.log('state after ' + data.itemNumber
            //     + data.description
            //     + data.requestedBy
            //     + data.assignedIST
            //     + data.commentAction
            //     + data.priority)
            if (user) {
                db.collection("istTrackers").doc().set({
                    //all variables
                    itemNumber: data.itemNumber,
                    description: data.description,
                    taskSubmitDate: new Date(),
                    user: user.displayName,
                    requestedBy: data.requestedBy,
                    assignedIST: data.assignedIST,
                    commentAction: data.commentAction,
                    priority: data.priority,
                    status: 'Open',
                    startDate: new Date(data.startDate),
                    completionDate: new Date(data.completionDate),

                }).then(() => {
                    resolve('Tracker created successfully. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }

    updateTracker = (data) => {

        return new Promise((resolve, reject) => {
            var user = auth.currentUser
            var date = new Date();
            var day = (date.getDate()) < 10 ? '0' + date.getDate() : '' + date.getDate();
            var month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : '' + (date.getMonth() + 1);
            var year = date.getFullYear();
            var today = year + '/' + month + '/' + day
            var formattedDate = moment(today).format('L'); // 'L' is format for 25/05/2020 dd/mm/yyyy

            if (data.description == null) data.description = '';
            if (data.requestedBy == null) data.requestedBy = '';
            if (data.assignedIST == null) data.assignedIST = '';
            if (data.commentAction == null) data.commentAction = '';
            // if (data.startDate == null) data.startDate = '';
            // if (data.completionDate == null) data.completionDate = '';
            if (data.startDate == null) {
                reject('Please provide Planned Start Date.');
                return;
            }
            if (data.completionDate == null) {
                reject('Please provide Estimated Completion Date.');
                return;
            }

            if (user) {
                db.collection("istTrackers").doc(data.docID).update({
                    //all variables
                    itemNumber: data.itemNumber,
                    description: data.description,
                    taskSubmitDate: new Date(data.taskSubmitDate),
                    user: user.displayName,
                    requestedBy: data.requestedBy,
                    assignedIST: data.assignedIST,
                    commentAction: data.commentAction,
                    priority: data.priority,
                    status: data.status,
                    startDate: new Date(data.startDate),
                    completionDate: new Date(data.completionDate),

                }).then(() => {
                    resolve('Tracker updated successfully. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }
    createEngDowntimeLog = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser
            console.log(data.recordedDate)
            console.log(new Date(data.recordedDate))
            if (user) {
                db.collection("engDowntimeLogs").doc().set({
                    //all variables
                    division: data.division,
                    process: data.process,
                    station: data.station,
                    downtimeDate: data.downtimeDate ? new Date(moment(data.downtimeDate).format('DD-MMM-YYYY')) : new Date(),
                    downtimeHours: data.downtimeHours ? data.downtimeHours : 0,
                    downtimeDescription: data.downtimeDescription ? data.downtimeDescription : '',
                    engRootCauseDescription: data.engRootCauseDescription ? data.engRootCauseDescription : '',
                    engFix: data.engFix ? data.engFix : '',
                    raisedBy: data.raisedBy,
                    recordedDate: new Date(moment(data.recordedDate).format('DD-MMM-YYYY')),
                }).then(() => {
                    resolve('New downtime log created successfully. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }

    updateEngDowntimeLog = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser

            if (user) {
                db.collection("engDowntimeLogs").doc(data.docID).update({
                    //all variables
                    division: data.division,
                    process: data.process,
                    station: data.station,
                    downtimeDate: new Date(data.downtimeDate),
                    downtimeHours: data.downtimeHours ? data.downtimeHours : 0,
                    downtimeDescription: data.downtimeDescription ? data.downtimeDescription : '',
                    engRootCauseDescription: data.engRootCauseDescription ? data.engRootCauseDescription : '',
                    engFix: data.engFix ? data.engFix : '',
                    // raisedBy: data.raisedBy,
                    // recordedDate: new Date(data.recordedDate),

                }).then(() => {
                    resolve('Tracker updated successfully. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }
    createEngActionTracker = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser

            // when typing == then we check for undefined nad for null
            if (data.itemNumber == null) data.itemNumber = '';
            if (data.description == null) data.description = '';
            if (data.requestedBy == null) data.requestedBy = '';
            if (data.assignedEng == null) data.assignedEng = '';
            if (data.commentAction == null) data.commentAction = '';
            if (data.priority == null) data.priority = '';
            if (data.startDate == null) {
                reject('Please provide Planned Start Date.');
            }
            if (data.completionDate == null) {
                reject('Please provide Estimated Completion Date.');
            }

            if (user) {
                db.collection("engActionTrackers").doc().set({
                    //all variables
                    description: data.description,
                    taskSubmitDate: new Date(moment(data.taskSubmitDate).format('DD-MMM-YYYY')),
                    raisedBy: data.raisedBy,
                    requestedBy: data.requestedBy,
                    assignedEng: data.assignedEng,
                    commentAction: data.commentAction,
                    priority: data.priority,
                    status: 'Open',
                    startDate: new Date(moment(data.startDate).format('DD-MMM-YYYY')),
                    completionDate: new Date(moment(data.completionDate).format('DD-MMM-YYYY')),

                }).then(() => {
                    resolve('Tracker created successfully. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }
    updateEngActionTracker = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser

            if (data.description == null) data.description = '';
            if (data.requestedBy == null) data.requestedBy = '';
            if (data.assignedEng == null) data.assignedEng = '';
            if (data.commentAction == null) data.commentAction = '';
            if (data.startDate == null) {
                reject('Please provide Planned Start Date.');
                return;
            }
            if (data.completionDate == null) {
                reject('Please provide Estimated Completion Date.');
                return;
            }

            if (user) {
                db.collection("engActionTrackers").doc(data.docID).update({
                    //all variables
                    description: data.description,
                    taskSubmitDate: new Date(moment(data.taskSubmitDate).format('DD-MMM-YYYY')),
                    raisedBy: data.raisedBy,
                    requestedBy: data.requestedBy,
                    assignedEng: data.assignedEng,
                    commentAction: data.commentAction,
                    priority: data.priority,
                    status: data.status,
                    startDate: new Date(moment(data.startDate).format('DD-MMM-YYYY')),
                    completionDate: new Date(moment(data.completionDate).format('DD-MMM-YYYY')),

                }).then(() => {
                    resolve('Tracker updated successfully. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }
    createISTActionTracker = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser

            // when typing == then we check for undefined nad for null
            if (data.itemNumber == null) data.itemNumber = '';
            if (data.description == null) data.description = '';
            if (data.requestedBy == null) data.requestedBy = '';
            if (data.assignedIT == null) data.assignedIT = '';
            if (data.commentAction == null) data.commentAction = '';
            if (data.priority == null) data.priority = '';
            if (data.startDate == null) {
                reject('Please provide Planned Start Date.');
            }
            if (data.completionDate == null) {
                reject('Please provide Estimated Completion Date.');
            }

            if (user) {
                db.collection("istActionTrackers").doc().set({
                    //all variables
                    description: data.description,
                    taskSubmitDate: new Date(moment(data.taskSubmitDate).format('DD-MMM-YYYY')),
                    raisedBy: data.raisedBy,
                    requestedBy: data.requestedBy,
                    assignedIT: data.assignedIT,
                    commentAction: data.commentAction,
                    priority: data.priority,
                    status: 'Open',
                    startDate: new Date(moment(data.startDate).format('DD-MMM-YYYY')),
                    completionDate: new Date(moment(data.completionDate).format('DD-MMM-YYYY')),

                }).then(() => {
                    resolve('Tracker created successfully. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }
    updateISTActionTracker = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser

            if (data.description == null) data.description = '';
            if (data.requestedBy == null) data.requestedBy = '';
            if (data.assignedIT == null) data.assignedIT = '';
            if (data.commentAction == null) data.commentAction = '';
            if (data.startDate == null) {
                reject('Please provide Planned Start Date.');
                return;
            }
            if (data.completionDate == null) {
                reject('Please provide Estimated Completion Date.');
                return;
            }

            if (user) {
                db.collection("istActionTrackers").doc(data.docID).update({
                    //all variables
                    description: data.description,
                    taskSubmitDate: new Date(moment(data.taskSubmitDate).format('DD-MMM-YYYY')),
                    raisedBy: data.raisedBy,
                    requestedBy: data.requestedBy,
                    assignedIT: data.assignedIT,
                    commentAction: data.commentAction,
                    priority: data.priority,
                    status: data.status,
                    startDate: new Date(moment(data.startDate).format('DD-MMM-YYYY')),
                    completionDate: new Date(moment(data.completionDate).format('DD-MMM-YYYY')),

                }).then(() => {
                    resolve('Tracker updated successfully. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
            else {
                reject('Session expired please log back in.')
            }
        })
    }

    calculateDaysRequested = (startDate, endDate) => {
        // console.log(startDate, endDate)
        const bankholidaysIRL2020 = [
            '2020-01-01', '2020-03-17', '2020-04-13', '2020-05-04', '2020-06-01', '2020-08-03', '2020-10-26', '2020-12-25', '2020-12-28'
        ]
        const bankholidaysIRL2021 = [
            '2021-01-01', '2021-03-17', '2021-04-05', '2021-05-03', '2021-06-07', '2021-08-02', '2021-10-25', '2021-12-25', '2021-12-26'
        ]
        const bankholidaysIRL2022 = [
            '2022-01-01', '2022-03-17', '2022-03-18', '2022-04-18', '2022-05-02', '2022-06-06', '2022-08-01', '2022-10-31', '2022-12-25', '2022-12-26'
        ]
        const bankholidaysIRL2023 = [
            '2023-01-02', '2023-02-06', '2023-03-17', '2023-04-10', '2023-05-01', '2023-06-05', '2023-08-07', '2023-10-30', '2023-12-25', '2023-12-26'
        ]
        // Monday, 2 January - New Year's Day
        // Monday, 6 February - St Brigid's Day
        // Friday, 17 March - St Patrick's Day
        // Monday, 10 April - Easter Monday
        // Monday, 1 May - May Day
        // Monday, 5 June - June Bank Holiday
        // Monday, 7 August - August Bank Holiday
        // Monday, 30 October - October Bank Holiday, Halloween
        // Monday, 25 December - Christmas Day
        // Tuesday, 26 December - St Stephen's Day
        // TODO:trzeba zrobic ifa na lata zeby sprawdzalo holidaye rozne
        var count = 0;
        var curDate = startDate;
        var holidayCount = 0;
        var holidayCountNextYear = 0;
        for (var i = 0; i < bankholidaysIRL2022.length; i++) {
            var irlHolidaysFormated = new Date(bankholidaysIRL2022[i]).setHours(0, 0, 0, 0);
            var dayOfWeek = new Date(bankholidaysIRL2022[i]).getDay();

            if (irlHolidaysFormated >= startDate && irlHolidaysFormated <= endDate && !(dayOfWeek == 0 || dayOfWeek == 6)) {
                holidayCount++;
            }
        }
        // only for december to calculate current holidays and next years holidays
        for (var i = 0; i < bankholidaysIRL2023.length; i++) {
            var irlHolidaysFormated = new Date(bankholidaysIRL2023[i]).setHours(0, 0, 0, 0);
            var dayOfWeek = new Date(bankholidaysIRL2023[i]).getDay();

            if (irlHolidaysFormated >= startDate && irlHolidaysFormated <= endDate && !(dayOfWeek == 0 || dayOfWeek == 6)) {
                holidayCountNextYear++;
            }
        }
        while (curDate <= endDate) {
            var dayOfWeek = curDate.getDay();
            if (!((dayOfWeek == 6) || (dayOfWeek == 0)))
                count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        // console.log(count, holidayCount, holidayCountNextYear)
        var totalHolidays = count - holidayCount - holidayCountNextYear
        // alert(totalHolidays)
        // console.log('total count:', totalHolidays)
        return totalHolidays;
    }
    // // function checkForBankholiday works perfect - counting is there bankholidays between dates
    // checkForBankholiday = (startDate, endDate) => {
    //     var holidayCount = 0;
    //     for (var i = 0; i < bankholidaysIRL2020.length; i++) {
    //         var irlHolidaysFormated = new Date(bankholidaysIRL2020[i]).setHours(0, 0, 0, 0);
    //         var dayOfWeek = new Date(bankholidaysIRL2020[i]).getDay();

    //         if (irlHolidaysFormated >= startDate && irlHolidaysFormated <= endDate && !(dayOfWeek == 0 || dayOfWeek == 6)) {
    //             holidayCount++;
    //         }
    //     }
    //     return holidayCount;
    // }
    createHolidayRequest = (data) => {
        console.log('Submitting Holiday Form...')
        return new Promise((resolve, reject) => {
            var user = auth.currentUser

            if (data.FullName == '' || data.Department == '' || data.Manager == '') {
                reject('Please provide all details required.')
            }
            else {

                if (user) {

                    db.collection("holidayRequests").doc().set({
                        DateOfRequest: new Date(data.DateOfRequest),
                        FullName: data.FullName,
                        Department: data.Department,
                        Manager: data.Manager,
                        // HalfDay: data.HalfDay,
                        // ShiftDay: data.ShiftDay,
                        // SingleDay: data.SingleDay,
                        FromDate: new Date(data.FromDate),
                        ToDate: new Date(data.ToDate),
                        TotalDaysRequested: data.TotalDaysRequested,
                        HoursRequested: data.HoursRequested,
                        AdditionalComment: data.AdditionalComment,
                        Status: data.Status,
                        ResponseComment: data.ResponseComment,
                        ResponseDate: data.ResponseDate,
                        UserEmail: data.UserEmail,
                        ManagerEmail: data.ManagerEmail,
                        FromDateString: data.FromDateString,
                        ToDateString: data.ToDateString,
                        WasRequestApproved: data.WasRequestApproved,
                        UnpaidLeave: data.UnpaidLeave,
                        ShiftWorker: data.ShiftWorker,
                        UserId: user.uid,
                    }).then(() => {
                        resolve('Request Submitted Successfully.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
                }
            }
        })
    }

    findApplicantAndUpdateHolidays = (data) => {
        return new Promise((resolve, reject) => {
            // console.log(data)

            var user = [];
            var shiftWorker = false;
            var availableDays = 0;
            var availableHours = 0;
            var approvedDays = 0;
            var remainingDays = 0;
            var remainingHours = 0;
            var totalApprovedDays = 0;
            var accumulatedUnpaidLeaveTaken = 0;

            var requestedDaysInHours = 0;
            var requestedHours = data.HoursRequested;
            var totalRequestedHours = 0;

            if (data.Status === 'Approved') {
                if (data.UnpaidLeave) {
                    db.collection("users").where("userEmail", "==", data.UserEmail).get()
                        .then(snapshot => {
                            snapshot.forEach(doc => {
                                user.push({
                                    ...doc.data(),
                                })
                                console.log(user[0])
                                // availableDays = user[0].availableDays;
                                // approvedDays = user[0].accumulatedApprovedDays;
                                // remainingDays = user[0].accumulatedRemainingDays;
                                accumulatedUnpaidLeaveTaken = user[0].accumulatedUnpaidLeaveTaken; // add to user details

                                // availableHours = availableDays * 8
                                requestedDaysInHours = data.TotalDaysRequested * 8

                                totalRequestedHours = requestedDaysInHours + requestedHours
                                // remainingDays = (availableHours - totalRequestedHours) / 8 // to calculate remaining days
                                totalApprovedDays = ((accumulatedUnpaidLeaveTaken * 8) + totalRequestedHours) / 8 // to calculate total approved days

                                db.collection("users").doc(doc.id).update({
                                    accumulatedUnpaidLeaveTaken: parseFloat(totalApprovedDays.toFixed(2)),
                                })
                                resolve('User\'s Unpaid Leave Updated.')
                            })
                        })
                        .catch(function (error) {
                            console.log("Error getting user details: ", error);
                            reject('Error' + error.message)
                        })
                }
                else { // normal anual leave
                    // make calculations for shift workers or not
                    db.collection("users").where("userEmail", "==", data.UserEmail).get()
                        .then(snapshot => {
                            snapshot.forEach(doc => {
                                user.push({
                                    ...doc.data(),
                                })
                                // console.log(user[0])
                                availableDays = user[0].availableDays;
                                approvedDays = user[0].accumulatedApprovedDays;
                                remainingDays = user[0].accumulatedRemainingDays;
                                accumulatedUnpaidLeaveTaken = user[0].accumulatedUnpaidLeaveTaken; // add to user details
                                shiftWorker = user[0].shiftWorker;

                                if (shiftWorker) {
                                    availableHours = availableDays * 12
                                    remainingHours = remainingDays * 12
                                    requestedDaysInHours = data.TotalDaysRequested * 12
                                    totalRequestedHours = requestedDaysInHours + requestedHours
                                    availableDays = (availableHours - totalRequestedHours) / 12 // to calculate available days
                                    remainingDays = (remainingHours - totalRequestedHours) / 12 // to calculate remaining days
                                    totalApprovedDays = ((approvedDays * 12) + totalRequestedHours) / 12 // to calculate total approved days
                                }
                                else {
                                    availableHours = availableDays * 8
                                    remainingHours = remainingDays * 8
                                    requestedDaysInHours = data.TotalDaysRequested * 8
                                    totalRequestedHours = requestedDaysInHours + requestedHours
                                    availableDays = (availableHours - totalRequestedHours) / 8 // to calculate available days
                                    remainingDays = (remainingHours - totalRequestedHours) / 8 // to calculate remaining days
                                    totalApprovedDays = ((approvedDays * 8) + totalRequestedHours) / 8 // to calculate total approved days
                                }

                                db.collection("users").doc(doc.id).update({
                                    // availableDays: parseFloat(availableDays.toFixed(2)),
                                    accumulatedApprovedDays: parseFloat(totalApprovedDays.toFixed(2)),
                                    accumulatedRemainingDays: parseFloat(remainingDays.toFixed(2)),
                                })
                                resolve('User Holidays Updated.')
                            })
                        })
                        .catch(function (error) {
                            console.log("Error getting user details: ", error);
                            reject('Error' + error.message)

                        })
                }
            }
            else if (data.Status === 'Cancelled') {
                if (data.UnpaidLeave) {
                    db.collection("users").where("userEmail", "==", data.UserEmail).get()
                        .then(snapshot => {
                            snapshot.forEach(doc => {
                                user.push({
                                    ...doc.data(),
                                })
                                console.log(user[0])
                                // availableDays = user[0].availableDays;
                                // approvedDays = user[0].accumulatedApprovedDays;
                                // remainingDays = user[0].accumulatedRemainingDays;
                                accumulatedUnpaidLeaveTaken = user[0].accumulatedUnpaidLeaveTaken; // add to user details

                                if (shiftWorker) {
                                    // availableHours = availableDays * 12
                                    requestedDaysInHours = data.TotalDaysRequested * 12

                                    totalRequestedHours = requestedDaysInHours + requestedHours
                                    // remainingDays = (availableHours - totalRequestedHours) / 12 // to calculate remaining days
                                    totalApprovedDays = ((accumulatedUnpaidLeaveTaken * 12) - totalRequestedHours) / 12 // to calculate how much return to user total approved days
                                }
                                else {
                                    // availableHours = availableDays * 8
                                    requestedDaysInHours = data.TotalDaysRequested * 8

                                    totalRequestedHours = requestedDaysInHours + requestedHours
                                    // remainingDays = (availableHours - totalRequestedHours) / 8 // to calculate remaining days
                                    totalApprovedDays = ((accumulatedUnpaidLeaveTaken * 8) - totalRequestedHours) / 8 // to calculate how much return to user total approved days
                                }

                                db.collection("users").doc(doc.id).update({
                                    accumulatedUnpaidLeaveTaken: parseFloat(totalApprovedDays.toFixed(2)),
                                })
                                resolve('User\'s Unpaid Leave Cancelled.')
                            })
                        })
                        .catch(function (error) {
                            console.log("Error getting user details: ", error);
                            reject('Error' + error.message)
                        })
                }
                else {

                    db.collection("users").where("userEmail", "==", data.UserEmail).get()
                        .then(snapshot => {
                            snapshot.forEach(doc => {
                                user.push({
                                    ...doc.data(),
                                })
                                // console.log(user[0])
                                availableDays = user[0].availableDays;
                                approvedDays = user[0].accumulatedApprovedDays;
                                remainingDays = user[0].accumulatedRemainingDays;


                                if (shiftWorker) {
                                    availableHours = availableDays * 12
                                    remainingHours = remainingDays * 12
                                    requestedDaysInHours = data.TotalDaysRequested * 12
                                    totalRequestedHours = requestedDaysInHours + requestedHours
                                    availableDays = (availableHours + totalRequestedHours) / 12 // to calculate available days
                                    remainingDays = (remainingHours + totalRequestedHours) / 12 // to calculate remaining days
                                    totalApprovedDays = ((approvedDays * 12) - totalRequestedHours) / 12 // to calculate total approved days
                                }
                                else {
                                    availableHours = availableDays * 8
                                    remainingHours = remainingDays * 8
                                    requestedDaysInHours = data.TotalDaysRequested * 8
                                    totalRequestedHours = requestedDaysInHours + requestedHours
                                    availableDays = (availableHours + totalRequestedHours) / 8 // to calculate available days
                                    remainingDays = (remainingHours + totalRequestedHours) / 8 // to calculate remaining days
                                    totalApprovedDays = ((approvedDays * 8) - totalRequestedHours) / 8 // to calculate total approved days
                                }


                                db.collection("users").doc(doc.id).update({
                                    // availableDays: parseFloat(availableDays.toFixed(2)),
                                    accumulatedApprovedDays: parseFloat(totalApprovedDays.toFixed(2)),
                                    accumulatedRemainingDays: parseFloat(remainingDays.toFixed(2)),
                                })
                                resolve('User Holidays Updated.')
                            })
                        })
                        .catch(function (error) {
                            console.log("Error getting user details: ", error);
                            reject('Error' + error.message)
                        })
                }
            }
        })
    }

    createSoftwareReleaseInfoTracker = (data) => {
        return new Promise((resolve, reject) => {

            var user = auth.currentUser;

            if (data.applicationName == null) data.applicationName = '';
            if (data.objectsModified == null) data.objectsModified = '';
            if (data.descriptionOfChanges == null) data.descriptionOfChanges = '';
            if (data.impactedDepartment == null) data.impactedDepartment = '';
            if (data.managedBy == null) data.managedBy = '';
            if (data.softwareReleasedBy == null) data.softwareReleasedBy = '';
            if (data.releaseDate == null) {
                reject('Please provide Release Date.');
            }
            if (user) {

                db.collection("softwareReleaseInfoTracker").doc().set({
                    releaseDate: new Date(data.releaseDate),
                    applicationName: data.applicationName,
                    objectsModified: data.objectsModified,
                    descriptionOfChanges: data.descriptionOfChanges,
                    impactedDepartment: data.impactedDepartment,
                    requestedBy: data.requestedBy,
                    softwareReleasedBy: data.softwareReleasedBy,
                    createdDate: new Date(),
                    createdBy: user.displayName,
                    updatedBy: '',

                }).then(() => {
                    resolve('Software release info submitted successfully.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
        })
    }
    updateSoftwareReleaseInfoTracker = (data) => {
        return new Promise((resolve, reject) => {

            var user = auth.currentUser;

            if (data.applicationName == null) data.applicationName = '';
            if (data.objectsModified == null) data.objectsModified = '';
            if (data.descriptionOfChanges == null) data.descriptionOfChanges = '';
            if (data.impactedDepartment == null) data.impactedDepartment = '';
            if (data.managedBy == null) data.managedBy = '';
            if (data.softwareReleasedBy == null) data.softwareReleasedBy = '';
            if (data.createdBy == null) data.createdBy = '';
            if (data.releaseDate == null) {
                reject('Please provide Release Date.');
            }
            if (user) {

                db.collection("softwareReleaseInfoTracker").doc(data.docId).update({
                    releaseDate: new Date(data.releaseDate),
                    applicationName: data.applicationName,
                    objectsModified: data.objectsModified,
                    descriptionOfChanges: data.descriptionOfChanges,
                    impactedDepartment: data.impactedDepartment,
                    requestedBy: data.requestedBy,
                    softwareReleasedBy: data.softwareReleasedBy,
                    // createdDate: new Date(), dont update creation date !
                    // createdBy: data.createdBy,
                    updatedBy: user.displayName,

                }).then(() => {
                    resolve('Software release info updated successfully.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
        })
    }

    //table functions for fetching data with paging
    getTableData = async (params) => {
        // console.log(params)
        // console.log("page: ", params.page, " previous page: ", params.previousPage)
        // console.log(params.last)
        // console.log(params)
        var tableData = {
            data: [],
            first: null,
            last: null,
            previousPage: params.page,
            totalCount: params.totalCount
        };

        // tableData.totalDocuments = await //zrobic kolekcje co zawieral liczniki i funkcje co update licznikow... i pobrac jk w probnym projekcie

        const counter = await db.collection("docCounter").doc(params.collection).get()
        var count = counter.data().count
        console.log('Counter on this collection:', params.collection, 'is', count)
        tableData.totalCount = count


        if (params.page === 0) {
            const snapshot = await db.collection(params.collection).orderBy(params.orderBy, params.order).limit(params.rowsPerPage).get()
            // console.log(snapshot.docs.length)
            tableData.last = snapshot.docs[snapshot.docs.length - 1]
            snapshot.forEach(doc => {
                // problem we need to know which column is date field to convert it
                if (doc.data().DateRaised) {
                    // console.log('there is DateRaised:')
                    tableData.data.push({
                        ...doc.data(),
                        RecordUID: doc.id,
                        // DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                        // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD')
                    });
                }
                else {
                    // console.log('no date field')

                    console.log(doc.data())
                    tableData.data.push({
                        ...doc.data(),
                        RecordUID: doc.id,
                    });
                }
            })
            return tableData;
        }

        if (params.page > params.previousPage) {
            //go right
            console.log("go right")
            if (params.last) {
                const snapshot = await db.collection(params.collection).orderBy(params.orderBy, params.order).startAfter(params.last).limit(params.rowsPerPage).get()
                console.log(snapshot.docs.length)
                tableData.first = snapshot.docs[0]
                tableData.last = snapshot.docs[snapshot.docs.length - 1]
                snapshot.forEach(doc => {
                    tableData.data.push({
                        ...doc.data(),
                        RecordUID: doc.id,
                        // DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                        // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD')
                    });
                })
                console.log(tableData)
                return tableData;
            } else {
                throw new Error('start after (last) document was not saved to state on previous page data fetch')
            }

        }

        if (params.page < params.previousPage) {
            //go left
            if (params.first) {
                const snapshot = await db.collection(params.collection).orderBy(params.orderBy, params.order).endBefore(params.first).limitToLast(params.rowsPerPage).get()
                tableData.first = snapshot.docs[0]
                tableData.last = snapshot.docs[snapshot.docs.length - 1]
                snapshot.forEach(doc => {
                    tableData.data.push({
                        ...doc.data(),
                        RecordUID: doc.id,
                        // DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                        // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD')
                    });
                })
                return tableData;
            } else {
                throw new Error('end before (first) document was not saved to state on previous page data fetch')
            }
        }
    }

    // THOSE ARE ALL FIELDS for PartnerMatrix
    // data.address = ""
    // data.collection = 0
    // data.contact = ""
    // data.country = ""
    // data.coverage = ""
    // data.csatScore = 0
    // data.decomission = 0
    // data.eStewards = false
    // data.email = ""
    // data.exportWaste = false
    // data.feedback = "" is empty on create of request
    // data.iso9001 = false
    // data.iso14001 = false
    // data.iso18001 = false
    // data.offsiteDD = 0
    // data.offsiteDE = ""
    // data.onsiteDD = 0
    // data.onsiteDE = ""
    // data.partner = ""
    // data.phone = ""
    // data.r2 = false
    // data.recycling = 0
    // data.refurbish = false
    // data.region = ""
    // data.resale = false
    // data.volume = "Low"
    // data.weee = false
    createPartnerMatrix = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser

            // when typing == then we check for undefined and for null

            // boolean fields
            if (data.eStewards == null) { data.eStewards = false }
            if (data.exportWaste == null) { data.exportWaste = false }
            if (data.iso9001 == null) { data.iso9001 = false }
            if (data.iso14001 == null) { data.iso14001 = false }
            if (data.iso18001 == null) { data.iso18001 = false }
            if (data.r2 == null) { data.r2 = false }
            if (data.refurbish == null) { data.refurbish = false }
            if (data.resale == null) { data.resale = false }
            if (data.weee == null) { data.weee = false }
            // string fields
            if (data.address == null || data.contact == null || data.country == null || data.coverage == null ||
                data.email == null || data.offsiteDE == null || data.onsiteDE == null ||
                data.partner == null || data.phone == null || data.region == null) {
                reject('Please fill in all fields required.')
                return;
            }

            if (user) {

                db.collection('partnerMatrix').doc().set({
                    region: data.region,
                    country: data.country,
                    partner: data.partner,
                    collection: data.collection, //', type: 'currency' },
                    decomission: data.decomission, //', type: 'currency' },
                    offsiteDD: data.offsiteDD,//', type: 'currency' },
                    onsiteDD: data.onsiteDD,//', type: 'currency' },
                    offsiteDE: data.offsiteDE,
                    onsiteDE: data.onsiteDE,
                    recycling: data.recycling,//', type: 'currency' },
                    refurbish: data.refurbish,//', type: 'boolean' },
                    resale: data.resale,//', type: 'boolean' },
                    exportWaste: data.exportWaste,//', type: 'boolean' },
                    weee: data.weee,//', type: 'boolean' },
                    r2: data.r2,//', type: 'boolean' },
                    eStewards: data.eStewards,//', type: 'boolean' },
                    iso9001: data.iso9001,//', type: 'boolean' },
                    iso14001: data.iso14001,//', type: 'boolean' },
                    iso18001: data.iso18001,//', type: 'boolean' },
                    address: data.address,
                    coverage: data.coverage,
                    contact: data.contact,
                    email: data.email,
                    phone: data.phone,
                    volume: data.volume,
                    csatScore: data.csatScore,//', type: 'numeric' },
                    feedback: "",
                    dateCreated: new Date(),
                    dateEdited: ""
                }).then(() => {
                    resolve('Successfully added new partner matrix record.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
            else {
                reject('Sesion expired please log back in.')
            }
        })
    }
    updatePartnerMatrixAsAdmin = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                // console.log('I am adminAM')
                db.collection('partnerMatrix').doc(data.docId).update({
                    region: data.region,
                    country: data.country,
                    partner: data.partner,
                    collection: data.collection, //', type: 'currency' },
                    decomission: data.decomission, //', type: 'currency' },
                    offsiteDD: data.offsiteDD,//', type: 'currency' },
                    onsiteDD: data.onsiteDD,//', type: 'currency' },
                    offsiteDE: data.offsiteDE,
                    onsiteDE: data.onsiteDE,
                    recycling: data.recycling,//', type: 'currency' },
                    refurbish: data.refurbish,//', type: 'boolean' },
                    resale: data.resale,//', type: 'boolean' },
                    exportWaste: data.exportWaste,//', type: 'boolean' },
                    weee: data.weee,//', type: 'boolean' },
                    r2: data.r2,//', type: 'boolean' },
                    eStewards: data.eStewards,//', type: 'boolean' },
                    iso9001: data.iso9001,//', type: 'boolean' },
                    iso14001: data.iso14001,//', type: 'boolean' },
                    iso18001: data.iso18001,//', type: 'boolean' },
                    address: data.address,
                    coverage: data.coverage,
                    contact: data.contact,
                    email: data.email,
                    phone: data.phone,
                    volume: data.volume,
                    csatScore: data.csatScore,//', type: 'numeric' },
                    feedback: data.feedback,
                    dateEdited: new Date()

                }).then(() => {
                    resolve('Successfully updated partner matrix record.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
        })
    }
    updatePartnerMatrixAsMember = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                // console.log('I am memberAM')
                db.collection('partnerMatrix').doc(data.docId).update({
                    feedback: data.feedback,
                    dateEdited: new Date()
                }).then(() => {
                    resolve('Successfully updated partner matrix record.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
            }
        })
    }

    knowledgeBoxGetLatest = async (data) => {

        var user = auth.currentUser;

        var knowledgeBoxItems = []
        if (user) {
            const snapshot = await db.collection('knowledgeBox').orderBy('', 'desc').limit(9).get()

            snapshot.forEach(doc => {
                knowledgeBoxItems.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            console.log(knowledgeBoxItems)
            return knowledgeBoxItems
        }
    }
    knowledgeBoxAdd = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var tempFiles = [];
            if (user) {
                db.collection('knowledgeBox').add({
                    title: data.title,
                    description: data.description,
                    // barcode: data.barcode,
                    files: [],
                    dateCreated: new Date(),
                    dateEdited: 'n/a',
                    author: user.displayName,
                    authorId: user.uid,
                    authorEmail: user.email,
                    editedBy: 'n/a'
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        data.RecordUID = docRef.id;
                        // save attachments if any added save in bucket knowledgeBoxAttachments
                        if (data.filesToStoreInDB.length > 0) {
                            data.filesToStoreInDB.forEach(file => {
                                console.log(file)
                                storage
                                    .ref('knowledgeBoxAttachments/' + data.RecordUID + '/' + file.name)
                                    .put(file)
                                    .then((snap) => {
                                        snap.ref.getDownloadURL().then(imgUrl => {
                                            console.log(imgUrl)
                                            tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                            db.collection("knowledgeBox").doc(data.RecordUID).update({
                                                files: tempFiles,
                                            })
                                        })
                                        resolve('Successfully created new item. Thank you.')
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        reject(err.message)
                                    })
                            })
                        }
                        else {
                            resolve('Successfully created new item. Thank you.')
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    addWeeklyTask = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var newAvailableHours = data.availableHours - data.assignedHours
            var newPercent = Number(((100 * data.assignedHours) / (data.availableHours + data.allocatedHours)).toFixed(1))
            // hoursAssigned = Number(((data.assignedPercent / 100) * (data.availableHours + data.allocatedHours)).toFixed(2));
            const weekNo = moment().isoWeek();
            if (user) {
                db.collection('userTasks').doc().set({
                    additionalComment: data.additionalComment,              // "bla bla bla"
                    allocatedHours: data.assignedHours,                     // 9
                    allocatedPercent: newPercent,                           // 30
                    assignedBy: user.displayName,//data.assignedBy,         // "John-Paul Collins"
                    assignedTo: data.assignedTo,                            // "6swPjBlta5XPapuq4FIgZQLrDPB3"
                    assignedToFullName: data.assignedToFullName,            // "Pawel Gajewski"
                    assignedUserLeave: data.assignedUserLeave,              // 0
                    availableHours: newAvailableHours,                      // 30
                    dateCreated: new Date(),                                // timestamp
                    dateWorkEnded: '',                                      // timestamp
                    dateWorkStarted: '',                                    // timestamp
                    department: data.department,                            // "IS&T"
                    taskCategory: data.assignedTask,                        // "Issues"
                    weekNo: weekNo,                                         // 3
                })
                    .then(() => {
                        resolve('Task created successfully. This user will be happy about it.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateWeeklyTask = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('userTasks').doc(data.id).update({
                    actualHours: data.actual,
                    additionalUserComment: data.additionalUserComment ? data.additionalUserComment : ''
                })
                    .then(() => {
                        resolve('Task hours updated successfully.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    addRecordsToDatabase = (data) => {
        return new Promise((resolve, reject) => {
            console.log('data', data)
            var counter = 0
            var isLoopDone = false
            data.forEach(item => {
                counter++
                db.collection("countryCodes").add({
                    value: item.value,
                    label: item.label
                })
                if (counter === data.length) {
                    console.log('loop done: ', counter, ' of ', data.length)
                    isLoopDone = true
                }
            })

            if (isLoopDone) {
                console.log('added:', counter)
                resolve(`${counter} records added to the database`)
            } else {
                console.error('there was some error')
                reject('there was some error')
            }

        })
    }

    addMetricsCustomerSLAUS = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var today = moment.utc();
            var counter = 0;
            var dataLength = data.length

            console.log(dataLength)

            // do write to db only if ALL data rows are correctly filled !!!!
            if (data.length > 0) {
                data.forEach(doc => {
                    counter++;

                    // DATE VALIDATION
                    var dateArrived = moment(doc.DateArrived, 'DD-MMM-YY', true).isValid() ? new Date(doc.DateArrived) : 'N/A';
                    var sLAReceivingDueDate = moment(doc.SLAReceivingDueDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.SLAReceivingDueDate) : 'N/A';
                    var basicAuditWIPDate = moment(doc.BasicAuditWIPDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.BasicAuditWIPDate) : 'N/A';
                    var bASLADueDate = moment(doc.BASLADueDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.BASLADueDate) : 'N/A';
                    var techAuditWIPDate = moment(doc.TechAuditWIPDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.TechAuditWIPDate) : 'N/A';
                    var techAuditSLADueDate = moment(doc.TechAuditSLADueDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.TechAuditSLADueDate) : 'N/A';
                    var verifyWIPDate = moment(doc.VerifyWIPDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.VerifyWIPDate) : 'N/A';
                    var verificationDueDate = moment(doc.VerificationDueDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.VerificationDueDate) : 'N/A';
                    var salesDate = moment(doc.SalesDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.SalesDate) : 'N/A';
                    var sLATurnoutDueDate = moment(doc.SLATurnoutDueDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.SLATurnoutDueDate) : 'N/A';
                    var customerPendingDate = moment(doc.CustomerPendingDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.CustomerPendingDate) : 'N/A';
                    var customerReleasedDate = moment(doc.CustomerReleasedDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.CustomerReleasedDate) : 'N/A';
                    // only works with this format date
                    // var dateArrived = moment(doc.DateArrived, 'DD MMMM YYYY', true).isValid() ? new Date(doc.DateArrived) : 'N/A';
                    // var sLAReceivingDueDate = moment(doc.SLAReceivingDueDate, 'DD MMMM YYYY', true).isValid() ? new Date(doc.SLAReceivingDueDate) : 'N/A';
                    // var basicAuditWIPDate = moment(doc.BasicAuditWIPDate, 'DD MMMM YYYY', true).isValid() ? new Date(doc.BasicAuditWIPDate) : 'N/A';
                    // var bASLADueDate = moment(doc.BASLADueDate, 'DD MMMM YYYY', true).isValid() ? new Date(doc.BASLADueDate) : 'N/A';
                    // var techAuditWIPDate = moment(doc.TechAuditWIPDate, 'DD MMMM YYYY', true).isValid() ? new Date(doc.TechAuditWIPDate) : 'N/A';
                    // var techAuditSLADueDate = moment(doc.TechAuditSLADueDate, 'DD MMMM YYYY', true).isValid() ? new Date(doc.TechAuditSLADueDate) : 'N/A';
                    // var verifyWIPDate = moment(doc.VerifyWIPDate, 'DD MMMM YYYY', true).isValid() ? new Date(doc.VerifyWIPDate) : 'N/A';
                    // var verificationDueDate = moment(doc.VerificationDueDate, 'DD MMMM YYYY', true).isValid() ? new Date(doc.VerificationDueDate) : 'N/A';
                    // var salesDate = moment(doc.SalesDate, 'DD MMMM YYYY', true).isValid() ? new Date(doc.SalesDate) : 'N/A';
                    // var sLATurnoutDueDate = moment(doc.SLATurnoutDueDate, 'DD MMMM YYYY', true).isValid() ? new Date(doc.SLATurnoutDueDate) : 'N/A';

                    // console.log("Date is:", doc.TRMAID, verifyWIPDate, salesDate);

                    // if (!isNaN(VerifyWIPDate.getMonth())) {
                    //     console.log('it was not empty')
                    // }
                    // else {
                    //     console.log('it was empty')
                    // }

                    db.collection('metrics').add({
                        TRMAID: doc.TRMAID,
                        DispositionRouting: doc.DispositionRouting,
                        BuyFromVendorName: doc.BuyFromVendorName,
                        LocationCode: doc.LocationCode,
                        DealWIPStatus: doc.DealWIPStatus,
                        DateArrived: dateArrived,
                        SLAReceivingDueDate: sLAReceivingDueDate,
                        CompliantToRecSLA: doc.CompliantToRecSLA,
                        BasicAuditWIPDate: basicAuditWIPDate,
                        BASLADueDate: bASLADueDate,
                        CompliantToBasicAuditSLA: doc.CompliantToBasicAuditSLA,
                        TechAuditWIPDate: techAuditWIPDate,
                        TechAuditSLADueDate: techAuditSLADueDate,
                        CompliantToTechAuditSLA: doc.CompliantToTechAuditSLA,
                        VerifyWIPDate: verifyWIPDate,
                        VerificationDueDate: verificationDueDate,
                        VerificationCompliantToSLA: doc.VerificationCompliantToSLA,
                        SalesDate: salesDate,
                        SLATurnoutDueDate: sLATurnoutDueDate,
                        CompliantTurnoutSLA: doc.CompliantTurnoutSLA,
                        CustomerPending: doc.CustomerPending,
                        CustomerPendingDate: customerPendingDate,
                        CustomerReleased: doc.CustomerReleased,
                        CustomerReleasedDate: customerReleasedDate,
                        // my extra fields
                        addedBy: user.uid,
                        dateCreated: new Date(today)
                    })
                })
                if (counter === data.length) {
                    // console.log('Added Records:', counter)
                    resolve('Successfully added ' + counter + ' records from CSV file.')
                }
                else {
                    reject('Something went wrong, added ' + counter + ' records of ' + data.length + '.')
                }
            }
            else {
                reject('No rows were added to metrics table.')
            }

            // data.TRMAID ='TRMA00123',
            // data.DispositionRouting='AUDIT',
            // data.BuyFromVendorName='MCDEAN INC',
            // data.LocationCode='ITADMD',
            // data.DealWIPStatus='Sales',
            // data.DateArrived=new Date('30/06/2021'),
            // data.SLAReceivingDueDate=new Date('02/07/2021'),
            // data.CompliantToRecSLA=true,
            // data.BasicAuditWIPDate=new Date('07/07/2021'),
            // data.BASLADueDate=new Date('07/07/2021'),
            // data.CompliantToBasicAuditSLA=true,
            // data.TechAuditWIPDate=new Date('07/07/2021'),
            // data.TechAuditSLADueDate=new Date('21/07/2021'),
            // data.CompliantToTechAuditSLA=true,
            // data.VerifyWIPDate=new Date('17/07/2021'),
            // data.VerificationDueDate=new Date('23/07/2021'),
            // data.VerificationCompliantToSLA=false,
            // data.SalesDate=new Date('16/11/2021'),
            // data.SLATurnoutDueDate=new Date('11/08/2021'),
            // data.CompliantTurnoutSLA=false
        })
    }
    addMetricsChainOfCustodyData = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var today = moment.utc();
            var counter = 0;
            var dataLength = data.length

            console.log(dataLength)

            // do write to db only if ALL data rows are correctly filled !!!!
            if (data.length > 0) {
                data.forEach(doc => {
                    counter++;

                    // DATE VALIDATION
                    var verifyWIPDate = moment(doc.VerifyWIPDate, 'DD-MMM-YY', true).isValid() ? new Date(doc.VerifyWIPDate) : 'N/A';

                    db.collection('metricsChainOfCustody').add({
                        BAWaybillNo: doc.BAWaybillNo,
                        BASerialNo: doc.BASerialNo,
                        BAProductType: doc.BAProductType,
                        BAOutgoingDispo: doc.BAOutgoingDispo,
                        LocationCode: doc.LocationCode,
                        VerifyWIPDate: verifyWIPDate,
                        NoOfPalletsOpen: Number(doc.NoOfPalletsOpen),
                        NoOfPalletsClosed: Number(doc.NoOfPalletsClosed),
                        TotalNoOfPallets: Number(doc.TotalNoOfPallets),
                        TAWaybillNo: doc.TAWaybillNo,
                        TASerialNo: doc.TASerialNo,
                        TAProductType: doc.TAProductType,
                        // my extra fields
                        addedBy: user.uid,
                        dateCreated: new Date(today)
                    })
                })
                if (counter === data.length) {
                    // console.log('Added Records:', counter)
                    resolve('Successfully added ' + counter + ' records from CSV file.')
                }
                else {
                    reject('Something went wrong, added ' + counter + ' records of ' + data.length + '.')
                }
            }
            else {
                reject('No rows were added to metrics table.')
            }
        })
    }

    createHiringRequisition = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var today = moment.utc();
            var tempFiles = [];

            if (user) {
                db.collection('recruitmentRequisitions').add({
                    Department: data.Department,
                    HiringManager: data.HiringManager,
                    HiringManagerEmail: data.HiringManagerEmail,
                    RoleTypeNew: data.RoleTypeNew,
                    RoleTypeReplacement: data.RoleTypeReplacement,
                    ReplacementFor: data.ReplacementFor,
                    PleaseProvideDetails: data.PleaseProvideDetails,
                    JobTitle: data.JobTitle,
                    ContractTypeFTC: data.ContractTypeFTC,
                    ContractTypePermanent: data.ContractTypePermanent,
                    ContractTypeDurationOfFTC: data.ContractTypeDurationOfFTC,
                    JdFinalisedHRYes: data.JdFinalisedHRYes,
                    JdFinalisedHRNo: data.JdFinalisedHRNo,
                    CopyOfJdAttachedYes: data.CopyOfJdAttachedYes,
                    CopyOfJdAttachedNo: data.CopyOfJdAttachedNo,
                    // BudgetRange: data.BudgetRange,
                    RoleToBeAdvertisedInternal: data.RoleToBeAdvertisedInternal,
                    RoleToBeAdvertisedExternal: data.RoleToBeAdvertisedExternal,
                    RoleToBeAdvertisedBoth: data.RoleToBeAdvertisedBoth,
                    FirstLevelApproval: data.FirstLevelApproval,
                    FirstLevelApprovalEmail: data.FirstLevelApprovalEmail,
                    SecondLevelApproval: data.SecondLevelApproval,
                    SecondLevelApprovalEmail: data.SecondLevelApprovalEmail,
                    CommentsNotes: data.CommentsNotes,
                    approvedLvl1: data.approvedLvl1,
                    approvedLvl2: data.approvedLvl2,
                    rejectedLvl1: data.rejectedLvl1,
                    rejectedLvl2: data.rejectedLvl2,
                    rejectedLvl1msg: data.rejectedLvl1msg,
                    rejectedLvl2msg: data.rejectedLvl2msg,
                    status: 'Created',
                    dateCreated: new Date(today),
                    files: data.files,
                    filesURLs: [],
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        data.RecordUID = docRef.id;
                        // save attachments if any added save in bucket ticketAttachments
                        if (data.filesToStoreInDB.length > 0) {
                            data.filesToStoreInDB.forEach(file => {
                                // console.log(file)
                                storage
                                    .ref('recruitmentRequisitionsAttachments/' + data.RecordUID + '/' + file.name)
                                    .put(file)
                                    .then((snap) => {
                                        snap.ref.getDownloadURL().then(imgUrl => {
                                            // console.log(imgUrl)
                                            tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                            db.collection("recruitmentRequisitions").doc(data.RecordUID).update({
                                                filesURLs: tempFiles,
                                            })
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        reject(err.message)
                                    })
                            })
                            if (data.approvedLvl1 || data.approvedLvl2) {
                                this.sendHiringRequisitionForHROneApproved(data)
                                resolve('Hiring requisition created successfully. Notification will be sent for second approval.')
                            }
                            else {
                                this.sendHiringRequisitionForHR(data)
                                resolve('Hiring requisition created successfully. It will be send for approval now.')
                            }
                        }
                        else {
                            if (data.approvedLvl1 || data.approvedLvl2) {
                                this.sendHiringRequisitionForHROneApproved(data)
                                resolve('Hiring requisition created successfully. Notification will be sent for second approval.')
                            }
                            else {
                                this.sendHiringRequisitionForHR(data)
                                resolve('Hiring requisition created successfully. It will be send for approval now.')
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateHiringRequisition = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('recruitmentRequisitions').doc(data.id).update({
                    status: data.status,
                    approvedLvl1: data.approvedLvl1,
                    approvedLvl2: data.approvedLvl2,
                    rejectedLvl1: data.rejectedLvl1,
                    rejectedLvl2: data.rejectedLvl2,
                    rejectedLvl1msg: data.rejectedLvl1msg,
                    rejectedLvl2msg: data.rejectedLvl2msg,
                })
                    .then(() => {
                        if (data.approvedLvl1 && data.approvedLvl2) {
                            // console.log(data)
                            this.sendHiringRequisitionForHRBothApproved(data)
                            resolve('Both managers approved this request. Notification will be sent to our HR department and hiring manager.')
                        }
                        else if (data.rejectedLvl1 || data.rejectedLvl2) {
                            this.sendHiringRequisitionForHRrejected(data)
                            resolve('This request has been rejected, hiring manager will be notified. Thank you.')
                        }
                        else if (data.approvedLvl1 || data.approvedLvl2) {
                            this.sendHiringRequisitionForHROneApproved(data)
                            resolve('Hiring requisition updated successfully. Notification will be sent for second approval.')
                        }
                        else {
                            resolve('Out of options, nothing was sent.')
                        }
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    addCustomerComplaintsStatus = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('customerComplaintsStatus').add({
                    Year: data.Year,
                    Week: data.Week,
                    Open: data.Open,
                    New: data.New,
                    Closed: data.Closed,
                    CCOpen: data.CCOpen,
                    CSATOpen: data.CSATOpen,
                    CCClosed: data.CCClosed,
                    CSATClosed: data.CSATClosed,
                    OpenValid: data.OpenValid,
                    ToBeValidated: data.ToBeValidated,
                    ToBeValidatedIre: data.ToBeValidatedIre,
                    ToBeValidatedUS: data.ToBeValidatedUS,
                    ToBeValidatedTH: data.ToBeValidatedTH,
                    ToBeValidatedUK: data.ToBeValidatedUK,
                    ToBeValidatedCC: data.ToBeValidatedCC,
                    ToBeValidatedCSAT: data.ToBeValidatedCSAT,
                    ValidCC: data.ValidCC,
                    ValidCSAT: data.ValidCSAT,
                    ValidIre: data.ValidIre,
                    ValidUS: data.ValidUS,
                    ValidTH: data.ValidTH,
                    ValidUK: data.ValidUK,
                    CCIre: data.CCIre,
                    CCUS: data.CCUS,
                    CSATIre: data.CSATIre,
                    CSATUS: data.CSATUS
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        // data.RecordUID = docRef.id;
                        resolve('Successfully added new record to Customer Complaint table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateCustomerComplaintsStatus = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('customerComplaintsStatus').doc(data.id).update({
                    Year: data.Year,
                    Week: data.Week,
                    Open: data.Open,
                    New: data.New,
                    Closed: data.Closed,
                    CCOpen: data.CCOpen,
                    CSATOpen: data.CSATOpen,
                    CCClosed: data.CCClosed,
                    CSATClosed: data.CSATClosed,
                    OpenValid: data.OpenValid,
                    ToBeValidated: data.ToBeValidated,
                    ToBeValidatedIre: data.ToBeValidatedIre,
                    ToBeValidatedUS: data.ToBeValidatedUS,
                    ToBeValidatedTH: data.ToBeValidatedTH,
                    ToBeValidatedUK: data.ToBeValidatedUK,
                    ToBeValidatedCC: data.ToBeValidatedCC,
                    ToBeValidatedCSAT: data.ToBeValidatedCSAT,
                    ValidCC: data.ValidCC,
                    ValidCSAT: data.ValidCSAT,
                    ValidIre: data.ValidIre,
                    ValidUS: data.ValidUS,
                    ValidTH: data.ValidTH,
                    ValidUK: data.ValidUK,
                    CCIre: data.CCIre,
                    CCUS: data.CCUS,
                    CSATIre: data.CSATIre,
                    CSATUS: data.CSATUS
                })
                    .then(() => {
                        resolve('Successfully updated record in Customer Complaint table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteCustomerComplaintsStatus = (dataId) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('customerComplaintsStatus').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record in Customer Complaint table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    addCapaVol = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('qualityCapaVol').add({
                    Year: data.Year,
                    Week: data.Week,
                    Open: data.Open,
                    New: data.New,
                    Closed: data.Closed,
                    OpenIre: data.OpenIre,
                    NewIre: data.NewIre,
                    ClosedIre: data.ClosedIre,
                    OpenUS: data.OpenUS,
                    NewUS: data.NewUS,
                    ClosedUS: data.ClosedUS,
                    OpenThai: data.OpenThai,
                    NewThai: data.NewThai,
                    ClosedThai: data.ClosedThai,
                    OpenUK: data.OpenUK,
                    NewUK: data.NewUK,
                    ClosedUK: data.ClosedUK,
                    AverageAging: data.AverageAging,
                    AverageAgingIre: data.AverageAgingIre,
                    OldestIre: data.OldestIre,
                    AverageAgingUS: data.AverageAgingUS,
                    OldestUS: data.OldestUS,
                    AverageAgingThai: data.AverageAgingThai,
                    OldestThai: data.OldestThai,
                    AverageAgingUK: data.AverageAgingUK,
                    OldestUK: data.OldestUK,
                    Goal: data.Goal,
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        // data.RecordUID = docRef.id;
                        resolve('Successfully added new record to Capa Volume table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateCapaVol = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('qualityCapaVol').doc(data.id).update({
                    // actualHours: data.userSetActual,
                })
                    .then(() => {
                        resolve('Successfully updated record in Capa Volume table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteCapaVol = (dataId) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('qualityCapaVol').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record in Capa Volume table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    addMetricsCustomerSLAIEandUK = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            var itadCorkRecCalc = data.itadCorkOpen === 0 ? data.itadCorkRecComp === data.itadCorkOpen ? itadCorkRecCalc = 100 : itadCorkRecCalc = 0 : itadCorkRecCalc = Math.round(((data.itadCorkRecComp / data.itadCorkOpen) * 100) * 10) / 10; //to have decimal percentage
            var itadCorkBACalc = data.itadCorkReqBA === 0 ? data.itadCorkBAComp === data.itadCorkReqBA ? itadCorkBACalc = 100 : itadCorkBACalc = 0 : itadCorkBACalc = Math.round(((data.itadCorkBAComp / data.itadCorkReqBA) * 100) * 10) / 10; //to have decimal percentage
            var itadCorkTACalc = data.itadCorkReqTA === 0 ? data.itadCorkTAComp === data.itadCorkReqTA ? itadCorkTACalc = 100 : itadCorkTACalc = 0 : itadCorkTACalc = Math.round(((data.itadCorkTAComp / data.itadCorkReqTA) * 100) * 10) / 10; //to have decimal percentage
            var itadCorkTOCalc = data.itadCorkReqTO === 0 ? data.itadCorkTOComp === data.itadCorkReqTO ? itadCorkTOCalc = 100 : itadCorkTOCalc = 0 : itadCorkTOCalc = Math.round(((data.itadCorkTOComp / data.itadCorkReqTO) * 100) * 10) / 10; //to have decimal percentage 
            var itadMKRecCalc = data.itadMKOpen === 0 ? data.itadMKRecComp === data.itadMKOpen ? itadMKRecCalc = 100 : itadMKRecCalc = 0 : itadMKRecCalc = Math.round(((data.itadMKRecComp / data.itadMKOpen) * 100) * 10) / 10; //to have decimal percentage 
            var itadMKBACalc = data.itadMKReqBA === 0 ? data.itadMKBAComp === data.itadMKReqBA ? itadMKBACalc = 100 : itadMKBACalc = 0 : itadMKBACalc = Math.round(((data.itadMKBAComp / data.itadMKReqBA) * 100) * 10) / 10; //to have decimal percentage 
            var itadMKTACalc = data.itadMKReqTA === 0 ? data.itadMKTAComp === data.itadMKReqTA ? itadMKTACalc = 100 : itadMKTACalc = 0 : itadMKTACalc = Math.round(((data.itadMKTAComp / data.itadMKReqTA) * 100) * 10) / 10; //to have decimal percentage 
            var itadMKTOCalc = data.itadMKReqTO === 0 ? data.itadMKTOComp === data.itadMKReqTO ? itadMKTOCalc = 100 : itadMKTOCalc = 0 : itadMKTOCalc = Math.round(((data.itadMKTOComp / data.itadMKReqTO) * 100) * 10) / 10; //to have decimal percentage 

            if (user) {
                db.collection('metricsIEandUK').add({
                    Year: data.Year,
                    Week: data.Week,
                    //inputs
                    itadCorkOpen: data.itadCorkOpen,
                    itadCorkRecComp: data.itadCorkRecComp,
                    itadCorkReqBA: data.itadCorkReqBA,
                    itadCorkBAComp: data.itadCorkBAComp,
                    itadCorkReqTA: data.itadCorkReqTA,
                    itadCorkTAComp: data.itadCorkTAComp,
                    itadCorkReqTO: data.itadCorkReqTO,
                    itadCorkTOComp: data.itadCorkTOComp,
                    itadMKOpen: data.itadMKOpen,
                    itadMKRecComp: data.itadMKRecComp,
                    itadMKReqBA: data.itadMKReqBA,
                    itadMKBAComp: data.itadMKBAComp,
                    itadMKReqTA: data.itadMKReqTA,
                    itadMKTAComp: data.itadMKTAComp,
                    itadMKReqTO: data.itadMKReqTO,
                    itadMKTOComp: data.itadMKTOComp,
                    //calcluated percentages
                    itadCorkRec: itadCorkRecCalc,
                    itadCorkBA: itadCorkBACalc,
                    itadCorkTA: itadCorkTACalc,
                    itadCorkTO: itadCorkTOCalc,
                    itadMKRec: itadMKRecCalc,
                    itadMKBA: itadMKBACalc,
                    itadMKTA: itadMKTACalc,
                    itadMKTO: itadMKTOCalc,
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        // data.RecordUID = docRef.id;
                        resolve('Successfully added new record to Deals in Sales table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateMetricsCustomerSLAIEandUK = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            var itadCorkRecCalc = data.itadCorkOpen === 0 ? data.itadCorkRecComp === data.itadCorkOpen ? itadCorkRecCalc = 100 : itadCorkRecCalc = 0 : itadCorkRecCalc = Math.round(((data.itadCorkRecComp / data.itadCorkOpen) * 100) * 10) / 10; //to have decimal percentage
            var itadCorkBACalc = data.itadCorkReqBA === 0 ? data.itadCorkBAComp === data.itadCorkReqBA ? itadCorkBACalc = 100 : itadCorkBACalc = 0 : itadCorkBACalc = Math.round(((data.itadCorkBAComp / data.itadCorkReqBA) * 100) * 10) / 10; //to have decimal percentage
            var itadCorkTACalc = data.itadCorkReqTA === 0 ? data.itadCorkTAComp === data.itadCorkReqTA ? itadCorkTACalc = 100 : itadCorkTACalc = 0 : itadCorkTACalc = Math.round(((data.itadCorkTAComp / data.itadCorkReqTA) * 100) * 10) / 10; //to have decimal percentage
            var itadCorkTOCalc = data.itadCorkReqTO === 0 ? data.itadCorkTOComp === data.itadCorkReqTO ? itadCorkTOCalc = 100 : itadCorkTOCalc = 0 : itadCorkTOCalc = Math.round(((data.itadCorkTOComp / data.itadCorkReqTO) * 100) * 10) / 10; //to have decimal percentage 
            var itadMKRecCalc = data.itadMKOpen === 0 ? data.itadMKRecComp === data.itadMKOpen ? itadMKRecCalc = 100 : itadMKRecCalc = 0 : itadMKRecCalc = Math.round(((data.itadMKRecComp / data.itadMKOpen) * 100) * 10) / 10; //to have decimal percentage 
            var itadMKBACalc = data.itadMKReqBA === 0 ? data.itadMKBAComp === data.itadMKReqBA ? itadMKBACalc = 100 : itadMKBACalc = 0 : itadMKBACalc = Math.round(((data.itadMKBAComp / data.itadMKReqBA) * 100) * 10) / 10; //to have decimal percentage 
            var itadMKTACalc = data.itadMKReqTA === 0 ? data.itadMKTAComp === data.itadMKReqTA ? itadMKTACalc = 100 : itadMKTACalc = 0 : itadMKTACalc = Math.round(((data.itadMKTAComp / data.itadMKReqTA) * 100) * 10) / 10; //to have decimal percentage 
            var itadMKTOCalc = data.itadMKReqTO === 0 ? data.itadMKTOComp === data.itadMKReqTO ? itadMKTOCalc = 100 : itadMKTOCalc = 0 : itadMKTOCalc = Math.round(((data.itadMKTOComp / data.itadMKReqTO) * 100) * 10) / 10; //to have decimal percentage 

            if (user) {
                db.collection('metricsIEandUK').doc(data.id).update({
                    Year: data.Year,
                    Week: data.Week,
                    //inputs
                    itadCorkOpen: data.itadCorkOpen,
                    itadCorkRecComp: data.itadCorkRecComp,
                    itadCorkReqBA: data.itadCorkReqBA,
                    itadCorkBAComp: data.itadCorkBAComp,
                    itadCorkReqTA: data.itadCorkReqTA,
                    itadCorkTAComp: data.itadCorkTAComp,
                    itadCorkReqTO: data.itadCorkReqTO,
                    itadCorkTOComp: data.itadCorkTOComp,
                    itadMKOpen: data.itadMKOpen,
                    itadMKRecComp: data.itadMKRecComp,
                    itadMKReqBA: data.itadMKReqBA,
                    itadMKBAComp: data.itadMKBAComp,
                    itadMKReqTA: data.itadMKReqTA,
                    itadMKTAComp: data.itadMKTAComp,
                    itadMKReqTO: data.itadMKReqTO,
                    itadMKTOComp: data.itadMKTOComp,
                    //calcluated percentages
                    itadCorkRec: itadCorkRecCalc,
                    itadCorkBA: itadCorkBACalc,
                    itadCorkTA: itadCorkTACalc,
                    itadCorkTO: itadCorkTOCalc,
                    itadMKRec: itadMKRecCalc,
                    itadMKBA: itadMKBACalc,
                    itadMKTA: itadMKTACalc,
                    itadMKTO: itadMKTOCalc,
                })
                    .then(() => {
                        resolve('Successfully updated record in Deals in Sales table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteMetricsCustomerSLAIEandUK = (dataId) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('metricsIEandUK').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record in Deals in Sales table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    addDealIntegrity = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('qualityDealIntegrity').add({
                    dateCreated: new Date(data.dateCreated),
                    AuthorisedBOS: data.AuthorisedBOS,
                    BaReqBOS: data.BaReqBOS,
                    BaRecBOS: data.BaRecBOS,
                    PalletClosedBOS: data.PalletClosedBOS,
                    TaReqBOS: data.TaReqBOS,
                    TaOutBOS: data.TaOutBOS,
                    WaybilCompBOS: data.WaybilCompBOS,
                    AuthorisedMD: data.AuthorisedMD,
                    BaReqMD: data.BaReqMD,
                    BaRecMD: data.BaRecMD,
                    PalletClosedMD: data.PalletClosedMD,
                    TaReqMD: data.TaReqMD,
                    TaOutMD: data.TaOutMD,
                    WaybilCompMD: data.WaybilCompMD,
                    AuthorisedSAC: data.AuthorisedSAC,
                    BaReqSAC: data.BaReqSAC,
                    BaRecSAC: data.BaRecSAC,
                    PalletClosedSAC: data.PalletClosedSAC,
                    TaReqSAC: data.TaReqSAC,
                    TaOutSAC: data.TaOutSAC,
                    WaybilCompSAC: data.WaybilCompSAC,
                    AuthorisedTX: data.AuthorisedTX,
                    BaReqTX: data.BaReqTX,
                    BaRecTX: data.BaRecTX,
                    PalletClosedTX: data.PalletClosedTX,
                    TaReqTX: data.TaReqTX,
                    TaOutTX: data.TaOutTX,
                    WaybilCompTX: data.WaybilCompTX,
                    AuthorisedVA: data.AuthorisedVA,
                    BaReqVA: data.BaReqVA,
                    BaRecVA: data.BaRecVA,
                    PalletClosedVA: data.PalletClosedVA,
                    TaReqVA: data.TaReqVA,
                    TaOutVA: data.TaOutVA,
                    WaybilCompVA: data.WaybilCompVA,
                    AuthorisedUStotal: data.AuthorisedBOS + data.AuthorisedMD + data.AuthorisedSAC + data.AuthorisedTX + data.AuthorisedVA,
                    BaReqUStotal: data.BaReqBOS + data.BaReqMD + data.BaReqSAC + data.BaReqTX + data.BaReqVA,
                    BaRecUStotal: data.BaRecBOS + data.BaRecMD + data.BaRecSAC + data.BaRecTX + data.BaRecVA,
                    PalletClosedUStotal: data.PalletClosedBOS + data.PalletClosedMD + data.PalletClosedSAC + data.PalletClosedTX + data.PalletClosedVA,
                    TaReqUStotal: data.TaReqBOS + data.TaReqMD + data.TaReqSAC + data.TaReqTX + data.TaReqVA,
                    TaOutUStotal: data.TaOutBOS + data.TaOutMD + data.TaOutSAC + data.TaOutTX + data.TaOutVA,
                    WaybilCompUStotal: data.WaybilCompBOS + data.WaybilCompMD + data.WaybilCompSAC + data.WaybilCompTX + data.WaybilCompVA,
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        // data.RecordUID = docRef.id;
                        resolve('Successfully added new record to Deal Integrity table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateDealIntegrity = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;


            if (user) {
                db.collection('qualityDealIntegrity').doc(data.id).update({
                    // dateCreated: new Date(data.dateCreated),
                    AuthorisedBOS: data.AuthorisedBOS,
                    BaReqBOS: data.BaReqBOS,
                    BaRecBOS: data.BaRecBOS,
                    PalletClosedBOS: data.PalletClosedBOS,
                    TaReqBOS: data.TaReqBOS,
                    TaOutBOS: data.TaOutBOS,
                    WaybilCompBOS: data.WaybilCompBOS,
                    AuthorisedMD: data.AuthorisedMD,
                    BaReqMD: data.BaReqMD,
                    BaRecMD: data.BaRecMD,
                    PalletClosedMD: data.PalletClosedMD,
                    TaReqMD: data.TaReqMD,
                    TaOutMD: data.TaOutMD,
                    WaybilCompMD: data.WaybilCompMD,
                    AuthorisedSAC: data.AuthorisedSAC,
                    BaReqSAC: data.BaReqSAC,
                    BaRecSAC: data.BaRecSAC,
                    PalletClosedSAC: data.PalletClosedSAC,
                    TaReqSAC: data.TaReqSAC,
                    TaOutSAC: data.TaOutSAC,
                    WaybilCompSAC: data.WaybilCompSAC,
                    AuthorisedTX: data.AuthorisedTX,
                    BaReqTX: data.BaReqTX,
                    BaRecTX: data.BaRecTX,
                    PalletClosedTX: data.PalletClosedTX,
                    TaReqTX: data.TaReqTX,
                    TaOutTX: data.TaOutTX,
                    WaybilCompTX: data.WaybilCompTX,
                    AuthorisedVA: data.AuthorisedVA,
                    BaReqVA: data.BaReqVA,
                    BaRecVA: data.BaRecVA,
                    PalletClosedVA: data.PalletClosedVA,
                    TaReqVA: data.TaReqVA,
                    TaOutVA: data.TaOutVA,
                    WaybilCompVA: data.WaybilCompVA,
                    AuthorisedUStotal: data.AuthorisedBOS + data.AuthorisedMD + data.AuthorisedSAC + data.AuthorisedTX + data.AuthorisedVA,
                    BaReqUStotal: data.BaReqBOS + data.BaReqMD + data.BaReqSAC + data.BaReqTX + data.BaReqVA,
                    BaRecUStotal: data.BaRecBOS + data.BaRecMD + data.BaRecSAC + data.BaRecTX + data.BaRecVA,
                    PalletClosedUStotal: data.PalletClosedBOS + data.PalletClosedMD + data.PalletClosedSAC + data.PalletClosedTX + data.PalletClosedVA,
                    TaReqUStotal: data.TaReqBOS + data.TaReqMD + data.TaReqSAC + data.TaReqTX + data.TaReqVA,
                    TaOutUStotal: data.TaOutBOS + data.TaOutMD + data.TaOutSAC + data.TaOutTX + data.TaOutVA,
                    WaybilCompUStotal: data.WaybilCompBOS + data.WaybilCompMD + data.WaybilCompSAC + data.WaybilCompTX + data.WaybilCompVA,
                })
                    .then(() => {
                        resolve('Successfully updated record in Deal Integrity table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteDealIntegrity = (dataId) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('qualityDealIntegrity').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record in Deal Integrity table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    addVerificationWip = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            var compliantPercentIE = data.totalIE === 0 ? data.compliantIE === data.totalIE ? compliantPercentIE = 100 : compliantPercentIE = 0 : compliantPercentIE = Math.round(((data.compliantIE / data.totalIE) * 100) * 10) / 10; //to have decimal percentage
            var compliantPercentUS = data.totalUS === 0 ? data.compliantUS === data.totalUS ? compliantPercentUS = 100 : compliantPercentUS = 0 : compliantPercentUS = Math.round(((data.compliantUS / data.totalUS) * 100) * 10) / 10; //to have decimal percentage
            var compliantPercentUK = data.totalUK === 0 ? data.compliantUK === data.totalUK ? compliantPercentUK = 100 : compliantPercentUK = 0 : compliantPercentUK = Math.round(((data.compliantUK / data.totalUK) * 100) * 10) / 10; //to have decimal percentage

            if (user) {
                db.collection('qualityVerificationWip').add({
                    dateCreated: data.dateCreated,
                    totalIE: data.totalIE,
                    discrepantIE: data.discrepantIE,
                    compliantIE: data.compliantIE,
                    compliantPercIE: compliantPercentIE,
                    totalUS: data.totalUS,
                    discrepantUS: data.discrepantUS,
                    compliantUS: data.compliantUS,
                    compliantPercUS: compliantPercentUS,
                    totalUK: data.totalUK,
                    discrepantUK: data.discrepantUK,
                    compliantUK: data.compliantUK,
                    compliantPercUK: compliantPercentUK,
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        // data.RecordUID = docRef.id;
                        resolve('Successfully added new record to Verification Wip table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateVerificationWip = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            var compliantPercentIE = data.totalIE === 0 ? data.compliantIE === data.totalIE ? compliantPercentIE = 100 : compliantPercentIE = 0 : compliantPercentIE = Math.round(((data.compliantIE / data.totalIE) * 100) * 10) / 10; //to have decimal percentage
            var compliantPercentUS = data.totalUS === 0 ? data.compliantUS === data.totalUS ? compliantPercentUS = 100 : compliantPercentUS = 0 : compliantPercentUS = Math.round(((data.compliantUS / data.totalUS) * 100) * 10) / 10; //to have decimal percentage
            var compliantPercentUK = data.totalUK === 0 ? data.compliantUK === data.totalUK ? compliantPercentUK = 100 : compliantPercentUK = 0 : compliantPercentUK = Math.round(((data.compliantUK / data.totalUK) * 100) * 10) / 10; //to have decimal percentage

            if (user) {
                db.collection('qualityVerificationWip').doc(data.id).update({
                    // dateCreated: new Date(data.dateCreated),
                    totalIE: data.totalIE,
                    discrepantIE: data.discrepantIE,
                    compliantIE: data.compliantIE,
                    compliantPercIE: compliantPercentIE,
                    totalUS: data.totalUS,
                    discrepantUS: data.discrepantUS,
                    compliantUS: data.compliantUS,
                    compliantPercUS: compliantPercentUS,
                    totalUK: data.totalUK,
                    discrepantUK: data.discrepantUK,
                    compliantUK: data.compliantUK,
                    compliantPercUK: compliantPercentUK,
                })
                    .then(() => {
                        resolve('Successfully updated record in Verification Wip table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteVerificationWip = (dataId) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('qualityVerificationWip').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record in Verification Wip table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    addDealsInSales = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            var compliantPercentIE = data.totalIE === 0 ? data.compliantIE === data.totalIE ? compliantPercentIE = 100 : compliantPercentIE = 0 : compliantPercentIE = Math.round(((data.compliantIE / data.totalIE) * 100) * 10) / 10; //to have decimal percentage
            var compliantPercentUS = data.totalUS === 0 ? data.compliantUS === data.totalUS ? compliantPercentUS = 100 : compliantPercentUS = 0 : compliantPercentUS = Math.round(((data.compliantUS / data.totalUS) * 100) * 10) / 10; //to have decimal percentage
            var compliantPercentUK = data.totalUK === 0 ? data.compliantUK === data.totalUK ? compliantPercentUK = 100 : compliantPercentUK = 0 : compliantPercentUK = Math.round(((data.compliantUK / data.totalUK) * 100) * 10) / 10; //to have decimal percentage
            var verifiedCompliantPercentIE = data.totalIE === 0 ? data.dealsWithPartsCompliantIE === data.totalIE ? verifiedCompliantPercentIE = 100 : verifiedCompliantPercentIE = 0 : verifiedCompliantPercentIE = Math.round(((data.dealsWithPartsCompliantIE / data.totalIE) * 100) * 10) / 10; //to have decimal percentage
            var verifiedCompliantPercentUS = data.totalUS === 0 ? data.dealsWithPartsCompliantUS === data.totalUS ? verifiedCompliantPercentUS = 100 : verifiedCompliantPercentUS = 0 : verifiedCompliantPercentUS = Math.round(((data.dealsWithPartsCompliantUS / data.totalUS) * 100) * 10) / 10; //to have decimal percentage
            var verifiedCompliantPercentUK = data.totalUK === 0 ? data.dealsWithPartsCompliantUK === data.totalUK ? verifiedCompliantPercentUK = 100 : verifiedCompliantPercentUK = 0 : verifiedCompliantPercentUK = Math.round(((data.dealsWithPartsCompliantUK / data.totalUK) * 100) * 10) / 10; //to have decimal percentage

            if (user) {
                db.collection('qualityDealsInSales').add({
                    dateCreated: data.dateCreated,
                    totalIE: data.totalIE,
                    discrepantIE: data.discrepantIE,
                    compliantIE: data.compliantIE,
                    compliantPercIE: compliantPercentIE,
                    dealsWithPartsIE: data.dealsWithPartsIE,
                    dealsWithPartsCompliantIE: data.dealsWithPartsCompliantIE,
                    verifiedCompliantPercIE: verifiedCompliantPercentIE,
                    totalUS: data.totalUS,
                    discrepantUS: data.discrepantUS,
                    compliantUS: data.compliantUS,
                    compliantPercUS: compliantPercentUS,
                    dealsWithPartsUS: data.dealsWithPartsUS,
                    dealsWithPartsCompliantUS: data.dealsWithPartsCompliantUS,
                    verifiedCompliantPercUS: verifiedCompliantPercentUS,
                    totalUK: data.totalUK,
                    discrepantUK: data.discrepantUK,
                    compliantUK: data.compliantUK,
                    compliantPercUK: compliantPercentUK,
                    dealsWithPartsUK: data.dealsWithPartsUK,
                    dealsWithPartsCompliantUK: data.dealsWithPartsCompliantUK,
                    verifiedCompliantPercUK: verifiedCompliantPercentUK,
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        // data.RecordUID = docRef.id;
                        resolve('Successfully added new record to Deals in Sales table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateDealsInSales = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            var compliantPercentIE = data.totalIE === 0 ? data.compliantIE === data.totalIE ? compliantPercentIE = 100 : compliantPercentIE = 0 : compliantPercentIE = Math.round(((data.compliantIE / data.totalIE) * 100) * 10) / 10; //to have decimal percentage
            var compliantPercentUS = data.totalUS === 0 ? data.compliantUS === data.totalUS ? compliantPercentUS = 100 : compliantPercentUS = 0 : compliantPercentUS = Math.round(((data.compliantUS / data.totalUS) * 100) * 10) / 10; //to have decimal percentage
            var compliantPercentUK = data.totalUK === 0 ? data.compliantUK === data.totalUK ? compliantPercentUK = 100 : compliantPercentUK = 0 : compliantPercentUK = Math.round(((data.compliantUK / data.totalUK) * 100) * 10) / 10; //to have decimal percentage
            var verifiedCompliantPercentIE = data.totalIE === 0 ? data.dealsWithPartsCompliantIE === data.totalIE ? verifiedCompliantPercentIE = 100 : verifiedCompliantPercentIE = 0 : verifiedCompliantPercentIE = Math.round(((data.dealsWithPartsCompliantIE / data.totalIE) * 100) * 10) / 10; //to have decimal percentage
            var verifiedCompliantPercentUS = data.totalUS === 0 ? data.dealsWithPartsCompliantUS === data.totalUS ? verifiedCompliantPercentUS = 100 : verifiedCompliantPercentUS = 0 : verifiedCompliantPercentUS = Math.round(((data.dealsWithPartsCompliantUS / data.totalUS) * 100) * 10) / 10; //to have decimal percentage
            var verifiedCompliantPercentUK = data.totalUK === 0 ? data.dealsWithPartsCompliantUK === data.totalUK ? verifiedCompliantPercentUK = 100 : verifiedCompliantPercentUK = 0 : verifiedCompliantPercentUK = Math.round(((data.dealsWithPartsCompliantUK / data.totalUK) * 100) * 10) / 10; //to have decimal percentage

            if (user) {
                db.collection('qualityDealsInSales').doc(data.id).update({
                    // dateCreated: data.dateCreated,
                    totalIE: data.totalIE,
                    discrepantIE: data.discrepantIE,
                    compliantIE: data.compliantIE,
                    compliantPercIE: compliantPercentIE,
                    dealsWithPartsIE: data.dealsWithPartsIE,
                    dealsWithPartsCompliantIE: data.dealsWithPartsCompliantIE,
                    verifiedCompliantPercIE: verifiedCompliantPercentIE,
                    totalUS: data.totalUS,
                    discrepantUS: data.discrepantUS,
                    compliantUS: data.compliantUS,
                    compliantPercUS: compliantPercentUS,
                    dealsWithPartsUS: data.dealsWithPartsUS,
                    dealsWithPartsCompliantUS: data.dealsWithPartsCompliantUS,
                    verifiedCompliantPercUS: verifiedCompliantPercentUS,
                    totalUK: data.totalUK,
                    discrepantUK: data.discrepantUK,
                    compliantUK: data.compliantUK,
                    compliantPercUK: compliantPercentUK,
                    dealsWithPartsUK: data.dealsWithPartsUK,
                    dealsWithPartsCompliantUK: data.dealsWithPartsCompliantUK,
                    verifiedCompliantPercUK: verifiedCompliantPercentUK,
                })
                    .then(() => {
                        resolve('Successfully updated record in Deals in Sales table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteDealsInSales = (dataId) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('qualityDealsInSales').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record in Deals in Sales table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    addRoomBooking = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('roomBookings').add({
                    Host: data.Host,
                    Subject: data.Subject,
                    Location: data.Location,
                    RoomName: data.RoomName,
                    DateAndTime: data.DateAndTime,
                    DateAndTimeString: data.DateAndTimeString,
                    Duration: data.Duration,
                    RoomRequirements: data.RoomRequirements,
                    Participants: data.Participants,
                    dateCreated: new Date(),
                    dateUpdated: '',
                })
                    .then(docRef => {
                        this.sendRoomBookingConfirmation(data)
                        resolve('Successfully created new Room Booking.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateRoomBooking = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('roomBookings').doc(data.id).update({
                    Host: data.Host,
                    Subject: data.Subject,
                    Location: data.Location,
                    RoomName: data.RoomName,
                    DateAndTime: data.DateAndTime,
                    DateAndTimeString: data.DateAndTimeString,
                    Duration: data.Duration,
                    RoomRequirements: data.RoomRequirements,
                    Participants: data.Participants,
                    // dateCreated: new Date(),
                    dateUpdated: new Date(),
                })
                    .then(() => {
                        // this.sendRoomBookingUpdateConfirmation(data)
                        resolve('Successfully updated record in Room Bookings table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteRoomBooking = (dataId) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('roomBookings').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record in Room Bookings table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    // this is used in two places VerificationWip and DealsInSales
    addAttachments = (data) => {
        return new Promise((resolve, reject) => {
            // //FILEDS NEEDED
            // files: [],
            // filesSize: 0,
            // filesToStoreInDB: [],
            // id: props.rowData.id,
            // bucketName:'verificationWipAttachments',
            // targetDB:'',
            // successMsg:'Successfully added file to that record in Verification Wip table.'
            // errorMsg:'Failed to add file to that record in Verification Wip table.'

            // save attachments if any added save in bucket 
            if (data.filesToStoreInDB.length > 0) {
                data.filesToStoreInDB.forEach(file => {
                    // console.log(file)
                    storage
                        .ref(data.bucketName + '/' + data.id + '/' + file.name)
                        .put(file)
                        .then((snap) => {
                            // console.log(snap)
                            snap.ref.getDownloadURL().then(imgUrl => {
                                // console.log(imgUrl)
                                data.filesURLs.push({ name: file.name, url: imgUrl, type: file.type })
                                db.collection(data.targetDB).doc(data.id).update({
                                    filesURLs: data.filesURLs,
                                })
                            })
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err.message)
                        })
                })
                resolve(data.successMsg)
            }
            else {
                reject(data.errorMsg)
            }
        })
    }

    addIncidentLog = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            console.log(data)
            if (user) {
                db.collection('incidentLogsIT').add({
                    ReportedDate: data.ReportedDate ? new Date(data.ReportedDate) : null,
                    Category: data.Category ? data.Category : '',
                    ReportedBy: data.ReportedBy ? data.ReportedBy : '',
                    DataLoss: data.DataLoss ? data.DataLoss : false,
                    Responder: data.Responder ? data.Responder : '',
                    UserSystemScanType: data.UserSystemScanType ? data.UserSystemScanType : '',
                    MalwareDetected: data.MalwareDetected ? data.MalwareDetected : false,
                    RiskSeverity: data.RiskSeverity ? data.RiskSeverity : '',
                    Description: data.Description ? data.Description : '',
                    SandboxCheck: data.SandboxCheck ? data.SandboxCheck : false,
                    LogCheck: data.LogCheck ? data.LogCheck : false,
                    EPOMonitor: data.EPOMonitor ? data.EPOMonitor : '',
                    ISMSTeamNotified: data.ISMSTeamNotified ? data.ISMSTeamNotified : false,
                    ThirdPartNotified: data.ThirdPartNotified ? data.ThirdPartNotified : false,
                    NSCNotified: data.NSCNotified ? data.NSCNotified : false,
                    CapaNo: data.CapaNo ? data.CapaNo : '',
                    Comments: data.Comments ? data.Comments : '',
                    FurtherActions: data.FurtherActions ? data.FurtherActions : '',
                    PendingActions: data.PendingActions ? data.PendingActions : '',
                    Status: 'Open',
                    dateCreated: new Date(),
                    dateUpdated: '',
                    dateClosed: data.dateClosed ? new Date(data.dateClosed) : '',
                })
                    .then(docRef => {
                        this.sendRoomBookingConfirmation(data)
                        resolve('Successfully created new record in Incident Log table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateIncidentLog = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            console.log(data)
            if (user) {
                db.collection('incidentLogsIT').doc(data.id).update({
                    ReportedDate: data.ReportedDate === 'N/A' ? null : new Date(data.ReportedDate),
                    Category: data.Category ? data.Category : '',
                    ReportedBy: data.ReportedBy ? data.ReportedBy : '',
                    DataLoss: data.DataLoss ? data.DataLoss : false,
                    Responder: data.Responder ? data.Responder : '',
                    UserSystemScanType: data.UserSystemScanType ? data.UserSystemScanType : '',
                    MalwareDetected: data.MalwareDetected ? data.MalwareDetected : false,
                    RiskSeverity: data.RiskSeverity ? data.RiskSeverity : '',
                    Description: data.Description ? data.Description : '',
                    SandboxCheck: data.SandboxCheck ? data.SandboxCheck : false,
                    LogCheck: data.LogCheck ? data.LogCheck : false,
                    EPOMonitor: data.EPOMonitor ? data.EPOMonitor : '',
                    ISMSTeamNotified: data.ISMSTeamNotified ? data.ISMSTeamNotified : false,
                    ThirdPartNotified: data.ThirdPartNotified ? data.ThirdPartNotified : false,
                    NSCNotified: data.NSCNotified ? data.NSCNotified : false,
                    CapaNo: data.CapaNo ? data.CapaNo : '',
                    Comments: data.Comments ? data.Comments : '',
                    FurtherActions: data.FurtherActions ? data.FurtherActions : '',
                    PendingActions: data.PendingActions ? data.PendingActions : '',
                    Status: data.Status ? data.Status : '',
                    dateClosed: data.Status === 'Closed' ? new Date() : '',
                    dateUpdated: new Date(),
                })
                    .then(() => {
                        // this.sendRoomBookingUpdateConfirmation(data)
                        resolve('Successfully updated record in Incident Log table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteIncidentLog = (dataId) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('incidentLogsIT').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record from Incident Log table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    addMemoryScanPartsAndBins = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('memoryScanPartsAndBins').add({
                    BinNumber: Number(data.BinNumber),
                    PartNumber: String(data.PartNumber).toUpperCase(),
                    dateCreated: new Date(),
                    dateUpdated: '',
                    addedBy: user.uid,
                    addedByUser: user.displayName,
                })
                    .then(docRef => {
                        resolve('Successfully created new record in memory scan parts and bins table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    updateMemoryScanPartsAndBins = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('memoryScanPartsAndBins').doc(data.id).update({
                    Country: data.Country,
                    Manufacturer: data.Manufacturer,
                    Prefix: data.Prefix,
                    dateUpdated: new Date(),
                })
                    .then(() => {
                        resolve('Successfully updated record in memory scan parts and bins table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteMemoryScanPartsAndBins = (dataId) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('memoryScanPartsAndBins').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record from memory scan parts and bins table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    addMemoryScanCheckSheet = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var lowerCountry = data.Country.toLowerCase()
            var capCountry = lowerCountry === 'unknown' ? 'Unknown' : lowerCountry.toUpperCase();
            var capManufacturer = data.Manufacturer.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
            var capPrefix = data.Prefix.toUpperCase();

            if (user) {
                db.collection('memoryScanCheckSheet').add({
                    Country: capCountry,
                    Manufacturer: capManufacturer,
                    Prefix: capPrefix,
                    dateCreated: new Date(),
                    dateUpdated: '',
                })
                    .then(docRef => {
                        resolve('Successfully created new record in memory scan check sheet table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    updateMemoryScanCheckSheet = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var lowerCountry = data.Country.toLowerCase()
            var capCountry = lowerCountry === 'unknown' ? 'Unknown' : lowerCountry.toUpperCase();
            var capManufacturer = data.Manufacturer.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
            var capPrefix = data.Prefix.toUpperCase();

            if (user) {
                db.collection('memoryScanCheckSheet').doc(data.id).update({
                    Country: capCountry,
                    Manufacturer: capManufacturer,
                    Prefix: capPrefix,
                    dateUpdated: new Date(),
                })
                    .then(() => {
                        resolve('Successfully updated record in memory scan check sheet table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteMemoryScanCheckSheet = (dataId) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('memoryScanCheckSheet').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record from memory scan check sheet table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    howToVideosAddNew = (data, collectionName, storageRefName) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var tempFiles = [];
            var keyWordsArray = data.Title.toLowerCase().trim().split(' ');
            if (user) {
                db.collection(collectionName).add({
                    Title: data.Title,
                    Url: '',
                    KeyWords: keyWordsArray,
                    dateCreated: new Date(),
                    author: user.displayName,
                    authorId: user.uid,
                })
                    .then(docRef => {
                        // console.log(docRef.id)
                        data.RecordUID = docRef.id;
                        // save attachments if any added save in bucket knowledgeBoxAttachments
                        if (data.filesToStoreInDB.length > 0) {
                            data.filesToStoreInDB.forEach(file => {

                                storage
                                    // .ref('knowledgeBoxAttachments/' + data.RecordUID + '/' + file.name)
                                    .ref(storageRefName + data.RecordUID + '/' + file.name)
                                    .put(file)
                                    .then((snap) => {
                                        snap.ref.getDownloadURL().then(downloadUrl => {
                                            console.log(downloadUrl)
                                            // tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                            db.collection(collectionName).doc(data.RecordUID).update({
                                                Url: downloadUrl,
                                            })
                                        })
                                        resolve('Successfully uploaded new video. Thank you.')
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        reject(err.message)
                                    })
                            })
                        }
                        else {
                            resolve('Successfully created new item. Thank you.')
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    addManagementActionTracker = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('managementActionTrackers').add({
                    IssueDescription: data.IssueDescription === undefined ? '' : data.IssueDescription,
                    RootCause: data.RootCause === undefined ? '' : data.RootCause,
                    Action: data.Action === undefined ? '' : data.Action,
                    Owner: data.Owner === undefined ? '' : data.Owner,
                    RaisedBy: data.RaisedBy === undefined ? '' : data.RaisedBy,
                    UpdateComment: data.UpdateComment === undefined ? '' : data.UpdateComment,
                    DateExpectedCompletion: data.DateExpectedCompletion === undefined ? new Date('01 01 1999') : new Date(data.DateExpectedCompletion),
                    DateRecommit: data.DateRecommit === undefined || data.DateRecommit === null ? null : new Date(data.DateRecommit),
                    Status: 'Open',
                    dateOpen: new Date(),
                    dateUpdated: '',
                    dateClosed: '',
                })
                    .then(docRef => {
                        resolve('Successfully created new record in management action tracker table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateManagementActionTracker = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('managementActionTrackers').doc(data.id).update({
                    IssueDescription: data.IssueDescription === undefined ? '' : data.IssueDescription,
                    RootCause: data.RootCause === undefined ? '' : data.RootCause,
                    Action: data.Action === undefined ? '' : data.Action,
                    Owner: data.Owner === undefined ? '' : data.Owner,
                    RaisedBy: data.RaisedBy === undefined ? '' : data.RaisedBy,
                    UpdateComment: data.UpdateComment === undefined ? '' : data.UpdateComment,
                    DateExpectedCompletion: data.DateExpectedCompletion === undefined ? new Date('01 01 1999') : new Date(data.DateExpectedCompletion),
                    DateRecommit: data.DateRecommit === undefined || data.DateRecommit === null ? null : new Date(data.DateRecommit),
                    Status: data.Status,
                    dateUpdated: new Date(),
                    dateClosed: data.Status === 'Closed' ? new Date() : '',
                })
                    .then(() => {
                        resolve('Successfully updated record in management action tracker table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteManagementActionTracker = (dataId) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('managementActionTrackers').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record from management action tracker table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    uploadPunchcardsData = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var counter = 0;

            if (user) {
                data.forEach(doc => {
                    counter++;
                    var evt = []
                    if (doc.Event.length > 0) {
                        doc.Event.forEach(docEvt => {
                            evt.push({ ClockIn: new Date(docEvt.ClockIn), ClockOut: new Date(docEvt.ClockOut), Hours: parseFloat(docEvt.Hours) })
                        })
                        console.log(evt)
                    }
                    db.collection('punchcardsReports').add({
                        FullName: doc.FullName,
                        BadgeId: parseInt(doc.BadgeId),
                        Department: doc.Department,
                        Shift: doc.Shift,
                        Event: evt,
                        TotalWorkTime: parseFloat(doc.TotalWorkTime),
                        TotalHolidays: parseFloat(doc.TotalHolidays),
                        SickTimeTaken: parseFloat(doc.SickTimeTaken),
                        SickTimeOwing: parseFloat(doc.SickTimeOwing),
                        VacationTimeTaken: parseFloat(doc.VacationTimeTaken),
                        VacationTimeOwing: parseFloat(doc.VacationTimeOwing),
                        year: parseInt(doc.year),
                        week: parseInt(doc.week)

                    })
                })

                if (counter === data.length) {
                    // console.log('Added Records:', counter)
                    resolve('Successfully addded ' + counter + ' records from CSV file to punchcards data table.')
                }
                else {
                    reject('Something went wrong, added ' + counter + ' records of ' + data.length + '.')
                }


                // console.log(err);
                // reject(err.message)

            }
        })
    }

    saveCompletedCourseDetails = (data) => {
        return new Promise((resolve, reject) => {
            db.collection('wiseAcademyCompleted').add({
                Category: data.Category,
                Title: data.Title,
                TraineeFullName: data.TraineeFullName,
                TraineeEmail: data.TraineeEmail,
                TraineeManagerFullName: data.TraineeManagerFullName,
                TraineeManagerEmail: data.TraineeManagerEmail,
                Location: data.Location,
                Site: data.Site,
                Date: new Date()
            })
                .then(docRef => {
                    resolve('Saved completed course details in DB.')
                })
                .catch(err => {
                    reject('There was problem saving completed course details in DB.')
                })
        })
    }
    createCourse = (data) => {
        return new Promise((resolve, reject) => {
            console.log(data)
            db.collection('wiseAcademy').add({
                Id: '',
                Category: data.Category,
                Title: data.Title,
                LogoUrl: '',
                Description: data.Description,
                Status: data.Status,
                dateCreated: new Date(moment.utc()),
                createdBy: data.createdBy,
                chapters: [],
            })
                .then(docRef => {
                    // console.log(docRef.id)
                    data.RecordUID = docRef.id;
                    // save attachments if any added save in bucket ticketAttachments
                    if (data.filesToStoreInDB.length > 0) {
                        data.filesToStoreInDB.forEach(file => {
                            console.log(file)
                            storage
                                .ref('WiseAcademy/CourseLogos/' + data.RecordUID)
                                .put(file)
                                .then((snap) => {
                                    snap.ref.getDownloadURL().then(imgUrl => {
                                        console.log(imgUrl)
                                        // tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                                        db.collection("wiseAcademy").doc(data.RecordUID).update({
                                            Id: data.RecordUID,
                                            LogoUrl: imgUrl
                                        })
                                    })
                                })
                                .catch(err => {
                                    console.log(err);
                                    reject(err.message)
                                })
                        })
                        // this.sendTicketSubmission(data)
                        resolve('New course created successfully. Thank you.')
                    }
                    else {
                        // this.sendTicketSubmission(data)
                        resolve('New course created successfully. Thank you.')
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err.message)
                })
        })
    }
    updateCourse = (data) => {
        return new Promise((resolve, reject) => {
            db.collection('wiseAcademy').doc(data.Id).update({

                Category: data.Category,
                Title: data.Title,
                LogoUrl: data.LogoUrl,
                Description: data.Description,
                Status: data.newStatus,
                chapters: data.chapters ? data.chapters : [],
            })
                .then(() => {
                    resolve('Successfully updated course content. Thank you.')
                }).catch(err => {
                    console.log(err);
                    reject(err.message)
                })
        })
    }
    updateCourseLogo = (data) => {
        return new Promise((resolve, reject) => {
            data.filesToStoreInDB.forEach(file => {
                console.log(file)
                storage
                    .ref('WiseAcademy/CourseLogos/' + data.Id)
                    .put(file)
                    .then((snap) => {
                        snap.ref.getDownloadURL().then(imgUrl => {
                            console.log(imgUrl)
                            // tempFiles.push({ name: file.name, url: imgUrl, type: file.type })
                            db.collection("wiseAcademy").doc(data.Id).update({
                                // Id: data.RecordUID,
                                LogoUrl: imgUrl
                            })
                            resolve(imgUrl)
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            })
        })
    }
    removeCourseLogo = (url) => {
        var logoRef = storage.refFromURL(url);
        logoRef.delete()
            .then(() => {
                console.log("Picture is deleted successfully!");
            })
            .catch((err) => {
                console.log(err);
            });
    }
    saveCourseProgress = (data, userAnswers, correctAnswers) => {
        console.log('data', data, 'userAnswers', userAnswers, 'correctAnswers', correctAnswers)
        return new Promise((resolve, reject) => {
            db.collection('wiseAcademyInProgress').get()
                .then(resp => {
                    var foundInProgressCourse = false
                    var docToUpdate = ''
                    resp.forEach(doc => {
                        // console.log(doc.data().Id)
                        if (doc.data().Id === data.Id && doc.data().TraineeEmail === data.TraineeEmail.toLowerCase()) {
                            foundInProgressCourse = true
                            docToUpdate = doc.id
                        }
                    })
                    // console.log(docToUpdate)
                    if (foundInProgressCourse) {
                        db.collection('wiseAcademyInProgress').doc(docToUpdate).update({
                            Id: data.Id,
                            Category: data.Category,
                            Title: data.Title,
                            LogoUrl: data.LogoUrl,
                            Description: data.Description,
                            chapters: data.chapters,
                            chaptersCompleted: JSON.stringify(data.chaptersCompleted), //nested arrays are not supported in firestore, workaround JSON.stringify
                            TotalProgress: data.TotalProgress,
                            TotalSteps: data.TotalSteps,
                            TotalStepsCompleted: data.TotalStepsCompleted,
                            TraineeFullName: data.TraineeFullName,
                            TraineeEmail: data.TraineeEmail.toLowerCase(),
                            TraineeManagerFullName: data.TraineeManagerFullName,
                            TraineeManagerEmail: data.TraineeManagerEmail,
                            Location: data.Location,
                            Site: data.Site,
                            userAnswers: userAnswers,
                            correctAnswers: correctAnswers,
                            docToRemove: docToUpdate,
                            lastSaved: new Date()
                        })
                            .then(succ => {
                                // console.log('saved progress successfully')
                                resolve()
                            })
                            .catch(err => {
                                console.log(err);
                                reject(err.message)
                            })
                    } else {
                        // console.log('adding new course progress', data)
                        db.collection('wiseAcademyInProgress').add({
                            Id: data.Id,
                            Category: data.Category,
                            Title: data.Title,
                            LogoUrl: data.LogoUrl,
                            Description: data.Description,
                            chapters: data.chapters,
                            chaptersCompleted: JSON.stringify(data.chaptersCompleted), //nested arrays are not supported in firestore, workaround JSON.stringify
                            TotalProgress: data.TotalProgress,
                            TotalSteps: data.TotalSteps,
                            TotalStepsCompleted: data.TotalStepsCompleted,
                            TraineeFullName: data.TraineeFullName,
                            TraineeEmail: data.TraineeEmail.toLowerCase(),
                            TraineeManagerFullName: data.TraineeManagerFullName,
                            TraineeManagerEmail: data.TraineeManagerEmail,
                            Location: data.Location,
                            Site: data.Site,
                            userAnswers: userAnswers,
                            correctAnswers: correctAnswers,
                            docToRemove: docToUpdate,
                            lastSaved: new Date()
                        })
                            .then(succ => {
                                db.collection('wiseAcademyInProgress').doc(succ.id).update({
                                    docToRemove: succ.id
                                })
                                // console.log('saved progress successfully')
                                resolve()
                            })
                            .catch(err => {
                                console.log(err);
                                reject(err.message)
                            })
                    }
                })
                .catch(err => {
                    reject(err.message)
                })
        })
    }
    removeCourseProgress = (removeDocID) => {
        // console.log(removeDocID)
        return new Promise((resolve, reject) => {
            db.collection('wiseAcademyInProgress').doc(removeDocID).delete()
                .then(succ => {
                    // console.log('saved progress has been removed successfully')
                    resolve()
                })
                .catch(err => {
                    console.log(err);
                    reject(err.message)
                })
        })
    }
    searchForCourseInProgress = (data) => {
        return new Promise((resolve, reject) => {
            var res = null
            db.collection('wiseAcademyInProgress').get()
                .then(resp => {
                    var foundInProgressCourse = false
                    resp.forEach(doc => {
                        if (doc.data().Id === data.courseId && doc.data().TraineeEmail === data.traineeEmail.toLowerCase()) {
                            res = doc.data()
                            res.chaptersCompleted = JSON.parse(doc.data().chaptersCompleted)
                            res.inProgress = true
                            foundInProgressCourse = true
                        }
                    })
                    if (foundInProgressCourse) {
                        resolve(res)
                    } else {
                        reject('no data...')
                    }
                })
                .catch(err => {
                    reject(err.message)
                })
        })
    }

    voteForWiseStar = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                // console.log(data)
                db.collection('wiseStars').add({
                    NominatedPerson: data.NominatedPerson,
                    Location: data.Location,
                    NominatedFor: data.NominatedFor,
                    NominatedWhy: data.NominatedWhy,
                    submitedBy: user.displayName,
                    submitedDate: new Date(moment.utc()),
                    quarter: moment.utc().quarter(),
                    year: moment().utc().year()
                })
                    .then(success => {
                        resolve('Successfully voted for this quarter WiseStar. Thank you.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    searchForWiseStar = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                // console.log(data)
                db.collection("wiseStars")
                    .where("year", "==", data.year)
                    .where("quarter", "==", data.quarter).get()
                    .then(snap => {
                        var results = []
                        snap.forEach(doc => {
                            results.push(doc.data())
                        })
                        resolve(results)
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    addToWiseStarsList = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('wiseStarsList').add({
                    fullName: data.fullName,
                })
                    .then(docRef => {
                        resolve('Successfully created new record in WiseStars list of members.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateWiseStarsList = (data) => {
        // console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('wiseStarsList').doc(data.id).update({
                    fullName: data.fullName,
                })
                    .then(() => {
                        resolve('Successfully updated record in WiseStars list of members.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteFromWiseStarsList = (dataId) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('wiseStarsList').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record from WiseStars list of members.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteLeaverAndHolidays = (userId) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection("users").doc(userId).delete()
                    .then(suc => {
                        db.collection('holidayRequests').where('UserId', '==', userId).get()
                            .then(snap => {
                                if (!snap.empty) {
                                    var count = 0
                                    snap.forEach(doc => {
                                        count++
                                        db.collection('holidayRequests').doc(doc.id).delete()
                                    })
                                    resolve(`Successfully deleted user and all ${count} of their holiday requests.`)
                                }
                                else {
                                    resolve('Successfully deleted user, no holiday requests were submitted.')
                                }
                            }).catch(err => {
                                console.log(err);
                                reject('User has been deleted but there was an error deleting holiday requests: ' + err.message)
                            })
                    })
                    .catch(err => {
                        reject("Could't delete user for users DB." + err.message)
                    })
            }
        })
    }
    getMarinaMarketBookings = () => {
        return new Promise((resolve, reject) => {
            db.collection('marinaMarketBooking').orderBy('date').get()
                .then(snap => {
                    var allBookings = []
                    snap.forEach(doc => {
                        var item = doc.data()
                        item.id = doc.id
                        allBookings.push(item)
                    })
                    resolve(allBookings)
                })
                .catch(err => {
                    console.log(err);
                    reject(err.message)
                })
        })
    }

    updateMarinaMarketBooking = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            if (user) {

                if (data.time === 'AM') {
                    db.collection('marinaMarketBooking').doc(data.id).update({

                        teamManagerAM: data.teamManager,
                        // teamManagerPM: data.teamManager,
                        plusOneAM: data.plusOne,
                        // plusOnePM: data.plusOne,
                        // timeAM: data.time,
                        // timePM: data.time,                   
                        isBookedForAM: true,
                        // isBookedForPM:  true,
                    })
                        .then(docRef => {
                            resolve('Successfully booked your spot for Marina Market event.')
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err.message)
                        })

                }
                if (data.time === 'PM') {
                    db.collection('marinaMarketBooking').doc(data.id).update({
                        // teamManagerAM: data.teamManager,
                        teamManagerPM: data.teamManager,
                        // plusOneAM: data.plusOne,
                        plusOnePM: data.plusOne,
                        // timeAM: data.time,
                        // timePM: data.time,
                        // isBookedForAM: true ,
                        isBookedForPM: true,
                    })
                        .then(docRef => {
                            resolve('Successfully booked your spot for Marina Market event.')
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err.message)
                        })
                }
            }
        })
    }

    addInternalCareers = (data) => {
        console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('careersInternal').add({
                    dateCreated: new Date(),
                    Status: data.Status ? data.Status : '',
                    Title: data.Title ? data.Title : '',
                    Description: data.Description ? data.Description : '',
                    UrlLink: data.UrlLink ? data.UrlLink : '',
                    Location: data.Location ? data.Location : '',
                    JobType: data.JobType ? data.JobType : '',
                })
                    .then(docRef => {
                        resolve('Successfully created new record in internal positions table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateInternalCareers = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('careersInternal').doc(data.id).update({
                    dateUpdated: new Date(),
                    Status: data.Status ? data.Status : '',
                    Title: data.Title ? data.Title : '',
                    Description: data.Description ? data.Description : '',
                    UrlLink: data.UrlLink ? data.UrlLink : '',
                    Location: data.Location ? data.Location : '',
                    JobType: data.JobType ? data.JobType : '',
                })
                    .then(() => {
                        resolve('Successfully updated record in internal positions table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteFromInternalCareers = (dataId) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('careersInternal').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record from internal positions table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    addIST27001 = (data) => {
        console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('ist27001').add({
                    Section: data.Section ? data.Section : '',
                    SubSection: data.SubSection ? data.SubSection : '',
                    Question: data.Question ? data.Question : '',
                    Response: data.Response ? data.Response : '',
                    Compliant: data.Compliant ? data.Compliant : '',
                    PendingAction: data.PendingAction ? data.PendingAction : '',
                    dateCreated: new Date(),
                    raisedBy: data.raisedBy ? data.raisedBy : '',
                })
                    .then(docRef => {
                        resolve('Successfully created new record in ISO 27001 table.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    updateIST27001 = (data) => {
        console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('ist27001').doc(data.id).update({
                    Section: data.Section ? data.Section : '',
                    SubSection: data.SubSection ? data.SubSection : '',
                    Question: data.Question ? data.Question : '',
                    Response: data.Response ? data.Response : '',
                    Compliant: data.Compliant ? data.Compliant : '',
                    PendingAction: data.PendingAction ? data.PendingAction : '',
                    dateUpdated: new Date(),
                    updatedBy: data.updatedBy ? data.updatedBy : '',
                })
                    .then(() => {
                        resolve('Successfully updated record in ISO 27001 table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    deleteFromIST27001 = (dataId) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('ist27001').doc(dataId).delete()
                    .then(() => {
                        resolve('Successfully deleted record from ISO 27001 table.')
                    }).catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    submitEmissionsSurvey = (data) => {
        console.log(data)
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                db.collection('ehsEmissionsSurvey').add({
                    FullName: data.FullName ? data.FullName : '',
                    Commuter: data.Commuter ? data.Commuter : '',
                    Vehicle: data.Vehicle ? data.Vehicle : '',
                    MultiVehicle: data.MultiVehicle ? data.MultiVehicle : '',
                    Fuel: data.Fuel ? data.Fuel : '',
                    TotalDistance: data.TotalDistance ? data.TotalDistance : 0,
                    Country: data.Country ? data.Country : '',
                    WorkingTime: data.WorkingTime ? data.WorkingTime : 0,
                    WorkFromHomePercent: data.WorkFromHomePercent ? data.WorkFromHomePercent : 0,
                    dateCreated: new Date(),
                    submittedBy: user.displayName,
                    submittedByEmail: user.email,
                })
                    .then(docRef => {
                        resolve('Successfully submitted ewmissions survey. Thank you.')
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    removePdfAttachmentFromWiseAcademyCourseBucket = (url) => {
        // console.log(url)
        return new Promise((resolve, reject) => {
            var docRef = storage.refFromURL(url);
            docRef.delete()
                .then(() => {
                    resolve('Pdf removed successfully')
                })
                .catch((err) => {
                    reject(`Unable to remove Pdf: Code:${err.code_} Message: ${err.message}`)
                });
        })
    }

    getServerRecoveryData = () => {
        return new Promise((resolve, reject) => {
            db.collection('serverRecovery').orderBy('partNo').get()
                .then(snap => {
                    var allData = []
                    snap.forEach(doc => {
                        var item = doc.data()
                        item.id = doc.id
                        allData.push(item)
                    })
                    resolve(allData)
                })
                .catch(err => {
                    console.log(err);
                    reject(err.message)
                })
        })
    }
    getServerRecoveryByPartNumber = (partNumber) => {
        return new Promise((resolve, reject) => {
            db.collection("serverRecovery").where("partNo", "==", partNumber).get()
                .then(snap => {
                    var allData = []
                    snap.forEach(doc => {
                        var item = doc.data()
                        item.id = doc.id
                        allData.push(item)
                    })
                    resolve(allData)
                })
                .catch(err => {
                    console.log(err);
                    reject(err.message)
                })
        })
    }
    addServerRecoveryRecord = (record) => {
        return new Promise((resolve, reject) => {
            // first save those three files and then add that to db
            var partUrl = ''
            var serverUrl = ''
            var boxUrl = ''
            db.collection('serverRecovery').add({
                boxUrl: record.boxUrl,
                partNo: record.partNo,
                partNoUrl: record.partNoUrl,
                serverUrl: record.serverUrl
            })
                .then(docRef => {
                    storage
                        .ref('serverRecovery/Items/' + docRef.id + '/' + record.partNoImg[0].name)
                        .put(record.partNoImg[0])
                        .then((snap) => {
                            snap.ref.getDownloadURL().then(imgPartUrl => {
                                partUrl = imgPartUrl

                                storage
                                    .ref('serverRecovery/Servers/' + docRef.id + '/' + record.serverImg[0].name)
                                    .put(record.serverImg[0])
                                    .then((snap) => {
                                        snap.ref.getDownloadURL().then(imgServerUrl => {
                                            serverUrl = imgServerUrl

                                            storage
                                                .ref('serverRecovery/Boxes/' + docRef.id + '/' + record.boxImg[0].name)
                                                .put(record.boxImg[0])
                                                .then((snap) => {
                                                    snap.ref.getDownloadURL().then(imgBoxUrl => {
                                                        boxUrl = imgBoxUrl

                                                        db.collection("serverRecovery").doc(docRef.id).update({
                                                            boxUrl: boxUrl,
                                                            partNoUrl: partUrl,
                                                            serverUrl: serverUrl
                                                        })
                                                    }).then(() => {
                                                        resolve('Successfully added new record and all images for that Part Number.')
                                                    }).catch(err => {
                                                        console.log(err);
                                                        reject(err.message)
                                                    })
                                                })
                                                .catch((err) => {
                                                    reject(`Unable to add Box image: Code:${err.code_} Message: ${err.message}`)
                                                })
                                        })

                                    })
                                    .catch((err) => {
                                        reject(`Unable to add Server image: Code:${err.code_} Message: ${err.message}`)
                                    })
                            })

                        })
                        .catch((err) => {
                            reject(`Unable to add Part image: Code:${err.code_} Message: ${err.message}`)
                        })
                })
                .catch(err => {
                    reject(err.message)
                })
        })
    }

    addPartnerCalculatorRecords = (data) => {
        return new Promise((resolve, reject) => {
            var user = auth.currentUser;
            var today = moment.utc();
            var counter = 0;
            var dataLength = data.length

            console.log(dataLength)

            // do write to db only if ALL data rows are correctly filled !!!!
            if (data.length > 0) {
                data.forEach(doc => {
                    counter++;

                    db.collection('partnerCalculator').add({
                        Country: doc.Country.toUpperCase(),
                        Vendor: doc.Vendor,
                        Partner: doc.Partner,
                        TRMA: doc.TRMA.toUpperCase(),
                        Category: doc.Category.toUpperCase(),
                        Make: doc.Make,
                        Model: doc.Model,
                        Serial: doc.Serial,
                        Memory: doc.Memory,
                        Cpu: doc.Cpu,
                        Storage: doc.Storage,
                        Price: Number(parseFloat(doc.Price).toFixed(2)),
                        Cost: Number(parseFloat(doc.Cost).toFixed(2)),
                        Grade: doc.Grade.toUpperCase(),
                        Comment: doc.Comment,
                        insertDate: new Date(today)
                    })
                })
                if (counter === data.length) {
                    // console.log('Added Records:', counter)
                    resolve('Successfully added ' + counter + ' records from CSV file.')
                }
                else {
                    reject('Something went wrong, added ' + counter + ' records of ' + data.length + '.')
                }
            }
            else {
                reject('No rows were added to metrics table.')
            }
        })
    }
    getPartnerCalculatorData = (data) => {
        return new Promise((resolve, reject) => {
            // console.log('coming in data:', data)
            var counter = 0;

            db.collection("partnerCalculator")
                // .where("Country", "==", data.Country).get()
                .where(data.filterColumn, "==", data.filterValue).get()
                // .where("Vendor", "==", data.Vendor).get()
                .then((snap) => {
                    var resArray = []
                    snap.forEach(doc => {
                        // console.log('this is doc data:', doc.data())
                        counter++;
                        resArray.push({ ...doc.data(), id: doc.id, insertDate: moment(doc.data().insertDate.toDate()).toISOString() })
                    })
                    resolve({ result: resArray, message: 'Successfully retrieved data for this filter.' })
                })
                .catch((error) => {
                    reject({ result: [], message: 'Could not retrieved data. Please try again later.' })
                })
        })
    }
    getPartnerCalculatorDateFilterData = (fromDate, toDate) => {
        return new Promise((resolve, reject) => {
            // console.log('coming in data fromDate:', fromDate)
            // console.log('coming in data toDate:', toDate)

            // Set fromDate to the start of the day
            fromDate.setHours(0, 0, 0, 0);
            // Set toDate to the end of the day
            toDate.setHours(23, 59, 59, 999);

            console.log('fromDate', fromDate)
            console.log('toDate', toDate)

            db.collection('partnerCalculator')
                .where('insertDate', '>=', fromDate)
                .where('insertDate', '<=', toDate)
                .get()
                .then((snap) => {
                    // Handle the retrieved data 
                    var resArray = []
                    snap.forEach(doc => {
                        // console.log('this is doc data:', doc.data())
                        // counter++;
                        resArray.push({ ...doc.data(), id: doc.id, insertDate: moment(doc.data().insertDate.toDate()).toISOString() })
                    })
                    resolve({ result: resArray, message: 'Successfully retrieved data for this filter.' })

                })
                .catch((error) => {
                    // Handle errors
                    console.error(error);
                    reject({ result: [], message: 'Could not retrieved data. Please try again later.' })
                })

            // var counter = 0;

            // db.collection("partnerCalculator")
            //     // .where("Country", "==", data.Country).get()
            //     .where(data.filterColumn, "==", data.filterValue).get()
            //     // .where("Vendor", "==", data.Vendor).get()
            //     .then((snap) => {
            //         var resArray = []
            //         snap.forEach(doc => {
            //             // console.log('this is doc data:', doc.data())
            //             counter++;
            //             resArray.push({ ...doc.data(), insertDate: moment(doc.data().insertDate.toDate()).format('DD MM YYYY') })
            //         })
            //         resolve({ result: resArray, message: 'Successfully retrieved data for this filter.' })
            //     })
            //     .catch((error) => {
            //         reject({ result: [], message: 'Could not retrieved data. Please try again later.' })
            //     })
        })
    }

    render() {
        return (
            <DataContext.Provider value={{
                ...this.state,
                getAllUsers: this.getAllUsers,
                updateUser: this.updateUser,
                delay: this.delay,
                createTicket: this.createTicket,
                updateTicket: this.updateTicket,
                createTicketEngineering: this.createTicketEngineering,
                updateTicketEngineering: this.updateTicketEngineering,
                createTicketFacilities: this.createTicketFacilities,
                addItAttachmentToBucketAndTicket: this.addItAttachmentToBucketAndTicket,
                removeItAttachmentFromBucketAndTicket: this.removeItAttachmentFromBucketAndTicket,
                addEngAttachmentToBucketAndTicket: this.addEngAttachmentToBucketAndTicket,
                removeEngAttachmentFromBucketAndTicket: this.removeEngAttachmentFromBucketAndTicket,
                updateTicketFacilities: this.updateTicketFacilities,
                getTRMAdetails: this.getTRMAdetails,
                createTicketCustomerService: this.createTicketCustomerService,
                updateTicketCustomerService: this.updateTicketCustomerService,
                // createTicketIssue: this.createTicketIssue,
                // createTicketChangeRequest: this.createTicketChangeRequest,
                createEngActionTracker: this.createEngActionTracker,
                updateEngActionTracker: this.updateEngActionTracker,
                createISTActionTracker: this.createISTActionTracker,
                updateISTActionTracker: this.updateISTActionTracker,
                createEngDowntimeLog: this.createEngDowntimeLog,
                updateEngDowntimeLog: this.updateEngDowntimeLog,
                createHolidayRequest: this.createHolidayRequest,
                findApplicantAndUpdateHolidays: this.findApplicantAndUpdateHolidays,
                calculateDaysRequested: this.calculateDaysRequested,
                createSoftwareReleaseInfoTracker: this.createSoftwareReleaseInfoTracker,
                updateSoftwareReleaseInfoTracker: this.updateSoftwareReleaseInfoTracker,
                getTableData: this.getTableData,
                createPartnerMatrix: this.createPartnerMatrix,
                updatePartnerMatrixAsAdmin: this.updatePartnerMatrixAsAdmin,
                updatePartnerMatrixAsMember: this.updatePartnerMatrixAsMember,
                knowledgeBoxGetLatest: this.knowledgeBoxGetLatest,
                knowledgeBoxAdd: this.knowledgeBoxAdd,
                addWeeklyTask: this.addWeeklyTask,
                updateWeeklyTask: this.updateWeeklyTask,
                addMetricsCustomerSLAUS: this.addMetricsCustomerSLAUS,
                addRecordsToDatabase: this.addRecordsToDatabase,
                addMetricsChainOfCustodyData: this.addMetricsChainOfCustodyData,
                createHiringRequisition: this.createHiringRequisition,
                updateHiringRequisition: this.updateHiringRequisition,
                addCustomerComplaintsStatus: this.addCustomerComplaintsStatus,
                updateCustomerComplaintsStatus: this.updateCustomerComplaintsStatus,
                deleteCustomerComplaintsStatus: this.deleteCustomerComplaintsStatus,
                addCapaVol: this.addCapaVol,
                updateCapaVol: this.updateCapaVol,
                deleteCapaVol: this.deleteCapaVol,
                addMetricsCustomerSLAIEandUK: this.addMetricsCustomerSLAIEandUK,
                updateMetricsCustomerSLAIEandUK: this.updateMetricsCustomerSLAIEandUK,
                deleteMetricsCustomerSLAIEandUK: this.deleteMetricsCustomerSLAIEandUK,
                addDealIntegrity: this.addDealIntegrity,
                updateDealIntegrity: this.updateDealIntegrity,
                deleteDealIntegrity: this.deleteDealIntegrity,
                addVerificationWip: this.addVerificationWip,
                updateVerificationWip: this.updateVerificationWip,
                deleteVerificationWip: this.deleteVerificationWip,
                addDealsInSales: this.addDealsInSales,
                updateDealsInSales: this.updateDealsInSales,
                deleteDealsInSales: this.deleteDealsInSales,
                addRoomBooking: this.addRoomBooking,
                updateRoomBooking: this.updateRoomBooking,
                deleteRoomBooking: this.deleteRoomBooking,
                addAttachments: this.addAttachments,
                addIncidentLog: this.addIncidentLog,
                updateIncidentLog: this.updateIncidentLog,
                deleteIncidentLog: this.deleteIncidentLog,
                addMemoryScanPartsAndBins: this.addMemoryScanPartsAndBins,
                updateMemoryScanPartsAndBins: this.updateMemoryScanPartsAndBins,
                deleteMemoryScanPartsAndBins: this.deleteMemoryScanPartsAndBins,
                addMemoryScanCheckSheet: this.addMemoryScanCheckSheet,
                updateMemoryScanCheckSheet: this.updateMemoryScanCheckSheet,
                deleteMemoryScanCheckSheet: this.deleteMemoryScanCheckSheet,
                howToVideosAddNew: this.howToVideosAddNew,
                sendBackToOfficeForm: this.sendBackToOfficeForm,
                sendFeedback: this.sendFeedback,
                sendInternalPositionEmail: this.sendInternalPositionEmail,
                // getFactorialEmployees: this.getFactorialEmployees,
                addManagementActionTracker: this.addManagementActionTracker,
                updateManagementActionTracker: this.updateManagementActionTracker,
                deleteManagementActionTracker: this.deleteManagementActionTracker,
                uploadPunchcardsData: this.uploadPunchcardsData,
                saveCompletedCourseDetails: this.saveCompletedCourseDetails,
                createCourse: this.createCourse,
                updateCourse: this.updateCourse,
                updateCourseLogo: this.updateCourseLogo,
                saveCourseProgress: this.saveCourseProgress,
                removeCourseProgress: this.removeCourseProgress,
                searchForCourseInProgress: this.searchForCourseInProgress,
                searchForWiseStar: this.searchForWiseStar,
                voteForWiseStar: this.voteForWiseStar,
                addToWiseStarsList: this.addToWiseStarsList,
                updateWiseStarsList: this.updateWiseStarsList,
                deleteFromWiseStarsList: this.deleteFromWiseStarsList,
                deleteLeaverAndHolidays: this.deleteLeaverAndHolidays,
                getMarinaMarketBookings: this.getMarinaMarketBookings,
                updateMarinaMarketBooking: this.updateMarinaMarketBooking,
                addInternalCareers: this.addInternalCareers,
                updateInternalCareers: this.updateInternalCareers,
                deleteFromInternalCareers: this.deleteFromInternalCareers,
                addIST27001: this.addIST27001,
                updateIST27001: this.updateIST27001,
                deleteFromIST27001: this.deleteFromIST27001,
                submitEmissionsSurvey: this.submitEmissionsSurvey,
                removePdfAttachmentFromWiseAcademyCourseBucket: this.removePdfAttachmentFromWiseAcademyCourseBucket,
                getServerRecoveryData: this.getServerRecoveryData,
                getServerRecoveryByPartNumber: this.getServerRecoveryByPartNumber,
                addServerRecoveryRecord: this.addServerRecoveryRecord,
                addPartnerCalculatorRecords: this.addPartnerCalculatorRecords,
                getPartnerCalculatorData: this.getPartnerCalculatorData,
                getPartnerCalculatorDateFilterData: this.getPartnerCalculatorDateFilterData,
            }}>
                {this.props.children}
            </DataContext.Provider >
        );
    }
}

export default DataContextProvider;