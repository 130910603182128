import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { db, functions, auth } from '../../../config/fbConfig';
import { makeStyles } from '@material-ui/core';
import UsersDetails from './UsersDetails';
import { DataContext } from '../../../contexts/DataContext';
import { useContext } from 'react';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        padding: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    marginOne: {
        margin: theme.spacing(1),
    },
    marginTwo: {
        margin: theme.spacing(2),
    }
}));

export default function Users() {
    const classes = useStyles();

    const { deleteLeaverAndHolidays } = useContext(DataContext)

    const [userToCreate, setUserToCreate] = useState({
        email: 'abc456@noemail.wisetek.net',
        emailVerified: true,
        password: 'Welcome123',
        displayName: 'Operations Members',
        role: 'member',
        firstName: '',
        lastName: '',
        initials: '',
        department: 'Operations',
        availableDays: 0,
        availableDays2022: 0,
        accumulatedApprovedDays: 0,
        accumulatedRemainingDays: 0,
        accumulatedUnpaidLeaveTaken: 0,
        shiftWorker: false,
        location: 'IE',
        userEmail: '',
        roles: ['member']
    })

    const [usersTable, setUsersTable] = useState({
        columns: [],
        data: [],
    });

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function createManagersObject(data) {
        var managers = {}
        data.forEach(manager => {
            managers[manager.value] = manager.label
        })
        // console.log(managers)
        return managers
    }
    function getAllUsers() {
        var users = []
        db.collection('users')
            .orderBy('firstName', 'asc')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    //console.log(doc.data().firstName + ' ' + doc.data().lastName + ' ' + doc.data().role + ' ' + doc.data().userEmail)
                    users.push({
                        ...doc.data(),
                        userId: doc.id
                    });
                })
                //console.log(users)
                setUsersTable({
                    ...usersTable,
                    data: users
                })
            })
            .catch(error => {
                console.log("Error getting users: ", error);
            })
    }
    function getAllManagersAndUsers() {
        var users = []
        var managerList = []
        var managersObj = {}
        db.collection('teamManagers')
            .orderBy("label", "asc").get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    managerList.push({
                        ...doc.data(),
                        teamManagerId: doc.id
                    });
                })
                // console.log(managerList)
                managersObj = createManagersObject(managerList)
                db.collection('users')
                    .orderBy('firstName', 'asc')
                    .get()
                    .then(snapshot => {
                        snapshot.forEach(doc => {
                            //console.log(doc.data().firstName + ' ' + doc.data().lastName + ' ' + doc.data().role + ' ' + doc.data().userEmail)
                            users.push({
                                ...doc.data(),
                                userId: doc.id
                            });
                        })
                        //console.log(users)
                        setUsersTable({
                            ...usersTable,
                            columns: [
                                { title: 'Name', field: 'firstName' },
                                { title: 'Surname', field: 'lastName' },
                                {
                                    title: 'Department', field: 'department', lookup: {
                                        'CEO': 'CEO',
                                        'CS/Logistics/Purchasing': 'CS/Logistics/Purchasing',
                                        'Customer Service': 'Customer Service',
                                        'Engineering': 'Engineering',
                                        'Finance': 'Finance',
                                        'HR': 'HR',
                                        'IS&T': 'IS&T',
                                        'Marketing': 'Marketing',
                                        'Operations': 'Operations',
                                        'Quality and Compliance': 'Quality and Compliance',
                                        'Sales': 'Sales',
                                    }
                                },
                                {
                                    title: 'Roles', field: 'roles',
                                    render: rowData => rowData.roles.join()
                                    // lookup: {
                                    //     'admin': 'admin',

                                    // 'admin',
                                    // 'adminAM',
                                    // 'adminCS',
                                    // 'adminENG',
                                    // 'adminFacilities',
                                    // 'adminHR',
                                    // 'adminIS&T',
                                    // 'adminSalesOps',
                                    // 'member',
                                    // 'memberAM',
                                    // 'memberCS',
                                    // 'memberENG',
                                    // 'memberFacilities',
                                    // 'memberHR',
                                    // 'memberIS&T',
                                    // 'memberSalesOps',
                                    // 'teamManager',
                                    // 'viewAcc',
                                    // 'viewAccAcademyExternal',
                                    // 'wiseAcademyEditor'
                                    // },
                                },
                                {
                                    title: 'Location', field: 'location', lookup: {
                                        'IE': 'IE',
                                        'UK': 'UK',
                                        'US': 'US',
                                    }
                                },
                                { title: 'Holiday Approving Manager', field: 'holidayApprovingManager', lookup: managersObj },
                                { title: 'Shift Worker', field: 'shiftWorker', lookup: { 'true': 'Yes', 'false': 'No' } },
                                { title: 'Email or Username', field: 'userEmail' },
                                // { title: 'Annual leave days Entitled', field: 'availableDays', type: 'numeric' },
                                // { title: 'Annual leave days Used', field: 'accumulatedApprovedDays', type: 'numeric' },
                                // { title: 'Annual leave days Remaining', field: 'accumulatedRemainingDays', type: 'numeric' },
                                // { title: 'Unpaid Leave Taken', field: 'accumulatedUnpaidLeaveTaken', type: 'numeric' },
                                { title: 'UID', field: 'userId', editable: 'never' },

                            ],
                            data: users
                        })
                    })
                    .catch(error => {
                        console.log("Error getting users: ", error);
                    })
            })
            .catch(error => {
                console.log("Error getting managers: ", error);
            });
    }

    useEffect(() => {
        getAllManagersAndUsers()
        return () => { }
    }, []);

    const deleteUser = (userToDelete) => {
        const callable = functions.httpsCallable('deleteUser');
        console.log('deleting user ' + userToDelete);

        return callable(userToDelete, auth)
            .then(success => {
                // console.log(success)
                deleteLeaverAndHolidays(userToDelete)
                    .then(suc => {
                        //display success
                        setSnackbar({
                            open: true,
                            severity: 'success',
                            message: suc,
                        })
                    })
                    .catch(err => {
                        //display error
                        setSnackbar({
                            open: true,
                            severity: 'error',
                            message: err,
                        })
                    })
            })
            .catch(error => {
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error.message,
                })
            })
    }
    function createUser() {
        const callable = functions.httpsCallable('createUserAsAdmin');
        // console.log('NEW VERSION: 002');
        //console.log(holidayRequestForm.FromDateString);
        //console.log(holidayRequestForm.ToDateString);
        // return callable(holidayRequestForm, auth).then(console.log)
        return callable(userToCreate, auth)
            .then(
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Created user as admin.',
                })
            )

    }
    function updateEachUser() {
        db.collection('users').get()
            .then(snap => {
                snap.forEach(doc => {
                    var location = ''
                    var userData = doc.data()
                    // console.log(userData)
                    console.log(doc.id)
                    var email = userData.userEmail.toLowerCase()
                    if (email.includes('@wisetek.net')) {
                        location = 'IE'
                    }
                    if (email.includes('@wisetek.co.uk')) {
                        location = 'UK'
                    }
                    if (email.includes('@wisetekusa.com')) {
                        location = 'US'
                    }

                    db.collection('users').doc(doc.id).update({
                        location: location
                    })
                })
            })
    }

    return (
        <div>
            <MaterialTable
                title={"Registered Employees"}
                columns={usersTable.columns}
                data={usersTable.data}
                detailPanel={rowData => {
                    // console.log(rowData)
                    return (
                        <UsersDetails userData={rowData} refresh={getAllUsers} setSnackbar={setSnackbar} />
                    )
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                // options={{ detailPanelType: "single", actionsColumnIndex: -1 }}
                options={{ detailPanelType: "single", exportButton: true, filtering: true, pageSize: 15, pageSizeOptions: [5, 15, 50, { value: usersTable.data.length, label: 'All' }] }}//paging: true, pageSize: 100, pageSizeOptions: [5, 10, 100] 
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getAllUsers()
                            return () => { }
                        },
                    }
                ]}
                editable={{
                    // onRowAdd: newData =>
                    //     new Promise(resolve => {
                    //         setTimeout(() => {
                    //             resolve();
                    //             setUsersTable(prevState => {
                    //                 const data = [...prevState.data];
                    //                 data.push(newData);
                    //                 return { ...prevState, data };
                    //             });
                    //         }, 600);
                    //     }),
                    // onRowUpdate: (newData, oldData) =>
                    //     new Promise(resolve => {
                    //         setTimeout(() => {
                    //             if (oldData) {
                    //                 setUsersTable(prevState => {
                    //                     const data = [...prevState.data];
                    //                     data[data.indexOf(oldData)] = newData;
                    //                     return { ...prevState, data };
                    //                 });
                    //             }
                    //         }, 600);
                    //         // Update data
                    //         console.log(newData)
                    //         db.collection("users").doc(newData.userId).update({
                    //             firstName: newData.firstName,
                    //             lastName: newData.lastName,
                    //             department: newData.department,
                    //             role: newData.role,
                    //             availableDays: newData.availableDays,
                    //             holidayApprovingManager: newData.holidayApprovingManager,
                    //             shiftWorker: newData.shiftWorker === 'true' ? true : false,
                    //             userEmail: newData.userEmail,
                    //             accumulatedUnpaidLeaveTaken: newData.accumulatedUnpaidLeaveTaken,
                    //             accumulatedRemainingDays: newData.accumulatedRemainingDays,
                    //             accumulatedApprovedDays: newData.accumulatedApprovedDays,
                    //             location: newData.location
                    //         });
                    //         resolve();
                    //     }),
                    onRowDelete: oldData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                setUsersTable(prevState => {
                                    const data = [...prevState.data];
                                    data.splice(data.indexOf(oldData), 1);
                                    return { ...prevState, data };
                                });
                            }, 600);
                            // delete user
                            var userToDelete = oldData.userId

                            deleteUser(userToDelete)

                            resolve();
                        }),
                }}
            />


            {/* <br />
            <Button className={classes.marginBottom}
                onClick={createUser}
                variant="contained"
                color="default"
                component="span">
                Add User
            </Button>
            <br />
            <Button className={classes.marginBottom}
                onClick={updateEachUser}
                variant="contained"
                color="default"
                component="span">
                Update Each User
            </Button> */}

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    );
}
