import React from 'react';
import { Button, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import LeftIcon from '@material-ui/icons/ArrowBackIosRounded';
import RightIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Loading from '../layout/Loading';
import { storage } from '../../config/fbConfig';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '90%',
        borderRadius: 10,
    },
    mediaPoster: {
        width: '50%',
        borderRadius: 20,
    },
    media: {
        width: '100%',
        borderRadius: 10,
    },
    thumbnail: {
        width: 100,
    },
    seventyWidth: {
        width: '70%',
    },
    fifteenWidth: {
        width: '15%',
    }
}));

const downloadImg = async (e, fileURL) => {
    // console.log('downloading', e.target)
    e.preventDefault();
    const fileName = /[^/]*$/.exec(fileURL)[0];
    // console.log(fileName)
    const response = await fetch(fileURL);
    if (response.status === 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        // link.href = url;
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
        return { success: true };
    }
}
// all needed props
// var storageRefString = 'NewsAndAnnouncements/ehsWeek/'
// var headerText='Here are some photos from that week...'

export default function ImageDisplayCarousel(props) {
    const classes = useStyles();

    const [currentImage, setCurrentImage] = useState(-1)

    const [imageURLs, setImageURLs] = useState([]);

    function getAllImages() {
        if (props.getDataNow) {
            console.log('get images data now..', props.getDataNow)

            var storageRef = storage.ref(props.storageRefString)
            var allURLs = []
            var counter = 0

            storageRef.listAll()
                .then(result => {
                    result.items.forEach(imageRef => {
                        imageRef.getDownloadURL()
                            .then(url => {
                                // console.log(url)
                                allURLs.push(url)
                                counter++
                                if (result.items.length === counter) {
                                    // console.log(result.items.length, ' of ', counter)
                                    // console.log('there are ', result.items.length, ' pictures to display', allURLs)
                                    console.log('there are ', result.items.length, ' pictures to display')
                                    setImageURLs(allURLs)
                                    setCurrentImage(0)
                                }
                            })
                    })
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }
    useEffect(() => {
        // console.log('loop!')
        getAllImages()
        return () => { }
    }, [props.getDataNow])


    function goRight() {
        // console.log(currentImage + ' of ' + (imageURLs.data.length - 1))
        if (currentImage < imageURLs.length - 1) {
            setCurrentImage(currentImage + 1)
        } else {
            setCurrentImage(imageURLs.length - 1)
        }
    }
    function goLeft() {
        // console.log(currentImage + ' of ' + (imageURLs.data.length - 1))
        if (currentImage > 0) {
            setCurrentImage(currentImage - 1)
        } else {
            setCurrentImage(0)
        }
    }

    function switchImage() {
        // console.log('switching image')
        if (currentImage < imageURLs.length - 1) {
            setCurrentImage(currentImage + 1)
        } else {
            setCurrentImage(0)
        }
    }

    useEffect(() => {
        const intervalId = setInterval(function () {
            switchImage()
        }, 5000)
        return () => clearInterval(intervalId)
    }, [currentImage])

    return (
        <>

            <br /><br />
            <div className={classes.textCentered}>
                <h3>{props.headerText}</h3>
                {currentImage < 0 ?
                    <Loading /> :
                    <div>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {currentImage === 0 ? <div className={classes.fifteenWidth}></div> :
                                <div className={classes.fifteenWidth}>
                                    <Tooltip title="Previous" aria-label="Previous" onClick={goLeft}>
                                        <IconButton aria-label="go Left" >
                                            <LeftIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>}
                            <div className={classes.seventyWidth}>
                                <Tooltip title="Download Image" aria-label="Download Image">
                                    <Button onClick={e => downloadImg(e, imageURLs[currentImage])}>
                                        <img className={classes.media}
                                            src={imageURLs[currentImage]}
                                            alt={imageURLs[currentImage]}
                                        />
                                    </Button>
                                </Tooltip>
                            </div>
                            {currentImage === imageURLs.length - 1 ? <div className={classes.fifteenWidth}></div> :
                                <div className={classes.fifteenWidth}>
                                    <Tooltip title="Next" aria-label="Next" onClick={goRight}>
                                        <IconButton aria-label="go Right" >
                                            <RightIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>}
                        </Grid>
                        {(currentImage + 1) + ' of ' + imageURLs.length}
                        {/* {(currentImage + 1) + ' of ' + currentStorageSize} */}
                    </div>
                    // <div>
                    //     <Grid
                    //         container
                    //         direction="row"
                    //         justifyContent="center"
                    //         alignItems="center"
                    //     >
                    //         {currentImage === 0 ? <div className={classes.fifteenWidth}></div> :
                    //             <div className={classes.fifteenWidth}>
                    //                 <Tooltip title="Previous" aria-label="Previous" onClick={goLeft}>
                    //                     <IconButton aria-label="go Left" >
                    //                         <LeftIcon fontSize="large" />
                    //                     </IconButton>
                    //                 </Tooltip>
                    //             </div>}
                    //         <div className={classes.seventyWidth}>
                    //             <Tooltip title="Download Image" aria-label="Download Image">
                    //                 <Button onClick={e => downloadImg(e, imageURLs.data[currentImage])}>
                    //                     <img className={classes.media}
                    //                         src={imageURLs.data[currentImage]}
                    //                         alt={imageURLs.data[currentImage]}
                    //                     />
                    //                 </Button>
                    //             </Tooltip>
                    //         </div>
                    //         {currentImage === imageURLs.data.length - 1 ? <div className={classes.fifteenWidth}></div> :
                    //             <div className={classes.fifteenWidth}>
                    //                 <Tooltip title="Next" aria-label="Next" onClick={goRight}>
                    //                     <IconButton aria-label="go Right" >
                    //                         <RightIcon fontSize="large" />
                    //                     </IconButton>
                    //                 </Tooltip>
                    //             </div>}
                    //     </Grid>
                    //     {(currentImage + 1) + ' of ' + imageURLs.data.length}
                    // </div>
                }
            </div>
            <br /><br />
        </>
    )
}