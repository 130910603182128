import { Container, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react'
import Loading from './Loading';
import { Grid } from '@material-ui/core';
import { AuthContext } from '../../contexts/AuthContext';
import underConstruction from '../../images/UnderConstruction.gif'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f0f5f4',
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
    },
}));

export default function UnderConstruction(props) {
    const classes = useStyles();

    const { loading } = useContext(AuthContext);

    if (loading) {
        //empty div
        return (
            <Loading />
        )
    }
    else {
        return (
            <div className={classes.root}>
                <Container component="main" className={classes.main} maxWidth="xl">

                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                        <h4>sorry, this section is still in development...</h4>
                        <img className={classes.media} src={underConstruction} alt="Under Construction" />
                    </Grid>

                </Container>
            </div>
        )
    }
}