import React from 'react';
import { makeStyles } from '@material-ui/core';
import ImageDisplayCarousel from '../../../customComponents/ImageDisplayCarousel';


const useStyles = makeStyles(theme => ({
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
}));

export default function BBQ2022(props) {
    const classes = useStyles();

    return (
        <div className={classes.mainDiv}>

            <p>Dear Colleagues,</p>

            <p>Our annual Summer BBQ was held on the 11th of June in Flannery's pub. We had a great evening catching up with colleagues outside the workplace and enjoying some delicious food and drinks!</p>

            <p>Thank you to all that attended and made the evening a success.</p>

            <ImageDisplayCarousel storageRefString='NewsAndAnnouncements/2022BBQ/' headerText='Here are some photos of the night...' getDataNow={props.getDataNow} />

        </div>
    )
}