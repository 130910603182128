import { makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import Loading from '../Loading';
import SideMenuV2 from '../SideMenus/SideMenuV2';
import KnowledgeBox from '../IS&T/KnowledgeBox/KnowledgeBox';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import Footer from '../../Footer';
import BAscan from '../Production/BAscan/BAscan';
import BarcodeScan from '../Production/BAscan/BarcodeScan';
import DisplayTRMAs from './DisplayTRMAs';


const useStyles = makeStyles(theme => ({
    root: {
        // width: `calc(100% - ${drawerWidth}px)`,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        // maxWidth: `calc(100% - ${drawerWidth}px)`,
        // backgroundColor: '#f0f5f4',
        margin: theme.spacing(2),
    },
}));


const TestingConsole = (props) => {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });


    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('adminISandT') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <SideMenuV2 selectedIndex={46} />
                        <div className={classes.main}>
                            {/* <h6>testing</h6> */}
                            {/* <KnowledgeBox /> */}
                            {/* <BAscan /> */}
                            {/* <BarcodeScan /> */}
                            <DisplayTRMAs />

                        </div>

                        <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                        <Footer />
                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}
export default TestingConsole