import React from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import women from '../../../../images/women2021.jpg'
import women21first from '../../../../images/women21first.jpg'
import women21second from '../../../../images/women21second.jpg'
import women21third from '../../../../images/women21third.jpg'
import women21fourth from '../../../../images/women21fourth.jpg'
import women21fifth from '../../../../images/women21fifth.jpg'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '70%',
        borderRadius: 20,
    },
    paper: {
        maxWidth: '20%',
    },
    paperIMG: {
        maxWidth: '100%'
    },

}));

export default function WomensDay2021() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                <h2 className={classes.textCentered}>International Women's Day 2021 campaign theme is #ChooseToChallenge</h2>

            </header>
            <div className={classes.textCentered}>
                <img className={classes.media} src={women} alt="women" />
            </div>


            <h4 className={classes.textCentered}>A challenged world is an alert world. Individually, we're all responsible for our own thoughts and actions - all day, every day.
                We can all choose to challenge and call out gender bias and inequality. We can all choose to seek out and celebrate women's achievements. Collectively, we can all help create an inclusive world. From challenge comes change, so let's all choose to challenge.
            </h4>
            <br />


            <article >
                <h5 >Dear Colleagues,</h5>
                <section>
                    Monday the 8th of March is International Women’s Day and we invite you all to take part in the Choose To Challenge pose (Male and Female Colleagues) and share your photo with a member of the HR or Marketing Team before close of business this Friday the 5th.

                    The purpose of the hand up pose 🙋 is to show your commitment to choose to challenge inequality, call out bias, question stereotypes, and help forge an inclusive world. Please feel free to send in individual photos 🤳, a photo with your kids/other family members or take a hand up pose photo with a fellow staff member socially distanced/wearing a mask🧍‍♀‍ – 🧍.

                </section>
                <br />


            </article>

            <h4 className={classes.textCentered}>
                We have added some nice examples of the hand up pose from the International Women’s Day website to provide you with some inspiration for your own photo.
            </h4>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item className={classes.paper}>
                    <img className={classes.paperIMG} src={women21first} alt="womenFirst" />
                </Grid>
                <Grid item className={classes.paper}>
                    <img className={classes.paperIMG} src={women21second} alt="womenSecond" />
                </Grid>
                <Grid item className={classes.paper}>
                    <img className={classes.paperIMG} src={women21third} alt="womenThird" />
                </Grid>
                <Grid item className={classes.paper}>
                    <img className={classes.paperIMG} src={women21fourth} alt="womenFourth" />
                </Grid>
                <Grid item className={classes.paper}>
                    <img className={classes.paperIMG} src={women21fifth} alt="womenFifth" />
                </Grid>
            </Grid>

            <h2 className={classes.textCentered}>

                Stay tuned for other activities and interest pieces to mark Women’s International Day.
            </h2>

            <br />
            <p>Regards,<br /><br />

                Geni D’Arcy and Eloise Tobler<br />
                {/* Global Head of HR<br />
            Wisetek™<br /> */}
            </p>
            <br />

        </div>
    )
}