import React, { useState, useContext, useEffect } from 'react'
import { db } from '../../../config/fbConfig';
import { AuthContext } from '../../../contexts/AuthContext';
import { Card, makeStyles, CssBaseline } from '@material-ui/core'
import MaterialTable from 'material-table';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    leftMargin: {
        marginLeft: '30px',
    },
}));

export const HolidayRequestApplicant = (props) => {
    const classes = useStyles();

    const { uid, displayName } = useContext(AuthContext);

    const [userRequests, setUserRequests] = useState({
        columns: [
            { title: 'Days Req', field: 'TotalDaysRequested', editable: 'never' },
            { title: 'Hours Req', field: 'HoursRequested', editable: 'never' },
            { title: 'From Date ', field: 'FromDate', editable: 'never' },
            { title: 'To Date ', field: 'ToDate', editable: 'never' },
            { title: 'Status', field: 'Status', editable: 'never' },
            { title: 'Manager', field: 'Manager', editable: 'never' },
            { title: 'Requested On Date', field: 'DateOfRequest', editable: 'never' },
            { title: 'Department', field: 'Department', editable: 'never' },
            { title: 'Your Comment', field: 'AdditionalComment', editable: 'never' },
            { title: 'Manager Comment', field: 'ResponseComment', editable: 'never' },
            { title: 'Shift Worker', field: 'ShiftWorker', editable: 'never' },
            { title: 'Unpaid Leave', field: 'UnpaidLeave', editable: 'never' },
        ],
        data: [],
    });
    const [currentYear, setCurrentYear] = useState({
        available: 0,
        approved: 0
    });

    function calculateCurrentYear(requests) {
        var days = 0
        var hours = 0
        var currentYearApprovedDays = 0
        var currentYearRemainingDays = 0

        requests.forEach(userRequest => {

            if (uid === userRequest.UserId && userRequest.Status === 'Approved') {
                var currentYearStart = moment().startOf('year')
                var currentYearEnd = moment().endOf('year')
                // console.log('START', currentYearStart, 'END', currentYearEnd)
                if (moment(userRequest.FromDate).isBetween(currentYearStart, currentYearEnd)) {
                    days += userRequest.TotalDaysRequested
                    hours += userRequest.HoursRequested
                }
                else {
                    if (moment(userRequest.ToDate).isBetween(currentYearStart, currentYearEnd)) {
                        var partOfHolidaysDays = moment(userRequest.ToDate).diff(currentYearStart, 'days') + 1// including 1st Jan
                        // console.log(partOfHolidaysDays)

                        for (let i = 0; i < partOfHolidaysDays; i++) {
                            var startDate = moment().startOf('year')
                            startDate.add(i, 'days')
                            // console.log(startDate.isoWeekday(), startDate.format('DD MMMM YYYY'))

                            if (startDate.isoWeekday() === 6 || startDate.isoWeekday() === 7 || startDate.isSame(moment().startOf('year'))) {
                                partOfHolidaysDays -= 1
                            }

                        }
                        days += partOfHolidaysDays
                        // console.log('started before new year:', userRequest)
                        // console.log(userEntitlement.firstName + ' ' + userEntitlement.lastName, 'had', days, 'days in', moment().year())
                    }
                }
            }
        })
        db.collection('users').doc(uid).get()
            .then(resp => {
                // console.log(resp.data().availableDays)
                currentYearApprovedDays = parseFloat((days + (hours / 8)).toFixed(2))
                currentYearRemainingDays = parseFloat((resp.data().availableDays - currentYearApprovedDays).toFixed(2))
                setCurrentYear({
                    ...currentYear,
                    approved: currentYearApprovedDays,
                    available: currentYearRemainingDays
                })
            })
            .catch(function (error) {
                console.log("Error getting user data: ", error);
            });
    }


    function getHolidays() {
        var allUserRequests = [];
        db.collection('holidayRequests')
            .where("UserId", "==", uid)
            .orderBy("DateOfRequest", "desc")
            .get()
            // db.collection('holidayRequests').where("FullName", "==", "Chris O'Neill").get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    allUserRequests.push({
                        ...doc.data(),
                        FromDate: moment(doc.data().FromDate.toDate()).format('DD MMMM YYYY'),
                        ToDate: moment(doc.data().ToDate.toDate()).format('DD MMMM YYYY'),
                        DateOfRequest: moment(doc.data().DateOfRequest.toDate()).format('DD MMMM YYYY'),
                    });
                })
                calculateCurrentYear(allUserRequests)

                setUserRequests({
                    ...userRequests,
                    data: allUserRequests
                })
                // console.log(allUserRequests)
            })
            .catch(function (error) {
                console.log("Error getting users holidays data: ", error);
            });
    }

    useEffect(() => {
        getHolidays()
        return () => { }
    }, []);

    return (
        <div>
            <CssBaseline >
                {/* <h1>My Holiday Requests</h1> */}
                <Card>
                    <p className={classes.leftMargin}>Hello {displayName} here you can view all your holiday requests.</p>
                    <p className={classes.leftMargin}>Approved holidays: {currentYear.approved} days for this year. </p>
                    {/* <p className={classes.leftMargin}>Remaining holidays: {currentYear.available} days for this year. </p> */}
                    <MaterialTable
                        title={"My Requests"}
                        //tableRef={this.tableRef}
                        columns={userRequests.columns}
                        data={userRequests.data}
                        options={{ exportButton: true, filtering: true }}
                        actions={[
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    getHolidays()
                                    return () => { }
                                },

                            }
                        ]}
                    // components={{
                    //     Action: props => (
                    //         <SendIcon
                    //             onClick={(event, rowData) => {

                    //                 // Do save operation
                    //                 console.log('send email reminder to manager')
                    //             }
                    //             }
                    //             // props.action.onClick(event, props.data)}
                    //             // color="primary"
                    //             // variant="contained"
                    //             // style={{textTransform: 'none'}}
                    //             size="small"
                    //         >
                    //             My Button
                    //         </SendIcon>
                    //     ),
                    // }}

                    // editable={[
                    //     {
                    // icon: SendIcon,
                    // tooltip: 'Nudge manager',
                    // onClick: (event, rowData) => {
                    //     // Do save operation
                    //     console.log('send email reminder to manager')
                    // }

                    //     }
                    // ]}
                    />
                </Card>
            </CssBaseline>

        </div>
    )
}