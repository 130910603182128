import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { LeafPoll } from 'react-leaf-polls-copy'
import 'react-leaf-polls-copy/dist/index.css'
import { db } from '../../../../config/fbConfig';
import { AuthContext } from '../../../../contexts/AuthContext';
import movember2022one from '../../../../images/Movember2022One.jpg'
import movember2022two from '../../../../images/Movember2022Two.png'
import movember2022three from '../../../../images/Movember2022Three.jpg'
import movember2022four from '../../../../images/Movember2022Four.jpg'
import wanted from '../../../../images/Wanted_word.png'
import Loading from '../../Loading';


const useStyles = makeStyles(theme => ({
    main: {
        margin: '30px 10% 30px 10%',
        width: '100%'
    },
    textCenter: {
        textAlign: 'center'
    },
    media: {
        width: '200px',
        height: '270px',
        borderRadius: 10,
        margin: '30px 30px 0px 30px'
    },
    mediaWanted: {
        height: '100px',
    },

}));

export default function Movember2022Vote() {
    const classes = useStyles();
    const { uid } = useContext(AuthContext);
    const [loading, setLoading] = useState(true)

    // Persistent data array (typically fetched from the server)
    const [candidates, setCandidates] = useState({
        isVoted: false,
        isVotedId: -1,
        data: [
            { id: 0, text: 'John-Paul Collins', votes: 0 },
            { id: 1, text: 'Robert Daly', votes: 0 },
            { id: 2, text: 'Pawel Gajewski', votes: 0 },
            { id: 3, text: 'Subhash Nalamati', votes: 0 }
        ]
    })

    const [votes, setVotes] = useState([])

    // Object keys may vary on the poll type (see the 'Theme options' table below)
    const customTheme = {
        isVoted: true,
        textColor: 'black',
        mainColor: '#00B87B',
        leftColor: 'rgb(79, 185, 227)',//blue
        // leftColor: 'rgb(100, 200, 50)',//green
        rightColor: 'rgb(250, 30, 30)',//red
        backgroundColor: 'rgb(255,255,255)',
        alignment: 'center',
        margin: 10
    }

    useEffect(() => {
        getVotes()
        getCandidates()
        return () => { }
    }, [])

    const getCandidates = () => {
        db.collection('movember2022votes').doc('JS4gq3yAY4wapkHacJMT').get()
            .then(doc => {
                var allCandidates = doc.data().candidates
                // console.log(allCandidates)
                db.collection('movember2022voted').doc('rWPhjb4jdjvcKlRQxw6h').get()
                    .then(doc => {

                        var allVotes = doc.data().votes
                        var voted = false
                        var objId = -1
                        allVotes.forEach(obj => {
                            if (obj.id === uid) {
                                // console.log(obj)
                                // console.log('isVoted should be true and this is the ibject ID ', obj.isVotedId)
                                voted = true
                                objId = obj.isVotedId
                            }
                        })
                        // console.log(voted, objId)
                        setCandidates({
                            ...candidates,
                            data: allCandidates,
                            isVoted: voted,
                            isVotedId: objId
                        })
                        setLoading(false)
                    })
            })
    }
    const getVotes = () => {
        db.collection('movember2022voted').doc('rWPhjb4jdjvcKlRQxw6h').get()
            .then(doc => {
                var allVotes = doc.data().votes
                setVotes(allVotes)
            })
    }

    function vote(Result, Results) {
        // Here you probably want to manage
        // and return the modified data to the server.
        db.collection('movember2022votes').doc('JS4gq3yAY4wapkHacJMT').update({
            candidates: Results
        })
        var newAllVotes = []
        newAllVotes = votes
        // console.log('votes', votes)
        // console.log('newAllVotes', newAllVotes)
        newAllVotes.push({
            id: uid,
            isVotedId: Result.id
        })
        db.collection('movember2022voted').doc('rWPhjb4jdjvcKlRQxw6h').update({
            votes: newAllVotes
        })
    }

    return (
        <div className={classes.main}>
            <div className={classes.textCenter}>
                <img className={classes.mediaWanted} src={wanted} alt="wanted" />
            </div>
            <br /><br />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <img className={classes.media} src={movember2022one} alt="Candidate 1" />
                    <div className={classes.textCenter}><h4>John-Paul Collins</h4></div>
                </Grid>
                <Grid item>
                    <img className={classes.media} src={movember2022two} alt="Candidate 2" />
                    <div className={classes.textCenter}><h4>Rober Daly</h4></div>
                </Grid>
                <Grid item>
                    <img className={classes.media} src={movember2022three} alt="Candidate 3" />
                    <div className={classes.textCenter}><h4>Pawel Gajewski</h4></div>
                </Grid>
                <Grid item>
                    <img className={classes.media} src={movember2022four} alt="Candidate 4" />
                    <div className={classes.textCenter}><h4>Subhash Nalamati</h4></div>
                </Grid>

            </Grid>

            {loading ? <div className={classes.textCenter}><Loading /></div> :
                <div>
                    <br /><br /><br />
                    <p>Hello All,</p>
                    <p>It's time to vote for <b>"THE BEST"</b> moustache grower. But remember you have <b>only one vote</b>, so please make sure to take your time on this one 😉</p>
                    <p>The candidate with the least amount of votes will automatically be nominated to <b>"THE WORSE"</b> moustache category.</p>
                    <p>Get your votes in by the end of the day on <b>Monday 12th</b> December.</p>
                    <p>Winners will be announced by <b>Tuesday 13th</b> December.</p>
                    <br /><br /><br />
                    <LeafPoll
                        type='multiple'
                        question='Who grows the best "Movember Moustache"?'
                        isVoted={candidates.isVoted}
                        isVotedId={candidates.isVotedId}
                        results={candidates.data}
                        theme={customTheme}
                        onVote={vote}
                    />
                </div>
            }
        </div>
    )
}
