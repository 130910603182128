import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { db } from '../../../../config/fbConfig';
import moment from 'moment';
import TicketHRDetails from './TicketHRDetails';

export default function TicketsHR() {

    const [recruitmentRequisitions, setRecruitmentRequisitions] = useState({
        columns: [

            // { title: 'Budget Range', field: 'BudgetRange', editable: 'never', hidden: true, export: true },
            { title: 'Comments Notes', field: 'CommentsNotes', editable: 'never', hidden: true, export: true },
            { title: 'FTC Contract Duration', field: 'ContractTypeDurationOfFTC', editable: 'never', hidden: true, export: true },
            { title: 'FTC Contract', field: 'ContractTypeFTC', editable: 'never', hidden: true, export: true },
            { title: 'Permanent Contract', field: 'ContractTypePermanent', editable: 'never', hidden: true, export: true },
            { title: 'JD Attached', field: 'CopyOfJdAttachedYes', editable: 'never', hidden: true, export: true },
            { title: 'First Approval', field: 'FirstLevelApproval', editable: 'never', hidden: true, export: true },
            { title: 'First Approval Email', field: 'FirstLevelApprovalEmail', editable: 'never', hidden: true, export: true },
            { title: 'Hiring Manager Email', field: 'HiringManagerEmail', editable: 'never', hidden: true, export: true },
            { title: 'Details', field: 'PleaseProvideDetails', editable: 'never', hidden: true, export: true },
            { title: 'Advertised Both', field: 'RoleToBeAdvertisedBoth', editable: 'never', hidden: true, export: true },
            { title: 'Advertised External', field: 'RoleToBeAdvertisedExternal', editable: 'never', hidden: true, export: true },
            { title: 'Advertised Internal', field: 'RoleToBeAdvertisedInternal', editable: 'never', hidden: true, export: true },
            { title: 'New Role', field: 'RoleTypeNew', editable: 'never', hidden: true, export: true },
            { title: 'Replacement', field: 'RoleTypeReplacement', editable: 'never', hidden: true, export: true },
            { title: 'Second Approval', field: 'SecondLevelApproval', editable: 'never', hidden: true, export: true },
            { title: 'Second Approval Email', field: 'SecondLevelApprovalEmail', editable: 'never', hidden: true, export: true },
            { title: 'Date Created', field: 'dateCreated', editable: 'never' },
            { title: 'Status', field: 'status', editable: 'never' },
            { title: 'Job Title', field: 'JobTitle', editable: 'never' },
            { title: 'Hiring Manager', field: 'HiringManager', editable: 'never' },
        ],
        data: [],
    });

    function getRecruitmentRequisitions() {
        var allRecruitmentRequisitions = [];

        db.collection('recruitmentRequisitions')
            .orderBy("dateCreated", "desc")
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    var creationDate = moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY')
                    allRecruitmentRequisitions.push({
                        ...doc.data(),
                        id: doc.id,
                        dateCreated: creationDate,
                    });
                })
                setRecruitmentRequisitions({
                    ...recruitmentRequisitions,
                    data: allRecruitmentRequisitions,
                })
            })
            .catch(function (error) {
                console.log("Error getting recruitment requisitions: ", error);
            });
    }

    useEffect(() => {
        getRecruitmentRequisitions()
        return () => { }
    }, []);

    return (
        <div>
            <MaterialTable
                title={"Recruitment Requisitions"}
                columns={recruitmentRequisitions.columns}
                data={recruitmentRequisitions.data}
                detailPanel={rowData => {
                    // console.log(rowData)
                    return (
                        <TicketHRDetails rowData={rowData} getRecruitmentRequisitions={getRecruitmentRequisitions} />
                    )
                }}
                options={{
                    // exportCsv: (data, columns) => console.log(data, columns, '<== CSV'),
                    // exportAllData: true, 
                    // columnsButton: true, 
                    detailPanelType: "single", actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50, recruitmentRequisitions.data.length === 0 ? 100 : { value: recruitmentRequisitions.data.length, label: 'All' }]
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                actions={
                    [{
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getRecruitmentRequisitions()
                            return () => { }
                        },
                    }]
                }
            />
        </div>
    );
}