import React from 'react';
import { makeStyles } from '@material-ui/core';
import coffeeMorning from '../../../../images/CoffeeMorningCertificate.jpg'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '70%',
        borderRadius: 20,
    },

}));

export default function CoffeeMorning2024() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                <h2 className={classes.textCentered}>Support Women's Aid Charity</h2>
                <article >
                    <h5 >Dear Colleagues,</h5>
                    <section>
                        Further to the coffee morning which was held on the 8th March 2024.
                        We would like to thank all of you who participated and generously donated to Women's Aid.
                        Your generosity is much appreciated.
                    </section>
                    <br />
                </article>
            </header>
            <div className={classes.textCentered}>
                <img className={classes.media} src={coffeeMorning} alt="women's day" />
            </div>

            <br />
            <p>Regards,<br />
                Eleanor Lester<br />
                HR Co-Ordinator | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}