import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(2, 1, 1, 1),
        marginTop: 'auto',
    },
}));

export default function Footer() {
    const classes = useStyles();

    return (

        <footer className={classes.footer}>
            <Typography className={classes.footer} variant="body2" color="textSecondary" align="center" mt={8} >
                {'© Pawel Gajewski | '}
                <Link color="inherit" href="https://wisetek.net/">
                    Wisetek
                        </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </footer>

    );
}
