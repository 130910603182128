import React, { useContext, useState } from 'react';
import Footer from '../../Footer'
import { AuthContext } from '../../../contexts/AuthContext';
import { makeStyles, Container, CssBaseline } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import AttendanceClock from './AttendanceClock';
import SideMenuSignOutOnly from '../SideMenus/SideMenuSignOutOnly';
import Loading from '../Loading';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    center: {
        textAlign: 'center'
    },
}));

export default function AttendanceConsole() {

    const classes = useStyles();
    const { isAuthenticated, loading, roles } = useContext(AuthContext)

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuSignOutOnly />
                        <Container component="main" className={classes.main} maxWidth="xl">

                            <h1 className={classes.center}>Attendance Clock</h1>
                            <AttendanceClock />

                        </Container>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}
