import React from 'react'
import { makeStyles } from '@material-ui/core';
import roundIrelandMap from './../../../../images/RoundIreland.PNG'
import movemberTeam from './../../../../images/MovemberTeam.jpg'
import grow from './../../../../images/Grow.jpg'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '55%',
        borderRadius: 10,
        margin: 30
    },
    map: {
        width: '60%',
        borderRadius: 10,
        margin: 30
    },
}));

export default function Movember2022() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.textCentered}>
                <h4>Movember 2022</h4>
                <img className={classes.media} src={movemberTeam} alt="Movember Team" />
            </div>

            <p>Hi All,</p>
            <p>We can all feel it. The evenings are becoming cooler, and quickly approaching is the month where men grow moustaches in support of men’s health.
                <b>Yes, MOVEMBER IS COMING!!!</b><br /><br />
                Wisetek are getting involved with <a href='https://ie.movember.com/' target="_blank" rel="noopener noreferrer">MOVEMBER</a> again this year with the hopes of raising some well needed funds for men’s mental health and suicide prevention, prostate cancer, and testicular cancer. This is a world-wide movement so all Wisetek employees can participate.</p>
            <h4>AS A WISETEK EMPLOYEE, HOW CAN I HELP TO RAISE MONEY?</h4>

            <h3 style={{ color: 'teal' }}>1.Grow a MOUSTACHE!</h3>
            <p>Step 1: Shave your beard for some smooth ‘before’ photos.
                <br />Step 2: Don’t shave for the month of November (great excuse).
                <br />Step 3: Join our <a href='https://ie.movember.com/team/2427912?mc=1&utm_medium=share&utm_source=dynamic&utm_campaign=copy-clipboard' target="_blank" rel="noopener noreferrer">Wisetek MO’s team</a>
                <br />Step 4: Please donate whatever you can.
                <br />Step 5: Tell people about your moustache, share your MOVEMBER Donation link to friends, family, neighbours and colleagues through text or social media.</p>

            <h3 style={{ color: 'teal' }}>2. WALK AROUND IRELAND! Join us in our ‘Walk around Ireland for Mo’ initiative.</h3>
            <p>Step 1: Download the app <b>Stridekick</b> on your smartphone or wearable device
                <br />Step 2: Send a friend request to Team Wisetek, <a href='https://link.stridekick.com/join?memberCode=NEP79M' target="_blank" rel="noopener noreferrer">‘Wisetek-IRL’</a>
                <br />Step 3: Start walking!</p>

            <h4>WALK AROUND IRELAND Challenge for Mo</h4>
            <ul>
                <li>All around Ireland, is estimate total: 2120km</li>
                <li>Steps Equivalent is: <b>3 million steps</b></li>
                <li>Walking Challenge <b>starting on Tuesday 1st November 2022, from Cork, then towards West Cork, hoping to be back in Cork by the 30th of November</b> 😊</li>
                <li>Goal - each person to walk 10,000 steps per day</li>
                <li>Pawel will keep an eye on it 😉</li>
            </ul>

            <div className={classes.textCentered}>
                <img className={classes.map} src={roundIrelandMap} alt="Round Ireland map" />
            </div>

            <h3 style={{ color: 'teal' }}>3. PRIZES 😊</h3>
            <p>We have prizes lined-up for: The Top Fundraiser, The Top Mover, and The Best & Worst Moustaches at the end of the month.</p>
            <p>ANY QUESTIONS?</p>

            <p><b>When do I shave?</b>
                <br />Shave off your beard on the 31st October, the day before Movember kicks off.
                <br /><br />
                <b>When do I grow?</b>
                <br />You grow from the 1st of November to the last day of November the 30th, shave it off on the 1st of December!
                <br /><br />
                <b>What do I grow?</b>
                <br />Typically, you would grow a moustache, but it is also acceptable to grow a beard if that’s what you prefer!</p>

            <div className={classes.textCentered}>
                <img className={classes.media} src={grow} alt="Grow moustache" />
            </div>

            <h4>WHY WOULD I TAKE PART MOVEMBER 2020?</h4>
            <p>Not only does it not cost you anything, but there are also <i style={{ color: 'orange' }}><b>prizes for best Moustache and best Walker, Runner, or Joggers!</b></i> Remember, you can choose to take part as much or as little as you are able to do.
                Any questions please ask.  Supervisors can you please share this communication with your Teams please.</p>
            <br /><br />
            <p>Regards,<br /><br />

                Evelyn Wiacek<br />
                HR Manager – Ireland/UK | Wisetek™<br />
            </p>
            <br />
        </div>
    )
}
