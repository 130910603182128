import React, { useState, useEffect, useContext } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { db } from '../../../../config/fbConfig';
import { InputBase, makeStyles } from '@material-ui/core';
import moment from 'moment';
import TicketsITDetails from './TicketITDetails';
import { AuthContext } from '../../../../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
    leftMargin: {
        marginLeft: '30px',
    },
}))

export default function TicketsIT() {
    const classes = useStyles();

    const { firstName, username } = useContext(AuthContext);

    const [ticketsIT, setTicketsIT] = useState({
        columns: [
            { title: 'Status', field: 'Status', editable: 'never' },
            { title: 'Department', field: 'Department', editable: 'never' },
            { title: 'Ticket Type', field: 'TicketType', editable: 'never' }, //filtering: false
            { title: 'Assigned IT', field: 'AssignedIt', editable: 'never' },
            { title: 'Raised By', field: 'RaisedBy', editable: 'never' },
            { title: 'Raised Date (yyyy/mm/dd)', field: 'DateRaised', type: 'date', editable: 'never', defaultSort: 'desc' },
            { title: 'Issue Category', field: 'IssueCategory', editable: 'never' },
            { title: 'Request Category', field: 'RequestCategory', editable: 'never' },
            { title: 'Week', field: 'WeekNo', editable: 'never' },
            { title: 'Record UID', field: 'RecordUID', editable: 'never', filtering: false },
            // { title: 'Platform', field: 'Platform', editable: 'never' },
            { title: 'Failure Category', field: 'FailureCategory', editable: 'never' },
            // { title: 'Closed Date', field: 'ClosedDate', type: 'date', editable: 'never' },
            { title: 'Site', field: 'Site', editable: 'never' },
            // { title: 'Process', field: 'Process', editable: 'never' },
            { title: 'Area', field: 'Area', editable: 'never' },
            // { title: 'Fix Type', field: 'FixType', editable: 'never' },
            // { title: 'Root Cause Category', field: 'RootCauseCategory', editable: 'never' },
            // { title: 'Root Cause Description', field: 'RootCauseDescription', hidden: true, cellStyle: { whiteSpace: "nowrap", maxWidth: "50px" }, editable: 'never' },
            // { title: 'Root Cause Description', field: 'RootCauseDescription', cellStyle: { whiteSpace: "nowrap", maxWidth: "50px" }, editable: 'never' },
            {
                title: 'Root Cause Description', field: 'RootCauseDescription', editable: 'never', render: rowData =>
                    <InputBase readOnly multiline maxRows={4} value={rowData.RootCauseDescription} style={{ minWidth: 250, fontSize: '1em' }} />,
                // editComponent: props => //console.log(props.rowData)
                //     <TextField multiline maxRows={6}
                //         value={props.rowData.Description}
                //         onChange={(e) => { props.onChange(e.target.value) }}
                //         style={{ minWidth: 400 }} inputProps={{ maxLength: 1000 }} />
            },
            {
                title: 'Fix Update Description', field: 'FixUpdateDescription', editable: 'never', render: rowData =>
                    <InputBase readOnly multiline maxRows={4} value={rowData.FixUpdateDescription} style={{ minWidth: 250, fontSize: '1em' }} />,
            },
            // { title: 'Nav Error', field: 'NavErrorNo', editable: 'never' },
            // { title: 'Ticket Send From', field: 'TicketSendFrom', editable: 'never' },

        ],
        data: [],
        openTickets: 0,
        closedTickets: 0,
    });

    const allTicketsIT = [];
    const allOpenTicketsIT = [];
    const allClosedTicketsIT = [];


    useEffect(() => {
        db.collection('ticketsIT')
            // .where('Status', 'in', ['Open', 'WIP - Scope', 'WIP - Dev', 'WIP - UAT', 'WIP - Production Cut in', 'On Hold', 'Aknowledge'])
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    // checking if FilesURLs are undefined from outlook add-in
                    if (doc.get('FilesURLs') === undefined) {
                        // console.log('added FilesURLs => ' + doc.id, doc.data())
                        allTicketsIT.push({
                            ...doc.data(),
                            RecordUID: doc.id,
                            DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                            // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                            FilesURLs: [],
                            moreThanOnePlatform: doc.data().moreThanOnePlatform ? doc.data().moreThanOnePlatform : [],
                        });
                        if (doc.data().Status === 'Open' || doc.data().Status === 'WIP - Scope' || doc.data().Status === 'WIP - Dev' || doc.data().Status === 'WIP - UAT' || doc.data().Status === 'WIP - Production Cut in' || doc.data().Status === 'On Hold' || doc.data().Status === 'Aknowledge') {
                            allOpenTicketsIT.push({
                                ...doc.data(),
                                RecordUID: doc.id,
                                DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                                FilesURLs: [],
                            });
                        }
                        if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                            allClosedTicketsIT.push({
                                ...doc.data(),
                                RecordUID: doc.id,
                                DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                                FilesURLs: [],
                            });
                        }
                        db.collection("ticketsIT").doc(doc.id).update({
                            FilesURLs: []
                        })
                    }
                    else {
                        allTicketsIT.push({
                            ...doc.data(),
                            RecordUID: doc.id,
                            DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                            // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                            moreThanOnePlatform: doc.data().moreThanOnePlatform ? doc.data().moreThanOnePlatform : [],
                        });
                        if (doc.data().Status === 'Open' || doc.data().Status === 'WIP - Scope' || doc.data().Status === 'WIP - Dev' || doc.data().Status === 'WIP - UAT' || doc.data().Status === 'WIP - Production Cut in' || doc.data().Status === 'On Hold' || doc.data().Status === 'Aknowledge') {
                            allOpenTicketsIT.push({
                                ...doc.data(),
                                RecordUID: doc.id,
                                DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                            });
                        }
                        if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                            allClosedTicketsIT.push({
                                ...doc.data(),
                                RecordUID: doc.id,
                                DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                            });
                        }
                    }
                    // add field FilesURLs to each ticket in firestore if not exists
                    // if (doc.get('FilesURLs') != null) {
                    //     console.log('FilesURLS exists')
                    //     return
                    // }
                    // else {
                    //     console.log('added FilesURLs => ' + doc.id, doc.data())
                    //     db.collection("ticketsIT").doc(doc.id).update({
                    //         FilesURLs: []
                    //     })
                    // }
                })
                setTicketsIT({
                    ...ticketsIT,
                    data: allTicketsIT,
                    openTickets: allOpenTicketsIT.length,
                    closedTickets: allClosedTicketsIT.length,
                })
            })
            .catch(function (error) {
                console.log("Error getting IT tickets: ", error);
            });
        return () => { }
    }, []);

    return (
        <div >

            <MaterialTable
                title={"IT Tickets"}
                columns={ticketsIT.columns}
                data={ticketsIT.data}
                detailPanel={rowData => {
                    rowData.username = username; // assigning username for email purpose
                    // console.log(rowData)
                    return (
                        <TicketsITDetails ticketData={rowData} />
                    )
                }}
                options={{
                    thirdSortClick: false, detailPanelType: "single", actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50, ticketsIT.data.length === 0 ? 100 : { value: ticketsIT.data.length, label: 'All' }]
                }}//, { value: ticketsIT.data.length, label: 'All' }] }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                actions={
                    [{
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            db.collection('ticketsIT')
                                // .where('Status', 'in', ['Open', 'WIP - Scope', 'WIP - Dev', 'WIP - UAT', 'WIP - Production Cut in', 'On Hold', 'Aknowledge'])
                                .get()
                                .then(snapshot => {
                                    snapshot.forEach(doc => {
                                        // checking if FilesURLs are undefined from outlook add-in
                                        if (doc.get('FilesURLs') === undefined) {
                                            console.log('added FilesURLs => ' + doc.id, doc.data())
                                            allTicketsIT.push({
                                                ...doc.data(),
                                                RecordUID: doc.id,
                                                DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                                // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                                                FilesURLs: [],
                                            });
                                            if (doc.data().Status === 'Open' || doc.data().Status === 'WIP - Scope' || doc.data().Status === 'WIP - Dev' || doc.data().Status === 'WIP - UAT' || doc.data().Status === 'WIP - Production Cut in' || doc.data().Status === 'On Hold' || doc.data().Status === 'Aknowledge') {
                                                allOpenTicketsIT.push({
                                                    ...doc.data(),
                                                    RecordUID: doc.id,
                                                    DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                                    // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                                                    FilesURLs: [],
                                                });
                                            }
                                            if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                                                allClosedTicketsIT.push({
                                                    ...doc.data(),
                                                    RecordUID: doc.id,
                                                    DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                                    ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                                                    FilesURLs: [],
                                                });
                                            }
                                            db.collection("ticketsIT").doc(doc.id).update({
                                                FilesURLs: []
                                            })
                                        }
                                        else {
                                            allTicketsIT.push({
                                                ...doc.data(),
                                                RecordUID: doc.id,
                                                DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                                // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                                            });
                                            if (doc.data().Status === 'Open' || doc.data().Status === 'WIP - Scope' || doc.data().Status === 'WIP - Dev' || doc.data().Status === 'WIP - UAT' || doc.data().Status === 'WIP - Production Cut in' || doc.data().Status === 'On Hold' || doc.data().Status === 'Aknowledge') {
                                                allOpenTicketsIT.push({
                                                    ...doc.data(),
                                                    RecordUID: doc.id,
                                                    DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                                    // ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                                                });
                                            }
                                            if (doc.data().Status === 'Completed' || doc.data().Status === 'Closed - Not Needed') {
                                                allClosedTicketsIT.push({
                                                    ...doc.data(),
                                                    RecordUID: doc.id,
                                                    DateRaised: moment(doc.data().DateRaised.toDate()).format('YYYY/MM/DD'),
                                                    ClosedDate: moment(doc.data().ClosedDate.toDate()).format('YYYY/MM/DD'),
                                                });
                                            }
                                        }
                                        // add field FilesURLs to each ticket in firestore if not exists
                                        // if (doc.get('FilesURLs') != null) {
                                        //     console.log('FilesURLS exists')
                                        //     return
                                        // }
                                        // else {
                                        //     console.log('added FilesURLs => ' + doc.id, doc.data())
                                        //     db.collection("ticketsIT").doc(doc.id).update({
                                        //         FilesURLs: []
                                        //     })
                                        // }
                                    })
                                    setTicketsIT({
                                        ...ticketsIT,
                                        data: allTicketsIT,
                                        openTickets: allOpenTicketsIT.length,
                                        closedTickets: allClosedTicketsIT.length,
                                    })
                                })
                                .catch(function (error) {
                                    console.log("Error getting IT tickets: ", error);
                                });
                            return () => { }
                        },
                    }]}
                // editable={{
                //     // onRowAdd: newData =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             resolve();
                //     //             setIssueError(prevState => {
                //     //                 const data = [...prevState.data];
                //     //                 data.push(newData);
                //     //                 return { ...prevState, data };
                //     //             });
                //     //         }, 600);
                //     //     }),
                //     // onRowUpdate: (newData, oldData) =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             if (oldData) {
                //     //                 setTicketsIT(prevState => {
                //     //                     const data = [...prevState.data];
                //     //                     data[data.indexOf(oldData)] = newData;
                //     //                     if (newData.Status == 'Completed' || newData.Status == 'Closed - Not Needed') {
                //     //                         var date = new Date();
                //     //                         var today = date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();
                //     //                         newData.ClosedDate = today;
                //     //                     }
                //     //                     console.log('Updated issue error ticket in DB')
                //     //                     // Update data
                //     //                     // if closed or not needed then add closing date
                //     //                     db.collection("issueErrorTickets").doc(newData.ticketId).update({
                //     //                         Status: newData.Status,
                //     //                         AssignedIT: newData.AssignedIT,
                //     //                         RootCauseCategory: newData.RootCauseCategory,
                //     //                         FixType: newData.FixType,
                //     //                         FailureCategory: newData.FailureCategory,
                //     //                         FixUpdateDescription: newData.FixUpdateDescription,
                //     //                         ClosedDate: newData.ClosedDate,
                //     //                     });
                //     //                     resolve();
                //     //                     return { ...prevState, data };
                //     //                 })
                //     //             }
                //     //         }, 600)
                //     //     }),
                //     // onRowDelete: oldData =>
                //     //     new Promise(resolve => {
                //     //         setTimeout(() => {
                //     //             resolve();
                //     //             setIssueError(prevState => {
                //     //                 const data = [...prevState.data];
                //     //                 data.splice(data.indexOf(oldData), 1);
                //     //                 return { ...prevState, data };
                //     //             });
                //     //         }, 600);
                //     //     }),
                // }}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div >
                                <p className={classes.leftMargin}>Hey {firstName}! We need to focus on those {ticketsIT.openTickets} open tickets.</p>
                            </div>
                        </div>
                    ),
                }}
            />
        </div>
    );
}
