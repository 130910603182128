import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: "absolute",

        backgroundColor: theme.palette.background.paper,
        // border: "1px solid #444",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

export const SimpleModal = (props) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [url, setUrl] = React.useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    // const url1 = "https://firebasestorage.googleapis.com/v0/b/wisetekapp.appspot.com/o/ticketAttachments%2Fi86yItv1OkofydkjEZP1%2Farea.jpg?alt=media&token=fd71be16-c77d-45a4-a1f6-23f405226530";

    useEffect(() => {
        setOpen(props.modalState.open)
        setUrl(props.modalState.url)
        return () => { }
    }, [props.modalState])
    return (
        <div>
            {/* <button type="button" onClick={handleOpen}>
                Open Modal
        </button> */}
            <Modal open={open} onClose={handleClose}>
                <div style={modalStyle} className={classes.paper}>
                    <img src={url} />
                </div>
            </Modal>
        </div>
    );
}
