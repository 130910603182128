import React, { useState, useContext, useEffect } from 'react'
import { TextField, makeStyles, Button, MenuItem, Grid, List, Tooltip, ListItem, ListItemText, InputAdornment, IconButton } from '@material-ui/core'
import { DataContext } from '../../../../contexts/DataContext'
import { functions, auth } from '../../../../config/fbConfig';
import moment from 'moment';
import { SimpleModal } from '../../ModalSnackbar/SimpleModal';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    minWidth: {
        minWidth: '200px'
    },
    body: {
        padding: '50px'
    },
    hidden: {
        display: 'none'
    },
    myForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '35ch',
        },
        paddingLeft: '35px',
        paddingTop: '35px',
        paddingRight: '50px',
        paddingBottom: '35px',
    },
    itAttachments: {
        margin: 24
    },
    marginLeft: {
        marginLeft: 24
    },
    buttonLeft: {
        float: 'left',
        marginLeft: '24px',
        marginTop: '24px',
        marginBottom: '35px',

    },
    buttonRight: {
        float: 'right',
        marginTop: '24px',
        marginBottom: '35px',

    },
    input: {
        display: 'none',
    },
    CardRoot: {
        width: 200,
        margin: '10px',
    },
    CardMedia: {
        height: 100,
        backgroundSize: 'contain',
    },
    marginRight: {
        marginRight: '15px'
    },
    attachments: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)'
        },
        height: '35px',
        background: '#5E89B4',
        color: 'white',
        borderRadius: '5px',
    },
    attList: {
        paddingLeft: '8px',
        paddingRight: '8px',
    }
}))

const engTicketStatus = [
    { value: '', label: '' },
    { value: 'Open', label: 'Open' },
    { value: 'WIP', label: 'WIP' },
    // { value: 'WIP - Scope', label: 'WIP - Scope' },
    // { value: 'WIP - Dev', label: 'WIP - Dev' },
    // { value: 'WIP - UAT', label: 'WIP - UAT' },
    // { value: 'WIP - Production Cut in', label: 'WIP - Production Cut in' },
    { value: 'On Hold', label: 'On Hold' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Closed - Not Needed', label: 'Closed - Not Needed' },
    // { value: 'Acknowledge', label: 'Acknowledge' },
];
const assignedENGs = [
    { value: '', label: '' },
    { value: 'Dave Murphy', label: 'Dave Murphy' },
    { value: 'Declan Maguire', label: 'Declan Maguire' },
    { value: 'Declan Cotter', label: 'Declan Cotter' },
    { value: 'Dermot Moane', label: 'Dermot Moane' },
    { value: 'Donnacha McEnery', label: 'Donnacha McEnery' },
    { value: 'Gerard Leen', label: 'Gerard Leen' },
    { value: 'John Morrison', label: 'John Morrison' },
    { value: 'John Murphy', label: 'John Murphy' },
    { value: 'Pierce Whelan', label: 'Pierce Whelan' },
    { value: 'William Murray', label: 'William Murray' },
];
const engRootCauseCategories = [
    { value: '', label: '' },
    { value: 'Hardware Component Issue', label: 'Hardware Component Issue' },
    { value: 'Hardware Driver/Software Issue', label: 'Hardware Driver/Software Issue' },
    { value: 'Process Gap', label: 'Process Gap' },
    { value: 'System Gap', label: 'System Gap' },
    { value: 'Training Gap', label: 'Training Gap' },
    { value: 'User Knowledge Gap', label: 'User Knowledge Gap' },
    { value: 'User Permission Set/Access setup', label: 'User Permission Set/Access setup' },
];
const engFixTypes = [
    { value: '', label: '' },
    { value: 'Erasure CI Required', label: 'Erasure CI Required' },
    { value: 'No Fix Required', label: 'No Fix Required' },
    { value: 'Process CI Required', label: 'Process CI Required' },
    { value: 'Repair/Replace hardware', label: 'Repair/Replace hardware' },
    { value: 'System CI Required', label: 'System CI Required' },
    { value: 'Test CI Required', label: 'Test CI Required' },
    { value: 'Training Required', label: 'Training Required' },
    { value: 'Update Documentation', label: 'Update Documentation' },
    { value: 'Updated Access', label: 'Updated Access' },
];


export default function TicketEngineeringDetails(props) {
    const classes = useStyles()
    // console.log('props.ticketData', props.ticketData)
    const { updateTicketEngineering, addEngAttachmentToBucketAndTicket, removeEngAttachmentFromBucketAndTicket } = useContext(DataContext);

    const [editValues, setEditValues] = useState({
        // issue error and change request values
        TicketType: '',
        RecordUID: '',
        Status: '',
        AssignedEng: '',
        RootCauseCategory: '',
        FixType: '',
        FixUpdateDescription: '',
        FilesURLs: [],

        engFilesURLs: [],
        engFiles: [],
        engFilesSize: 0,
        engFilesToStoreInDB: [],
    })
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        // console.log('props.ticketData.engFilesURLs', props.ticketData.engFilesURLs)
        setEditValues({
            ...editValues,
            TicketType: props.ticketData.TicketType,
            RecordUID: props.ticketData.RecordUID,
            Status: props.ticketData.Status,
            AssignedEng: props.ticketData.AssignedEng,
            RootCauseCategory: props.ticketData.RootCauseCategory,
            FixType: props.ticketData.FixType,
            FixUpdateDescription: props.ticketData.FixUpdateDescription,
            FilesURLs: props.ticketData.FilesURLs,
            engFilesURLs: props.ticketData.engFilesURLs,
        })
    }, [props.ticketData])

    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isSubmitting) {
            setIsSubmitting(true);
            if (editValues.TicketType === 'Issue / Error') {

                if (editValues.Status === '' ||
                    editValues.AssignedEng === '' ||
                    editValues.RootCauseCategory === '' ||
                    editValues.FixType === '' ||
                    editValues.FixUpdateDescription === '') {
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: '* Please select all required fields and add description.',
                    })
                    setIsSubmitting(false)
                    // console.log('dont update and return')
                    return
                }
            } else {
                if (editValues.Status === '' ||
                    editValues.AssignedEng === '' ||
                    editValues.FixUpdateDescription === '') {
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: '* Please select all required fields and add description.',
                    })
                    setIsSubmitting(false)
                    // console.log('dont update and return')
                    return
                }
            }

            console.log('Update ticket now')
            console.log('props.ticketData', props.ticketData)

            updateTicketEngineering(props.ticketData)
                .then(success => {
                    console.log('success', success)
                    //display success
                    // sendTicketResponse()
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: success,
                    })
                    setEditValues({
                        ...editValues,
                        engFiles: [],
                        engFilesSize: 0,
                        engFilesToStoreInDB: [],
                    })
                    setIsSubmitting(false)
                })
                .catch(error => {
                    console.log('error', error)
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: error,
                    })
                    setIsSubmitting(false)
                })
        }
    };

    function sendTicketResponse() {
        console.log('send the response now')
        // console.log(props.ticketData)

        const callable = functions.httpsCallable('sendEngTicketResponse');
        return callable(props.ticketData, auth)
            .then(
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Respond sent successfully. User will be notified. Thank you.',
                })
            )
    }

    const handleChange = (e) => {
        props.ticketData[e.target.name] = e.target.value
        setEditValues({
            ...editValues,
            [e.target.name]: e.target.value
        })
    };

    const handleDownload = async (e, file) => {
        e.preventDefault();
        const response = await fetch(file.url);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }


    return (
        <div>

            <form className={classes.myForm} noValidate autoComplete="off" onSubmit={handleSubmit}>

                <TextField name="Division" label="Division" variant="outlined" value={props.ticketData.Division} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="Category" label="Category" variant="outlined" value={props.ticketData.Category} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="Action" label="Action" variant="outlined" value={props.ticketData.Action} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="Process" label="Process" variant="outlined" value={props.ticketData.Process} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="Site" label="Site" variant="outlined" value={props.ticketData.Site} disabled size="small" InputLabelProps={{ shrink: true }} />
                {/* no need to display closed date if ticket is open */}
                {props.ticketData.Status === 'Completed' || props.ticketData.Status === 'Closed - Not Needed' ?
                    <TextField name="ClosedDate" label="Closed Date" variant="outlined" value={moment(props.ticketData.ClosedDate.toDate()).format('YYYY/MM/DD')} disabled size="small" InputLabelProps={{ shrink: true }} />
                    : null}

                {editValues.FilesURLs === undefined || editValues.FilesURLs.length === 0 ? null :
                    <Grid container className={classes.marginLeftRight} direction="row" justifyContent="flex-start" alignItems="flex-start">
                        {editValues.FilesURLs.map((file, i) => (
                            <List key={i} className={classes.attList}>
                                <Tooltip title="Download">
                                    <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                        <SaveAltIcon className={classes.marginRight} />
                                        <ListItemText primary={file.name} />
                                    </ListItem>
                                </Tooltip>
                            </List>
                        ))}
                    </Grid>
                }

                <TextField name="Description" label="Description" fullWidth multiline minRows={6} variant="outlined" value={props.ticketData.Description} disabled size="small" InputLabelProps={{ shrink: true }} />

                {editValues.TicketType === 'Issue / Error' ?
                    <div>
                        <TextField
                            select
                            required
                            name="Status"
                            label="Status"
                            value={editValues.Status}
                            onChange={handleChange}
                            error={editValues.Status === ""}
                            helperText={editValues.Status === "" ? 'Required field to send response.' : ' '}
                            variant="outlined"
                            size='small'
                            InputLabelProps={{ shrink: true }}>
                            {engTicketStatus.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            required
                            name="AssignedEng"
                            label="Assigned Eng"
                            value={editValues.AssignedEng}
                            onChange={handleChange}
                            error={editValues.AssignedEng === ""}
                            helperText={editValues.AssignedEng === "" ? 'Required field to send response.' : ' '}
                            variant="outlined"
                            size='small'
                            InputLabelProps={{ shrink: true }}>
                            {assignedENGs.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            required
                            name="RootCauseCategory"
                            label="Root Cause Category"
                            value={editValues.RootCauseCategory}
                            onChange={handleChange}
                            error={editValues.RootCauseCategory === ""}
                            helperText={editValues.RootCauseCategory === "" ? 'Required field to send response.' : ' '}
                            variant="outlined"
                            size='small'
                            InputLabelProps={{ shrink: true }}>
                            {engRootCauseCategories.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            required
                            name="FixType"
                            label="Fix Type"
                            value={editValues.FixType}
                            onChange={handleChange}
                            error={editValues.FixType === ""}
                            helperText={editValues.FixType === "" ? 'Required field to send response.' : ' '}
                            variant="outlined"
                            size='small'
                            InputLabelProps={{ shrink: true }}>
                            {engFixTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField name="FixUpdateDescription" label="Fix Update Description"
                            required
                            fullWidth
                            multiline
                            minRows={6}
                            variant="outlined"
                            value={editValues.FixUpdateDescription}
                            onChange={handleChange}
                            error={editValues.FixUpdateDescription === ""}
                            helperText={editValues.FixUpdateDescription === "" ? 'Required field to send response.' : ' '}
                            size="small"
                            InputLabelProps={{ shrink: true }} />
                    </div>
                    :
                    <div>
                        <TextField
                            select
                            required
                            name="Status"
                            label="Status"
                            value={editValues.Status}
                            onChange={handleChange}
                            error={editValues.Status === ""}
                            helperText={editValues.Status === "" ? 'Required field to send response.' : ' '}
                            variant="outlined"
                            size='small'
                            InputLabelProps={{ shrink: true }}>
                            {engTicketStatus.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            required
                            name="AssignedEng"
                            label="Assigned Eng"
                            value={editValues.AssignedEng}
                            onChange={handleChange}
                            error={editValues.AssignedEng === ""}
                            helperText={editValues.AssignedEng === "" ? 'Required field to send response.' : ' '}
                            variant="outlined"
                            size='small'
                            InputLabelProps={{ shrink: true }}>
                            {assignedENGs.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField name="FixUpdateDescription" label="Fix Update Description"
                            required
                            fullWidth
                            multiline
                            minRows={6}
                            variant="outlined"
                            value={editValues.FixUpdateDescription}
                            onChange={handleChange}
                            error={editValues.FixUpdateDescription === ""}
                            helperText={editValues.FixUpdateDescription === "" ? 'Required field to send response.' : ' '}
                            size="small"
                            InputLabelProps={{ shrink: true }} />
                    </div>
                }

                <Button
                    type="button"
                    disabled={isSubmitting}
                    className={classes.buttonLeft}
                    variant="contained"
                    style={{ backgroundColor: 'lightgreen' }}
                    onClick={sendTicketResponse}
                >
                    Send response
                </Button>
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={classes.buttonRight}
                    variant="contained"
                    color="primary"
                >
                    Update ticket
                </Button>
            </form>
            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    )
}