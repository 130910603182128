import React, { useState, useContext } from 'react';
import logo from '../../images/wisetek_owl.png';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { AuthContext } from '../../contexts/AuthContext';
import { Card } from '@material-ui/core';
import Footer from '../Footer';
import SnackbarComponent from '../customComponents/SnackbarComponent';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f0f5f4'
    },
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    card: {
        padding: '24px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ForgotPassword() {
    const classes = useStyles();
    const [state, setState] = useState({
        email: '',
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const { resetPasswordEmail } = useContext(AuthContext);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        //console.log(state)
        resetPasswordEmail(state)
            .then(success => {
                //console.log(success);
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                })
            })
            .catch(error => {
                //console.log(error);
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                })
            })
        document.getElementById('passwordReset-form').reset();
    }

    return (
        <div className={classes.root}>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <div>
                        <img id="logo" src={logo} alt="Logo" />
                    </div>
                    <Typography component="h1" variant="h5">
                        Send password reset link
                    </Typography>
                </div>

                <Card className={classes.card}>
                    <form id="passwordReset-form" className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            size="small"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                            inputProps={{ maxLength: 50 }}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Reset password link
                        </Button>
                        <Grid container>
                            <Grid item xs>

                            </Grid>
                            <Grid item>
                                <Link href="/" variant="body2">
                                    {"So you remember password? Sign in"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

                </Card>
            </Container>
            <Footer />
        </div>
    );
}