import React, { useState, useContext, useEffect } from 'react'
import { TextField, makeStyles, Button, MenuItem, Grid, Slider, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import { green, yellow } from '@material-ui/core/colors';
import { DataContext } from '../../../contexts/DataContext'
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({

    detailBody: {
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '50px',
        paddingRight: '50px',
    },
    tableContainer: {
        backgroundColor: '#90f0a9'
    },
    myForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '35ch',
            maxWidth: '70ch',
        },
        padding: '35px',
    },
    addButton: {
        margin: theme.spacing(1),
    },
    mySlider: {
        marginTop: '40px'
    }
}));

const AssignTasksDetails = (props) => {
    const classes = useStyles();

    const { addWeeklyTask } = useContext(DataContext);

    // console.log('props', props)
    // const [percent, setPercent] = useState(0)

    const categories = createMappingArrayStrings(props.taskCategory.selected)
    // const percentages = createMappingArrayNumbers(props.taskCategory.allocatedPercent)

    const [thisUserTasks, setThisUserTasks] = useState({
        columns: [
            { label: 'Task Category', key: 'taskCategory' },
            { label: 'Allocated (%)', key: 'allocatedPercent' },
            { label: 'Allocated (h)', key: 'allocatedHours' },
            { label: 'Actual (h)', key: 'actualHours' },
            { label: 'Manager Comment', key: 'additionalComment' },
            { label: 'User Comment', key: 'additionalUserComment' },
        ],
        data: []
    });

    const [form, setForm] = useState({
        additionalComment: '',
        additionalUserComment: props.tasksData.additionalUserComment,
        assignedTask: '',
        assignedTo: props.userData.id,
        assignedToFullName: props.userData.assignedTo,
        assignedHours: 0,
        assignedPercent: 0,
        assignedUserLeave: props.userData.holidaysDays,
        department: props.userData.department,
        availableHours: props.userData.availableHours,
        availablePercent: 0,
    });

    console.log(form)

    //Snackbar
    //#region 
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    const getUserTasks = (tasks, userId) => {
        console.log(tasks, userId)
        var count = 0;
        var userTasks = []
        var allocatedHours = 0
        var allocatedPercentages = 0
        tasks.forEach(task => {
            if (task.assignedTo === userId) {
                count++;
                task.key = count;
                userTasks.push(task)
                allocatedHours += task.allocatedHours
                allocatedPercentages += task.allocatedPercent
            }
        })
        setThisUserTasks({
            ...thisUserTasks,
            data: userTasks
        });
        setForm({
            ...form,
            allocatedHours: allocatedHours,
        });
        // setPercent(allocatedPercentages)
    }

    function createMappingArrayStrings(data) {
        // console.log(data)
        var tasks = [];
        var sortedTasks = [];

        // tasks = Object.keys(data).map(key => { key = data[key] });
        tasks = Object.entries(data)
        // sort by name
        var sorted = tasks.sort(function (a, b) {
            var nameA = a;
            var nameB = b;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        // console.log(sorted)
        sortedTasks.push({ value: '', label: '' })
        sorted.forEach(item => {
            sortedTasks.push({ value: item[0], label: item[1] })
        })

        // console.log('Tasks:', sortedTasks)
        return sortedTasks
    }
    // function createMappingArrayNumbers(data) {
    //     // console.log(data)
    //     var percentages = [];
    //     var availablePercentages = [];

    //     // tasks = Object.keys(data).map(key => { key = data[key] });
    //     percentages = Object.entries(data)

    //     percentages.forEach(item => {
    //         // console.log(item)
    //         // console.log(percent)
    //         if (Number(item[0]) <= 100 - percent) {
    //             availablePercentages.push({ value: Number(item[0]), label: item[1] })
    //         }
    //     })

    //     // console.log('Percentages:', sortedPercentages)
    //     return availablePercentages
    // }

    useEffect(() => {
        console.log('geting user tasks')
        getUserTasks(props.tasksData, props.userData.id)
        return () => {
        }
    }, [])

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    };
    const handleChangeSlider = (e, newValue) => {
        setForm({
            ...form,
            assignedHours: newValue,
            // assignedPercent: Number(((100 * newValue) / form.availableHours).toFixed(0))
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.assignedHours === 0) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please assign hours for that task.',
            })
            return
        }
        else {
            console.log('adding new task for that user')
            var assignedPercent = Number(((100 * form.assignedHours) / form.availableHours + form.allocatedHours).toFixed(0))
            setForm({
                ...form,
                assignedPercent: assignedPercent,
            })
            // console.log('This is to submit:', form)
            addWeeklyTask(form)
                .then(success => {
                    //display success
                    // console.log('back from db with success')
                    props.refreshTeamDetails();
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: success,
                    })
                })
                .catch(error => {
                    //display error
                    // console.log('back from db with error')
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: error,
                    })
                })
        }
    };

    function assignForm() {
        return (
            <form className={classes.myForm} autoComplete="off" onSubmit={handleSubmit}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <TextField select required name="assignedTask" label="Assign Task" value={form.assignedTask} onChange={handleChange} variant="outlined" size="small">
                        {categories.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {/* <TextField select required name="assignedPercent" label="Assign Percent" value={form.assignedPercent} onChange={handleChange} variant="outlined" size="small">
                        {percentages.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField> */}
                    {/* <TextField select required name="assignedHours" label="Assign Hours" value={form.assignedHours} onChange={handleChange} variant="outlined" size="small"> */}
                    <Grid container item direction="row" justifyContent="center" alignItems="center" xs={6}>
                        <Grid item xs={3}><p>Assign Hours:</p></Grid>
                        <Grid item xs={9}>
                            <Slider className={classes.mySlider}
                                value={form.assignedHours}
                                onChange={handleChangeSlider}
                                // getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-small-steps"
                                step={0.5}
                                marks
                                min={0}
                                max={form.availableHours}
                                valueLabelDisplay="on"
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        name="additionalComment"
                        label="Additional Comment"
                        variant="outlined"
                        size="small"
                        fullWidth
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 255 }}
                        className={classes.textArea}
                        value={form.additionalComment}
                        onChange={handleChange}
                    />
                    <Button type="submit"
                        className={classes.addButton}
                        variant="contained"
                        color="default"
                        startIcon={<AddBoxIcon style={{ color: green[500] }} />}
                    >
                        Add task
                    </Button>
                </Grid>
            </form>
        )
    }

    function assignedTasks() {
        return (
            <div>
                <Typography variant="subtitle2" >
                    Please see the tasks below:
                </Typography>
                <br />
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                {thisUserTasks.columns.map(column => (
                                    <TableCell key={column.key}>{column.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {thisUserTasks.data.map(row => (
                                <TableRow key={row.key}>
                                    <TableCell >{row.taskCategory}</TableCell>
                                    <TableCell >{row.allocatedPercent + ' %'}</TableCell>
                                    <TableCell >{row.allocatedHours + ' h'}</TableCell>
                                    <TableCell >{row.actualHours > -1 ? row.actualHours + ' h' : 'not submitted yet'}</TableCell>
                                    <TableCell >{row.additionalComment}</TableCell>
                                    <TableCell >{row.additionalUserComment}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer >
                <br />
            </div>
        )
    }

    return (
        <div className={classes.detailBody}>
            <Typography variant="h6" >
                {props.userData.assignedTo}
            </Typography>

            {props.userData.availableHours === 0 ?
                // available hours 0 and no tasks = user on holidays
                thisUserTasks.data.length === 0 ?
                    <div>
                        <BeachAccessIcon style={{ color: green[700] }} />__On Holidays__<WbSunnyIcon style={{ color: yellow[700] }} />
                    </div>
                    :
                    // has tasks assigned and allocatedPercent is 100 then only show tasks without add option
                    props.userData.allocatedPercent >= 100 ?
                        <div>
                            <Typography variant="subtitle1" >
                                Is fully booked for this week.
                            </Typography>

                            {assignedTasks()}

                        </div>
                        :
                        // has tasks assigned and but not at 100 %
                        null
                :
                // has available hours to be assigned                
                <div>
                    <Typography variant="subtitle1" >
                        Available hours to assign: {props.userData.availableHours} h
                    </Typography>

                    {assignedTasks()}

                    {assignForm()}

                </div>
            }

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    )
}

export default AssignTasksDetails