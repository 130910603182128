import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
    },
    textCentered: {
        textAlign: 'center'
    }
}));

export default function Coronavirus1() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                <h2 className={classes.textCentered}>Coronavirus (COVID-19)</h2>
            </header>

            <h5 >Dear Colleagues,</h5>

            <h4 >As I am sure you are aware the situation regarding the coronavirus (COVID -19) continues to evolve on a daily basis and as we continue to endeavour to protect the Health and Safety of our Employees in particular, we have decided to eliminate travel between all Wisetek Sites effective immediately.   This is also being done to assure our customers that we are taking all practical steps to mitigate any risk to business continuity.  Meetings and Site visits should be conducted over Zoom, teleconference, or other collaboration platforms, until further notice.
            </h4>
            <h4>
                We are cognisant that there may be occasions where some movement is necessary for business purposes and this will be evaluated on a case by case basis by management prior to the travel being undertaken.  Such queries should be directed to Ron Wiggins and Opal Dawn Martin in the US and Tom Delahunty and John Browne outside of the US.
            </h4>


            <h3 className={classes.textCentered}>We will update you when any new developments happen that require us as a Company to take additional action</h3>
            <article >

                <br />
                <p>Finally can I continue to remind you of the continued recommended advice which is as follows;</p>
                <ul >
                    <li>
                        <strong>Wash your hands frequently<br /></strong>
                        Regularly and thoroughly clean your hands with an alcohol-based hand rub or wash them with soap and
                        water.
                        <strong><br />Why?<br /></strong>
                        Washing your hands with soap and water or using alcohol-based hand rub kills viruses that may be on
                        your hands.
                    </li><br />
                    <li>
                        <strong>Maintain social distancing<br /></strong>
                        Maintain at least 1 metre (3 feet) distance between yourself and anyone who is coughing or sneezing.
                        <strong><br />Why?<br /></strong>
                        When someone coughs or sneezes they spray small liquid droplets from their nose or mouth which may
                        contain virus. If you are too close, you can breathe in the droplets, including the COVID-19 virus if
                        the person coughing has the disease.
                    </li><br />
                    <li>
                        <strong>Avoid touching eyes, nose and mouth<br />
                            Why?<br /></strong>
                        Hands touch many surfaces and can pick up viruses. Once contaminated, hands can transfer the virus
                        to your eyes, nose or mouth. From there, the virus can enter your body and can make you sick.
                    </li><br />
                    <li>
                        <strong>Practice respiratory hygiene<br /></strong>
                        Make sure you, and the people around you, follow good respiratory hygiene. This means covering your
                        mouth and nose with your bent elbow or tissue when you cough or sneeze. Then dispose of the used tissue
                        immediately.
                        <strong><br />Why?<br /></strong>Droplets spread virus. By following good respiratory hygiene you protect the people around you from
                        viruses such as cold, flu and COVID-19.
                    </li><br />
                    <li>
                        <strong>If you have fever, cough and difficulty breathing, seek medical care early<br /></strong>
                        Stay home if you feel unwell. If you have a fever, cough and difficulty breathing, seek medical
                        attention and call in advance. Follow the directions of your local health authority.
                        <strong><br />Why?<br /></strong>National and local authorities will have the most up to date information on the situation in your
                        area. Calling in advance will allow your health care provider to quickly direct you to the right health
                        facility. This will also protect you and help prevent spread of viruses and other infections.
                    </li><br />
                </ul>
                <br />
            </article>

            <h3 className={classes.textCentered}>
                Supervisors please share this communication with your Teams whom don’t have email access.
            </h3>
            <h2 className={classes.textCentered}>
                <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public" target="_blank" rel="noopener noreferrer">Additional
                    information about COVID-19</a>
            </h2>
            <br />
            <p>Regards,<br /><br />

                Geni D’Arcy<br />
                Global Head of HR<br />
                Wisetek™<br />
            </p>
            <br />

        </div>
    )
}