import React from 'react'
import { makeStyles } from '@material-ui/core';
// import happyEaster from '../../../../images/HappyEaster.jpg'
import easter from '../../../../images/Easter2024.jpg'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaHeader: {
        width: '70%',
        // borderRadius: 20,
    },
    media: {
        width: '70%',
        borderRadius: 20,
    },


}));

export default function Easter2024() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                {/* <h2 className={classes.textCentered}>Happy Easter ! ! !</h2> */}

            </header>


            <article >
                <h5 >Dear Colleagues,</h5>
                <section>
                    {/* Wishing you all the best this Easter.
                    <br /> */}
                    Happy Easter, It’s a tradition that we give Employees an Easter Egg,
                    <br />
                    your Supervisor/Manager will be distributing them in the next few days
                </section>

            </article>
            <br />
            <br />
            <br />

            <div className={classes.textCentered}>
                <img className={classes.media} src={easter} alt="Happy Easter" />
            </div>

            <h4 className={classes.textCentered}>
                Enjoy!
                <br />
                Here to the long weekend 🐣🌼🐰
            </h4>

            <br />
            <p>Regards,<br />
                Eleanor Lester<br />
                HR Co-Ordinator | Wisetek™<br />
            </p>
            <br />
        </div>
    )
}
