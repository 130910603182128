import React, { useContext, useEffect } from 'react'
import { Button, Card, CardActions, CardContent, Container, Grid, IconButton, Input, InputAdornment, List, ListItem, ListItemText, makeStyles, Modal, TextField, Tooltip, Typography } from '@material-ui/core';
import { useState } from 'react';
import { DataContext } from '../../../../contexts/DataContext';
import { db } from '../../../../config/fbConfig';
import SearchIcon from '@material-ui/icons/Search';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelIcon from '@material-ui/icons/Cancel';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles((theme) => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        '*::-webkit-scrollbar': {
            display: 'none'
        },
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        backgroundColor: '#f2f2f2',
        maxHeight: '500px',
        boxSizing: 'borderBox',
        overflow: 'auto',
        scrollbars: { display: 'none' }
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flexGrow: 1,
    },
    rightText: {
        textAlign: 'right',
        fontSize: '15px',
        fontStyle: 'italic'
    },
    modalStyle: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    addItem: {
        height: '60%',
        width: '60%',
        marginTop: '250px',
        marginLeft: 'auto',
        marginRight: 'auto',
        overflowY: 'auto'
    },
    viewItem: {
        height: '50%',
        width: '60%',
        marginTop: '250px',
        marginLeft: 'auto',
        marginRight: 'auto',
        overflowY: 'auto'
    },
    addItemForm: {
        marginTop: '50px',
    },
    cancelButton: {
        float: 'right',
        margin: 10
    },
    filePicker: {
        float: 'left',
        marginLeft: '35px',
        marginTop: '35px',
    },
    input: {
        display: 'none',
    },
    marginRight: {
        marginRight: '15px'
    },
    buttonRight: {
        // float: 'right',
        margin: 35
    },
    marginBottom: {
        marginBottom: '60px'
    },
    green: {
        color: '#048713',
        fontStyle: 'bold'
    },
    marginLeftRight: {
        width: '70%',
        marginTop: '24px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    attachments: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)'
        },
        height: '35px',
        background: '#5E89B4',
        color: 'white',
        borderRadius: '5px',
    },

}));

export default function KnowledgeBox() {
    const classes = useStyles();

    const { knowledgeBoxAdd } = useContext(DataContext);

    const [addBackdropOpen, setAddBackdropOpen] = useState(false);

    const [viewBackdropOpen, setViewBackdropOpen] = useState(false);

    const [knowledgeBox, setKnowledgeBox] = useState({
        searchValue: '',
        data: []
    })

    const [itemToAdd, setItemToAdd] = useState({
        title: '',
        description: '',
        files: [],
        filesSize: 0,
        filesToStoreInDB: [],
    })
    const [itemToView, setItemToView] = useState({
        author: '',
        description: '',
        title: '',
        files: [],
    })

    const handleAddBackdropOpen = () => {
        setAddBackdropOpen(!addBackdropOpen);
    };
    const handleAddBackdropClose = () => {
        setAddBackdropOpen(false);
        setItemToAdd({
            title: '',
            description: '',
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        })
    };
    const handleViewBackdropOpen = (card, cardIndex) => {
        // console.log(card, cardIndex)
        setItemToView({
            author: knowledgeBox.data[cardIndex].author,
            description: knowledgeBox.data[cardIndex].description,
            title: knowledgeBox.data[cardIndex].title,
            files: knowledgeBox.data[cardIndex].files,
        })
        setViewBackdropOpen(!viewBackdropOpen);
    };
    const handleViewBackdropClose = () => {
        setViewBackdropOpen(false);
    };

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...itemToAdd.files];
        var filesToStore = [...itemToAdd.filesToStoreInDB];
        [...files].forEach(file => {

            filesToStore.push(file)
            var convertResult = ''
            calculatedSize += file.size

            toBase64(file)
                .then(result => {
                    convertResult = result
                    filesToSend.push({
                        content: convertResult,
                        filename: file.name,
                        type: file.type,
                        disposition: "attachment"
                    })
                    // console.log(calculatedSize)
                    if (itemToAdd.filesSize >= maxSize || calculatedSize >= maxSize) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'Selected files are too big to attach.',
                        })
                    }
                    else {
                        if (itemToAdd.filesSize + calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                        }
                        else {
                            setItemToAdd({
                                ...itemToAdd,
                                files: filesToSend,
                                filesSize: itemToAdd.filesSize + calculatedSize,
                                filesToStoreInDB: filesToStore,
                            });
                            // console.log(filesToSend)
                        }
                    }
                })
        })
    }
    const handleChangeFilePickerRemove = (e) => {
        setItemToAdd({
            ...itemToAdd,
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
    }
    const handleChangeFormFields = (e) => {
        setItemToAdd({
            ...itemToAdd,
            [e.target.name]: e.target.value,
        })
    }

    const getAllTheKnowledge = () => {
        db.collection('knowledgeBox').orderBy('dateCreated', 'desc').get()
            .then((snap) => {
                var allKnowledge = []
                snap.forEach(doc => {
                    var docItem = doc.data();
                    docItem.id = doc.id;
                    allKnowledge.push(docItem)
                })
                setKnowledgeBox({
                    ...knowledgeBox,
                    data: allKnowledge
                })
            })
    }
    useEffect(() => {
        getAllTheKnowledge()
        return () => { }
    }, [])

    const handleChangeSearch = (e) => {
        setKnowledgeBox({
            ...knowledgeBox,
            searchValue: e.target.value
        })
    }
    const handleSearch = () => {
        var results = document.getElementsByClassName('resultItem')
        console.log(results)
        for (var i = 0; i < results.length; i++) {
            console.log(results[i].innerText)
            const isVisible = results[i].innerText.toLowerCase().includes(knowledgeBox.searchValue.toLowerCase())
            results[i].classList.toggle('hide', !isVisible)
        }
    }
    useEffect(() => {
        handleSearch()
        return () => {
        }
    }, [knowledgeBox.searchValue])

    const handleDownload = async (e, file) => {
        e.preventDefault();
        const response = await fetch(file.url);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        knowledgeBoxAdd(itemToAdd)
            .then(success => {
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                });
                handleAddBackdropClose()
            })
            .catch(error => {
                console.log(error)
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                });
            })
    }

    return (
        <div>
            <Card className={classes.heroContent}>
                <Container maxWidth="md">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        Search for knowledge
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" >
                        <Typography variant="h5" align="center" color="textSecondary" paragraph>
                            Here you can find answers for all your questions...
                        </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" paragraph>
                            “Tell me and I forget, teach me and I may remember, involve me and I learn.”
                        </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" paragraph className={classes.rightText}>
                            ~ Benjamin Franklin
                        </Typography>
                    </Typography>
                    <div className={classes.heroButtons}>
                        <Grid container spacing={5} direction="column" justifyContent="center" alignItems="center">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="default"
                                    onClick={handleAddBackdropOpen}
                                    startIcon={<AddBoxIcon />}
                                >
                                    Add new item
                                </Button>
                            </Grid>

                            <Grid item>
                                <Input
                                    type='search'
                                    placeholder="Search"
                                    value={knowledgeBox.searchValue}
                                    onChange={handleChangeSearch}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    } />
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </Card>

            <Container className={classes.cardGrid} id="scrollableContainer" maxWidth="xl" >
                <Grid container spacing={3}>
                    {knowledgeBox.data.map((card, cardIndex) => (
                        < Grid item className='resultItem' key={card.id} xl={12} lg={12} md={12} xs={12}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        {card.title}
                                    </Typography>
                                    <Typography numberoflines={1}>
                                        {card.description.length < 60 ? card.description : card.description.substring(0, 57) + '...'}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button name="view" size="small" color="primary" onClick={() => handleViewBackdropOpen(card, cardIndex)}>
                                        View
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            {/* {knowledgeBox.data.length > 0 ? <SearchComponent searchData={knowledgeBox.data} /> : null} */}

            <Modal className={classes.modalStyle} open={addBackdropOpen} onClose={handleAddBackdropClose}>
                <Card className={classes.addItem}>
                    <Tooltip title='Cancel'>
                        <IconButton className={classes.cancelButton}
                            onClick={handleAddBackdropClose}
                            color='secondary'>
                            <CancelIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                    <form className={classes.addItemForm} onSubmit={handleSubmit}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                            <Typography className={classes.marginBottom} variant="h6" align="center" paragraph>
                                Create new "KnowledgeBox" item by adding Title, Description and File.
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    helperText="(max 50 characters)"
                                    name="title"
                                    value={itemToAdd.title}
                                    onChange={handleChangeFormFields}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    name="description"
                                    fullWidth
                                    required
                                    multiline
                                    rows="6"
                                    label="Description"
                                    helperText="(max 500 characters)"
                                    variant="outlined"
                                    onChange={handleChangeFormFields}
                                    value={itemToAdd.description}
                                    inputProps={{ maxLength: 500 }}
                                />
                            </Grid>
                            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        </Grid>

                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Grid item >
                                <div className={classes.filePicker}>
                                    <input
                                        // accept="image/*"
                                        className={classes.input}
                                        id="add-contained-button-file"
                                        multiple
                                        type="file"
                                        onChange={handleChangeFilePicker}
                                    />
                                    <label htmlFor="add-contained-button-file">
                                        <Button
                                            className={classes.marginRight}
                                            variant="contained"
                                            color="default"
                                            component="span"
                                            startIcon={<AttachFileIcon />}>
                                            Add Files
                                        </Button>
                                    </label>
                                    <label htmlFor="remove-contained-button-file">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            component="span"
                                            startIcon={<DeleteOutlineIcon />}
                                            onClick={handleChangeFilePickerRemove}>
                                            Remove Files
                                        </Button>
                                    </label>
                                    {itemToAdd.files.length === 0 ? null : <p className={classes.green}>Files attached: {itemToAdd.files.length}</p>}
                                </div>
                            </Grid>

                            <Grid item>
                                <Button
                                    type="submit"
                                    className={classes.buttonRight}
                                    variant="contained"
                                    color="primary">
                                    Create new item
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
                </Card>
            </Modal>

            <Modal className={classes.modalStyle} open={viewBackdropOpen} onClose={handleViewBackdropClose} >
                <Card className={classes.viewItem}>
                    <Tooltip title='Cancel'>
                        <IconButton className={classes.cancelButton}
                            onClick={handleViewBackdropClose}
                            color='secondary'>
                            <CancelIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>

                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <TextField
                                size="small"
                                // variant="filled"       
                                disabled
                                fullWidth
                                id="viewTitle"
                                label="Title"
                                // helperText="(max 50 characters)"
                                name="viewTitle"
                                value={itemToView.title}
                                // onChange={handleChangeFormFields}
                                inputProps={{ maxLength: 50, readOnly: true }}
                            />
                        </Grid>
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <TextField
                                name="viewDescription"
                                fullWidth
                                disabled
                                multiline
                                rows="6"
                                label="Description"
                                helperText={"Author " + itemToView.author}
                                variant="outlined"
                                // onChange={handleChangeFormFields}
                                value={itemToView.description}
                                inputProps={{ maxLength: 500, readOnly: true }}
                            />
                        </Grid>
                    </Grid>

                    {itemToView.files.length === undefined || itemToView.files.length === 0 ? null :
                        <Grid container className={classes.marginLeftRight} direction="column" justifyContent="flex-start" alignItems="stretch" >
                            <Typography variant="h6" >
                                Attachments:
                            </Typography>
                            {itemToView.files.map((file, i) => (
                                <List key={i}>
                                    <Tooltip title="Download">
                                        <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                            <SaveAltIcon className={classes.marginRight} />
                                            <ListItemText primary={file.name} />
                                        </ListItem>
                                    </Tooltip>
                                </List>
                            ))}
                        </Grid>
                    }
                </Card>
            </Modal>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    )
}
