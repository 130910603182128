import React, { useContext, useState, useEffect } from 'react';
import { Container, CssBaseline, makeStyles, Snackbar } from '@material-ui/core';
import MaterialTable from 'material-table';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import SideMenu from '../SideMenus/SideMenu';
import Footer from '../../Footer'
import { db } from '../../../config/fbConfig';
import { DataContext } from '../../../contexts/DataContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Redirect } from 'react-router-dom';
import SideMenuV2 from '../SideMenus/SideMenuV2';
import Loading from '../Loading';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    myTable: {
        padding: '0px',
    },
    overrides: {
        MuiTableCell: {
            root: {
                paddingTop: '2px',
                paddingBottom: '2px',
                "&:last-child": {
                    paddingRight: '2px',
                    paddingLeft: '2px'
                }
            }
        }
    }
}));

const EngDowntimeLog = (props) => {

    const classes = useStyles();

    const { createEngDowntimeLog, updateEngDowntimeLog } = useContext(DataContext);

    const { isAuthenticated, loading, roles, displayName } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const [downtimeLog, setDowntimeLog] = useState({
        columns: [
            {
                title: 'Division', field: 'division', initialEditValue: 'DVE', lookup: {
                    'DVE': 'DVE',
                },
            },
            {
                title: 'Process', field: 'process', initialEditValue: 'Erasure', lookup: {
                    'Erasure': 'Erasure',
                    'Pre-Test': 'Pre-Test',
                    'Fru-Test': 'Fru-Test',
                    'Final Inspection': 'Final Inspection'
                }
            },
            {
                title: 'Station', field: 'station', initialEditValue: 'FC Pre/Erasure rack 1', lookup: {
                    'FC Pre/Erasure rack 1': 'FC Pre/Erasure rack 1',
                    'FC Pre/Erasure rack 2': 'FC Pre/Erasure rack 2',
                    'FC FRU Rack 1': 'FC FRU Rack 1',
                    'FC FRU Rack 2': 'FC FRU Rack 2',
                    'SAS Pre/Erasure rack 1': 'SAS Pre/Erasure rack 1',
                    'SAS Pre/Erasure rack 2': 'SAS Pre/Erasure rack 2',
                    'SAS FRU Rack 1': 'SAS FRU Rack 1',
                    'SAS FRU Rack 2': 'SAS FRU Rack 2',
                    'SAS FRU Rack 3': 'SAS FRU Rack 3',
                    'Final Inspection Station': 'Final Inspection Station'
                }
            },
            { title: 'Downtime Date', field: 'downtimeDate', type: 'date', },
            { title: 'Downtime Hours', field: 'downtimeHours', type: 'numeric', initialEditValue: 1 },
            { title: 'Downtime Description', field: 'downtimeDescription', initialEditValue: '' },
            { title: 'Eng - Root Cause Description', field: 'engRootCauseDescription', initialEditValue: '' },
            { title: 'Eng - Fix', field: 'engFix', initialEditValue: '' },
            { title: 'Raised By', field: 'raisedBy', initialEditValue: displayName, editable: 'never' },
            { title: 'Recorded Date', field: 'recordedDate', defaultSort: 'desc', initialEditValue: moment().format('DD-MMM-YYYY'), editable: 'never' },

            // {
            //     title: 'Description', field: 'Description',
            //     editComponent: props => (
            //         <TextField
            //             className={classes.minWidth}
            //             placeholder="Description"
            //             inputProps={{ maxLength: 500 }}
            //             value={props.value}
            //             onChange={e => props.onChange(e.target.value)}
            //         />
            //     )
            // },
        ],
        data: []
    })

    const allDowntimeLogs = [];
    function getAllDowntimeLogs() {
        db.collection('engDowntimeLogs').orderBy('recordedDate', 'asc').get()
            .then(snapshot => {
                snapshot.forEach(doc => {

                    allDowntimeLogs.push({
                        ...doc.data(),
                        docID: doc.id,
                        recordedDate: moment(doc.data().recordedDate.toDate()).format('DD-MMM-YYYY'),
                        downtimeDate: moment(doc.data().downtimeDate.toDate()).format('DD-MMM-YYYY'),
                    });
                })
                setDowntimeLog({
                    ...downtimeLog,
                    data: allDowntimeLogs
                })
            })
            .catch(function (error) {
                console.log("Error getting trackers data: ", error);
            });
    }

    useEffect(() => {
        getAllDowntimeLogs()

        return () => { }
    }, []);

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('memberENG') || roles.includes('adminENG') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={42} openEngColapse={true} />
                            {/* <Container component="main" className={classes.main} maxWidth="xl"> */}

                            <div className={classes.main}>
                                {/* <h1>Engineering - Downtime Log</h1> */}

                                <MaterialTable
                                    title={"Downtime Logs"}
                                    //tableRef={this.tableRef}
                                    columns={downtimeLog.columns}
                                    data={downtimeLog.data}
                                    options={{ addRowPosition: "first" }}//actionsColumnIndex: -1 }}
                                    actions={[
                                        {
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => getAllDowntimeLogs(),
                                        }
                                    ]}
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    setDowntimeLog(prevState => {
                                                        const data = [...prevState.data];
                                                        data.unshift(newData);

                                                        createEngDowntimeLog(newData)
                                                            .then(success => {
                                                                // console.log('Added new downtimeLog to ENG DB')
                                                                //display success
                                                                setSnackbar({
                                                                    open: true,
                                                                    severity: 'success',
                                                                    message: success,
                                                                })
                                                                getAllDowntimeLogs();
                                                            })
                                                            .catch(error => {
                                                                console.log(error)
                                                                //display error
                                                                reject()
                                                                setSnackbar({
                                                                    open: true,
                                                                    severity: 'error',
                                                                    message: error,
                                                                })
                                                            });
                                                        resolve();
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>

                                            new Promise(resolve => {
                                                // console.log(oldData);
                                                // console.log(newData);
                                                setTimeout(() => {
                                                    if (oldData) {
                                                        setDowntimeLog(prevState => {
                                                            const data = [...prevState.data];
                                                            newData.downtimeDate = moment(newData.downtimeDate).format('DD-MMM-YYYY')
                                                            data[data.indexOf(oldData)] = newData;

                                                            // Update data
                                                            updateEngDowntimeLog(newData)
                                                                .then(success => {
                                                                    console.log('Updated downtimeLog in ENG DB')
                                                                    //display success
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'success',
                                                                        message: success,
                                                                    })
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                    //display error
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'error',
                                                                        message: error,
                                                                    })
                                                                });
                                                            resolve();
                                                            return { ...prevState, data };
                                                        })
                                                    }
                                                }, 600)
                                            }),
                                        // onRowDelete: oldData =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             resolve();
                                        //             setDowntimeLog(prevState => {
                                        //                 const data = [...prevState.data];
                                        //                 data.splice(data.indexOf(oldData), 1);
                                        //                 return { ...prevState, data };
                                        //             });
                                        //         }, 600);
                                        //     }),
                                    }}
                                />
                            </div>

                            {/* </Container> */}
                        </CssBaseline>
                        <Footer />

                        <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}

export default EngDowntimeLog