import React from 'react';
import { makeStyles } from '@material-ui/core';
import WisetekNewsVol2part1 from '../../../../images/WisetekNewsVol2part1.JPG'
import WisetekNewsVol2part2 from '../../../../images/WisetekNewsVol2part2.JPG'
import WisetekNewsVol2part3 from '../../../../images/WisetekNewsVol2part3.JPG'
import WisetekNewsVol2part4 from '../../../../images/WisetekNewsVol2part4.JPG'
import WisetekNewsVol2part5 from '../../../../images/WisetekNewsVol2part5.JPG'
import WisetekNewsVol2part6 from '../../../../images/WisetekNewsVol2part6.JPG'
import WisetekNewsVol2part7 from '../../../../images/WisetekNewsVol2part7.JPG'
import WisetekNewsVol2part8 from '../../../../images/WisetekNewsVol2part8.JPG'
import WisetekNewsVol2part9 from '../../../../images/WisetekNewsVol2part9.JPG'
import WisetekNewsVol2part10 from '../../../../images/WisetekNewsVol2part10.JPG'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: '5%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '70%',
        borderRadius: 10,
    },
}));

export default function WisetekNewsVol2() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <article >
                <h5 >Dear Wisetek Employees,</h5>
                <section>
                    As we approach the end of 2021 which many of us will be happy to put behind us, we would like to share our second Employee Newsletter with you all as attached and below.  We have included a mix of Company information as well as some fun Puzzles, Brain Teasers and Recipes to do with Family, Friends or Colleagues.  We hope you find it a light and enjoyable read!
                </section>
                <br />
                <section>
                    We are again running a little competition for those who have a talent for drawing/colouring/taking pictures which is also open to all Wisetek family members.  Please send your entries to MichelleOS@wisetek.net
                </section>
                <br />
                <section>
                    For those not on email, copies will be available at Canteen/Reception locations or Supervisors can you please print for your Teams as needed.  I would like to give a special thanks to <b>Milica Vojnic</b> in Marketing and <b>Michelle O’Sullivan</b> in Reception in Dublin Hill, who have done a tremendous amount of work in compiling and designing this for us.
                </section>
            </article>

            <br />
            <div className={classes.textCentered}>
                <img className={classes.media} src={WisetekNewsVol2part1} alt="WisetekNewsVol2part1" />

                <img className={classes.media} src={WisetekNewsVol2part2} alt="WisetekNewsVol2part2" />

                <img className={classes.media} src={WisetekNewsVol2part3} alt="WisetekNewsVol2part3" />

                <img className={classes.media} src={WisetekNewsVol2part4} alt="WisetekNewsVol2part4" />

                <img className={classes.media} src={WisetekNewsVol2part5} alt="WisetekNewsVol2part5" />

                <img className={classes.media} src={WisetekNewsVol2part6} alt="WisetekNewsVol2part6" />

                <img className={classes.media} src={WisetekNewsVol2part7} alt="WisetekNewsVol2part7" />

                <img className={classes.media} src={WisetekNewsVol2part8} alt="WisetekNewsVol2part8" />

                <img className={classes.media} src={WisetekNewsVol2part9} alt="WisetekNewsVol2part9" />

                <img className={classes.media} src={WisetekNewsVol2part10} alt="WisetekNewsVol2part10" />
            </div>
            <br />
            <p>Happy Holidays to All and enjoy the break.  </p>
            <br />
            <p>Kind Regards,<br /><br />

                Geni D’Arcy<br />
                International Head of HR | Wisetek™
            </p>

        </div>
    )
}