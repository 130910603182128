import React, { useContext, useState } from 'react';
import logo from '../../images/wisetek_owl.png';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AuthContext } from '../../contexts/AuthContext';
import Footer from '../Footer'
import { Card } from '@material-ui/core';
import SnackbarComponent from '../customComponents/SnackbarComponent';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f0f5f4'
    },
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    card: {
        padding: '24px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function VarifyEmail() {
    const classes = useStyles();
    const { signOut, verifyEmail } = useContext(AuthContext);

    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    const sendVerificationMail = (e) => {
        e.preventDefault();

        //console.log(state)
        verifyEmail()
            .then(success => {
                //console.log(success);
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                })
            })
            .catch(error => {
                //console.log(error);
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                })
            })
    }


    return (
        <div className={classes.root}>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <div>
                        <img id="logo" src={logo} alt="Logo" />
                    </div>
                    <Typography component="h1" variant="h5">
                        Please verify your email
                    </Typography>
                </div>
                <Card className={classes.card}>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={sendVerificationMail}
                    >
                        Resend verification link
                    </Button>

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={signOut}
                    >
                        Sign out
                    </Button>


                </Card>

                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

            </Container>
            <Footer />
        </div>
    );
}