import React from 'react';
import { Button, Checkbox, FormControlLabel, Grid, makeStyles, Snackbar, TextField } from '@material-ui/core';
import { useContext } from 'react';
import { DataContext } from '../../../../contexts/DataContext';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import download from 'downloadjs';
import { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';



const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '90%',
        borderRadius: 10,
    },
    center: {
        textAlign: 'center',
        // marginBottom: '30px'
    },
    buttonRight: {
        float: 'right',
        marginTop: '35px',
    },
    buttonLeft: {
        float: 'left',
        marginTop: '35px',
    },
}));


const BackToWork = (props) => {
    const classes = useStyles();
    const { sendBackToOfficeForm } = useContext(DataContext);

    const [formData, setFormData] = useState({
        FullName: '',
        Email: '',
        Date: moment().format('DD MMMM YYYY'),
        Site: '',
        Confirm: false,
        Files: []
    })

    async function downloadForm() {
        // Fetch an existing PDF document
        const url = 'https://storage.googleapis.com/wisetekapp.appspot.com/docTemplates/HandS/WTK-OHS-CK-076%20Covid-19_Response_Plan%20TP%20(B)%20doc.pdf'
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Get the form containing all the fields
        const form = pdfDoc.getForm()

        // Get all fields in the PDF by their names
        const fName = form.getTextField('fName')
        const email = form.getTextField('email')
        const date = form.getTextField('date')
        const site = form.getTextField('site')
        const confirm = form.getCheckBox('confirm')

        if (formData.Confirm) {
            fName.setText(formData.FullName)
            email.setText(formData.Email)
            date.setText(formData.Date)
            site.setText(formData.Site)
            confirm.check()
        }

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document
        download(pdfBytes, "WTK-OHS-CK-076 Covid-19_Response_Plan TP (B) doc.pdf", "application/pdf");
    }

    async function fillAndSendForm() {
        // Fetch an existing PDF document
        const url = 'https://storage.googleapis.com/wisetekapp.appspot.com/docTemplates/HandS/WTK-OHS-CK-076%20Covid-19_Response_Plan%20TP%20(B)%20doc.pdf'
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

        // Get the form containing all the fields
        const form = pdfDoc.getForm()

        // Get all fields in the PDF by their names
        const fName = form.getTextField('fName')
        const email = form.getTextField('email')
        const date = form.getTextField('date')
        const site = form.getTextField('site')
        const confirm = form.getCheckBox('confirm')

        if (formData.Confirm) {
            fName.setText(formData.FullName)
            email.setText(formData.Email)
            date.setText(formData.Date)
            site.setText(formData.Site)
            confirm.check()
        }

        // Serialize the PDFDocument to base64
        const pdfBytes = await pdfDoc.saveAsBase64()

        var files = []
        var fileToSend = {
            content: pdfBytes,
            filename: 'WTK-OHS-CK-076 Covid-19_Response_Plan TP (B) doc.pdf',
            type: 'application/pdf',
            disposition: "attachment"
        }
        files.push(fileToSend)
        formData.Files = files
        // console.log('before state update', formData)

        sendBackToOfficeForm(formData)
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const handleChangeCheckBox = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        if (formData.Confirm) {
            fillAndSendForm()
            props.setSnackbar({
                open: false,
                severity: 'success',
                message: 'Form has been submited to H&S advisor successfully.',
            })
        }
        else {
            props.setSnackbar({
                open: false,
                severity: 'warning',
                message: 'Please tick the confirm button.',
            })
            return
        }
        setFormData({
            FullName: '',
            Email: '',
            Date: moment().format('DD MMMM YYYY'),
            Site: '',
            Confirm: false,
            Files: []
        })
    };

    return (
        <div className={classes.root}>

            <header >
                <h2 className={classes.textCentered}>Welcome Back Team Wisetek</h2>
                <h3 className={classes.textCentered}>Induction Materials</h3>

            </header>
            <br />
            <p>Please familiarise yourself with the Wisetek Covid 19 Transitional Protocol which you can download below.
                Once reviewed, <b>you are required to digitally sign the document</b> and keep to refer to at any time you may feel the need.
            </p>
            <br />

            <form id='backToWorkEmployeeForm' onSubmit={handleSubmit} autoComplete='off'>
                <h3 className={classes.center}>Employee Back to Office Form</h3>
                <br /><br />
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                    <Grid container item xs={12} sm={5} spacing={1}>
                        <TextField
                            size="small"
                            name="FullName"
                            id="FullName"
                            label="Full Name"
                            variant="outlined"
                            value={formData.FullName}
                            required
                            fullWidth
                            // autoFocus
                            // className={classes.lowerTextfield}
                            inputProps={{ maxLength: 50 }}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid container item xs={12} sm={5} spacing={1}>
                        <TextField
                            type='email'
                            size="small"
                            name="Email"
                            id="Email"
                            label="Email"
                            variant="outlined"
                            value={formData.Email}
                            required
                            fullWidth
                            // autoFocus
                            // className={classes.lowerTextfield}
                            inputProps={{ maxLength: 50 }}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid container item xs={12} sm={5} spacing={1}>
                        <TextField
                            size="small"
                            name="Date"
                            id="Date"
                            label="Date"
                            variant="outlined"
                            value={formData.Date}
                            required
                            fullWidth
                            // autoFocus
                            // className={classes.lowerTextfield}
                            inputProps={{ maxLength: 50 }}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid container item xs={12} sm={5} spacing={1}>
                        <TextField
                            size="small"
                            name="Site"
                            id="Site"
                            label="Site"
                            variant="outlined"
                            value={formData.Site}
                            required
                            fullWidth
                            // autoFocus
                            // className={classes.lowerTextfield}
                            inputProps={{ maxLength: 50 }}
                            onChange={handleChange}
                        />
                    </Grid>
                    <br /><br />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.Confirm}
                                onChange={handleChangeCheckBox}
                                name="Confirm"
                                id="Confirm"
                                color="primary"
                            />
                        }
                        label="Please tick here to confirm your understanding."
                    />


                </Grid>
                <Button
                    type="submit"
                    className={classes.buttonRight}
                    variant="contained"
                    color="primary"
                >
                    Submit form
                </Button>
                <Button variant="contained" className={classes.buttonLeft} onClick={downloadForm}>Editable PDF version</Button>
            </form>

            <br />
            <br />
            <p>Regards,<br /><br />

                Patrick O'Sullivan
                <br />
                Safety Advisor | Wisetek™
                <br />
            </p>
            <br />

        </div>
    )
}
export default BackToWork