import React from 'react'
import xmas2023 from '../../../../images/xmas2023.jpg'
import { makeStyles } from '@material-ui/core';
import ImageDisplayCarousel from '../../../customComponents/ImageDisplayCarousel';
import socialClub from '../../../../images/SocialClub.mp4'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // marginLeft: '10%',
        // marginRight: '10%',
        width: '100%',
        // backgroundImage: `url(${halloweenBackground})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        // opacity: 0.9,
        // overflow: 'hidden',
        // position: 'relative',
        // color: '#fff',
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaVideoSlide: {
        width: '100%',
        borderRadius: 20,
    },
    media: {
        width: '40%',
        borderRadius: 20,
        // border: '5px solid orange'
    },
    marginLeft: {
        marginLeft: '10%',
    }
}));

export default function Christmas2023(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header>
                <h2 className={classes.textCentered}>Christmas 2023</h2>
            </header>
            <br />

            <h3 className={classes.marginLeft}> Dear Colleagues,</h3>
            <p className={classes.marginLeft}>Wisetek wishes to invite all staff to our annual Christmas Party.</p>
            <p className={classes.marginLeft}>There will be a live DJ on the night, finger food and lots of spot prizes to be won. You need to be there to be in with the chance to win!!</p>

            <br />

            <div className={classes.textCentered}>
                <img className={classes.media} src={xmas2023} alt="Wisetek Christmas 2023" />
            </div>

            <br />
            <p className={classes.marginLeft}>We look forward to seeing you all there on the night!🎵🎄💃🕺</p>

            <br /> <br /> <br />
            <div className={classes.textCentered}>
                <video className={classes.media} src={socialClub}
                    type="video/mp4"
                    // poster={posterEHSWeek}
                    muted
                    autoPlay
                    loop
                    controls
                    controlsList="nodownload">
                    Your browser does not support HTML video.
                </video>
            </div>

            <ImageDisplayCarousel storageRefString='NewsAndAnnouncements/Christmas2023/' headerText='Here are some photos from that night...' getDataNow={props.getDataNow} />

            <p className={classes.marginLeft}>Regards,<br /><br />
                Social Club | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}
