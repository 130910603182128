import React from 'react'
import { makeStyles } from '@material-ui/core';
import christmas2022 from '../../../../images/Christmas2022.png'
import christmas2022prices from '../../../../images/Christmas2022Prices.png'
import ImageDisplayCarousel from '../../../customComponents/ImageDisplayCarousel';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '60%',
        borderRadius: 10,
        margin: 30
    },
}));

export default function Christmas2022(props) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.textCentered}>
                <img className={classes.media} src={christmas2022} alt="Wisetek Christmas 2022" />
            </div>

            <p>Hi All,</p>
            <p>We know that Christmas is still a good time away, but that doesn’t mean it’s too soon to start planning our big party. Wisetek would be honoured to have you join us at our Christmas party this year. Please join us on <b>Saturday, 17th of December,</b> from 7pm onwards. The party will be at <a href='https://www.crawfordandco.ie/' target="_blank" rel="noopener noreferrer">Crawford & Co</a>, located in the heart of Cork (10/11 Anglesea St, Cork, T12 CXN8).</p>

            <br /><br />
            <div className={classes.textCentered}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2462.148123771414!2d-8.467691253268777!3d51.894760902913745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48449111a631e207%3A0xc8697b26087e58b9!2sCrawford%20%26%20Co!5e0!3m2!1sen!2sie!4v1669134337060!5m2!1sen!2sie" width="600" height="450" style={{ border: 0 }} loading="lazy" allowFullScreen=""></iframe>
                <br /><br />
                <h4>Be prepared for amazing food, drinks, and some great prizes! Hope you will share the moment with us!</h4>
                <h4>Please mark the date in your diary!</h4>

                <img className={classes.media} src={christmas2022prices} alt="Wisetek Christmas 2022" />
            </div>


            <br /><br />
            <p>Hope everyone had a great night and came back home safe! Thank you to all that attended and made the evening a success.</p>

            <ImageDisplayCarousel storageRefString='NewsAndAnnouncements/Christmas2022/' headerText='Here are some photos of the night...' getDataNow={props.getDataNow} />

            <br /><br />
            <p>Regards,<br /><br />

                Evelyn Wiacek<br />
                HR Manager – Ireland/UK | Wisetek™<br />
            </p>
            <br />
        </div>
    )
}
