import React, { useContext, useEffect, useState } from 'react'
import { functions } from '../../../config/fbConfig';
import Loading from '../Loading';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import { AuthContext } from '../../../contexts/AuthContext';
import MapContainer from './MapContainer';

export default function DisplayTRMAs(props) {

    console.log('props:', props)

    const { auth } = useContext(AuthContext)

    const [allTRMAs, setAllTRMAs] = useState({
        data: [],
        markersData: [],
        oDataContext: null,
        oDataNextLink: null,
        isLoading: true,
        errorMessage: '',
    })

    // addMarker(map, { lat: 40.730610, lng: -73.935242 }, 'Blue'); // New York
    // addMarker(map, { lat: 53.123482, lng: 18.008438 }, 'Yellow'); // Bydgoszcz
    // addMarker(map, { lat: 51.903614, lng: -8.468399 }, 'Green'); // Cork

    const handleGettingAllTRMAs = () => {
        // filtered data with status code RMACNT003 - Submitted , RMACNT011 - Planned , RMACNT012 - Planned
        let statusCodes = ['RMACNT003', 'RMACNT011', 'RMACNT012']; // Replace with your desired status codes now its: [request submitted, Pick Up Scheduled, In Transit CD]

        // Constructing the $filter parameter with 'or' operator for multiple status codes
        let filterString = `?$filter=${statusCodes.map(code => `Status_Code eq '${code}'`).join(' or ')}`

        console.log('urlQuery: ', filterString)

        const callable = functions.httpsCallable('getAllTrmas');
        var data = { text: 'empty string', urlQuery: filterString };

        return callable(data, auth)
            .then(success => {

                let oData = JSON.parse(success.data)
                console.log('DATA:', oData.value)
                console.log('oDataContext', oData["@odata.context"])
                console.log('oDataNextLink:', oData["@odata.nextLink"])

                let markers = []
                oData.value.forEach((trma) => {
                    markers.push({ Latitude: trma.Latitude, Longitude: trma.Longitude, Status_Code: trma.Status_Code, ID: trma.ID })
                })
                console.log('markers:', markers)
                // console.log(success.data)
                setAllTRMAs({
                    ...allTRMAs,
                    data: oData.value,
                    markersData: markers,
                    oDataContext: oData["@odata.context"] || null,
                    oDataNextLink: oData["@odata.nextLink"] || null,
                    isLoading: false,
                })

                console.log(`Last successful TRMAs call was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`)
            })
            .catch(err => {
                console.log('Error GET All TRMAs...', err.message)
                console.log(`Last FAILED TRMAs call was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`, err)
                setAllTRMAs({
                    ...allTRMAs,
                    isLoading: false,
                    errorMessage: `Failed to fetch TRMAs: ${err.message}`
                })
            })
    }

    useEffect(() => {
        handleGettingAllTRMAs();
        return () => { }
    }, [])

    return (
        <div>
            <p>Display TRMAs from NAV DEV</p>

            <MapContainer markersData={allTRMAs.markersData} />

            {/* Show loading spinner if still fetching */}
            {allTRMAs.isLoading ?
                <Loading /> :
                /* Otherwise show the table with the data */
                allTRMAs.data.length > 0 ?
                    <div>
                        <TableContainer component={Paper}>
                            <p>Displaying: {allTRMAs.data.length} records.</p>

                            <Table size="small" aria-label="All TRMAs">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>TRMA #</TableCell>
                                        <TableCell align="right">Insert Date</TableCell>
                                        <TableCell align="right">Status Code</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Longitude</TableCell>
                                        <TableCell align="right">Latitude</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allTRMAs.data.map(trma => (
                                        <TableRow key={trma.ID}>
                                            <TableCell component="th" scope="row">{trma.ID}</TableCell>
                                            <TableCell align="right">{trma.Insert_Date}</TableCell>
                                            <TableCell align="right">{trma.Status_Code}</TableCell>
                                            <TableCell align="right">{trma.Status_Text}</TableCell>
                                            <TableCell align="right">{trma.Longitude}</TableCell>
                                            <TableCell align="right">{trma.Latitude}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <p>got it:{`context: ${allTRMAs.oDataContext}, nextLink: ${allTRMAs.oDataNextLink}, DATA: ${allTRMAs.data},`}</p>
                    </div>
                    :
                    <div>{allTRMAs.errorMessage}</div>
            }
        </div>
    )
}
