import React, { useState, useContext, useEffect } from 'react'
import { auth, db, functions } from '../../../config/fbConfig';
import { AuthContext } from '../../../contexts/AuthContext';
import { Card, makeStyles, CssBaseline } from '@material-ui/core'
import MaterialTable from 'material-table';
import moment from 'moment';
import { DataContext } from '../../../contexts/DataContext';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    leftMargin: {
        marginLeft: '30px',
    },
}));

export const HolidayRequestAll = (props) => {
    const classes = useStyles();

    const { displayName } = useContext(AuthContext);
    const { findApplicantAndUpdateHolidays } = useContext(DataContext);

    const [allEmployeesRequests, setAllEmployeesRequests] = useState({
        columns: [
            { title: 'Name', field: 'FullName', editable: 'never' },
            // { title: 'Available Days', field: 'AvailableDays' },
            // { title: 'All Approved Days', field: 'AccumulatedApprovedDays', editable: 'never' },
            { title: 'Days Req', field: 'TotalDaysRequested', editable: 'never' },
            { title: 'Hours Req', field: 'HoursRequested', editable: 'never' },
            { title: 'From Date', field: 'FromDate', editable: 'never' },
            { title: 'To Date', field: 'ToDate', editable: 'never' },
            {
                title: 'Status', field: 'Status', lookup: {
                    // '': '',
                    'Pending': 'Pending',
                    'Approved': 'Approved',
                    'Declined': 'Declined',
                    'Cancelled': 'Cancelled',
                }
            },
            { title: 'Manager Comment', field: 'ResponseComment' },
            { title: 'Manager', field: 'Manager', editable: 'never' },
            { title: 'Requested On Date', field: 'DateOfRequest', editable: 'never' },
            { title: 'Department', field: 'Department', editable: 'never' },
            { title: 'Users Comment', field: 'AdditionalComment', editable: 'never' },
            { title: 'Shift Worker', field: 'ShiftWorker', editable: 'never' },
            { title: 'Unpaid Leave', field: 'UnpaidLeave', editable: 'never' },
        ],
        data: [],
    });
    const [holidayEntitlements, setHolidayEntitlements] = useState({
        columns: [
            { title: 'Name', field: 'firstName', editable: 'never' },
            { title: 'Surname', field: 'lastName', editable: 'never' },
            { title: 'Department', field: 'department', editable: 'never' },
            { title: 'Location', field: 'location', editable: 'never' },
            { title: 'Approving Manager', field: 'holidayApprovingManager', editable: 'never' },
            { title: 'Total Days Available', field: 'availableDays', type: 'numeric' },
            // { title: 'Current Year / Days Approved', field: 'accumulatedApprovedDays', type: 'numeric', editable: 'never' },
            // { title: 'Current Year / Days Remaining', field: 'accumulatedRemainingDays', type: 'numeric', editable: 'never' },
            { title: 'Current Year / Days Approved', field: 'currentYearApprovedDays', type: 'numeric', editable: 'never' },
            { title: 'Current Year / Days Remaining', field: 'currentYearRemainingDays', type: 'numeric', editable: 'never' },
        ],
        data: []
    })


    function getUsersAndHolidayRequests() {
        var employeesEntitlements = [];
        var calculatedEmployeesEntitlements = [];
        var employeesRequests = [];

        db.collection("holidayRequests")
            .orderBy('DateOfRequest', 'desc')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    employeesRequests.push({
                        ...doc.data(),
                        FromDate: moment(doc.data().FromDate.toDate()).format('DD MMMM YYYY'),
                        ToDate: moment(doc.data().ToDate.toDate()).format('DD MMMM YYYY'),
                        DateOfRequest: moment(doc.data().DateOfRequest.toDate()).format('DD MMMM YYYY'),
                        requestId: doc.id,
                    });
                })
                db.collection('users').orderBy('firstName').get()
                    .then(snapshot => {
                        snapshot.forEach(doc => {
                            employeesEntitlements.push({
                                ...doc.data(),
                                userId: doc.id,
                            })
                        })
                        calculateCurrentYearDaysApproved(employeesEntitlements, employeesRequests)

                        setAllEmployeesRequests({
                            ...allEmployeesRequests,
                            data: employeesRequests
                        })
                    })
                    .catch(function (error) {
                        console.log("Error getting employees entitlements: ", error);
                    });
            })
            .catch(function (error) {
                console.log("Error getting all team holiday requests: ", error);
            });

    }

    function getAllHolidayRequests() {
        var employeesRequests = []
        db.collection("holidayRequests")
            // .where("DateOfRequest", ">=", yearStart)
            // .where("DateOfRequest", "<=", yearEnd)
            .orderBy('DateOfRequest', 'desc')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    employeesRequests.push({
                        ...doc.data(),
                        FromDate: moment(doc.data().FromDate.toDate()).format('DD MMMM YYYY'),
                        ToDate: moment(doc.data().ToDate.toDate()).format('DD MMMM YYYY'),
                        DateOfRequest: moment(doc.data().DateOfRequest.toDate()).format('DD MMMM YYYY'),
                        requestId: doc.id,
                    });
                })
                setAllEmployeesRequests({
                    ...allEmployeesRequests,
                    data: employeesRequests
                })
            })
            .catch(function (error) {
                console.log("Error getting all team holiday requests: ", error);
            });
    }

    function getAllUsersEntitlements() {
        var employeesEntitlements = []
        db.collection('users').get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    employeesEntitlements.push({
                        ...doc.data(),
                        userId: doc.id,
                    })
                })
                setHolidayEntitlements({
                    ...holidayEntitlements,
                    data: employeesEntitlements
                })
            })
            .catch(function (error) {
                console.log("Error getting employees entitlements: ", error);
            });
    }

    // useEffect(() => {
    //     getAllHolidayRequests()
    //     return () => { }
    // }, []);

    // useEffect(() => {
    //     getAllUsersEntitlements()
    //     return () => { }
    // }, []);
    useEffect(() => {
        getUsersAndHolidayRequests()

        return () => {
        }
    }, [])


    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function sendEmailToApplicantApproved(data) {
        const callable = functions.httpsCallable('sendHolidayEmailToApplicantApproved');
        console.log('NEW VERSION: 002');
        return callable(data, auth)
    };
    function sendEmailToApplicantRefused(data) {
        const callable = functions.httpsCallable('sendHolidayEmailToApplicantRefused');
        console.log('NEW VERSION: 002');
        return callable(data, auth)
            .then(
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'User was notified about the status change of this request.',
                })
            )
    };
    function sendEmailToApplicantCancelled(data) {
        const callable = functions.httpsCallable('sendHolidayEmailToApplicantCancelled');
        console.log('NEW VERSION: 002');
        return callable(data, auth)
            .then(
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'User was notified about the cancellation of this request.',
                })
            )
    };
    function sendEmailToHR(data) {
        const callable = functions.httpsCallable('sendHolidayEmailToHR');
        console.log('NEW VERSION: 002');
        return callable(data, auth)
            .then(
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'HR department email sent successfully, user was also notified about this approval.',
                })
            )
    };
    function sendEmailToHRCancelled(data) {
        const callable = functions.httpsCallable('sendHolidayEmailToHRCancelled');
        console.log('NEW VERSION: 002');
        return callable(data, auth)
            .then(
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'HR department email sent successfully, user was also notified about this cancellation.',
                })
            )
    };

    function calculateCurrentYearDaysApproved(users, requests) {
        var newEmployeesEntitlements = []
        // console.log(users, requests)
        if (requests.length > 0 && users.length > 0) {

            //get current year holidays approved and calculate total for each UserId
            if (users.length > 0) {
                users.forEach(userEntitlement => {
                    var days = 0
                    var hours = 0
                    var daysPlusHours = 0
                    requests.forEach(userRequest => {

                        if (userEntitlement.userId === userRequest.UserId) {
                            var currentYearStart = moment().startOf('year')
                            var currentYearEnd = moment().endOf('year')
                            // console.log('START', currentYearStart, 'END', currentYearEnd)
                            if (moment(userRequest.FromDate).isBetween(currentYearStart, currentYearEnd)) {
                                days += userRequest.TotalDaysRequested
                                hours += userRequest.HoursRequested
                            }
                            else {
                                if (moment(userRequest.ToDate).isBetween(currentYearStart, currentYearEnd)) {
                                    var partOfHolidaysDays = moment(userRequest.ToDate).diff(currentYearStart, 'days') + 1// including 1st Jan
                                    // console.log(partOfHolidaysDays)

                                    for (let i = 0; i < partOfHolidaysDays; i++) {
                                        var startDate = moment().startOf('year')
                                        startDate.add(i, 'days')
                                        // console.log(startDate.isoWeekday(), startDate.format('DD MMMM YYYY'))

                                        if (startDate.isoWeekday() === 6 || startDate.isoWeekday() === 7 || startDate.isSame(moment().startOf('year'))) {
                                            partOfHolidaysDays -= 1
                                        }

                                    }
                                    days += partOfHolidaysDays
                                    // console.log('started before new year:', userRequest)
                                    // console.log(userEntitlement.firstName + ' ' + userEntitlement.lastName, 'had', days, 'days in', moment().year())
                                }
                            }
                        }
                    })
                    // console.log(userEntitlement.firstName, days)
                    daysPlusHours = parseFloat((days + (hours / 8)).toFixed(2))
                    userEntitlement.currentYearApprovedDays = daysPlusHours
                    userEntitlement.currentYearRemainingDays = parseFloat((userEntitlement.availableDays - daysPlusHours).toFixed(2))
                    newEmployeesEntitlements.push(userEntitlement)
                })
                setHolidayEntitlements({
                    ...holidayEntitlements,
                    data: newEmployeesEntitlements
                })
            }
        }
    }

    return (
        <div >
            <CssBaseline >

                <Card>
                    <p className={classes.leftMargin}>Hello {displayName} here you can view all requests for all Wisetek employees.</p>
                    <MaterialTable
                        id="allEmpHolidays"
                        title={"All Employees Holiday Requests"}
                        columns={allEmployeesRequests.columns}
                        data={allEmployeesRequests.data}
                        // onFilterChange={(e) => { console.log(e) }}
                        options={{ exportButton: true, filtering: true, pageSizeOptions: [5, 15, 50, { value: allEmployeesRequests.data.length, label: 'All' }] }}
                        actions={[
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    getAllHolidayRequests()
                                    return () => { }
                                },
                            }
                        ]}
                        editable={{
                            // onRowAdd: newData =>
                            //     new Promise(resolve => {
                            //         setTimeout(() => {
                            //             resolve();
                            //             setIssueError(prevState => {
                            //                 const data = [...prevState.data];
                            //                 data.push(newData);
                            //                 return { ...prevState, data };
                            //             });
                            //         }, 600);
                            //     }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        if (oldData) {
                                            setAllEmployeesRequests(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;

                                                // Update data
                                                db.collection("holidayRequests").doc(newData.requestId).update({
                                                    Status: newData.Status,
                                                    ResponseComment: newData.ResponseComment,
                                                    ResponseDate: new Date(),
                                                    WasRequestApproved: newData.Status == 'Approved' ? true : false,
                                                })
                                                    .then(() => {

                                                        switch (newData.Status) {
                                                            case 'Approved':
                                                                // find applicant in users by his email and update holiday entitlements
                                                                // deduct TotalDaysRequested from availableDays
                                                                // send email to applicant - approved and email to HR
                                                                findApplicantAndUpdateHolidays(newData)
                                                                sendEmailToApplicantApproved(newData);
                                                                sendEmailToHR(newData);
                                                                break;

                                                            case 'Declined':
                                                                // send email to applicant - refused
                                                                sendEmailToApplicantRefused(newData);
                                                                break;

                                                            case 'Cancelled':
                                                                // send email to applicant and HR
                                                                // add canceled holidays(TotalDaysRequested) to availableDays
                                                                findApplicantAndUpdateHolidays(newData);
                                                                sendEmailToApplicantCancelled(newData);
                                                                sendEmailToHRCancelled(newData);
                                                                break;

                                                            default:
                                                                break;
                                                        }
                                                    })
                                                console.log('Updated holiday request.')
                                                resolve();
                                                return { ...prevState, data };
                                            })
                                        }
                                    }, 600)
                                }),
                        }}
                    />



                </Card>

                <br />

                <Card>
                    <MaterialTable
                        title={"Employees holiday leave entitlements"}
                        columns={holidayEntitlements.columns}
                        data={holidayEntitlements.data}
                        options={{ exportButton: true, filtering: true, pageSize: 15, pageSizeOptions: [5, 15, 50, { value: holidayEntitlements.data.length, label: 'All' }] }}
                        actions={[
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    getUsersAndHolidayRequests()
                                    return () => { }
                                },
                            }
                        ]}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        if (oldData) {
                                            setHolidayEntitlements(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                return { ...prevState, data };
                                            });
                                        }
                                    }, 600);
                                    // Update data
                                    db.collection("users").doc(newData.userId).update({
                                        availableDays: parseFloat(newData.availableDays),
                                        // accumulatedApprovedDays: parseFloat(newData.accumulatedApprovedDays),
                                        // accumulatedRemainingDays: parseFloat(newData.accumulatedRemainingDays),
                                    });
                                    getUsersAndHolidayRequests();
                                    resolve();
                                }),
                        }}
                    />
                </Card>

            </CssBaseline>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    )
}