import React, { useContext, useState } from 'react';
import connectW from '../../../images/ConnectWisetekNet.png'
import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from '../../Footer'
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { Card, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Coronavirus from './announcements/Coronavirus';
import WomensDay2021 from './announcements/WomensDay2021';
import Coronavirus1 from './announcements/Coronavirus1';
import TemporaryWFH from './announcements/TemporaryWFH';
import BusinessUpdate from './announcements/BusinessUpdate';
import WisetekNewsVol1 from './announcements/WisetekNewsVol1';
import CookingDemo from './announcements/CookingDemo';
import BusinessUpdateVol2 from './announcements/BusinessUpdateVol2';
import WisetekStoreLive from './announcements/WisetekStoreLive';
import BusinessUpdateVol3 from './announcements/BusinessUpdateVol3';
import WisetekNewsVol2 from './announcements/WisetekNewsVol2';
import BackToWork from './announcements/BackToWork';
import BenefitNightUkraine from './announcements/BenefitNightUkraine';
import BBQ2022 from './announcements/BBQ2022';
import BusinessUpdateVol4 from './announcements/BusinessUpdateVol4';
import SideMenuV2 from '../SideMenus/SideMenuV2';
import EHSWeek from './announcements/EHSWeek';
import SideMenuSignOutOnlyV2 from '../SideMenus/SideMenuSignOutOnlyV2';
import Loading from '../Loading';
import MarinaMarketBooking from './announcements/MarinaMarket/MarinaMarketBooking';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import Movember2022 from './announcements/Movember2022';
import Christmas2022 from './announcements/Christmas2022';
import Movember2022Vote from './announcements/Movember2022Vote';
import BusinessUpdateVol5 from './announcements/BusinessUpdateVol5';
import Quarter42022 from './announcements/WiseStarsWinners/Quarter42022';
import WomensDay2023 from './announcements/WomensDay2023';
import StPatricks2023 from './announcements/StPatricks2023';
import Easter2023 from './announcements/Easter2023';
import BBQ2023 from './announcements/BBQ2023';
import EHSWeek2023 from './announcements/EHSWeek2023';
import Halloween2023 from './announcements/Halloween2023';
import Christmas2023 from './announcements/Christmas2023';
import WomensDay2024 from './announcements/WomensDay2024';
import Healthcare2024 from './announcements/Healthcare2024';
import Easter2024 from './announcements/Easter2024';
import CoffeeMorning2024 from './announcements/CoffeeMorning2024';
import Careers20240409 from './announcements/Careers20240409';
import WellbeingDay2024 from './announcements/WellbeingDay2024';
import BBQ2024 from './announcements/BBQ2024';
import HealthyHeartDay2024 from './announcements/HealthyHeartDay2024';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        //marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    dashboardCard: {
        marginLeft: '5%',
        marginRight: '5%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    noPadding: {
        padding: 0,
    },
    yellow: {
        backgroundColor: 'yellow',
    },
    noPaddingStPatricks: {
        padding: 0,
        backgroundColor: 'lightgreen',
    },
    stPatricksGreen: {
        backgroundColor: 'lightgreen',
    },
}));

export default function Home() {
    const classes = useStyles();
    const { isAuthenticated, firstName, loading, roles, uid } = useContext(AuthContext);

    const [expanded, setExpanded] = React.useState('panel37a');
    // const [expanded, setExpanded] = React.useState('');

    const [stopVideo, setStopVideo] = useState(false)

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    function handleOpen(snackbar) {
        setSnackbar({
            open: true,
            severity: snackbar.severity,
            message: snackbar.message,
        })
    }

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        // console.log(panel)
        // console.log(isExpanded)
        setExpanded(isExpanded ? panel : false);
        setStopVideo(true)
    };

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('viewAccAcademyExternal')) {
                return <Redirect to='/wiseAcademy' />
            }
            else {
                return (
                    <div className={classes.root}>
                        <CssBaseline >
                            {uid === 'KN7wpe3VQ2NCA4iTLN4KBvRxtos1' || uid === '7blfoccwt2Pd11d2vewWoR2WHJo2' ? <SideMenuSignOutOnlyV2 /> : <SideMenuV2 selectedIndex={0} />}
                            {/* <Container component="main" className={classes.main} maxWidth="sm">
                        <div >
                        <img id="logoW" src={logoW} alt="Logo" />
                        </div>
                    </Container> */}
                            <h4 id="helloName">Hello {firstName}, welcome to</h4>
                            <div>
                                <img id="connectW" src={connectW} alt="connect logo" />
                            </div>

                            <br /><br />
                            <h3 id="sectionTitle">News & Announcements</h3>

                            <Card className={classes.dashboardCard}>

                                <Accordion id='panel37a' expanded={expanded === 'panel37a'} onChange={handleChangeAccordion('panel37a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel37a-content"
                                        id="panel37a-header"
                                    >
                                        Healthy Heart Day 2024
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <HealthyHeartDay2024 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel36a' expanded={expanded === 'panel36a'} onChange={handleChangeAccordion('panel36a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel36a-content"
                                        id="panel36a-header"
                                    >
                                        Annual Summer BBQ 2024
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BBQ2024 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel35a' expanded={expanded === 'panel35a'} onChange={handleChangeAccordion('panel35a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel35a-content"
                                        id="panel35a-header"
                                    >
                                        National Workplace Wellbeing Day - 26ᵗʰ April
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <WellbeingDay2024 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel34a' expanded={expanded === 'panel34a'} onChange={handleChangeAccordion('panel34a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel34a-content"
                                        id="panel34a-header"
                                    >
                                        Wisetek - Career opportunities
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Careers20240409 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel33a' expanded={expanded === 'panel33a'} onChange={handleChangeAccordion('panel33a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel33a-content"
                                        id="panel33a-header"
                                    >
                                        Support Women's Aid Charity
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <CoffeeMorning2024 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel32a' expanded={expanded === 'panel32a'} onChange={handleChangeAccordion('panel32a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel32a-content"
                                        id="panel32a-header"
                                    >
                                        🐣🌼🐰 Happy Easter 2024! 🐣🌼🐰
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Easter2024 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel31a' expanded={expanded === 'panel31a'} onChange={handleChangeAccordion('panel31a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel31a-content"
                                        id="panel31a-header"
                                    >
                                        Laya Healthcare available for all Healthcare Plans
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Healthcare2024 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel30a' expanded={expanded === 'panel30a'} onChange={handleChangeAccordion('panel30a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel30a-content"
                                        id="panel30a-header"
                                    >
                                        💃 International Women's Day 2024 campaign to support Women's Aid Charity 💃
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <WomensDay2024 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel29a' expanded={expanded === 'panel29a'} onChange={handleChangeAccordion('panel29a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel29a-content"
                                        id="panel29a-header"
                                    >
                                        ☾⋆⁺₊🎵🎄💃🕺  Christmas 2023 ☾⋆⁺₊🎵🎄💃🕺
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Christmas2023 getDataNow={expanded === 'panel29a'} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel28a' expanded={expanded === 'panel28a'} onChange={handleChangeAccordion('panel28a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel28a-content"
                                        id="panel28a-header"
                                    >
                                        ☾⋆⁺₊👻🎃🦇´₊⁺~ Halloween - Pumpkin Painting ☾⋆⁺₊🎃👻🦇´₊⁺~
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Halloween2023 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel27a' expanded={expanded === 'panel27a'} onChange={handleChangeAccordion('panel27a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel27a-content"
                                        id="panel27a-header"
                                    >
                                        Environmental Health & Safety Week 2023
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <EHSWeek2023 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel26a' expanded={expanded === 'panel26a'} onChange={handleChangeAccordion('panel26a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel26a-content"
                                        id="panel26a-header"
                                    >
                                        Annual Summer BBQ 2023
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BBQ2023 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel25a' expanded={expanded === 'panel25a'} onChange={handleChangeAccordion('panel25a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel25a-content"
                                        id="panel25a-header"
                                    >
                                        🥚.•*¨`*•.¸🐰..🐰¸.•*¨`*•.🥚 Happy Easter 2023 🥚.•*¨`*•.¸🐰..🐰¸.•*¨`*•.🥚
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Easter2023 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel24a' expanded={expanded === 'panel24a'} onChange={handleChangeAccordion('panel24a')}>
                                    <AccordionSummary className={classes.stPatricksGreen}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel24a-content"
                                        id="panel24a-header"
                                    >
                                        ☘️ ☘️ ☘️ Happy St. Patrick's Day 2023 ☘️ ☘️ ☘️
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPaddingStPatricks}>

                                        <StPatricks2023 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel23a' expanded={expanded === 'panel23a'} onChange={handleChangeAccordion('panel23a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel23a-content"
                                        id="panel23a-header"
                                    >
                                        International Women's Day 2023
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <WomensDay2023 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel22a' expanded={expanded === 'panel22a'} onChange={handleChangeAccordion('panel22a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel22a-content"
                                        id="panel22a-header"
                                    >
                                        ⭐⭐⭐ WiseStars Winners for Quarter 4 of 2022 ⭐⭐⭐
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Quarter42022 getDataNow={expanded === 'panel22a'} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel21a' expanded={expanded === 'panel21a'} onChange={handleChangeAccordion('panel21a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel21a-content"
                                        id="panel21a-header"
                                    >
                                        Recording of Employee Business Update 21ˢᵗ December 2022
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BusinessUpdateVol5 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel20a' expanded={expanded === 'panel20a'} onChange={handleChangeAccordion('panel20a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel20a-content"
                                        id="panel20a-header"
                                    >
                                        Wisetek Christmas Party - 17ᵗʰ December 2022
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Christmas2022 getDataNow={expanded === 'panel20a'} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel19a' expanded={expanded === 'panel19a'} onChange={handleChangeAccordion('panel19a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel19a-content"
                                        id="panel19a-header"
                                    >
                                        Movember 2022 - Vote now!
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Movember2022Vote />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel18a' expanded={expanded === 'panel18a'} onChange={handleChangeAccordion('panel18a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel18a-content"
                                        id="panel18a-header"
                                    >
                                        Wisetek Movember is back - November 2022
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Movember2022 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel17a' expanded={expanded === 'panel17a'} onChange={handleChangeAccordion('panel17a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel17a-content"
                                        id="panel17a-header"
                                    >
                                        Wisetek on Marina Market - 5ᵗʰ November - 17ᵗʰ December 2022
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <MarinaMarketBooking />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel16a' expanded={expanded === 'panel16a'} onChange={handleChangeAccordion('panel16a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel16a-content"
                                        id="panel16a-header"
                                    >
                                        EHS Week - 14ᵗʰ - 16ᵗʰ September 2022
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <EHSWeek getDataNow={expanded === 'panel16a'} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel15a' expanded={expanded === 'panel15a'} onChange={handleChangeAccordion('panel15a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel15a-content"
                                        id="panel15a-header"
                                    >
                                        Recording of Employee Business Update 24ᵗʰ August 2022
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BusinessUpdateVol4 stopVideo={stopVideo} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel14a' expanded={expanded === 'panel14a'} onChange={handleChangeAccordion('panel14a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel14a-content"
                                        id="panel14a-header"
                                    >
                                        Annual Summer BBQ 2022
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BBQ2022 getDataNow={expanded === 'panel14a'} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel13a' expanded={expanded === 'panel13a'} onChange={handleChangeAccordion('panel13a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel13a-content"
                                        id="panel13a-header"
                                    >
                                        Wisetek Staff - Benefit Night for Ukraine
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BenefitNightUkraine getDataNow={expanded === 'panel13a'} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel12a' expanded={expanded === 'panel12a'} onChange={handleChangeAccordion('panel12a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel12a-content"
                                        id="panel12a-header"
                                    >
                                        Welcome Back Team Wisetek – Induction Materials
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BackToWork setSnackbar={handleOpen} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel11a' expanded={expanded === 'panel11a'} onChange={handleChangeAccordion('panel11a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel11a-content"
                                        id="panel11a-header"
                                    >
                                        Wisetek News Volume 2 | December 2021
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <WisetekNewsVol2 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel10a' expanded={expanded === 'panel10a'} onChange={handleChangeAccordion('panel10a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10a-content"
                                        id="panel10a-header"
                                    >
                                        Recording of Employee Business Update 14ᵗʰ October 2021
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BusinessUpdateVol3 stopVideo={stopVideo} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel9a' expanded={expanded === 'panel9a'} onChange={handleChangeAccordion('panel9a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel9a-content"
                                        id="panel9a-header"
                                    >
                                        WisetekStore.com is LIVE! 4ᵗʰ October 2021
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <WisetekStoreLive />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel8a' expanded={expanded === 'panel8a'} onChange={handleChangeAccordion('panel8a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel8a-content"
                                        id="panel8a-header"
                                    >
                                        Recording of Employee Business Update 28ᵗʰ April 2021
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BusinessUpdateVol2 stopVideo={stopVideo} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel7a' expanded={expanded === 'panel7a'} onChange={handleChangeAccordion('panel7a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel7a-content"
                                        id="panel7a-header"
                                    >
                                        Recording of Cooking Demo April 2021
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <CookingDemo stopVideo={stopVideo} />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel6a' expanded={expanded === 'panel6a'} onChange={handleChangeAccordion('panel6a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6a-content"
                                        id="panel6a-header"
                                    >
                                        International Women's Day 2021
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <WomensDay2021 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel5a' expanded={expanded === 'panel5a'} onChange={handleChangeAccordion('panel5a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5a-content"
                                        id="panel5a-header"
                                    >
                                        Wisetek News Volume 1 | December 2020
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <WisetekNewsVol1 />

                                    </AccordionDetails>
                                </Accordion>


                                <Accordion id='panel4a' expanded={expanded === 'panel4a'} onChange={handleChangeAccordion('panel4a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        Recording of Employee Business Update 1ˢᵗ December 2020
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <BusinessUpdate stopVideo={stopVideo} />

                                    </AccordionDetails>
                                </Accordion>


                                <Accordion id='panel3a' expanded={expanded === 'panel3a'} onChange={handleChangeAccordion('panel3a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        'Work From Home' Policy
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <TemporaryWFH />

                                    </AccordionDetails>
                                </Accordion>


                                <Accordion id='panel2a' expanded={expanded === 'panel2a'} onChange={handleChangeAccordion('panel2a')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        Coronavirus COVID-19 Update...
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Coronavirus1 />

                                    </AccordionDetails>
                                </Accordion>

                                <Accordion id='panel1a' expanded={expanded === 'panel1a'} onChange={handleChangeAccordion('panel1a')}>
                                    <AccordionSummary className={classes.yellow}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        Coronavirus COVID-19
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>

                                        <Coronavirus />

                                    </AccordionDetails>
                                </Accordion>

                                {/* <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel1a-header"
                            >
                                International Women's Day
                           </AccordionSummary>
                            <AccordionDetails className={classes.noPadding}>

                                <WomensDay />

                            </AccordionDetails>
                        </Accordion> */}

                            </Card>

                            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

                        </CssBaseline>
                        <Footer />
                    </div>
                );
            }

        }
    }
}
