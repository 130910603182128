import { Chip, CssBaseline, LinearProgress, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import { db } from '../../../../config/fbConfig';
import MaterialTable, { MTableToolbar } from 'material-table';
import { DataContext } from '../../../../contexts/DataContext';
import moment from 'moment';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
    },
}));

export default function MetricsChainOfCustodyDealIntegrity(props) {
    const classes = useStyles();

    const { addDealIntegrity, updateDealIntegrity, deleteDealIntegrity } = useContext(DataContext);
    const { isAuthenticated, loading, roles } = useContext(AuthContext);
    const todaysDate = new Date();
    const [dealIntegrity, setDealIntegrity] = useState({
        columns: [
            { title: 'Date', field: 'dateCreated', type: 'date', filtering: false, initialEditValue: todaysDate },
            { title: 'Authorised', field: 'AuthorisedBOS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'BA Required', field: 'BaReqBOS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'BA Record', field: 'BaRecBOS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Pallet Closed', field: 'PalletClosedBOS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'TA Rquired', field: 'TaReqBOS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'TA Output Correct', field: 'TaOutBOS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Waybill Compliant', field: 'WaybilCompBOS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Authorised', field: 'AuthorisedMD', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'BA Required', field: 'BaReqMD', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'BA Record', field: 'BaRecMD', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Pallet Closed', field: 'PalletClosedMD', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'TA Rquired', field: 'TaReqMD', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'TA Output Correct', field: 'TaOutMD', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Waybill Compliant', field: 'WaybilCompMD', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Authorised', field: 'AuthorisedSAC', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'BA Required', field: 'BaReqSAC', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'BA Record', field: 'BaRecSAC', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'Pallet Closed', field: 'PalletClosedSAC', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'TA Rquired', field: 'TaReqSAC', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'TA Output Correct', field: 'TaOutSAC', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'Waybill Compliant', field: 'WaybilCompSAC', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'Authorised', field: 'AuthorisedTX', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'BA Required', field: 'BaReqTX', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'BA Record', field: 'BaRecTX', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'Pallet Closed', field: 'PalletClosedTX', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'TA Rquired', field: 'TaReqTX', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'TA Output Correct', field: 'TaOutTX', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'Waybill Compliant', field: 'WaybilCompTX', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'Authorised', field: 'AuthorisedVA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(110, 110, 110, 0.2)' } },
            { title: 'BA Required', field: 'BaReqVA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(110, 110, 110, 0.2)' } },
            { title: 'BA Record', field: 'BaRecVA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(110, 110, 110, 0.2)' } },
            { title: 'Pallet Closed', field: 'PalletClosedVA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(110, 110, 110, 0.2)' } },
            { title: 'TA Rquired', field: 'TaReqVA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(110, 110, 110, 0.2)' } },
            { title: 'TA Output Correct', field: 'TaOutVA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(110, 110, 110, 0.2)' } },
            { title: 'Waybill Compliant', field: 'WaybilCompVA', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(110, 110, 110, 0.2)' } },
            { title: 'Authorised', field: 'AuthorisedUStotal', type: 'numeric', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(130, 10, 200, 0.2)' } },
            { title: 'BA Required', field: 'BaReqUStotal', type: 'numeric', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(130, 10, 200, 0.2)' } },
            { title: 'BA Record', field: 'BaRecUStotal', type: 'numeric', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(130, 10, 200, 0.2)' } },
            { title: 'Pallet Closed', field: 'PalletClosedUStotal', type: 'numeric', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(130, 10, 200, 0.2)' } },
            { title: 'TA Rquired', field: 'TaReqUStotal', type: 'numeric', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(130, 10, 200, 0.2)' } },
            { title: 'TA Output Correct', field: 'TaOutUStotal', type: 'numeric', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(130, 10, 200, 0.2)' } },
            { title: 'Waybill Compliant', field: 'WaybilCompUStotal', type: 'numeric', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(130, 10, 200, 0.2)' } },
        ],
        data: []
    })
    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function calcWeeklyVars(data) {
        var dealIntegrityData = [];
        // var verificationWipLabels = [];

        // var verWipIE = [
        //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//totalIE
        //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //discrepantIE
        // ]
        // var verWipUS = [
        //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//totalUS
        //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //discrepantUS
        // ]
        // var verWipUK = [
        //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//totalUK
        //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //discrepantUK
        // ]
        // var verWipPercent = [
        //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//compliantPercIE
        //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//compliantPercUS
        //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //compliantPercUK
        // ]

        data.forEach(doc => {
            // console.log(doc.id)
            var row = doc.data()
            row.id = doc.id
            // row.compliantPercentIE = doc.data().compliantPercIE + '%'
            // row.compliantPercentUS = doc.data().compliantPercUS + '%'
            // row.compliantPercentUK = doc.data().compliantPercUK + '%'
            row.dateCreated = moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY')

            dealIntegrityData.push(row)
            // verificationWipLabels.push(moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY'))
        })
        // var labelsLength = verificationWipLabels.length
        // if (labelsLength < 13) {
        //     // console.log('We have ' + labelsLength + ' labels')
        //     var noLabel = 'n/a'
        //     var calculatedLabels = []
        //     for (let i = labelsLength; i < 13; i++) {
        //         calculatedLabels.push(noLabel)
        //     }
        //     for (let j = 0; j < labelsLength; j++) {
        //         calculatedLabels.push(verificationWipLabels.pop())
        //     }
        // }
        // else {
        //     calculatedLabels = verificationWipLabels.reverse()
        // }
        // data.forEach(doc => {
        //     var positionDate = calculatedLabels.findIndex(dateCreated => dateCreated === moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY'))

        //     if (positionDate >= 0) {
        //         var totalIRELAND = doc.data().totalIE
        //         var discrepantIRELAND = doc.data().discrepantIE
        //         var totalUSA = doc.data().totalUS
        //         var discrepantUSA = doc.data().discrepantUS
        //         var totalUNITEDKINGDOM = doc.data().totalUK
        //         var discrepantUNITEDKINGDOM = doc.data().discrepantUK
        //         var percCompIE = doc.data().compliantPercIE
        //         var percCompUS = doc.data().compliantPercUS
        //         var percCompUK = doc.data().compliantPercUK
        //         verWipIE[0][positionDate] = totalIRELAND
        //         verWipIE[1][positionDate] = discrepantIRELAND
        //         verWipUS[0][positionDate] = totalUSA
        //         verWipUS[1][positionDate] = discrepantUSA
        //         verWipUK[0][positionDate] = totalUNITEDKINGDOM
        //         verWipUK[1][positionDate] = discrepantUNITEDKINGDOM
        //         verWipPercent[0][positionDate] = percCompIE
        //         verWipPercent[1][positionDate] = percCompUS
        //         verWipPercent[2][positionDate] = percCompUK
        //     }
        // })

        // var verWipIEHigh = getMaxFrom2dAarray(verWipIE) + 5
        // var verWipUSHigh = getMaxFrom2dAarray(verWipUS) + 5
        // var verWipUKHigh = getMaxFrom2dAarray(verWipUK) + 5
        // var verWipPercentageHigh = getMaxFrom2dAarray(verWipPercent) + 5
        // console.log(dealIntegrityData)
        setDealIntegrity({
            ...dealIntegrity,
            data: dealIntegrityData
        })
        // setVerWipGraphIE({
        //     ...verWipGraphIE,
        //     data: {
        //         labels: calculatedLabels,
        //         series: verWipIE
        //     },
        //     options: {
        //         high: verWipIEHigh,
        //         low: -5
        //     }
        // })
        // setVerWipGraphUS({
        //     ...verWipGraphUS,
        //     data: {
        //         labels: calculatedLabels,
        //         series: verWipUS
        //     },
        //     options: {
        //         high: verWipUSHigh,
        //         low: -5
        //     }
        // })
        // setVerWipGraphUK({
        //     ...verWipGraphUK,
        //     data: {
        //         labels: calculatedLabels,
        //         series: verWipUK
        //     },
        //     options: {
        //         high: verWipUKHigh,
        //         low: -5
        //     }
        // })
        // setVerWipPercentageGraph({
        //     ...verWipPercentageGraph,
        //     data: {
        //         labels: calculatedLabels,
        //         series: verWipPercent
        //     },
        //     options: {
        //         high: verWipPercentageHigh,
        //         low: -5
        //     }
        // })
        var searchProgress = document.getElementById('searchGraphProgress');
        searchProgress.style.visibility = 'hidden';
    }

    const getGraphsData = () => {
        // searchProgress.style.visibility = 'visible';

        db.collection('qualityDealIntegrity')
            .orderBy("dateCreated", "desc")
            .limit(13)
            .get()
            .then(snapshot => {
                calcWeeklyVars(snapshot)
            })
            .catch(function (error) {
                console.log("Error getting Verification Wip data: ", error);
            });
    }

    useEffect(() => {
        getGraphsData()
        return () => { }
    }, [])

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={30} openMetricsColapse={true} openMetricsQandCColapse={true} openMetricsQandCChainColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Metrics - Deal Integrity</h1> */}
                            <LinearProgress id='searchGraphProgress' className={classes.searchProgress} />
                            {roles.includes('admin') ?
                                <MaterialTable
                                    title={"Deals in Verification Wip & Percent Compliant"}
                                    columns={dealIntegrity.columns}
                                    data={dealIntegrity.data}
                                    // detailPanel={rowData => {
                                    //     // console.log(rowData)
                                    //     return (
                                    //         <MetricsChainOfCustodyVerificationWipDetails rowData={rowData} refreshData={getGraphsData} />
                                    //     )
                                    // }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="ITAD BOS" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD MD" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD SAC" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD TX" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(210, 180, 30, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD VA" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(110, 110, 110, 0.2)', color: '#000' }} />
                                                    <Chip label="Total US ITAD" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(130, 10, 200, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                        // Action: props => (
                                        //     <Button
                                        //         onClick={(event) => props.action.onClick(event, props.data)}
                                        //         color="primary"
                                        //         variant="contained"
                                        //         style={{ textTransform: 'none' }}
                                        //         size="small"
                                        //     >
                                        //         My Button
                                        //     </Button>
                                        // ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15],
                                        headerStyle: {
                                            textAlign: 'center'
                                        },
                                        cellStyle: {
                                            textAlign: 'center'
                                        }
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    addDealIntegrity(newData)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    getGraphsData()
                                                    resolve();
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    if (oldData) {
                                                        setDealIntegrity(prevState => {
                                                            const data = [...prevState.data];
                                                            newData.dateCreated = moment(newData.dateCreated).format('DD MMM YYYY')
                                                            data[data.indexOf(oldData)] = newData;
                                                            console.log('Updated Deal Integrity row in DB')
                                                            // Update data
                                                            updateDealIntegrity(newData)
                                                                .then(success => {
                                                                    //display success
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'success',
                                                                        message: success,
                                                                    });
                                                                    resolve();
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                    //display error
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'error',
                                                                        message: error,
                                                                    });
                                                                })
                                                            getGraphsData();
                                                            return { ...prevState, newData };
                                                        })
                                                    }
                                                }, 600)
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    console.log(oldData.id)
                                                    deleteDealIntegrity(oldData.id)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            resolve();
                                                            setDealIntegrity(prevState => {
                                                                const data = [...prevState.data];
                                                                data.splice(data.indexOf(oldData), 1);
                                                                return { ...prevState, data };
                                                            });
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    // getGraphsData();
                                                }, 600);
                                            }),
                                    }}
                                />
                                : <MaterialTable
                                    title={"Deals in Verification Wip & Percent Compliant"}
                                    columns={dealIntegrity.columns}
                                    data={dealIntegrity.data}
                                    // detailPanel={rowData => {
                                    //     // console.log(rowData)
                                    //     return (
                                    //         <MetricsChainOfCustodyVerificationWipDetails rowData={rowData} refreshData={getGraphsData} />
                                    //     )
                                    // }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="ITAD BOS" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD MD" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD SAC" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD TX" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(210, 180, 30, 0.2)', color: '#000' }} />
                                                    <Chip label="ITAD VA" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(110, 110, 110, 0.2)', color: '#000' }} />
                                                    <Chip label="Total US ITAD" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(130, 10, 200, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                        // Action: props => (
                                        //     <Button
                                        //         onClick={(event) => props.action.onClick(event, props.data)}
                                        //         color="primary"
                                        //         variant="contained"
                                        //         style={{ textTransform: 'none' }}
                                        //         size="small"
                                        //     >
                                        //         My Button
                                        //     </Button>
                                        // ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15],
                                        headerStyle: {
                                            textAlign: 'center'
                                        },
                                        cellStyle: {
                                            textAlign: 'center'
                                        }
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    addDealIntegrity(newData)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    getGraphsData()
                                                    resolve();
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        // onRowUpdate: (newData, oldData) =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             if (oldData) {
                                        //                 setDealIntegrity(prevState => {
                                        //                     const data = [...prevState.data];
                                        //                     newData.dateCreated = moment(newData.dateCreated).format('DD MMM YYYY')
                                        //                     data[data.indexOf(oldData)] = newData;
                                        //                     console.log('Updated Deal Integrity row in DB')
                                        //                     // Update data
                                        //                     updateDealIntegrity(newData)
                                        //                         .then(success => {
                                        //                             //display success
                                        //                             setSnackbar({
                                        //                                 open: true,
                                        //                                 severity: 'success',
                                        //                                 message: success,
                                        //                             });
                                        //                             resolve();
                                        //                         })
                                        //                         .catch(error => {
                                        //                             console.log(error)
                                        //                             //display error
                                        //                             setSnackbar({
                                        //                                 open: true,
                                        //                                 severity: 'error',
                                        //                                 message: error,
                                        //                             });
                                        //                         })
                                        //                     getGraphsData();
                                        //                     return { ...prevState, newData };
                                        //                 })
                                        //             }
                                        //         }, 600)
                                        //     }),
                                        // onRowDelete: oldData =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             console.log(oldData.id)
                                        //             deleteDealIntegrity(oldData.id)
                                        //                 .then(success => {
                                        //                     //display success
                                        //                     setSnackbar({
                                        //                         open: true,
                                        //                         severity: 'success',
                                        //                         message: success,
                                        //                     });
                                        //                     resolve();
                                        //                     setDealIntegrity(prevState => {
                                        //                         const data = [...prevState.data];
                                        //                         data.splice(data.indexOf(oldData), 1);
                                        //                         return { ...prevState, data };
                                        //                     });
                                        //                 })
                                        //                 .catch(error => {
                                        //                     console.log(error)
                                        //                     //display error
                                        //                     setSnackbar({
                                        //                         open: true,
                                        //                         severity: 'error',
                                        //                         message: error,
                                        //                     });
                                        //                 })
                                        //             // getGraphsData();
                                        //         }, 600);
                                        //     }),
                                    }}
                                />
                            }

                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}