import React, { useContext, useState } from 'react'
import { Button, Card, CssBaseline, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Loading from './Loading';
import SideMenuV2 from './SideMenus/SideMenuV2';
import FooterV2 from '../FooterV2';
import feedback from '../../images/feedback.gif'
import { DataContext } from '../../contexts/DataContext';
import SnackbarComponent from '../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        // maxWidth: `calc(100% - ${drawerWidth}px)`,
        // backgroundColor: '#f0f5f4',
        margin: theme.spacing(2),
    },
    formCard: {
        padding: 25,
    },
    media: {
        maxWidth: '150px',
        margin: 15
    },
    textMargin: {
        marginTop: 50,
        marginBottom: 50,
        maxWidth: '70%'
    },
    textfieldMargin: {
        marginTop: 30,
        maxWidth: 550,
    },
    formFullWidth: {
        width: '100%',
        textAlign: 'center',
    },
    submitButton: {
        margin: 50
    }


}));


export default function FeedbackConsole(props) {
    const classes = useStyles();

    const { sendFeedback } = useContext(DataContext);
    const { isAuthenticated, loading, roles, displayName } = useContext(AuthContext);

    const [feedbackForm, setFeedbackForm] = useState({
        FullName: displayName,
        Title: '',
        Description: ''
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleChange = (e) => {
        setFeedbackForm({
            ...feedbackForm,
            [e.target.name]: e.target.value
        })
    }

    function clearStateAndFields() {
        setFeedbackForm({
            ...feedbackForm,
            Title: '',
            Description: ''
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('Trying to submit form...');


        if (feedbackForm.Title !== '' &&
            feedbackForm.Description !== '') {
            sendFeedback(feedbackForm)
                .then(success => {
                    console.log('feedback submitted')
                    //display success
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: 'Feedback successfully submited. Thank you.',
                    });
                    clearStateAndFields();
                })
                .catch(error => {
                    console.log(error)
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: 'Could not send feedback at this time. Please try again later.',
                    });
                })
        }
        else {
            //display error
            setSnackbar({
                open: true,
                severity: 'warning',
                message: '* Please add Title and Description.',
            })

        }

    }

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>

                    <SideMenuV2 selectedIndex={47} />
                    <div className={classes.main}>
                        <Card className={classes.formCard}>
                            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                <img className={classes.media} src={feedback} alt="Feedback Logo" />
                                <Typography gutterBottom variant="h5" component="h2">
                                    Tell us what you think
                                </Typography>

                                <Typography variant="body1" component="p" className={classes.textMargin}>
                                    If you found anything not working correctly, have any ideas on the improvement of the website, or would like to add new functionality features. Please send us a message and we will take it all on board.
                                </Typography>

                                <form id="feedbackForm" className={classes.formFullWidth} onSubmit={handleSubmit}>
                                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                        <TextField
                                            name="Title"
                                            fullWidth
                                            required
                                            // multiline
                                            // rows="4"
                                            label="Subject"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={feedbackForm.Title}
                                            inputProps={{ maxLength: 500 }}
                                            className={classes.textfieldMargin}
                                        />

                                        <TextField
                                            name="Description"
                                            fullWidth
                                            required
                                            multiline
                                            rows="8"
                                            label="Description"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={feedbackForm.Description}
                                            inputProps={{ maxLength: 1000 }}
                                            className={classes.textfieldMargin}
                                        />


                                        <Button
                                            type="submit"
                                            className={classes.submitButton}
                                            variant="contained"
                                            color="primary">
                                            Submit
                                        </Button>
                                    </Grid>
                                </form>
                            </Grid>

                        </Card>
                    </div>

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

                    <FooterV2 />
                </div>
            )
        }
    }
}
