import React, { useState, useContext } from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import { Container, makeStyles, TextField, MenuItem, Button, Grid } from '@material-ui/core'
import { DataContext } from '../../../../contexts/DataContext';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { AuthContext } from '../../../../contexts/AuthContext';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    buttonRight: {
        float: 'right',
        marginTop: '35px',
        marginRight: '15%',
        // marginBottom: '24px',
    },
    margin15: {
        margin: '15px',
    },
    filePicker: {
        float: 'left',
        marginLeft: '16%',
        marginTop: '35px',
    },
    input: {
        display: 'none',
    },
    marginRight: {
        marginRight: '15px'
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
    },

}));

const TicketFormFacilities = (props) => {
    const classes = useStyles();

    const { createTicketFacilities } = useContext(DataContext);
    const { email, displayName, username } = useContext(AuthContext);

    const [ticketForm, setTicketForm] = useState({
        userEmail: '',
        userFullName: '',
        TicketType: '',
        Category: '',
        Site: '',
        Department: '',
        Division: '',
        ImmediateAttentionDescription: '',
        ProjectRequestDescription: '',
        files: [],
        filesSize: 0,
        filesToStoreInDB: [],
    });

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const ticketTypeFacilities = [
        { value: '', label: '' },
        { value: 'Immediate Attention', label: 'Immediate Attention' },
        { value: 'Project Request', label: 'Project Request' },
    ];
    const category = [
        { value: '', label: '' },
        { value: 'Building Maintenance', label: 'Building Maintenance' },
        { value: 'Electrical', label: 'Electrical' },
        { value: 'Health & Safety', label: 'Health & Safety' },
        { value: 'Plumbing', label: 'Plumbing' },
        { value: 'Security', label: 'Security' },
    ];
    const site = [
        { value: 'Kilbarry', label: 'Kilbarry' },
        { value: 'K4 / K5', label: 'K4 / K5' },
        { value: 'UK', label: 'UK' },
        { value: 'Thailand', label: 'Thailand' },
        { value: 'MD - Maryland', label: 'MD - Maryland' },
        { value: 'TX - Texas', label: 'TX - Texas' },
        { value: 'VA - Virginia', label: 'VA - Virginia' },
        { value: 'SAC - Sacramento', label: 'SAC - Sacramento' },
        { value: 'MA - Massachusetts', label: 'MA - Massachusetts' },
        { value: 'Dubai', label: 'Dubai' },
        // { value: 'All sites', label: 'All Sites' },
    ];
    const department = [
        { value: '', label: '' },
        { value: 'Customer Service', label: 'Customer Service' },
        { value: 'Engineering', label: 'Engineering' },
        { value: 'Finance', label: 'Finance' },
        { value: 'HR', label: 'HR' },
        { value: 'IT', label: 'IT' },
        { value: 'Logistics', label: 'Logistics' },
        { value: 'Operations', label: 'Operations' },
        { value: 'Procurement', label: 'Procurement' },
        { value: 'Quality', label: 'Quality' },
        { value: 'R&D', label: 'R&D' },
        { value: 'Sales', label: 'Sales' },
        { value: 'TRMA', label: 'TRMA' },
    ];
    const division = [
        { value: '', label: '' },
        { value: 'AVR', label: 'AVR' },
        { value: 'Dexgreen', label: 'Dexgreen' },
        { value: 'Forcepoint', label: 'Forcepoint' },
        { value: 'ITAD', label: 'ITAD' },
        { value: 'LB', label: 'LB' },
        { value: 'OEM', label: 'OEM' },
        { value: 'Onsite Service', label: 'Onsite Service' },
        { value: 'Protek', label: 'Protek' },
        // { value: 'All', label: 'All' },
    ];

    const handleChangeTicketTypeFacilities = (e) => {

        setTicketForm({
            ...ticketForm,
            [e.target.name]: e.target.value,
            userEmail: email, //adding email to data when it will be send to function createTicket
            userFullName: displayName, //adding FullName to data when it will be send to function createTicket
            username: username, //adding username to data when it will be send to function createTicket
            // reset other fields
            Site: '',
            Department: '',
            Division: '',
            Category: '',
            ImmediateAttentionDescription: '',
            ProjectRequestDescription: '',
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        })
    }
    const handleChange = (e) => {
        setTicketForm({
            ...ticketForm,
            [e.target.name]: e.target.value,
        })
    }
    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        // var attachments = e.target.files;
        // var filesToSend = [e.target.files[0]];
        // const filesToSend = [...files];
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...ticketForm.files];
        var filesToStore = [...ticketForm.filesToStoreInDB];
        [...files].forEach(file => {
            if (file.type.toLowerCase().includes("audio/") ||
                file.type.toLowerCase().includes("video/") ||
                file.type.toLowerCase().includes("image/") ||
                file.type.toLowerCase().includes("application/pdf") ||
                file.type.toLowerCase().includes("application/vnd") || //for all office documents
                file.type.toLowerCase().includes("text/")) { //text/csv text/hml
                // storage
                //     .ref('ticketAttachments/' + '01Hk2AQqOOoHjy2LBXql/' + file.name)
                //     .put(file)
                // console.log(file)
                filesToStore.push(file)
                var convertResult = ''
                calculatedSize += file.size

                toBase64(file)
                    .then(result => {
                        convertResult = result
                        filesToSend.push({
                            content: convertResult,
                            filename: file.name,
                            type: file.type,
                            disposition: "attachment"
                        })
                        // console.log(calculatedSize)
                        if (ticketForm.filesSize >= maxSize || calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                        }
                        else {
                            if (ticketForm.filesSize + calculatedSize >= maxSize) {
                                setSnackbar({
                                    open: true,
                                    severity: 'warning',
                                    message: 'Selected files are too big to attach.',
                                })
                            }
                            else {
                                setTicketForm({
                                    ...ticketForm,
                                    files: filesToSend,
                                    filesSize: ticketForm.filesSize + calculatedSize,
                                    filesToStoreInDB: filesToStore,
                                });
                                // console.log(filesToSend)
                            }
                        }
                    })
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'File type not supported, please attach supported file: "audio/*, video/*, image/*, .pdf, .xlsx, .xls, .csv"',
                });
            }
        })
        e.target.value = null // to allow selecting the same file over and over
    }
    const handleChangeFilePickerRemove = (e) => {
        setTicketForm({
            ...ticketForm,
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
    }

    const clearStateAndFields = () => {

        setTicketForm({
            userEmail: '',
            userFullName: '',
            TicketType: '',
            Category: '',
            Site: '',
            Department: '',
            Division: '',
            ImmediateAttentionDescription: '',
            ProjectRequestDescription: '',
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
        // get form and reset all inputs
        console.log("resetting form");
    }

    var isSubmitting = false
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isSubmitting) {
            isSubmitting = true
            console.log('Trying to submit form...');
            if (ticketForm.TicketType === 'Immediate Attention') { // Immediate Attention

                if (ticketForm.Category !== '' &&
                    ticketForm.Site !== '' &&
                    ticketForm.Department !== '' &&
                    ticketForm.Division !== '' &&
                    ticketForm.ImmediateAttentionDescription !== '') {
                    createTicketFacilities(ticketForm)
                        .then(success => {
                            console.log('added new "immediate attention" ticket')
                            //display success
                            setSnackbar({
                                open: true,
                                severity: 'success',
                                message: success,
                            });
                            clearStateAndFields();
                            isSubmitting = false
                        })
                        .catch(error => {
                            console.log(error)
                            //display error
                            setSnackbar({
                                open: true,
                                severity: 'error',
                                message: error,
                            });
                            isSubmitting = false
                        })
                }
                else {
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: '* Please select all required fields and add description.',
                    })
                    isSubmitting = false
                }
            }
            else {
                if (ticketForm.Category !== '' &&
                    ticketForm.Site !== '' &&
                    ticketForm.Department !== '' &&
                    ticketForm.Division !== '' &&
                    ticketForm.ProjectRequestDescription !== '') {// Project Request 
                    createTicketFacilities(ticketForm)
                        .then(success => {
                            console.log('added new "Project Request" ticket')
                            //display success
                            setSnackbar({
                                open: true,
                                severity: 'success',
                                message: success,
                            });
                            clearStateAndFields();
                            isSubmitting = false
                        })
                        .catch(error => {
                            console.log(error)
                            //display error
                            setSnackbar({
                                open: true,
                                severity: 'error',
                                message: error,
                            });
                            isSubmitting = false
                        })
                }
                else {
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: '* Please select all required fields and add description.',
                    })
                    isSubmitting = false
                }
            }
        }
    }

    return (
        <div>
            <form id="ticketFormFacilities" onSubmit={handleSubmit}>
                <h3 className={classes.center}>Create New Facilities Ticket</h3>

                {/* <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <img className={classes.media} src={underConstruction} alt="Under Construction" />
                </Grid> */}

                <Container maxwidth="xs">
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} >
                        <Grid container item xs={12} sm={4} spacing={1} >

                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                fullWidth
                                required
                                id="TicketType"
                                label="Facilities Ticket Type"
                                name="TicketType"
                                className={classes.margin15}
                                inputProps={{ maxLength: 50 }}
                                value={ticketForm.TicketType}
                                // InputLabelProps={{ shrink: true }}
                                onChange={handleChangeTicketTypeFacilities}>
                                {ticketTypeFacilities.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Container>

                {ticketForm.TicketType === 'Immediate Attention' ?
                    <Container maxwidth="xs">

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Category"
                                    label="Category"
                                    name="Category"
                                    value={ticketForm.Category}
                                    onChange={handleChange}   >
                                    {category.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Site"
                                    label="Site"
                                    name="Site"
                                    value={ticketForm.Site}
                                    onChange={handleChange}   >
                                    {site.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Department"
                                    label="Department"
                                    name="Department"
                                    value={ticketForm.Department}
                                    onChange={handleChange}   >
                                    {department.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Division"
                                    label="Division" // changed from Division Division
                                    name="Division"
                                    value={ticketForm.Division}
                                    onChange={handleChange}   >
                                    {division.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    name="ImmediateAttentionDescription"
                                    fullWidth
                                    // required
                                    multiline
                                    rows="4"
                                    label="Immediate Attention Description"
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={ticketForm.ImmediateAttentionDescription}
                                    inputProps={{ maxLength: 500 }}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            className={classes.buttonRight}
                            variant="contained"
                            color="primary">
                            Create ticket & send email
                        </Button>

                        <div className={classes.filePicker}>
                            <input
                                // accept="image/*"
                                accept="audio/*,video/*,image/*,.xlsx,.xls"
                                className={classes.input}
                                id="add-contained-button-file"
                                multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="add-contained-button-file">
                                <Button
                                    className={classes.marginRight}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Files
                                </Button>
                            </label>
                            <label htmlFor="remove-contained-button-file">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component="span"
                                    startIcon={<DeleteOutlineIcon />}
                                    onClick={handleChangeFilePickerRemove}>
                                    Remove Files
                                </Button>
                            </label>
                            {ticketForm.files.length === 0 ? null : <p>Files attached: {ticketForm.files.length}</p>}
                        </div>

                    </Container> : null}

                {ticketForm.TicketType === 'Project Request' ?
                    <Container maxwidth="xs">

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Category"
                                    label="Category"
                                    name="Category"
                                    value={ticketForm.Category}
                                    onChange={handleChange}   >
                                    {category.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Site"
                                    label="Site"
                                    name="Site"
                                    value={ticketForm.Site}
                                    onChange={handleChange}   >
                                    {site.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Department"
                                    label="Department"
                                    name="Department"
                                    value={ticketForm.Department}
                                    onChange={handleChange}   >
                                    {department.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Division"
                                    label="Division"
                                    name="Division"
                                    value={ticketForm.Division}
                                    onChange={handleChange}   >
                                    {division.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    name="ProjectRequestDescription"
                                    multiline
                                    fullWidth
                                    // required
                                    rows="4"
                                    label="Project Request Description"
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={ticketForm.ProjectRequestDescription}
                                    inputProps={{ maxLength: 500 }}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            className={classes.buttonRight}
                            variant="contained"
                            color="primary">
                            Create ticket & send email
                        </Button>

                        <div className={classes.filePicker}>
                            <input
                                // accept="image/*"
                                accept="audio/*,video/*,image/*,.xlsx,.xls"
                                className={classes.input}
                                id="add-contained-button-file"
                                multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="add-contained-button-file">
                                <Button
                                    className={classes.marginRight}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Files
                                </Button>
                            </label>
                            <label htmlFor="remove-contained-button-file">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component="span"
                                    startIcon={<DeleteOutlineIcon />}
                                    onClick={handleChangeFilePickerRemove}>
                                    Remove Files
                                </Button>
                            </label>
                            {ticketForm.files.length === 0 ? null : <p>Files attached: {ticketForm.files.length}</p>}
                        </div>

                    </Container> : null}

            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    )
}

export default TicketFormFacilities
