import React from 'react';
import { makeStyles } from '@material-ui/core';
import stPatricks from '../../../../images/stPatricks2023.png'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '70%',
        borderRadius: 20,
    },


}));

export default function StPatricks2023() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                <h2 className={classes.textCentered}>St. Patrick's Day 2023</h2>

            </header>
            <div className={classes.textCentered}>
                <img className={classes.media} src={stPatricks} alt="women" />
            </div>

            <article >
                <h5 >Dear Colleagues,</h5>
                <section>
                    Wishing you all the luck of the Irish on this St. Patrick's Day.
                    <br />
                    May your day be filled with laughter, good cheer, and plenty of green.
                    <br />
                    ☘️ ☘️ ☘️Happy St. Patrick's Day to all our amazing employees ☘️ ☘️ ☘️
                </section>
                <br />

            </article>

            <h4 className={classes.textCentered}>
                Enjoy some delicious St. Patrick's Day Cupcakes in the canteen during your lunch break. 😊☘️
            </h4>

            <br />
            <p>Regards,<br /><br />

                Evelyn Wiacek<br />
                {/* Global Head of HR<br />
            Wisetek™<br /> */}
            </p>
            <br />
        </div>
    )
}
