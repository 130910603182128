import React, { useState, useContext, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { makeStyles, Button, Grid, List, Tooltip, ListItem, ListItemText, FormControlLabel, Checkbox, TextField } from '@material-ui/core'
import { DataContext } from '../../../../contexts/DataContext'
// import { SimpleModal } from '../../ModalSnackbar/SimpleModal';
import { AuthContext } from '../../../../contexts/AuthContext';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SaveIcon from '@material-ui/icons/Save';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    myForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '35ch',
        },
        paddingLeft: '35px',
        paddingTop: '5px',
        paddingRight: '50px',
        paddingBottom: '35px',
    },
    buttonLeft: {
        float: 'left',
        marginLeft: '24px',
        marginTop: '24px',
        marginBottom: '35px',

    },
    buttonRight: {
        float: 'right',
        marginTop: '24px',
        marginBottom: '35px',
    },
    marginRight: {
        marginRight: '15px'
    },
    attachments: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)'
        },
        height: '35px',
        background: '#5E89B4',
        color: 'white',
        borderRadius: '5px',
    },
    attachmentsMargin: {
        marginLeft: '10%'
    },
    attList: {
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    textCentered: {
        textAlign: 'center',
    },
    checkboxGroup: {
        width: '100%',
        textAlign: 'left',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
    },
    marginLeftRight: {
        color: 'rgba(0, 0, 0, 0.4)',
        marginLeft: '15px',
        marginRight: '40px',
        minHeight: '55px'
    },
    inputSlider: {
        width: 42,
        marginRight: '20px'
    },
    smallerTextfield: {
        maxWidth: '82%'
    },
    textGrey: {
        color: 'rgba(0, 0, 0, 0.4)',
        fontSize: '115%'
    },
    greenButton: {
        marginTop: "4px",
        marginLeft: "25px",
        maxHeight: "35px",
        backgroundColor: "rgb(20, 150, 30)",
        color: "rgba(255, 255, 255)",
        '&:hover': {
            backgroundColor: 'rgb(20, 150, 30)',
        },
    }
}))

export default function TicketHRDetails(props) {
    const classes = useStyles()
    // console.log(props)
    const { updateHiringRequisition, delay } = useContext(DataContext);
    const { displayName } = useContext(AuthContext)

    const [rowValues, setRowValues] = useState({
        Department: '',
        // BudgetRange: '',
        CommentsNotes: '',
        ContractTypeDurationOfFTC: 0,
        ContractTypeFTC: false,
        ContractTypePermanent: false,
        JdFinalisedHRNo: false,
        JdFinalisedHRYes: false,
        CopyOfJdAttachedNo: false,
        CopyOfJdAttachedYes: false,
        FirstLevelApproval: '',
        FirstLevelApprovalEmail: '',
        HiringManager: '',
        HiringManagerEmail: '',
        JobTitle: '',
        PleaseProvideDetails: '',
        ReplacementFor: '',
        RoleToBeAdvertisedBoth: false,
        RoleToBeAdvertisedExternal: false,
        RoleToBeAdvertisedInternal: false,
        RoleTypeNew: false,
        RoleTypeReplacement: false,
        SecondLevelApproval: '',
        SecondLevelApprovalEmail: '',
        dateCreated: '',
        files: [],
        filesURLs: [],
        status: '',
        approvedLvl1: false,
        approvedLvl2: false,
        rejectedLvl1: false,
        rejectedLvl2: false,
        rejectedLvl1msg: '',
        rejectedLvl2msg: '',
        id: ''
    })

    const GreenCheckbox = withStyles({
        root: {
            color: green[400],
            '&$checked': {
                color: green[600],
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);
    const RedCheckbox = withStyles({
        root: {
            color: red[400],
            '&$checked': {
                color: red[600],
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    useEffect(() => {
        // var filesURLs = []
        // if (props.rowData.filesURLs.length > 0) {
        //     filesURLs = props.rowData.filesURLs
        // }
        setRowValues({
            Department: props.rowData.Department,
            // BudgetRange: props.rowData.BudgetRange,
            CommentsNotes: props.rowData.CommentsNotes,
            ContractTypeDurationOfFTC: props.rowData.ContractTypeDurationOfFTC,
            ContractTypeFTC: props.rowData.ContractTypeFTC,
            ContractTypePermanent: props.rowData.ContractTypePermanent,
            JdFinalisedHRNo: props.rowData.JdFinalisedHRNo,
            JdFinalisedHRYes: props.rowData.JdFinalisedHRYes,
            CopyOfJdAttachedNo: props.rowData.CopyOfJdAttachedNo,
            CopyOfJdAttachedYes: props.rowData.CopyOfJdAttachedYes,
            FirstLevelApproval: props.rowData.FirstLevelApproval,
            FirstLevelApprovalEmail: props.rowData.FirstLevelApprovalEmail,
            HiringManager: props.rowData.HiringManager,
            HiringManagerEmail: props.rowData.HiringManagerEmail,
            JobTitle: props.rowData.JobTitle,
            PleaseProvideDetails: props.rowData.PleaseProvideDetails,
            ReplacementFor: props.rowData.ReplacementFor,
            RoleToBeAdvertisedBoth: props.rowData.RoleToBeAdvertisedBoth,
            RoleToBeAdvertisedExternal: props.rowData.RoleToBeAdvertisedExternal,
            RoleToBeAdvertisedInternal: props.rowData.RoleToBeAdvertisedInternal,
            RoleTypeNew: props.rowData.RoleTypeNew,
            RoleTypeReplacement: props.rowData.RoleTypeReplacement,
            SecondLevelApproval: props.rowData.SecondLevelApproval,
            SecondLevelApprovalEmail: props.rowData.SecondLevelApprovalEmail,
            dateCreated: props.rowData.dateCreated,
            files: props.rowData.files.length > 0 ? props.rowData.files : [],
            filesURLs: props.rowData.filesURLs.length > 0 ? props.rowData.filesURLs : [],
            status: props.rowData.status,
            approvedLvl1: props.rowData.approvedLvl1,
            approvedLvl2: props.rowData.approvedLvl2,
            rejectedLvl1: props.rowData.rejectedLvl1,
            rejectedLvl2: props.rowData.rejectedLvl2,
            rejectedLvl1msg: props.rowData.rejectedLvl1msg,
            rejectedLvl2msg: props.rowData.rejectedLvl2msg,
            id: props.rowData.id
        })
    }, [props.rowData])

    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    // const [modal, setModal] = useState({
    //     open: false,
    //     url: ''
    // });

    const handleChangeCheckboxes = (e) => {
        // console.log(e.target.checked)
        switch (e.target.name) {
            case 'approvedLvl1':
                setRowValues({
                    ...rowValues,
                    [e.target.name]: e.target.checked,
                    rejectedLvl1: !e.target.checked,
                    rejectedLvl1msg: '',
                })
                break;
            case 'approvedLvl2':
                setRowValues({
                    ...rowValues,
                    [e.target.name]: e.target.checked,
                    rejectedLvl2: !e.target.checked,
                    rejectedLvl2msg: '',
                })
                break;
            case 'rejectedLvl1':
                setRowValues({
                    ...rowValues,
                    [e.target.name]: e.target.checked,
                    approvedLvl1: !e.target.checked
                })
                break;
            case 'rejectedLvl2':
                setRowValues({
                    ...rowValues,
                    [e.target.name]: e.target.checked,
                    approvedLvl2: !e.target.checked
                })
                break;

            default:
                break;
        }
    }
    const handleChangeTexts = (e) => {
        setRowValues({
            ...rowValues,
            [e.target.name]: e.target.value,
        })
    }

    var isApproving = false
    const approveRequest = (approvingLvl) => {
        if (approvingLvl === 'Lvl1') {
            if (!rowValues.approvedLvl1 && !rowValues.rejectedLvl1) {
                return setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: 'Please approve or reject this request before saving.',
                })
            }
        }
        if (approvingLvl === 'Lvl2') {
            if (!rowValues.approvedLvl2 && !rowValues.rejectedLvl2) {
                return setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: 'Please approve or reject this request before saving.',
                })
            }
        }

        if (!isApproving) {
            isApproving = true

            var lvl1Status = rowValues.approvedLvl1 ? 'Lvl 1 Approved' : rowValues.rejectedLvl1 ? 'Lvl 1 Rejected' : ''
            var lvl2Status = rowValues.approvedLvl2 ? 'Lvl 2 Approved' : rowValues.rejectedLvl2 ? 'Lvl 2 Rejected' : ''
            rowValues.status = lvl1Status.length > 1 && lvl2Status.length > 1 ? lvl1Status + ' & ' + lvl2Status : lvl1Status.length > 1 ? lvl1Status : lvl2Status

            updateHiringRequisition(rowValues)
                .then(success => {
                    //display success
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: success,
                    })
                    delay(6000)
                        .then(res => {
                            props.getRecruitmentRequisitions()
                            isApproving = false
                        })
                })
                .catch(error => {
                    //display error
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: error,
                    })
                    isApproving = false
                })
        }
    };

    const handleDownload = async (e, file) => {
        e.preventDefault();
        const response = await fetch(file.url);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }

    return (
        <div>
            <form className={classes.myForm} noValidate autoComplete="off" >
                <h5 className={classes.textCentered}>Recruitment Details</h5>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>

                    {rowValues.filesURLs.length === undefined || rowValues.filesURLs.length === 0 ? null :
                        <Grid container item className={classes.attachmentsMargin} direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <h5>Attachments:</h5>
                            {rowValues.filesURLs.map((file, i) => (
                                <List key={i} className={classes.attList}>
                                    <Tooltip title="Download">
                                        <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                            <SaveAltIcon className={classes.marginRight} />
                                            <ListItemText primary={file.name} />
                                        </ListItem>
                                    </Tooltip>
                                </List>
                            ))}
                        </Grid>
                    }
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Hiring manager :</p>
                            </Grid>
                            <Grid container item xs={4} className={classes.textGrey}>
                                <p>{rowValues.HiringManager}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Department :</p>
                            </Grid>
                            <Grid container item xs={4} className={classes.textGrey}>
                                <p>{rowValues.Department}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={10} spacing={1} >
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Role type :</p>
                            </Grid>
                            <Grid container item xs={4} sm={2}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.RoleTypeNew} name="RoleTypeNew" color="primary" />}
                                    label="New"
                                />
                            </Grid>
                            <Grid container item xs={4} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.RoleTypeReplacement} name="RoleTypeReplacement" color="primary" />}
                                    label="Replacement"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {rowValues.RoleTypeReplacement ?
                        <Grid container item xs={12} sm={10} spacing={1}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                                <Grid container item xs={4} className={classes.marginLeftRight}>
                                    <p>Replacement For :</p>
                                </Grid>
                                <Grid container item xs={7} className={classes.textGrey}>
                                    <p>{rowValues.ReplacementFor}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        : null}
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Role details :</p>
                            </Grid>
                            <Grid container item xs={7} className={classes.textGrey}>
                                <p>{rowValues.PleaseProvideDetails}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Job title :</p>
                            </Grid>
                            <Grid container item xs={7} className={classes.textGrey}>
                                <p>{rowValues.JobTitle}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Contract type :</p>
                            </Grid>
                            <Grid container item xs={12} sm={2}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.ContractTypeFTC} name="ContractTypeFTC" color="primary" />}
                                    label="Fixed Term"
                                />
                            </Grid>
                            <Grid container item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.ContractTypePermanent} name="ContractTypePermanent" color="primary" />}
                                    label="Permanent"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {rowValues.ContractTypeFTC ?
                        <Grid container item xs={12} sm={10} spacing={1} >
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                                <Grid container item xs={4} className={classes.marginLeftRight}>
                                    <p>Contract length :</p>
                                </Grid>
                                <Grid container item xs={12} sm={5} className={classes.textGrey}>
                                    <p>{rowValues.ContractTypeDurationOfFTC + ' months'}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        : null}
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Job description finalised with HR? :</p>
                            </Grid>
                            <Grid container item xs={12} sm={2}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.JdFinalisedHRYes} name="JdFinalisedHRYes" color="primary" />}
                                    label="Yes"
                                />
                            </Grid>
                            <Grid container item xs={12} sm={2}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.JdFinalisedHRNo} name="JdFinalisedHRNo" color="primary" />}
                                    label="No"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Copy of job description attached? :</p>
                            </Grid>
                            <Grid container item xs={12} sm={2}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.CopyOfJdAttachedYes} name="CopyOfJdAttachedYes" color="primary" />}
                                    label="Yes"
                                />
                            </Grid>
                            <Grid container item xs={12} sm={2}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.CopyOfJdAttachedNo} name="CopyOfJdAttachedNo" color="primary" />}
                                    label="No"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Role budget :</p>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                                <Grid container item xs={7} className={classes.textGrey}>
                                    <p>{rowValues.BudgetRange}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Role to be advertised :</p>
                            </Grid>
                            <Grid container item xs={12} sm={2}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.RoleToBeAdvertisedInternal} name="RoleToBeAdvertisedInternal" color="primary" />}
                                    label="Internal"
                                />
                            </Grid>
                            <Grid container item xs={12} sm={2}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.RoleToBeAdvertisedExternal} name="RoleToBeAdvertisedExternal" color="primary" />}
                                    label="External"
                                />
                            </Grid>
                            <Grid container item xs={12} sm={2}>
                                <FormControlLabel
                                    control={<Checkbox disabled checked={rowValues.RoleToBeAdvertisedBoth} name="RoleToBeAdvertisedBoth" color="primary" />}
                                    label="Both"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>First level approving manager :</p>
                            </Grid>
                            <Grid container item xs={2} className={classes.textGrey}>
                                <p>{rowValues.FirstLevelApproval}</p>
                            </Grid>
                            {/* {displayName === 'Pawel Gajewski' ?//rowValues.FirstLevelApproval ? */}
                            {displayName === rowValues.FirstLevelApproval ?
                                <Grid container item xs={5} >
                                    <div style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                                        <FormControlLabel style={{ minWidth: '39%' }}
                                            control={<GreenCheckbox checked={rowValues.approvedLvl1} onChange={handleChangeCheckboxes} name="approvedLvl1" />}
                                            label='Approve'
                                        />
                                        <FormControlLabel style={{ minWidth: '15%' }}
                                            control={<RedCheckbox checked={rowValues.rejectedLvl1} onChange={handleChangeCheckboxes} name="rejectedLvl1" />}
                                            label='Reject'
                                        />
                                        <Button
                                            disabled={isApproving}
                                            className={classes.greenButton}
                                            variant="contained" size="small" name="Lvl1" onClick={() => approveRequest('Lvl1')}
                                            startIcon={<SaveIcon />}>
                                            Save & Send
                                        </Button>
                                    </div>
                                    {rowValues.rejectedLvl1 ?
                                        <Grid container item xs={12} sm={12} spacing={1} style={{ marginBottom: 10 }}>
                                            <TextField
                                                name="rejectedLvl1msg"
                                                fullWidth
                                                // required
                                                multiline
                                                minRows={4}
                                                maxRows={6}
                                                label="Rejection comments"
                                                variant="outlined"
                                                onChange={handleChangeTexts}
                                                value={rowValues.rejectedLvl1msg}
                                                inputProps={{ maxLength: 500 }}
                                            />
                                        </Grid>
                                        : null}
                                </Grid>
                                :
                                <Grid container item xs={5} >
                                    <div style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                                        <FormControlLabel style={{ minWidth: '39%' }}
                                            control={<Checkbox disabled checked={rowValues.approvedLvl1} name="approvedLvl1" color="primary" />}
                                            label="Approved"
                                        />
                                        <FormControlLabel style={{ minWidth: '39%' }}
                                            control={<Checkbox disabled checked={rowValues.rejectedLvl1} name="rejectedLvl1" color="primary" />}
                                            label="Rejected"
                                        />
                                    </div>
                                    {rowValues.rejectedLvl1 ?
                                        <Grid container item xs={12} sm={12} spacing={1} style={{ marginBottom: 10 }}>
                                            <TextField
                                                name="rejectedLvl1msg"
                                                fullWidth
                                                disabled
                                                multiline
                                                minRows={4}
                                                maxRows={6}
                                                label="Rejection comments"
                                                variant="outlined"
                                                // onChange={handleChangeTexts}
                                                value={rowValues.rejectedLvl1msg}
                                            // inputProps={{ maxLength: 500 }}
                                            />
                                        </Grid>
                                        : null}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Second level approving manager :</p>
                            </Grid>
                            <Grid container item xs={2} className={classes.textGrey}>
                                <p>{rowValues.SecondLevelApproval}</p>
                            </Grid>
                            {/* {displayName === 'Pawel Gajewski' ?//rowValues.SecondLevelApproval ? */}
                            {displayName === rowValues.SecondLevelApproval ?
                                <Grid container item xs={5} >
                                    <div style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                                        <FormControlLabel style={{ minWidth: '39%' }}
                                            control={<GreenCheckbox checked={rowValues.approvedLvl2} onChange={handleChangeCheckboxes} name="approvedLvl2" />}
                                            label='Approve'
                                        />
                                        <FormControlLabel style={{ minWidth: '15%' }}
                                            control={<RedCheckbox checked={rowValues.rejectedLvl2} onChange={handleChangeCheckboxes} name="rejectedLvl2" />}
                                            label='Reject'
                                        />
                                        <Button
                                            disabled={isApproving}
                                            className={classes.greenButton}
                                            variant="contained" size="small" name="Lvl2" onClick={() => approveRequest('Lvl2')}
                                            startIcon={<SaveIcon />}>
                                            Save & Send
                                        </Button>
                                    </div>
                                    {rowValues.rejectedLvl2 ?
                                        <Grid container item xs={12} sm={12} spacing={1} style={{ marginBottom: 10 }}>
                                            <TextField
                                                name="rejectedLvl2msg"
                                                fullWidth
                                                // required
                                                multiline
                                                minRows={4}
                                                maxRows={6}
                                                label="Rejection comments"
                                                variant="outlined"
                                                onChange={handleChangeTexts}
                                                value={rowValues.rejectedLvl2msg}
                                                inputProps={{ maxLength: 500 }}
                                            />
                                        </Grid>
                                        : null}
                                </Grid>
                                :
                                <Grid container item xs={5} >
                                    <div style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                                        <FormControlLabel style={{ minWidth: '39%' }}
                                            control={<Checkbox disabled checked={rowValues.approvedLvl2} name="approvedLvl2" color="primary" />}
                                            label="Approved"
                                        />
                                        <FormControlLabel style={{ minWidth: '39%' }}
                                            control={<Checkbox disabled checked={rowValues.rejectedLvl2} name="rejectedLvl2" color="primary" />}
                                            label="Rejected"
                                        />
                                    </div>
                                    {rowValues.rejectedLvl2 ?
                                        <Grid container item xs={12} sm={12} spacing={1} style={{ marginBottom: 10 }}>
                                            <TextField
                                                name="rejectedLvl2msg"
                                                fullWidth
                                                disabled
                                                multiline
                                                minRows={4}
                                                maxRows={6}
                                                label="Rejection comments"
                                                variant="outlined"
                                                // onChange={handleChangeTexts}
                                                value={rowValues.rejectedLvl2msg}
                                            // inputProps={{ maxLength: 500 }}
                                            />
                                        </Grid>
                                        : null}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={10} spacing={1}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.checkboxGroup}>
                            <Grid container item xs={4} className={classes.marginLeftRight}>
                                <p>Comments and notes :</p>
                            </Grid>
                            <Grid container item xs={7} className={classes.textGrey} >
                                <p>{rowValues.CommentsNotes}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

        </div >
    )
}