import React from 'react'
import { Button, Card, Grid, makeStyles } from '@material-ui/core';
import logo from '../../images/ConnectWisetekNet.png';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    sxCard: {
        maxWidth: 500,
        padding: '50px',
        marginTop: '50px',
    },
    media: {
        width: '70%',
        margin: 30
        // padding: '50px',
        // marginTop: '50px',
    },
    textCenter: {
        textAlign: 'center'
    },
    sxH1: {
        fontSize: 22,
        fontWeight: 300,
        color: 'rgba(0, 0, 0, 0.6)',
        margin: '0 0 16px',
    },
    sxH2: {
        color: '#ffa100',
        fontWeight: 'bold',
        fontSize: 16,
        margin: '0 0 8px',
    },
    sxAHref: {
        textDecoration: 'none'
    },
}));

export default function NotFoundPage() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Card className={classes.sxCard}>
                    <h2 className={classes.sxH2}>404 - Connect | Wisetek</h2>
                    <h1 className={classes.sxH1}>Page Not Found</h1>
                    <div className={classes.textCenter}>
                        <img className={classes.media} src={logo} alt="Connect Logo" />
                    </div>
                    <br />
                    <p>The specified page was not found on this website. Please check the URL for mistakes and try again.</p>
                    <br />
                    {/* <a href="javascript:history.back()">Back Home</a> */}
                    <div className='txtCenter'>
                        <a href="/" className={classes.sxAHref}>
                            <Button variant='outlined'>Back Home</Button>
                        </a>
                    </div>
                    <br />
                </Card>
            </Grid>
        </div>
    )
}
