import React, { useState } from 'react'
import { Card, makeStyles, Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { db } from '../../../config/fbConfig';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        padding: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    margin: {
        margin: theme.spacing(2),
    }

}));

export default function MetricsAdmin() {
    const classes = useStyles();

    const [searchDate, setSearchDate] = useState(new Date());

    const [open, setOpen] = useState(false);

    //#region Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    const handleSearchDate = (date) => {
        setSearchDate(date)
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        console.log('Cancel or Close.')
        setOpen(false);
    };

    const handleDelete = () => {
        console.log('Going to db and deleteing records for:', moment(searchDate).format('DD MMMM YYYY'))
        var startDateTime = new Date(moment(searchDate).startOf('date'));
        var endDateTime = new Date(moment(searchDate).endOf('date'));
        var counter = 0;
        var delCounter = 0;
        db.collection("metrics")
            .where("dateCreated", ">=", startDateTime)
            .where("dateCreated", "<=", endDateTime)
            .get()
            .then(snap => {
                snap.forEach(doc => {
                    counter++;
                    var docId = doc.id
                    db.collection("metrics").doc(docId).delete()
                        .then(
                            delCounter++
                        )
                })
                console.log('There was:', counter, 'records that day.')
                console.log('Deleted:', delCounter, 'records.')
                setOpen(false);
                if (counter === 0 && delCounter === 0) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'There was nothing to delete for that day( ' + moment(searchDate).format('DD MMMM YYYY') + ' ).',
                    })
                }
                else {
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: 'Deleted ' + counter + '/' + delCounter + ' records for that day.',
                    })
                }
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    severity: 'alert',
                    message: err.message,
                })
            })
    };

    return (
        <div>

            <Card className={classes.center} >
                <MuiPickersUtilsProvider utils={DateFnsUtils}  >
                    <Typography variant="h6">
                        Delete data in Metrics DB for selected Date
                    </Typography>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <KeyboardDatePicker
                            margin="normal"
                            id="searchDate"
                            disableFuture
                            // label="From Date:"
                            name="searchDate"
                            format="dd-MMM-yy"
                            error // makes text red
                            helperText="Please select Date to delete records"
                            // required
                            // type="date"
                            // className={classes.datePickerCenter}
                            value={searchDate}
                            onChange={handleSearchDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.margin}
                        startIcon={<DeleteIcon />}
                        onClick={handleClickOpen}
                    >
                        Delete Metrics Daily Data
                    </Button>
                </MuiPickersUtilsProvider>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" className={classes.center}>Are you sure you want to delete?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Metrics data for {moment(searchDate).format('DD MMMM YYYY')} will be deleted.
                            There is no coming back.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} >
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="secondary" autoFocus>
                            Yes, Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    )
}
