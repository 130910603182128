import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Container, TextField, CssBaseline, Grid, Card, Button, InputBase } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Loading from '../../Loading';
import { db } from '../../../../config/fbConfig';
import { DataContext } from '../../../../contexts/DataContext';
import Footer from '../../../Footer';
import moment from 'moment';
import MaterialTable from 'material-table';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    marginAutoRight: {
        // display: 'none',
        marginRight: 'auto'
    },
    marginAutoLeft: {
        marginLeft: 'auto'
    },
    textFieldBox: {
        width: '100%',
        height: '65px',
        marginTop: '0px',
        marginBottom: '15px',
        // textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
    },
    inputBaseScan: {
        padding: '10px',
        fontSize: '1.5em',
    },
    outputBinText: {
        marginTop: '35px',
        marginBottom: '0px',
    },
    outputTrayText: {
        margin: '10px',
        fontSize: '3em',
        color: 'green'
    },
    clearInputButtonMargin: {
        marginRight: '15px'
    },
    labelText: {
        fontSize: '11px',
        marginBottom: '0px'
    },
    labelTextSpace: {
        fontSize: '11px',
        marginRight: '15px',
        marginBottom: '0px'
    },
    labelCard: {
        padding: '20px',
        // position: 'absolute',
        // // top: '50%',
        // // left: '50%',
        // // transform: 'translate(-50%, -50%)',
        // // transform: 'translate(50%, 50%)',
        // width: '40%',
        // maxHeight: '90%',
        // overflow: 'hidden',
        // // width: '265px', //70mm
        // // height: '151px', //40mm
        top: 0,
        left: 0,
        // // width: 400,
    },
    // sectionToPrint: {
    //     position: 'absolute',
    //     width: '265px', //70mm
    //     height: '151px', //40mm
    //     overflow: 'hidden',
    //     // left: '0',
    //     // top: '0',
    //     display: 'none'
    // },
    marginBottom: {
        marginBottom: '45px'
    },
    partsAndBinsTable: {
        marginTop: '25px',
        // paddingLeft: '25px',
        // paddingRight: '25px'
    },

}));

export default function LBMemoryScan() {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);
    const { addMemoryScanPartsAndBins } = useContext(DataContext);

    const [scanInfo, setScanInfo] = useState({
        ScanPartNo: '',
        PartNumber: '',
        BinNumber: '',
        Country: '',
        Manufacturer: '',
        Prefix: '',
        binHelperText: '',
        binTrayText: '',
    });

    const [partsAndBins, setPartsAndBins] = useState({
        columns: [
            { title: 'Part Number', field: 'PartNumber', editable: 'never' },
            { title: 'Bin Number', field: 'BinNumber', editable: 'never' }, //filtering: false
            { title: 'Added By', field: 'addedByUser', editable: 'never' },
            { title: 'Added Date', field: 'dateCreated', editable: 'never', filtering: false },
        ],
        data: []
    })
    const [iframeSrcDoc, setIframeSrcDoc] = useState('')
    const [manufacturers, setManufacturers] = useState([])

    const [openLabel, setOpenLabel] = useState(false);
    const handleOpenLabel = () => {
        if (scanInfo.PartNumber.trim().length < 16) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please scan part number first. At least 16 characters required.',
            })
            return
        }
        else {
            setOpenLabel(true)
        }
    };
    const handleCloseLabel = () => setOpenLabel(false);

    //Snackbar
    //#region 
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    const handleChange = (e) => {
        setScanInfo({
            ...scanInfo,
            [e.target.name]: e.target.value,
        })
    }

    const handleClearForm = (e) => {
        setScanInfo({
            ...scanInfo,
            ScanPartNo: '',
            PartNumber: '',
            BinNumber: '',
            Country: '',
            Manufacturer: '',
            Prefix: '',
            binHelperText: '',
            binTrayText: '',
        })
        document.getElementById('ScanPartNo').focus();
    }

    function printClick() {
        // var w = window.open('', 'modalLabel', 'width=400,height=600');
        // var html = $("#sectionToPrint").html();
        // $(w.document.body).html(html);
        var w = document.getElementById('sectionToPrint');
        var pri = document.getElementById("iframeToPrint").contentWindow;
        pri.document.open();
        pri.document.write(w.innerHTML)
        pri.document.close();
        pri.focus();
        pri.print();
        // w.print()
    }
    function labelToPrint() {

        return {
            __html:
                '<div ><div className={classes.sectionToPrint} id="sectionToPrint"><p >{scanInfo.PartNumber}</p><p >Tray {scanInfo.BinNumber}</p><h1 id="memoryScanLabelBarcode">{"*" + scanInfo.PartNumber + "*"}</h1>                 <br />                       <br />                        <br />                        <p >{scanInfo.PartNumber}</p>                        <p >Tray {scanInfo.BinNumber}</p>                        <p id="memoryScanLabelBarcode">{"*" + scanInfo.PartNumber + "*"}</p>                    </div>                </div>'
        }
    }
    var label = '<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style>#barcodeText {font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-bottom:0px;}#barcode{font-family: "Libre Barcode 39", cursive;font-size:14px;margin-top:0px;}#barcodeText{margin-bottom:0px}</style></head><body><div><div style="display: flex;"><p id="barcodeText" style="font-family: "Libre Barcode 39"">HMT351R7CFR8A-H9</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray 3</p></div><p id="barcode">*HMT351R7CFR8A-H9*</p><br/><div style="display: flex;"><p id="barcodeText">HMT351R7CFR8A-H9</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray 3</p></div><p id="barcode">*HMT351R7CFR8A-H9*</p></div></body></html>'

    function handlePrintLabel(e) {
        console.log('Printing Label')
        if (scanInfo.PartNumber.length > 15) {
            // var content = document.getElementById("sectionToPrint");
            // var doc = document.getElementById("iframeToPrint");
            var doc = document.getElementById("iframeToPrint").contentWindow;
            // doc.document.open();
            // doc.document.write('<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style>#barcodeText {font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-bottom:0px;}#barcode{font-family: "Libre Barcode 39", cursive;font-size:14px;margin-top:0px;}#barcodeText{margin-bottom:0px}</style></head><body>')
            // doc.document.write('<div><div style="display: flex;"><p id="barcodeText" style="font-family: "Libre Barcode 39"">')
            // doc.document.write('HMT351R7CFR8A-H9')
            // doc.document.write('</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray 3</p></div><p id="barcode">*')
            // doc.document.write('HMT351R7CFR8A-H9')
            // doc.document.write('*</p><br/><div style="display: flex;"><p id="barcodeText">')
            // doc.document.write('HMT351R7CFR8A-H9')
            // doc.document.write('</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray 3</p></div><p id="barcode">*')
            // doc.document.write('HMT351R7CFR8A-H9')
            // doc.document.write('*</p></div>')
            // doc.document.write('</body></html>')
            // doc.document.close();
            // doc.document.srcDoc = '<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style>#barcodeText {font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-bottom:0px;}#barcode{font-family: "Libre Barcode 39", cursive;font-size:14px;margin-top:0px;}#barcodeText{margin-bottom:0px}</style></head><body><div><div style="display: flex;"><p id="barcodeText" style="font-family: "Libre Barcode 39"">HMT351R7CFR8A-H9</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray 3</p></div><p id="barcode">*HMT351R7CFR8A-H9*</p><br/><div style="display: flex;"><p id="barcodeText">HMT351R7CFR8A-H9</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray 3</p></div><p id="barcode">*HMT351R7CFR8A-H9*</p></div></body></html>'
            doc.focus();
            // doc.print();

            // doc.document.write('<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style>#barcodeText {font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-bottom:0px;}#barcode{font-family: "Libre Barcode 39", cursive;font-size:14px;margin-top:0px;}#barcodeText{margin-bottom:0px}</style></head><body>')
            // doc.document.write('<div><div style="display: flex;"><p id="barcodeText" style="font-family: "Libre Barcode 39"">')
            // doc.document.write(scanInfo.PartNumber)
            // doc.document.write('</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray ')
            // doc.document.write(scanInfo.BinNumber)
            // doc.document.write('</p></div><p id="barcode">*')
            // doc.document.write(scanInfo.PartNumber)
            // doc.document.write('*</p><br/><br/><div style="display: flex;"><p id="barcodeText">')
            // doc.document.write(scanInfo.PartNumber)
            // doc.document.write('</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray ')
            // doc.document.write(scanInfo.BinNumber)
            // doc.document.write('</p></div><p id="barcode">*')
            // doc.document.write(scanInfo.PartNumber)
            // doc.document.write('*</p></div>')
            // doc.document.write('</body></html>')
            // doc.document.close();

            // doc.close();
            // doc.focus();
            doc.print();
        }
        else {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Bin is empty. Please scan part to create label.',
            })
            return
        }
    }

    function handlePrintLabel(partNo, binNo) {
        var srcDoc = '<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style>#barcodeText {font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-bottom:0px;}#barcode{font-family: "Libre Barcode 39", cursive;font-size:16px;margin-top:8px;}#barcodeText{margin-bottom:0px}</style></head><body><div><div style="display: flex;"><p id="barcodeText" style="font-family: "Libre Barcode 39"">' + partNo + '</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray ' + binNo + '</p></div><p id="barcode">*' + partNo + '*</p></div></body></html>'
        // var srcDoc = '<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style>#barcodeText {font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-bottom:5px;}#barcode{font-family: "Libre Barcode 39", cursive;font-size:18px;margin-top:0px;}#barcodeText{margin-bottom:0px}</style></head><body><div><div style="display: flex;"><p id="barcodeText" style="font-family: "Libre Barcode 39"">' + partNo + '</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray ' + binNo + '</p></div><p id="barcode">*' + partNo + '*</p><br/><div style="display: flex;"><p id="barcodeText">' + partNo + '</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray ' + binNo + '</p></div><p id="barcode">*' + partNo + '*</p></div></body></html>'

        var iframe = document.createElement('iframe');
        iframe.srcdoc = srcDoc
        iframe.style.display = "none"
        document.body.appendChild(iframe)
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
    }

    function getBinsAndPArtsData() {
        var binsAndParts = []
        db.collection('memoryScanPartsAndBins').orderBy('BinNumber', 'desc').get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    var row = doc.data()
                    row.dateCreated = moment(row.dateCreated.toDate()).format('DD MMM YYYY')
                    binsAndParts.push(row)
                })
                setPartsAndBins({
                    ...partsAndBins,
                    data: binsAndParts
                })
            })
    }

    function getMemoryScanData() {
        // console.log(data)
        var manufacturers = []

        db.collection('memoryScanCheckSheet').orderBy('Prefix').get()
            .then(snap => {
                snap.forEach(doc => {
                    manufacturers.push(doc.data())
                })
            })
        setManufacturers(manufacturers)
    }

    useEffect(() => {
        getMemoryScanData()
        getBinsAndPArtsData()
        return () => { }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log('submiting scan')

        if (scanInfo.ScanPartNo.trim().length < 16) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Scanned part number is to short.',
            })
            return
        }
        else {
            var firstTwo = scanInfo.ScanPartNo.trim().substring(0, 2).toUpperCase()
            var manufacturerData = { Country: '', Manufacturer: '', Prefix: '' }
            var processedPartNumber = ''
            var processedBinNumber = 0
            manufacturers.forEach(manufacturer => {
                if (manufacturer.Prefix === firstTwo) {
                    manufacturerData = manufacturer
                    return
                }
            })

            switch (manufacturerData.Manufacturer.toUpperCase()) {
                case 'HYNIX':
                    processedPartNumber = scanInfo.ScanPartNo.trim().substring(0, 16);
                    break;
                case 'MICRON':
                    processedPartNumber = scanInfo.ScanPartNo.trim().substring(0, 20);
                    break;
                case 'SAMSUNG':
                    processedPartNumber = scanInfo.ScanPartNo.trim().substring(3, 19);
                    break;
                case 'SAMSUNG DIRECT':
                    processedPartNumber = scanInfo.ScanPartNo.trim()
                    break;

                default:
                    processedPartNumber = scanInfo.ScanPartNo.trim()
                    break;
            }

            var highestBinNumber = 0
            db.collection('memoryScanPartsAndBins').orderBy('BinNumber', 'desc').get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        if (doc.data().BinNumber > highestBinNumber) highestBinNumber = doc.data().BinNumber
                        if (doc.data().PartNumber === processedPartNumber.toUpperCase()) {
                            processedBinNumber = doc.data().BinNumber
                        }
                    })
                    if (processedBinNumber === 0) {
                        processedBinNumber = highestBinNumber + 1
                        var dataToSend = { PartNumber: processedPartNumber, BinNumber: processedBinNumber }
                        addMemoryScanPartsAndBins(dataToSend)
                            .then(success => {
                                setSnackbar({
                                    open: true,
                                    severity: 'warning',
                                    message: 'Part Number assigned to the new bin! Please create phisical Tray ' + processedBinNumber + '.',
                                })
                                getBinsAndPArtsData()
                                // console.log(success)
                            })
                            .catch(err => {
                                setSnackbar({
                                    open: true,
                                    severity: 'error',
                                    message: 'Unable to create a new bin in the database. Please check the internet connection and try again.',
                                })
                                // console.log(err)
                            })
                    }
                    var srcDoc = '<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style>#barcodeText {font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-bottom:0px;}#barcode{font-family: "Libre Barcode 39", cursive;font-size:14px;margin-top:0px;}#barcodeText{margin-bottom:0px}</style></head><body><div><div style="display: flex;"><p id="barcodeText" style="font-family: "Libre Barcode 39"">' + processedPartNumber + '</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray ' + processedBinNumber + '</p></div><p id="barcode">*' + processedPartNumber + '*</p><br/><div style="display: flex;"><p id="barcodeText">' + processedPartNumber + '</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray ' + processedBinNumber + '</p></div><p id="barcode">*' + processedPartNumber + '*</p></div></body></html>'
                    setIframeSrcDoc(srcDoc)
                    setScanInfo({
                        ...scanInfo,
                        ScanPartNo: '',
                        PartNumber: processedPartNumber,
                        BinNumber: processedBinNumber,
                        Country: manufacturerData.Country,
                        Manufacturer: manufacturerData.Manufacturer,
                        Prefix: manufacturerData.Prefix,
                        binHelperText: 'Place in Bin #',
                        binTrayText: 'Tray ' + processedBinNumber,
                    })

                })
        }
    }

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={5} openProductionColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Production - Memory Scan</h1> */}

                            <Card>
                                <Container component="main" className={classes.main} maxWidth="xl">

                                    <form id="memoryScan" onSubmit={handleSubmit}>

                                        <h3 className={classes.center}>Memory Scan</h3>
                                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>

                                            <Grid container item xs={12} sm={6} spacing={1}>
                                                <div className={classes.textFieldBox}>
                                                    <InputBase
                                                        className={classes.inputBaseScan}
                                                        autoFocus={true}
                                                        // select
                                                        // required
                                                        autoComplete="off"
                                                        variant="standard"
                                                        fullWidth
                                                        id="ScanPartNo"
                                                        // label="Scan part number here"
                                                        name="ScanPartNo"
                                                        value={scanInfo.ScanPartNo}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 30 }}
                                                    >
                                                    </InputBase>
                                                </div>
                                                <Button
                                                    className={classes.clearInputButtonMargin}
                                                    type="button"
                                                    size="small"
                                                    // className={classes.buttonHidden}
                                                    // variant="contained"
                                                    // color="primary"
                                                    variant="outlined"
                                                    onClick={handleClearForm}>
                                                    Clear Input
                                                </Button>

                                                <Button
                                                    className={classes.marginAutoRight}
                                                    type="button"
                                                    size="small"
                                                    // className={classes.buttonHidden}
                                                    // variant="contained"
                                                    // color="primary"
                                                    variant="outlined"
                                                    onClick={() => handlePrintLabel(scanInfo.PartNumber, scanInfo.BinNumber)}
                                                >
                                                    Label
                                                </Button>

                                                <Button
                                                    type="submit"
                                                    size="small"
                                                    className={classes.marginAutoLeft}
                                                    variant="outlined"
                                                    color="primary">
                                                    Submit
                                                </Button>

                                                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                                                    <h2 className={classes.outputBinText}>{scanInfo.binHelperText}</h2>
                                                </Grid>
                                                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                                                    <p className={classes.outputTrayText}>
                                                        {scanInfo.binTrayText}
                                                    </p>
                                                </Grid>

                                            </Grid>

                                            <Grid container item xs={12} sm={6} spacing={1}>
                                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>

                                                    <Grid container item >
                                                        <TextField
                                                            size="small"
                                                            variant="outlined"
                                                            disabled
                                                            // required
                                                            fullWidth
                                                            id="PartNumber"
                                                            label="Part Number"
                                                            name="PartNumber"
                                                            value={scanInfo.PartNumber}
                                                            onChange={handleChange}   >
                                                        </TextField>
                                                    </Grid>
                                                    <Grid container item >
                                                        <TextField
                                                            size="small"
                                                            variant="outlined"
                                                            disabled
                                                            // required
                                                            fullWidth
                                                            id="BinNumber"
                                                            label="Bin Number"
                                                            name="BinNumber"
                                                            value={scanInfo.BinNumber}
                                                            onChange={handleChange}   >
                                                        </TextField>
                                                    </Grid>

                                                    <Grid container item >
                                                        <TextField
                                                            size="small"
                                                            variant="outlined"
                                                            disabled
                                                            // required
                                                            fullWidth
                                                            id="Country"
                                                            label="Country"
                                                            name="Country"
                                                            value={scanInfo.Country}
                                                            onChange={handleChange}   >
                                                        </TextField>
                                                    </Grid>
                                                    <Grid container item >
                                                        <TextField
                                                            size="small"
                                                            variant="outlined"
                                                            disabled
                                                            // required
                                                            fullWidth
                                                            id="Manufacturer"
                                                            label="Manufacturer"
                                                            name="Manufacturer"
                                                            value={scanInfo.Manufacturer}
                                                            onChange={handleChange}   >
                                                        </TextField>
                                                    </Grid>
                                                    <Grid container item >
                                                        <TextField
                                                            className={classes.marginBottom}
                                                            size="small"
                                                            variant="outlined"
                                                            disabled
                                                            // required
                                                            fullWidth
                                                            id="Prefix"
                                                            label="Prefix"
                                                            name="Prefix"
                                                            inputProps={{ maxLength: 2 }}
                                                            value={scanInfo.Prefix}
                                                            onChange={handleChange}   >
                                                        </TextField>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </form>

                                </Container>
                            </Card>

                            <Card className={classes.partsAndBinsTable}>
                                <MaterialTable
                                    title={"Part Numbers & Bins"}
                                    columns={partsAndBins.columns}
                                    data={partsAndBins.data}
                                    // detailPanel={rowData => {
                                    //     // console.log(rowData)
                                    //     return (
                                    //         <TicketsITDetails ticketData={rowData} />
                                    //     )
                                    // }}
                                    options={{
                                        actionsColumnIndex: 0, filtering: true, pageSize: 5, pageSizeOptions: [5, 10, 50]//,exportButton: true,
                                    }}//, { value: ticketsIT.data.length, label: 'All' }] }}
                                    // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={[
                                        {
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getBinsAndPArtsData();
                                            },
                                        },
                                        {
                                            icon: 'print',
                                            tooltip: 'Print Label',
                                            // onClick: (event, rowData) => alert("Printing Label for " + rowData.PartNumber)
                                            onClick: (event, rowData) => {
                                                // var srcDoc = '<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style>#barcodeText {font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-bottom:0px;}#barcode{font-family: "Libre Barcode 39", cursive;font-size:14px;margin-top:0px;}#barcodeText{margin-bottom:0px}</style></head><body><div><div style="display: flex;"><p id="barcodeText" style="font-family: "Libre Barcode 39"">' + rowData.PartNumber + '</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray ' + rowData.BinNumber + '</p></div><p id="barcode">*' + rowData.PartNumber + '*</p><br/><div style="display: flex;"><p id="barcodeText">' + rowData.PartNumber + '</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray ' + rowData.BinNumber + '</p></div><p id="barcode">*' + rowData.PartNumber + '*</p></div></body></html>'

                                                // var iframe = document.createElement('iframe');
                                                // iframe.srcdoc = srcDoc
                                                // document.body.appendChild(iframe)
                                                // iframe.contentWindow.focus()
                                                // iframe.contentWindow.print()
                                                handlePrintLabel(rowData.PartNumber, rowData.BinNumber)
                                            }
                                        }
                                    ]}
                                />
                            </Card>
                            {/* <iframe id="iframeToPrint" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe> */}
                            {/* <iframe id="iframeToPrint" srcDoc={iframeSrcDoc} style={{ display: 'none' }}></iframe> */}
                            {/* <iframe id="iframeToPrint" srcDoc='<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style>#barcodeText {font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-bottom:0px;}#barcode{font-family: "Libre Barcode 39", cursive;font-size:14px;margin-top:0px;}#barcodeText{margin-bottom:0px}</style></head><body><div><div style="display: flex;"><p id="barcodeText" style="font-family: "Libre Barcode 39"">HMT351R7CFR8A-H9</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray 3</p></div><p id="barcode">*HMT351R7CFR8A-H9*</p><br/><div style="display: flex;"><p id="barcodeText">HMT351R7CFR8A-H9</p><p id="barcodeText">&nbsp;&nbsp;&nbsp;&nbsp;Tray 3</p></div><p id="barcode">*HMT351R7CFR8A-H9*</p></div></body></html>' ></iframe> */}
                            {/* <Modal
                                id='modalLabel'
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={openLabel}
                                onClose={handleCloseLabel}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={openLabel}>
                                    <Card className={classes.labelCard}>

                                        <div className="sectionToPrint" id="sectionToPrint">
                                            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"  >
                                                <Grid container item direction="row" justifyContent="flex-start" alignItems="flex-start" >
                                                    <p className={classes.labelTextSpace}>{scanInfo.PartNumber}</p>
                                                    <p className={classes.labelText}>{scanInfo.binTrayText}</p>
                                                </Grid>
                                                <Grid container item direction="row" justifyContent="flex-start" alignItems="flex-start" >
                                                    <p className='memoryScanLabelBarcode'>{'*' + scanInfo.PartNumber + '*'}</p>
                                                </Grid>
                                                <br />
                                                <br />
                                                <br />
                                                <Grid container item direction="row" justifyContent="flex-start" alignItems="flex-start"  >
                                                    <p className={classes.labelTextSpace}>{scanInfo.PartNumber}</p>
                                                    <p className={classes.labelText}>{scanInfo.binTrayText}</p>
                                                </Grid>
                                                <Grid container item direction="row" justifyContent="flex-start" alignItems="flex-start" >
                                                    <p className='memoryScanLabelBarcode'>{'*' + scanInfo.PartNumber + '*'}</p>
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <Grid container item direction="row" justifyContent="flex-end" alignItems="center" >
                                            <Button type='button'
                                                size="small"
                                                variant="outlined"
                                                color="primary"
                                                // onClick={() => { handleCloseLabel(); printClick(); }}>Print</Button>
                                                onClick={() => { handleCloseLabel(); window.print(); }}>Print</Button>
                                        </Grid>

                                    </Card>
                                </Fade>
                            </Modal> */}

                            {/* <div className={classes.sectionToPrint} id="sectionToPrint"> */}
                            {/* <p >{scanInfo.PartNumber}</p> */}
                            {/* <p >Tray {scanInfo.BinNumber}</p> */}
                            {/* <h1 id="barcodeLabel">{'*' + scanInfo.PartNumber + '*'}</h1> */}
                            {/* <br /> */}
                            {/* <br /> */}
                            {/* <br /> */}
                            {/* <p >{scanInfo.PartNumber}</p> */}
                            {/* <p >Tray {scanInfo.BinNumber}</p> */}
                            {/* <p id="barcodeLabel">{'*' + scanInfo.PartNumber + '*'}</p> */}
                            {/* </div> */}
                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div >
            )
        }
    }
}