import React, { useContext, useEffect, useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { db, } from '../../../config/fbConfig';
import { makeStyles, Typography, Grid, Button, } from '@material-ui/core';
import { DataContext } from '../../../contexts/DataContext';
import MaterialTable from 'material-table';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        padding: '30px',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // width: '50%'
    },
    halfWidth: {
        textAlign: 'center',
        padding: '30px',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        width: '50%'
    },
    marginOne: {
        margin: theme.spacing(1),
    },
    marginTwo: {
        margin: theme.spacing(2),
    },
    selectTextfields: {
        width: '300px',
        margin: theme.spacing(1)
    }
}));

const locations = [
    { value: 'IE', label: 'IE' },
    { value: 'UK', label: 'UK' },
    { value: 'US', label: 'US' }
];
const departments = [
    { value: 'CS/Logistics/Purchasing', label: 'CS/Logistics/Purchasing' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Finance', label: 'Finance' },
    { value: 'HR', label: 'HR' },
    { value: 'IS&T', label: 'IS&T' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Operations', label: 'Operations' },
    { value: 'Quality and Compliance', label: 'Quality and Compliance' },
    { value: 'Sales', label: 'Sales' }
];
const managers = [
    // { value: '', label: '' },
    { value: 'CS/Logistics/Purchasing', label: 'CS/Logistics/Purchasing' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Finance', label: 'Finance' },
    { value: 'HR', label: 'HR' },
    { value: 'IS&T', label: 'IS&T' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Operations', label: 'Operations' },
    { value: 'Quality and Compliance', label: 'Quality and Compliance' },
    { value: 'Sales', label: 'Sales' }
];

const usersArray = [
    // 'Adam Horan',
    // 'Adam Cosgrove',
    // 'Adrian Leavy',
    // 'Agnieszka Kobos',
    // 'Aidan Long',
    // 'Alan Thompson',
    // 'Aleksandra Lewandowska',
    // 'Alen Laura',
    // 'Amy Norton',
    // "Andy O'Leary",
    // 'Anna Bajko',
    // 'ANNA Rachytska',
    // 'Anne Marie Hourihan',
    // "Arthur O'Donnell",
    // 'Barry Kavanagh',
    // 'Bartosz Kobasz',
    // 'Bernard Cronin',
    // 'Billy Cronin',
    // 'Blaise Faiski',
    // "Brendan O'Sullivan",
    // 'Brendan Reilly',
    // 'Brian Murphy',
    // 'Bruce Donachie',
    // 'Bruno Magalhaes',
    // "Caitlin O'Sullivan",
    // 'Cayo Mores',
    // 'Christine Fitzgerald',
    // "Christine O'Mahony",
    // "Christopher O'Neill",
    // 'Christopher Hull',
    // 'Ciara Delahunty',
    // 'Conor Murray',
    // 'Cormac Hourihane',
    // "Cormac O'Keefe",
    // 'Craig Heffernan',
    // 'Dalila Nadia Kapalka',
    // 'Damilare Atilola',
    // 'Daniel O Leary',
    // "Daniel O'Callaghan",
    // 'Danni Trzesicka',
    // "Danny O'Callaghan",
    // 'Danuta Osinska',
    // "Darren O'Hara",
    // 'David Daly',
    // 'David Nagle',
    // 'David Forde',
    // 'David Moran',
    // 'David Peard',
    // 'David Creedon',
    // 'David Gallagher',
    // 'Dawid Glaszcz',
    // 'Declan Cotter',
    // 'Declan Maguire',
    // 'Deirdre Arrigan',
    // "Denise O'Regan",
    // 'Derek Kelly',
    // 'Dermot Moane',
    // 'Diana Gui',
    // 'Diarmuid Cormack',
    // 'Dmytro Bitkov',
    // 'Dmytro Kravets',
    // 'Donnacha McEnery',
    // 'Dorota Wewiora',
    // "Eamonn O'Brien",
    // 'Edward Galvin',
    // 'Eileen McCarthy',
    // 'Eleanor Lester',
    // 'Eli Balbino Oliveira',
    // 'Ella Novachenko',
    // 'Elmer Shirata',
    // 'Emmanuel Adekunle',
    // 'Eric Hiixunyi',
    // 'Erika Vijner Kovacsne',
    // 'Evelyn Wiacek',
    // 'Gary McCarthy',
    // 'Gary Doyle',
    // "Gene O'Mahony",
    // 'Ger Sheehan',
    // "Gerard O'Rourke",
    // 'Grazyna Skawinski',
    // 'Greg Wysocki',
    // 'Halyna Lobiak',
    // 'Hazel Downey',
    // 'Hryn Olha',
    // 'Huilin Su',
    // 'Iryna Sliusar',
    // 'Iryna Goriashko',
    // 'Isaac Aggrey',
    // 'Isaac Herlihy',
    // 'James Grant',
    // "James O'Sullivan",
    // "Jamie O'Connell",
    // 'Jerry McCarthy',
    // 'Jessica Brooks',
    // 'Jibin Thomas',
    // 'Jim Sheehan',
    // 'Jim Kelly',
    // 'John Savage',
    // 'John Cormack',
    // 'John Murphy',
    // 'John Quinlan',
    // 'John Kelleher',
    // 'John McCarthy',
    // 'John Costello',
    // 'John Paul Collins',
    // "John Paul O'Driscoll",
    // "Joseph O'Meara",
    // 'Joseph Moynihan',
    // 'Julia Ordynets',
    // 'Julia Boiko',
    // 'Julio Andrade',
    // "Kate O'Connell",
    // 'Keith Broderick',
    // "Kelvin O'Donoghue",
    // "Keran O'Brien",
    // 'Kevin Keane',
    // "Kevin O'Meara",
    // 'Kieran Lyons',
    // "Kulsiriya O'Sullivan",
    // 'Larysa Rusina',
    // "Laura O'Connell",
    // "Lee O'Sullivan",
    // "Leroy O'Donovan",
    // 'Liam Sexton',
    // 'Liam Murray',
    // 'Lisa Speight',
    // 'Lisa Gahan',
    // 'Lito Jose',
    // 'Louise Burke',
    // 'Luke Downey',
    // 'Lynda McGregor',
    // "Mairead O'Leary",
    // 'Marcela Souza de Oliveira',
    // 'Marcus Aherne',
    // 'Marek Wewiora',
    // 'Maria Viola',
    // 'Mark Leo',
    // 'Mark Hackett',
    // "Martin O'Flynn",
    // "Martina O'Leary",
    // 'Mary Cunneen',
    // 'Mathew El Sonbokhty',
    // 'Maurice Higgins',
    // 'Medyanik Yrii',
    // 'Michael Maguire',
    // 'Michael Keogh',
    // 'Michael Keohane',
    // 'Michael Morrissey',
    // 'Michael Oduro',
    // "Michelle O'Sullivan",
    // 'Milica Vojnic',
    // "Neil O'Brien",
    // 'Nick Miatltski',
    // 'Norbert Szablewski',
    // 'Nuntakarn Maguire',
    // 'Nuria Grau Riera',
    // 'Oleksii Dovhan',
    // 'Olena Sinkova',
    // 'Olena Kupriianova',
    // 'Olivia Sheehan',
    // 'Oyinwonuola H Olatoye',
    // 'Pablo Segovia Aldecoa',
    // 'Pamella Lopes',
    // 'Patrick Healy',
    // 'Patryk Mocny',
    // "Paul O'Reilly",
    // 'Paul Cogan',
    // 'Paula Napolitano',
    // 'Pawel Gajewski',
    // 'Piotr Mitianiec',
    // 'Raven Rose',
    // 'Richard Mee',
    // 'Robert Daly',
    // 'Robert Kelly',
    // 'Roger Donegan',
    // 'Roman Yakuba',
    // 'Rory Lucey',
    // 'Rui Correia',
    // "Ryan O'Leary",
    // "Ryan O'Mahony",
    // 'Sasha Oleksandr',
    // 'Sean Sheehan',
    // 'Sean Bent',
    // 'Sean Power',
    // 'Shane Roche',
    // 'Sheila Madden',
    // 'Shi Li',
    // 'Shirley Twohig',
    // 'Sinead Malone',
    // 'Stepan Sliusar',
    // 'Subash Kumar',
    // 'Tadgh Killen',
    // 'Tak Chan',
    // 'Tara Keohane',
    // 'Thea Long',
    // 'Thomas Delahunty',
    // 'Tom Sobel',
    // 'Tomasz Bak',
    // 'Tony Linehan',
    // 'Valentyna Shkil',
    // 'Vigneshwar Sivakumar',
    // 'Vincent Cherry',
    // 'Vladysla Vlobanov',
    // "William O'Leary",
    // 'Wojciech Kobos',
    // 'Yuliia Chekmarova',
    // 'Zhanna Popovych'

    // 'Matt Summers',
    // 'Rolandas Bartasius',
    // 'Alin Anghel',
    // 'Daniela Anghel',
    // 'Jason Lowry',
    // 'Paul Martin',
    // 'Emma Adams',
    // 'Joanna Nazar',
    // 'Simon Pile',
    // 'Charles Gree',
    // 'Luke Orchard',
    // 'Katarzyna Szarek',
    // 'Felicity Davies',
    // 'Lisa March',
    // 'John Luke Tremain'

]

export default function WiseStarsList() {
    const classes = useStyles();

    const { listLocations, listDepartments, listManagers } = useContext(DataContext)

    const [wiseStarList, setWiseStarList] = useState({
        columns: [
            { title: 'Full Name', field: 'fullName' },
            { title: 'Id', field: 'id', editable: 'never', filtering: false },
            // { title: 'Ticket Type', field: 'TicketType', editable: 'never' }, //filtering: false
            // { title: 'Assigned IT', field: 'AssignedIt', editable: 'never' },
            // { title: 'Raised By', field: 'RaisedBy', editable: 'never' },
            // { title: 'Raised Date (yyyy/mm/dd)', field: 'DateRaised', type: 'date', editable: 'never', defaultSort: 'desc', filtering: false },
        ],
        data: []
    });
    const [wiseStarLocations, setWiseStarLocations] = useState({
        columns: [
            { title: 'Label', field: 'label' },
            { title: 'Value', field: 'value' },
        ],
        data: []
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function getWiseStarsList() {
        db.collection('wiseStarsList')
            .orderBy('fullName', 'asc').get()
            .then(snap => {
                var allWiseStars = []
                snap.forEach(star => {
                    var item = star.data()
                    item.id = star.id
                    allWiseStars.push(item)
                })
                setWiseStarList({
                    ...wiseStarList,
                    data: allWiseStars
                })
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: err.message,
                })
            })
    }
    function getWiseStarsLocations() {
        db.collection('wiseStarsLocations')
            .orderBy('label', 'asc').get()
            .then(snap => {
                var allWiseStarsLocations = []
                snap.forEach(star => {
                    var item = star.data()
                    item.id = star.id
                    allWiseStarsLocations.push(item)
                })
                setWiseStarLocations({
                    ...wiseStarLocations,
                    data: allWiseStarsLocations
                })
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: err.message,
                })
            })
    }
    useEffect(() => {
        getWiseStarsList()
        getWiseStarsLocations()
        return () => { }
    }, [])

    function addUsersFromArray(arr) {
        // var counter = 0
        // arr.forEach(el => {
        //     counter++
        //     db.collection('wiseStarsList').add({
        //         fullName: el
        //     })
        // })
        // setSnackbar({
        //     open: true,
        //     severity: 'success',
        //     message: `Added ${counter} records...`,
        // })
        setSnackbar({
            open: true,
            severity: 'success',
            message: `Go to code and uncomment the function for that button`,
        })
    }

    return (
        <div>
            <Typography variant="h6" className={classes.center}>
                WiseStars
            </Typography>
            <Typography variant="caption" className={classes.center}>
                Add or remove users from the WiseStars List. List is used for dropdown of people available for voting in quarterly WiseStars nominations. Add remove Location for WiseStars, same process as for users.
            </Typography>
            <Button variant='outlined' color='secondary' onClick={() => addUsersFromArray(usersArray)} >Add users from array to users list</Button>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">

                <div className={classes.halfWidth}>
                    <MaterialTable
                        title={"WiseStars Users List"}
                        columns={wiseStarList.columns}
                        data={wiseStarList.data}
                        // detailPanel={rowData => {
                        //     rowData.username = username; // assigning username for email purpose
                        //     // console.log(rowData)
                        //     return (
                        //         <TicketsITDetails ticketData={rowData} />
                        //     )
                        // }}
                        options={{
                            addRowPosition: 'first',
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 50, wiseStarList.data.length === 0 ? 100 : { value: wiseStarList.data.length, label: 'All' }]
                        }}
                        // options={{
                        //     actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50, wiseStarList.data.length === 0 ? 100 : { value: wiseStarList.data.length, label: 'All' }]
                        // }}
                        // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                        actions={
                            [{
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    getWiseStarsList()
                                },
                            }]}
                        editable={{
                            onRowAdd: newData =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        setWiseStarList(prevState => {
                                            const data = [...prevState.data];
                                            data.push(newData);

                                            return { ...prevState, data };
                                        })
                                        // Add data                                        
                                        db.collection("wiseStarsList").add({
                                            fullName: newData.fullName,
                                        })
                                            .then(() => {
                                                getWiseStarsList()
                                                resolve()
                                            })
                                    }, 600);
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        if (oldData) {
                                            setWiseStarList(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                return { ...prevState, data };
                                            })
                                            // Update data     
                                            db.collection("wiseStarsList").doc(newData.id).update({
                                                fullName: newData.fullName,
                                            })
                                                .then(() => {
                                                    getWiseStarsList()
                                                    resolve();
                                                })
                                        }
                                    }, 600)
                                }),
                            onRowDelete: oldData =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        setWiseStarList(prevState => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            return { ...prevState, data };
                                        })
                                        // Remove data                                        
                                        db.collection("wiseStarsList").doc(oldData.id).delete()
                                            .then(() => {
                                                getWiseStarsList()
                                                resolve()
                                            })
                                    }, 600);
                                }),
                        }}
                    // components={{
                    //     Toolbar: props => (
                    //         <div>
                    //             <MTableToolbar {...props} />
                    //             <div >
                    //                 <p className={classes.leftMargin}>Hey {firstName}! We need to focus on those {ticketsIT.openTickets} open tickets.</p>
                    //             </div>
                    //         </div>
                    //     ),
                    // }}
                    />
                </div>
                <div className={classes.halfWidth}>
                    <MaterialTable
                        title={"WiseStars Locations"}
                        columns={wiseStarLocations.columns}
                        data={wiseStarLocations.data}
                        // detailPanel={rowData => {
                        //     rowData.username = username; // assigning username for email purpose
                        //     // console.log(rowData)
                        //     return (
                        //         <TicketsITDetails ticketData={rowData} />
                        //     )
                        // }}
                        options={{
                            addRowPosition: 'first',
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 50, wiseStarLocations.data.length === 0 ? 100 : { value: wiseStarLocations.data.length, label: 'All' }]
                        }}
                        // options={{
                        //     actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50, wiseStarList.data.length === 0 ? 100 : { value: wiseStarList.data.length, label: 'All' }]
                        // }}
                        // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                        actions={
                            [{
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    getWiseStarsLocations()
                                },
                            }]}
                        editable={{
                            onRowAdd: newData =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        setWiseStarLocations(prevState => {
                                            const data = [...prevState.data];
                                            data.push(newData);

                                            return { ...prevState, data };
                                        })
                                        // Add data                                        
                                        db.collection("wiseStarsLocations").add({
                                            label: newData.label,
                                            value: newData.value,
                                        })
                                            .then(() => {
                                                getWiseStarsLocations()
                                                resolve()
                                            })
                                    }, 600);
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        if (oldData) {
                                            setWiseStarLocations(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                return { ...prevState, data };
                                            })
                                            // Update data     
                                            db.collection("wiseStarsLocations").doc(newData.id).update({
                                                label: newData.label,
                                                value: newData.value,
                                            })
                                                .then(() => {
                                                    getWiseStarsLocations()
                                                    resolve();
                                                })
                                        }
                                    }, 600)
                                }),
                            onRowDelete: oldData =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        setWiseStarLocations(prevState => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            return { ...prevState, data };
                                        })
                                        // Remove data                                        
                                        db.collection("wiseStarsLocations").doc(oldData.id).delete()
                                            .then(() => {
                                                getWiseStarsLocations()
                                                resolve()
                                            })
                                    }, 600);
                                }),
                        }}
                    // components={{
                    //     Toolbar: props => (
                    //         <div>
                    //             <MTableToolbar {...props} />
                    //             <div >
                    //                 <p className={classes.leftMargin}>Hey {firstName}! We need to focus on those {ticketsIT.openTickets} open tickets.</p>
                    //             </div>
                    //         </div>
                    //     ),
                    // }}
                    />
                </div>
            </Grid>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    );
}