import React, { useContext } from 'react';
import { Grid, Card } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Footer from '../../../Footer';
import Loading from '../../../layout/Loading';
import eMail from '../../../../images/eMail.gif'
import TicketFormHR from './TicketFormHR';
import SideMenuV2 from '../../SideMenus/SideMenuV2';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '15%',
    },
    cardTicket: {
        padding: '30px',
    },
}));

export default function TicketingHRConsole(props) {

    const classes = useStyles();
    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline >
                        <SideMenuV2 selectedIndex={4} openTicketingColapse={true} />
                        <div className={classes.main}>
                            {/* <h1>Ticketing Console - HR</h1> */}

                            <Card className={classes.cardTicket}>

                                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                    <img className={classes.media} src={eMail} alt="send ticket" />
                                </Grid>

                                <TicketFormHR />

                            </Card>
                        </div>

                    </CssBaseline>
                    <Footer />
                </div>
            );
        }
    }
}