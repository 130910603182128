import React, { Component, createContext } from 'react';
import firebase, { auth, db } from '../config/fbConfig';

export const AuthContext = createContext();

class AuthContextProvider extends Component {
    state = {
        loading: true,
        displayName: '',
        role: '',
        uid: '',
        firstName: '',
        lastName: '',
        email: '',
        initials: '',
        isAuthenticated: false,
        emailVerified: false,
        authError: '',
        department: '',
        holidayApprovingManager: '',
        availableDays: 0,
        accumulatedApprovedDays: 0,
        accumulatedRemainingDays: 0,
        accumulatedUnpaidLeaveTaken: 0,
        shiftWorker: false,
        location: '',
        username: '',
        roles: []

    }

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if (user) {
                // console.log('Logged in : ' + JSON.stringify(user.metadata));

                this.setState({
                    displayName: user.displayName,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    // loading: false,
                    uid: user.uid,
                    // role: user.role,
                    isAuthenticated: true,
                })
                //get user db data

                db.collection('users').doc(user.uid).get()
                    .then(resp => {
                        const data = resp.data();
                        if (data) {
                            this.setState({
                                firstName: data.firstName,
                                initials: data.initials,
                                lastName: data.lastName,
                                // role: data.role,
                                department: data.department,
                                holidayApprovingManager: data.holidayApprovingManager,
                                availableDays: data.availableDays,
                                accumulatedApprovedDays: data.accumulatedApprovedDays,
                                accumulatedRemainingDays: data.accumulatedRemainingDays,
                                accumulatedUnpaidLeaveTaken: data.accumulatedUnpaidLeaveTaken,
                                shiftWorker: data.shiftWorker,
                                location: data.location,
                                username: data.userEmail,// for users without emails
                                roles: data.roles,
                                loading: false,
                            })
                        }
                    })
                    .catch(function (error) {
                        console.log("Error getting document: ", error);
                    })
                    .then(() => {
                        console.log('First name is: ' + this.state.firstName)
                        console.log('Last name is: ' + this.state.lastName)
                        // var user = auth.currentUser;
                        // user.updateProfile({
                        //     displayName: this.state.firstName + ' ' + this.state.lastName
                        // })
                        //     .catch(error => {
                        //         // Handle Errors here.
                        //         var errorCode = error.code;
                        //         var errorMessage = error.message;
                        //     })
                    })
                //console.log(user.displayName)

                // db.collection('dropdowns').get()
                //     .then(snapshot => {
                //         snapshot.forEach(doc => {
                //             console.log(doc.id, '=>', doc.data());
                //         });
                //     })
                //     .catch(function (error) {
                //         console.log("Error getting document: ", error);
                //     });
            }
            else {
                // User not signed in
                console.log("User not authenticated...");
                this.setState({
                    displayName: '',
                    email: '',
                    emailVerified: false,
                    firstName: '',
                    initials: '',
                    lastName: '',
                    uid: '',
                    // role: '',
                    isAuthenticated: false,
                    department: '',
                    availableDays: 0,
                    availableDays2022: 0,
                    accumulatedApprovedDays: 0,
                    accumulatedRemainingDays: 0,
                    accumulatedUnpaidLeaveTaken: 0,
                    shiftWorker: false,
                    location: '',
                    username: '',
                    roles: [],
                    loading: false,
                });
                console.log('Sign out...')
                auth.signOut();
            }
        });
    };

    signIn = (credentials) => {
        return new Promise((resolve, reject) => {

            auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
                .then(() => {

                    var username = ''
                    if (credentials.email.toLowerCase().includes('@')) {
                        username = credentials.email.toLowerCase()
                    }
                    else {
                        username = credentials.email.toLowerCase() + '@noemail.wisetek.net'
                    }
                    auth.signInWithEmailAndPassword(
                        username,
                        credentials.password
                    )
                        .then(resp => {
                            resolve('Login successful.')
                        })
                        .catch(error => {
                            switch (error.code) {
                                case 'auth/invalid-email':
                                    reject(`Username ${credentials.email} is invalid.`);
                                    break;
                                case 'auth/user-disabled':
                                    reject(`Username ${credentials.email} has been disabled.`);
                                    break;
                                case 'auth/user-not-found':
                                    reject(`Username ${credentials.email} does not exist.`);
                                    break;
                                case 'auth/wrong-password':
                                    reject('Username or Password does not match.');
                                    break;
                                default:
                                    reject(error);
                                    break;
                            }
                        })
                })
        })
    }

    signOut = () => {
        console.log('Sign out done...')
        auth.signOut();
    }

    signUp = (credentials) => {
        return new Promise((resolve, reject) => {

            auth.createUserWithEmailAndPassword(
                credentials.email,
                credentials.password
            )
                .then((resp) => {
                    resp.user.updateProfile({
                        displayName: this.Capitalize(credentials.firstName) + ' ' + this.Capitalize(credentials.lastName)
                    }).then(() => {
                        var location = '';
                        var email = credentials.email.toLowerCase();
                        if (email.includes('@wisetek.net')) {
                            location = 'IE'
                        }
                        if (email.includes('@wisetek.co.uk')) {
                            location = 'UK'
                        }
                        if (email.includes('@wisetekusa.com')) {
                            location = 'US'
                        }
                        db.collection('users').doc(resp.user.uid).set({
                            userEmail: (credentials.email).toLowerCase(),
                            firstName: this.Capitalize(credentials.firstName),
                            lastName: this.Capitalize(credentials.lastName),
                            initials: (credentials.firstName[0] + credentials.lastName[0]).toUpperCase(),
                            role: 'member',
                            department: credentials.department,
                            availableDays: 0,
                            accumulatedApprovedDays: 0,
                            accumulatedRemainingDays: 0,
                            accumulatedUnpaidLeaveTaken: 0,
                            shiftWorker: false,
                            holidayApprovingManager: '',
                            location: location,
                            roles: ['member']

                            // userEmail
                            // firstName
                            // lastName
                            // initials
                            // role
                            // department
                            // availableDays
                            // accumulatedApprovedDays
                            // accumulatedRemainingDays
                            // accumulatedUnpaidLeaveTaken
                            // shiftWorker
                            // holidayApprovingManager
                            // location
                            // roles

                        }).catch(err => {
                            console.log(err);
                        })
                    })
                })
                .then(() => {
                    auth.currentUser.sendEmailVerification()
                        .then(() => {
                            resolve('Verify email was sent.\nPlease check your mail.');

                        }).catch(err => {
                            console.log(err);
                        })
                })
                .catch(error => {
                    switch (error.code) {
                        case 'auth/email-already-in-use':
                            reject(`Email address ${credentials.email} is already in use.`);
                            break;
                        case 'auth/invalid-email':
                            reject(`Email address ${credentials.email} is invalid.`);
                            break;
                        case 'auth/operation-not-allowed':
                            reject(`Error during sign up.`);
                            break;
                        case 'auth/weak-password':
                            reject('Password is not strong enough. Add additional characters including special characters and numbers.');
                            break;
                        default:
                            reject(error);
                            break;
                    }
                })
        })
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    verifyEmail = () => {
        return new Promise((resolve, reject) => {
            auth.currentUser.sendEmailVerification()
                .then(() => {
                    console.log('Verify email was sent.');
                    resolve('Verify email was sent.\nPlease check your mail.');
                })
                .catch(error => {
                    console.log(error);
                    reject('Unable to send verification mail.\nPlease check internet connection.')
                })

        })
    }

    resetPasswordEmail = (state) => {
        const emailAddress = state.email;

        return new Promise((resolve, reject) => {
            auth.sendPasswordResetEmail(emailAddress)
                .then(() => {
                    // Email sent.
                    resolve('Reset password email was sent.\nPlease check your mail.');
                })
                .catch((error) => {
                    // An error happened.
                    switch (error.code) {
                        case 'auth/invalid-email':
                            reject('Email address is not valid.')
                            break;
                        case 'auth/user-not-found':
                            reject('There is no user corresponding to this email address.\nPlease sign up and validate your email.')
                            break;

                        default:
                            reject(error)
                            break;
                    }
                })
        })
    }

    render() {

        return (
            <AuthContext.Provider value={{
                ...this.state,
                signIn: this.signIn,
                signOut: this.signOut,
                signUp: this.signUp,
                verifyEmail: this.verifyEmail,
                resetPasswordEmail: this.resetPasswordEmail,
            }}>
                {this.props.children}
            </AuthContext.Provider >
        );
    }
}

export default AuthContextProvider;