import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Container, TextField, CssBaseline, Grid, Card, Button, InputBase } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Loading from '../../Loading';
import { db } from '../../../../config/fbConfig';
import { DataContext } from '../../../../contexts/DataContext';
import Footer from '../../../Footer';
import moment from 'moment';
import MaterialTable from 'material-table';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';
import Barcode from 'react-barcode';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    marginAutoRight: {
        // display: 'none',
        marginRight: 'auto'
    },
    marginAutoLeft: {
        marginLeft: 'auto'
    },
    textFieldBox: {
        width: '100%',
        height: '65px',
        marginTop: '0px',
        marginBottom: '15px',
        // textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
    },
    inputBaseScan: {
        padding: '10px',
        fontSize: '1.5em',
    },
    outputBinText: {
        marginTop: '35px',
        marginBottom: '0px',
    },
    outputTrayText: {
        margin: '10px',
        fontSize: '3em',
        color: 'green'
    },
    clearInputButtonMargin: {
        marginRight: '15px'
    },
    labelText: {
        fontSize: '11px',
        marginBottom: '0px'
    },
    labelTextSpace: {
        fontSize: '11px',
        marginRight: '15px',
        marginBottom: '0px'
    },
    labelCard: {
        padding: '20px',
        // position: 'absolute',
        // // top: '50%',
        // // left: '50%',
        // // transform: 'translate(-50%, -50%)',
        // // transform: 'translate(50%, 50%)',
        // width: '40%',
        // maxHeight: '90%',
        // overflow: 'hidden',
        // // width: '265px', //70mm
        // // height: '151px', //40mm
        top: 0,
        left: 0,
        // // width: 400,
    },
    // sectionToPrint: {
    //     position: 'absolute',
    //     width: '265px', //70mm
    //     height: '151px', //40mm
    //     overflow: 'hidden',
    //     // left: '0',
    //     // top: '0',
    //     display: 'none'
    // },
    marginBottom: {
        marginBottom: '45px'
    },
    partsAndBinsTable: {
        marginTop: '25px',
        // paddingLeft: '25px',
        // paddingRight: '25px'
    },

}));

const midsForPartNo = [
    { value: '9100008993', label: 'USED 65W POWER ADAPTERS' },
    { value: '9100008986', label: 'USED 85W POWER ADAPTERS' },
    { value: '9100008093', label: 'USED POWER ADAPTERS (Rest)' },
    { value: '9100008100', label: 'USED MICE' },
    { value: '9100008107', label: 'USED CABLES' },
    { value: '9100008079', label: 'USED KEYBOARDS' },
    { value: '9100009000', label: 'USED USB HUBS' },
    { value: '9100008108', label: 'USED POWER CORDS' },
]

export default function PpidLabel() {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    const [scanInfo, setScanInfo] = useState({
        PartNo: '',
        PartNoBarcode: '',
        PPID: '',
        PPIDBarcode: '',
        Date: moment().utc().format('MM/DD/YYYY'),
        DateBarcode: `${moment().utc().format('MM/DD/YYYY')}`,
        AssemblyRev: '',
        AssemblyRevBarcode: '',
        BiosRev: '',
        BiosRevBarcode: '',
    });

    //Snackbar
    //#region 
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    const handleChange = (e) => {

        switch (e.target.name) {
            case 'PartNo':
                setScanInfo({
                    ...scanInfo,
                    [e.target.name]: e.target.value.toUpperCase(),
                    PartNoBarcode: e.target.value.toUpperCase(),
                })
                break;
            case 'PPID':
                setScanInfo({
                    ...scanInfo,
                    [e.target.name]: e.target.value.toUpperCase(),
                    PPIDBarcode: e.target.value.toUpperCase(),
                })
                break;
            case 'AssemblyRev':
                setScanInfo({
                    ...scanInfo,
                    [e.target.name]: e.target.value.toUpperCase(),
                    AssemblyRevBarcode: e.target.value.toUpperCase(),
                })
                break;
            case 'BiosRev':
                setScanInfo({
                    ...scanInfo,
                    [e.target.name]: e.target.value.toUpperCase(),
                    BiosRevBarcode: e.target.value.toUpperCase(),
                })
                break;

            default:
                setScanInfo({
                    ...scanInfo,
                    [e.target.name]: e.target.value.toUpperCase(),
                })
                break;
        }
    }

    const handleClearForm = (e) => {
        setScanInfo({
            ...scanInfo,
            PartNo: '',
            PartNoBarcode: '',
            PPID: '',
            PPIDBarcode: '',
            Date: moment().utc().format('MM/DD/YYYY'),
            DateBarcode: `${moment().utc().format('MM/DD/YYYY')}`,
            AssemblyRev: '',
            AssemblyRevBarcode: '',
            BiosRev: '',
            BiosRevBarcode: '',
        })
        document.getElementById('PartNo').focus();
    }

    function handlePrintLabel(scaninfo) {
        var srcDoc = '<html><head><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&family=Roboto:wght@100&display=swap" rel="stylesheet"><link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap" rel="stylesheet"><style> #barcodeText { font-family: "Helvetica", "Arial", sans-serif;font-size: 11px; margin-top: 5px; margin-bottom: 15px; } #barcode { font-family: "Libre Barcode 39"; font-size: 24px; margin: 6px; } #center { text-align: center !important; margin: 0px } #size65 { width: 60%; float: left; } #size35 { width: 39%; float: right; } </style></head><body><div><h2 id="center">REFURBISHED</h2><div><div id="size65"><p id="barcode">*' + scaninfo.PartNo + '*</p><p id="barcodeText">D P/N: ' + scaninfo.PartNo + '</p><p id="barcode">*' + scaninfo.PPIDBarcode + '*</p><p id="barcodeText">PPID: ' + scaninfo.PPID + '</p><p id="barcode">*' + scaninfo.DateBarcode + '*</p><p id="barcodeText">' + scaninfo.Date + '</p><p id="barcodeText">DATE OF LABEL APPLICATION</p></div><div id="size35"><h2>' + scaninfo.PartNo + '</h2><p id="barcode">*' + scaninfo.AssemblyRevBarcode + '*</p><p id="barcodeText">ASSEMBLY REV: ' + scaninfo.AssemblyRev + '</p><p id="barcode">*' + scaninfo.BiosRevBarcode + '*</p><p id="barcodeText">BIOS REV: ' + scaninfo.BiosRev + '</p></div></div></div></body></html>'

        var iframe = document.createElement('iframe');
        iframe.srcdoc = srcDoc
        iframe.style.display = "none"
        document.body.appendChild(iframe)
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log('submiting scan')
    // }

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={57} openProductionColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Production - Wise Label</h1> */}

                            <Card>
                                <Container component="main" className={classes.main} maxWidth="xl">

                                    {/* <form id="googleAccessories" onSubmit={handleSubmit}> */}
                                    <form id="ppidForm" >

                                        <h3 className={classes.center}>PPID Label</h3>
                                        {/* <p><b>Note: </b>Part Number and Bin Code will be translated to capital letters for consistency purpose.</p> */}
                                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>

                                            <Grid container item xs={12} sm={6} spacing={1}>
                                                <p>Scan Part Number below</p>
                                                <div className={classes.textFieldBox}>
                                                    <InputBase
                                                        className={classes.inputBaseScan}
                                                        autoFocus={true}
                                                        // select
                                                        // required
                                                        autoComplete="off"
                                                        variant="standard"
                                                        fullWidth
                                                        id="PartNo"
                                                        label="Scan part number here"
                                                        name="PartNo"
                                                        value={scanInfo.PartNo}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 30 }}
                                                    >
                                                    </InputBase>
                                                </div>
                                                <p>Scan PPID below</p>
                                                <div className={classes.textFieldBox}>
                                                    <InputBase
                                                        className={classes.inputBaseScan}
                                                        // autoFocus={true}
                                                        // select
                                                        // required
                                                        autoComplete="off"
                                                        variant="standard"
                                                        fullWidth
                                                        id="PPID"
                                                        label="Scan PPID here"
                                                        name="PPID"
                                                        value={scanInfo.PPID}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 30 }}
                                                    >
                                                    </InputBase>
                                                </div>
                                                <p>Enter Assembly Rev below</p>
                                                <div className={classes.textFieldBox}>
                                                    <InputBase
                                                        className={classes.inputBaseScan}
                                                        // autoFocus={true}
                                                        // select
                                                        // required
                                                        autoComplete="off"
                                                        variant="standard"
                                                        fullWidth
                                                        id="AssemblyRev"
                                                        label="Enter Assembly Rev here"
                                                        name="AssemblyRev"
                                                        value={scanInfo.AssemblyRev}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 30 }}
                                                    >
                                                    </InputBase>
                                                </div>
                                                <p>Enter BIOS Rev below</p>
                                                <div className={classes.textFieldBox}>
                                                    <InputBase
                                                        className={classes.inputBaseScan}
                                                        // autoFocus={true}
                                                        // select
                                                        // required
                                                        autoComplete="off"
                                                        variant="standard"
                                                        fullWidth
                                                        id="BiosRev"
                                                        label="Enter Bios Rev here"
                                                        name="BiosRev"
                                                        value={scanInfo.BiosRev}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 30 }}
                                                    >
                                                    </InputBase>
                                                </div>
                                                <div className={classes.textFieldBox}>
                                                    <p>PPID example: 000002TR1241104K3XWXJALOR0</p>
                                                </div>
                                                <Button
                                                    className={classes.clearInputButtonMargin}
                                                    type="button"
                                                    size="small"
                                                    // className={classes.buttonHidden}
                                                    // variant="contained"
                                                    // color="primary"
                                                    variant="outlined"
                                                    onClick={handleClearForm}>
                                                    Clear Input
                                                </Button>

                                                <Button
                                                    className={classes.marginAutoRight}
                                                    type="button"
                                                    size="small"
                                                    // className={classes.buttonHidden}
                                                    // variant="contained"
                                                    // color="primary"
                                                    variant="outlined"
                                                    onClick={() => handlePrintLabel(scanInfo)}
                                                >
                                                    Label
                                                </Button>

                                                {/* <Button
                                                    type="submit"
                                                    size="small"
                                                    className={classes.marginAutoLeft}
                                                    variant="outlined"
                                                    color="primary">
                                                    Submit
                                                </Button> */}

                                                {/* <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                                                    <h2 className={classes.outputBinText}>{scanInfo.binHelperText}</h2>
                                                </Grid>
                                                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                                                    <p className={classes.outputTrayText}>
                                                        {scanInfo.binTrayText}
                                                    </p>
                                                </Grid> */}

                                            </Grid>

                                            <Grid container item xs={12} sm={6} spacing={1}>

                                                <Grid container item style={{ marginTop: 44 }}>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        disabled
                                                        // required
                                                        fullWidth
                                                        id="PartNo"
                                                        label="Part No"
                                                        name="PartNo"
                                                        value={scanInfo.PartNo}
                                                        onChange={handleChange}>
                                                    </TextField>
                                                </Grid>
                                                <Grid container item style={{ height: 60, marginBottom: 12 }} >

                                                    {scanInfo.PartNoBarcode ? <Barcode value={scanInfo.PartNoBarcode} format='CODE39' height={25} displayValue={false} />
                                                        :
                                                        <div style={{ height: 25 }}></div>}
                                                    {/* those are available attributes for Barcode
                                                        width: 2,
                                                        height: 100,
                                                        format: "CODE128",
                                                        displayValue: true,
                                                        fontOptions: "",
                                                        font: "monospace",
                                                        textAlign: "center",
                                                        textPosition: "bottom",
                                                        textMargin: 2,
                                                        fontSize: 20,
                                                        background: "#ffffff",
                                                        lineColor: "#000000",
                                                        margin: 10,
                                                        marginTop: undefined,
                                                        marginBottom: undefined,
                                                        marginLeft: undefined,
                                                        marginRight: undefined */}
                                                </Grid>
                                                <Grid container item style={{ marginTop: 8 }}>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        disabled
                                                        // required
                                                        fullWidth
                                                        id="PPID"
                                                        label="PPID"
                                                        name="PPID"
                                                        value={scanInfo.PPID}
                                                        onChange={handleChange}   >
                                                    </TextField>
                                                </Grid>

                                                <Grid container item style={{ height: 60, marginBottom: 12 }}>

                                                    {scanInfo.PPIDBarcode ? <Barcode value={scanInfo.PPIDBarcode} format='CODE39' height={25} displayValue={false} /> :
                                                        <div style={{ height: 25 }}></div>}
                                                </Grid>
                                                <Grid container item style={{ marginTop: 8 }}>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        disabled
                                                        // required
                                                        fullWidth
                                                        id="AssemblyRev"
                                                        label="Assembly Rev"
                                                        name="AssemblyRev"
                                                        value={scanInfo.AssemblyRev}
                                                        onChange={handleChange}   >
                                                    </TextField>
                                                </Grid>
                                                <Grid container item style={{ height: 60, marginBottom: 12 }}>

                                                    {scanInfo.AssemblyRevBarcode ? <Barcode value={scanInfo.AssemblyRevBarcode} format='CODE39' height={25} displayValue={false} /> :
                                                        <div style={{ height: 25 }}></div>}
                                                </Grid>

                                                <Grid container item style={{ marginTop: 8 }}>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        disabled
                                                        // required
                                                        fullWidth
                                                        id="BiosRev"
                                                        label="Bios Rev"
                                                        name="BiosRev"
                                                        inputProps={{ maxLength: 2 }}
                                                        value={scanInfo.BiosRev}
                                                        onChange={handleChange}   >
                                                    </TextField>
                                                </Grid>
                                                <Grid container item style={{ height: 60, marginBottom: 12 }}>

                                                    {scanInfo.BiosRevBarcode ? <Barcode value={scanInfo.BiosRevBarcode} format='CODE39' height={25} displayValue={false} /> :
                                                        <div style={{ height: 25 }}></div>}
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </form>

                                </Container>
                            </Card>

                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div >
            )
        }
    }
}