import { CssBaseline, makeStyles, LinearProgress, Typography, Chip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import { Grid } from '@material-ui/core';
import ChartistGraph from 'react-chartist'
import ctPointLabels from 'chartist-plugin-pointlabels'
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import { DataContext } from '../../../../contexts/DataContext';
import { db } from '../../../../config/fbConfig';
import Chartist from 'chartist';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
    },
    searchProgress: {
        // visibility: 'hidden',
        marginBottom: '15px'
    },
    graphHeader: {
        marginTop: '100px',
        textAlign: 'center'
    },
    green: {
        width: '20px',
        color: 'rgb(20, 150, 30)',  // green
        backgroundColor: 'rgb(20, 150, 30)',  // green
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    blue: {
        width: '20px',
        color: 'rgb(50, 50, 240)', // blue
        backgroundColor: 'rgb(50, 50, 240)', // blue
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    grey: {
        width: '20px',
        color: 'rgb(110, 110, 110)', // grey
        backgroundColor: 'rgb(110, 110, 110)', // grey
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    orange: {
        width: '20px',
        color: 'rgb(200, 110, 10)', // orange
        backgroundColor: 'rgb(200, 110, 10)', // orange
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
}));

export default function MetricsQualityCapaVol(props) {
    const classes = useStyles();
    const { addCapaVol, deleteCapaVol } = useContext(DataContext);
    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    const year = moment().get('year');
    const week = moment().get('isoWeek');

    const [capaVol, setCapaVol] = useState({
        // Year: 0,
        // Week: 0,
        // Open: 0,
        // New: 0,
        // Closed: 0,
        // OpenIre: 0,
        // NewIre: 0,
        // ClosedIre: 0,
        // OpenUS: 0,
        // NewUS: 0,
        // ClosedUS: 0,
        // OpenThai: 0,
        // NewThai: 0,
        // ClosedThai: 0,
        // OpenUK: 0,
        // NewUK: 0,
        // ClosedUK: 0,
        // AverageAging: 0,
        // AverageAgingIre: 0,
        // OldestIre: 0,
        // AverageAgingUS: 0,
        // OldestUS: 0,
        // AverageAgingThai: 0,
        // OldestThai: 0,
        // AverageAgingUK: 0,
        // OldestUK: 0,
        // Goal: 0,
        columns: [
            { title: 'Year', field: 'Year', type: 'numeric', filtering: false, initialEditValue: year },
            { title: 'Week', field: 'Week', type: 'numeric', filtering: false, initialEditValue: week },
            { title: 'Open', field: 'Open', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'New', field: 'New', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'Closed', field: 'Closed', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'Open Ireland', field: 'OpenIre', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'New Ireland', field: 'NewIre', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Closed Ireland', field: 'ClosedIre', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Open US', field: 'OpenUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'New US', field: 'NewUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Closed US', field: 'ClosedUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Open Thailand', field: 'OpenThai', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'New Thailand', field: 'NewThai', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'Closed Thailand', field: 'ClosedThai', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'Open UK', field: 'OpenUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'New UK', field: 'NewUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'Closed UK', field: 'ClosedUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'Average Aging', field: 'AverageAging', type: 'numeric', filtering: false, initialEditValue: 0 },
            { title: 'Average Aging Ireland', field: 'AverageAgingIre', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Oldest Ireland', field: 'OldestIre', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'Average Aging US', field: 'AverageAgingUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Oldest US', field: 'OldestUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'Average Aging Thailand', field: 'AverageAgingThai', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'Oldest Thailand', field: 'OldestThai', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'Average Aging UK', field: 'AverageAgingUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'Oldest UK', field: 'OldestUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(210, 180, 30, 0.2)' } },
            { title: 'Goal', field: 'Goal', type: 'numeric', filtering: false, initialEditValue: 42 }
        ],
        data: [],
    });
    const [averageIre, setAverageIre] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })
    const [averageUS, setAverageUS] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })
    const [averageThai, setAverageThai] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })
    const [openNewClosedIre, setOpenNewClosedIre] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })
    const [openNewClosedUS, setOpenNewClosedUS] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })
    const [openNewClosedThai, setOpenNewClosedThai] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v : 0 // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })
    const [capaOpen, setCapaOpen] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    })
    const [capaIre, setCapaIre] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    })
    const [capaUS, setCapaUS] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function getMaxFrom2dAarray(a) {
        return Math.max(...a.map(e => Array.isArray(e) ? getMaxFrom2dAarray(e) : e));
    }
    function getMinFrom2dAarray(a) {
        return Math.min(...a.map(e => Array.isArray(e) ? getMinFrom2dAarray(e) : e));
    }

    const weekNumbers = [
        moment().day(-77).isoWeek(),
        moment().day(-70).isoWeek(),
        moment().day(-63).isoWeek(),
        moment().day(-56).isoWeek(),
        moment().day(-49).isoWeek(),
        moment().day(-42).isoWeek(),
        moment().day(-35).isoWeek(),
        moment().day(-28).isoWeek(),
        moment().day(-21).isoWeek(),
        moment().day(-14).isoWeek(),
        moment().day(-7).isoWeek(),
        moment().day(0).isoWeek(),
        moment().day(7).isoWeek() // current isoWeek
    ]

    function getCalculatedLabels() {
        return [
            'Week ' + weekNumbers[0],
            'Week ' + weekNumbers[1],
            'Week ' + weekNumbers[2],
            'Week ' + weekNumbers[3],
            'Week ' + weekNumbers[4],
            'Week ' + weekNumbers[5],
            'Week ' + weekNumbers[6],
            'Week ' + weekNumbers[7],
            'Week ' + weekNumbers[8],
            'Week ' + weekNumbers[9],
            'Week ' + weekNumbers[10],
            'Week ' + weekNumbers[11],
            'Week ' + weekNumbers[12]
        ]
    }

    const handleGraphChangeClick = (e) => {

        var optionId = e.target.id

        switch (optionId) {
            case 'AverageAgingIre':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'OldestIre':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'GoalIre':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'AverageAgingUS':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'OldestUS':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'GoalUS':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'AverageAgingThai':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[2].style.visibility == '' || graph[2].style.visibility == 'visible' ? graph[2].style.visibility = 'hidden' : graph[2].style.visibility = 'visible'
                break;
            case 'OldestThai':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[2].style.visibility == '' || graph[2].style.visibility == 'visible' ? graph[2].style.visibility = 'hidden' : graph[2].style.visibility = 'visible'
                break;
            case 'GoalThai':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[2].style.visibility == '' || graph[2].style.visibility == 'visible' ? graph[2].style.visibility = 'hidden' : graph[2].style.visibility = 'visible'
                break;
            case 'OpenIre':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[3].style.visibility == '' || graph[3].style.visibility == 'visible' ? graph[3].style.visibility = 'hidden' : graph[3].style.visibility = 'visible'
                break;
            case 'NewIre':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[3].style.visibility == '' || graph[3].style.visibility == 'visible' ? graph[3].style.visibility = 'hidden' : graph[3].style.visibility = 'visible'
                break;
            case 'ClosedIre':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[3].style.visibility == '' || graph[3].style.visibility == 'visible' ? graph[3].style.visibility = 'hidden' : graph[3].style.visibility = 'visible'
                break;
            case 'OpenUS':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[4].style.visibility == '' || graph[4].style.visibility == 'visible' ? graph[4].style.visibility = 'hidden' : graph[4].style.visibility = 'visible'
                break;
            case 'NewUS':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[4].style.visibility == '' || graph[4].style.visibility == 'visible' ? graph[4].style.visibility = 'hidden' : graph[4].style.visibility = 'visible'
                break;
            case 'ClosedUS':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[4].style.visibility == '' || graph[4].style.visibility == 'visible' ? graph[4].style.visibility = 'hidden' : graph[4].style.visibility = 'visible'
                break;
            case 'OpenThai':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[5].style.visibility == '' || graph[5].style.visibility == 'visible' ? graph[5].style.visibility = 'hidden' : graph[5].style.visibility = 'visible'
                break;
            case 'NewThai':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[5].style.visibility == '' || graph[5].style.visibility == 'visible' ? graph[5].style.visibility = 'hidden' : graph[5].style.visibility = 'visible'
                break;
            case 'ClosedThai':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[5].style.visibility == '' || graph[5].style.visibility == 'visible' ? graph[5].style.visibility = 'hidden' : graph[5].style.visibility = 'visible'
                break;

            default:
                break;
        }
    }

    function calcWeeklyVars(data) {
        // console.log(data)
        var capaVolData = [];
        var averageIreGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //AverageAgingIre
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OldestIre
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //Goal
        ]
        var averageUSGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //AverageAgingUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OldestUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //Goal
        ]
        var averageThaiGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //AverageAgingThai
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OldestThai
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //Goal
        ]
        var openNewClosedIreGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OpenIre
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //NewIre
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //ClosedIre
        ]
        var openNewClosedUSGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OpenUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //NewUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //ClosedUS
        ]
        var openNewClosedThaiGraphCalc = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OpenThai
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //NewThai
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //ClosedThai
        ]
        var capaOpenNewClosed = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //Open
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //New
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //Closed
        ]
        var capaIreOpenNewClosed = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OpenIre
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //NewIre
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //ClosedIre
        ]
        var capaUSOpenNewClosed = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //OpenThai
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //NewThai
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]  //ClosedThai
        ]

        data.forEach(doc => {
            // console.log(doc.id)
            var capaVolRow = doc.data()
            capaVolRow.id = doc.id
            capaVolData.push(capaVolRow)
            var positionWeek = weekNumbers.findIndex(week => week === doc.data().Week)
            // console.log(positionWeek)
            if (positionWeek >= 0) {
                var averageAgingIre = doc.data().AverageAgingIre
                var oldestIre = doc.data().OldestIre
                var goal = doc.data().Goal
                var averageAgingUS = doc.data().AverageAgingUS
                var oldestUS = doc.data().OldestUS
                var averageAgingThai = doc.data().AverageAgingThai
                var oldestThai = doc.data().OldestThai
                var openIre = doc.data().OpenIre
                var newIre = doc.data().NewIre
                var closedIre = doc.data().ClosedIre
                var openUS = doc.data().OpenUS
                var newUS = doc.data().NewUS
                var closedUS = doc.data().ClosedUS
                var openThai = doc.data().OpenThai
                var newThai = doc.data().NewThai
                var closedThai = doc.data().ClosedThai
                var openCapaVol = doc.data().Open
                var newCapaVol = doc.data().New
                var closedCapaVol = doc.data().Closed
                averageIreGraphCalc[0][positionWeek] = averageAgingIre
                averageIreGraphCalc[1][positionWeek] = oldestIre
                averageIreGraphCalc[2][positionWeek] = goal
                averageUSGraphCalc[0][positionWeek] = averageAgingUS
                averageUSGraphCalc[1][positionWeek] = oldestUS
                averageUSGraphCalc[2][positionWeek] = goal
                averageThaiGraphCalc[0][positionWeek] = averageAgingThai
                averageThaiGraphCalc[1][positionWeek] = oldestThai
                averageThaiGraphCalc[2][positionWeek] = goal
                openNewClosedIreGraphCalc[0][positionWeek] = openIre
                openNewClosedIreGraphCalc[1][positionWeek] = newIre
                openNewClosedIreGraphCalc[2][positionWeek] = closedIre
                openNewClosedUSGraphCalc[0][positionWeek] = openUS
                openNewClosedUSGraphCalc[1][positionWeek] = newUS
                openNewClosedUSGraphCalc[2][positionWeek] = closedUS
                openNewClosedThaiGraphCalc[0][positionWeek] = openThai
                openNewClosedThaiGraphCalc[1][positionWeek] = newThai
                openNewClosedThaiGraphCalc[2][positionWeek] = closedThai
                capaOpenNewClosed[0][positionWeek] = openCapaVol
                capaOpenNewClosed[1][positionWeek] = newCapaVol
                capaOpenNewClosed[2][positionWeek] = closedCapaVol
                capaIreOpenNewClosed[0][positionWeek] = openIre // same dataset as openNewClosedIreGraphCalc
                capaIreOpenNewClosed[1][positionWeek] = newIre // same dataset as openNewClosedIreGraphCalc
                capaIreOpenNewClosed[2][positionWeek] = closedIre // same dataset as openNewClosedIreGraphCalc
                capaUSOpenNewClosed[0][positionWeek] = openUS // same dataset as openNewClosedUSGraphCalc
                capaUSOpenNewClosed[1][positionWeek] = newUS // same dataset as openNewClosedUSGraphCalc
                capaUSOpenNewClosed[2][positionWeek] = closedUS // same dataset as openNewClosedUSGraphCalc
            }
        })

        var averageIreGraphCalcHigh = getMaxFrom2dAarray(averageIreGraphCalc) + 10
        var averageIreGraphCalcLow = getMinFrom2dAarray(averageIreGraphCalc) - 5
        var averageUSGraphCalcHigh = getMaxFrom2dAarray(averageUSGraphCalc) + 10
        var averageUSGraphCalcLow = getMinFrom2dAarray(averageUSGraphCalc) - 5
        var averageThaiGraphCalcHigh = getMaxFrom2dAarray(averageThaiGraphCalc) + 10
        var averageThaiGraphCalcLow = getMinFrom2dAarray(averageThaiGraphCalc) - 5
        var openNewClosedIreGraphCalcHigh = getMaxFrom2dAarray(openNewClosedIreGraphCalc) + 5
        var openNewClosedIreGraphCalcLow = getMinFrom2dAarray(openNewClosedIreGraphCalc) - 5
        var openNewClosedUSGraphCalcHigh = getMaxFrom2dAarray(openNewClosedUSGraphCalc) + 5
        var openNewClosedUSGraphCalcLow = getMinFrom2dAarray(openNewClosedUSGraphCalc) - 5
        var openNewClosedThaiGraphCalcHigh = getMaxFrom2dAarray(openNewClosedThaiGraphCalc) + 5
        var openNewClosedThaiGraphCalcLow = getMinFrom2dAarray(openNewClosedThaiGraphCalc) - 5
        var capaOpenHigh = getMaxFrom2dAarray(capaOpenNewClosed) + 5
        var capaOpenLow = getMinFrom2dAarray(capaOpenNewClosed) - 5
        var capaIreHigh = getMaxFrom2dAarray(capaIreOpenNewClosed) + 5
        var capaIreLow = getMinFrom2dAarray(capaIreOpenNewClosed) - 5
        var capaUSHigh = getMaxFrom2dAarray(capaUSOpenNewClosed) + 5
        var capaUSLow = getMinFrom2dAarray(capaUSOpenNewClosed) - 5

        setCapaVol({
            ...capaVol,
            data: capaVolData
        })
        setAverageIre({
            ...averageIre,
            data: {
                labels: getCalculatedLabels(),
                series: averageIreGraphCalc
            },
            options: {
                high: averageIreGraphCalcHigh,
                low: averageIreGraphCalcLow
            }
        })
        setAverageUS({
            ...averageUS,
            data: {
                labels: getCalculatedLabels(),
                series: averageUSGraphCalc
            },
            options: {
                high: averageUSGraphCalcHigh,
                low: averageUSGraphCalcLow
            }
        })
        setAverageThai({
            ...averageThai,
            data: {
                labels: getCalculatedLabels(),
                series: averageThaiGraphCalc
            },
            options: {
                high: averageThaiGraphCalcHigh,
                low: averageThaiGraphCalcLow
            }
        })
        setOpenNewClosedIre({
            ...openNewClosedIre,
            data: {
                labels: getCalculatedLabels(),
                series: openNewClosedIreGraphCalc
            },
            options: {
                high: openNewClosedIreGraphCalcHigh,
                low: openNewClosedIreGraphCalcLow
            }
        })
        setOpenNewClosedUS({
            ...openNewClosedUS,
            data: {
                labels: getCalculatedLabels(),
                series: openNewClosedUSGraphCalc
            },
            options: {
                high: openNewClosedUSGraphCalcHigh,
                low: openNewClosedUSGraphCalcLow
            }
        })
        setOpenNewClosedThai({
            ...openNewClosedThai,
            data: {
                labels: getCalculatedLabels(),
                series: openNewClosedThaiGraphCalc
            },
            options: {
                high: openNewClosedThaiGraphCalcHigh,
                low: openNewClosedThaiGraphCalcLow
            }
        })
        setCapaOpen({
            ...capaOpen,
            data: {
                labels: getCalculatedLabels(),
                series: capaOpenNewClosed
            },
            options: {
                high: capaOpenHigh,
                low: capaOpenLow
            }
        })
        setCapaIre({
            ...capaIre,
            data: {
                labels: getCalculatedLabels(),
                series: capaIreOpenNewClosed
            },
            options: {
                high: capaIreHigh,
                low: capaIreLow
            }
        })
        setCapaUS({
            ...capaUS,
            data: {
                labels: getCalculatedLabels(),
                series: capaUSOpenNewClosed
            },
            options: {
                high: capaUSHigh,
                low: capaUSLow
            }
        })
        var searchProgress = document.getElementById('searchGraphProgress');
        searchProgress.style.visibility = 'hidden';
    }

    const getGraphsData = () => {
        // searchProgress.style.visibility = 'visible';

        db.collection('qualityCapaVol')
            .where("Year", "<=", year)
            .orderBy("Year", "desc")
            .orderBy("Week", "desc")
            .limit(15)
            .get()
            .then(snapshot => {
                calcWeeklyVars(snapshot, weekNumbers)
            })
            .catch(function (error) {
                console.log("Error getting Capa Volume data: ", error);
            });
    }

    useEffect(() => {
        getGraphsData()
        return () => { }
    }, [])

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={33} openMetricsColapse={true} openMetricsQandCColapse={true} openMetricsQandCQualityColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Metrics - Capa Volume & Aging</h1> */}

                            <LinearProgress id='searchGraphProgress' className={classes.searchProgress} />
                            {/* <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <img className={classes.media} src={underConstruction} alt="Under Construction" />
                            </Grid> */}

                            {roles.includes('admin') ?
                                <MaterialTable
                                    title={"Capa Volume"}
                                    columns={capaVol.columns}
                                    data={capaVol.data}
                                    // detailPanel={rowData => {
                                    //     // console.log(rowData)
                                    //     return (
                                    //         <TicketHRDetails rowData={rowData} />
                                    //     )
                                    // }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="Ireland" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="United States" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                    <Chip label="Thailand" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                    <Chip label="United Kingdom" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(210, 180, 30, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15]
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    addCapaVol(newData);
                                                    getGraphsData()
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        // onRowUpdate: (newData, oldData) =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             if (oldData) {
                                        //                 setTicketsFacilities(prevState => {
                                        //                     const data = [...prevState.data];
                                        //                     data[data.indexOf(oldData)] = newData;
                                        //                     if (newData.Status == 'Completed' || newData.Status == 'Closed - Not Needed') {
                                        //                         var date = new Date();
                                        //                         var today = date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();
                                        //                         newData.ClosedDate = today;
                                        //                     }
                                        //                     console.log('Updated issue error ticket in DB')
                                        //                     // Update data
                                        //                     // if closed or not needed then add closing date
                                        //                     db.collection("issueErrorTickets").doc(newData.ticketId).update({
                                        //                         Status: newData.Status,
                                        //                         AssignedIT: newData.AssignedIT,
                                        //                         RootCauseCategory: newData.RootCauseCategory,
                                        //                         FixType: newData.FixType,
                                        //                         FailureCategory: newData.FailureCategory,
                                        //                         FixUpdateDescription: newData.FixUpdateDescription,
                                        //                         ClosedDate: newData.ClosedDate,
                                        //                     });
                                        //                     resolve();
                                        //                     return { ...prevState, data };
                                        //                 })
                                        //             }
                                        //         }, 600)
                                        //     }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    console.log(oldData.id)
                                                    deleteCapaVol(oldData.id)
                                                    setCapaVol(prevState => {
                                                        const data = [...prevState.data];
                                                        data.splice(data.indexOf(oldData), 1);
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                    }}
                                />
                                :
                                <MaterialTable
                                    title={"Capa Volume"}
                                    columns={capaVol.columns}
                                    data={capaVol.data}
                                    // detailPanel={rowData => {
                                    //     // console.log(rowData)
                                    //     return (
                                    //         <TicketHRDetails rowData={rowData} />
                                    //     )
                                    // }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="Ireland" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="United States" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                    <Chip label="Thailand" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                    <Chip label="United Kingdom" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(210, 180, 30, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15]
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    addCapaVol(newData);
                                                    getGraphsData()
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        // onRowUpdate: (newData, oldData) =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             if (oldData) {
                                        //                 setTicketsFacilities(prevState => {
                                        //                     const data = [...prevState.data];
                                        //                     data[data.indexOf(oldData)] = newData;
                                        //                     if (newData.Status == 'Completed' || newData.Status == 'Closed - Not Needed') {
                                        //                         var date = new Date();
                                        //                         var today = date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();
                                        //                         newData.ClosedDate = today;
                                        //                     }
                                        //                     console.log('Updated issue error ticket in DB')
                                        //                     // Update data
                                        //                     // if closed or not needed then add closing date
                                        //                     db.collection("issueErrorTickets").doc(newData.ticketId).update({
                                        //                         Status: newData.Status,
                                        //                         AssignedIT: newData.AssignedIT,
                                        //                         RootCauseCategory: newData.RootCauseCategory,
                                        //                         FixType: newData.FixType,
                                        //                         FailureCategory: newData.FailureCategory,
                                        //                         FixUpdateDescription: newData.FixUpdateDescription,
                                        //                         ClosedDate: newData.ClosedDate,
                                        //                     });
                                        //                     resolve();
                                        //                     return { ...prevState, data };
                                        //                 })
                                        //             }
                                        //         }, 600)
                                        //     }),
                                        // onRowDelete: oldData =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             resolve();
                                        //             console.log(oldData.id)
                                        //             deleteCapaVol(oldData.id)
                                        //             setCapaVol(prevState => {
                                        //                 const data = [...prevState.data];
                                        //                 data.splice(data.indexOf(oldData), 1);
                                        //                 return { ...prevState, data };
                                        //             });
                                        //         }, 600);
                                        //     }),
                                    }}
                                />}

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Average Aging, Oldest & Goal - Ireland
                                </Typography>

                                <ChartistGraph
                                    id='averageIre'
                                    data={averageIre.data}
                                    type={averageIre.type}
                                    options={averageIre.options}
                                    // listener={averageIre.animation}
                                    plugins={averageIre.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="AverageAgingIre" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Average Aging Ireland"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="OldestIre" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Oldest Ireland"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="GoalIre" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Goal Ireland"
                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Average Aging, Oldest & Goal - USA
                                </Typography>

                                <ChartistGraph
                                    id='averageUS'
                                    data={averageUS.data}
                                    type={averageUS.type}
                                    options={averageUS.options}
                                    // listener={averageUS.animation}
                                    plugins={averageUS.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="AverageAgingUS" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Average Aging USA"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="OldestUS" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Oldest USA"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="GoalUS" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Goal USA"
                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Average Aging, Oldest & Goal - Thailand
                                </Typography>

                                <ChartistGraph
                                    id='averageThai'
                                    data={averageThai.data}
                                    type={averageThai.type}
                                    options={averageThai.options}
                                    // listener={averageThai.animation}
                                    plugins={averageThai.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="AverageAgingThai" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Average Aging Thailand"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="OldestThai" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Oldest Thailand"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="GoalThai" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Goal Thailand"
                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Open, New & Closed - Ireland
                                </Typography>

                                <ChartistGraph
                                    id='openNewClosedIre'
                                    data={openNewClosedIre.data}
                                    type={openNewClosedIre.type}
                                    options={openNewClosedIre.options}
                                    // listener={openNewClosedIre.animation}
                                    plugins={openNewClosedIre.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="OpenIre" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Open Ireland"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="NewIre" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="New Ireland"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="ClosedIre" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Closed Ireland"
                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Open, New & Closed - USA
                                </Typography>

                                <ChartistGraph
                                    id='openNewClosedUS'
                                    data={openNewClosedUS.data}
                                    type={openNewClosedUS.type}
                                    options={openNewClosedUS.options}
                                    // listener={openNewClosedUS.animation}
                                    plugins={openNewClosedUS.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="OpenUS" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Open USA"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="NewUS" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="New USA"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="ClosedUS" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Closed USA"
                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Open, New & Closed - Thailand
                                </Typography>

                                <ChartistGraph
                                    id='openNewClosedThai'
                                    data={openNewClosedThai.data}
                                    type={openNewClosedThai.type}
                                    options={openNewClosedThai.options}
                                    // listener={openNewClosedThai.animation}
                                    plugins={openNewClosedThai.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="OpenThai" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Open Thailand"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="NewThai" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="New Thailand"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="ClosedThai" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Closed Thailand"
                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Open, New & Closed - Capa's
                                </Typography>

                                <ChartistGraph
                                    id='capaOpen'
                                    data={capaOpen.data}
                                    type={capaOpen.type}
                                    options={capaOpen.options}
                                    // listener={capaOpen.animation}
                                    plugins={capaOpen.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Open</p>
                                    <div className={classes.blue}>.</div><p>New</p>
                                    <div className={classes.grey}>.</div><p>Closed</p>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Open, New & Closed - Ireland
                                </Typography>

                                <ChartistGraph
                                    id='capaIre'
                                    data={capaIre.data}
                                    type={capaIre.type}
                                    options={capaIre.options}
                                    // listener={capaIre.animation}
                                    plugins={capaIre.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Open</p>
                                    <div className={classes.blue}>.</div><p>New</p>
                                    <div className={classes.grey}>.</div><p>Closed</p>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Open, New & Closed - USA
                                </Typography>

                                <ChartistGraph
                                    id='capaUS'
                                    data={capaUS.data}
                                    type={capaUS.type}
                                    options={capaUS.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={capaUS.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Open</p>
                                    <div className={classes.blue}>.</div><p>New</p>
                                    <div className={classes.grey}>.</div><p>Closed</p>

                                </Grid>
                            </Grid>

                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}