import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, MenuItem, Slide, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { db } from '../../../config/fbConfig';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import EditIcon from '@material-ui/icons/Edit';
import { AuthContext } from '../../../contexts/AuthContext';
import { DataContext } from '../../../contexts/DataContext';
import Footer from '../../Footer';
import owlVideo from '../../../images/WiseAcademy.mp4';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import SideMenuV2 from '../SideMenus/SideMenuV2';
import SideMenuSignOutOnlyV2 from '../SideMenus/SideMenuSignOutOnlyV2';
import Loading from '../Loading';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import Sustainability from './Sustainability.js';
import WiseAcademyCerts from '../admin/WiseAcademyCerts';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
    center: {
        // color: 'White',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    newCourseForm: {
        width: '60%',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50px',
        marginBottom: '50px',
    },
    cardMedia: {
        height: 200,
    },
    courseCard: {
        margin: theme.spacing(2),
        height: 560,
        width: 400
    },
    editBtn: {
        float: 'right'
    },
    linkBtn: {
        textDecoration: 'none',
        marginRight: 20,
        fontSize: '1.3em'
    },
    filePicker: {
        // float: 'left',
        // marginLeft: '16%',
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // marginTop: '35px',
        // marginBottom: '10px',
    },
    input: {
        display: 'none',
    },
    marginRight: {
        margin: theme.spacing(2),
        // marginBottom: theme.spacing(2),
    },
    marginOne: {
        margin: theme.spacing(1),
    },
    marginTwo: {
        margin: theme.spacing(2),
    },
    folderCard: {
        // backgroundColor: '#DAF9DA', // lightgreen
        width: 300,
        height: 100,
        margin: theme.spacing(2),
    },
    folderCardAction: {
        width: '100%',
        height: '100%'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const categories = [
    // { category: 'ITAD', categoryDescription: 'IT asset disposition (ITAD) is the process of disposing of or reallocating hardware, software, and other IT assets within an organization. This process is designed to ensure that IT assets are disposed of in a secure and responsible manner.' },
    { category: 'ITAD', categoryDescription: 'IT Asset Disposition.' },
    { category: 'OEM', categoryDescription: 'Original Equipment Manufacturing.' },
    { category: 'Manufacturing', categoryDescription: 'Manufacturing division.' },
    { category: 'General', categoryDescription: 'General Wisetek across all divisions.' },
    { category: 'Compliance', categoryDescription: 'Compliance, Quality & Security.' },
    { category: 'Sustainability', categoryDescription: 'Environmental Health & Safety.' },
]
const menuItemsCategories = [
    { label: 'ITAD', value: 'ITAD' },
    { label: 'OEM', value: 'OEM' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'General', value: 'General' },
    { label: 'Compliance', value: 'Compliance' },
]
const locations = [
    { label: 'Ireland', value: 'Ireland' },
    { label: 'Thailand', value: 'Thailand' },
    { label: 'United Kingdom', value: 'United Kingdom' },
    { label: 'United States of America', value: 'United States of America' },
]
const sitesIE = [
    { label: 'Dublin Hill Cork', value: 'Dublin Hill Cork' },
    { label: 'K4 / K5 Cork', value: 'K4 / K5 Cork' },
    { label: 'TDI Cork', value: 'TDI Cork' },
    { label: 'Dublin', value: 'Dublin' },
]
const sitesTH = [
    { label: 'Tambol Thungsukla', value: 'Tambol Thungsukla' },
]
const sitesUK = [
    { label: 'Milton Keynes', value: 'Milton Keynes' },
]
const sitesUS = [
    { label: 'California', value: 'California' },
    // { label: 'Maryland', value: 'Maryland' },
    { label: 'Massachusetts', value: 'Massachusetts' },
    { label: 'Texas', value: 'Texas' },
    { label: 'Virginia', value: 'Virginia' },
]

export default function WiseAcademy() {
    const classes = useStyles();
    const { push } = useHistory();
    var snackbarData = useLocation().snackbarDetails
    const { isAuthenticated, uid, displayName, loading, roles } = useContext(AuthContext)
    const { createCourse, searchForCourseInProgress } = useContext(DataContext)

    const [allCourses, setAllCourses] = useState({
        data: []
    })
    const [publishedCourses, setPublishedCourses] = useState({
        data: []
    })

    const [newCourseDialog, setNewCourseDialog] = useState({
        dialogOpen: false,
        Id: '',
        Category: '',
        Title: '',
        LogoUrl: '',
        Description: '',
        Status: 'Draft',
        data: [],
        createdBy: '',
        filesToStoreInDB: [],
        files: [],
    })
    const [startCourse, setStartCourse] = useState({
        dialogOpen: false,
        TraineeFullName: '',
        TraineeEmail: '',
        TraineeManagerFullName: '',
        TraineeManagerEmail: '',
        Site: '',
        Location: '',
        courseData: []
    })
    const [displayCategoryHeader, setDisplayCategoryHeader] = useState('')

    var countCourses = 0

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: snackbarData ? snackbarData.open : false,
        severity: snackbarData ? snackbarData.severity : 'info',
        message: snackbarData ? snackbarData.message : '',
    });

    function getAllCourses() {
        // console.log('getting all courses')
        db.collection('wiseAcademy').orderBy('dateCreated').get()
            .then(snap => {
                var courses = []
                snap.forEach(doc => {
                    courses.push(doc.data())
                })
                setAllCourses({
                    ...allCourses,
                    data: courses
                })
            })
            .catch(function (error) {
                console.log("Error getting all courses: ", error);
            });
    }

    function getPublishedCourses() {
        db.collection('wiseAcademy').where('Status', '==', 'Published').orderBy('dateCreated').get()
            .then(snap => {
                var courses = []
                snap.forEach(doc => {
                    courses.push(doc.data())
                })
                setPublishedCourses({
                    ...publishedCourses,
                    data: courses
                })
            })
            .catch(function (error) {
                console.log("Error getting published courses: ", error);
            });
    }
    useEffect(() => {
        getAllCourses();
        return () => { }
    }, [])
    useEffect(() => {
        getPublishedCourses();
        return () => { }
    }, [])

    const handleOpenNewCourseForm = (e) => {
        setNewCourseDialog({ ...newCourseDialog, dialogOpen: true })
    }
    const handleCloseNewCourseForm = (e) => {
        setNewCourseDialog({ ...newCourseDialog, dialogOpen: false })
    }
    const handleChangeNewCourseForm = (e) => {
        setNewCourseDialog({ ...newCourseDialog, [e.target.name]: e.target.value })
    }
    const handleOpenStartCourseForm = (course) => {
        var completedChapters = []
        course.chapters.forEach((chap, chapIn) => {
            var newCompleted = []
            course.chapters[chapIn].chapSteps.forEach(step => {
                newCompleted.push(false)
            })
            completedChapters.push(newCompleted)
        })
        course.completedChapters = completedChapters
        // console.log(course)
        setStartCourse({ ...startCourse, dialogOpen: true, courseData: course })
    }
    const handleCloseStartCourseForm = () => {
        clearStartCourseFields()
    }
    const handleChangeStartCourseForm = (e) => {
        setStartCourse({ ...startCourse, [e.target.name]: e.target.value })
    }
    function clearStateAndFields() {
        setNewCourseDialog({
            ...newCourseDialog,
            dialogOpen: false,
            Id: '',
            Category: '',
            Title: '',
            LogoUrl: '',
            Description: '',
            data: [],
            createdBy: '',
            filesToStoreInDB: [],
            files: [],
        })
    }
    function clearStartCourseFields() {
        setStartCourse({
            ...startCourse,
            dialogOpen: false,
            TraineeFullName: '',
            TraineeEmail: '',
            TraineeManagerFullName: '',
            TraineeManagerEmail: '',
            Site: '',
            Location: '',
            courseData: []
        })
    }
    function capitalizeFirstLetterOfEachWordInString(text) {
        return text.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }
    const handleSubmitNewCourseForm = (e) => {
        e.preventDefault();
        if (newCourseDialog.Category === '' || newCourseDialog.Title === '' || newCourseDialog.filesToStoreInDB.length === 0 || newCourseDialog.Description === '') {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please fill in all the fields and select logo image',
            })
            return
        }
        createCourse(newCourseDialog)
            .then(success => {
                console.log('added new course')
                //display success
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                });
                clearStateAndFields();
                setTimeout(() => { getAllCourses() }, 5000)
                // getAllCourses();
            })
            .catch(error => {
                console.log(error)
                //display error
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                });
            })
    }
    const handleSubmitStartCourseForm = (e) => {
        e.preventDefault();
        if (startCourse.TraineeEmail.toLowerCase() === startCourse.TraineeManagerEmail.toLowerCase()) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: "Those two emails can't be the same!",
            })
            return
        }
        //check here in db for previous progress of the course for that user
        var searchDetails = { courseId: startCourse.courseData.Id, traineeEmail: startCourse.TraineeEmail }
        searchForCourseInProgress(searchDetails)
            .then(resolved => {
                // console.log('found user and course in progress', resolved, startCourse)
                // console.log('next to check changes in the course between saved and not completed chapters')
                resolved.TraineeFullName = capitalizeFirstLetterOfEachWordInString(startCourse.TraineeFullName)
                resolved.TraineeManagerFullName = capitalizeFirstLetterOfEachWordInString(startCourse.TraineeManagerFullName)
                resolved.Location = startCourse.Location
                resolved.Site = startCourse.Site
                // TODO: next to check changes in the course between saved and not completed chapters
                resolved.courseData = startCourse.courseData
                push({
                    pathname: '/wiseCourse',
                    state: resolved,
                    snackbarDetails: {
                        open: true,
                        severity: 'success',
                        message: 'Your course progress has been loaded successfully.',
                    }
                })
            })
            .catch(rejected => {
                // console.log('no record for that user and that course', rejected)
                //capitalize both Full Names
                var tempState = startCourse
                tempState.TraineeFullName = capitalizeFirstLetterOfEachWordInString(startCourse.TraineeFullName)
                tempState.TraineeManagerFullName = capitalizeFirstLetterOfEachWordInString(startCourse.TraineeManagerFullName)
                push({
                    pathname: '/wiseCourse',
                    state: tempState
                })
            })
    }

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        // var attachments = e.target.files;
        // var filesToSend = [e.target.files[0]];
        // const filesToSend = [...files];
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...newCourseDialog.files];
        var filesToStore = [...newCourseDialog.filesToStoreInDB];
        [...files].forEach(file => {
            if (file.size >= maxSize) {
                setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: 'Selected file is too big to upload.',
                })
            }
            else {
                filesToStore.push(file)
                var convertResult = ''
                calculatedSize += file.size

                toBase64(file)
                    .then(result => {
                        convertResult = result
                        filesToSend.push({
                            content: convertResult,
                            filename: file.name,
                            type: file.type,
                            disposition: "attachment"
                        })
                        setNewCourseDialog({
                            ...newCourseDialog,
                            filesToStoreInDB: filesToStore,
                            createdBy: displayName
                        });
                    })
            }
        })
    }
    const handleChangeFilePickerRemove = (e) => {
        setNewCourseDialog({
            ...newCourseDialog,
            // files: [],
            // filesSize: 0,
            filesToStoreInDB: [],
        });
    }

    const handleClickCategory = (cat) => {
        // console.log('clicked', cat)
        countCourses = 0
        setDisplayCategoryHeader(cat)
        document.getElementById('ITAD').style.backgroundColor = '#fff'
        document.getElementById('OEM').style.backgroundColor = '#fff'
        document.getElementById('Manufacturing').style.backgroundColor = '#fff'
        document.getElementById('General').style.backgroundColor = '#fff'
        document.getElementById('Compliance').style.backgroundColor = '#fff'
        document.getElementById('Sustainability').style.backgroundColor = '#fff'
        document.getElementById(cat).style.backgroundColor = '#DAF9DA' // lightgreen
        // console.log('counter reset: ', countCourses)
    }

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        } else {
            return (
                <div className={classes.root}>
                    <CssBaseline >
                        {/* wiseacademy and dublin hill shows only signout */}
                        {uid === 'KN7wpe3VQ2NCA4iTLN4KBvRxtos1' || uid === 'qPnOaLlov4YY9jvUo4JyxdpxOQc2' || roles.includes('viewAccAcademyExternal') ? <SideMenuSignOutOnlyV2 /> : <SideMenuV2 selectedIndex={8} />}

                        <video src={owlVideo} loop autoPlay muted controlsList="nodownload">
                            Your browser does not support HTML video.
                        </video>
                        <Container component="main" className={classes.main} maxWidth="xl">

                            {roles.includes('wiseAcademyEditor') ?
                                <Grid container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center" >

                                    <h1 className={classes.marginTwo}>Training Materials - Categories</h1>

                                    <Button className={classes.marginTwo}
                                        onClick={handleOpenNewCourseForm}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<AddIcon />}>
                                        new course
                                    </Button>

                                </Grid>
                                :
                                <h1 className={classes.marginTwo}>Training Materials - Categories</h1>}

                            <Grid container
                                direction='row'
                                justifyContent='flex-start'
                                alignItems='center'>
                                {categories.map((cat, index) => (
                                    <Card className={classes.folderCard} key={index} id={cat.category}>
                                        <CardActionArea className={classes.folderCardAction} onClick={() => handleClickCategory(cat.category)}>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    {cat.category}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {cat.categoryDescription}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                ))}
                            </Grid>

                            {roles.includes('wiseAcademyEditor') ?
                                <div>
                                    {displayCategoryHeader ? <h1 className={classes.marginTwo}>{displayCategoryHeader + ' category'}</h1> : null}

                                    <Grid container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start" >

                                        {allCourses.data ? allCourses.data.map(course => {
                                            if (course.Category === displayCategoryHeader) {
                                                countCourses++

                                                return <Card key={course.Id} className={classes.courseCard}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {course.Status === 'Draft' ?
                                                            <Typography variant="button" style={{ color: 'red', margin: 8 }}>
                                                                {course.Status}
                                                            </Typography>
                                                            :
                                                            <Typography variant="button" style={{ color: 'green', margin: 8 }}>
                                                                {course.Status}
                                                            </Typography>}
                                                    </div>
                                                    <div className={classes.center}>
                                                        <img className={classes.cardMedia}
                                                            src={course.LogoUrl}
                                                            alt={course.Title}
                                                        />
                                                    </div>
                                                    <CardContent style={{ height: 250 }}>
                                                        <Typography variant="h5" component="h5">
                                                            {course.Title}
                                                        </Typography>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <Typography variant="caption" color="textSecondary" >{'by ' + course.createdBy}</Typography>
                                                        </div>
                                                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                                                            {course.Description}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions className={classes.editBtn}>
                                                        <Button className={classes.marginTwo}
                                                            component={Link}
                                                            to={{
                                                                pathname: '/courseDesigner',
                                                                state: course
                                                            }}
                                                            variant="outlined"
                                                            color="default"
                                                            size="small"
                                                            startIcon={<EditIcon />}>
                                                            Edit
                                                        </Button>
                                                        <Button className={classes.marginTwo}
                                                            onClick={(e) => handleOpenStartCourseForm(course)}
                                                            variant="contained"
                                                            color="primary"
                                                            size="small">
                                                            Course
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            }

                                        }) : null}
                                        {countCourses === 0 && displayCategoryHeader ? displayCategoryHeader === 'Sustainability' ? <Sustainability setSnackbar={setSnackbar} /> : <h4 style={{ marginLeft: 'auto', marginRight: 'auto' }}>There are no courses in this category yet...</h4> : null}
                                    </Grid>

                                    <br />
                                    <h1 className={classes.marginTwo}>Reporting - WiseAcademy</h1>
                                    <h5 className={classes.marginTwo}>Note: Below you can see the reporting section for "Completed" and "In Progress" courses.</h5>
                                    <br />
                                    <WiseAcademyCerts />
                                </div>
                                :
                                // Only published courses
                                <div>
                                    <Grid container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center" >

                                        {displayCategoryHeader ? <h1 className={classes.marginTwo}>{displayCategoryHeader + ' category'}</h1> : null}

                                    </Grid>
                                    <Grid container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start" >
                                        {publishedCourses.data ? publishedCourses.data.map(course => {
                                            if (course.Category === displayCategoryHeader) {
                                                countCourses++

                                                return <Card key={course.Id} className={classes.courseCard}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {course.Status === 'Draft' ?
                                                            <Typography variant="button" style={{ color: 'red', margin: 8 }}>
                                                                {course.Status}
                                                            </Typography>
                                                            :
                                                            <Typography variant="button" style={{ color: 'green', margin: 8 }}>
                                                                {course.Status}
                                                            </Typography>}
                                                    </div>
                                                    <div className={classes.center}>
                                                        <img className={classes.cardMedia}
                                                            src={course.LogoUrl}
                                                            alt={course.Title}
                                                        />
                                                    </div>
                                                    <CardContent style={{ height: 250 }}>
                                                        <Typography variant="h5" component="h5">
                                                            {course.Title}
                                                        </Typography>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <Typography variant="caption" color="textSecondary" >{'by ' + course.createdBy}</Typography>
                                                        </div>
                                                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                                                            {course.Description}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions className={classes.editBtn}>
                                                        <Button className={classes.marginTwo}
                                                            onClick={(e) => handleOpenStartCourseForm(course)}
                                                            variant="contained"
                                                            color="primary"
                                                            size="small">
                                                            Course
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            }
                                        }) : null}
                                        {countCourses === 0 && displayCategoryHeader ? displayCategoryHeader === 'Sustainability' ? <Sustainability setSnackbar={setSnackbar} /> : <h4 style={{ marginLeft: 'auto', marginRight: 'auto' }}>There are no published courses in this category</h4> : null}
                                    </Grid>
                                </div>}

                            {/* new course dialog */}
                            <Dialog
                                open={newCourseDialog.dialogOpen}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseNewCourseForm}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="newCourseTitle">{newCourseDialog.Title ? newCourseDialog.Title : 'Name your new course'}</DialogTitle>

                                <div className={classes.newCourseForm}>
                                    <form onSubmit={handleSubmitNewCourseForm}>
                                        {newCourseDialog.filesToStoreInDB.length > 0 ? <div><img src={URL.createObjectURL(newCourseDialog.filesToStoreInDB[0])} style={{ width: '200px' }} /></div> : null}
                                        <div className={classes.filePicker}>
                                            <input
                                                accept="image/*"
                                                // accept="image/*, video/*,.pdf,.xlsx,.xls,.csv"
                                                className={classes.input}
                                                id="add-contained-button-file"
                                                // multiple
                                                type="file"
                                                onChange={handleChangeFilePicker}
                                            />
                                            <p>Logo can be an image or gif format.</p>
                                            <label htmlFor="add-contained-button-file" style={{ width: '100%' }}>
                                                <Button
                                                    // size="small"
                                                    className={classes.marginRight}
                                                    variant="outlined"
                                                    color="default"
                                                    component="span"
                                                    startIcon={<AttachFileIcon />}>
                                                    Add Course Logo
                                                </Button>
                                            </label>
                                            {newCourseDialog.filesToStoreInDB.length > 0 ? <label htmlFor="remove-contained-button-file">
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="secondary"
                                                    component="span"
                                                    startIcon={<DeleteOutlineIcon />}
                                                    onClick={handleChangeFilePickerRemove}>
                                                    remove
                                                </Button>
                                            </label> : null}
                                            {/* {newCourseDialog.filesToStoreInDB.length === 0 ? null : <p>Files attached: {newCourseDialog.filesToStoreInDB.length}</p>} */}

                                        </div>
                                        <div style={{ marginTop: 15, marginBottom: 15, textAlign: 'start' }}>
                                            <TextField
                                                select
                                                size="small"
                                                variant="outlined"
                                                // margin="normal"
                                                name='Category'
                                                label="Course Category"
                                                fullWidth
                                                // helperText="up to 50 characters"
                                                inputProps={{ maxLength: 50 }}
                                                value={newCourseDialog.Category}
                                                onChange={handleChangeNewCourseForm} >
                                                {menuItemsCategories.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            name='Title'
                                            label="Course Title"
                                            fullWidth
                                            helperText="up to 100 characters"
                                            inputProps={{ maxLength: 100 }}
                                            value={newCourseDialog.Title}
                                            onChange={handleChangeNewCourseForm} />
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            margin="normal"
                                            name='Description'
                                            label="Description"
                                            fullWidth
                                            multiline
                                            minRows={3}
                                            maxRows={5}
                                            helperText="up to 500 characters"
                                            inputProps={{ maxLength: 500 }}
                                            value={newCourseDialog.Description}
                                            onChange={handleChangeNewCourseForm} />
                                    </form>
                                </div>
                                <DialogActions>
                                    <Button onClick={handleCloseNewCourseForm} className={classes.marginTwo}
                                        variant="outlined"
                                        color="secondary"
                                        size="small">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmitNewCourseForm} className={classes.marginTwo}
                                        variant="contained"
                                        color="primary"
                                        size="small">
                                        Create Course
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/* start course dialog */}
                            <Dialog
                                open={startCourse.dialogOpen}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseStartCourseForm}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="startCourseTitle">{startCourse.courseData.Title}</DialogTitle>
                                <DialogContent>
                                    <br />
                                    <DialogContentText id="alert-dialog-slide-description">
                                        Welcome to "Wise Academy" you are about to start one of the courses provided by ©Wisetek.
                                        But before you proceed we need to gather some basic information below.
                                        Please make sure your name appears the same as on your ID or passport.
                                        Those information will be later used to send you an email with the certificate of completion (pdf file).
                                    </DialogContentText>
                                    <form onSubmit={handleSubmitStartCourseForm}>
                                        <div className={classes.newCourseForm}>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                name='TraineeFullName'
                                                label="Trainee Full Name"
                                                fullWidth
                                                required
                                                // autoComplete='off'
                                                helperText="up to 25 characters"
                                                inputProps={{ maxLength: 25 }}
                                                value={startCourse.TraineeFullName}
                                                onChange={handleChangeStartCourseForm} />
                                            <TextField
                                                type="email"
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                name='TraineeEmail'
                                                label="Trainee Email"
                                                fullWidth
                                                required
                                                // autoComplete='off'
                                                helperText="up to 50 characters"
                                                inputProps={{ maxLength: 50 }}
                                                value={startCourse.TraineeEmail}
                                                onChange={handleChangeStartCourseForm} />
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                name='TraineeManagerFullName'
                                                label="Trainee Manager Full Name"
                                                fullWidth
                                                required
                                                // autoComplete='off'
                                                helperText="up to 50 characters"
                                                inputProps={{ maxLength: 50 }}
                                                value={startCourse.TraineeManagerFullName}
                                                onChange={handleChangeStartCourseForm} />
                                            <TextField
                                                type="email"
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                name='TraineeManagerEmail'
                                                label="Trainee Manager Email"
                                                fullWidth
                                                required
                                                // autoComplete='off'
                                                helperText="up to 50 characters"
                                                inputProps={{ maxLength: 50 }}
                                                value={startCourse.TraineeManagerEmail}
                                                onChange={handleChangeStartCourseForm} />
                                            <TextField style={{ textAlign: 'left' }}
                                                select
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                name='Location'
                                                label="Location"
                                                fullWidth
                                                required
                                                // autoComplete='off'
                                                value={startCourse.Location}
                                                onChange={handleChangeStartCourseForm}>
                                                {locations.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField style={{ textAlign: 'left' }}
                                                select
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                name='Site'
                                                label="Site"
                                                fullWidth
                                                required
                                                // autoComplete='off'
                                                value={startCourse.Site}
                                                onChange={handleChangeStartCourseForm}>
                                                {startCourse.Location === 'Ireland' ?
                                                    sitesIE.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    )) : null}
                                                {startCourse.Location === 'Thailand' ?
                                                    sitesTH.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    )) : null}
                                                {startCourse.Location === 'United Kingdom' ?
                                                    sitesUK.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    )) : null}
                                                {startCourse.Location === 'United States of America' ?
                                                    sitesUS.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    )) : null}

                                            </TextField>
                                        </div>
                                        <DialogActions>
                                            <Button onClick={handleCloseStartCourseForm} className={classes.marginTwo}
                                                variant="outlined"
                                                color="secondary"
                                                size="small">
                                                Cancel
                                            </Button>
                                            <Button className={classes.marginTwo}
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<PlayArrowRoundedIcon />}>
                                                Start Course
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </DialogContent>
                            </Dialog>

                        </Container>

                    </CssBaseline>
                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                    <Footer />
                </div>
            )
        }
    }
}