import React, { useContext, useEffect, useState } from 'react';
import { Button, makeStyles, Typography, Grid, Card, Paper, LinearProgress, Accordion, AccordionSummary, AccordionDetails, Stepper, Step, StepButton, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, Checkbox, FormGroup } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import Footer from '../../Footer';
import congrats from '../../../images/Congrats.gif';
import congratsFlipped from '../../../images/CongratsFlipped.gif';
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import moment from 'moment';
import { auth, functions } from '../../../config/fbConfig';
import { DataContext } from '../../../contexts/DataContext';
import Loading from '../Loading';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import { capitalizeFullName } from '../../../helpers/hardcodedVars';

const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f0f5f4',
        minHeight: '99vh',
        padding: '15px 15px 0px 15px'
    },
    center: {
        // color: 'White',
        textAlign: 'center',
        padding: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonCenter: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    centerHeader: {
        // color: 'White',
        textAlign: 'center',
        marginBottom: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    centerSubtitle: {
        // color: 'White',
        textAlign: 'center',
        marginBottom: '50px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    marginOne: {
        margin: theme.spacing(1),
    },
    marginTwo: {
        margin: theme.spacing(2),
    },
    marginLeftRadioQuiz: {
        marginLeft: '35%',
    },
    xs3Grid: {
        // backgroundColor: '#2e2e2e',//'DarkGrey',
    },
    xs9GridHeader: {
        padding: '10px'
        // backgroundColor: 'Black',
    },
    xs9Grid: {
        // backgroundColor: 'Black',
        minHeight: '75vh',
        padding: '30px'
    },
    totalProgressCard: {
        // color: 'White',
        // backgroundColor: 'Black',
        padding: '20px',
    },
    chaptersCard: {
        // color: 'White',
        // backgroundColor: '#2e2e2e',//'DarkGrey',
        // marginTop: '20px',
    },
    chaptersMenuFirst: {
        marginTop: '40px',
        // color: 'White',
        // backgroundColor: '#2e2e2e',//'DarkGrey',
    },
    chaptersMenu: {
        // color: 'White',
        // backgroundColor: '#2e2e2e',//'DarkGrey',
    },
    textDiv: {
        minHeight: '50vh',
        marginLeft: '10%',
        marginRight: '10%',
    },
    textDivVid: {
        marginTop: '50px',
        marginLeft: '10%',
        marginRight: '10%',
    },
    heading: {
        // fontSize: theme.typography.pxToRem(15),
        flexBasis: '90%',
        // flexShrink: 0,
    },
    secondaryHeading: {
        // fontSize: theme.typography.pxToRem(15),
    },
    nextBackButtons: {
        marginLeft: '20px',
        marginBottom: '20px',
        marginTop: '20px',
    },
    startButton: {
        margin: '60px',
        textAlign: 'center',
    },
    finishButton: {
        margin: '50px',
        textAlign: 'center',
    },
    completeButton: {
        margin: '50px',
        textAlign: 'center',
        visibility: 'hidden'
    },
    completeButtonVisible: {
        margin: '50px',
        textAlign: 'center',
    },
    div90vh: {
        height: '90vh',
        // overflowY: 'auto'
    },
    div80vh: {
        height: '80%'
    },
    mediaDiv: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    media: {
        paddingLeft: '30px',
        paddingRight: '30px',
        width: '100%',
        height: 'auto'
    },
    radioGroup: {
        margin: '30px',
        // marginBottom: '30px',
    },
    myFormLabel: {
        marginLeft: '10%',
        marginRight: '10%',
        marginTop: '50px',
        marginBottom: '50px',
    },
    cardMedia: { // course logo
        margin: '20px',
        height: 300,
    },
    buttonMarginAround: {
        margin: '20px',
    },
    videoSize: {
        minWidth: '200px',
        width: '70%',
        maxWidth: '1000px',
        marginBottom: 20
    },
    questionsDiv: {
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    questionsMinHeightDiv: {
        minHeight: '50vh',
    },
    singleQuestion: {
        marginBottom: '50px',
    },
    centerCongrats: {
        maxWidth: '50%',
        textAlign: 'center',
    },
    congratsConfetti: {
        maxWidth: '15%',
        marginLeft: '5%',
        marginBottom: '30px',
    },
    congratsConfettiFlipped: {
        maxWidth: '15%',
        marginRight: '5%',
        marginBottom: '30px',
    },

}));

export default function GoCourse() {
    const classes = useStyles();
    const { push } = useHistory();
    var propData = useLocation().state;
    var snackbarData = useLocation().snackbarDetails
    // console.log('propData:::::::', propData);
    const { saveCourseProgress, removeCourseProgress, saveCompletedCourseDetails } = useContext(DataContext)
    // const [course, setCourse] = useState({ propData })
    const [goCourse, setGoCourse] = useState({
        Id: propData.courseData.Id,
        Category: propData.courseData.Category,
        Title: propData.courseData.Title,
        LogoUrl: propData.courseData.LogoUrl,
        Description: propData.courseData.Description,
        chapters: propData.courseData.chapters,
        // Id: propData.inProgress ? propData.Id : propData.courseData.Id,
        // Category: propData.inProgress ? propData.Category : propData.courseData.Category,
        // Title: propData.inProgress ? propData.Title : propData.courseData.Title,
        // LogoUrl: propData.inProgress ? propData.LogoUrl : propData.courseData.LogoUrl,
        // Description: propData.inProgress ? propData.Description : propData.courseData.Description,
        // chapters: propData.inProgress ? propData.chapters : propData.courseData.chapters,
        chaptersCompleted: propData.inProgress ? propData.chaptersCompleted : propData.courseData.completedChapters,
        TotalProgress: propData.inProgress ? propData.TotalProgress : 0,
        TotalSteps: propData.inProgress ? propData.TotalSteps : 0,
        TotalStepsCompleted: propData.inProgress ? propData.TotalStepsCompleted : 0,
        TraineeFullName: capitalizeFullName(propData.TraineeFullName),
        TraineeEmail: propData.TraineeEmail,
        TraineeManagerFullName: capitalizeFullName(propData.TraineeManagerFullName),
        TraineeManagerEmail: propData.TraineeManagerEmail,
        Location: propData.Location,
        Site: propData.Site,
        Date: moment().format('DD MMMM YYYY'),
        // cert: 'https://storage.googleapis.com/wisetekappappspotcom/WiseAcademy/CourseCerts/WiseAcademyCertificate.pdf'
        cert: 'https://storage.googleapis.com/wisetekappappspotcom/WiseAcademy/CourseCerts/WiseAcademyCertificateV2.pdf'

    });

    const containsFalse = (element) => element.includes(false) // calculate in progress chapter expanded
    const inProgressChapExpanded = goCourse.chaptersCompleted.findIndex(containsFalse)
    // console.log('CHAPTER:', inProgressChapExpanded)
    const inProgressStepActive = inProgressChapExpanded === -1 ? -1 : goCourse.chaptersCompleted[inProgressChapExpanded].indexOf(false) // calculate in progress step active
    // console.log('STEP', inProgressStepActive)
    const [activeExpanded, setActiveExpanded] = useState({
        chapExpanded: propData.inProgress ? inProgressChapExpanded : -1,
        stepActive: propData.inProgress ? inProgressStepActive : -1
    });
    const [userAnswers, setUserAnswers] = useState(propData.inProgress ? propData.userAnswers : {});
    // TODO: done now fixing correct answers and checking them
    // const [correctAnswers, setCorrectAnswers] = useState(propData.inProgress ? propData.correctAnswers : {});
    const [correctAnswers, setCorrectAnswers] = useState({});
    // const [btnDisabled, setBtnDisabled] = useState(false);

    function saveProgressAndGoToCourseLibrary() {
        var loader = document.getElementById('saveLoader')
        loader.style.display = 'block'
        saveCourseProgress(goCourse, userAnswers, correctAnswers)
            .then(() => {
                push({
                    pathname: '/wiseAcademy',
                    snackbarDetails: {
                        open: true,
                        severity: 'success',
                        message: 'Your course progress has been saved successfully.',
                    }
                })
                loader.style.display = 'none'
            })
            .catch(() => {
                loader.style.display = 'none'
            })
    }
    function goToCourseLibrary() {
        push({
            pathname: '/wiseAcademy',
        })
    }
    function createDefaultAnswers() {
        var defaultAnswers = {}
        var chapterDetails = []
        // propData.inProgress ? chapterDetails = propData.chapters : chapterDetails = propData.courseData.chapters
        chapterDetails = propData.courseData.chapters
        chapterDetails.forEach((chap, chapIn) => {
            chap.chapStepsContent.forEach(step => {
                if (step.isQuizLesson) {
                    step.questions.forEach((question, questionIn) => {
                        if (question.type === 'Single') {
                            defaultAnswers['q' + chapIn + 'q' + questionIn + 'Error'] = false;
                            defaultAnswers['q' + chapIn + 'q' + questionIn + 'Value'] = '';
                            defaultAnswers['q' + chapIn + 'q' + questionIn + 'HelperText'] = '';
                        } else {
                            question.choices.forEach(choi => {
                                defaultAnswers['q' + chapIn + 'q' + questionIn + 'Value' + choi] = false;
                            })
                            defaultAnswers['q' + chapIn + 'q' + questionIn + 'HelperText'] = '';
                        }
                    })
                }
            })
        })
        setUserAnswers(defaultAnswers)
    }
    function createCorrectAnswers() {
        var correctAnswers = {}
        var chapterDetails = []
        // propData.inProgress ? chapterDetails = propData.chapters : chapterDetails = propData.courseData.chapters
        // console.log('HERE:', propData.courseData)
        chapterDetails = propData.courseData.chapters
        chapterDetails.forEach((chap, chapIn) => {
            chap.chapStepsContent.forEach(step => {
                if (step.isQuizLesson) {
                    step.questions.forEach((question, questionIn) => {
                        question.answers.forEach((answer, answerIndex) => {
                            if (question.type === 'Single') {
                                if (answer) {
                                    correctAnswers['q' + chapIn + 'q' + questionIn + 'Value'] = question.choices[answerIndex]
                                }
                            } else {
                                question.choices.forEach((choi, choiIndex) => {
                                    correctAnswers['q' + chapIn + 'q' + questionIn + 'Value' + choi] = question.answers[choiIndex];
                                })
                            }
                        })
                    })
                }
            })
        })
        console.log('correctAnswers here:', correctAnswers)
        setCorrectAnswers(correctAnswers)
    }
    function extractPrefixFromStringBetweenTwoQs(inputString) {
        // const regex = /q.*?V/i; // Updated regex pattern
        const regex = /q.*?(?=V)/i; // Regex pattern to extract everything from start to letter V
        const matches = inputString.match(regex); // Match the pattern in the input string

        if (matches) {
            return matches[0]; // Return the matched substring
        } else {
            return null; // Return null if no match is found
        }
    }
    const handleRadioChange = (e) => {
        // console.log('e.target.name', e.target.name)
        // console.log(e.target.value)
        // var isSingleDigit = Number(e.target.name.substring(3, 4))
        // var isDoubleDigit = Number(e.target.name.substring(3, 5))
        // var prefixBad = isDoubleDigit ? e.target.name.substring(0, 5) : e.target.name.substring(0, 4)
        // console.log('bad prefix', prefixBad)
        var prefix = extractPrefixFromStringBetweenTwoQs(e.target.name)
        // console.log('calculated prefix', prefix)
        setUserAnswers({
            ...userAnswers,
            [e.target.name]: e.target.value,
            [prefix + 'HelperText']: '',
            [prefix + 'Error']: false
        })
    }
    const handleChangeMultichoice = (e) => {
        // var isDoubleDigit = Number(e.target.name.substring(3, 5))
        // var prefix = isDoubleDigit ? e.target.name.substring(0, 5) : e.target.name.substring(0, 4)
        var prefix = extractPrefixFromStringBetweenTwoQs(e.target.name)
        setUserAnswers({
            ...userAnswers,
            [e.target.name]: e.target.checked,
            [prefix + 'HelperText']: '',
            [prefix + 'Error']: false
        })
    };

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: snackbarData ? snackbarData.open : false,
        severity: snackbarData ? snackbarData.severity : 'info',
        message: snackbarData ? snackbarData.message : '',
    });

    const startCourse = () => {
        setActiveExpanded({
            ...activeExpanded,
            chapExpanded: 0,
            stepActive: 0
        })
    }
    const sendEmailCertification = (data) => {
        const callable = functions.httpsCallable('sendWiseAcademyCertification');
        return callable(data, auth)
            .then(
                console.log('Certification successfully sent by email.')
            )
    }
    // // Example usage:
    // const fullName = "sarah j. sheppard mcdowell mc carthy mccarthy o'driscoll ";
    // const capitalizedFullName = capitalizeFullName(fullName);
    // console.log('capitalized full name: ', capitalizedFullName);

    async function handleEmailingCertification() {
        // Fetch an existing PDF document
        const url = goCourse.cert
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        // Register a `fontkit` instance with `PDFDocument.registerFontkit(...)` before embedding custom fonts.
        pdfDoc.registerFontkit(fontkit);
        const fontUrl = 'https://storage.googleapis.com/wisetekappappspotcom/WiseAcademy/CourseFonts/MySoul-Regular.ttf'
        const mySoulBytes = await fetch(fontUrl).then(res => res.arrayBuffer())
        const mySoulFont = await pdfDoc.embedFont(mySoulBytes);
        // Get the form containing all the fields
        const form = pdfDoc.getForm()

        // Get all fields in the PDF by their names
        const trainee = form.getTextField('participantHeader')
        const courseTitle = form.getTextField('courseTitle')
        const supervisor = form.getTextField('supervisorSignature')
        const courseDate = form.getTextField('courseDate')

        trainee.setText(goCourse.TraineeFullName)
        courseTitle.setText(goCourse.Title)
        // supervisor.setText(goCourse.TraineeManagerFullName)   // removed supervisor population and in template this field is "Thomas Delahunty"
        courseDate.setText(goCourse.Date)

        // update appearances
        trainee.setFontSize(40);
        trainee.updateAppearances(mySoulFont);
        // // form flatten is available from v1.16.0 and it makes form read-only (not editable)
        // form.flatten();
        // Serialize the PDFDocument to base64
        const pdfBytes = await pdfDoc.saveAsBase64()

        var files = []
        var fileToSend = {
            content: pdfBytes,
            filename: 'WiseAcademy_Certification.pdf',
            type: 'application/pdf',
            disposition: "attachment"
        }
        files.push(fileToSend)
        // formData.Files = files
        goCourse.Files = files
        // console.log(goCourse)
        sendEmailCertification(goCourse)
        saveCompletedCourseDetails(goCourse)
            .then(succ => {
                console.log('removing course progress..', propData.docToRemove)
                removeCourseProgress(propData.docToRemove)
            })
            .catch(err => {
                console.log('there was an error saving the course')
            })
    }

    function countTrueValuesInChaptersCompleted(arrays) {
        var count = 0;
        arrays.forEach(array => {
            count += array.filter(value => value === true).length;
        });
        return count;
    }
    function calculateTotalProgress() {
        var sumOfSteps = 0
        var sumOfCompleted = countTrueValuesInChaptersCompleted(goCourse.chaptersCompleted);
        var calcPercent = 0;

        goCourse.chapters.forEach(chapter => {
            sumOfSteps += chapter.chapSteps.length
        })
        // console.log('all course steps:', sumOfSteps, 'completed steps:', sumOfCompleted)
        calcPercent = parseFloat(((sumOfCompleted / sumOfSteps) * 100).toFixed(2));
        // console.log(sumOfSteps, sumOfCompleted, calcPercent)
        setGoCourse({
            ...goCourse,
            TotalProgress: calcPercent,
            TotalSteps: sumOfSteps,
            TotalStepsCompleted: sumOfCompleted
        });
    }
    useEffect(() => {
        // console.log('loop!')
        calculateTotalProgress();
        return () => {
        }
    }, [goCourse.TotalStepsCompleted]);

    useEffect(() => {
        var doNothing = ''
        // propData.inProgress ? doNothing = 'course in progress, got answers' : createDefaultAnswers();
        // propData.inProgress ? doNothing = 'course in progress, got answers' : createCorrectAnswers();
        createDefaultAnswers();
        createCorrectAnswers();
        return () => { }
    }, [])
    // useEffect(() => {
    //     if (goCourse.TotalProgress >= 100 && propData.inProgress) {
    //         // console.log(propData.docToRemove)
    //         removeCourseProgress(propData.docToRemove)
    //     }
    //     return () => { }
    // }, [goCourse.TotalProgress])

    function showCompleteStepButton(chapterNumber, stepNumber) {
        // console.log('show button from chapter: ', chapterNumber, ' and step:', stepNumber)
        document.getElementById('chapter' + chapterNumber + 'Step' + stepNumber + 'Complete').style.visibility = 'visible'
    }
    function playing(e) {
        // console.log('started playing',e.target.id)
        // var regEx = /\d+/
        // var chapterNumber = e.target.id.match(regEx)
        // console.log(chapterNumber[0])
        document.getElementById(e.target.id).addEventListener('ended', () => showCompleteStepButton(activeExpanded.chapExpanded, activeExpanded.stepActive))
    }
    const handleAllChaptersNext = () => {
        var newActiveChapter = 0;
        var newActiveStep = 0;

        // console.log('goCourse.chaptersCompleted', goCourse.chaptersCompleted)
        // console.log('activeExpanded.stepActive', activeExpanded.stepActive)
        // console.log('activeExpanded.chapExpanded', activeExpanded.chapExpanded)
        // console.log('goCourse.TotalStepsCompleted+1', goCourse.TotalStepsCompleted + 1)
        // console.log('goCourse.TotalSteps', goCourse.TotalSteps)

        // check if this chapter has more steps if it does go to next step if not go to next chapter and step zero
        if (goCourse.chaptersCompleted[activeExpanded.chapExpanded].length > activeExpanded.stepActive + 1) {
            newActiveChapter = activeExpanded.chapExpanded
            newActiveStep = activeExpanded.stepActive + 1
            // console.log(goCourse.TotalStepsCompleted + 1, goCourse.TotalSteps)
            if (goCourse.TotalStepsCompleted + 1 === goCourse.TotalSteps) {//+1 is the current added step
                newActiveChapter = -2
                newActiveStep = 0
            }
        }
        else {
            newActiveChapter = activeExpanded.chapExpanded + 1
            newActiveStep = 0
            //check if no more chapters or steps
            // console.log(goCourse.TotalStepsCompleted + 1, goCourse.TotalSteps)
            if (goCourse.TotalStepsCompleted + 1 === goCourse.TotalSteps) {//+1 is the current added step
                newActiveChapter = -2
                newActiveStep = 0
                // send email without user clicking the button on the -2 page
                // console.log('finished the course will now send email notification....')
                handleEmailingCertification();
            }
        }

        console.log('newActiveChapter', newActiveChapter)
        console.log('newActiveStep', newActiveStep)
        setActiveExpanded({
            chapExpanded: newActiveChapter,
            stepActive: newActiveStep
        })
    };

    function completeChapter() {
        var newChaptersCompleted = goCourse.chaptersCompleted
        newChaptersCompleted[activeExpanded.chapExpanded][activeExpanded.stepActive] = true
        setGoCourse({
            ...goCourse,
            TotalStepsCompleted: goCourse.TotalStepsCompleted + 1,
            chaptersCompleted: newChaptersCompleted
        })
        handleAllChaptersNext();
    }

    function extractNumberAfterSecondQ(inputString) {
        const regex = /q(?:[^q]*q){1}(\d+)/i; // Regular expression to match the pattern
        const matches = inputString.match(regex); // Match the pattern in the input string

        if (matches && matches[1]) {
            return parseInt(matches[1]); // Extract and return the number after the second 'q'
        } else {
            return null; // Return null if no match is found
        }
    }

    function validateQuizAnswers() {
        var chapPrefix = 'q' + activeExpanded.chapExpanded + 'q'
        var questionIndex = -1
        var questionDoubleIndex = -1
        var questionsToCheck = []
        var counter = 0
        console.log('those are user answers:', userAnswers)
        Object.entries(userAnswers).forEach(userAnswer => {
            // console.log('userAnswer', userAnswer)
            // console.log('KEY:', userAnswer[0], 'VALUE:', userAnswer[1])

            if (userAnswer[0].includes(chapPrefix)) {
                //check only current chapter questions without Error field and helper text
                if (userAnswer[0].includes('HelperText') || userAnswer[0].includes('Error')) {
                    //skip
                } else {
                    questionsToCheck.push(userAnswer[0])
                }
            }
        })
        // console.log(correctAnswers)

        questionsToCheck.every(question => {
            console.log('question', question)
            questionIndex = extractNumberAfterSecondQ(question)
            // questionIndex = Number(question.substring(3, 4))
            // questionDoubleIndex = Number(question.substring(3, 5))
            // console.log('question Index:', questionIndex)
            // console.log('question Double Index:', questionDoubleIndex)

            // var correctIndexToUse = questionDoubleIndex > 9 ? console.log('it is a number') : console.log('not a number')
            // var correctIndexToUse = questionDoubleIndex > 9 ? questionDoubleIndex : questionIndex
            // console.log('correctIndexToUse', correctIndexToUse)
            // console.log('correct:', correctAnswers[question], 'user:', userAnswers[question])
            // var quizNumberQuestionNumberError = chapPrefix + correctIndexToUse + 'Error'
            // var quizNumberQuestionNumberHelperText = chapPrefix + correctIndexToUse + 'HelperText'

            console.log('use this question index', questionIndex)
            var quizNumberQuestionNumberError = chapPrefix + questionIndex + 'Error'
            var quizNumberQuestionNumberHelperText = chapPrefix + questionIndex + 'HelperText'

            if (correctAnswers[question] === userAnswers[question]) {
                // console.log('correctAnswers[question]', correctAnswers[question])
                // console.log('userAnswers[question]', userAnswers[question])
                // single answer correct
                counter++
                if (counter === questionsToCheck.length) {
                    completeChapter();
                }
                // console.log('counter', counter, 'questions to check:', questionsToCheck.length)
                return true
            } else {
                if (userAnswers[question] === '') {
                    setUserAnswers({ ...userAnswers, [quizNumberQuestionNumberError]: true, [quizNumberQuestionNumberHelperText]: 'Please select one option...' });
                    return false
                } else {
                    // check if the answer is string or boolean if string that means its single if bool that means its multiple
                    if (typeof userAnswers[question] === 'string') {
                        setUserAnswers({ ...userAnswers, [quizNumberQuestionNumberError]: true, [quizNumberQuestionNumberHelperText]: 'Ops, thats not the answer we are looking for, please try again...' });
                        return false
                    }
                    if (typeof userAnswers[question] === 'boolean') {
                        if (userAnswers[question] === true && userAnswers[question] === correctAnswers[question]) {
                            //all multiple correct
                            counter++
                            if (counter === questionsToCheck.length) {
                                completeChapter();
                            }
                            // console.log('counter', counter, 'questions to check:', questionsToCheck.length)
                            return true
                        } else {
                            setUserAnswers({ ...userAnswers, [quizNumberQuestionNumberError]: true, [quizNumberQuestionNumberHelperText]: 'Ops, not all correct answers are selected or selected incorrect answers, please try again...' });
                            return false
                        }
                    }
                }
            }
        })
    }

    const handleAllChaptersComplete = (whichBtn) => {

        if (whichBtn === 'quizBtn') {
            validateQuizAnswers();
        } else {
            completeChapter()
        }
    };
    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.center}>
                {goCourse.Title}
            </Typography>
            <Grid container spacing={2}>

                <Grid item xs={3}>
                    <div className={classes.xs3Grid}>
                        <Card className={classes.totalProgressCard}>
                            <div style={{ marginBottom: '30px' }}>
                                <Typography variant="h6" >{goCourse.Title}</Typography>
                                <Typography variant="caption" >{goCourse.TraineeFullName}</Typography>
                            </div>
                            <LinearProgress variant="determinate" value={goCourse.TotalProgress} />
                            <Typography variant="h6" style={{ marginTop: '10px' }}>
                                {goCourse.TotalProgress + ' % completed'}
                            </Typography>
                            <div style={{ visibility: goCourse.TotalProgress >= 100 ? 'hidden' : 'visible' }}>
                                <div style={{ margin: '20px' }}>
                                    <Typography variant="caption">
                                        * If you'd like to save your course progress for the next day, click the 'Save Progress' button.
                                        The application will save your progress and return you to the course's home page. To access your ongoing course, please use the same email address as before.
                                    </Typography>
                                </div>
                                <div className={classes.buttonCenter}>
                                    <Button
                                        onClick={saveProgressAndGoToCourseLibrary}
                                        variant="outlined"
                                        color="default">
                                        Save Progress
                                    </Button>
                                </div>
                                <div id='saveLoader' style={{ display: 'none' }}>
                                    <Loading />
                                </div>
                            </div>
                        </Card>
                        <br /> <br />
                        <div id='chaptersDiv' className={classes.chaptersCard}>

                            {goCourse.chapters.map((chapter, chapIndex) => (
                                // <Accordion key={chapIndex} expanded={activeExpanded.chapExpanded === chapIndex ? true : false} onChange={(e) => handleChangeAcordion(e, chapIndex)} className={classes.acordionMenu}>
                                <Accordion square key={chapIndex} expanded={activeExpanded.chapExpanded === chapIndex ? true : false} className={classes.acordionMenu}>
                                    <AccordionSummary aria-controls={"panel" + chapIndex + "Content"} id={chapIndex} >
                                        <Typography className={classes.heading}>{chapter.chapTitle}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        {chapter.chapSteps ?
                                            <Stepper nonLinear activeStep={activeExpanded.stepActive} orientation="vertical" className={classes.acordionMenu}>
                                                {chapter.chapSteps.map((label, stepIndex) => (
                                                    <Step key={stepIndex}>
                                                        <StepButton completed={goCourse.chaptersCompleted[chapIndex][stepIndex]}>
                                                            {label}
                                                        </StepButton>
                                                    </Step>
                                                ))}
                                            </Stepper> : null}
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                        </div>
                    </div>
                </Grid>

                <Grid item xs={9}>
                    <Paper className={classes.xs9Grid}>
                        {activeExpanded.chapExpanded === -2 ?
                            <Grid container spacing={2}
                                direction="column"
                                justifyContent="center"
                                alignItems="center">
                                <div className='wiseCertificationCongrats'>Congratulations!</div>
                                <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center">
                                    <img className={classes.congratsConfetti} src={congrats} alt="congrats confetti" />
                                    <div className={classes.centerCongrats}>
                                        <Typography display="block" varian="h6">{goCourse.TraineeFullName} you just successfully completed "{goCourse.Title}" course.</Typography>
                                    </div>
                                    <img className={classes.congratsConfettiFlipped} src={congratsFlipped} alt="congrats confetti" />
                                </Grid>
                                <div className={classes.questionsMinHeightDiv}>
                                    <div className={classes.center}>
                                        <img className={classes.cardMedia}
                                            src={goCourse.LogoUrl}
                                            alt={goCourse.Title}
                                        />
                                    </div>
                                </div>

                                <FormLabel>
                                    {/* Regular space: &nbsp;
                                    Two spaces gap: &ensp;
                                    Four spaces gap: &emsp; */}
                                    <Typography display="block" variant="body1" gutterBottom>
                                        Congratulations, an email notification is on the way, please check your inbox.<br />
                                        Attached in the email you will find your brand new certification PDF document<br />
                                        A notification was sent to the below addresses:<br /><br />
                                        &emsp;&emsp; &#9679; {goCourse.TraineeEmail}<br />
                                        &emsp;&emsp; &#9679; {goCourse.TraineeManagerEmail}<br /><br /><br /><br />
                                    </Typography>
                                    <Typography display="block" variant="body2" gutterBottom>
                                        If you didn't receive an email please notify your supervisor and make sure you provide all the details:<br /><br />
                                        &emsp;&emsp;1. Course name: "{goCourse.Title}"<br />
                                        &emsp;&emsp;2. Your Full Name: {goCourse.TraineeFullName}<br />
                                        &emsp;&emsp;3. Your Email: {goCourse.TraineeEmail}<br />
                                        &emsp;&emsp;4. Supervisors Full Name: {goCourse.TraineeManagerFullName}<br />
                                        &emsp;&emsp;5. Supervisors Email: {goCourse.TraineeManagerEmail}
                                    </Typography>
                                </FormLabel>
                                <br />

                                <div className={classes.finishButton}>
                                    <Button
                                        onClick={goToCourseLibrary}
                                        variant="contained"
                                        color="primary">
                                        Complete this Course and Return to Courses Library
                                    </Button>
                                </div>

                            </Grid>
                            : null}
                        {activeExpanded.chapExpanded === -1 ?
                            <Grid container spacing={2}
                                direction="column"
                                justifyContent="center"
                                alignItems="center">

                                <div className={classes.center}>
                                    <img className={classes.cardMedia}
                                        src={goCourse.LogoUrl}
                                        alt={goCourse.Title}
                                    />
                                </div>

                                <div style={{ marginTop: '50px', marginBottom: '100px', marginLeft: '10%', marginRight: '10%' }}>
                                    <FormLabel >{goCourse.Description}</FormLabel>
                                </div>

                                <div className={classes.startButton} >
                                    <Button
                                        onClick={startCourse}
                                        variant="outlined"
                                        color="primary">
                                        Let's go
                                    </Button>
                                </div>

                            </Grid>
                            : null}

                        {activeExpanded.chapExpanded >= 0 ?
                            <div>
                                {goCourse.chapters.map((chapter, chapIndex) => (
                                    <div key={chapIndex}>
                                        {activeExpanded.chapExpanded === chapIndex ?
                                            <div>

                                                <Typography variant="h6" gutterBottom className={classes.centerHeader}>{chapter.chapTitle}</Typography>

                                                {chapter.chapSteps ? chapter.chapSteps.map((stepText, stepIndex) => (
                                                    <div key={stepIndex}>
                                                        {activeExpanded.stepActive === stepIndex ?
                                                            <div>

                                                                <FormLabel><Typography variant="subtitle1" gutterBottom className={classes.centerSubtitle}>{stepText}</Typography></FormLabel>

                                                                {activeExpanded.chapExpanded === chapIndex && activeExpanded.stepActive === stepIndex ?
                                                                    <div>
                                                                        {goCourse.chapters[chapIndex].chapStepsContent[stepIndex].isVideoLesson ?

                                                                            <Grid container
                                                                                direction="column"
                                                                                justifyContent="center"
                                                                                alignItems="center">
                                                                                <video className={classes.videoSize}
                                                                                    src={goCourse.chapters[chapIndex].chapStepsContent[stepIndex].videoUrl}
                                                                                    // type="video/mp4"
                                                                                    // poster={poster}
                                                                                    id={'chapter' + chapIndex + 'Step' + stepIndex}
                                                                                    onPlay={playing}
                                                                                    controls
                                                                                    controlsList="nodownload">
                                                                                    Your browser does not support HTML video.
                                                                                </video>

                                                                                <Typography variant="caption" gutterBottom style={{ marginLeft: '20%', marginRight: '20%' }}>* Please complete watching this video as many times as you feel is needed, when you are ready please complete this step by clicking "Complete Step" button.</Typography>

                                                                                <div className={classes.textDivVid}>
                                                                                    <FormLabel className={classes.myFormLabel}><Typography variant="body1" gutterBottom>{goCourse.chapters[chapIndex].chapStepsContent[stepIndex].textOptional}</Typography></FormLabel>
                                                                                </div>
                                                                                <div className={classes.completeButton} id={'chapter' + chapIndex + 'Step' + stepIndex + 'Complete'}>
                                                                                    <Button
                                                                                        onClick={() => handleAllChaptersComplete('videoBtn')}
                                                                                        variant="outlined"
                                                                                        color="primary">
                                                                                        {goCourse.TotalStepsCompleted === goCourse.TotalSteps - 1 ? 'Finish' : 'Complete Step'}
                                                                                    </Button>
                                                                                </div>
                                                                            </Grid>

                                                                            : null}

                                                                        {goCourse.chapters[chapIndex].chapStepsContent[stepIndex].isTextLesson ?
                                                                            <Grid container
                                                                                direction="column"
                                                                                justifyContent="center"
                                                                                alignItems="center">

                                                                                <div className={classes.textDiv}>
                                                                                    <FormLabel className={classes.myFormLabel}><Typography variant="body1" gutterBottom>{goCourse.chapters[chapIndex].chapStepsContent[stepIndex].text}</Typography></FormLabel>
                                                                                </div>

                                                                                <div className={classes.completeButtonVisible} id={'chapter' + chapIndex + 'Step' + stepIndex + 'Complete'}>
                                                                                    <Button
                                                                                        onClick={() => handleAllChaptersComplete('textBtn')}
                                                                                        variant="outlined"
                                                                                        color="primary">
                                                                                        {goCourse.TotalStepsCompleted === goCourse.TotalSteps - 1 ? 'Finish' : 'Complete Step'}
                                                                                    </Button>
                                                                                </div>
                                                                            </Grid>
                                                                            : null}

                                                                        {goCourse.chapters[chapIndex].chapStepsContent[stepIndex].isQuizLesson ?
                                                                            <div>
                                                                                <div className={classes.questionsMinHeightDiv}>

                                                                                    {chapter.chapStepsContent[stepIndex].questions.map((quest, questionIndex) => (

                                                                                        <div key={questionIndex} className={classes.questionsDiv}>

                                                                                            {quest.type === 'Single' ?
                                                                                                <div className={classes.singleQuestion}>
                                                                                                    <FormControl component="fieldset" error={userAnswers['q' + chapIndex + 'q' + questionIndex + 'Error']}>
                                                                                                        <FormLabel component="legend">{questionIndex + 1 + '. ' + quest.question}
                                                                                                        </FormLabel>
                                                                                                        <br />
                                                                                                        <FormHelperText>{userAnswers['q' + chapIndex + 'q' + questionIndex + 'HelperText']}</FormHelperText>
                                                                                                        <RadioGroup aria-label="quiz" name={'q' + chapIndex + 'q' + questionIndex + 'Value'} value={userAnswers['q' + chapIndex + 'q' + questionIndex + 'Value']} onChange={handleRadioChange} className={classes.radioGroup}>
                                                                                                            {quest.choices.map((choice, choiceIndex) => (
                                                                                                                <FormControlLabel key={choiceIndex} value={choice} control={<Radio color="primary" />} label={choice} />
                                                                                                            ))}
                                                                                                        </RadioGroup>
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                                :
                                                                                                //'Multiple'
                                                                                                <div className={classes.singleQuestion}>
                                                                                                    <FormControl component="fieldset" error={userAnswers['q' + chapIndex + 'q' + questionIndex + 'Error']}>
                                                                                                        <FormLabel component="legend">{questionIndex + 1 + '. ' + quest.question + '(multiple correct answers)'}</FormLabel>
                                                                                                        <br />
                                                                                                        <FormHelperText>{userAnswers['q' + chapIndex + 'q' + questionIndex + 'HelperText']}</FormHelperText>
                                                                                                        <FormGroup name={'q' + questionIndex} className={classes.radioGroup}>
                                                                                                            {quest.choices.map((choice, choiceIndex) => (
                                                                                                                <FormControlLabel key={choiceIndex}
                                                                                                                    // control={<Checkbox checked={quiz.q1q4Sean} color="primary" onChange={handleChangeMultichoice} name="q1q4Sean" />}
                                                                                                                    control={<Checkbox checked={userAnswers['q' + chapIndex + 'q' + questionIndex + 'Value' + choice]} color="primary" onChange={handleChangeMultichoice} name={'q' + chapIndex + 'q' + questionIndex + 'Value' + choice} />}
                                                                                                                    // control={<Checkbox color="primary" name="q1q4Sean" />}
                                                                                                                    label={choice}
                                                                                                                />
                                                                                                            ))}
                                                                                                        </FormGroup>
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                <div className={classes.completeButtonVisible} id={'chapter' + chapIndex + 'Step' + stepIndex + 'Complete'}>
                                                                                    <Button
                                                                                        onClick={() => handleAllChaptersComplete('quizBtn')}
                                                                                        variant="outlined"
                                                                                        color="primary">
                                                                                        {goCourse.TotalStepsCompleted === goCourse.TotalSteps - 1 ? 'Finish' : 'Complete Step'}
                                                                                    </Button>
                                                                                </div>


                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                            : null}
                                                    </div>
                                                )) : null}
                                            </div>
                                            : null}
                                    </div>
                                ))}
                            </div>
                            : null}

                    </Paper>
                </Grid>
            </Grid>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

            <Footer />
        </div>
    );
}