import React, { useContext, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MuiListItem from '@material-ui/core/ListItem';
import HomeIcon from '@material-ui/icons/Home';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import connectW from '../../../images/ConnectWisetekNet.png'
import { Avatar, Breadcrumbs, Collapse, Grid, ListItemSecondaryAction } from '@material-ui/core';
import { AuthContext } from '../../../contexts/AuthContext';
import { Link, Route } from 'react-router-dom';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MailIcon from '@material-ui/icons/Mail';
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BuildIcon from '@material-ui/icons/Build';
import AdjustIcon from '@material-ui/icons/Adjust';
import AllInboxTwoToneIcon from '@material-ui/icons/AllInboxTwoTone';
import { BrowserRouter, useHistory } from 'react-router-dom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import FaceIcon from '@material-ui/icons/Face';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AssignmentLateTwoToneIcon from '@material-ui/icons/AssignmentLateTwoTone';
import LaptopWindowsTwoToneIcon from '@material-ui/icons/LaptopWindowsTwoTone';
import ApartmentTwoToneIcon from '@material-ui/icons/ApartmentTwoTone';
import SupervisorAccountTwoToneIcon from '@material-ui/icons/SupervisorAccountTwoTone';
import { AccessibilityNewRounded, ExpandLess, ExpandMore } from '@material-ui/icons';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import BusinessIcon from '@material-ui/icons/Business';
import MemoryTwoToneIcon from '@material-ui/icons/MemoryTwoTone';
import AlarmOnTwoToneIcon from '@material-ui/icons/AlarmOnTwoTone';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import NavIcon from '../../../images/NavIcon.svg'
import ReturntekIcon from '../../../images/ReturntekIcon.svg'
import FactorialIcon from '../../../images/FactorialIcon.svg'
// import factoriaL from '../../../images/FacV5.svg'
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import SchoolIcon from '@material-ui/icons/School';
import BookTwoToneIcon from '@material-ui/icons/BookTwoTone';
import TrackChangesTwoToneIcon from '@material-ui/icons/TrackChangesTwoTone';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import { Link as RouterLink } from 'react-router-dom'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import PermContactCalendarTwoToneIcon from '@material-ui/icons/PermContactCalendarTwoTone';
import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import CardMembershipTwoToneIcon from '@material-ui/icons/CardMembershipTwoTone';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import DnsIcon from '@material-ui/icons/Dns';
import AllOutOutlinedIcon from '@material-ui/icons/AllOutOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import DialpadIcon from '@material-ui/icons/Dialpad';
import RoomServiceTwoToneIcon from '@material-ui/icons/RoomServiceTwoTone';
import RoomServiceIcon from '@material-ui/icons/RoomService';


const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({

    connectLogo: {
        height: 100
    },
    connectLogoImage: {
        maxWidth: 300
    },
    userLogo: {
        minHeight: 100
    },
    largeAvatar: {
        // backgroundColor: '#182751',
        margin: 10,
        backgroundColor: '#5E89B4',
    },
    appBar: {
        // width: `calc(100% - ${drawerWidth}px)`,
        minWidth: 600,
        width: '100%',
        // marginLeft: drawerWidth,
        height: 100,
        backgroundColor: '#f0f5f4',
        // backgroundColor: '#cfd8dc'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#182751',
        color: 'lightblue',

    },
    dividerColor: {
        // backgroundColor: '#5E89B4'
        backgroundColor: 'lightblue'
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    menuButton: {
        textAlign: 'left'
    },
    menuDrawer: {
        width: '250px',
    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    doubleNested: {
        paddingLeft: theme.spacing(5),
    },
    tripleNested: {
        paddingLeft: theme.spacing(6),
    },
    quadripleNested: {
        paddingLeft: theme.spacing(7),
    },
    restrictedRed: {
        paddingLeft: theme.spacing(4),
        color: 'red',
        opacity: 0.6
    },

}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const ListItem = withStyles({
    root: {
        "& .MuiListItemIcon-root": {
            color: "lightblue"
        },
        "&$selected": {
            backgroundColor: "#5E89B4",
            color: "white",
            "& .MuiListItemIcon-root": {
                color: "white"
            }
        },
        "&$selected:hover": {
            backgroundColor: "#5E8999",
            color: "white",
            "& .MuiListItemIcon-root": {
                color: "white"
            }
        },
        "&:hover": {
            backgroundColor: "MidnightBlue",
            color: "white",
            "& .MuiListItemIcon-root": {
                color: "white"
            }
        }
    },
    selected: {}
})(MuiListItem);

export default function SideMenuV2(props) {
    const classes = useStyles();
    const { signOut, roles, displayName, initials, department, uid } = useContext(AuthContext);

    const history = useHistory();

    const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);
    //nested
    const [openTicketingColapse, setOpenTicketingColapse] = useState(props.openTicketingColapse);
    const [openProductionColapse, setOpenProductionColapse] = useState(props.openProductionColapse);
    const [openHumanResourcesColapse, setOpenHumanResourcesColapse] = useState(props.openHumanResourcesColapse);
    const [openHowToVideosColapse, setOpenHowToVideosColapse] = useState(props.openHowToVideosColapse);
    const [openMetricsColapse, setOpenMetricsColapse] = useState(props.openMetricsColapse);
    const [openISandTColapse, setOpenISandTColapse] = useState(props.openISandTColapse);
    const [openEngColapse, setOpenEngColapse] = useState(props.openEngColapse);
    const [openSalesOpsColapse, setOpenSalesOpsColapse] = useState(props.openSalesOpsColapse);

    //double nested
    const [openHowToVideosNavisionArchieColapse, setOpenHowToVideosNavisionArchieColapse] = useState(props.openHowToVideosNavisionArchieColapse);
    const [openHowToVideosNavisionColapse, setOpenHowToVideosNavisionColapse] = useState(props.openHowToVideosNavisionColapse);
    const [openMetricsISandTColapse, setOpenMetricsISandTColapse] = useState(props.openMetricsISandTColapse);
    const [openMetricsOperationsColapse, setOpenMetricsOperationsColapse] = useState(props.openMetricsOperationsColapse);
    const [openMetricsQandCColapse, setOpenMetricsQandCColapse] = useState(props.openMetricsQandCColapse);
    const [openMetricsCustomerSLAColapse, setOpenMetricsCustomerSLAColapse] = useState(props.openMetricsCustomerSLAColapse);
    //triple nested
    const [openHowToVideosNavisionProductionColapse, setOpenHowToVideosNavisionProductionColapse] = useState(props.openHowToVideosNavisionProductionColapse);
    const [openMetricsQandCChainColapse, setOpenMetricsQandCChainColapse] = useState(props.openMetricsQandCChainColapse);
    const [openMetricsQandCQualityColapse, setOpenMetricsQandCQualityColapse] = useState(props.openMetricsQandCQualityColapse);


    //#region handle
    const handleClickTicketing = (e) => {
        setOpenTicketingColapse(!openTicketingColapse);
    };
    const handleClickISandT = () => {
        setOpenISandTColapse(!openISandTColapse);
    };
    const handleClickEng = () => {
        setOpenEngColapse(!openEngColapse);
    };
    const handleClickMetrics = () => {
        setOpenMetricsColapse(!openMetricsColapse);
    };
    const handleClickMetricsISandT = () => {
        setOpenMetricsISandTColapse(!openMetricsISandTColapse);
    };
    const handleClickMetricsOperations = () => {
        setOpenMetricsOperationsColapse(!openMetricsOperationsColapse);
    };
    const handleClickMetricsQandC = () => {
        setOpenMetricsQandCColapse(!openMetricsQandCColapse);
    };
    const handleClickMetricsQandCChain = () => {
        setOpenMetricsQandCChainColapse(!openMetricsQandCChainColapse);
    };
    const handleClickMetricsQandCQuality = () => {
        setOpenMetricsQandCQualityColapse(!openMetricsQandCQualityColapse);
    };
    const handleClickMetricsCustomerSLA = () => {
        setOpenMetricsCustomerSLAColapse(!openMetricsCustomerSLAColapse);
    };
    const handleClickProduction = () => {
        setOpenProductionColapse(!openProductionColapse);
    };
    const handleClickHumanR = () => {
        setOpenHumanResourcesColapse(!openHumanResourcesColapse);
    };
    const handleClickHowToVideos = () => {
        setOpenHowToVideosColapse(!openHowToVideosColapse);
    };
    const handleClickHowToVideosNavision = () => {
        setOpenHowToVideosNavisionColapse(!openHowToVideosNavisionColapse);
    };
    const handleClickHowToVideosNavisionProduction = () => {
        setOpenHowToVideosNavisionProductionColapse(!openHowToVideosNavisionProductionColapse);
    };
    const handleClickHowToVideosNavisionArchie = () => {
        setOpenHowToVideosNavisionArchieColapse(!openHowToVideosNavisionArchieColapse);
    };
    const handleClickSalesOps = () => {
        setOpenSalesOpsColapse(!openSalesOpsColapse);
    };
    //#endregion

    //#region breadcrumbs
    const breadcrumbNameMap = {
        '/home': 'Home',
        '/ticketingISandT': 'Ticketing / IS&T',
        '/ticketingEngineering': 'Ticketing / Engineering',
        '/ticketingFacilities': 'Ticketing / Facilities',
        '/ticketingHR': 'Ticketing / HR',
        '/ticketingCS': 'Ticketing / Customer Service',
        '/memoryScan': 'Production / Memory Scan',
        '/purgeChecker': 'Production / Purge Checker',
        '/googleAccessories': 'Production / Wise Label',
        '/serverRecovery': 'Production / Server Recovery',
        '/meetingRooms': 'Meeting Rooms',
        '/careers': 'Human Resources / Careers',
        '/wisestars': 'Human Resources / WiseStars',
        // '/holiday': 'Holiday Request',
        // '/wiseAcademy': 'WiseAcademy',
        '/ticketsIS&T': 'IS&T / Tickets',
        '/knowledgebox': 'IS&T / KnowledgeBox',
        '/incidentsIS&T': 'IS&T / Incident Log',
        '/istActionTracker': 'IS&T / Action Tracker',
        '/facilitiesconsole': 'Facilities',
        '/engineeringTickets': 'Engineering / Tickets',
        '/engActionTracker': 'Engineering / Action Tracker',
        '/engDowntimeLog': 'Engineering / Downtime Log',
        '/csconsole': 'Customer Service',
        '/hrconsole': 'Human Resources',
        '/admin': 'Admin',
        '/testing': 'Testing',
        '/navisionArchieAiken': 'How to videos / Archie / Aiken',
        '/navisionArchieBlancco': 'How to videos / Archie / Blancco',
        '/navisionArchieWhiteCanyon': 'How to videos / Archie / WhiteCanyon',
        '/navisionArchieDellBiosOwnership': 'How to videos / Archie / Dell BIOS Ownership Date',
        '/navisionArchieDEP': 'How to videos / Archie / DEP',
        '/navisionArchieDigitalLicenses': 'How to videos / Archie / Digital Licenses',
        '/navisionArchieOperatingSystem': 'How to videos / Archie / Operating System',
        '/navisionArchieT2Workaround': 'How to videos / Archie / T2 Workaround',
        '/navisionCustomerService': 'How to videos / Navision / Customer Service',
        '/navisionFinance': 'How to videos / Navision / Finance',
        '/navisionSales': 'How to videos / Navision / Sales',
        '/navisionProductionBasicAudit': 'How to videos / Navision / Production / Basic Audit',
        '/navisionProductionInventoryManagement': 'How to videos / Navision / Production / Inventory Management',
        '/navisionProductionReceiving': 'How to videos / Navision / Production / Receiving',
        '/navisionProductionShipping': 'How to videos / Navision / Production / Shipping',
        '/navisionProductionTechAudit': 'How to videos / Navision / Production / Tech Audit',
        '/navisionProductionVerify': 'How to videos / Navision / Production / Verify',
        '/ecommerceTraining': 'How to videos / Ecommerce',
        '/returntekTraining': 'How to videos / Returntek',
        '/factorialTraining': 'How to videos / Factorial',
        '/kpis': 'Metrics / IS&T / KPI\'s',
        '/archiekpis': 'Metrics / Operations / Archie KPI\'s',
        '/metricsChainOfCustodyDealIntegrity': 'Metrics / Quality & Compliance / Chain of Custody / Deal Integrity',
        '/metricsChainOfCustodyVerificationWip': 'Metrics / Quality & Compliance / Chain of Custody / Verification WIP',
        '/metricsChainOfCustodyDealsInSales': 'Metrics / Quality & Compliance / Chain of Custody / Deals in Sales',
        '/metricsQualityCapaVol': 'Metrics / Quality & Compliance / Quality / Capa Vol & Aging',
        '/metricsQualityCustomerIssues': 'Metrics / Quality & Compliance / Quality / Customer Issues',
        '/metricsQualityFPY': 'Metrics / Quality & Compliance / Quality / FPY',
        '/metricsCustomerSLAforIEandUK': 'Metrics / Customer SLA / IE & UK SLA',
        '/metricsCustomerSLAforUS': 'Metrics / Customer SLA / US SLA',
        // '/metricsInventory': 'Metrics / Inventory',
        '/feedback': 'Feedback',
        '/ist27001': 'IS&T / IST 27001',
        '/eh&s': 'Environmental Health and Safety',
        '/fileFormatter': 'Sales Ops / File Formatter',
        '/partnerCalculator': 'Production / Partner Calculator'
    };
    //#endregion

    //#region goTo
    const goTicketingISandT = (e) => {
        history.push("/ticketingISandT");
    }
    const goTicketingEngineering = (e) => {
        history.push("/ticketingEngineering");
    }
    const goTicketingFacilities = (e) => {
        history.push("/ticketingFacilities");
    }
    const goTicketingHR = (e) => {
        history.push("/ticketingHR");
    }
    const goTicketingCS = (e) => {
        history.push("/ticketingCS");
    }
    const goDashboard = (e) => {
        history.push("/home");
    }
    const goAdmin = (e) => {
        history.push("/admin");
    }
    const goTicketsIST = (e) => {
        history.push("/ticketsIS&T");
    }
    const goIncidentsIST = (e) => {
        history.push("/incidentsIS&T");
    }
    // const goHoliday = (e) => {
    //     history.push("/holiday");
    // }
    const goMeetingRooms = (e) => {
        history.push("/meetingRooms");
    }
    const goEngineeringTickets = (e) => {
        history.push("/engineeringTickets");
    }
    const goEngActionTracker = (e) => {
        history.push("/engActionTracker");
    }
    const goISTActionTracker = (e) => {
        history.push("/istActionTracker");
    }
    const goIST27001 = (e) => {
        history.push("/ist27001");
    }
    const goEngDowntimeLog = (e) => {
        history.push("/engDowntimeLog");
    }
    const goFacilities = (e) => {
        history.push("/facilitiesconsole");
    }
    const goCS = (e) => {
        history.push("/csconsole");
    }
    const goHR = (e) => {
        history.push("/hrconsole");
    }
    const goTesting = (e) => {
        history.push("/testing");
    }
    const goMetricsKPIs = (e) => {
        history.push("/kpis");
    }
    const goArchieKPIs = (e) => {
        history.push("/archiekpis");
    }
    const goKnowledgeBox = (e) => {
        history.push("/knowledgebox");
    }
    const goProfile = (e) => {
        history.push("/profile");
    }
    const goFeedback = (e) => {
        history.push("/feedback");
    }
    const goMetricsCustomerSLAforUS = (e) => {
        history.push("/metricsCustomerSLAforUS");
    }
    const goMetricsCustomerSLAforIEandUK = (e) => {
        history.push("/metricsCustomerSLAforIEandUK");
    }
    const goMetricsChainOfCustodyDealIntegrity = (e) => {
        history.push("/metricsChainOfCustodyDealIntegrity")
    }
    const goMetricsChainOfCustodyVerificationWip = (e) => {
        history.push("/metricsChainOfCustodyVerificationWip")
    }
    const goMetricsChainOfCustodyDealsInSales = (e) => {
        history.push("/metricsChainOfCustodyDealsInSales")
    }
    const goMetricsQualityCapaVol = (e) => {
        history.push("/metricsQualityCapaVol")
    }
    const goMetricsQualityCustomerIssues = (e) => {
        history.push("/metricsQualityCustomerIssues")
    }
    const goMetricsOption2 = (e) => {
        history.push("/")
    }
    const goProductionMemoryScan = (e) => {
        history.push("/memoryScan")
    }
    const goProductionPurgeChecker = (e) => {
        history.push("/purgeChecker")
    }
    const goProductionGoogleAccessories = (e) => {
        history.push("/googleAccessories")
    }
    const goProductionServerRecovery = (e) => {
        history.push("/serverRecovery")
    }
    const goProductionPpidLabel = (e) => {
        history.push("/ppidLabel")
    }
    const goProductionPartnerCalculator = (e) => {
        history.push("/partnerCalculator")
    }
    const goHrInternalJobs = (e) => {
        history.push("/careers")
    }
    const goHrWiseStars = (e) => {
        history.push("/wisestars")
    }
    const goNavisionCustomerService = (e) => {
        history.push("/navisionCustomerService")
    }
    const goNavisionFinance = (e) => {
        history.push("/navisionFinance")
    }
    const goNavisionProductionBasicAudit = (e) => {
        history.push("/navisionProductionBasicAudit")
    }
    const goNavisionProductionInventoryManagement = (e) => {
        history.push("/navisionProductionInventoryManagement")
    }
    const goNavisionProductionReceiving = (e) => {
        history.push("/navisionProductionReceiving")
    }
    const goNavisionProductionShipping = (e) => {
        history.push("/navisionProductionShipping")
    }
    const goNavisionProductionTechAudit = (e) => {
        history.push("/navisionProductionTechAudit")
    }
    const goNavisionProductionVerify = (e) => {
        history.push("/navisionProductionVerify")
    }
    const goEcommerceTraining = (e) => {
        history.push("/ecommerceTraining")
    }
    const goReturntekTraining = (e) => {
        history.push("/returntekTraining")
    }
    const goFactorialTraining = (e) => {
        history.push("/factorialTraining")
    }
    const goNavisionSales = (e) => {
        history.push("/navisionSales")
    }
    const goNavisionArchieAiken = (e) => {
        history.push("/navisionArchieAiken")
    }
    const goNavisionArchieBlancco = (e) => {
        history.push("/navisionArchieBlancco")
    }
    const goNavisionArchieDellBiosOwnership = (e) => {
        history.push("/navisionArchieDellBiosOwnership")
    }
    const goNavisionArchieDEP = (e) => {
        history.push("/navisionArchieDEP")
    }
    const goNavisionArchieDigitalLicenses = (e) => {
        history.push("/navisionArchieDigitalLicenses")
    }
    const goNavisionArchieOS = (e) => {
        history.push("/navisionArchieOperatingSystem")
    }
    const goNavisionArchieT2Workaround = (e) => {
        history.push("/navisionArchieT2Workaround")
    }
    const goNavisionArchieWhiteCanyon = (e) => {
        history.push("/navisionArchieWhiteCanyon")
    }
    const goWiseAcademy = (e) => {
        history.push("/wiseAcademy");
    }
    const goAttendanceClock = (e) => {
        history.push("/attendanceClock");
    }
    const goHandS = (e) => {
        history.push("/eh&s");
    }
    const goSalesOpsFileFormatter = (e) => {
        history.push("/fileFormatter");
    }
    //#endregion

    //#region slectedIndexes variables
    const siHome = 0
    const siAttendanceClock = 1
    const siTicketingISandT = 2
    const siTicketingFacilities = 3
    const siTicketingHR = 4
    const siProductionMemoryScan = 5
    const siMeetingRooms = 6
    // const siHolidayRequest = 7
    const siWiseAcademy = 8
    const siNavisionArchieAiken = 9
    const siNavisionArchieBlancco = 10
    const siNavisionArchieWhiteCanyon = 11
    const siNavisionArchieDellBiosOwnership = 12
    const siNavisionArchieDEP = 13
    const siNavisionArchieDigitalLicenses = 14
    const siNavisionArchieOS = 15
    const siNavisionArchieT2Workaround = 16
    const siNavisionCustomerService = 17
    const siNavisionFinance = 18
    const siNavisionProductionBasicAudit = 19
    const siNavisionProductionInventoryManagement = 20
    const siNavisionProductionReceiving = 21
    const siNavisionProductionShipping = 22
    const siNavisionProductionTechAudit = 23
    const siNavisionProductionVerify = 24
    const siNavisionSales = 25
    const siReturntekTraining = 26
    const siFactorialTraining = 27
    const siMetricsKPIs = 28
    const siArchieKPIs = 29
    const siMetricsChainOfCustodyDealIntegrity = 30
    const siMetricsChainOfCustodyVerificationWip = 31
    const siMetricsChainOfCustodyDealsInSales = 32
    const siMetricsQualityCapaVol = 33
    const siMetricsQualityCustomerIssues = 34
    const siMetricsCustomerSLAforIEandUK = 35
    const siMetricsCustomerSLAforUS = 36
    const siTicketsIST = 37
    const siKnowledgeBox = 38
    const siIncidentsIST = 39
    const siISTActionTracker = 40
    const siEngActionTracker = 41
    const siEngDowntimeLog = 42
    const siFacilities = 43
    const siHR = 44
    const siAdmin = 45
    const siTesting = 46
    const siFeedback = 47
    const siProfile = 48
    const siHrInternalJobs = 49
    const siHrWiseStars = 50
    const siIST27001 = 51
    const siTicketingEngineering = 52
    const siEngTickets = 53
    const siHandS = 54
    const siProductionGoogleAccessories = 55
    const siProductionServerRecovery = 56
    const siProductionPpidLabel = 57
    const siEcommerceTraining = 58
    const siProductionPurgeChecker = 59
    const siSalesOpsFileFormatter = 60
    const siProductionPartnerCalculator = 61
    const siTicketingCS = 62
    const siCS = 63
    //#endregion

    return (
        <div >
            <CssBaseline />
            <Route>
                {({ location }) => {
                    // console.log(location.pathname)
                    if (location.pathname == '/home') {
                        return (<div></div>)
                    } else {
                        return (
                            <AppBar position="sticky" className={classes.appBar}>
                                <Toolbar >
                                    <Grid className={classes.connectLogo}
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <img src={connectW} alt="connect logo" className={classes.connectLogoImage} />

                                        <Route>
                                            {({ location }) => {
                                                const pathnames = location.pathname.split('/').filter((x) => x);
                                                return (
                                                    <Breadcrumbs aria-label="breadcrumb">
                                                        {/* <LinkRouter color="inherit" to="/">
                                                            Home
                                                        </LinkRouter> */}
                                                        {pathnames.map((value, index) => {
                                                            const last = index === pathnames.length - 1;
                                                            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                                                            return last ? (
                                                                <Typography color="textPrimary" key={to}>
                                                                    {breadcrumbNameMap[to]}
                                                                </Typography>
                                                            ) : (
                                                                <LinkRouter color="inherit" to={to} key={to}>
                                                                    {breadcrumbNameMap[to]}
                                                                </LinkRouter>
                                                            );
                                                        })}
                                                    </Breadcrumbs>
                                                );
                                            }}
                                        </Route>
                                    </Grid>
                                </Toolbar>
                            </AppBar>
                        )
                    }
                }}
            </Route>

            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <Grid className={classes.userLogo}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Avatar className={classes.largeAvatar}>{initials}</Avatar>
                    <Typography align='center' variant="subtitle2" style={{ color: 'white' }}>{displayName}</Typography>
                </Grid>
                <Divider className={classes.dividerColor} />

                <List>
                    <ListItem button name="home" selected={selectedIndex === siHome} onClick={goDashboard}><ListItemIcon><HomeIcon /></ListItemIcon><ListItemText>Home</ListItemText></ListItem>
                    {uid === '6swPjBlta5XPapuq4FIgZQLrDPB3'
                        ? <ListItem button name="attendanceClock" selected={selectedIndex === siAttendanceClock} onClick={goAttendanceClock}><ListItemIcon><AddAlarmIcon /></ListItemIcon><ListItemText>Attendance Clock</ListItemText></ListItem> : null}
                    <ListItem button name="ticketing" onClick={handleClickTicketing}><ListItemIcon><MailIcon /></ListItemIcon><ListItemText>Ticketing</ListItemText>
                        {openTicketingColapse ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openTicketingColapse} timeout="auto" unmountOnExit>
                        <List dense={true} component="div" disablePadding>
                            <ListItem button className={classes.nested} selected={selectedIndex === siTicketingISandT} onClick={goTicketingISandT}><ListItemIcon><LaptopWindowsTwoToneIcon /></ListItemIcon><ListItemText primary="IS & T" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siTicketingEngineering} onClick={goTicketingEngineering}><ListItemIcon><BuildIcon /></ListItemIcon><ListItemText primary="Engineering" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siTicketingFacilities} onClick={goTicketingFacilities}><ListItemIcon><ApartmentTwoToneIcon /></ListItemIcon><ListItemText primary="Facilities" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siTicketingHR} onClick={goTicketingHR}><ListItemIcon><SupervisorAccountTwoToneIcon /></ListItemIcon><ListItemText primary="HR" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siTicketingCS} onClick={goTicketingCS}><ListItemIcon><RoomServiceTwoToneIcon /></ListItemIcon><ListItemText primary="Customer Service" /></ListItem>
                        </List>
                    </Collapse>
                    <ListItem button name="production" onClick={handleClickProduction}><ListItemIcon><BusinessIcon /></ListItemIcon><ListItemText>Production</ListItemText>
                        {openProductionColapse ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openProductionColapse} timeout="auto" unmountOnExit>
                        <List dense={true} component="div" disablePadding>
                            <ListItem button className={classes.nested} selected={selectedIndex === siProductionMemoryScan} onClick={goProductionMemoryScan}><ListItemIcon><MemoryTwoToneIcon /></ListItemIcon><ListItemText primary="Memory Scan" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siProductionPurgeChecker} onClick={goProductionPurgeChecker}><ListItemIcon><AlarmOnTwoToneIcon /></ListItemIcon><ListItemText primary="Purge Checker" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siProductionGoogleAccessories} onClick={goProductionGoogleAccessories}><ListItemIcon><BlurOnIcon /></ListItemIcon><ListItemText primary="Wise Label" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siProductionServerRecovery} onClick={goProductionServerRecovery}><ListItemIcon><DnsIcon /></ListItemIcon><ListItemText primary="Server Recovery" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siProductionPpidLabel} onClick={goProductionPpidLabel}><ListItemIcon><AllOutOutlinedIcon /></ListItemIcon><ListItemText primary="PPID Label" /></ListItem>
                            {roles.includes('partnerManager') || roles.includes('admin') ?
                                <ListItem button className={classes.nested} selected={selectedIndex === siProductionPartnerCalculator} onClick={goProductionPartnerCalculator}><ListItemIcon><DialpadIcon /></ListItemIcon><ListItemText primary="Partner Calculator" /></ListItem> : null}
                        </List>
                    </Collapse>
                    <ListItem button name="meetingRooms" selected={selectedIndex === siMeetingRooms} onClick={goMeetingRooms}><ListItemIcon><MeetingRoomIcon /></ListItemIcon><ListItemText>Meeting Rooms</ListItemText></ListItem>
                    <ListItem button name="humanResources" onClick={handleClickHumanR}><ListItemIcon><SupervisorAccountIcon /></ListItemIcon><ListItemText>Human Resources</ListItemText>
                        {openHumanResourcesColapse ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openHumanResourcesColapse} timeout="auto" unmountOnExit>
                        <List dense={true} component="div" disablePadding>
                            <ListItem button className={classes.nested} selected={selectedIndex === siHrInternalJobs} onClick={goHrInternalJobs}><ListItemIcon><PermContactCalendarTwoToneIcon /></ListItemIcon><ListItemText primary="Careers" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siHrWiseStars} onClick={goHrWiseStars}><ListItemIcon><StarTwoToneIcon /></ListItemIcon><ListItemText primary="WiseStars" /></ListItem>
                        </List>
                    </Collapse>
                    <ListItem button name="healthAndSafety" selected={selectedIndex === siHandS} onClick={goHandS}><ListItemIcon><AccessibilityNewRounded /></ListItemIcon><ListItemText>Environmental Health and Safety</ListItemText></ListItem>
                    {/* <ListItem button name="holiday" selected={selectedIndex === siHolidayRequest} onClick={goHoliday}><ListItemIcon><BeachAccessIcon /></ListItemIcon><ListItemText>Holiday Request</ListItemText></ListItem> */}
                    <ListItem button name="wiseAcademy" selected={selectedIndex === siWiseAcademy} onClick={goWiseAcademy}><ListItemIcon><SchoolIcon /></ListItemIcon><ListItemText>Wise Academy</ListItemText></ListItem>
                    <ListItem button name="howToVideos" onClick={handleClickHowToVideos}><ListItemIcon><OndemandVideoIcon /></ListItemIcon><ListItemText>How to videos</ListItemText>
                        {openHowToVideosColapse ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openHowToVideosColapse} timeout="auto" unmountOnExit>
                        <List dense={true} component="div" disablePadding>
                            <ListItem button className={classes.nested} onClick={handleClickHowToVideosNavisionArchie}><ListItemIcon><SettingsInputSvideoIcon /></ListItemIcon><ListItemText primary="Archie" />{openHowToVideosNavisionArchieColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                            <Collapse in={openHowToVideosNavisionArchieColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionArchieAiken} onClick={goNavisionArchieAiken}><ListItemText primary="Aiken" /></ListItem>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionArchieBlancco} onClick={goNavisionArchieBlancco}><ListItemText primary="Blancco" /></ListItem>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionArchieWhiteCanyon} onClick={goNavisionArchieWhiteCanyon}><ListItemText primary="WhiteCanyon" /></ListItem>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionArchieDellBiosOwnership} onClick={goNavisionArchieDellBiosOwnership}><ListItemText primary="Dell BIOS Ownership Date" /></ListItem>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionArchieDEP} onClick={goNavisionArchieDEP}><ListItemText primary="DEP" /></ListItem>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionArchieDigitalLicenses} onClick={goNavisionArchieDigitalLicenses}><ListItemText primary="Digital Licenses" /></ListItem>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionArchieOS} onClick={goNavisionArchieOS}><ListItemText primary="Operating System" /></ListItem>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionArchieT2Workaround} onClick={goNavisionArchieT2Workaround}><ListItemText primary="T2 Workaround" /></ListItem>
                                </List>
                            </Collapse>
                            <ListItem button className={classes.nested} onClick={handleClickHowToVideosNavision}><ListItemIcon><img src={NavIcon} width="25px" height="25px" /></ListItemIcon><ListItemText primary="Navision" />{openHowToVideosNavisionColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                            <Collapse in={openHowToVideosNavisionColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionCustomerService} onClick={goNavisionCustomerService}><ListItemText primary="Customer Service" /></ListItem>
                                    {department === 'Finance' || roles.includes('admin') || uid === 'PJRnhkoSFeYOSLa3jUMvbsFfCDu1' ? <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionFinance} onClick={goNavisionFinance}><ListItemText primary="Finance" /></ListItem> : null}
                                    <ListItem button className={classes.tripleNested} onClick={handleClickHowToVideosNavisionProduction}><ListItemText primary="Production" />{openHowToVideosNavisionProductionColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                                    <Collapse in={openHowToVideosNavisionProductionColapse} timeout="auto" unmountOnExit>
                                        <List dense={true} component="div" disablePadding>
                                            <ListItem button className={classes.quadripleNested} selected={selectedIndex === siNavisionProductionBasicAudit} onClick={goNavisionProductionBasicAudit}><ListItemText primary="Basic Audit" /></ListItem>
                                            <ListItem button className={classes.quadripleNested} selected={selectedIndex === siNavisionProductionInventoryManagement} onClick={goNavisionProductionInventoryManagement}><ListItemText primary="Inventory Management" /></ListItem>
                                            <ListItem button className={classes.quadripleNested} selected={selectedIndex === siNavisionProductionReceiving} onClick={goNavisionProductionReceiving}><ListItemText primary="Receiving" /></ListItem>
                                            <ListItem button className={classes.quadripleNested} selected={selectedIndex === siNavisionProductionShipping} onClick={goNavisionProductionShipping}><ListItemText primary="Shipping" /></ListItem>
                                            <ListItem button className={classes.quadripleNested} selected={selectedIndex === siNavisionProductionTechAudit} onClick={goNavisionProductionTechAudit}><ListItemText primary="Tech Audit" /></ListItem>
                                            <ListItem button className={classes.quadripleNested} selected={selectedIndex === siNavisionProductionVerify} onClick={goNavisionProductionVerify}><ListItemText primary="Verify" /></ListItem>
                                        </List>
                                    </Collapse>
                                    <ListItem button className={classes.tripleNested} selected={selectedIndex === siNavisionSales} onClick={goNavisionSales}><ListItemText primary="Sales" /></ListItem>
                                </List>
                            </Collapse>
                            <ListItem button className={classes.nested} selected={selectedIndex === siEcommerceTraining} onClick={goEcommerceTraining}><ListItemIcon><ReceiptIcon /></ListItemIcon><ListItemText primary="Ecommerce" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siReturntekTraining} onClick={goReturntekTraining}><ListItemIcon><img src={ReturntekIcon} width="22px" height="22px" /></ListItemIcon><ListItemText primary="Returntek" /></ListItem>
                            <ListItem button className={classes.nested} selected={selectedIndex === siFactorialTraining} onClick={goFactorialTraining}><ListItemIcon><img src={FactorialIcon} width="22px" height="22px" /></ListItemIcon><ListItemText primary="Factorial" /></ListItem>
                        </List>
                    </Collapse>
                    <ListItem button name="metrics" onClick={handleClickMetrics}><ListItemIcon><AssessmentIcon /></ListItemIcon><ListItemText>Metrics</ListItemText>
                        {openMetricsColapse ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openMetricsColapse} timeout="auto" unmountOnExit>
                        <List dense={true} component="div" disablePadding>
                            <ListItem button className={classes.nested} onClick={handleClickMetricsISandT}>
                                <ListItemText primary="IS & T" />
                                {openMetricsISandTColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            {roles.includes('admin') || roles.includes('adminISandT') ?
                                <Collapse in={openMetricsISandTColapse} timeout="auto" unmountOnExit>
                                    <List dense={true} component="div" disablePadding>
                                        <ListItem button className={classes.doubleNested} selected={selectedIndex === siMetricsKPIs} onClick={goMetricsKPIs}><ListItemText primary="KPI's" /></ListItem>
                                        {/* <ListItem button className={classes.doubleNested} onClick={goMetricsOption2}><ListItemText primary="Option 2" /></ListItem> */}
                                    </List>
                                </Collapse>
                                :
                                <Collapse in={openMetricsISandTColapse} timeout="auto" unmountOnExit>
                                    <List dense={true} component="div" disablePadding>
                                        <ListItem button className={classes.doubleNestedRed} >
                                            <ListItemText className={classes.restrictedRed}>Restricted access</ListItemText>
                                        </ListItem>
                                    </List>
                                </Collapse>
                            }

                            <ListItem button className={classes.nested} onClick={handleClickMetricsOperations}>
                                <ListItemText primary="Operations" />
                                {openMetricsOperationsColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            {roles.includes('admin') || roles.includes('adminISandT') ?
                                <Collapse in={openMetricsOperationsColapse} timeout="auto" unmountOnExit>
                                    <List dense={true} component="div" disablePadding>
                                        <ListItem button className={classes.doubleNested} selected={selectedIndex === siArchieKPIs} onClick={goArchieKPIs}><ListItemText primary="Archie KPI's" /></ListItem>
                                        {/* <ListItem button className={classes.doubleNested} onClick={goMetricsOption2}><ListItemText primary="Option 2" /></ListItem> */}
                                    </List>
                                </Collapse>
                                :
                                <Collapse in={openMetricsOperationsColapse} timeout="auto" unmountOnExit>
                                    <List dense={true} component="div" disablePadding>
                                        <ListItem button className={classes.doubleNestedRed} >
                                            <ListItemText className={classes.restrictedRed}>Restricted access</ListItemText>
                                        </ListItem>
                                    </List>
                                </Collapse>
                            }

                            <ListItem button className={classes.nested} onClick={handleClickMetricsQandC}>
                                <ListItemText primary="Quality & Compliance" />
                                {openMetricsQandCColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openMetricsQandCColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>

                                    <ListItem button className={classes.doubleNested} onClick={handleClickMetricsQandCChain}>
                                        <ListItemText primary="Chain of Custody" />
                                        {openMetricsQandCChainColapse ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={openMetricsQandCChainColapse} timeout="auto" unmountOnExit>
                                        <List dense={true} component="div" disablePadding>
                                            <ListItem button className={classes.tripleNested} selected={selectedIndex === siMetricsChainOfCustodyDealIntegrity} onClick={goMetricsChainOfCustodyDealIntegrity}><ListItemText primary="Deal Integrity" /></ListItem>
                                            <ListItem button className={classes.tripleNested} selected={selectedIndex === siMetricsChainOfCustodyVerificationWip} onClick={goMetricsChainOfCustodyVerificationWip}><ListItemText primary="Verification Wip" /></ListItem>
                                            <ListItem button className={classes.tripleNested} selected={selectedIndex === siMetricsChainOfCustodyDealsInSales} onClick={goMetricsChainOfCustodyDealsInSales}><ListItemText primary="Deals in Sales" /></ListItem>
                                        </List>
                                    </Collapse>
                                    <ListItem button className={classes.doubleNested} onClick={handleClickMetricsQandCQuality}>
                                        <ListItemText primary="Quality" />
                                        {openMetricsQandCQualityColapse ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={openMetricsQandCQualityColapse} timeout="auto" unmountOnExit>
                                        <List dense={true} component="div" disablePadding>
                                            <ListItem button className={classes.tripleNested} selected={selectedIndex === siMetricsQualityCapaVol} onClick={goMetricsQualityCapaVol}><ListItemText primary="Capa Vol & Aging" /></ListItem>
                                            <ListItem button className={classes.tripleNested} selected={selectedIndex === siMetricsQualityCustomerIssues} onClick={goMetricsQualityCustomerIssues}><ListItemText primary="Customer Issues" /></ListItem>
                                        </List>
                                    </Collapse>
                                </List>
                            </Collapse>

                            <ListItem button className={classes.nested} onClick={handleClickMetricsCustomerSLA}>
                                <ListItemText primary="Customer SLA" />
                                {openMetricsCustomerSLAColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openMetricsCustomerSLAColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.doubleNested} selected={selectedIndex === siMetricsCustomerSLAforIEandUK} onClick={goMetricsCustomerSLAforIEandUK}><ListItemText primary="IE & UK SLA" /></ListItem>
                                    <ListItem button className={classes.doubleNested} selected={selectedIndex === siMetricsCustomerSLAforUS} onClick={goMetricsCustomerSLAforUS}><ListItemText primary="US SLA" /></ListItem>
                                </List>
                            </Collapse>

                        </List>
                    </Collapse>
                </List>
                <Divider className={classes.dividerColor} />

                {/* visible for memberISandT */}
                {roles.includes('admin') ? null :
                    roles.includes('memberISandT') ?
                        <List>
                            <ListItem button name="IS&T" onClick={handleClickISandT}><ListItemIcon><LaptopWindowsIcon /></ListItemIcon><ListItemText>IS&T</ListItemText>
                                {openISandTColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openISandTColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siTicketsIST} onClick={goTicketsIST}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siKnowledgeBox} onClick={goKnowledgeBox}><ListItemIcon><AllInboxTwoToneIcon /></ListItemIcon><ListItemText primary="KnowledgeBox" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siIncidentsIST} onClick={goIncidentsIST}><ListItemIcon><AssignmentLateTwoToneIcon /></ListItemIcon><ListItemText primary="Incident Log" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siISTActionTracker} onClick={goISTActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siIST27001} onClick={goIST27001}><ListItemIcon><CardMembershipTwoToneIcon /></ListItemIcon><ListItemText primary="IST 27001" /></ListItem>
                                </List>
                            </Collapse>
                        </List>
                        : null
                }

                {/* visible for memberENG */}
                {roles.includes('admin') ? null :
                    roles.includes('memberENG') ?
                        <List>
                            <ListItem button name="engconsole" onClick={handleClickEng}><ListItemIcon><BuildIcon /></ListItemIcon><ListItemText>Engineering</ListItemText>
                                {openEngColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openEngColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngTickets} onClick={goEngineeringTickets}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngActionTracker} onClick={goEngActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngDowntimeLog} onClick={goEngDowntimeLog}><ListItemIcon><BookTwoToneIcon /></ListItemIcon><ListItemText primary="Downtime Log" /></ListItem>
                                </List>
                            </Collapse>
                        </List>
                        : null
                }

                {/* visible for memberFacilities */}
                {roles.includes('admin') ? null :
                    roles.includes('memberFacilities') ?
                        <List>
                            <ListItem button name="facilitiesconsole" selected={selectedIndex === siFacilities} onClick={goFacilities}><ListItemIcon><ApartmentIcon /></ListItemIcon><ListItemText>Facilities</ListItemText></ListItem>
                        </List>
                        : null
                }

                {/* visible for memberCS */}
                {roles.includes('admin') ? null :
                    roles.includes('memberCS') ?
                        <List>
                            <ListItem button name="csconsole" selected={selectedIndex === siCS} onClick={goCS}><ListItemIcon><RoomServiceIcon /></ListItemIcon><ListItemText>Customer Service</ListItemText></ListItem>
                        </List>
                        : null
                }

                {/* visible for memberHR */}

                {/* visible for memberAM */}

                {/* visible for membersSalesOps */}

                {/* visible for adminISandT */}
                {roles.includes('admin') ? null :
                    // department === "IS&T" && roles.includes('adminISandT') ?
                    roles.includes('adminISandT') ?
                        <List>
                            <ListItem button name="IS&T" onClick={handleClickISandT}><ListItemIcon><LaptopWindowsIcon /></ListItemIcon><ListItemText>IS&T</ListItemText>
                                {openISandTColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openISandTColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siTicketsIST} onClick={goTicketsIST}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siKnowledgeBox} onClick={goKnowledgeBox}><ListItemIcon><AllInboxTwoToneIcon /></ListItemIcon><ListItemText primary="KnowledgeBox" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siIncidentsIST} onClick={goIncidentsIST}><ListItemIcon><AssignmentLateTwoToneIcon /></ListItemIcon><ListItemText primary="Incident Log" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siISTActionTracker} onClick={goISTActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siIST27001} onClick={goIST27001}><ListItemIcon><CardMembershipTwoToneIcon /></ListItemIcon><ListItemText primary="IST 27001" /></ListItem>
                                </List>
                            </Collapse>
                            {/* <ListItem button name="engconsole" onClick={handleClickEng}><ListItemIcon><BuildIcon /></ListItemIcon><ListItemText>Engineering</ListItemText>
                                {openEngColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openEngColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngTickets} onClick={goEngineeringTickets}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngActionTracker} onClick={goEngActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngDowntimeLog} onClick={goEngDowntimeLog}><ListItemIcon><BookTwoToneIcon /></ListItemIcon><ListItemText primary="Downtime Log" /></ListItem>
                                </List>
                            </Collapse> */}
                        </List>
                        : null
                }

                {/* visible for adminENG */}
                {roles.includes('admin') ? null :
                    roles.includes('adminENG') || department === 'Engineering' && roles.includes('teamManager') ?
                        <List>
                            <ListItem button name="engconsole" onClick={handleClickEng}><ListItemIcon><BuildIcon /></ListItemIcon><ListItemText>Engineering</ListItemText>
                                {openEngColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openEngColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngTickets} onClick={goEngineeringTickets}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngActionTracker} onClick={goEngActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngDowntimeLog} onClick={goEngDowntimeLog}><ListItemIcon><BookTwoToneIcon /></ListItemIcon><ListItemText primary="Downtime Log" /></ListItem>
                                </List>
                            </Collapse>
                        </List>
                        : null
                }

                {/* visible for adminFacilities */}
                {roles.includes('admin') ? null :
                    roles.includes('adminFacilities') || department === "Quality and Compliance" && roles.includes('teamManager') ?
                        <List>
                            <ListItem button name="facilitiesconsole" selected={selectedIndex === siFacilities} onClick={goFacilities}><ListItemIcon><ApartmentIcon /></ListItemIcon><ListItemText>Facilities</ListItemText></ListItem>
                        </List>
                        : null
                }

                {/* visible for adminCS */}
                {roles.includes('admin') ? null :
                    roles.includes('adminCS') || department === 'Customer Service' && roles.includes('teamManager') ?
                        <List>
                            <ListItem button name="csconsole" selected={selectedIndex === siCS} onClick={goCS}><ListItemIcon><RoomServiceIcon /></ListItemIcon><ListItemText>Customer Service</ListItemText></ListItem>
                        </List>
                        : null
                }

                {/* visible for adminHR */}
                {roles.includes('admin') ? null :
                    roles.includes('adminHR') || department === 'HR' && roles.includes('teamManager') ?
                        <List>
                            <ListItem button name="hrconsole" selected={selectedIndex === siHR} onClick={goHR}><ListItemIcon><SupervisorAccountIcon /></ListItemIcon><ListItemText>HR</ListItemText></ListItem>
                        </List>
                        : null
                }

                {/* visible for adminAM */}

                {/* visible for adminSalesOps */}
                {roles.includes('admin') ? null :
                    roles.includes('adminSalesOps') ?
                        <List>
                            <ListItem button name="salesOpsConsole" onClick={handleClickSalesOps}><ListItemIcon><ReceiptIcon /></ListItemIcon><ListItemText>Sales Ops</ListItemText>
                                {openSalesOpsColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openSalesOpsColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siSalesOpsFileFormatter} onClick={goSalesOpsFileFormatter}><ListItemIcon><ListAltTwoToneIcon /></ListItemIcon><ListItemText primary="File Formatter" /></ListItem>
                                </List>
                            </Collapse>
                        </List>
                        : null
                }

                {/* visible for admin */}
                {
                    roles.includes('admin') ?
                        <List>
                            <ListItem button name="IS&T" onClick={handleClickISandT}><ListItemIcon><LaptopWindowsIcon /></ListItemIcon><ListItemText>IS&T</ListItemText>
                                {openISandTColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openISandTColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siTicketsIST} onClick={goTicketsIST}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siKnowledgeBox} onClick={goKnowledgeBox}><ListItemIcon><AllInboxTwoToneIcon /></ListItemIcon><ListItemText primary="KnowledgeBox" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siIncidentsIST} onClick={goIncidentsIST}><ListItemIcon><AssignmentLateTwoToneIcon /></ListItemIcon><ListItemText primary="Incident Log" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siISTActionTracker} onClick={goISTActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siIST27001} onClick={goIST27001}><ListItemIcon><CardMembershipTwoToneIcon /></ListItemIcon><ListItemText primary="IST 27001" /></ListItem>
                                </List>
                            </Collapse>

                            <ListItem button name="engconsole" onClick={handleClickEng}><ListItemIcon><BuildIcon /></ListItemIcon><ListItemText>Engineering</ListItemText>
                                {openEngColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openEngColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngTickets} onClick={goEngineeringTickets}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngActionTracker} onClick={goEngActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siEngDowntimeLog} onClick={goEngDowntimeLog}><ListItemIcon><BookTwoToneIcon /></ListItemIcon><ListItemText primary="Downtime Log" /></ListItem>
                                </List>
                            </Collapse>

                            <ListItem button name="salesOpsConsole" onClick={handleClickSalesOps}><ListItemIcon><ReceiptIcon /></ListItemIcon><ListItemText>Sales Ops</ListItemText>
                                {openSalesOpsColapse ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openSalesOpsColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.nested} selected={selectedIndex === siSalesOpsFileFormatter} onClick={goSalesOpsFileFormatter}><ListItemIcon><ListAltTwoToneIcon /></ListItemIcon><ListItemText primary="File Formatter" /></ListItem>
                                </List>
                            </Collapse>

                            <ListItem button name="facilitiesconsole" selected={selectedIndex === siFacilities} onClick={goFacilities}><ListItemIcon><ApartmentIcon /></ListItemIcon><ListItemText>Facilities</ListItemText></ListItem>
                            {/* <ListItem button name="partnermatrixconsole" onClick={goPartnerMatrix}><ListItemIcon><GroupWorkIcon /></ListItemIcon><ListItemText>Partner Matrix</ListItemText></ListItem> */}
                            <ListItem button name="csconsole" selected={selectedIndex === siCS} onClick={goCS}><ListItemIcon><RoomServiceIcon /></ListItemIcon><ListItemText>Customer Service</ListItemText></ListItem>
                            <ListItem button name="hrconsole" selected={selectedIndex === siHR} onClick={goHR}><ListItemIcon><SupervisorAccountIcon /></ListItemIcon><ListItemText>HR</ListItemText></ListItem>
                            <ListItem button name="admin" selected={selectedIndex === siAdmin} onClick={goAdmin}><ListItemIcon><AccountBoxIcon /></ListItemIcon><ListItemText>Admin</ListItemText></ListItem>
                            <ListItem button name="testing" selected={selectedIndex === siTesting} onClick={goTesting}><ListItemIcon><AdjustIcon /></ListItemIcon><ListItemText>Testing</ListItemText></ListItem>
                        </List>
                        : null
                }

                {/* show JP the testing console */}
                {uid === 'mxBUvBxfb8PJS57QV8TaAMz5WJO2' ? <ListItem button name="testing" selected={selectedIndex === siTesting} onClick={goTesting}><ListItemIcon><AdjustIcon /></ListItemIcon><ListItemText>Testing</ListItemText></ListItem> : null}

                {/* {role != 'member' || role != 'teamManager' ? <Divider className={classes.dividerColor} /> : null} */}
                {/* {!roles.includes('member') ? <Divider className={classes.dividerColor} /> : null} */}
                {roles.length > 1 ? <Divider className={classes.dividerColor} /> : null}
                <List>
                    <ListItem button selected={selectedIndex === siFeedback} onClick={goFeedback}><ListItemIcon><ThumbsUpDownIcon /></ListItemIcon><ListItemText>Feedback</ListItemText></ListItem>
                    <ListItem button selected={selectedIndex === siProfile} onClick={goProfile}><ListItemIcon><FaceIcon /></ListItemIcon><ListItemText>Profile</ListItemText></ListItem>
                    <ListItem button onClick={signOut}><ListItemIcon><ExitToAppIcon /></ListItemIcon><ListItemText>Sign out</ListItemText></ListItem>
                </List>
            </Drawer>

        </div>
    );
}