import { Button, Card, CardActionArea, Grid, makeStyles, MenuItem, Modal, TextField, Typography } from '@material-ui/core';
import moment from 'moment/moment';
import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { DataContext } from '../../../../../contexts/DataContext';
import SnackbarComponent from '../../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({

    calendarCard: {
        textAlign: 'center',
        margin: 20,
        width: 200,
        height: 500
    },
    centered: {
        textAlign: 'center',
    },
    modalHeader: {
        textAlign: 'center',
        margin: '70px 50px 40px 50px'
        // margin: 50
    },
    cardHeader: {
        height: 50,
        backgroundColor: 'tomato',
        color: 'white',
    },
    cardMonth: {
        padding: 5
    },
    cardDay: {
        padding: 10,
        color: '#182751'
    },
    cardWeekday: {
        padding: '0px 0px 10px 0px',
        color: 'grey'
    },
    cardFooter: {
        height: 20,
        backgroundColor: 'tomato',
    },
    actionArea: {
        height: 255,
        // backgroundColor: 'lightblue',
        color: '#182751',
    },
    actionAreaFull: {
        height: 255,
        backgroundColor: '#bfd9bf',//'DarkSeaGreen',
        color: '#182751',
    },
    bgGreen: {
        backgroundColor: '#bfd9bf',//'DarkSeaGreen',
    },
    cardTimeTwo: {
        padding: '10px 5px 0px 5px',
        color: '#182751',
    },
    modalPaper: {
        textAlign: 'center',
        backgroundColor: 'white',
        marginTop: '10%',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 600,
        height: 500
    },
    modalGrid: {
        height: '100%'
    },
    modalTextfield: {
        width: 400,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonsDiv: {
        float: 'right'
    },
    modalButton: {
        margin: '80px 30px 30px 30px'
        // margin: 40
    }
}));


const timesAll = [
    { value: 'AM', label: '10 AM - 1:30 PM' },
    { value: 'PM', label: '1:30 PM - 5 PM' }
]
const timesAM = [
    { value: 'AM', label: '10 AM - 1:30 PM' },
    // { value: 'PM', label: '1:30 PM - 5 PM' }
]
const timesPM = [
    // { value: 'AM', label: '10 AM - 1:30 PM' },
    { value: 'PM', label: '1:30 PM - 5 PM' }
]

export default function CalendarCard(props) {
    const classes = useStyles();
    const { getMarinaMarketBookings, updateMarinaMarketBooking } = useContext(DataContext)
    const [open, setOpen] = useState(false)
    const [booking, setBooking] = useState({
        teamManager: '',
        plusOne: '',
        time: '',
        isBookedForAM: props.booking.isBookedForAM,
        isBookedForPM: props.booking.isBookedForPM,
        id: props.booking.id
    })
    const [times, setTimes] = useState([
        { value: 'AM', label: '10 AM - 1:30 PM' },
        { value: 'PM', label: '1:30 PM - 5 PM' }
    ])

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleCloseModal = () => {
        setBooking({
            ...booking,
            teamManager: '',
            plusOne: '',
            time: '',
            isBookedForAM: false,
            isBookedForPM: false
        })
        setOpen(false);
    };
    const handleChange = (e) => {
        setBooking({
            ...booking,
            [e.target.name]: e.target.value
        })
    }
    const handleBook = (e) => {
        e.preventDefault()
        // console.log(booking)
        updateMarinaMarketBooking(booking)
            .then(succ => {
                getMarinaMarketBookings()
                    .then(res => {
                        props.setAllBookings(res)
                        setSnackbar({
                            open: true,
                            severity: 'success',
                            message: 'Succeffully boked your place on Marina Market stall.',
                        })
                        handleCloseModal()
                    })
                    .catch(err => {
                        console.log(err)
                        setSnackbar({
                            open: true,
                            severity: 'error',
                            message: err.message,
                        })
                    })
            })
            .catch(err => {
                console.log(err)
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: err.message,
                })
            })
    }

    useEffect(() => {
        if (props.booking.isBookedForAM) {
            setTimes(timesPM)
        }
        if (props.booking.isBookedForPM) {
            setTimes(timesAM)
        }
        return () => { }
    }, [props.booking.isBookedForAM, props.booking.isBookedForPM])

    const body = (
        <Card className={classes.modalPaper}>
            <Grid container
                direction="column"
                justifyContent="flex-end"
                alignItems="center" className={classes.modalGrid}>
                <h2>Book now</h2>
                <p className={classes.modalHeader}>Please tell us team managers name and sellect time you wish to attend.</p>

                <form onSubmit={handleBook}>
                    <TextField
                        className={classes.modalTextfield}
                        fullWidth
                        // size="small"
                        name="teamManager"
                        id="teamManager"
                        label="Team Manager Full Name"
                        variant="outlined"
                        autoComplete="off"
                        value={booking.teamManager}
                        required
                        inputProps={{ minLength: 6, maxLength: 50 }}
                        onChange={handleChange} />
                    {/* <TextField
                    className={classes.modalTextfield}
                    fullWidth
                    // size="small"
                    name="plusOne"
                    id="plusOne"
                    label="Plus One"
                    variant="outlined"
                    autoComplete="off"
                    value={booking.plusOne}
                    required
                    inputProps={{ minLength: 6, maxLength: 50 }}
                    onChange={handleChange} /> */}

                    <TextField
                        select
                        className={classes.modalTextfield}
                        fullWidth
                        // size="small"
                        name="time"
                        id="time"
                        label="Time"
                        variant="outlined"
                        autoComplete="off"
                        value={booking.time}
                        required
                        inputProps={{ minLength: 6, maxLength: 50 }}
                        onChange={handleChange} >
                        {times.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Grid container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end" >
                        <Button color='default' onClick={handleCloseModal} variant='outlined' className={classes.modalButton}>Cancel</Button>
                        <Button color='primary' type='submit' variant='outlined' className={classes.modalButton}>Submit</Button>
                    </Grid>
                </form>
            </Grid>
        </Card>
    )


    return (
        <Card className={classes.calendarCard}>
            <div className={classes.cardHeader}>
                <Typography variant="h4" className={classes.cardMonth}>{moment(props.booking.date).format('MMMM')}</Typography>
            </div>
            <Typography variant="h1" className={classes.cardDay}>{moment(props.booking.date).date()}</Typography>
            <Typography variant="h5" className={classes.cardWeekday}>{moment(props.booking.date).format('dddd')}</Typography>
            <div className={classes.cardFooter}></div>

            <CardActionArea
                disabled={props.booking.isBookedForAM === true && props.booking.isBookedForPM === true ? true : false}
                onClick={() => setOpen(true)}
                className={props.booking.isBookedForAM === true && props.booking.isBookedForPM === true ? classes.actionAreaFull : classes.actionArea}>

                {props.booking.isBookedForAM === false && props.booking.isBookedForPM === false ? <Typography variant="h6">Please<br />Click here<br />to book</Typography> : null}

                {props.booking.isBookedForAM ?
                    <>
                        <div style={{ height: 126 }} className={props.booking.isBookedForAM ? classes.bgGreen : 'nothing'}>
                            <Typography variant="h6" className={classes.cardTimeTwo}>10am - 1:30pm</Typography>
                            <Typography style={{ fontSize: '1.2em' }}>{props.booking.teamManagerAM}</Typography>
                        </div>
                        <div style={{ marginLeft: 10, marginRight: 10, height: 3, backgroundColor: 'tomato' }}></div>
                        {props.booking.isBookedForPM ?
                            <div style={{ height: 126 }}>
                                <Typography variant="h6" className={classes.cardTimeTwo}>1:30pm - 5pm</Typography>
                                <Typography style={{ fontSize: '1.2em' }}>{props.booking.teamManagerPM}</Typography>
                            </div>
                            :
                            <div style={{ height: 126 }}>
                                <Typography variant="h6" className={classes.cardTimeTwo}>Please<br />Click here<br />to book</Typography>
                            </div>}
                    </>

                    : null}

                {/* {props.booking.isBookedForAM === true && props.booking.isBookedForPM === true ? <div style={{ marginLeft: 10, marginRight: 10, height: 2, backgroundColor: 'tomato' }}></div> : null} */}

                {props.booking.isBookedForPM ?
                    <>
                        {props.booking.isBookedForAM ?
                            <div style={{ height: 126 }}>
                                <Typography variant="h6" className={classes.cardTimeTwo}>10am - 1:30pm</Typography>
                                <Typography style={{ fontSize: '1.2em' }}>{props.booking.teamManagerAM}</Typography>
                            </div>
                            :
                            <div style={{ height: 126 }}>
                                <Typography variant="h6" className={classes.cardTimeTwo}>Please<br />Click here<br />to book</Typography>
                            </div>}
                        <div style={{ marginLeft: 10, marginRight: 10, height: 3, backgroundColor: 'tomato' }}></div>
                        <div style={{ height: 126 }} className={props.booking.isBookedForPM ? classes.bgGreen : 'nothing'}>
                            <Typography variant="h6" className={classes.cardTimeTwo}>1:30pm - 5pm</Typography>
                            <Typography style={{ fontSize: '1.2em' }}>{props.booking.teamManagerPM}</Typography>
                        </div>
                    </>
                    : null}

            </CardActionArea>

            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </Card >
    )
}
