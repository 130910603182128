import React, { useState, useContext } from 'react'
import { makeStyles, Grid, List, Tooltip, ListItem, ListItemText, IconButton } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { DataContext } from '../../../../contexts/DataContext';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    marginLeftRight: {
        marginLeft: '45px',
        marginRight: '10px'
    },
    marginRight: {
        marginRight: '15px'
    },
    input: {
        display: 'none',
    },
    attachments: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)'
        },
        height: '35px',
        background: '#5E89B4',
        color: 'white',
        borderRadius: '5px',
    },
    attList: {
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    noMargin: {
        margin: 0
    }
}))

export default function IncidentsDetails(props) {
    const classes = useStyles()
    console.log(props.rowData)
    const { addAttachments } = useContext(DataContext)

    const [attachments, setAttachments] = useState({
        files: [],
        filesSize: 0,
        filesToStoreInDB: [],
        id: props.rowData.id,
        filesURLs: props.rowData.filesURLs === undefined ? [] : props.rowData.filesURLs,
        bucketName: 'itIncidentLogsAttachments',
        targetDB: 'incidentLogsIT',
        successMsg: 'Successfully added file to that record in incidentLogsIT table.',
        errorMsg: 'Failed to add file to that record in incidentLogsIT table.'
    })

    //#region Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const attachFile = (e) => {
        // console.log(e.target)
        // console.log(props.rowData.id)
        var { target: { files } } = e
        // var attachments = e.target.files;
        // var filesToSend = [e.target.files[0]];
        // const filesToSend = [...files];
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...attachments.files];
        var filesToStore = [...attachments.filesToStoreInDB];
        [...files].forEach(file => {

            // storage
            //     .ref('ticketAttachments/' + '01Hk2AQqOOoHjy2LBXql/' + file.name)
            //     .put(file)
            // console.log(file)
            filesToStore.push(file)
            var convertResult = ''
            calculatedSize += file.size

            toBase64(file)
                .then(result => {
                    convertResult = result
                    filesToSend.push({
                        content: convertResult,
                        filename: file.name,
                        type: file.type,
                        disposition: "attachment"
                    })
                    // console.log(calculatedSize)
                    if (attachments.filesSize >= maxSize || calculatedSize >= maxSize) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'Selected files are too big to attach.',
                        })
                    }
                    else {
                        if (attachments.filesSize + calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                        }
                        else {
                            setAttachments({
                                ...attachments,
                                files: filesToSend,
                                filesSize: attachments.filesSize + calculatedSize,
                                filesToStoreInDB: filesToStore,
                            });
                            // console.log(filesToSend)
                        }
                    }
                })
        })
        e.target.value = null // to allow selecting the same file over and over
    }
    const clearFiles = (e) => {
        setAttachments({
            ...attachments,
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
            id: props.rowData.id,
            filesURLs: props.rowData.filesURLs === undefined ? [] : props.rowData.filesURLs,
            bucketName: 'dealsInSalesAttachments',
            targetDB: 'qualityDealsInSales',
            successMsg: 'Successfully added file to that record in Deals In Sales table.',
            errorMsg: 'Failed to add file to that record in Deals In Sales table.'
        });
    }

    function delay(byMilliseconds) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(byMilliseconds);
            }, byMilliseconds);
        });
    }
    const saveAttachementInDB = async () => {
        addAttachments(attachments)
            .then((success) => {
                clearFiles();
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                })
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: err,
                })
            })
        var x = await delay(4000)
        console.log('awaited for', x)
        props.refreshData()

    }
    const cancelSaveAttachementInDB = () => {
        clearFiles();
        setSnackbar({
            open: true,
            severity: 'warning',
            message: 'File removed, it wont be saved.',
        })
    }
    const handleDownload = async (e, file) => {
        e.preventDefault();
        const response = await fetch(file.url);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" >

            <p style={{ marginLeft: '25px' }}>You can attach file to this record:</p>
            <div className={classes.filePicker}>
                <input
                    // accept="image/*"
                    accept=".xlsx, .xls, .csv, .doc, .docx, .txt, .pdf"
                    className={classes.input}
                    id={props.rowData.id + 'AB'}
                    multiple
                    type="file"
                    onChange={attachFile}
                />
                <label htmlFor={props.rowData.id + 'AB'}>
                    <Tooltip className={classes.noMargin} title="Attach File">
                        <IconButton className={classes.marginRight}
                            variant="contained"
                            color="default"
                            component="span"
                        >
                            <AttachFileIcon />
                        </IconButton>
                    </Tooltip>
                </label>
            </div>
            {attachments.files.length === 0 ? null :
                <Grid container item direction="row" justifyContent="flex-start" alignItems="center" >
                    <p className={classes.marginLeftRight}>Files selected: {attachments.files.length} </p>
                    <Tooltip className={classes.noMargin} title="Save Attachment">
                        <IconButton
                            variant="contained"
                            color="default"
                            component="span"
                            onClick={saveAttachementInDB}
                        >
                            <DoneIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip className={classes.noMargin} title="Cancel Saving">
                        <IconButton
                            variant="contained"
                            color="default"
                            component="span"
                            onClick={cancelSaveAttachementInDB}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            }

            {props.rowData.filesURLs === undefined || props.rowData.filesURLs.length === 0 ? null :
                <Grid container item className={classes.marginLeftRight} direction="row" justifyContent="flex-start" alignItems="center">
                    <p style={{ marginLeft: '45px' }}>Files Attached:</p>
                    {props.rowData.filesURLs.map((file, i) => (
                        <List key={i} className={classes.attList}>
                            <Tooltip title="Download">
                                <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                    <SaveAltIcon className={classes.marginRight} />
                                    <ListItemText primary={file.name} />
                                </ListItem>
                            </Tooltip>
                        </List>
                    ))}
                </Grid>
            }

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </Grid>
    )
}