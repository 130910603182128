import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Container, TextField, CssBaseline, Grid, Card, Button, InputBase } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import Loading from '../../Loading';
import { db } from '../../../../config/fbConfig';
import { DataContext } from '../../../../contexts/DataContext';
import Footer from '../../../Footer';
import moment from 'moment';
import MaterialTable from 'material-table';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2),
    },
    marginAutoRight: {
        // display: 'none',
        marginRight: 'auto'
    },
    marginAutoLeft: {
        marginLeft: 'auto'
    },
    textFieldBox: {
        width: '100%',
        height: '65px',
        marginTop: '0px',
        marginBottom: '15px',
        // textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
    },
    inputBaseScan: {
        padding: '10px',
        fontSize: '1.5em',
    },
    outputBinText: {
        marginTop: '35px',
        marginBottom: '0px',
    },
    outputResultGreen: {
        margin: '10px',
        fontSize: '3em',
        color: 'green',
    },
    outputResultOrange: {
        margin: '10px',
        fontSize: '3em',
        color: 'orange',
    },
    clearInputButtonMargin: {
        marginRight: '15px'
    },

    marginBottom: {
        marginBottom: '45px'
    },


}));

export default function PurgeChecker() {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    const [scanInfo, setScanInfo] = useState({
        ScanPartNo: '',
        PartNumber: '',
        Manufacturer: '',
        Prefix: '',
        DateCode: '',
        DellPartNo: '',
        PurgeAction: '',
        PurgeNo: 0,
        PurgeCreatedAt: '',
        Result: '',
    });

    const [isShowing, setIsShowing] = useState(false)

    const [manufacturers, setManufacturers] = useState([])
    const [dellMappings, setDellMappings] = useState([])
    const [purgeActions, setPurgeActions] = useState([])
    const [purgeNumbers, setPurgeNumbers] = useState([])

    //Snackbar
    //#region 
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion


    function getMemoryScanData() {
        // console.log(data)
        var manufacturersData = []

        db.collection('memoryScanCheckSheet').orderBy('Prefix').get()
            .then(snap => {
                snap.forEach(doc => {
                    manufacturersData.push(doc.data())
                })
            })
        setManufacturers(manufacturersData)
    }

    function getDellMappings() {
        var dellMappings = []

        db.collection('productionPurgeCheckerDellMapping').orderBy('DellItemNo').get()
            .then(snap => {
                snap.forEach(doc => {
                    dellMappings.push(doc.data())
                })
            })

        console.log('setting up dell mappings to this:', dellMappings)
        setDellMappings(dellMappings)
    }

    function getPurgeActions() {
        var purgeActions = []

        db.collection('productionPurgeChecker').orderBy('PurgeNo').get()
            .then(snap => {
                snap.forEach(doc => {
                    purgeActions.push(doc.data())
                })
            })

        console.log('setting up purge Actions to this:', purgeActions)
        setPurgeActions(purgeActions)
    }
    function getPurgeNumbers() {
        var purgeNo = []

        db.collection('productionPurgeCheckerPurgeNumbers').get()
            .then(snap => {
                snap.forEach(doc => {
                    purgeNo.push({ PurgeNo: doc.id, ...doc.data() })
                })
            })

        console.log('setting up purge Numbers to this:', purgeNo)
        setPurgeNumbers(purgeNo)
    }

    useEffect(() => {
        getMemoryScanData()
        getDellMappings()
        getPurgeActions()
        getPurgeNumbers()
        return () => { }
    }, [])

    const handleChange = (e) => {
        setScanInfo({
            ...scanInfo,
            [e.target.name]: e.target.value,
        })
    }

    const handleClearForm = (e) => {
        setScanInfo({
            ...scanInfo,
            ScanPartNo: '',
            PartNumber: '',
            Manufacturer: '',
            Prefix: '',
            DateCode: '',
            DellPartNo: '',
            PurgeAction: '',
            PurgeNo: 0,
            PurgeCreatedAt: '',
            Result: '',
        })
        document.getElementById('ScanPartNo').focus();
        setIsShowing(false)
    }

    function isNumberInRange(number, min, max) {
        return number >= min && number <= max;
    }

    const calculateResult = (processedPurgeAction, processedPurgeNo, processedDateCode) => {
        var result = ''
        // console.log('purgeNumbers are:', purgeNumbers)
        // console.log('processedPurgeAction:', processedPurgeAction)
        // console.log('processedPurgeNo:', processedPurgeNo)
        // console.log('processedDateCode:', processedDateCode)
        switch (processedPurgeAction.toUpperCase()) {
            case 'MPN':
                result = 'PURGE'
                break;
            case 'DATE CODE RANGE':
                purgeNumbers.forEach(num => {
                    if (num.PurgeNo === processedPurgeNo) {
                        if (isNumberInRange(processedDateCode, num.From, num.To)) {
                            result = 'PURGE'
                            return
                        } else {
                            result = 'OK'
                            return
                        }
                    }
                })
                break;
            case 'DATE CODE & OLDER':
                purgeNumbers.forEach(num => {
                    if (num.PurgeNo === processedPurgeNo) {
                        if (processedDateCode <= num.From) {
                            result = 'PURGE'
                        } else {
                            result = 'OK'
                        }
                    }
                })
                break;

            default:
                break;
        }
        return result
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log('submitting scan')
        var trimmedScan = scanInfo.ScanPartNo.trim()

        if (trimmedScan.length < 16) {
            setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Scanned part number is to short. Expected e.g. VN M393A4K40CB2-CTD 2109',
            })
            return
        }
        else {


            var firstTwo = trimmedScan.substring(0, 2).toUpperCase()
            var manufacturerData = { Country: '', Manufacturer: '', Prefix: '' }
            var processedPartNumber = ''
            var processedDateCode = trimmedScan.substring(trimmedScan.length - 4)
            var processedDellPartNumber = ''
            var processedPurgeAction = ''
            var processedPurgeNo = 0
            var processedPurgeCreatedAt = ''
            var processedResult = ''

            manufacturers.forEach(manufacturer => {
                if (manufacturer.Prefix === firstTwo) {
                    manufacturerData = manufacturer
                    return
                }
            })

            switch (manufacturerData.Manufacturer.toUpperCase()) {
                case 'HYNIX':
                    processedPartNumber = scanInfo.ScanPartNo.trim().substring(0, 16);
                    break;
                case 'MICRON':
                    processedPartNumber = scanInfo.ScanPartNo.trim().substring(0, 20);
                    break;
                case 'SAMSUNG':
                    processedPartNumber = scanInfo.ScanPartNo.trim().substring(3, 19);
                    break;
                case 'SAMSUNG DIRECT':
                    processedPartNumber = scanInfo.ScanPartNo.trim()
                    break;

                default:
                    processedPartNumber = scanInfo.ScanPartNo.trim()
                    break;
            }

            dellMappings.forEach(item => {
                item.memory.forEach(memory => {
                    if (memory.MfgItemNo === processedPartNumber) {
                        // console.log('found a match!')
                        processedDellPartNumber = item.DellItemNo
                        return
                    }
                })
            })

            purgeActions.forEach(item => {
                item.Impacted.forEach(impacted => {
                    if (impacted.MPN === processedPartNumber) {
                        // console.log("Found an impacted part")
                        processedPurgeAction = impacted.Action
                        processedPurgeCreatedAt = moment(new Date(impacted.CreatedAt.toDate())).format('DD/MMM/yyyy')
                        processedPurgeNo = item.PurgeNo
                        return
                    }
                })
            })

            // TODO: have all the values to calculate the result lets do that next.
            processedResult = calculateResult(processedPurgeAction, processedPurgeNo, processedDateCode) || 'NOT FOUND'


            setScanInfo({
                ...scanInfo,
                ScanPartNo: '',
                PartNumber: processedPartNumber,
                Manufacturer: manufacturerData.Manufacturer,
                Prefix: manufacturerData.Prefix,
                DateCode: processedDateCode,
                DellPartNo: processedDellPartNumber,
                PurgeAction: processedPurgeAction,
                PurgeNo: processedPurgeNo,
                PurgeCreatedAt: processedPurgeCreatedAt,
                Result: processedResult,

            })
            setIsShowing(true)
        }
    }

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={59} openProductionColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Production - Purge Checker</h1> */}

                            <Card>
                                <Container component="main" className={classes.main} maxWidth="xl">

                                    <form id="purgeChecker" onSubmit={handleSubmit}>

                                        <h3 className={classes.center}>Purge Checker</h3>
                                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>

                                            <Grid container item xs={12} sm={6} spacing={1} className={classes.marginBottom}>
                                                <div className={classes.textFieldBox}>
                                                    <InputBase
                                                        className={classes.inputBaseScan}
                                                        autoFocus={true}
                                                        // select
                                                        // required
                                                        autoComplete="off"
                                                        variant="standard"
                                                        fullWidth
                                                        id="ScanPartNo"
                                                        // label="Scan part number here"
                                                        name="ScanPartNo"
                                                        value={scanInfo.ScanPartNo}
                                                        onChange={handleChange}
                                                        inputProps={{ maxLength: 30 }}
                                                    >
                                                    </InputBase>
                                                </div>
                                                <Button
                                                    className={classes.clearInputButtonMargin}
                                                    type="button"
                                                    size="small"
                                                    // className={classes.buttonHidden}
                                                    // variant="contained"
                                                    // color="primary"
                                                    variant="outlined"
                                                    onClick={handleClearForm}>
                                                    Clear Input
                                                </Button>

                                                <Button
                                                    type="submit"
                                                    size="small"
                                                    className={classes.marginAutoLeft}
                                                    variant="outlined"
                                                    color="primary">
                                                    Submit
                                                </Button>

                                                {/* <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                                                    <h2 className={classes.outputBinText}>{scanInfo.binHelperText}</h2>
                                                </Grid> */}
                                                {isShowing && <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                                                    <h2 className={classes.outputBinText}>Result:</h2>
                                                    <p className={scanInfo.Result === 'NOT FOUND' ? classes.outputResultOrange : classes.outputResultGreen}>
                                                        <b>{scanInfo.Result}</b>
                                                    </p>
                                                </Grid>}

                                            </Grid>

                                            <Grid container item xs={12} sm={6} spacing={1}>

                                                {isShowing ?
                                                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>

                                                        <Grid container item >
                                                            <TextField
                                                                size="small"
                                                                variant="outlined"
                                                                disabled
                                                                // required
                                                                fullWidth
                                                                id="PartNumber"
                                                                label="Part Number"
                                                                name="PartNumber"
                                                                value={scanInfo.PartNumber}
                                                                onChange={handleChange}   >
                                                            </TextField>
                                                        </Grid>
                                                        <Grid container item >
                                                            <TextField
                                                                size="small"
                                                                variant="outlined"
                                                                disabled
                                                                // required
                                                                fullWidth
                                                                id="Manufacturer"
                                                                label="Manufacturer"
                                                                name="Manufacturer"
                                                                value={scanInfo.Manufacturer}
                                                                onChange={handleChange}   >
                                                            </TextField>
                                                        </Grid>
                                                        <Grid container item >
                                                            <TextField
                                                                size="small"
                                                                variant="outlined"
                                                                disabled
                                                                // required
                                                                fullWidth
                                                                id="DateCode"
                                                                label="Date Code"
                                                                name="DateCode"
                                                                value={scanInfo.DateCode}
                                                                onChange={handleChange}   >
                                                            </TextField>
                                                        </Grid>
                                                        <Grid container item >
                                                            <TextField
                                                                size="small"
                                                                variant="outlined"
                                                                disabled
                                                                // required
                                                                fullWidth
                                                                id="DellPartNo"
                                                                label="DELL Part Number"
                                                                name="DellPartNo"
                                                                value={scanInfo.DellPartNo}
                                                                onChange={handleChange}   >
                                                            </TextField>
                                                        </Grid>
                                                        <Grid container item >
                                                            <TextField
                                                                size="small"
                                                                variant="outlined"
                                                                disabled
                                                                // required
                                                                fullWidth
                                                                id="PurgeAction"
                                                                label="Purge Action"
                                                                name="PurgeAction"
                                                                value={scanInfo.PurgeAction}
                                                                onChange={handleChange} >
                                                            </TextField>
                                                        </Grid>
                                                        <Grid container item >
                                                            <TextField
                                                                className={classes.marginBottom}
                                                                size="small"
                                                                variant="outlined"
                                                                disabled
                                                                // required
                                                                fullWidth
                                                                id="PurgeCreatedAt"
                                                                label="Purge Created At"
                                                                name="PurgeCreatedAt"
                                                                value={scanInfo.PurgeCreatedAt}
                                                                onChange={handleChange} >
                                                            </TextField>
                                                        </Grid>
                                                        {/* <Grid container item >
                                                            <TextField
                                                                className={classes.marginBottom}
                                                                size="small"
                                                                variant="outlined"
                                                                disabled
                                                                // required
                                                                fullWidth
                                                                id="Result"
                                                                label="Result"
                                                                name="Result"
                                                                // inputProps={{ maxLength: 2 }}
                                                                value={scanInfo.Result}
                                                                onChange={handleChange}   >
                                                            </TextField>
                                                        </Grid> */}

                                                    </Grid>
                                                    : null}
                                            </Grid>
                                        </Grid>

                                    </form>

                                </Container>
                            </Card>


                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div >
            )
        }
    }
}