import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, IconButton, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { AuthContext } from '../../../contexts/AuthContext';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DataContext } from '../../../contexts/DataContext';
import { db } from '../../../config/fbConfig';
import Timeline from 'react-calendar-timeline'
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import { useRef } from 'react';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        marginTop: '30px',
        marginBottom: '30px'
    },
    marginTopBottom: {
        margin: '30px',
    },
    formCard: {
        padding: '30px',
    },
    buttonRight: {
        float: 'right',
        margin: '35px',
    },
    lowerTextfield: {
        marginTop: '25px',
    },
    toggleColor: {
        color: 'rgba(0, 0, 0, 0.60) !important',
        backgroundColor: 'rgba(0, 0, 0, 0.15) !important',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3) !important'
        },
    },
    toggleSelected: {
        color: 'rgba(0, 0, 0, 0.60) !important',
        backgroundColor: 'rgba(170, 210, 230, 0.8) !important',
        '&:hover': {
            backgroundColor: 'rgba(160, 180, 230, 1) !important'
        },
    },
    cardMarginBottom: {
        paddingBottom: '20px',
        marginBottom: '20px'
    },
    centerHeader: {
        textAlign: 'center',
        marginLeft: '48px',
        // marginBottom: '30px'
    },
    buttonRightNoMargin: {
        float: 'right',
        // margin: '35px',
    },

}));
const locations = [
    { value: '', label: '' },
    { value: 'Dublin Hill', label: 'Dublin Hill' },
    { value: 'K4 / K5', label: 'K4 / K5' },
]
const roomNamesDH = [
    { value: '', label: '' },
    { value: 'VALENTIA Boardroom', label: 'VALENTIA Boardroom' },
    { value: 'GARNISH Room', label: 'GARNISH Room' },
    { value: 'SHERKIN Room', label: 'SHERKIN Room' },
]
const roomNameK4K5 = [
    { value: '', label: '' },
    { value: 'CAPE CLEAR Room', label: 'CAPE CLEAR Room' },
]


export default function AddViewManageRoomBookings() {
    const classes = useStyles();
    const { addRoomBooking, deleteRoomBooking } = useContext(DataContext)
    const { displayName, email } = useContext(AuthContext)

    const [viewAllBookings, setViewAllBookings] = useState({
        groups: [
            {
                id: 1,
                title: 'group 1'
            },
        ],
        items: [],
        defaultTimeStart: moment().startOf('day'),
        defaultTimeEnd: moment().endOf('day'),
    })

    const [booking, setBooking] = useState({
        Host: displayName,
        Subject: '',
        Location: '',
        RoomName: '',
        DateAndTime: null,
        DateAndTimeString: '',
        Duration: 15,
        RoomRequirements: [],
        Participants: [],
        dateCreated: '',
        dateUpdated: '',
        roomHelperText: '',
        dateAndTimeHelperText: '',
        hostEmail: email,
    });

    const [allBookings, setAllBookings] = useState(null)

    const [myBookings, setMyBookings] = useState({
        columns: [
            { title: 'Booking Date', field: 'DateAndTime', editable: 'never' },
            { title: 'Host', field: 'Host', editable: 'never' },
            { title: 'Location', field: 'Location', editable: 'never' },
            { title: 'Room Name', field: 'RoomName', editable: 'never' },
            { title: 'Subject', field: 'Subject', editable: 'never' },
            { title: 'Duration', field: 'Duration', editable: 'never' },
            // Host: displayName,
            // Subject: '',
            // Location: '',
            // RoomName: '',
            // DateAndTime: null,
            // DateAndTimeString: '',
            // Duration: 15,
            // RoomRequirements: [],
            // Participants: [],
            // dateCreated: '',
            // dateUpdated: '',
            // roomHelperText: '',
        ],
        data: []
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    function calculateCalendarVars(data) {
        var roomsGroup = [];
        var roomItems = [];
        var color = '';
        var borderColor = '';
        data.forEach(bookingView => {
            const roomExists = roomsGroup.some(room => room.id === bookingView.RoomName)
            if (!roomExists) {
                roomsGroup.push({
                    id: bookingView.RoomName,
                    title: bookingView.RoomName,
                })
            }
            switch (bookingView.RoomName) {
                case 'VALENTIA Boardroom':
                    color = 'rgb(170, 150, 230)' // violet
                    borderColor = 'rgb(120, 110, 170)' // darker violet
                    break;
                case 'GARNISH Room':
                    color = 'rgb(95, 190, 125)'  // green
                    borderColor = 'rgb(80, 160, 100)' // darker green
                    break;
                case 'SHERKIN Room':
                    color = 'rgb(230, 140, 180)' // pink
                    borderColor = 'rgb(180, 110, 140)' // darker pink
                    break;
                // case 'ISOLATION Room':
                //     color = 'rgb(250, 180, 100)' // orange
                //     borderColor = 'rgb(250, 160, 60)' // darker orange
                //     break;
                // case 'HOT Desk':
                //     color = 'rgb(200, 60, 40)' // red
                //     borderColor = 'rgb(250,70,40)' // darker red
                //     break;
                case 'CAPE CLEAR Room':
                    color = 'rgb(20, 150, 120)' // pastel green
                    borderColor = 'rgb(40, 170, 140)' // darker pastel green
                    break;
                // case 'Unit 19 Middle Room':
                //     color = 'rgb(120, 200, 10)' // bright green
                //     borderColor = 'rgb(100, 170, 5)' // darker bright green
                //     break;

                default:
                    color = 'rgb(35, 150, 245)' // blue
                    borderColor = 'rgb(25, 110, 180)' // darker blue
                    break;
            }
            roomItems.push({
                id: bookingView.id,
                group: bookingView.RoomName,
                title: bookingView.Host + ' - ' + bookingView.Subject,
                start_time: moment(bookingView.DateAndTime),
                end_time: moment(bookingView.DateAndTime).add(bookingView.Duration, 'minutes'),
                canMove: false,
                canResize: false,
                canSelect: false,
                canChangeGroup: false,
                itemProps: {
                    style: {
                        // color: color,
                        background: color,
                        borderColor: borderColor,
                        paddingLeft: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }
                }
            })
        })
        setViewAllBookings({
            ...viewAllBookings,
            groups: roomsGroup.sort((a, b) => (a.id > b.id) ? 1 : -1),// sort rooms alphabetically
            items: roomItems
        })
    }

    const getAllBookingsView = () => {
        var today = new Date(moment(new Date()).startOf('day'))
        db.collection('roomBookings')
            .where('DateAndTime', '>=', today)
            .orderBy('DateAndTime', 'desc')
            .get()
            .then(snapshot => {
                var viewAllBookings = []
                snapshot.forEach(doc => {
                    var isUpdatedDate = doc.data().dateUpdated === "" ? "" : moment(doc.data().dateUpdated.toDate()).format('DD MMMM YYYY hh:mm a')
                    // var duration = doc.data().Duration > 60 ? (Math.round((doc.data().Duration / 60) * 10) / 10) + ' hours' : doc.data().Duration + ' minutes' // to have one decimal
                    viewAllBookings.push({
                        ...doc.data(),
                        id: doc.id,
                        DateAndTime: moment(doc.data().DateAndTime.toDate()).format('DD MMM YYYY hh:mm a'),
                        dateCreated: moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY hh:mm a'),
                        dateUpdated: isUpdatedDate,
                        // Duration: duration
                    })
                })
                calculateCalendarVars(viewAllBookings)
            })
            .catch(function (error) {
                console.log("Error getting Room Bookings: ", error);
            })
    }
    useEffect(() => {
        console.log('loop!')
        getAllBookingsView()
        return () => {
        }
    }, [])

    const handleChange = (e) => {
        setBooking({
            ...booking,
            [e.target.name]: e.target.value,
        })
    }
    const handleChangeLocation = (e) => {
        setBooking({
            ...booking,
            [e.target.name]: e.target.value,
            RoomName: '',
            roomHelperText: '',
            dateAndTimeHelperText: '',
        })
    }
    const handleChangeRoomName = (e) => {

        switch (e.target.value) {
            case 'VALENTIA Boardroom':
                setBooking({
                    ...booking,
                    [e.target.name]: e.target.value,
                    roomHelperText: 'Up to 5 people, biggest meeting room'
                })
                break;
            case 'GARNISH Room':
                setBooking({
                    ...booking,
                    [e.target.name]: e.target.value,
                    roomHelperText: 'Up to 4 people, second biggest meeting room beside the Sherkin'
                })
                break;
            case 'SHERKIN Room':
                setBooking({
                    ...booking,
                    [e.target.name]: e.target.value,
                    roomHelperText: 'Up to 3 people, smallest meeting room at the far end of the corridor'
                })
                break;
            case 'CAPE CLEAR Room':
                setBooking({
                    ...booking,
                    [e.target.name]: e.target.value,
                    roomHelperText: 'CAPE CLEAR Room, can fit up to 8 people'
                })
                break;

            default:
                break;
        }
    }
    const handleDateChangeFromDate = (selectedDate) => {
        setBooking({
            ...booking,
            DateAndTime: selectedDate,
            DateAndTimeString: moment(selectedDate).format('DD MMMM YYYY hh:mm a'),
            dateAndTimeHelperText: 'Please make sure you have AM / PM correctly selected when picking the time!',
        })
    }
    const handleChangeToggleButton = (e, newValue) => {
        // if (newValue !== null) {
        setBooking({
            ...booking,
            Duration: newValue
        })
        // }
    }

    function clearForm() {
        setBooking({
            Host: displayName,
            Subject: '',
            Location: '',
            RoomName: '',
            DateAndTime: null,
            DateAndTimeString: '',
            Duration: 15,
            RoomRequirements: [],
            Participants: [],
            dateCreated: '',
            dateUpdated: '',
            roomHelperText: '',
            dateAndTimeHelperText: '',
            hostEmail: email,
        })
    }

    function isAvailableToBook() {
        return new Promise((resolve, reject) => {
            var isAvailable = true;
            var meetingStart = moment(booking.DateAndTime).add(1, 'seconds')
            var meetingEnd = moment(booking.DateAndTime).add(booking.Duration, 'minutes').add(-1, 'seconds')
            // console.log('meeting start', meetingStart, 'meeting end', meetingEnd)

            var today = new Date(moment().startOf('day'))

            db.collection('roomBookings')
                .where('DateAndTime', '>=', today)
                .orderBy('DateAndTime', 'desc')
                .get()
                .then(snapshot => {
                    var allBookingsX = []
                    snapshot.forEach(doc => {
                        var isUpdatedDate = doc.data().dateUpdated === "" ? "" : moment(doc.data().dateUpdated.toDate()).format('DD MMMM YYYY hh:mm a')
                        // var duration = doc.data().Duration > 60 ? (Math.round((doc.data().Duration / 60) * 10) / 10) + ' hours' : doc.data().Duration + ' minutes' // to have one decimal
                        allBookingsX.push({
                            ...doc.data(),
                            id: doc.id,
                            DateAndTime: moment(doc.data().DateAndTime.toDate()).format('DD MMM YYYY hh:mm a'),
                            dateCreated: moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY hh:mm a'),
                            dateUpdated: isUpdatedDate,
                            // Duration: duration
                        })
                    })
                    console.log(allBookingsX)
                    console.log(booking)
                    allBookingsX.forEach(existingBooking => {
                        if (moment(meetingStart).isBetween(moment(existingBooking.DateAndTime), moment(existingBooking.DateAndTime).add(existingBooking.Duration, 'minutes'))
                            || moment(meetingEnd).isBetween(moment(existingBooking.DateAndTime), moment(existingBooking.DateAndTime).add(existingBooking.Duration, 'minutes'))
                            || moment(existingBooking.DateAndTime).isBetween(meetingStart, meetingEnd)
                            || moment(existingBooking.DateAndTime).add(existingBooking.Duration, 'minutes').isBetween(meetingStart, meetingEnd)) {

                            if (existingBooking.Location === booking.Location && existingBooking.RoomName === booking.RoomName) {
                                // console.log('meeting room unavailable.')
                                console.log('existingBooking', existingBooking, 'booking', booking)
                                isAvailable = false
                            }
                        }
                    });
                    if (isAvailable) {
                        resolve()
                    }
                    else {
                        reject('Date or Time is unavailable, please select different room or change meeting time.')
                    }
                })
                .catch(function (error) {
                    console.log("Error getting Room Bookings: ", error);
                    reject('Room booking functionality currently unavailable. Please try again later or contact Connect admin.')
                })

        })
    }
    const getAllBookings = () => {
        var today = new Date(moment().startOf('day'))
        db.collection('roomBookings')
            .where('DateAndTime', '>=', today)
            .orderBy('DateAndTime', 'desc')
            .get()
            .then(snapshot => {
                var allBookingsX = []
                snapshot.forEach(doc => {
                    var isUpdatedDate = doc.data().dateUpdated === "" ? "" : moment(doc.data().dateUpdated.toDate()).format('DD MMMM YYYY hh:mm a')
                    // var duration = doc.data().Duration > 60 ? (Math.round((doc.data().Duration / 60) * 10) / 10) + ' hours' : doc.data().Duration + ' minutes' // to have one decimal
                    allBookingsX.push({
                        ...doc.data(),
                        id: doc.id,
                        DateAndTime: moment(doc.data().DateAndTime.toDate()).format('DD MMM YYYY hh:mm a'),
                        dateCreated: moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY hh:mm a'),
                        dateUpdated: isUpdatedDate,
                        // Duration: duration
                    })
                })
                setAllBookings(allBookingsX)
            })
            .catch(function (error) {
                console.log("Error getting Room Bookings: ", error);
            })
    }
    useEffect(() => {
        getAllBookings()
        return () => {
        }
    }, [])

    const getAllBookingsManage = () => {
        var today = new Date(moment(new Date()).startOf('day'))
        db.collection('roomBookings')
            .where('DateAndTime', '>=', today)
            .where('Host', '==', displayName)
            .orderBy('DateAndTime', 'desc')
            .get()
            .then(snapshot => {
                var allBookings = []
                snapshot.forEach(doc => {
                    var isUpdatedDate = doc.data().dateUpdated === "" ? "" : moment(doc.data().dateUpdated.toDate()).format('DD MMMM YYYY hh:mm a')
                    var duration = doc.data().Duration > 60 ? (Math.round((doc.data().Duration / 60) * 10) / 10) + ' hours' : doc.data().Duration + ' minutes' // to have one decimal
                    allBookings.push({
                        ...doc.data(),
                        id: doc.id,
                        DateAndTime: moment(doc.data().DateAndTime.toDate()).format('DD MMM YYYY hh:mm a'),
                        dateCreated: moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY hh:mm a'),
                        dateUpdated: isUpdatedDate,
                        Duration: duration,
                        hostEmail: email
                    })
                })
                setMyBookings({
                    ...myBookings,
                    data: allBookings
                })
            })
            .catch(function (error) {
                console.log("Error getting Room Bookings: ", error);
            })
    }

    const timerRef = useRef(null)

    function startTime() {
        const today = new Date();
        let h = today.getHours();
        let m = today.getMinutes();
        let s = today.getSeconds();
        m = checkTime(m);
        s = checkTime(s);
        var clock = document.getElementById('clock');
        if (clock != null) {
            document.getElementById('clock').innerHTML = h + ":" + m + ":" + s;
            timerRef.current = setTimeout(startTime, 1000);
        }
    }

    useEffect(() => {
        getAllBookingsManage()
        startTime()
        return () => {
            clearTimeout(timerRef.current)
        }
    }, [])

    function checkTime(i) {
        if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
        return i;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log("Submitting new room booking")
        if (booking.Duration) {
            if (booking.DateAndTime) {
                isAvailableToBook()
                    .then(succ => {
                        addRoomBooking(booking)
                            .then(success => {
                                //display success
                                setSnackbar({
                                    open: true,
                                    severity: 'success',
                                    message: success,
                                })
                                clearForm()
                                getAllBookings()
                                getAllBookingsView()
                                getAllBookingsManage()
                            })
                            .catch(error => {
                                //display error
                                setSnackbar({
                                    open: true,
                                    severity: 'error',
                                    message: error,
                                })
                            })
                    })
                    .catch(error => {
                        setSnackbar({
                            open: true,
                            severity: 'error',
                            message: error,
                        })
                    })

            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'info',
                    message: 'Please select Date & Start Time for your booking.',
                })
            }
        }
        else {
            setSnackbar({
                open: true,
                severity: 'info',
                message: 'Please select Duration for your booking.',
            })
        }
    }

    return (
        <div>
            <Card className={classes.cardMarginBottom}>
                <h3 className={classes.centerHeader}>Current Bookings
                    {<Tooltip className={classes.buttonRightNoMargin} title="Refresh Data">
                        <IconButton onClick={getAllBookingsView} aria-label="Refresh Data">
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>}
                </h3>
                {/* bookingRoomsClock is on index.css */}
                <div ref={timerRef} id='clock' className='bookingRoomsClock'></div>
                <Timeline
                    sidebarWidth={200}
                    groups={viewAllBookings.groups}
                    items={viewAllBookings.items}
                    defaultTimeStart={viewAllBookings.defaultTimeStart}
                    defaultTimeEnd={viewAllBookings.defaultTimeEnd}
                />
            </Card>

            <Card className={classes.cardMarginBottom}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form id="roomBookingForm" onSubmit={handleSubmit}>
                        <h3 className={classes.center}>Book your meeting room</h3>

                        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    size="small"
                                    name="Subject"
                                    id="Subject"
                                    label="Subject"
                                    variant="outlined"
                                    value={booking.Subject}
                                    required
                                    fullWidth
                                    multiline
                                    rows={1}
                                    className={classes.lowerTextfield}
                                    inputProps={{ maxLength: 50 }}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid container item xs={12} sm={3} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Location"
                                    label="Location"
                                    name="Location"
                                    className={classes.lowerTextfield}
                                    // inputProps={{ maxLength: 50 }}
                                    value={booking.Location}
                                    onChange={handleChangeLocation}
                                // InputLabelProps={{ shrink: true }}
                                >
                                    {locations.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={3} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="RoomName"
                                    label="Room Name"
                                    name="RoomName"
                                    className={classes.lowerTextfield}
                                    // inputProps={{ maxLength: 50 }}
                                    helperText={booking.roomHelperText}
                                    value={booking.RoomName}
                                    onChange={handleChangeRoomName}
                                // InputLabelProps={{ shrink: true }}
                                >
                                    {booking.Location === '' ?
                                        <MenuItem value='Please select Location'>
                                            Please select Location
                                        </MenuItem>
                                        : booking.Location === 'Dublin Hill' ?
                                            roomNamesDH.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                            :
                                            roomNameK4K5.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                    }
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={3} spacing={1}>
                                <DateTimePicker
                                    margin="normal"
                                    id="DateAndTime"
                                    label="Date & Start Time"
                                    name="DateAndTime"
                                    format="dd MMM yyyy HH:mm"
                                    // error // makes text red
                                    // helperText="Inclusive in your holiday request. Check before submitting **"
                                    // required
                                    helperText={booking.dateAndTimeHelperText}
                                    fullWidth
                                    value={booking.DateAndTime}
                                    minDate={new Date()}
                                    onChange={handleDateChangeFromDate}
                                // ButtonProps={{
                                //     'aria-label': 'change date',
                                // }}
                                />
                            </Grid>

                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>

                            <ToggleButtonGroup
                                color='primary'
                                value={booking.Duration}
                                exclusive
                                onChange={handleChangeToggleButton}
                                className={classes.marginTopBottom}
                            >
                                {/* <ToggleButton classes={{ selected: classes.toggleColor }} value={15}>15 minutes<br />(0.25 hour)</ToggleButton> */}
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={15}>15 minutes<br />(0.25 hour)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={30}>30 minutes<br />(0.5 hour)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={45}>45 minutes<br />(0.75 hour)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={60}>60 minutes<br />(1 hour)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={75}>75 minutes<br />(1.25 hour)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={90}>90 minutes<br />(1.5 hour)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={105}>105 minutes<br />(1.75 hour)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={120}>120 minutes<br />(2 hours)</ToggleButton>

                            </ToggleButtonGroup>

                            <ToggleButtonGroup
                                color='primary'
                                value={booking.Duration}
                                exclusive
                                onChange={handleChangeToggleButton}
                            >
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={150}>150 minutes<br />(2.5 hours)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={180}>180 minutes<br />(3 hours)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={210}>210 minutes<br />(3.5 hours)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={240}>240 minutes<br />(4 hours)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={300}>300 minutes<br />(5 hours)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={360}>360 minutes<br />(6 hours)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={420}>420 minutes<br />(7 hours)</ToggleButton>
                                <ToggleButton className={classes.toggleColor} classes={{ selected: classes.toggleSelected }} value={480}>480 minutes<br />(8 hours)</ToggleButton>

                            </ToggleButtonGroup>

                        </Grid>
                        <Button
                            type="submit"
                            className={classes.buttonRight}
                            variant="contained"
                            color="primary"
                        >
                            Book Now
                        </Button>

                    </form>
                </MuiPickersUtilsProvider>
            </Card>

            <Card>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <MaterialTable
                        title={"My meeting room bookings"}
                        columns={myBookings.columns}
                        data={myBookings.data}
                        // detailPanel={rowData => {
                        //     // console.log(rowData)
                        //     return (
                        //         <TicketHRDetails rowData={rowData} />
                        //     )
                        // }}
                        options={{
                            detailPanelType: "single",
                            actionsColumnIndex: -1,
                            // exportButton: true, 
                            filtering: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 50]
                            //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                        }}
                        // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                        actions={
                            [{
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    getAllBookings()
                                    return () => { }
                                },
                            }]
                        }
                        editable={{
                            // onRowAdd: newData =>
                            //     new Promise(resolve => {
                            //         setTimeout(() => {
                            //             addDealsInSales(newData)
                            //                 .then(success => {
                            //                     //display success
                            //                     setSnackbar({
                            //                         open: true,
                            //                         severity: 'success',
                            //                         message: success,
                            //                     });
                            //                     // resolve();
                            //                 })
                            //                 .catch(error => {
                            //                     console.log(error)
                            //                     //display error
                            //                     setSnackbar({
                            //                         open: true,
                            //                         severity: 'error',
                            //                         message: error,
                            //                     });
                            //                 })
                            //             getGraphsData()
                            //             resolve();
                            //             // setCustomerComplaintsStatus(prevState => {
                            //             //     const data = [...prevState.data];
                            //             //     data.push(newData);
                            //             //     // return { ...prevState, data };
                            //             // });
                            //         }, 600);
                            //     }),
                            // onRowUpdate: (newData, oldData) =>
                            //     new Promise(resolve => {
                            //         setTimeout(() => {
                            //             if (oldData) {
                            //                 setDealsInSales(prevState => {
                            //                     const data = [...prevState.data];
                            //                     newData.dateCreated = moment(newData.dateCreated).format('DD MMM YYYY')
                            //                     data[data.indexOf(oldData)] = newData;
                            //                     console.log('Updated Verification Wip row in DB')
                            //                     // Update data
                            //                     updateDealsInSales(newData)
                            //                         .then(success => {
                            //                             //display success
                            //                             setSnackbar({
                            //                                 open: true,
                            //                                 severity: 'success',
                            //                                 message: success,
                            //                             });
                            //                             resolve();
                            //                         })
                            //                         .catch(error => {
                            //                             console.log(error)
                            //                             //display error
                            //                             setSnackbar({
                            //                                 open: true,
                            //                                 severity: 'error',
                            //                                 message: error,
                            //                             });
                            //                         })
                            //                     getGraphsData();
                            //                     return { ...prevState, newData };
                            //                 })
                            //             }
                            //         }, 600)
                            //     }),
                            onRowDelete: oldData =>
                                new Promise(resolve => {
                                    setTimeout(() => {
                                        // console.log(oldData.id)
                                        deleteRoomBooking(oldData.id)
                                            .then(success => {
                                                //display success
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'success',
                                                    message: success,
                                                });
                                                setMyBookings(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                                getAllBookingsView()
                                                resolve();
                                            })
                                            .catch(error => {
                                                // console.log(error)
                                                //display error
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'error',
                                                    message: error,
                                                });
                                            })
                                        // getGraphsData();
                                    }, 600);
                                }),
                        }}
                    />

                </MuiPickersUtilsProvider>
            </Card>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    );
}