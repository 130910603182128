import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { InputBase, makeStyles, TextField } from '@material-ui/core';
import { db } from '../../../config/fbConfig';
import { DataContext } from '../../../contexts/DataContext';

const useStyles = makeStyles(theme => ({
    leftMargin: {
        marginLeft: '30px',
    },
}))

export default function CareersTable(props) {
    const classes = useStyles();

    const { addInternalCareers, updateInternalCareers, deleteFromInternalCareers } = useContext(DataContext);

    const [internalCareers, setInternalCareers] = useState({
        columns: [
            {
                title: 'Status', field: 'Status', initialEditValue: 'Open', lookup: {
                    'Open': 'Open',
                    'Closed': 'Closed',
                }
            },
            { title: 'Title', field: 'Title' },
            {
                title: 'Short Description (up to 1000 characters)', field: 'Description',
                render: rowData =>
                    <InputBase readOnly multiline maxRows={6} value={rowData.Description} style={{ minWidth: 400 }} />,
                editComponent: props => //console.log(props.rowData)
                    <TextField multiline maxRows={6}
                        value={props.rowData.Description}
                        onChange={(e) => { props.onChange(e.target.value) }}
                        style={{ minWidth: 400 }} inputProps={{ maxLength: 1000 }} />
            },
            { title: 'Occupop Url Link', field: 'UrlLink' },
            { title: 'Location', field: 'Location' },
            {
                title: 'Job Type', field: 'JobType', lookup: {
                    'Permanent / Full-Time': 'Permanent / Full-Time',
                    'Contract': 'Contract'
                }
            },

            // { title: 'Status', field: 'Status', editable: 'never' },
            // { title: 'Department', field: 'Department', editable: 'never' },
            // { title: 'Ticket Type', field: 'TicketType', editable: 'never' }, //filtering: false
            // { title: 'Assigned IT', field: 'AssignedIt', editable: 'never' },
            // { title: 'Raised By', field: 'RaisedBy', editable: 'never' },
            // { title: 'Raised Date', field: 'DateRaised', type: 'date', editable: 'never', defaultSort: 'desc', filtering: false },
            // { title: 'Issue Category', field: 'IssueCategory', editable: 'never' },
            // { title: 'Request Category', field: 'RequestCategory', editable: 'never' },
            // { title: 'Week', field: 'WeekNo', editable: 'never' },
            // { title: 'Record UID', field: 'RecordUID', editable: 'never', filtering: false },
            // { title: 'Platform', field: 'Platform', editable: 'never' },
            // { title: 'Failure Category', field: 'FailureCategory', editable: 'never' },
            // { title: 'Closed Date', field: 'ClosedDate', type: 'date', editable: 'never' },
            // { title: 'Site', field: 'Site', editable: 'never' },
            // { title: 'Process', field: 'Process', editable: 'never' },
            // { title: 'Area', field: 'Area', editable: 'never' },
            // { title: 'Fix Type', field: 'FixType', editable: 'never' },
            // { title: 'Fix Update Description', field: 'FixUpdateDescription', editable: 'never' },
            // { title: 'Root Cause Category', field: 'RootCauseCategory', editable: 'never' },
            // { title: 'Root Cause Description', field: 'RootCauseDescription', hidden: true, cellStyle: { whiteSpace: "nowrap", maxWidth: "50px" }, editable: 'never' },
            // { title: 'Root Cause Description', field: 'RootCauseDescription', cellStyle: { whiteSpace: "nowrap", maxWidth: "50px" }, editable: 'never' },
            // { title: 'Nav Error', field: 'NavErrorNo', editable: 'never' },
            // { title: 'Ticket Send From', field: 'TicketSendFrom', editable: 'never' },

        ],
        data: [],
    });

    useEffect(() => {
        getInternalCareers()
        return () => { }
    }, [])

    function getInternalCareers() {
        db.collection('careersInternal')
            .orderBy("dateCreated", "desc")
            .get()
            .then(snap => {
                var snapData = []
                snap.forEach(doc => {
                    snapData.push({ ...doc.data(), id: doc.id, })
                })
                setInternalCareers({
                    ...internalCareers,
                    data: snapData
                })
            })
            .catch(err => {
                console.log("Error getting internal positions: ", err);
            })
    }

    return (
        <div>

            <MaterialTable
                title={"Internal Positions"}
                columns={internalCareers.columns}
                data={internalCareers.data}
                // detailPanel={rowData => {
                //     rowData.username = username; // assigning username for email purpose
                //     // console.log(rowData)
                //     return (
                //         <TicketsITDetails ticketData={rowData} />
                //     )
                // }}                
                // onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                options={{
                    addRowPosition: 'first',
                    detailPanelType: "single",
                    actionsColumnIndex: -1,
                    exportButton: true,
                    filtering: false,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 50, internalCareers.data.length === 0 ? 100 : { value: internalCareers.data.length, label: 'All' }]
                }}
                actions={
                    [{
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            getInternalCareers()
                            return () => { }
                        },
                    }]}
                editable={{
                    onRowAdd: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                addInternalCareers(newData)
                                    .then(success => {
                                        //display success
                                        props.setSnackbar({
                                            open: true,
                                            severity: 'success',
                                            message: success,
                                        });
                                        // resolve();
                                    })
                                    .catch(error => {
                                        console.log(error)
                                        //display error
                                        props.setSnackbar({
                                            open: true,
                                            severity: 'error',
                                            message: error,
                                        });
                                    })
                                getInternalCareers()
                                resolve();
                            }, 600);

                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                if (oldData) {
                                    setInternalCareers(prevState => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        console.log('going to Updated internal careers row in DB')
                                        // Update data
                                        updateInternalCareers(newData)
                                            .then(success => {
                                                //display success
                                                props.setSnackbar({
                                                    open: true,
                                                    severity: 'success',
                                                    message: success,
                                                });

                                            })
                                            .catch(error => {
                                                console.log(error)
                                                //display error
                                                props.setSnackbar({
                                                    open: true,
                                                    severity: 'error',
                                                    message: error,
                                                });
                                            })
                                        getInternalCareers();
                                        resolve();
                                        return { ...prevState, newData };
                                    })
                                }
                            }, 600)
                        }),
                    onRowDelete: oldData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                // console.log(oldData.id)
                                deleteFromInternalCareers(oldData.id)
                                    .then(success => {
                                        //display success
                                        props.setSnackbar({
                                            open: true,
                                            severity: 'success',
                                            message: success,
                                        });
                                        setInternalCareers(prevState => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            return { ...prevState, data };
                                        });
                                        resolve();
                                    })
                                    .catch(error => {
                                        console.log(error)
                                        //display error
                                        props.setSnackbar({
                                            open: true,
                                            severity: 'error',
                                            message: error,
                                        });
                                    })
                                // getInternalCareers();
                            }, 600);
                        }),
                }}
            />

        </div>
    )
}
