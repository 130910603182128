import React, { useContext, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, TextField, makeStyles } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { DataContext } from '../../../../contexts/DataContext';
import Loading from '../../Loading';

const useStyles = makeStyles(theme => ({
    addingDialog: {
        padding: '0px 150px 0px 150px',
    },
    input: {
        display: 'none',
    },
    partNoTextfield: {
        minWidth: 300
    },
    greenText: {
        textAlign: 'center',
        color: 'green',
    },
    buttonsMargin: {
        margin: '50px 15px 15px 15px'
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ServerRecoveryAddRecord(props) {
    const classes = useStyles();

    const { addServerRecoveryRecord } = useContext(DataContext)

    const [record, setRecord] = useState({
        boxUrl: '',
        partNo: '',
        partNoUrl: '',
        serverUrl: '',
        partNoImg: [],
        serverImg: [],
        boxImg: [],
    })

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        var maxSize = 25000000
        var imagesPartNo = [...record.partNoImg];
        var imagesServer = [...record.serverImg];
        var imagesBox = [...record.boxImg];

        var file = e.target.files[0]
        if (file.size >= maxSize) {
            return props.setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Selected file is too big to upload.',
            })
            // e.target.value = null // to allow selecting the same file over and over
        }
        else {
            // console.log('file.type', file.type.toLowerCase())
            if (file.type.toLowerCase() === 'image/jpeg' || file.type.toLowerCase() === 'image/png') {
                console.log('e.target.id', e.target.id)
                switch (e.target.id) {
                    case 'partNoImage':
                        imagesPartNo.push(file)
                        break;
                    case 'serverImage':
                        imagesServer.push(file)
                        break;
                    case 'boxImage':
                        imagesBox.push(file)
                        break;

                    default:
                        break;
                }
                setRecord({
                    ...record,
                    partNoImg: imagesPartNo,
                    serverImg: imagesServer,
                    boxImg: imagesBox,
                })
            }
            else {
                return props.setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: 'Please select a valid image format, JPEG or PNG.',
                })
                // e.target.value = null // to allow selecting the same file over and over
            }
        }
    }

    function clearRecordFields() {
        setRecord({
            boxUrl: '',
            partNo: '',
            partNoUrl: '',
            serverUrl: '',
            partNoImg: [],
            serverImg: [],
            boxImg: [],
        })
    }
    const handleClose = () => {
        clearRecordFields()
        props.setIsDialogOpen(false);
    }
    const handleChange = (e) => {
        setRecord({
            ...record,
            [e.target.name]: e.target.value.toUpperCase(),
        })
    }

    const handleSubmitNewRecord = () => {
        // console.log("submitting")

        if (!record.partNo) {
            return props.setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please provide Part Number.',
            })
        }
        if (record.partNoImg.length === 0) {
            return props.setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please add Part image.',
            })
        }
        if (record.serverImg.length === 0) {
            return props.setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please add Server image.',
            })
        }
        if (record.boxImg.length === 0) {
            return props.setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please add Box image.',
            })
        }
        if (!isSubmitting) {
            setIsSubmitting(true)
            addServerRecoveryRecord(record)
                .then(succ => {
                    handleClose()
                    props.setSnackbar({
                        open: true,
                        severity: 'success',
                        message: succ,
                    })
                    setIsSubmitting(false)
                })
                .catch(err => {
                    props.setSnackbar({
                        open: true,
                        severity: 'error',
                        message: err,
                    })
                    setIsSubmitting(false)
                })
        }
    }
    return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            {isSubmitting ? <Loading /> : null}
            <DialogTitle id="dialogTitle">Add new record</DialogTitle>

            <DialogContent>
                <DialogContentText id="addDialogDescription" style={{ fontSize: "0.8rem" }}>
                    Please add "Part Number" and three images:
                    <ul>
                        <li>Part image</li>
                        <li>Server image</li>
                        <li>Box image</li>
                    </ul>

                </DialogContentText>
            </DialogContent>

            <div className={classes.addingDialog}>
                <form onSubmit={handleSubmitNewRecord}>

                    <TextField className={classes.partNoTextfield}
                        size="small"
                        variant="outlined"
                        margin="normal"
                        name='partNo'
                        label="Part Number"
                        autoComplete='off'
                        fullWidth
                        helperText="up to 50 characters"
                        inputProps={{ maxLength: 50 }}
                        value={record.partNo}
                        onChange={handleChange} />

                    <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
                        <Grid item>
                            <input
                                accept="image/jpeg, image/png"
                                // accept="image/*, video/*,.pdf,.xlsx,.xls,.csv"
                                className={classes.input}
                                id="partNoImage"
                                // multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="partNoImage" style={{ width: '100%' }}>
                                <Button
                                    // size="small"
                                    variant="outlined"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Part Image
                                </Button>
                            </label>
                            {record.partNoImg.length === 0 ? null : <p className={classes.greenText}>Part image attached</p>}
                        </Grid>

                        <Grid item>
                            <input
                                accept="image/jpeg, image/png"
                                // accept="image/*, video/*,.pdf,.xlsx,.xls,.csv"
                                className={classes.input}
                                id="serverImage"
                                // multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="serverImage" style={{ width: '100%' }}>
                                <Button
                                    // size="small"
                                    variant="outlined"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Server Image
                                </Button>
                            </label>
                            {record.serverImg.length === 0 ? null : <p className={classes.greenText}>Server image attached</p>}
                        </Grid>

                        <Grid item>
                            <input
                                accept="image/jpeg, image/png"
                                // accept="image/*, video/*,.pdf,.xlsx,.xls,.csv"
                                className={classes.input}
                                id="boxImage"
                                // multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="boxImage" style={{ width: '100%' }}>
                                <Button
                                    // size="small"
                                    variant="outlined"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Box Image
                                </Button>
                            </label>
                            {record.boxImg.length === 0 ? null : <p className={classes.greenText}>Box image attached</p>}
                        </Grid>
                    </Grid>

                </form>
            </div>
            <DialogActions >
                <Button onClick={handleClose}
                    className={classes.buttonsMargin}
                    disabled={isSubmitting}
                    variant="outlined"
                    color="secondary"
                    size="small">
                    Cancel
                </Button>
                <Button onClick={handleSubmitNewRecord}
                    className={classes.buttonsMargin}
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    size="small">
                    Create New Record
                </Button>
            </DialogActions>
        </Dialog>
    )
}
