import React, { useEffect, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { db } from '../../../config/fbConfig';
import { DataContext } from '../../../contexts/DataContext';
import moment from 'moment';
import MaterialTable from 'material-table';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
}));

const SoftwareReleaseInfo = () => {
    const classes = useStyles();
    const { createSoftwareReleaseInfoTracker, updateSoftwareReleaseInfoTracker } = useContext(DataContext);
    const [softwareReleaseInfoTracker, setSoftwareReleaseInfoTracker] = useState({
        columns: [
            { title: 'Release Date (yyyy/mm/dd)', field: 'releaseDate', type: 'date', initialEditValue: new Date() },
            {
                title: 'Application Name', field: 'applicationName', defaultValue: '', lookup: {
                    '': '',
                    'Connect': 'Connect',
                    'Datadead': 'Datadead',
                    'ISO Express': 'ISO Express',
                    'Nav': 'Nav',
                    'Returntek': 'Returntek',
                    'Salesforce': 'Salesforce',
                    'Wisetek Website': 'Wisetek Website',
                    'Wisetektools': 'Wisetektools'
                }
            },
            { title: 'Objects Modified', field: 'objectsModified' },
            { title: 'Decription Of Changes', field: 'descriptionOfChanges' },
            {
                title: 'Impacted Department', field: 'impactedDepartment', defaultValue: '', lookup: {
                    '': '',
                    'CS/Logistics (Dublin Hill)': 'CS/Logistics (Dublin Hill)',
                    'Engineering': 'Engineering',
                    'Finance': 'Finance',
                    'HR': 'HR',
                    'IS&T': 'IS&T',
                    'Marketing': 'Marketing',
                    'Operations': 'Operations',
                    'Quality and Compliance': 'Quality and Compliance',
                    'Sales': 'Sales',
                }
            },
            { title: 'Managed By', field: 'createdBy', editable: 'never' },
            { title: 'Requested By', field: 'requestedBy' },
            { title: 'Software Released By', field: 'softwareReleasedBy' },
            { title: 'Created Date (yyyy/mm/dd)', field: 'createdDate', editable: 'never', defaultSort: 'desc' },
        ],
        data: []
    });
    const softwareReleaseInfo = [];

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    useEffect(() => {
        db.collection('softwareReleaseInfoTracker').get()
            .then(snapshot => {
                snapshot.forEach(doc => {

                    softwareReleaseInfo.push({
                        ...doc.data(),
                        docId: doc.id,

                        releaseDate: moment(doc.data().releaseDate.toDate()).format('YYYY/MM/DD'),
                        createdDate: moment(doc.data().createdDate.toDate()).format('YYYY/MM/DD '),

                    });
                })
                setSoftwareReleaseInfoTracker({
                    ...softwareReleaseInfoTracker,
                    data: softwareReleaseInfo
                })
            })
            .catch(function (error) {
                console.log("Error getting nav trackers data: ", error);
            });
        return () => { }
    }, []);

    return (
        <div >

            <MaterialTable
                title={"Software Release Information"}
                columns={softwareReleaseInfoTracker.columns}
                data={softwareReleaseInfoTracker.data}
                options={{ addRowPosition: 'first' }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            db.collection('softwareReleaseInfoTracker').get()
                                .then(snapshot => {
                                    snapshot.forEach(doc => {

                                        softwareReleaseInfo.push({
                                            ...doc.data(),
                                            docId: doc.id,

                                            releaseDate: moment(doc.data().releaseDate.toDate()).format('YYYY/MM/DD'),
                                            createdDate: moment(doc.data().createdDate.toDate()).format('YYYY/MM/DD'),

                                        });
                                    })
                                    setSoftwareReleaseInfoTracker({
                                        ...softwareReleaseInfoTracker,
                                        data: softwareReleaseInfo
                                    })
                                })
                                .catch(function (error) {
                                    console.log("Error getting nav trackers data: ", error);
                                });
                        },
                    }
                ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                setSoftwareReleaseInfoTracker(prevState => {
                                    const data = [...prevState.data];
                                    data.push(newData);
                                    console.log(newData)
                                    createSoftwareReleaseInfoTracker(newData)
                                        .then(success => {
                                            console.log('Added new software change to DB')
                                            //display success
                                            setSnackbar({
                                                open: true,
                                                severity: 'success',
                                                message: success,
                                            })
                                        })
                                        .catch(error => {
                                            console.log(error)
                                            //display error
                                            reject()
                                            setSnackbar({
                                                open: true,
                                                severity: 'error',
                                                message: error,
                                            })
                                        });
                                    resolve();
                                    return { ...prevState, data };
                                });
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>

                        new Promise(resolve => {
                            // console.log(oldData);
                            // console.log(newData);
                            setTimeout(() => {
                                if (oldData) {
                                    setSoftwareReleaseInfoTracker(prevState => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;

                                        // Update data
                                        updateSoftwareReleaseInfoTracker(newData)
                                            .then(success => {
                                                console.log('Updated software info in DB')
                                                //display success
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'success',
                                                    message: success,
                                                })
                                            })
                                            .catch(error => {
                                                console.log(error)
                                                //display error
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'error',
                                                    message: error,
                                                })
                                            });
                                        resolve();
                                        return { ...prevState, data };
                                    })
                                }
                            }, 600)
                        }),
                    // onRowDelete: oldData =>
                    //     new Promise(resolve => {
                    //         setTimeout(() => {
                    //             resolve();
                    //             setTracker(prevState => {
                    //                 const data = [...prevState.data];
                    //                 data.splice(data.indexOf(oldData), 1);
                    //                 return { ...prevState, data };
                    //             });
                    //         }, 600);
                    //     }),
                }}
            />

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    )
}
export default SoftwareReleaseInfo