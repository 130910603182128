import React from 'react';
import { makeStyles } from '@material-ui/core';
import women2024 from '../../../../images/InternationalWomensDayPoster2024.jpg'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '70%',
        borderRadius: 20,
    },

}));

export default function WomensDay2024() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                <h2 className={classes.textCentered}>International Women's Day 2024 campaign to support Women's Aid Charity</h2>

            </header>
            <div className={classes.textCentered}>
                <img className={classes.media} src={women2024} alt="women's day" />
            </div>


            {/* <h4 className={classes.textCentered}>An equal world is an enabled world.
                Individually, we're all responsible for our own thoughts and actions - all day, every day.
                We can actively choose to challenge stereotypes, fight bias, broaden perceptions, improve situations and celebrate women's achievements.
                Collectively, each one of us can help create a gender equal world.
                Let's all be #EachforEqual
            </h4> */}

            <br />
            <p>Regards,<br /><br />
                HR Team, Wisetek™<br />
            </p>
            <br />

        </div>
    )
}