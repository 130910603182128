import React from 'react';
import { makeStyles } from '@material-ui/core';
import EHSPresentation from '../../../../images/EHSPresentationCOMPLETE.mp4'
import posterEHSWeek from '../../../../images/posterEHSWeek.jpg'
import ImageDisplayCarousel from '../../../customComponents/ImageDisplayCarousel';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    media: {
        width: '90%',
        borderRadius: 10,
    },
}));

export default function EHSWeek(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            {/* <header >
                <h2 className={classes.textCentered}>Recording of Employee Business Update 1ˢᵗ December 2020</h2>
            </header> */}

            <br />
            <div className={classes.textCentered}>
                <video className={classes.media} src={EHSPresentation}
                    type="video/mp4"
                    poster={posterEHSWeek}
                    loop
                    controls
                    controlsList="nodownload">
                    Your browser does not support HTML video.
                </video>
            </div>

            <ImageDisplayCarousel storageRefString='NewsAndAnnouncements/ehsWeek/' headerText='Here are some photos from that week...' getDataNow={props.getDataNow} />

            <p>Regards,<br /><br />

                Health & Safety Team<br />
                {/* COO<br /> */}
                Wisetek™<br />
            </p>
            <br />

        </div>
    )
}