import React, { useState, useContext } from 'react'
import { makeStyles, TextField, MenuItem, Button, Grid } from '@material-ui/core'
import { DataContext } from '../../../../contexts/DataContext';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Container } from '@material-ui/core';
import { AuthContext } from '../../../../contexts/AuthContext';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    buttonRight: {
        float: 'right',
        marginTop: '35px',
        marginRight: '15%',
        // marginBottom: '24px',
    },
    margin15: {
        margin: '15px',
    },
    filePicker: {
        float: 'left',
        marginLeft: '16%',
        marginTop: '35px',
    },
    input: {
        display: 'none',
    },
    marginRight: {
        marginRight: '15px'
    },

}));


const ticketTypeENG = [
    { value: '', label: '' },
    { value: 'Issue / Error', label: 'Issue / Error' },
    { value: 'Change Request', label: 'Change Request' },
];
const division = [
    { value: '', label: '' },
    { value: 'Dell-CS', label: 'Dell-CS' },
    { value: 'Dell-DVE', label: 'Dell-DVE' },
    { value: 'Dell-RLO', label: 'Dell-RLO' },
    { value: 'Forcepoint', label: 'Forcepoint' },
    { value: 'ITAD', label: 'ITAD' },
    { value: 'WISE', label: 'WISE' },
    { value: 'WiseCare', label: 'WiseCare' },
    { value: 'WiseWork', label: 'WiseWork' },
];
const categoryDellDVE = [
    // { value: '', label: '' },
    { value: 'Process', label: 'Process' },
    { value: 'Test', label: 'Test' },
];
const categoryWiseCare = [
    // { value: '', label: '' },
    { value: 'Process', label: 'Process' },
    { value: 'Test', label: 'Test' },
];
const categoryWISE = [
    // { value: '', label: '' },
    { value: 'Process', label: 'Process' },
    { value: 'Test', label: 'Test' },
];
const categoryITAD = [
    // { value: '', label: '' },
    { value: 'License Request', label: 'License Request' },
    { value: 'Process', label: 'Process' },
    { value: 'Shred Video Request', label: 'Shred Video Request' },
    { value: 'Software Installation', label: 'Software Installation' },
    { value: 'Test', label: 'Test' },
];
const categoryForcepoint = [
    // { value: '', label: '' },
    { value: 'Process', label: 'Process' },
    { value: 'Test', label: 'Test' },
];
const categoryDellRLO = [
    // { value: '', label: '' },
    { value: 'Cumulus', label: 'Cumulus' },
    { value: 'Process', label: 'Process' },
    { value: 'Test', label: 'Test' },
];
const categoryWiseWork = [
    // { value: '', label: '' },
    { value: 'Process', label: 'Process' },
    { value: 'Test', label: 'Test' },
];
const categoryDellCS = [
    // { value: '', label: '' },
    { value: 'Process', label: 'Process' },
];
const categoryAll = [
    { value: '', label: '' },
    // { value: 'Process', label: 'Process' },
    // { value: 'Test', label: 'Test' },
];
// Define category maps based on Division values
const categoryMaps = {
    'Dell-CS': categoryDellCS,
    'Dell-DVE': categoryDellDVE,
    'Dell-RLO': categoryDellRLO,
    'Forcepoint': categoryForcepoint,
    'ITAD': categoryITAD,
    'WISE': categoryWISE,
    'WiseCare': categoryWiseCare,
    'WiseWork': categoryWiseWork,
};

const actionDellDVE = [
    // { value: '', label: '' },
    { value: 'Infrastructure', label: 'Infrastructure' },
    { value: 'No part Number devices found', label: 'No part Number devices found' },
    { value: 'Procedure & Training', label: 'Procedure & Training' },
    { value: 'Pulling Logs onto Nav', label: 'Pulling Logs onto Nav' },
    { value: 'Test Content Unavailable', label: 'Test Content Unavailable' },
    { value: 'Test Failing to Complete', label: 'Test Failing to Complete' },
];
const actionWiseCare = [
    // { value: '', label: '' },
    { value: 'FTP Site', label: 'FTP Site' },
    { value: 'Hardware', label: 'Hardware' },
    { value: 'Network', label: 'Network' },
    { value: 'Procedure & Training', label: 'Procedure & Training' },
    { value: 'Software/Portal', label: 'Software/Portal' },
];
const actionWISE = [
    // { value: '', label: '' },
    { value: 'Infrastructure', label: 'Infrastructure' },
    { value: 'Procedure & Training', label: 'Procedure & Training' },
];
const actionITAD = [
    // { value: '', label: '' },
    { value: 'Procedure & Training', label: 'Procedure & Training' },
    { value: 'Process Deviation', label: 'Process Deviation' },
    { value: 'Shred Video Request', label: 'Shred Video Request' },
];
const actionForcepoint = [
    // { value: '', label: '' },
    { value: 'FAI', label: 'FAI' },
    { value: 'Procedure & Training', label: 'Procedure & Training' },
];
const actionDellRLO = [
    // { value: '', label: '' },
    { value: 'Infrastructure', label: 'Infrastructure' },
    { value: 'License Request', label: 'License Request' },
    { value: 'Procedure & Training', label: 'Procedure & Training' },
    { value: 'Software Installation', label: 'Software Installation' },
    { value: 'Test Content', label: 'Test Content' },
];
const actionWiseWork = [
    // { value: '', label: '' },
    { value: 'Procedure & Training', label: 'Procedure & Training' },
];
const actionDellCS = [
    // { value: '', label: '' },
    { value: 'Procedure & Training', label: 'Procedure & Training' },
];
const actionAll = [
    { value: '', label: '' },
    // { value: 'Process', label: 'Process' },
    // { value: 'Test', label: 'Test' },
];
const actionMaps = {
    'Dell-CS': actionDellCS,
    'Dell-DVE': actionDellDVE,
    'Dell-RLO': actionDellRLO,
    'Forcepoint': actionForcepoint,
    'ITAD': actionITAD,
    'WISE': actionWISE,
    'WiseCare': actionWiseCare,
    'WiseWork': actionWiseWork,
};

const processDellDVE = [
    // { value: '', label: '' },
    { value: 'Erasure', label: 'Erasure' },
    { value: 'Final Inspection', label: 'Final Inspection' },
    { value: 'FRU', label: 'FRU' },
    { value: 'OBA', label: 'OBA' },
    { value: 'PRE-Test', label: 'PRE-Test' },
];
const processWiseCare = [
    // { value: '', label: '' },
    { value: 'Erasure/Test', label: 'Erasure/Test' },
    { value: 'Packout/Drive Strip', label: 'Packout/Drive Strip' },
    { value: 'Receiving', label: 'Receiving' },
];
const processWISE = [
    // { value: '', label: '' },
    { value: 'Basic Audit', label: 'Basic Audit' },
    { value: 'Component Recovery', label: 'Component Recovery' },
    { value: 'Erasure', label: 'Erasure' },
    { value: 'Final Inspection', label: 'Final Inspection' },
    { value: 'FRU', label: 'FRU' },
    { value: 'GUTS', label: 'GUTS' },
    { value: 'OBA', label: 'OBA' },
    { value: 'Packout', label: 'Packout' },
    { value: 'PRE-Test', label: 'PRE-Test' },
    { value: 'TPM Check', label: 'TPM Check' },

];
const processITAD = [
    // { value: '', label: '' },
    { value: 'Ecomm', label: 'Ecomm' },
    { value: 'Erasure', label: 'Erasure' },
    { value: 'Google Accessories', label: 'Google Accessories' },
    { value: 'PFH', label: 'PFH' },
    { value: 'Receiving & Basic Audit', label: 'Receiving & Basic Audit' },
    { value: 'Refurb', label: 'Refurb' },
    { value: 'Shipping', label: 'Shipping' },
    { value: 'Shredding', label: 'Shredding' },
    { value: 'Tech Audit', label: 'Tech Audit' },
    { value: 'Teardown', label: 'Teardown' },
    { value: 'Verify', label: 'Verify' },

];
const processForcepoint = [
    // { value: '', label: '' },
    { value: 'Packout', label: 'Packout' },
    { value: 'Receiving', label: 'Receiving' },
    { value: 'Shipping', label: 'Shipping' },
    { value: 'Test', label: 'Test' },
];
const processDellRLO = [
    // { value: '', label: '' },
    { value: '1UClear', label: '1UClear' },
    { value: 'Archive Viewer', label: 'Archive Viewer' },
    { value: 'DAE Test', label: 'DAE Test' },
    { value: 'Drive Inspection/Avail', label: 'Drive Inspection/Avail' },
    { value: 'Drive Strip', label: 'Drive Strip' },
    { value: 'EPS', label: 'EPS' },
    { value: 'Erasure', label: 'Erasure' },
    { value: 'HiPot', label: 'HiPot' },
    { value: 'Mellanox', label: 'Mellanox' },
    { value: 'Packout', label: 'Packout' },
    { value: 'Receiving', label: 'Receiving' },
    { value: 'Refurb', label: 'Refurb' },
    { value: 'RTV', label: 'RTV' },
    { value: 'Teardown', label: 'Teardown' },
    { value: 'WBDD', label: 'WBDD' },

];
const processWiseWork = [
    // { value: '', label: '' },
    { value: 'Audit', label: 'Audit' },
    { value: 'Packout', label: 'Packout' },
    { value: 'Receiving', label: 'Receiving' },
    { value: 'Shipping', label: 'Shipping' },
    { value: 'Test', label: 'Test' },
];
const processDellCS = [
    // { value: '', label: '' },
    { value: 'Drive Strip', label: 'Drive Strip' },
    { value: 'Receiving', label: 'Receiving' },
    { value: 'RTV', label: 'RTV' },
];
const processAll = [
    { value: '', label: '' },
    // { value: 'Process', label: 'Process' },
    // { value: 'Test', label: 'Test' },
];
const processMaps = {
    'Dell-CS': processDellCS,
    'Dell-DVE': processDellDVE,
    'Dell-RLO': processDellRLO,
    'Forcepoint': processForcepoint,
    'ITAD': processITAD,
    'WISE': processWISE,
    'WiseCare': processWiseCare,
    'WiseWork': processWiseWork,
};

const empty = [
    { value: '', label: '' },
];

const site = [
    { value: 'Kilbarry', label: 'Kilbarry' },
    { value: 'K4 / K5', label: 'K4 / K5' },
    { value: 'Carrigtohill', label: 'Carrigtohill' },
    { value: 'Dublin', label: 'Dublin' },
    { value: 'UK', label: 'UK' },
    { value: 'Thailand', label: 'Thailand' },
    // { value: 'MD - Maryland', label: 'MD - Maryland' },
    { value: 'TX - Texas', label: 'TX - Texas' },
    { value: 'VA - Virginia', label: 'VA - Virginia' },
    { value: 'SAC - Sacramento', label: 'SAC - Sacramento' },
    { value: 'MA - Northborough', label: 'MA - Northborough' },
    // { value: 'Dubai', label: 'Dubai' },
    { value: 'All sites', label: 'All Sites' },
];


export default function TicketFormEngineering(props) {
    const classes = useStyles();

    const { createTicketEngineering } = useContext(DataContext);
    const { email, displayName } = useContext(AuthContext);

    const defaultTicketFrom = {
        userEmail: '',
        userFullName: '',
        TicketType: '',
        Division: '',
        Category: '',
        Action: '',
        Process: '',
        Site: '',
        Description: '',
        files: [],
        filesSize: 0,
        filesToStoreInDB: [],
    }
    const [ticketForm, setTicketForm] = useState(defaultTicketFrom);

    const [isSubmitting, setIsSubmitting] = useState(false)

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleChangeTicketTypeEngineering = (e) => {

        setTicketForm({
            ...ticketForm,
            [e.target.name]: e.target.value,
            userEmail: email, //adding email to data when it will be send to function createTicketEngineering
            userFullName: displayName, //adding FullName to data when it will be send to function createTicketEngineering
            // reset other fields
            Division: '',
            Category: '',
            Action: '',
            Process: '',
            Site: '',
            Description: '',
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        })
    }
    const handleChangeDivision = (e) => {
        setTicketForm({
            ...ticketForm,
            [e.target.name]: e.target.value,
            Category: '',
            Action: '',
            Process: '',
            // Site: '',
            // Department: '',
            // Description: '',
        })
    }
    const handleChange = (e) => {
        setTicketForm({
            ...ticketForm,
            [e.target.name]: e.target.value,
        })
    }
    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onerror = error => reject(error);
    });
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        // var attachments = e.target.files;
        // var filesToSend = [e.target.files[0]];
        // const filesToSend = [...files];
        var maxSize = 25000000
        var calculatedSize = 0
        var filesToSend = [...ticketForm.files];
        var filesToStore = [...ticketForm.filesToStoreInDB];
        [...files].forEach(file => {
            console.log(file.type)
            if (file.type.toLowerCase().includes("audio/") ||
                file.type.toLowerCase().includes("video/") ||
                file.type.toLowerCase().includes("image/") ||
                file.type.toLowerCase().includes("application/pdf") ||
                file.type.toLowerCase().includes("application/vnd") || //for all office documents
                file.type.toLowerCase().includes("text/")) { //text/csv text/hml
                // storage
                //     .ref('ticketAttachments/' + '01Hk2AQqOOoHjy2LBXql/' + file.name)
                //     .put(file)
                // console.log(file)
                filesToStore.push(file)
                var convertResult = ''
                calculatedSize += file.size

                toBase64(file)
                    .then(result => {
                        convertResult = result
                        filesToSend.push({
                            content: convertResult,
                            filename: file.name,
                            type: file.type,
                            disposition: "attachment"
                        })
                        // console.log(calculatedSize)
                        if (ticketForm.filesSize >= maxSize || calculatedSize >= maxSize) {
                            setSnackbar({
                                open: true,
                                severity: 'warning',
                                message: 'Selected files are too big to attach.',
                            })
                        }
                        else {
                            if (ticketForm.filesSize + calculatedSize >= maxSize) {
                                setSnackbar({
                                    open: true,
                                    severity: 'warning',
                                    message: 'Selected files are too big to attach.',
                                })
                            }
                            else {
                                setTicketForm({
                                    ...ticketForm,
                                    files: filesToSend,
                                    filesSize: ticketForm.filesSize + calculatedSize,
                                    filesToStoreInDB: filesToStore,
                                });
                                // console.log(filesToSend)
                            }
                        }
                    })
            } else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'File type not supported, please attach supported file: "audio/*, video/*, image/*, .pdf, .xlsx, .xls, .csv"',
                });
            }
        })
        e.target.value = null // to allow selecting the same file over and over
    }
    const handleChangeFilePickerRemove = (e) => {
        setTicketForm({
            ...ticketForm,
            files: [],
            filesSize: 0,
            filesToStoreInDB: [],
        });
    }

    // Function to get the map based on Division value
    const getCategoryMap = (division) => {
        return categoryMaps[division] || empty;
    }
    const getActionMap = (division) => {
        return actionMaps[division] || empty;
    }
    const getProcessMap = (division) => {
        return processMaps[division] || empty;
    }

    const clearStateAndFields = () => {

        setTicketForm(defaultTicketFrom);
        console.log("resetting form");
    }
    const autoAssignENG = () => {
        let assignThisENG = ''
        switch (ticketForm.Division) {
            case 'Dell-DVE':
                if (ticketForm.Category === 'Process') { assignThisENG = 'Declan Maguire' }
                if (ticketForm.Category === 'Test') { assignThisENG = 'Dermot Moane' }
                break;
            case 'WiseCare':
                if (ticketForm.Category === 'Process') { assignThisENG = 'Declan Maguire' }
                if (ticketForm.Category === 'Test') { assignThisENG = 'Dermot Moane' }
                break;
            case 'WISE':
                if (ticketForm.Category === 'Process') { assignThisENG = 'Gerard Leen' }
                if (ticketForm.Category === 'Test') { assignThisENG = 'John Morrison' }
                break;
            case 'ITAD':
                if (ticketForm.Category === 'Process') { assignThisENG = 'Donnacha McEnery' }
                if (ticketForm.Category === 'Test') { assignThisENG = 'John Murphy' }
                if (ticketForm.Category === 'License Request') { assignThisENG = 'John Murphy' }
                if (ticketForm.Category === 'Software Installation') { assignThisENG = 'John Murphy' }
                if (ticketForm.Category === 'Shred Video Request') { assignThisENG = 'John Murphy' }
                break;
            case 'AVR':
                if (ticketForm.Category === 'Process') { assignThisENG = 'Donnacha McEnery' }
                if (ticketForm.Category === 'Shred Video Request') { assignThisENG = 'John Murphy' }
                break;
            case 'Forcepoint':
                if (ticketForm.Category === 'Process') { assignThisENG = 'Gerard Leen' }
                if (ticketForm.Category === 'Test') { assignThisENG = 'John Murphy' }
                break;
            case 'Dell-RLO':
                if (ticketForm.Category === 'Process') { assignThisENG = 'Declan Maguire' }
                if (ticketForm.Category === 'Test') { assignThisENG = 'Dermot Moane' }
                if (ticketForm.Category === 'Cumulus') { assignThisENG = 'Dermot Moane' }
                break;
            case 'WiseWork':
                if (ticketForm.Category === 'Process') { assignThisENG = 'Donnacha McEnery' }
                if (ticketForm.Category === 'Test') { assignThisENG = 'Donnacha McEnery' }
                break;
            case 'Dell-CS':
                if (ticketForm.Category === 'Process') { assignThisENG = 'Declan Maguire' }
                break;

            default:
                break;
        }
        return assignThisENG
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isSubmitting) {
            setIsSubmitting(true)
            console.log('Trying to submit form...');
            if (ticketForm.Category !== '' &&
                ticketForm.Division !== '' &&
                // ticketForm.Action !== '' &&  // Action field is not mandatory
                ticketForm.Process !== '' &&
                ticketForm.Site !== '' &&
                ticketForm.Description !== '') {
                // run auto assign function
                ticketForm.AssignedEng = autoAssignENG()
                // console.log(ticketForm)
                createTicketEngineering(ticketForm)
                    .then(success => {
                        console.log('added new engineering issue ticket')
                        //display success
                        setSnackbar({
                            open: true,
                            severity: 'success',
                            message: success,
                        });
                        clearStateAndFields();
                        setIsSubmitting(false)
                    })
                    .catch(error => {
                        console.log(error)
                        //display error
                        setSnackbar({
                            open: true,
                            severity: 'error',
                            message: error,
                        });
                        setIsSubmitting(false)
                    })
            }
            else {
                //display error
                setSnackbar({
                    open: true,
                    severity: 'warning',
                    message: '* Please select all required fields and add description.',
                })
                setIsSubmitting(false)
            }
        }
    }

    return (
        <div>
            <form id="ticketFormIT" onSubmit={handleSubmit}>
                <h3 className={classes.center}>Create New Engineering Ticket</h3>


                <Container maxwidth="xs">
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} >
                        <Grid container item xs={12} sm={4} spacing={1} >

                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                fullWidth
                                required
                                id="TicketType"
                                label="Engineering Ticket Type"
                                name="TicketType"
                                className={classes.margin15}
                                inputProps={{ maxLength: 50 }}
                                value={ticketForm.TicketType}
                                // InputLabelProps={{ shrink: true }}
                                onChange={handleChangeTicketTypeEngineering}>
                                {ticketTypeENG.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Container>

                {ticketForm.TicketType === 'Issue / Error' ?
                    <Container maxwidth="xs">

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Division"
                                    label="Division"
                                    name="Division"
                                    value={ticketForm.Division}
                                    onChange={handleChangeDivision} >
                                    {division.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Category"
                                    label="Category"
                                    name="Category"
                                    value={ticketForm.Category}
                                    onChange={handleChange} >
                                    {getCategoryMap(ticketForm.Division).map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    // required
                                    fullWidth
                                    id="Action"
                                    label="Action"
                                    name="Action"
                                    value={ticketForm.Action}
                                    onChange={handleChange} >
                                    {getActionMap(ticketForm.Division).map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Process"
                                    label="Process"
                                    name="Process"
                                    value={ticketForm.Process}
                                    onChange={handleChange} >
                                    {getProcessMap(ticketForm.Division).map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Site"
                                    label="Site"
                                    name="Site"
                                    value={ticketForm.Site}
                                    onChange={handleChange}   >
                                    {site.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>

                            </Grid>

                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    name="Description"
                                    fullWidth
                                    required
                                    multiline
                                    minRows={4}
                                    label="Issue Description"
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={ticketForm.Description}
                                    inputProps={{ maxLength: 500 }}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            className={classes.buttonRight}
                            variant="contained"
                            color="primary">
                            Create ticket & send email
                        </Button>

                        <div className={classes.filePicker}>
                            <input
                                accept="audio/*,video/*,image/*,.xlsx,.xls"
                                className={classes.input}
                                id="add-contained-button-file"
                                multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="add-contained-button-file">
                                <Button
                                    className={classes.marginRight}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Files
                                </Button>
                            </label>
                            <label htmlFor="remove-contained-button-file">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component="span"
                                    startIcon={<DeleteOutlineIcon />}
                                    onClick={handleChangeFilePickerRemove}>
                                    Remove Files
                                </Button>
                            </label>
                            {ticketForm.files.length === 0 ? null : <p>Files attached: {ticketForm.files.length}</p>}
                        </div>

                    </Container> : null}

                {ticketForm.TicketType === 'Change Request' ?
                    <Container maxwidth="xs">

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Division"
                                    label="Division"
                                    name="Division"
                                    value={ticketForm.Division}
                                    onChange={handleChangeDivision}   >
                                    {division.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Category"
                                    label="Category"
                                    name="Category"
                                    value={ticketForm.Category}
                                    onChange={handleChange}   >
                                    {getCategoryMap(ticketForm.Division).map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    // required
                                    fullWidth
                                    id="Action"
                                    label="Action"
                                    name="Action"
                                    value={ticketForm.Action}
                                    onChange={handleChange} >
                                    {getActionMap(ticketForm.Division).map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Process"
                                    label="Process"
                                    name="Process"
                                    value={ticketForm.Process}
                                    onChange={handleChange} >
                                    {getProcessMap(ticketForm.Division).map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="Site"
                                    label="Site"
                                    name="Site"
                                    value={ticketForm.Site}
                                    onChange={handleChange}   >
                                    {site.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid container item xs={12} sm={5} spacing={1}>

                            </Grid>

                            <Grid container item xs={12} sm={10} spacing={1}>
                                <TextField
                                    name="Description"
                                    multiline
                                    fullWidth
                                    required
                                    minRows="4"
                                    label="Request Description"
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={ticketForm.Description}
                                    inputProps={{ maxLength: 500 }}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            className={classes.buttonRight}
                            variant="contained"
                            color="primary">
                            Create ticket & send email
                        </Button>

                        <div className={classes.filePicker}>
                            <input
                                // accept="image/*"
                                accept="audio/*,video/*,image/*,.xlsx,.xls"
                                className={classes.input}
                                id="add-contained-button-file"
                                multiple
                                type="file"
                                onChange={handleChangeFilePicker}
                            />
                            <label htmlFor="add-contained-button-file">
                                <Button
                                    className={classes.marginRight}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    startIcon={<AttachFileIcon />}>
                                    Add Files
                                </Button>
                            </label>
                            <label htmlFor="remove-contained-button-file">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component="span"
                                    startIcon={<DeleteOutlineIcon />}
                                    onClick={handleChangeFilePickerRemove}>
                                    Remove Files
                                </Button>
                            </label>
                            {ticketForm.files.length === 0 ? null : <p>Files attached: {ticketForm.files.length}</p>}
                        </div>

                    </Container> : null}

            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div >
    )
}