import React from 'react';
import { makeStyles } from '@material-ui/core';
import benefitNight from '../../../../images/benefitNight.png'
import StepChallenge from './StepChallenge';
import ImageDisplayCarousel from '../../../customComponents/ImageDisplayCarousel';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    margin50: {
        marginLeft: '50px',
        marginRight: '50px'
    },
    textCentered: {
        textAlign: 'center'
    },
    mediaPoster: {
        width: '50%',
        borderRadius: 20,
    },

}));



export default function BenefitNightUkraine(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <div className={classes.margin50}>
                <p >Dear Colleagues,</p>

                <p >We have all seen the horrific events that have been unfolding in Ukraine. In light of everyone returning to the workplace we thought it would be timely to organise a fundraising event so we can support this cause and also connect with our colleagues. We are delighted to announce that the <b>Wisetek Staff Benefit Night</b> will take place on <b>Saturday the 9th of April in the Clayton Hotel, Lapps Quay</b>. We encourage you to invite your friends and family to what is sure to be a great night for an extremely worthy cause, tickets will be on sale next week.</p>

                <p>As a lead up to the event and a way to generate sponsorship, we will be ‘Walking from Cork to Ukraine’ (figuratively of course!).</p>
                <section>
                    <b>Step Challenge</b>
                    <ul>
                        <li>Cork to Kyiv Ukraine is ~3000km</li>
                        <li>Steps Equivalent is ~4,000,000</li>
                        <li>Walking Challenge starting on St. Patrick’s day,  Thursday, 17 March to Friday 8th April (23days)</li>
                        <li>Goal - each person to walk 10,000 steps per day (230,000 in total over the 23days)</li>
                    </ul>
                </section>
                <section>
                    <b>How to take part</b>
                    <ol>
                        <li>Collect a sponsorship card from one of the following people
                            <p></p>
                            <table style={{
                                border: '1px solid #dddddd',
                                textAlign: 'center',
                                padding: '0px',
                                borderCollapse: 'collapse'

                            }}>
                                <thead>
                                    <tr >
                                        <th style={{ textAlign: 'center', paddingLeft: '100px', paddingRight: '100px' }}>Name</th>
                                        <th style={{ textAlign: 'center', paddingLeft: '100px', paddingRight: '100px' }}>Building</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td style={{ border: '1px solid #dddddd' }}>Ian Murphy</td>
                                        <td style={{ border: '1px solid #dddddd' }}>Dublin Hill HQ Staff</td>
                                    </tr>
                                    <tr >
                                        <td style={{ border: '1px solid #dddddd' }}>Conor Murray</td>
                                        <td style={{ border: '1px solid #dddddd' }}>Dublin Hill HQ Production</td>
                                    </tr>
                                    <tr >
                                        <td style={{ border: '1px solid #dddddd' }}>Roger Donegan</td>
                                        <td style={{ border: '1px solid #dddddd' }}>K4K5</td>
                                    </tr>
                                    <tr >
                                        <td style={{ border: '1px solid #dddddd' }}>Daniel O'Leary</td>
                                        <td style={{ border: '1px solid #dddddd' }}>Glanmire</td>
                                    </tr>
                                    <tr >
                                        <td style={{ border: '1px solid #dddddd' }}>Barry Darcy</td>
                                        <td style={{ border: '1px solid #dddddd' }}>TDI</td>
                                    </tr>
                                    <tr >
                                        <td style={{ border: '1px solid #dddddd' }}>Christine/Eleanor</td>
                                        <td style={{ border: '1px solid #dddddd' }}>Staff</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p></p>
                        </li>
                        <li>Download the app <b>Stridekick</b> on your smartphone or wearable device</li>
                        <li>Send a friend request to Team Wisetek, <a href="https://link.stridekick.com/join?memberCode=NEP79M" target="_blank" rel="noopener noreferrer">‘Wisetek-IRL’</a></li>
                        <li>Start walking!</li>
                        <li>Return your sponsorship card and money by Friday 1st April</li>
                    </ol>
                </section>
                <p>We will also setup a ‘Cork to Kyiv walking challenge’ leaderboard on our Connect platform & update daily displaying our individual and team cumulative steps of all users on Stridekick friends with ‘Team Wisetek’</p>

                <p>If you have any questions please contact Christine Fitzgerald, Eleanor Lester or Laura Devereux.</p>

                <p>Thank you in advance for your support!</p>
                <br /><br />
                <div className={classes.textCentered}>
                    <img className={classes.mediaPoster} src={benefitNight} alt="benefit night poster" />
                </div>
                <br /><br />

                <StepChallenge />

            </div>

            <ImageDisplayCarousel storageRefString='NewsAndAnnouncements/UkraineNightOut/' headerText='Below you can check how it all went... :)' getDataNow={props.getDataNow} />

        </div>
    )
}