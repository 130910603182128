import React, { useContext, useState } from 'react'
import { CssBaseline, makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import Footer from '../../Footer';
import Loading from '../Loading';
import AddViewManageRoomBookings from './AddViewManageRoomBookings';
import SideMenuV2 from '../SideMenus/SideMenuV2';
import SnackbarComponent from '../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    }
}));


const BookingConsole = (props) => {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });


    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={6} />
                        <div className={classes.main}>

                            {/* <h1>Meeting Room Console</h1> */}

                            <AddViewManageRoomBookings />

                            {/* <ViewRoomBookings />
                            <br />
                            <AddRoomBookings />
                            <br />
                            <ManageRoomBookings /> */}

                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}
export default BookingConsole