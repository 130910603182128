import { Collapse, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MailIcon from '@material-ui/icons/Mail';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BuildIcon from '@material-ui/icons/Build';
import AdjustIcon from '@material-ui/icons/Adjust';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import React, { useContext, useState } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import FaceIcon from '@material-ui/icons/Face';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { AuthContext } from '../../../contexts/AuthContext';

const useStyles = makeStyles(theme => ({
    menuButton: {
        textAlign: 'left'
    },
    menuDrawer: {
        width: '250px',
    },
    fullList: {
        width: 'auto',
    },

}));

export default function SideMenuSignOutOnly() {
    const classes = useStyles();
    const { signOut } = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleDrawer = (side, open, reason) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open === false) {
            if (reason === 'clickaway') {
                setMenuOpen(open);
            }
            return;
        }
        setMenuOpen(open);
    };

    const sideList = side => (
        <div
            className={classes.fullList}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            {/* visible for all members */}
            <List>
                <ListItem button onClick={signOut}><ListItemIcon><ExitToAppIcon /></ListItemIcon>Sign out</ListItem>
            </List>
            <Snackbar />
        </div >
    );

    return (
        <div>
            <BrowserRouter>
                <Button className={classes.menuButton} onClick={toggleDrawer('left', true, 'opening')}>Menu
                    <MoreVertIcon />
                </Button>
                {/* <Button onClick={toggleDrawer('left', true)}>Menu</Button> */}
                {/* <Button onClick={toggleDrawer('right', true)}>Open Right</Button>
                <Button onClick={toggleDrawer('top', true)}>Open Top</Button>
                <Button onClick={toggleDrawer('bottom', true)}>Open Bottom</Button> */}
                <Drawer classes={{ paper: classes.menuDrawer }} open={menuOpen} onClose={toggleDrawer('left', false, 'clickaway')}>
                    {sideList('left')}
                </Drawer>
                {/* <Drawer anchor="top" open={menuOpen.top} onClose={toggleDrawer('top', false)}>
                    {fullList('top')}
                </Drawer>
                <Drawer anchor="bottom" open={menuOpen.bottom} onClose={toggleDrawer('bottom', false)}>
                    {fullList('bottom')}
                </Drawer>
                <Drawer anchor="right" open={menuOpen.right} onClose={toggleDrawer('right', false)}>
                    {sideList('right')}
                </Drawer> */}

            </BrowserRouter>
        </div>
    );
}