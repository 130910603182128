import React, { useContext, useState } from 'react';

import { auth, db, functions } from '../../../config/fbConfig';
import { Button, makeStyles, TextField, Typography, Grid, MenuItem } from '@material-ui/core';
import { DataContext } from '../../../contexts/DataContext';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import { lightGreen } from '@material-ui/core/colors';
import Loading from '../Loading';

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        padding: '30px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    marginOne: {
        margin: theme.spacing(1),
    },
    marginTwo: {
        margin: theme.spacing(2),
    },
    selectTextfields: {
        width: '300px',
        margin: theme.spacing(1)
    },
    generateBtn: {
        margin: theme.spacing(2),
        backgroundColor: lightGreen[500],
        '&:hover': {
            backgroundColor: lightGreen[700],
        }
    },
}));

const locations = [
    { value: 'IE', label: 'IE' },
    { value: 'UK', label: 'UK' },
    { value: 'US', label: 'US' }
];
const departments = [
    { value: 'CS/Logistics/Purchasing', label: 'CS/Logistics/Purchasing' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Finance', label: 'Finance' },
    { value: 'HR', label: 'HR' },
    { value: 'IS&T', label: 'IS&T' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Operations', label: 'Operations' },
    { value: 'Quality and Compliance', label: 'Quality and Compliance' },
    { value: 'Sales', label: 'Sales' }
];
const managers = [
    // { value: '', label: '' },
    { value: 'CS/Logistics/Purchasing', label: 'CS/Logistics/Purchasing' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Finance', label: 'Finance' },
    { value: 'HR', label: 'HR' },
    { value: 'IS&T', label: 'IS&T' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Operations', label: 'Operations' },
    { value: 'Quality and Compliance', label: 'Quality and Compliance' },
    { value: 'Sales', label: 'Sales' }
];

export default function NoEmailUser() {
    const classes = useStyles();

    const { listLocations, listDepartments, listManagers } = useContext(DataContext)

    const [isSubmittingCreateForm, setIsSubmittingCreateForm] = useState(false)
    const [isSubmittingResetForm, setIsSubmittingResetForm] = useState(false)

    const [noEmailUser, setNoEmailUser] = useState({
        email: '',
        emailVerified: true,
        noEmail: '',
        outsideEmail: '',
        password: '',
        displayName: '',
        // role: 'member',
        roles: ['member'],
        firstName: '',
        lastName: '',
        initials: '',
        department: 'Operations',
        availableDays: 0,
        availableDays2022: 0,
        accumulatedApprovedDays: 0,
        accumulatedRemainingDays: 0,
        accumulatedUnpaidLeaveTaken: 0,
        shiftWorker: false,
        location: 'IE',
        userEmail: '',
        holidayApprovingManager: ''
    })
    const [usersNewPassword, setUsersNewPassword] = useState({
        uid: '',
        pw: ''
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });


    const handleChange = (e) => {
        setNoEmailUser({
            ...noEmailUser,
            [e.target.name]: e.target.value,
        })
    }
    const handleChangePwText = (e) => {
        setUsersNewPassword({
            ...usersNewPassword,
            [e.target.name]: e.target.value
        })
    }
    function clearCreateUserForm() {
        setNoEmailUser({
            ...noEmailUser,
            email: '',
            emailVerified: true,
            noEmail: '',
            outsideEmail: '',
            password: '',
            displayName: '',
            // role: 'member',
            roles: ['member'],
            firstName: '',
            lastName: '',
            initials: '',
            department: 'Operations',
            availableDays: 0,
            availableDays2022: 0,
            accumulatedApprovedDays: 0,
            accumulatedRemainingDays: 0,
            accumulatedUnpaidLeaveTaken: 0,
            shiftWorker: false,
            location: 'IE',
            userEmail: '',
            holidayApprovingManager: ''
        })
    }
    function clearResetPasswordForm() {
        setUsersNewPassword({
            uid: '',
            pw: ''
        })
    }
    function createUser(userToCreate) {
        const callable = functions.httpsCallable('createUserAsAdmin');

        callable(userToCreate, auth)
            .then(success => {
                // const data = success.data
                // const msg = data.text
                // console.log(success)
                // console.log(msg)
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success.data.text,
                })
                clearCreateUserForm()
                setIsSubmittingCreateForm(false)
            })
            .catch(err => {
                console.log(err)
                // console.log(err.code)
                // console.log(err.message)
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: err.message,
                })
                setIsSubmittingCreateForm(false)
            })

    }
    function hasWhiteSpace(s) {
        return /\s/g.test(s);
    }
    function handleResetUserPassword(e) {
        e.preventDefault()
        if (!isSubmittingResetForm) {
            setIsSubmittingResetForm(true)
            const callable = functions.httpsCallable('resetNoEmailUserPassword')
            console.log(hasWhiteSpace(usersNewPassword.pw))
            if (usersNewPassword.pw.length < 10 || hasWhiteSpace(usersNewPassword.pw) === true) {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: "New password can't contain white spaces and has to be at least ten characters long..."
                })
                setIsSubmittingResetForm(false)
                return
            }
            if (usersNewPassword.uid === '') {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: "UID needed to reset  password."
                })
                setIsSubmittingResetForm(false)
                return
            }
            callable(usersNewPassword, auth)
                .then(success => {
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: success.data.text,
                    })
                    clearResetPasswordForm()
                    setIsSubmittingResetForm(false)
                })
                .catch(err => {
                    // console.log(err)
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: err.message,
                    })
                    setIsSubmittingResetForm(false)
                })
        }
    }

    function getRandomChar(charset) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        return charset.charAt(randomIndex);
    }
    function generateRandomPassword() {
        const length = 10
        const lowercaseCharset = "abcdefghijklmnopqrstuvwxyz"
        const uppercaseCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        const numberCharset = "0123456789"

        // Ensure at least one character from each character set
        const lowercaseChar = getRandomChar(lowercaseCharset)
        const uppercaseChar = getRandomChar(uppercaseCharset)
        const numberChar = getRandomChar(numberCharset)

        // Fill the rest of the password with random characters
        const remainingChars = length - 3
        const allCharset = lowercaseCharset + uppercaseCharset + numberCharset
        let password = lowercaseChar + uppercaseChar + numberChar

        for (let i = 0; i < remainingChars; i++) {
            const randomIndex = Math.floor(Math.random() * allCharset.length)
            password += allCharset.charAt(randomIndex)
        }

        return password
    }

    const handleGeneratePassword = (eTargetName) => {
        // console.log('eTargetName', eTargetName)

        let newPassword = generateRandomPassword()
        if (eTargetName === 'createForm') {
            let newUsername = ''
            if (noEmailUser.firstName && noEmailUser.lastName) {
                newUsername = `${noEmailUser.firstName.toLowerCase()}${noEmailUser.lastName.charAt(0)}`
            }
            setNoEmailUser({ ...noEmailUser, noEmail: newUsername, password: newPassword })

        }
        if (eTargetName === 'resetForm') {
            setUsersNewPassword({ ...usersNewPassword, pw: newPassword })
        }
    }

    function CapitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!isSubmittingCreateForm) {
            setIsSubmittingCreateForm(true)

            if (noEmailUser.password.length < 10 || hasWhiteSpace(noEmailUser.password) === true) {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: "Password has to be at least ten characters long and can't contain white spaces..."
                })
                setIsSubmittingCreateForm(false)
            }
            else {
                var calcEmail = (noEmailUser.noEmail + '@noemail.wisetek.net').toLowerCase();//aka noEmail in textfield bellow+@...
                if (noEmailUser.outsideEmail === '') {
                    setSnackbar({
                        open: true,
                        severity: 'info',
                        message: 'Dummy email will be created for that user.'
                    })
                }
                else {
                    calcEmail = noEmailUser.outsideEmail.toLowerCase()
                }
                var emailUser = {};
                emailUser.email = calcEmail
                emailUser.emailVerified = true;
                emailUser.password = noEmailUser.password;
                emailUser.displayName = CapitalizeFirstLetter(noEmailUser.firstName) + ' ' + CapitalizeFirstLetter(noEmailUser.lastName);
                // emailUser.role = 'member';
                emailUser.roles = ['member'];
                emailUser.firstName = CapitalizeFirstLetter(noEmailUser.firstName);
                emailUser.lastName = CapitalizeFirstLetter(noEmailUser.lastName);
                emailUser.initials = (noEmailUser.firstName[0] + noEmailUser.lastName[0]).toUpperCase();
                emailUser.department = noEmailUser.department;
                emailUser.availableDays = 0;
                emailUser.accumulatedApprovedDays = 0;
                emailUser.accumulatedRemainingDays = 0;
                emailUser.accumulatedUnpaidLeaveTaken = 0;
                emailUser.shiftWorker = false;
                emailUser.location = noEmailUser.location;
                emailUser.userEmail = calcEmail
                emailUser.holidayApprovingManager = noEmailUser.holidayApprovingManager;

                console.log('emailUser', emailUser)
                createUser(emailUser)
            }
        }
    }

    return (
        <div>
            <Typography variant="h6" className={classes.center}>
                Create user without an email
            </Typography>
            <Typography variant="caption" className={classes.center}>
                Create user without an email, just with username and password. In the background the username will be replaced with dummy email @noemail.wisetek.net.
                Username is generated from first name and first letter of last name, in case that already exists error will be shown.
                Modified 07 June 2023 to create optional email outside of Wisetek if no email provided then use @noemail.wisetek.net
            </Typography>
            <form id="creatNoEmailUser" onSubmit={handleSubmit} >
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <br />
                    {isSubmittingCreateForm ? <Loading /> : null}
                    <br />
                    <TextField
                        className={classes.selectTextfields}
                        size="small"
                        name="firstName"
                        id="firstName"
                        label="First Name"
                        variant="outlined"
                        autoComplete="off"
                        value={noEmailUser.firstName}
                        required
                        inputProps={{ maxLength: 80 }}
                        onChange={handleChange}
                    />

                    <TextField
                        className={classes.selectTextfields}
                        size="small"
                        name="lastName"
                        id="lastName"
                        label="Last Name"
                        variant="outlined"
                        autoComplete="off"
                        value={noEmailUser.lastName}
                        required
                        inputProps={{ maxLength: 80 }}
                        onChange={handleChange}
                    />

                    <TextField
                        select
                        className={classes.selectTextfields}
                        size="small"
                        name="location"
                        id="location"
                        label="Location"
                        variant="outlined"
                        autoComplete="off"
                        value={noEmailUser.location}
                        required
                        inputProps={{ maxLength: 80 }}
                        onChange={handleChange}
                    >
                        {listLocations.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        className={classes.selectTextfields}
                        size="small"
                        name="department"
                        id="department"
                        label="Department"
                        variant="outlined"
                        autoComplete="off"
                        value={noEmailUser.department}
                        required
                        inputProps={{ maxLength: 80 }}
                        onChange={handleChange}
                    >
                        {listDepartments.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        className={classes.selectTextfields}
                        size="small"
                        name="holidayApprovingManager"
                        id="holidayApprovingManager"
                        label="Team Manager"
                        variant="outlined"
                        autoComplete="off"
                        value={noEmailUser.holidayApprovingManager}
                        required
                        inputProps={{ maxLength: 80 }}
                        onChange={handleChange}
                    >
                        {listManagers.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        className={classes.selectTextfields}
                        size="small"
                        name="outsideEmail"
                        id="outsideEmail"
                        label="Outside Wisetek Email"
                        variant="outlined"
                        autoComplete="off"
                        helperText='Leave empty if its Wisetek employee without email'
                        value={noEmailUser.outsideEmail}
                        // required
                        inputProps={{ maxLength: 80 }}
                        onChange={handleChange}
                    />

                    <TextField
                        className={classes.selectTextfields}
                        size="small"
                        name="noEmail"
                        id="noEmail"
                        label="Username/noEmail"
                        variant="outlined"
                        autoComplete="off"
                        value={noEmailUser.noEmail}
                        required={noEmailUser.outsideEmail.length > 0 ? false : true}
                        inputProps={{ maxLength: 80 }}
                        onChange={handleChange}
                    />

                    <TextField
                        className={classes.selectTextfields}
                        size="small"
                        name="password"
                        id="password"
                        label="Password"
                        variant="outlined"
                        autoComplete="off"
                        value={noEmailUser.password}
                        required
                        inputProps={{ minLength: 10, maxLength: 80 }}
                        onChange={handleChange}
                    />

                    <Button className={classes.generateBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => handleGeneratePassword('createForm')}>
                        Generate user name and Random Password
                    </Button>

                    <Button className={classes.marginTwo}
                        disabled={isSubmittingCreateForm}
                        type="submit"
                        variant="contained"
                        color="primary">
                        Create no email user
                    </Button>

                </Grid>
            </form>
            <br /><br />
            <Typography variant="h6" className={classes.center}>
                User password reset
            </Typography>
            <Typography variant="caption" className={classes.center}>
                Reset password for users. UID is required and password needs to be at least ten characters.
            </Typography>
            <form id="resetPasswordForm" onSubmit={handleResetUserPassword} >
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <br />

                    <TextField
                        className={classes.selectTextfields}
                        size="small"
                        name="uid"
                        id="uid"
                        label="UID"
                        variant="outlined"
                        autoComplete="off"
                        value={usersNewPassword.uid}
                        required
                        inputProps={{ minLength: 10, maxLength: 80 }}
                        onChange={handleChangePwText}
                    />
                    <TextField
                        className={classes.selectTextfields}
                        size="small"
                        name="pw"
                        id="pw"
                        label="New Password"
                        variant="outlined"
                        autoComplete="off"
                        value={usersNewPassword.pw}
                        required
                        inputProps={{ minLength: 10, maxLength: 80 }}
                        onChange={handleChangePwText}
                    />

                    <Button className={classes.generateBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => handleGeneratePassword('resetForm')}>
                        Generate Random Password
                    </Button>

                    <Button className={classes.marginTwo}
                        disabled={isSubmittingResetForm}
                        type="submit"
                        variant="contained"
                        color="primary">
                        Reset Password
                    </Button>
                </Grid>
            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    );
}