import React, { useState, useContext, useEffect } from 'react'
import { TextField, makeStyles, Button, MenuItem, Grid, List, Tooltip, ListItem, ListItemText } from '@material-ui/core'
import { DataContext } from '../../../../contexts/DataContext'
import { SimpleModal } from '../../ModalSnackbar/SimpleModal';
import { auth, functions } from '../../../../config/fbConfig';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    minWidth: {
        minWidth: '200px'
    },
    body: {
        padding: '50px'
    },
    hidden: {
        display: 'none'
    },
    myForm: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: '35ch',
        },
        paddingLeft: '35px',
        paddingTop: '5px',
        paddingRight: '50px',
        paddingBottom: '35px',
    },
    buttonLeft: {
        float: 'left',
        marginLeft: '24px',
        marginTop: '24px',
        marginBottom: '35px',

    },
    buttonRight: {
        float: 'right',
        marginTop: '24px',
        marginBottom: '35px',

    },
    CardRoot: {
        width: 200,
        margin: '10px',
    },
    CardMedia: {
        height: 100,
        backgroundSize: 'contain',
    },
    marginRight: {
        marginRight: '15px'
    },
    attachments: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)'
        },
        height: '35px',
        background: '#5E89B4',
        color: 'white',
        borderRadius: '5px',
    },
    attList: {
        paddingLeft: '8px',
        paddingRight: '8px',
    }
}))

export default function TicketFacilitiesDetails(props) {
    const classes = useStyles()
    // console.log(props)
    const { updateTicketFacilities } = useContext(DataContext);

    const facilitiesTicketStatus = [
        { value: '', label: '' },
        // { value: 'Aknowledge', label: 'Aknowledge' },
        { value: 'Closed - Not Needed', label: 'Closed - Not Needed' },
        { value: 'Completed', label: 'Completed' },
        { value: 'On Hold', label: 'On Hold' },
        { value: 'Open', label: 'Open' },
        { value: 'Work in progress', label: 'Work in progress' },
    ];
    const assignedTo = [
        { value: '', label: '' },
        { value: 'Bernard Cronin', label: 'Bernard Cronin' },
        { value: 'John Savage', label: 'John Savage' },
        { value: 'Michelle Gallagher', label: 'Michelle Gallagher' },
    ];
    const rootCauseCategories = [
        { value: '', label: '' },
        { value: 'Training Gap', label: 'Training Gap' },
        { value: 'Documentation Gap', label: 'Documentation Gap' },
        { value: 'Production cutin not completed', label: 'Production cutin not completed' },
        { value: 'Process Gap', label: 'Process Gap' },
        { value: 'New Implementation UAT miss', label: 'New Implementation UAT miss' },
        { value: 'User Permission Set/Access setup', label: 'User Permission Set/Access setup' },
        { value: 'Development bug', label: 'Development bug' },
        { value: 'Reporting bug', label: 'Reporting bug' },
        { value: 'System Gap', label: 'System Gap' },
        { value: 'Hardware Component Issue', label: 'Hardware Component Issue' },
        { value: 'Hardware Driver/Software Issue', label: 'Hardware Driver/Software Issue' },
        { value: 'Hardware User Damage', label: 'Hardware User Damage' },
        { value: 'User Knowledge Gap', label: 'User Knowledge Gap' },
    ];
    const fixTypes = [
        { value: '', label: '' },
        { value: 'System CI Required', label: 'System CI Required' },
        { value: 'Process CI Required', label: 'Process CI Required' },
        { value: 'New Report Required', label: 'New Report Required' },
        { value: 'No Fix Required', label: 'No Fix Required' },
        { value: 'Debug', label: 'Debug' },
        { value: 'Training Required', label: 'Training Required' },
        { value: 'Update Documentation', label: 'Update Documentation' },
        { value: 'Repair/Replace hardware', label: 'Repair/Replace hardware' },
        { value: 'Fixed Data & Post', label: 'Fixed Data & Post' },
        { value: 'Updated Access', label: 'Updated Access' },
    ];
    const failureCategories = [
        { value: '', label: '' },
        { value: 'End-User', label: 'End-User' },
        { value: 'System Issue', label: 'System Issue' },
        { value: 'Process Issue', label: 'Process Issue' },
        { value: 'No Issue Found', label: 'No Issue Found' },
        { value: 'System Feature Not Implemented', label: 'System Feature Not Implemented' },
        { value: 'Reporting Issue', label: 'Reporting Issue' },
        { value: 'Report not implemented', label: 'Report not implemented' },
        { value: 'Hardware malfunction', label: 'Hardware malfunction' },
    ];

    const [editValues, setEditValues] = useState({
        AssignedTo: '',
        Category: '',
        Department: '',
        Description: '',
        Division: '',
        FilesURLs: [],
        FixDescription: '',
        RecordUID: '',
        Site: '',
        Status: '',
        TicketType: '',
        WeekNo: 0,
        dateClosed: '',
        dateRaised: '',
        raisedBy: '',
        raisedByFullName: '',

        // AssignedTo: '',
        // RecordUID: '',
        // Status: '',
        RootCauseCategory: '',
        FixType: '',
        FailureCategory: '',
        // FixDescription: '',
        // FilesURLs: [],
    })

    useEffect(() => {
        var filesURLs = []
        if (props.ticketData.FilesURLs.length > 0) {
            filesURLs = props.ticketData.FilesURLs
        }
        setEditValues({
            AssignedTo: props.ticketData.AssignedTo,
            Category: props.ticketData.Category,
            Department: props.ticketData.Department,
            Description: props.ticketData.Description,
            Division: props.ticketData.Division,
            FilesURLs: filesURLs,
            FixDescription: props.ticketData.FixDescription,
            RecordUID: props.ticketData.RecordUID,
            Site: props.ticketData.Site,
            Status: props.ticketData.Status,
            TicketType: props.ticketData.TicketType,
            WeekNo: props.ticketData.WeekNo,
            dateClosed: props.ticketData.dateClosed,
            dateRaised: props.ticketData.dateRaised,
            raisedBy: props.ticketData.raisedBy,
            raisedByFullName: props.ticketData.raisedByFullName,
        })
    }, [props.ticketData])

    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const [modal, setModal] = useState({
        open: false,
        url: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log('Updating Ticket')
        updateTicketFacilities(props.ticketData)
            .then(success => {
                //display success
                console.log('back from db with success')
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                })
            })
            .catch(error => {
                //display error
                console.log('back from db with error')
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error,
                })
            })
    };

    function sendFacilitiesTicketResponse() {
        if (editValues.Status == '' ||
            editValues.AssignedTo == '' ||
            // editValues.RootCauseCategory == '' ||
            // editValues.FixType == '' ||
            // editValues.FailureCategory == '' ||
            editValues.FixDescription == '') {
            setSnackbar({
                open: true,
                severity: 'error',
                message: '* Please fill in all required fields to send response.',
            })
        }
        else {
            console.log('Sending email.')
            const callable = functions.httpsCallable('sendFacilitiesTicketResponse');
            return callable(props.ticketData, auth)
                .then(
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        message: 'Respond sent successfully. User will be notified. Thank you.',
                    })
                )
        }
    }

    const handleChange = (e) => {

        // console.log(e.target.name);
        // console.log(e.target.value);

        props.ticketData[e.target.name] = e.target.value
        setEditValues({
            ...editValues,
            [e.target.name]: e.target.value
        })
    };

    const handleDownload = async (e, file) => {
        e.preventDefault();
        const response = await fetch(file.url);
        if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { success: true };
        }
    }

    return (
        <div>
            <form className={classes.myForm} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <h5>Ticket ID: {props.ticketData.RecordUID}</h5>
                {editValues.FilesURLs.length === undefined || editValues.FilesURLs.length === 0 ? null :
                    <Grid container className={classes.marginLeftRight} direction="row" justify="flex-start" alignItems="flex-start">
                        <h5>Attachments:</h5>
                        {editValues.FilesURLs.map((file, i) => (
                            <List key={i} className={classes.attList}>
                                <Tooltip title="Download">
                                    <ListItem button className={classes.attachments} onClick={e => handleDownload(e, file)}>
                                        <SaveAltIcon className={classes.marginRight} />
                                        <ListItemText primary={file.name} />
                                    </ListItem>
                                </Tooltip>
                            </List>
                        ))}
                    </Grid>
                }
                <TextField name="Category" label="Category" variant="outlined" value={props.ticketData.Category} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="Site" label="Site" variant="outlined" value={props.ticketData.Site} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="Department" label="Department" variant="outlined" value={props.ticketData.Department} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="Division" label="Division" variant="outlined" value={props.ticketData.Division} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="dateClosed" label="Closed Date" variant="outlined" value={props.ticketData.dateClosed} disabled size="small" InputLabelProps={{ shrink: true }} />
                <TextField name="Description" label="Description" fullWidth multiline rows={6} variant="outlined" value={props.ticketData.Description} disabled size="small" InputLabelProps={{ shrink: true }} />

                <TextField
                    select
                    // required
                    name="Status"
                    label="Status"
                    value={editValues.Status}
                    onChange={handleChange}
                    error={editValues.Status === ""}
                    helperText={editValues.Status === "" ? 'Required field to send response.' : ' '}
                    variant="outlined"
                    size='small'
                    InputLabelProps={{ shrink: true }}>
                    {facilitiesTicketStatus.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    // required
                    name="AssignedTo"
                    label="Assigned To"
                    value={editValues.AssignedTo}
                    onChange={handleChange}
                    error={editValues.AssignedTo === ""}
                    helperText={editValues.AssignedTo === "" ? 'Required field to send response.' : ' '}
                    variant="outlined"
                    size='small'
                    InputLabelProps={{ shrink: true }}>
                    {assignedTo.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                {/* <TextField
                    select
                    // required
                    name="RootCauseCategory"
                    label="Root Cause Category"
                    value={editValues.RootCauseCategory}
                    onChange={handleChange}
                    error={editValues.RootCauseCategory === ""}
                    helperText={editValues.RootCauseCategory === "" ? 'Required field to send response.' : ' '}
                    variant="outlined"
                    size='small'
                    InputLabelProps={{ shrink: true }}>
                    {rootCauseCategories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    // required
                    name="FixType"
                    label="Fix Type"
                    value={editValues.FixType}
                    onChange={handleChange}
                    error={editValues.FixType === ""}
                    helperText={editValues.FixType === "" ? 'Required field to send response.' : ' '}
                    variant="outlined"
                    size='small'
                    InputLabelProps={{ shrink: true }}>
                    {fixTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    // required
                    name="FailureCategory"
                    label="Failure Category"
                    value={editValues.FailureCategory}
                    onChange={handleChange}
                    error={editValues.FailureCategory === ""}
                    helperText={editValues.FailureCategory === "" ? 'Required field to send response.' : ' '}
                    variant="outlined"
                    size='small'
                    InputLabelProps={{ shrink: true }}>
                    {failureCategories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField> */}
                <TextField name="FixDescription" label="Fix Description"
                    // required
                    fullWidth
                    multiline
                    rows={6}
                    variant="outlined"
                    value={editValues.FixDescription}
                    onChange={handleChange}
                    error={editValues.FixDescription === ""}
                    helperText={editValues.FixDescription === "" ? 'Required field to send response.' : ' '}
                    size="small"
                    InputLabelProps={{ shrink: true }} />

                <Button
                    type="button"
                    className={classes.buttonLeft}
                    variant="contained"
                    color="secondary"
                    onClick={sendFacilitiesTicketResponse}
                >
                    Send response
                </Button>
                <Button
                    type="submit"
                    className={classes.buttonRight}
                    variant="contained"
                    color="primary"
                >
                    Update ticket
                </Button>
            </form>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

            <SimpleModal modalState={modal} />
        </div >
    )
}