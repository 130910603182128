import React from 'react';
import { makeStyles } from '@material-ui/core';
import careers1 from '../../../../images/WisetekCareers20240409no1.png'
import careers2 from '../../../../images/WisetekCareers20240409no2.png'
import { Link } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        width: '100%',
    },
    textCentered: {
        textAlign: 'center'
    },
    textLeft: {
        textAlign: 'left',
        marginLeft: '18%',
        width: '60%'
    },
    linkStyle: {
        textDecoration: 'none',
    },
    mediaHead: {
        width: '70%',
        borderRadius: '20px 20px 0px 0px',
    },
    media: {
        width: '70%',
        borderRadius: 20,
    },

}));

export default function Careers20240409() {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <header >
                {/* <h2 className={classes.textCentered}>Wisetek - Career opportunities</h2> */}
                {/* <article >
                        <h5 >Dear Colleagues,</h5>
                        <section>
                            Further to the coffee morning which was held on the 8th March 2024.
                            We would like to thank all of you who participated and generously donated to Women's Aid.
                            Your generosity is much appreciated.
                        </section>
                        <br />
                    </article> */}
            </header>
            <div className={classes.textCentered}>
                <img className={classes.mediaHead} src={careers1} alt="career opportunities image one" />
            </div>

            <div className={classes.textLeft}>
                <h5 >Dear Colleagues,</h5>
                <p>The Wisetek HR team is actively seeking to fill a number of vacancies. If you or someone you know is interested, please follow the link to submit CV.</p>
                <br />

                <table style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                    <tbody>
                        <tr style={{ border: '1px solid black' }}>
                            <th style={{ border: '1px solid black', padding: 5 }}>Position</th>
                            <th style={{ border: '1px solid black', padding: 5 }}>Location</th>
                            <th style={{ border: '1px solid black', padding: 5 }}>Type</th>
                            <th style={{ border: '1px solid black', padding: 5 }}>URL Link</th>
                        </tr>
                        <tr>
                            <td style={{ width: '40%', border: '1px solid black', padding: 5 }}>Logistics Manager</td>
                            <td style={{ width: '10%', border: '1px solid black', padding: 5 }}>Cork, IE</td>
                            <td style={{ width: '15%', border: '1px solid black', padding: 5 }}>Permanent</td>
                            <td style={{ width: '35%', border: '1px solid black', padding: 5 }}>
                                <a href='https://api.occupop.com/shared/job/logistics-manager-00976' target="_blank" rel="noopener noreferrer">https://api.occupop.com/shared/job/logistics-manager-00976</a>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '40%', border: '1px solid black', padding: 5 }}>Accounts Assistant</td>
                            <td style={{ width: '10%', border: '1px solid black', padding: 5 }}>Cork, IE</td>
                            <td style={{ width: '15%', border: '1px solid black', padding: 5 }}>Permanent</td>
                            <td style={{ width: '35%', border: '1px solid black', padding: 5 }}>
                                <a href='https://api.occupop.com/shared/job/accounts-assistant-d63c9' target="_blank" rel="noopener noreferrer">https://api.occupop.com/shared/job/accounts-assistant-d63c9</a>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '40%', border: '1px solid black', padding: 5 }}>Customer Service Account Manager</td>
                            <td style={{ width: '10%', border: '1px solid black', padding: 5 }}>Cork, IE</td>
                            <td style={{ width: '15%', border: '1px solid black', padding: 5 }}>Permanent</td>
                            <td style={{ width: '35%', border: '1px solid black', padding: 5 }}>
                                <a href='https://api.occupop.com/shared/job/customer-service-account-manager-70378d' target="_blank" rel="noopener noreferrer">https://api.occupop.com/shared/job/customer-service-account-manager-70378d</a>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: '40%', border: '1px solid black', padding: 5 }}>Commercial Finance Analyst</td>
                            <td style={{ width: '10%', border: '1px solid black', padding: 5 }}>Cork, IE</td>
                            <td style={{ width: '15%', border: '1px solid black', padding: 5 }}>Permanent</td>
                            <td style={{ width: '35%', border: '1px solid black', padding: 5 }}>
                                <a href='https://api.occupop.com/shared/job/commercial-financial-analyst-8f8a9' target="_blank" rel="noopener noreferrer">https://api.occupop.com/shared/job/commercial-financial-analyst-8f8a9</a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />
                <p>You can find updated career opportunities in <Link to='/careers' className={classes.linkStyle}>"Human Resources / Careers"</Link> section.</p>
                <br />
                <br />
            </div>

            <div className={classes.textCentered}>
                <img className={classes.media} src={careers2} alt="career opportunities image two" />
            </div>

            <br />
            <p>Regards,<br />
                Sinead Malone<br />
                HR | Wisetek™<br />
            </p>
            <br />

        </div>
    )
}

