import React, { useState, useEffect, useContext } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { db } from '../../../../config/fbConfig';
import { InputBase, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { AuthContext } from '../../../../contexts/AuthContext';
import TicketCSDetails from './TicketCSDetails';

const useStyles = makeStyles(theme => ({
    leftMargin: {
        marginLeft: '30px',
    },
}))

export default function TicketsCS() {
    const classes = useStyles();

    const { firstName, username } = useContext(AuthContext);

    const [ticketsCS, setTicketsCS] = useState({
        columns: [
            { title: 'Status', field: 'Status', editable: 'never' },
            { title: 'Ticket Type', field: 'TicketType', editable: 'never' }, //filtering: false
            { title: 'Customer', field: 'Customer', editable: 'never' },
            { title: 'Lock Type', field: 'LockType', editable: 'never' },
            { title: 'Site', field: 'Site', editable: 'never' },
            { title: 'TRMA', field: 'TRMA', editable: 'never' },
            { title: 'Assigned CS', field: 'AssignedCS', editable: 'never' },
            { title: 'Raised By', field: 'userFullName', editable: 'never' },
            { title: 'Raised By Email', field: 'userEmail', editable: 'never' },
            { title: 'Raised Date (yyyy/mm/dd)', field: 'dateOpen', type: 'date', editable: 'never', defaultSort: 'desc' },
            { title: 'Closed Date (yyyy/mm/dd)', field: 'dateClosed', type: 'date', editable: 'never' },
            { title: 'Assigned Date (yyyy/mm/dd)', field: 'dateAssigned', type: 'date', editable: 'never' },
            {
                title: 'Description', field: 'Description', editable: 'never', render: rowData =>
                    <InputBase readOnly multiline maxRows={4} value={rowData.Description} style={{ minWidth: 250, fontSize: '1em' }} />,
                // editComponent: props => //console.log(props.rowData)
                //     <TextField multiline maxRows={6}
                //         value={props.rowData.Description}
                //         onChange={(e) => { props.onChange(e.target.value) }}
                //         style={{ minWidth: 400 }} inputProps={{ maxLength: 1000 }} />
            },
            { title: 'Record UID', field: 'RecordUID', editable: 'never', filtering: false },
        ],
        data: [],
        openTickets: 0,
        closedTickets: 0,
    });

    const allTicketsCS = [];
    const allOpenTicketsCS = [];
    const allClosedTicketsCS = [];

    const getAllCSTickets = () => {
        db.collection('ticketsCustomerService')
            // .where('Status', 'in', ['Open', 'WIP - Scope', 'WIP - Dev', 'WIP - UAT', 'WIP - Production Cut in', 'On Hold', 'Acknowledge'])
            .orderBy('dateOpen', 'desc')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    allTicketsCS.push({
                        ...doc.data(),
                        RecordUID: doc.id,
                        dateOpen: moment(doc.data().dateOpen.toDate()).format('YYYY/MM/DD'),
                        dateClosed: moment(doc.data().dateClosed.toDate()).format('YYYY/MM/DD'),
                        dateAssigned: moment(doc.data().dateAssigned.toDate()).format('YYYY/MM/DD'),
                    });
                    if (doc.data().Status === 'Open') {
                        allOpenTicketsCS.push({
                            ...doc.data(),
                            RecordUID: doc.id,
                            dateOpen: moment(doc.data().dateOpen.toDate()).format('YYYY/MM/DD'),
                            dateClosed: moment(doc.data().dateClosed.toDate()).format('YYYY/MM/DD'),
                            dateAssigned: moment(doc.data().dateAssigned.toDate()).format('YYYY/MM/DD'),
                        });
                    }
                    if (doc.data().Status === 'Closed - Passwords/un-enrolment provided' || doc.data().Status === 'Closed - No passwords/un-enrolment provided') {
                        allClosedTicketsCS.push({
                            ...doc.data(),
                            RecordUID: doc.id,
                            dateOpen: moment(doc.data().dateOpen.toDate()).format('YYYY/MM/DD'),
                            dateClosed: moment(doc.data().dateClosed.toDate()).format('YYYY/MM/DD'),
                            dateAssigned: moment(doc.data().dateAssigned.toDate()).format('YYYY/MM/DD'),
                        });
                    }
                })
                setTicketsCS({
                    ...ticketsCS,
                    data: allTicketsCS,
                    openTickets: allOpenTicketsCS.length,
                    closedTickets: allClosedTicketsCS.length,
                })
            })
            .catch(function (error) {
                console.log("Error getting Customer Service tickets: ", error);
            });
    }

    useEffect(() => {
        getAllCSTickets()
        return () => { }
    }, []);

    return (
        <div >

            <MaterialTable
                title={"Customer Service Tickets"}
                columns={ticketsCS.columns}
                data={ticketsCS.data}
                detailPanel={rowData => {
                    // rowData.username = username; // assigning username for email purpose
                    // console.log(rowData)
                    return (
                        <TicketCSDetails ticketData={rowData} refreshTickets={getAllCSTickets} />
                    )
                }}
                options={{
                    thirdSortClick: false, detailPanelType: "single", actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 10, pageSizeOptions: [5, 10, 50, ticketsCS.data.length === 0 ? 100 : { value: ticketsCS.data.length, label: 'All' }]
                }}//, { value: ticketsCS.data.length, label: 'All' }] }}               
                onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                actions={[{
                    icon: 'refresh',
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () => {
                        getAllCSTickets()
                        return () => { }
                    },
                }]}

                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div >
                                <p className={classes.leftMargin}>Hey {firstName}! We need to focus on those {ticketsCS.openTickets} open tickets.</p>
                            </div>
                        </div>
                    ),
                }}
            />
        </div>
    );
}
