import React, { useState, useContext, useEffect } from 'react'
import { auth, db, functions } from '../../../config/fbConfig';
import { DataContext } from '../../../contexts/DataContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Card, makeStyles, TextField, Grid, Button, FormGroup, FormControlLabel, Checkbox, MenuItem, Container, Zoom } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import SnackbarComponent from '../../customComponents/SnackbarComponent';
import surveyVillainVideo from '../../../images/SurveyGreenCommute.mp4'
import surveyHeroImage from '../../../images/SurveyHero.png'
import surveyHeaderImage from '../../../images/CarbonJourney.png'
import MaterialTable from 'material-table';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    videoMedia: {
        width: '60%',
        margin: 100,
        borderRadius: 25
    },
    selectCommuter: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'green',
            }
        },
        width: 300,
        margin: 30,
    },
    formCard: {
        padding: '30px',
    },
    sxForm: {
        maxWidth: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonRight: {
        float: 'right',
        marginTop: '35px',
    },
    textDiv: {
        width: '80%',
        marginTop: 80,
        marginBottom: 40,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    surveyImage: {
        maxWidth: '40%',
    },
    groupSelect: {
        width: '100%',
        // height: 200,
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: 5,
        margin: '0px 5px 25px 5px',
    },
    checkboxLabel: {
        margin: 5,
        width: 180
    }
}));

const commuterTypes = [
    { value: 'Facility', label: 'On-site only' },
    { value: 'Hybrid', label: 'Hybrid (Site & Home)' },
];
const vehicle = [
    { value: 'More then one selected', label: 'More then one from the list...' },
    { value: 'Bus', label: 'Bus' },
    { value: 'Car', label: 'Car' },
    { value: 'Motorbike', label: 'Motorbike' },
    { value: 'Rail', label: 'Rail' },
    { value: 'Taxi', label: 'Taxi' },
    { value: 'Walk', label: 'Walk, Run, Cycle' },
];
const fuelType = [
    { value: 'Petrol', label: 'Petrol' },
    { value: 'Diesel', label: 'Diesel' },
    { value: 'Hybrid', label: 'Hybrid' },
    { value: 'CNG', label: 'CNG' },
    { value: 'LPG', label: 'LPG' },
    { value: 'Battery Electric', label: 'Battery Electric' },
    { value: 'Plug-in Hybrid Electric', label: 'Plug-in Hybrid Electric' },
    { value: 'Plug-in Hybrid Electric (Petrol)', label: 'Plug-in Hybrid Electric (Petrol)' },
];
const countries = [
    { value: 'Ireland', label: 'Ireland' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United States of America', label: 'United States of America' },
    { value: 'Thailand', label: 'Thailand' },
];
const workingTimes = [
    { value: 100, label: '100 % = 40h/week (5 days)' },
    { value: 80, label: '80 % = 32h/week (4 days)' },
    { value: 60, label: '60 % = 24h/week (3 days)' },
    { value: 40, label: '40 % = 16h/week (2 days)' },
    { value: 20, label: '20 % = 8h/week (1 day)' },
];
const wfhPercents = [
    { value: 100, label: '100 % = 5d home' },
    { value: 80, label: '80 % = 4d home' },
    { value: 60, label: '60 % = 3d home' },
    { value: 40, label: '40 % = 2d home' },
    { value: 20, label: '20 % = 1d home' },
];

export default function EmissionsSurvey(props) {

    const classes = useStyles();

    const { submitEmissionsSurvey } = useContext(DataContext);
    const { roles, displayName } = useContext(AuthContext);

    const [survey, setSurvey] = useState({
        FullName: displayName,
        Commuter: '',
        Vehicle: '',
        Bus: false,
        Car: false,
        Motorbike: false,
        Rail: false,
        Taxi: false,
        WalkRunCycle: false,
        Fuel: '',
        TotalDistance: 0,
        Country: '',
        WorkingTime: 100,
        WorkFromHomePercent: 100,
    })

    const [results, setResults] = useState({
        columns: [
            { title: 'Full Name', field: 'FullName', editable: 'never' },
            { title: 'Commuter', field: 'Commuter', editable: 'never' },
            { title: 'Country', field: 'Country', editable: 'never' },
            { title: 'Fuel', field: 'Fuel', editable: 'never' },
            { title: 'Total Distance', field: 'TotalDistance', editable: 'never' },
            { title: 'Vehicle', field: 'Vehicle', editable: 'never' },
            { title: 'Multi Vehicle', field: 'MultiVehicle', editable: 'never' },
            { title: 'WFH Percent', field: 'WorkFromHomePercent', editable: 'never' },
            { title: 'Working Time', field: 'WorkingTime', editable: 'never' },
            { title: 'Date Created', field: 'dateCreated', editable: 'never' },
            { title: 'Submitted By', field: 'submittedBy', editable: 'never' },
            { title: 'Submitted By Email', field: 'submittedByEmail', editable: 'never' },
        ],
        data: []
    })

    const [isDisabled, setIsDisabled] = useState(false)

    const [showVillainZoom, setShowVillainZoom] = useState(false)

    const [showHeroZoom, setShowHeroZoom] = useState(false)

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    useEffect(() => {
        getSurveyResults()
        return () => { }
    }, [])

    function getSurveyResults() {
        db.collection("ehsEmissionsSurvey")
            .orderBy("dateCreated", "desc").get()
            .then(snap => {
                var res = []
                snap.forEach(doc => {
                    res.push({ ...doc.data(), dateCreated: moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY HH:mm:ss') })
                })
                setResults({ ...results, data: res })
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'Commuter':
                setSurvey({
                    ...survey,
                    [e.target.name]: e.target.value,
                    Vehicle: '',
                    Fuel: '',
                    TotalDistance: 0
                })

                setShowHeroZoom(false)
                setShowVillainZoom(false)
                break;

            case 'Vehicle':

                setSurvey({
                    ...survey,
                    [e.target.name]: e.target.value,
                    Bus: false,
                    Car: false,
                    Motorbike: false,
                    Rail: false,
                    Taxi: false,
                    WalkRunCycle: false,
                })
                break;

            default:
                setSurvey({
                    ...survey,
                    [e.target.name]: e.target.value,
                })
                break;
        }
    };
    const handleChangeNumber = (e) => {
        setSurvey({
            ...survey,
            [e.target.name]: e.target.value <= 0 ? 0 : parseInt(e.target.value),
        })
    };
    const handleChangeChecked = (e) => {
        setSurvey({
            ...survey,
            [e.target.name]: e.target.checked
        })
    }

    function clearState() {
        setSurvey({
            FullName: '',
            Commuter: '',
            Vehicle: '',
            Bus: false,
            Car: false,
            Motorbike: false,
            Rail: false,
            Taxi: false,
            WalkRunCycle: false,
            Fuel: '',
            TotalDistance: 0,
            Country: '',
            WorkingTime: 100,
            WorkFromHomePercent: 100,
        })
        setTimeout(() => {
            setIsDisabled(false)
            setShowVillainZoom(false)
            setShowHeroZoom(false)

        }, 8000);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (survey.Vehicle === 'More then one selected' && !survey.Bus && !survey.Car && !survey.Motorbike && !survey.Rail && !survey.Taxi && !survey.WalkRunCycle ||
            survey.Vehicle === 'More then one selected' && survey.Bus && !survey.Car && !survey.Motorbike && !survey.Rail && !survey.Taxi && !survey.WalkRunCycle ||
            survey.Vehicle === 'More then one selected' && !survey.Bus && survey.Car && !survey.Motorbike && !survey.Rail && !survey.Taxi && !survey.WalkRunCycle ||
            survey.Vehicle === 'More then one selected' && !survey.Bus && !survey.Car && survey.Motorbike && !survey.Rail && !survey.Taxi && !survey.WalkRunCycle ||
            survey.Vehicle === 'More then one selected' && !survey.Bus && !survey.Car && !survey.Motorbike && survey.Rail && !survey.Taxi && !survey.WalkRunCycle ||
            survey.Vehicle === 'More then one selected' && !survey.Bus && !survey.Car && !survey.Motorbike && !survey.Rail && survey.Taxi && !survey.WalkRunCycle ||
            survey.Vehicle === 'More then one selected' && !survey.Bus && !survey.Car && !survey.Motorbike && !survey.Rail && !survey.Taxi && survey.WalkRunCycle) {
            return setSnackbar({
                ...snackbar,
                open: true,
                severity: 'warning',
                message: 'Please select at least two types in the vehicle section.',
            })
        }
        if (survey.Vehicle !== 'Walk' && survey.TotalDistance <= 0) {
            return setSnackbar({
                ...snackbar,
                open: true,
                severity: 'warning',
                message: 'Please enter a valid distance',
            })
        }
        if (survey.Vehicle === 'More then one selected') {
            var multiVehicle = ''
            survey.Bus ? multiVehicle += 'Bus, ' : console.log('')
            survey.Car ? multiVehicle += 'Car, ' : console.log('')
            survey.Motorbike ? multiVehicle += 'Motorbike, ' : console.log('')
            survey.Rail ? multiVehicle += 'Rail, ' : console.log('')
            survey.Taxi ? multiVehicle += 'Taxi, ' : console.log('')
            survey.WalkRunCycle ? multiVehicle += 'Walk, Run, Cycle, ' : console.log('')

            multiVehicle = multiVehicle.slice(0, -2)
            console.log(multiVehicle)
            survey.MultiVehicle = multiVehicle
        }

        setIsDisabled(true)
        survey.Vehicle === 'Walk' ? setShowHeroZoom(true) : setShowVillainZoom(true)
        console.log('submitting')
        submitEmissionsSurvey(survey)
            .then(success => {
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: success,
                })
                clearState()
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: err.message,
                })
            })
    }

    return (
        <div>
            <Container component="main" className={classes.main} maxWidth="lg">
                <Card className={classes.formCard} >
                    <h3 className={classes.center}>Employee Carbon Emissions Form</h3>

                    <div className={classes.textDiv}>
                        <h5>Hello All,</h5>
                        <p>Wisetek is on a mission to achieve carbon neutrality by 2030! We are currently working on a project to calculate Wisetek Ireland, UK, USA, and Thailand’s carbon emissions. We would greatly appreciate it if you could fill out this short form to gather employee commuting and home office data.
                            Thank you in advance!</p>
                    </div>

                    <div className={classes.center}>
                        <img src={surveyHeaderImage} className={classes.surveyImage} alt="Carbon Journey" />
                    </div>

                    <div className={classes.textDiv}>
                        <p>Please indicate whether you work on-site or hybrid by using the drop-down arrow in the commuter field below, if you need to calculate the distance you can use <a href='https://www.google.com/maps/dir/' target="_blank" rel="noopener noreferrer">Google Maps</a> to do that.</p>
                    </div>

                    <div>

                        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>

                            <TextField style={{ maxWidth: 500 }}
                                size="small"
                                variant="outlined"
                                // required
                                fullWidth
                                // type="number"
                                // helperText='* to work and from work (1 day)'
                                label="Full Name"
                                name="FullName"
                                inputProps={{ maxLength: 80 }}
                                value={survey.FullName}
                                onChange={handleChange}
                            >
                            </TextField>

                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                label="Commuter"
                                name="Commuter"
                                helperText=' '
                                className={classes.selectCommuter}
                                value={survey.Commuter}
                                onChange={handleChange}
                            >
                                {commuterTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        {survey.Commuter === 'Facility' ?
                            <div>
                                <form id="surveyF" onSubmit={handleSubmit} className={classes.sxForm}>
                                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                        <Grid container item xs={12} sm={5} spacing={1}>
                                            <TextField
                                                select
                                                size="small"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                helperText=' '
                                                label="Working Time %"
                                                name="WorkingTime"
                                                inputProps={{ maxLength: 50 }}
                                                value={survey.WorkingTime}
                                                onChange={handleChange}
                                            >
                                                {workingTimes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid container item xs={12} sm={5} spacing={1}>
                                            <TextField
                                                select
                                                size="small"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="Vehicle"
                                                name="Vehicle"
                                                helperText=' '
                                                value={survey.Vehicle}
                                                onChange={handleChange}
                                            >
                                                {vehicle.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {survey.Vehicle === 'More then one selected' ?
                                            <Grid container item xs={12} sm={10} spacing={1}>
                                                <div className={classes.groupSelect}>
                                                    <FormGroup>
                                                        <Grid container direction='row'>
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Bus} name='Bus' onChange={handleChangeChecked} color='primary' />} label="Bus" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Car} name='Car' onChange={handleChangeChecked} color='primary' />} label="Car" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Motorbike} name='Motorbike' onChange={handleChangeChecked} color='primary' />} label="Motorbike" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Rail} name='Rail' onChange={handleChangeChecked} color='primary' />} label="Rail" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Taxi} name='Taxi' onChange={handleChangeChecked} color='primary' />} label="Taxi" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.WalkRunCycle} name='WalkRunCycle' onChange={handleChangeChecked} color='primary' />} label="Walk Run Cycle" />
                                                        </Grid>
                                                    </FormGroup>
                                                </div>
                                            </Grid>
                                            : null}

                                        {survey.Car || survey.Motorbike || survey.Vehicle === 'Car' || survey.Vehicle === 'Motorbike' ?
                                            <Grid container item xs={12} sm={5} spacing={1}>
                                                <TextField
                                                    select
                                                    size="small"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Fuel"
                                                    name="Fuel"
                                                    helperText=' '
                                                    inputProps={{ maxLength: 50 }}
                                                    value={survey.Fuel}
                                                    onChange={handleChange}
                                                >
                                                    {fuelType.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            : null}

                                        {survey.Vehicle !== 'Walk' ?
                                            <Grid container item xs={12} sm={5} spacing={1}>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    type="number"
                                                    helperText='* to work and from work (1 day)'
                                                    label="Total Distance in Kilometers"
                                                    name="TotalDistance"
                                                    inputProps={{ maxLength: 5 }}
                                                    value={survey.TotalDistance}
                                                    onChange={handleChangeNumber}
                                                >
                                                </TextField>
                                            </Grid>
                                            : null}

                                    </Grid>
                                    {survey.Vehicle !== 'Walk' ?
                                        <div style={{ textAlign: 'center', marginTop: 40 }}>
                                            <p>* Please use <a href='https://www.google.com/maps/dir/' target="_blank" rel="noopener noreferrer">Google Maps</a> to get the total distance</p>
                                        </div>
                                        : null}

                                    <Button
                                        type="submit"
                                        disabled={isDisabled}
                                        className={classes.buttonRight}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                </form>
                            </div>
                            : null}

                        {survey.Commuter === 'Hybrid' ?
                            <div>
                                <form id="surveyH" onSubmit={handleSubmit} className={classes.sxForm}>
                                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>

                                        <Grid container item xs={12} sm={5} spacing={1}>
                                            <TextField
                                                select
                                                size="small"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                helperText=' '
                                                label="Country"
                                                name="Country"
                                                value={survey.Country}
                                                onChange={handleChange}
                                            >
                                                {countries.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid container item xs={12} sm={5} spacing={1}>
                                            <TextField
                                                select
                                                size="small"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                helperText=' '
                                                label="Working Time %"
                                                name="WorkingTime"
                                                inputProps={{ maxLength: 50 }}
                                                value={survey.WorkingTime}
                                                onChange={handleChange}
                                            >
                                                {workingTimes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid container item xs={12} sm={5} spacing={1}>
                                            <TextField
                                                select
                                                size="small"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                helperText=' '
                                                label="Working From Home %"
                                                name="WorkFromHomePercent"
                                                inputProps={{ maxLength: 50 }}
                                                value={survey.WorkFromHomePercent}
                                                onChange={handleChange}
                                            >
                                                {wfhPercents.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {survey.WorkFromHomePercent !== 100 ?
                                            <Grid container item xs={12} sm={5} spacing={1}>
                                                <TextField
                                                    select
                                                    size="small"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    helperText=' '
                                                    label="Vehicle"
                                                    name="Vehicle"
                                                    value={survey.Vehicle}
                                                    onChange={handleChange}
                                                >
                                                    {vehicle.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            : null}

                                        {survey.Vehicle === 'More then one selected' ?
                                            <Grid container item xs={12} sm={10} spacing={1}>
                                                <div className={classes.groupSelect}>
                                                    <FormGroup>
                                                        <Grid container direction='row'>
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Bus} name='Bus' onChange={handleChangeChecked} color='primary' />} label="Bus" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Car} name='Car' onChange={handleChangeChecked} color='primary' />} label="Car" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Motorbike} name='Motorbike' onChange={handleChangeChecked} color='primary' />} label="Motorbike" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Rail} name='Rail' onChange={handleChangeChecked} color='primary' />} label="Rail" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.Taxi} name='Taxi' onChange={handleChangeChecked} color='primary' />} label="Taxi" />
                                                            <FormControlLabel className={classes.checkboxLabel} control={<Checkbox checked={survey.WalkRunCycle} name='WalkRunCycle' onChange={handleChangeChecked} color='primary' />} label="Walk Run Cycle" />
                                                        </Grid>
                                                    </FormGroup>
                                                </div>
                                            </Grid>
                                            : null}

                                        {survey.WorkFromHomePercent !== 100 ?
                                            survey.Car || survey.Motorbike || survey.Vehicle === 'Car' || survey.Vehicle === 'Motorbike' ?
                                                <Grid container item xs={12} sm={5} spacing={1}>
                                                    <TextField
                                                        select
                                                        size="small"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        label="Fuel"
                                                        name="Fuel"
                                                        helperText=' '
                                                        inputProps={{ maxLength: 50 }}
                                                        value={survey.Fuel}
                                                        onChange={handleChange}
                                                    >
                                                        {fuelType.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                : null
                                            : null}

                                        {survey.WorkFromHomePercent !== 100 ?
                                            survey.Vehicle !== 'Walk' ?
                                                <Grid container item xs={12} sm={5} spacing={1} >
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        type="number"
                                                        helperText='* to work and from work (1 day)'
                                                        label="Total Distance in Kilometers"
                                                        name="TotalDistance"
                                                        inputProps={{ maxLength: 5 }}
                                                        value={survey.TotalDistance}
                                                        onChange={handleChangeNumber}
                                                    >
                                                    </TextField>

                                                </Grid>
                                                : null
                                            : null}

                                    </Grid>
                                    {survey.WorkFromHomePercent !== 100 ?
                                        survey.Vehicle !== 'Walk' ?
                                            <div style={{ textAlign: 'center', marginTop: 40 }}>
                                                <p>* Please use <a href='https://www.google.com/maps/dir/' target="_blank" rel="noopener noreferrer">Google Maps</a> to get the total distance</p>
                                            </div>
                                            : null
                                        : null}

                                    <Button
                                        type="submit"
                                        disabled={isDisabled}
                                        className={classes.buttonRight}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                </form>
                            </div>
                            : null}

                    </div>
                    <br /><br />
                    <Zoom in={showHeroZoom} style={{ transitionDelay: showHeroZoom ? '1000ms' : '0ms', display: !showHeroZoom ? 'none' : 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                        <img src={surveyHeroImage} className={classes.videoMedia} alt="Survey hero" />
                    </Zoom>

                    <Zoom in={showVillainZoom} style={{ transitionDelay: showVillainZoom ? '1000ms' : '0ms', display: !showVillainZoom ? 'none' : 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                        <video id='villainVideo' className={classes.videoMedia} src={surveyVillainVideo}
                            type="video/mp4"
                            // poster={cookingPoster}
                            // controls
                            muted
                            autoPlay
                            loop
                            controlsList="nodownload">
                            Your browser does not support HTML video.
                        </video>
                    </Zoom>

                </Card>

                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
            </Container >

            {roles.includes('admin') ?
                <MaterialTable style={{ marginTop: 50 }}
                    title={"Carbon Emissions Results"}
                    columns={results.columns}
                    data={results.data}
                    options={{
                        actionsColumnIndex: -1, exportButton: true, filtering: true, pageSize: 5, pageSizeOptions: [5, 10, 50, results.data.length === 0 ? 100 : { value: results.data.length, label: 'All' }]
                    }}//, { value: ticketsIT.data.length, label: 'All' }] }}
                    actions={
                        [{
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => {
                                getSurveyResults()

                            }
                        }]
                    }
                />
                : null}

        </div>
    )
}