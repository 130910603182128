import React, { useState, useEffect, useContext } from 'react'
import Timeline from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import '../../../customTimeline.css';
import moment from 'moment'
import { makeStyles, Card, Typography, Grid } from '@material-ui/core'
import { db } from '../../../config/fbConfig'
import { AuthContext } from '../../../contexts/AuthContext';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        marginBottom: theme.spacing(2),
    },
    minWidth: {
        minWidth: '200px'
    },
    cardPadding: {
        padding: '30px'
    },
    pending: {
        color: 'rgb(110, 110, 110)', // gray
        backgroundColor: 'rgb(110, 110, 110)', // gray
        borderRadius: '50%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    approved: {
        color: 'rgb(20, 150, 30)',  // green
        backgroundColor: 'rgb(20, 150, 30)',  // green
        borderRadius: '50%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    declined: {
        color: 'rgb(200, 110, 10)', // orange
        backgroundColor: 'rgb(200, 110, 10)', // orange
        borderRadius: '50%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    cancelled: {
        color: 'rgb(190, 30, 20)', // red
        backgroundColor: 'rgb(190, 30, 20)', // red
        borderRadius: '50%',
        marginLeft: '35px',
        marginRight: '10px',
    },
}))

const HolidayCalendarView = (props) => {
    const classes = useStyles();

    const { displayName } = useContext(AuthContext);

    const departmentList = [
        { value: '', label: '' },
        { value: 'CS/Logistics/Purchasing', label: 'CS/Logistics/Purchasing' },
        { value: 'Engineering', label: 'Engineering' },
        { value: 'Finance', label: 'Finance' },
        { value: 'HR', label: 'HR' },
        { value: 'IS&T', label: 'IS&T' },
        { value: 'Marketing', label: 'Marketing' },
        { value: 'Operations', label: 'Operations' },
        { value: 'Quality and Compliance', label: 'Quality and Compliance' },
        { value: 'Sales', label: 'Sales' },
    ];

    const groups = [ // that will be users names
        {
            id: 1,
            title: 'group 1'
        },
        {
            id: 2,
            title: 'group 2'
        },
        // { // all posible options
        //     id: 1,
        //     title: 'group 1',
        //     rightTitle: 'title in the right sidebar',
        //     stackItems: true,
        //     height: 30
        // }
    ]

    const items = [ // that will be holiday start date, end date, in title will be status and color
        {
            id: 1,
            group: 1,
            title: 'item 1',
            start_time: moment('2020/06/26', 'YYYY MM DD').format(),
            end_time: moment('2020/06/26', 'YYYY MM DD').add(23, 'hour').add(59, 'minute').format()
        },
        {
            id: 2,
            group: 2,
            title: 'item 2',
            start_time: moment().add(-0.5, 'hour'),
            end_time: moment().add(0.5, 'hour')
        },
        {
            id: 3,
            group: 1,
            title: 'item 3',
            start_time: moment().add(2, 'hour'),
            end_time: moment().add(3, 'hour')
        },
        // { // all posible options
        //     id: 1,
        //     group: 1,
        //     title: 'Random title',
        //     start_time: 1457902922261,
        //     end_time: 1457902922261 + 86400000,
        //     canMove: true,
        //     canResize: false,
        //     canChangeGroup: false,
        //     itemProps: {
        //         // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
        //         'data-custom-attribute': 'Random content',
        //         'aria-hidden': true,
        //         onDoubleClick: () => { console.log('You clicked double!') },
        //         className: 'weekend',
        //         style: {
        //             background: 'fuchsia'
        //         }
        //     }
        // }
    ]

    const [departmentHolidays, setDepartmentHolidays] = useState({
        // department: '',
        groups: [],
        items: [],
        defaultTimeStart: moment().startOf('month'),
        defaultTimeEnd: moment().endOf('month'),
    })

    const updatedNamesGroup = [];
    const updatedNamesItems = [];

    // const handleChange = (e) => {

    //     db.collection('holidayRequests').where("Department", "==", e.target.value).get()
    //         .then(snapshot => {
    //             snapshot.forEach(doc => {

    //                 if (doc.data().UserId) {
    //                     var userHolidays = {
    //                         id: doc.data().UserId,
    //                         title: doc.data().FullName,
    //                     }
    //                     if (!updatedNamesGroup.some(e => e.id === doc.data().UserId)) {
    //                         updatedNamesGroup.push(userHolidays)
    //                     }

    //                     // updatedNamesGroup.push({
    //                     //     id: doc.id,
    //                     //     title: doc.data().FullName,
    //                     //     // ...doc.data(),
    //                     // });

    //                     var color = ''
    //                     var borderColor = ''
    //                     switch (doc.data().Status) {
    //                         case 'Pending':
    //                             color = 'rgb(110, 110, 110)' // gray
    //                             borderColor = 'rgb(72, 72, 72)' // darker grey
    //                             break;
    //                         case 'Approved':
    //                             color = 'rgb(20, 150, 30)'  // green
    //                             borderColor = 'rgb(15, 115, 15)' // darker green
    //                             break;
    //                         case 'Declined':
    //                             color = 'rgb(200, 110, 10)' // orange
    //                             borderColor = 'rgb(140, 75, 15)' // darker orange
    //                             break;
    //                         case 'Cancelled':
    //                             color = 'rgb(190, 30, 20)' // red
    //                             borderColor = 'rgb(145, 20, 15)' // darker red
    //                             break;

    //                         default:
    //                             break;
    //                     }
    //                     updatedNamesItems.push({
    //                         id: doc.id,
    //                         group: doc.data().UserId,
    //                         title: doc.data().Status,
    //                         start_time: moment(new Date(doc.data().FromDateString)).valueOf(),
    //                         end_time: moment(new Date(doc.data().ToDateString)).add(23, 'hour').add(59, 'minute').valueOf(),
    //                         canMove: false,
    //                         canResize: false,
    //                         canSelect: false,
    //                         canChangeGroup: false,
    //                         itemProps: {
    //                             style: {
    //                                 // color: color,
    //                                 background: color,
    //                                 borderColor: borderColor,
    //                                 paddingLeft: 1,
    //                                 overflow: "hidden",
    //                                 textOverflow: "ellipsis",
    //                                 whiteSpace: "nowrap"
    //                             }
    //                         }
    //                     });
    //                 }
    //             })
    //             setDepartmentHolidays({
    //                 ...departmentHolidays,
    //                 department: e.target.value,
    //                 groups: updatedNamesGroup,
    //                 items: updatedNamesItems,
    //             })
    //             // console.log(updatedNamesItems)
    //         })
    //         .catch(function (error) {
    //             console.log("Error getting department holiday requests: ", error);
    //         })

    // };
    useEffect(() => {
        db.collection('holidayRequests').where("Manager", "==", displayName).get()
            // db.collection('holidayRequests').where("Manager", "==", "Chris O'Neill").get()
            .then(snapshot => {
                snapshot.forEach(doc => {

                    if (doc.data().UserId) {
                        var userHolidays = {
                            id: doc.data().UserId,
                            title: doc.data().FullName,
                        }
                        if (!updatedNamesGroup.some(e => e.id === doc.data().UserId)) {
                            updatedNamesGroup.push(userHolidays)
                        }

                        // updatedNamesGroup.push({
                        //     id: doc.id,
                        //     title: doc.data().FullName,
                        //     // ...doc.data(),
                        // });

                        var color = ''
                        var borderColor = ''
                        switch (doc.data().Status) {
                            case 'Pending':
                                color = 'rgb(110, 110, 110)' // gray
                                borderColor = 'rgb(72, 72, 72)' // darker grey
                                break;
                            case 'Approved':
                                color = 'rgb(20, 150, 30)'  // green
                                borderColor = 'rgb(15, 115, 15)' // darker green
                                break;
                            case 'Declined':
                                color = 'rgb(200, 110, 10)' // orange
                                borderColor = 'rgb(140, 75, 15)' // darker orange
                                break;
                            case 'Cancelled':
                                color = 'rgb(190, 30, 20)' // red
                                borderColor = 'rgb(145, 20, 15)' // darker red
                                break;

                            default:
                                break;
                        }
                        updatedNamesItems.push({
                            id: doc.id,
                            group: doc.data().UserId,
                            title: doc.data().Status,
                            start_time: moment(new Date(doc.data().FromDateString)).valueOf(),
                            end_time: moment(new Date(doc.data().ToDateString)).add(23, 'hour').add(59, 'minute').valueOf(),
                            canMove: false,
                            canResize: false,
                            canSelect: false,
                            canChangeGroup: false,
                            itemProps: {
                                style: {
                                    // color: color,
                                    background: color,
                                    borderColor: borderColor,
                                    paddingLeft: 1,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }
                            }
                        });
                    }
                })
                setDepartmentHolidays({
                    ...departmentHolidays,
                    // department: e.target.value,
                    groups: updatedNamesGroup,
                    items: updatedNamesItems,
                })
                // console.log(updatedNamesItems)
            })
            .catch(function (error) {
                console.log("Error getting department holiday requests: ", error);
            })
        return () => { }
    }, [])

    return (
        <Card className={classes.cardPadding}>
            <Typography className={classes.margin20} variant="h6">
                Holiday Calendar View
            </Typography>

            <br />
            {departmentHolidays.items.length > 0 ?
                <Timeline
                    className={classes.calendarView}
                    groups={departmentHolidays.groups}
                    items={departmentHolidays.items}
                    defaultTimeStart={departmentHolidays.defaultTimeStart}
                    defaultTimeEnd={departmentHolidays.defaultTimeEnd}
                /> : null}
            <br />
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">

                {/* <Grid item xs={12} sm={3}>
                    <TextField
                        select
                        fullWidth
                        size="small"
                        variant="outlined"
                        id="department"
                        label="Please Select Department"
                        name="department"
                        inputProps={{ maxLength: 50 }}
                        value={departmentHolidays.department}
                        onChange={handleChange}
                    // InputLabelProps={{ shrink: true }}
                    >
                        {departmentList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid> */}
                <Grid item container xs={12} sm={6}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">

                    <div className={classes.pending}>col.</div><p>Pending</p>
                    <div className={classes.approved}>col.</div><p>Approved</p>
                    <div className={classes.declined}>col.</div><p>Declined</p>
                    <div className={classes.cancelled}>col.</div><p>Cancelled</p>

                </Grid>
            </Grid>

        </Card>
    )
}

export default HolidayCalendarView