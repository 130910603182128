import React, { useContext, useEffect, useState } from 'react';
import Loading from '../../Loading';
import Footer from '../../../Footer'
import { AuthContext } from '../../../../contexts/AuthContext';
import { makeStyles, CssBaseline, Grid, Typography, LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import ChartistGraph from 'react-chartist'
import Chartist from 'chartist'
import ctPointLabels from 'chartist-plugin-pointlabels'
import moment from 'moment';
import { db } from '../../../../config/fbConfig';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
    marginLeftRight: {
        paddingLeft: '50px',
        paddingRight: '50px',
    },
    marginTopLeftRight: {
        marginTop: '70px',
        paddingLeft: '50px',
        paddingRight: '50px',
    },
    searchProgress: {
        visibility: 'hidden',
        marginBottom: '15px'
    },
    myTable: {
        padding: '0px',
    },
    graphHeader: {
        textAlign: 'center'
    },
    green: {
        width: '20px',
        color: 'rgb(20, 150, 30)',  // green
        backgroundColor: 'rgb(20, 150, 30)',  // green
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    blue: {
        width: '20px',
        color: 'rgb(50, 50, 240)', // blue
        backgroundColor: 'rgb(50, 50, 240)', // blue
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    grey: {
        width: '20px',
        color: 'rgb(110, 110, 110)', // grey
        backgroundColor: 'rgb(110, 110, 110)', // grey
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    yellow: {
        width: '20px',
        color: 'rgb(210, 180, 30)', // yellow
        backgroundColor: 'rgb(210, 180, 30)', // yellow
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    orange: {
        width: '20px',
        color: 'rgb(200, 110, 10)', // orange
        backgroundColor: 'rgb(200, 110, 10)', // orange
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    purple: {
        width: '20px',
        color: 'rgb(130, 10, 200)', // violet
        backgroundColor: 'rgb(130, 10, 200)', // violet
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    red: {
        width: '20px',
        color: 'rgb(190, 30, 20)', // red
        backgroundColor: 'rgb(190, 30, 20)', // red
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    darkPurple: {
        width: '20px',
        color: 'rgb(50, 10, 100)', // dark purple
        backgroundColor: 'rgb(50, 10, 100)', // dark purple
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
}));

export default function ArchieKPIs(props) {

    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);

    const [weekNumbers, setWeekNumbers] = useState([]);

    const [weeklyUptimeGraph, setWeeklyUptimeGraph] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 35,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                })
            ]
        }
    })

    const [dailyUptimeGraph, setDailyUptimeGraph] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                type: Chartist.FixedScaleAxis,
                ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                high: 26,
                low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + 'h' : 0 + 'h' // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleGraphChangeClick = (e) => {

        var location = e.target.id

        switch (location) {
            case 'AU':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'DH':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'K4':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'MD':
                var graph = document.getElementsByClassName('ct-series-d');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'MI':
                var graph = document.getElementsByClassName('ct-series-e');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'SAC02':
                var graph = document.getElementsByClassName('ct-series-f');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'WI':
                var graph = document.getElementsByClassName('ct-series-g');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'UK':
                var graph = document.getElementsByClassName('ct-series-h');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;

            default:
                break;
        }
    }

    function calcWeeklyVars(docs, weekNumbers) {
        var searchProgress = document.getElementById('searchGraphProgress');
        searchProgress.style.visibility = 'visible';

        // var servers = ['SA', 'AU', 'DH', 'K4', 'MD', 'MI', 'SAC02', 'WI', 'UK'];
        var servers = ['AU', 'DH', 'K4', 'MD', 'MI', 'SAC02', 'WI', 'UK'];
        var calcualtedSeries = [
            //weeks 4,3,2,1 
            // [0, 0, 0, 0],       // SA
            [0, 0, 0, 0],       // AU
            [0, 0, 0, 0],       // DH
            [0, 0, 0, 0],       // K4
            [0, 0, 0, 0],       // MD
            [0, 0, 0, 0],       // MI
            [0, 0, 0, 0],       // SAC02
            [0, 0, 0, 0],       // WI
            [0, 0, 0, 0]        // UK
        ]
        docs.forEach(doc => {
            var weekPosition = doc.data().Week - weekNumbers[3]
            var sitePosition = servers.findIndex(server => server === doc.data().Site)
            // console.log(doc.data());
            // console.log(weekPosition)
            // console.log(sitePosition)
            if (sitePosition >= 0) {
                var currentValue = calcualtedSeries[sitePosition][weekPosition]
                var percent = ((doc.data().DowntimeHours > 24 ? 24 : doc.data().DowntimeHours) * 100) / (24 * 7)
                var newValue = currentValue + percent
                calcualtedSeries[sitePosition][weekPosition] = Math.round(newValue * 10) / 10
            }
        })
        // console.log(calcualtedSeries)
        setWeeklyUptimeGraph({
            ...weeklyUptimeGraph,
            data: {
                labels: ['Week ' + weekNumbers[3], 'Week ' + weekNumbers[2], 'Week ' + weekNumbers[1], 'Week ' + weekNumbers[0]],
                series: calcualtedSeries
                // series: [
                //     //   34  35  36  37 
                //     [11, 11, 11, 11],       // AU
                //     [22, 22, 22, 22],       // DH
                //     [33, 33, 33, 33],       // K4
                //     [44, 44, 44, 44],       // MD
                //     [55, 55, 55, 55],       // MI
                //     [66, 66, 66, 66],       // SAC02
                //     [77, 77, 77, 77],       // WI
                //     [88, 88, 88, 88]        // UK
                // ]
            },
        });

        setWeekNumbers(weekNumbers)
        searchProgress.style.visibility = 'hidden';
    }

    const getWeeklyData = () => {

        var weekNumbers = [moment().day(0).isoWeek(), moment().day(-7).isoWeek(), moment().day(-14).isoWeek(), moment().day(-21).isoWeek()]
        var year = moment().year()
        var firstWeek = weekNumbers[0]
        var fourthWeek = weekNumbers[3]

        db.collection("archieUptime")
            .where("Year", "==", year)
            .where("Week", ">=", fourthWeek)
            .where("Week", "<=", firstWeek)
            .orderBy("Week", "asc")
            .orderBy("Site", "asc")
            .get()
            .then(snap => {
                if (snap.empty) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'There was no results for weeks ' + fourthWeek + ' to ' + firstWeek,
                    })
                    return
                }
                else {
                    console.log('Going to db for data on weeks ' + fourthWeek + ' to ' + firstWeek)
                    calcWeeklyVars(snap.docs, weekNumbers)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    function calcDailyVars(docs) {
        var servers = ['AU', 'DH', 'K4', 'MD', 'MI', 'SAC02', 'WI', 'UK'];
        var weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        var calcualtedSeries = [
            //   Mon -> Sun
            [0, 0, 0, 0, 0, 0, 0],      // AU
            [0, 0, 0, 0, 0, 0, 0],      // DH
            [0, 0, 0, 0, 0, 0, 0],      // K4
            [0, 0, 0, 0, 0, 0, 0],      // MD
            [0, 0, 0, 0, 0, 0, 0],      // MI
            [0, 0, 0, 0, 0, 0, 0],      // SAC02
            [0, 0, 0, 0, 0, 0, 0],      // WI
            [0, 0, 0, 0, 0, 0, 0]       // UK
        ]
        docs.forEach(doc => {
            var dayPosition = weekDays.findIndex(day => day === doc.data().Weekday)
            var sitePosition = servers.findIndex(server => server === doc.data().Site)
            // console.log(doc.data());
            // console.log(dayPosition);
            // console.log(sitePosition);
            if (sitePosition >= 0) {

                calcualtedSeries[sitePosition][dayPosition] = doc.data().DowntimeHours > 24 ? 24 : Math.round(doc.data().DowntimeHours * 100) / 100
            }
        })
        // console.log(calcualtedSeries)
        setDailyUptimeGraph({
            ...dailyUptimeGraph,
            data: {
                labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                series: calcualtedSeries
                // series: [
                //     [11, 11, 11, 11, 11, 11, 11],   // AU
                //     [22, 33, 44, 55, 66, 77, 88],   // DH
                //     [33, 44, 55, 66, 77, 88, 99],   // K4
                //     [0, 11, 22, 33, 44, 55, 66],    // MD
                //     [99, 88, 77, 66, 55, 44, 33],   // MI
                //     [88, 77, 66, 55, 44, 33, 22],   // SAC02
                //     [77, 66, 55, 44, 33, 22, 11],   // WI
                //     [66, 55, 44, 33, 22, 11, 0]     // UK
                // ]
            }
        });
    }

    const getDailyData = () => {
        var weekNumbers = [moment().day(0).isoWeek(), moment().day(-7).isoWeek(), moment().day(-14).isoWeek(), moment().day(-21).isoWeek()]
        var thisWeek = weekNumbers[0] + 1
        var year = moment().year()

        db.collection("archieUptime")
            .where("Year", "==", year)
            .where("Week", "==", thisWeek)
            .orderBy("Site", "asc")
            .get()
            .then(snap => {
                if (snap.empty) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'There was no results for week ' + thisWeek,
                    })
                    return
                }
                else {
                    console.log('Going to db for data on week ' + thisWeek)
                    calcDailyVars(snap.docs, thisWeek)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }



    useEffect(() => {
        getWeeklyData();
        getDailyData();
        return () => {
        }
    }, [])

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated) {
            return <Redirect to='/' />
        }
        else {
            if (roles.includes('adminISandT') || roles.includes('admin')) {
                return (
                    <div className={classes.root}>
                        <CssBaseline>
                            <SideMenuV2 selectedIndex={29} openMetricsColapse={true} openMetricsOperationsColapse={true} />
                            <div className={classes.main}>

                                {/* <h1>Operations - Archie KPI's</h1> */}

                                <LinearProgress id='searchGraphProgress' className={classes.searchProgress} />
                                <Grid item xs={12} className={classes.marginLeftRight}>
                                    <Typography className={classes.graphHeader} variant="h6">
                                        ARCHIE Connection - Weekly downtime percentage
                                    </Typography>

                                    <ChartistGraph
                                        id='archieUptime'
                                        data={weeklyUptimeGraph.data}
                                        type={weeklyUptimeGraph.type}
                                        options={weeklyUptimeGraph.options}
                                        // listener={weeklyUptimeGraph.animation}
                                        plugins={weeklyUptimeGraph.plugins}
                                    />
                                    <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="center">

                                        <div className={classes.green}>.</div><p>AU</p>
                                        <div className={classes.blue}>.</div><p>DH</p>
                                        <div className={classes.grey}>.</div><p>K4</p>
                                        <div className={classes.yellow}>.</div><p>MD</p>
                                        <div className={classes.orange}>.</div><p>MI</p>
                                        <div className={classes.purple}>.</div><p>SAC02</p>
                                        <div className={classes.red}>.</div><p>WI</p>
                                        <div className={classes.darkPurple}>.</div><p>UK</p>

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={classes.marginTopLeftRight}>
                                    <Typography className={classes.graphHeader} variant="h6">
                                        Daily downtime hours for week {weekNumbers[0] + 1} - current week
                                    </Typography>

                                    <ChartistGraph
                                        id='dailyData'
                                        data={dailyUptimeGraph.data}
                                        type={dailyUptimeGraph.type}
                                        options={dailyUptimeGraph.options}
                                        listener={dailyUptimeGraph.animation}
                                        plugins={dailyUptimeGraph.plugins}
                                    />
                                    <Grid container direction="row" justify="center" alignItems="center">

                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChangeClick} id="AU" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(50, 50, 240)',
                                                '&$checked': {
                                                    color: 'rgb(50, 50, 240)',
                                                },
                                                marginLeft: '35px',
                                            }} />}
                                            label="AU"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChangeClick} id="DH" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(200, 110, 10)',
                                                '&$checked': {
                                                    color: 'rgb(200, 110, 10)',
                                                },
                                                marginLeft: '35px',
                                            }} />}
                                            label="DH"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChangeClick} id="K4" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(20, 150, 30)',
                                                '&$checked': {
                                                    color: 'rgb(20, 150, 30)',
                                                },
                                                marginLeft: '35px',
                                            }} />}
                                            label="K4"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChangeClick} id="MD" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(210, 180, 30)',
                                                '&$checked': {
                                                    color: 'rgb(210, 180, 30)',
                                                },
                                                marginLeft: '35px',
                                            }} />}
                                            label="MD"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChangeClick} id="MI" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(110, 110, 110)',
                                                '&$checked': {
                                                    color: 'rgb(110, 110, 110)',
                                                },
                                                marginLeft: '35px',
                                            }} />}
                                            label="MI"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChangeClick} id="SAC02" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(130, 10, 200)',
                                                '&$checked': {
                                                    color: 'rgb(130, 10, 200)',
                                                },
                                                marginLeft: '35px',
                                            }} />}
                                            label="SAC02"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChangeClick} id="WI" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(190, 30, 20)',
                                                '&$checked': {
                                                    color: 'rgb(190, 30, 20)',
                                                },
                                                marginLeft: '35px',
                                            }} />}
                                            label="WI"
                                        />
                                        <FormControlLabel className={classes.ticks}
                                            control={<Checkbox onChange={handleGraphChangeClick} id="UK" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                                color: 'rgb(50, 10, 100)',
                                                '&$checked': {
                                                    color: 'rgb(50, 10, 100)',
                                                },
                                                marginLeft: '35px',
                                            }} />}
                                            label="UK"
                                        />

                                    </Grid>
                                </Grid>

                            </div>
                        </CssBaseline>
                        <Footer />

                        <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                    </div>
                )
            }
            else {
                return <Redirect to='/' />
            }
        }
    }
}