import React, { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Loading from '../../Loading'
import Tesseract from 'tesseract.js'

const useStyles = makeStyles(theme => ({

    hidden: {
        display: 'none'
    },

    marginLeft: {
        marginLeft: 24
    },
    buttonLeft: {
        float: 'left',
        marginLeft: '24px',
        marginTop: '24px',
        marginBottom: '35px',

    },
    buttonRight: {
        float: 'right',
        marginTop: '24px',
        marginBottom: '35px',

    },
    input: {
        display: 'none',
    },

    marginRight: {
        marginRight: '15px'
    },

}))


export default function BAscan() {
    const classes = useStyles()

    const [searching, setSearching] = useState(false)
    const [foundText, setFoundText] = useState('')

    const config = {
        lang: "eng",
        oem: 1,
        psm: 3,
    }
    const handleChangeFilePicker = (e) => {
        var { target: { files } } = e
        console.log(files[0])
        const img = 'https://storage.googleapis.com/wisetekappappspotcom/testFolder/20230215_133901.jpg'
        setSearching(true)

        Tesseract.recognize(files[0])
            .then(result => {
                console.log('Text found on this image: ,', result)
                setFoundText(result.data.text)
                setSearching(false)
            })
            .catch(err => console.error('Error: ', err))
        // .finally(resultOrError => console.log(resultOrError))




    }

    return (
        <div>
            <p>BA scan</p>
            <input
                // accept="audio/*,video/*,image/*,.xlsx,.xls"
                accept="image/*"
                className={classes.input}
                id="add-contained-button-file"
                // multiple
                type="file"
                onChange={handleChangeFilePicker}
            />
            <label htmlFor="add-contained-button-file">
                <Button
                    className={classes.marginRight}
                    variant="contained"
                    color="default"
                    component="span"
                    startIcon={<AttachFileIcon />}>
                    Select Img
                </Button>
            </label>
            {searching ? <div><br /><Loading /><br /></div> : null}

            <br />
            <br />
            <br />
            <p>{foundText}</p>
        </div>
    )
}
