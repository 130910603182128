import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { storage } from '../../config/fbConfig';


const useStyles = makeStyles(theme => ({

    textCentered: {
        width: '100%',
        textAlign: 'center'
    },

}));


export default function WiseStarsWinnersCards(props) {
    const classes = useStyles();

    const [imageURLs, setImageURLs] = useState([]);

    function getAllImages() {
        if (props.getDataNow) {
            console.log('get images data now..', props.getDataNow)

            var storageRef = storage.ref(props.storageRefString)
            var allURLs = []
            var counter = 0

            storageRef.listAll()
                .then(result => {
                    result.items.forEach(imageRef => {
                        imageRef.getDownloadURL()
                            .then(url => {
                                // console.log(url)
                                allURLs.push(url)
                                counter++
                                if (result.items.length === counter) {
                                    // console.log(result.items.length, ' of ', counter)
                                    // console.log('there are ', result.items.length, ' pictures to display', allURLs)
                                    console.log('there are ', result.items.length, ' pictures to display')
                                    setImageURLs(allURLs)

                                }
                            })
                    })
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }
    useEffect(() => {
        // console.log('loop!')
        getAllImages()
        return () => { }
    }, [props.getDataNow])

    return (

        <div className={classes.textCentered}>
            <h3>{props.headerText}</h3>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {imageURLs.map((imgUrl, index) => (
                    <Grid item key={index} style={{ margin: 10, maxHeight: 800, width: '30%', }}>
                        <img src={imgUrl} alt={`WiseStars Winner ${index}`} style={{ width: '100%', height: '100%', borderRadius: 25 }} />
                    </Grid>
                ))}
            </Grid>
            <br /><br />
        </div >

    )
}
