import { Collapse, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MailIcon from '@material-ui/icons/Mail';
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BuildIcon from '@material-ui/icons/Build';
import AdjustIcon from '@material-ui/icons/Adjust';
import AllInboxTwoToneIcon from '@material-ui/icons/AllInboxTwoTone';
import React, { useContext, useState } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import FaceIcon from '@material-ui/icons/Face';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AssignmentLateTwoToneIcon from '@material-ui/icons/AssignmentLateTwoTone';
import LaptopWindowsTwoToneIcon from '@material-ui/icons/LaptopWindowsTwoTone';
import ApartmentTwoToneIcon from '@material-ui/icons/ApartmentTwoTone';
import SupervisorAccountTwoToneIcon from '@material-ui/icons/SupervisorAccountTwoTone';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import BusinessIcon from '@material-ui/icons/Business';
import MemoryTwoToneIcon from '@material-ui/icons/MemoryTwoTone';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import NavIcon from '../../../images/NavIcon.svg'
import ReturntekIcon from '../../../images/ReturntekIcon.svg'
import FactorialIcon from '../../../images/FactorialIcon.svg'
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import SchoolIcon from '@material-ui/icons/School';
import BookTwoToneIcon from '@material-ui/icons/BookTwoTone';
import TrackChangesTwoToneIcon from '@material-ui/icons/TrackChangesTwoTone';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';

const useStyles = makeStyles(theme => ({
    menuButton: {
        textAlign: 'left'
    },
    menuDrawer: {
        width: '250px',
    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    doubleNested: {
        paddingLeft: theme.spacing(5),
    },
    tripleNested: {
        paddingLeft: theme.spacing(6),
    },
    quadripleNested: {
        paddingLeft: theme.spacing(7),
    },
    restrictedRed: {
        paddingLeft: theme.spacing(4),
        color: 'red',
        opacity: 0.6
    },
}));

export default function SideMenu(user) {
    const classes = useStyles();
    const [menuOpen, setMenuOpen] = useState(false);
    const [openTicketingColapse, setOpenTicketingColapse] = useState(false);
    const [openISandTColapse, setOpenISandTColapse] = useState(false);
    const [openEngColapse, setOpenEngColapse] = useState(false);
    const [openMetricsColapse, setOpenMetricsColapse] = useState(false);
    const [openMetricsISandTColapse, setOpenMetricsISandTColapse] = useState(false);
    const [openMetricsOperationsColapse, setOpenMetricsOperationsColapse] = useState(false);
    const [openMetricsQandCColapse, setOpenMetricsQandCColapse] = useState(false);
    const [openMetricsQandCChainColapse, setOpenMetricsQandCChainColapse] = useState(false);
    const [openMetricsQandCQualityColapse, setOpenMetricsQandCQualityColapse] = useState(false);
    const [openMetricsCustomerSLAColapse, setOpenMetricsCustomerSLAColapse] = useState(false);
    const [openMetricsInventoryColapse, setOpenMetricsInventoryColapse] = useState(false);
    const [openProductionColapse, setOpenProductionColapse] = useState(false);
    const [openKnowledgeHubColapse, setOpenKnowledgeHubColapse] = useState(false);
    const [openKnowledgeHubFinanceColapse, setOpenKnowledgeHubFinanceColapse] = useState(false);
    const [openKnowledgeHubCSColapse, setOpenKnowledgeHubCSColapse] = useState(false);
    const [openKnowledgeHubSalesColapse, setOpenKnowledgeHubSalesColapse] = useState(false);
    const [openKnowledgeHubHRColapse, setOpenKnowledgeHubHRColapse] = useState(false);
    const [openKnowledgeHubOperationsColapse, setOpenKnowledgeHubOperationsColapse] = useState(false);
    const [openKnowledgeHubGeneralColapse, setOpenKnowledgeHubGeneralColapse] = useState(false);
    const [openHowToVideosColapse, setOpenHowToVideosColapse] = useState(false);
    const [openHowToVideosNavisionColapse, setOpenHowToVideosNavisionColapse] = useState(false);
    const [openHowToVideosNavisionCustomerServiceColapse, setOpenHowToVideosNavisionCustomerServiceColapse] = useState(false);
    const [openHowToVideosNavisionEngineeringColapse, setOpenHowToVideosNavisionEngineeringColapse] = useState(false);
    const [openHowToVideosNavisionFinanceColapse, setOpenHowToVideosNavisionFinanceColapse] = useState(false);
    const [openHowToVideosNavisionHRColapse, setOpenHowToVideosNavisionHRColapse] = useState(false);
    const [openHowToVideosNavisionISandTColapse, setOpenHowToVideosNavisionISandTColapse] = useState(false);
    const [openHowToVideosNavisionLogisticsColapse, setOpenHowToVideosNavisionLogisticsColapse] = useState(false);
    const [openHowToVideosNavisionMarketingColapse, setOpenHowToVideosNavisionMarketingColapse] = useState(false);
    const [openHowToVideosNavisionProductionColapse, setOpenHowToVideosNavisionProductionColapse] = useState(false);
    const [openHowToVideosNavisionPurchasingColapse, setOpenHowToVideosNavisionPurchasingColapse] = useState(false);
    const [openHowToVideosNavisionQandCHandSColapse, setOpenHowToVideosNavisionQandCHandSColapse] = useState(false);
    const [openHowToVideosNavisionArchieColapse, setOpenHowToVideosNavisionArchieColapse] = useState(false);

    const { signOut, role, displayName, department, uid } = useContext(AuthContext);

    const history = useHistory();

    const handleClickTicketing = () => {
        setOpenTicketingColapse(!openTicketingColapse);
    };
    const handleClickISandT = () => {
        setOpenISandTColapse(!openISandTColapse);
    };
    const handleClickEng = () => {
        setOpenEngColapse(!openEngColapse);
    };
    const handleClickMetrics = () => {
        setOpenMetricsColapse(!openMetricsColapse);
    };
    const handleClickMetricsISandT = () => {
        setOpenMetricsISandTColapse(!openMetricsISandTColapse);
    };
    const handleClickMetricsOperations = () => {
        setOpenMetricsOperationsColapse(!openMetricsOperationsColapse);
    };
    const handleClickMetricsQandC = () => {
        setOpenMetricsQandCColapse(!openMetricsQandCColapse);
    };
    const handleClickMetricsQandCChain = () => {
        setOpenMetricsQandCChainColapse(!openMetricsQandCChainColapse);
    };
    const handleClickMetricsQandCQuality = () => {
        setOpenMetricsQandCQualityColapse(!openMetricsQandCQualityColapse);
    };
    const handleClickMetricsCustomerSLA = () => {
        setOpenMetricsCustomerSLAColapse(!openMetricsCustomerSLAColapse);
    };
    const handleClickProduction = () => {
        setOpenProductionColapse(!openProductionColapse);
    };
    const handleClickHowToVideos = () => {
        setOpenHowToVideosColapse(!openHowToVideosColapse);
    };
    const handleClickHowToVideosNavision = () => {
        setOpenHowToVideosNavisionColapse(!openHowToVideosNavisionColapse);
    };
    const handleClickHowToVideosNavisionEngineering = () => {
        setOpenHowToVideosNavisionEngineeringColapse(!openHowToVideosNavisionEngineeringColapse);
    };
    const handleClickHowToVideosNavisionHR = () => {
        setOpenHowToVideosNavisionHRColapse(!openHowToVideosNavisionHRColapse);
    };
    const handleClickHowToVideosNavisionISandT = () => {
        setOpenHowToVideosNavisionISandTColapse(!openHowToVideosNavisionISandTColapse);
    };
    const handleClickHowToVideosNavisionLogistics = () => {
        setOpenHowToVideosNavisionLogisticsColapse(!openHowToVideosNavisionLogisticsColapse);
    };
    const handleClickHowToVideosNavisionMarketing = () => {
        setOpenHowToVideosNavisionMarketingColapse(!openHowToVideosNavisionMarketingColapse);
    };
    const handleClickHowToVideosNavisionProduction = () => {
        setOpenHowToVideosNavisionProductionColapse(!openHowToVideosNavisionProductionColapse);
    };
    const handleClickHowToVideosNavisionPurchasing = () => {
        setOpenHowToVideosNavisionPurchasingColapse(!openHowToVideosNavisionPurchasingColapse);
    };
    const handleClickHowToVideosNavisionQandCHandS = () => {
        setOpenHowToVideosNavisionQandCHandSColapse(!openHowToVideosNavisionQandCHandSColapse);
    };
    const handleClickHowToVideosNavisionArchie = () => {
        setOpenHowToVideosNavisionArchieColapse(!openHowToVideosNavisionArchieColapse);
    };


    const toggleDrawer = (side, open, reason) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open === false) {
            if (reason === 'clickaway') {
                setMenuOpen(open);
                setOpenTicketingColapse(false);
                setOpenISandTColapse(false);
                setOpenEngColapse(false);
                setOpenMetricsColapse(false);
                setOpenMetricsISandTColapse(false);
                setOpenMetricsQandCColapse(false);
                setOpenMetricsQandCChainColapse(false);
                setOpenMetricsQandCQualityColapse(false);
                setOpenMetricsCustomerSLAColapse(false);
                setOpenMetricsInventoryColapse(false);
                setOpenProductionColapse(false);
                setOpenKnowledgeHubColapse(false);
                setOpenKnowledgeHubFinanceColapse(false);
                setOpenKnowledgeHubCSColapse(false);
                setOpenKnowledgeHubSalesColapse(false);
                setOpenKnowledgeHubHRColapse(false);
                setOpenKnowledgeHubOperationsColapse(false);
                setOpenKnowledgeHubGeneralColapse(false);
                setOpenHowToVideosColapse(false);
                setOpenHowToVideosNavisionColapse(false);
                setOpenHowToVideosNavisionArchieColapse(false);
                setOpenHowToVideosNavisionProductionColapse(false);
            }
            return;
        }
        setMenuOpen(open);
    };

    const goKickDashboard = (e) => {
        history.push("/");
    }
    const goTicketingISandT = (e) => {
        //e.preventDefault();
        history.push("/ticketingISandT");
    }
    const goTicketingFacilities = (e) => {
        //e.preventDefault();
        history.push("/ticketingFacilities");
    }
    const goTicketingHR = (e) => {
        //e.preventDefault();
        history.push("/ticketingHR");
    }
    const goDashboard = (e) => {
        history.push("/dashboard");
    }
    const goAdmin = (e) => {
        history.push("/admin");
    }
    const goTicketsIST = (e) => {
        history.push("/ticketsIS&T");
    }
    const goIncidentsIST = (e) => {
        history.push("/incidentsIS&T");
    }
    // const goHoliday = (e) => {
    //     history.push("/holiday");
    // }
    const goMeetingRooms = (e) => {
        history.push("/meetingRooms");
    }
    const goEngActionTracker = (e) => {
        history.push("/engActionTracker");
    }
    const goISTActionTracker = (e) => {
        history.push("/istActionTracker");
    }
    const goEngDowntimeLog = (e) => {
        history.push("/engDowntimeLog");
    }
    const goFacilities = (e) => {
        history.push("/facilitiesconsole");
    }
    const goHR = (e) => {
        history.push("/hrconsole");
    }
    const goTesting = (e) => {
        history.push("/testing");
    }
    const goMetricsKPIs = (e) => {
        history.push("/kpis");
    }
    const goArchieKPIs = (e) => {
        history.push("/archiekpis");
    }
    const goKnowledgeBox = (e) => {
        history.push("/knowledgebox");
    }
    const goPartnerMatrix = (e) => {
        history.push("/partnermatrix");
    }
    const goProfile = (e) => {
        history.push("/profile");
    }
    const goMetricsCustomerSLAforUS = (e) => {
        history.push("/metricsCustomerSLAforUS");
    }
    const goMetricsCustomerSLAforIEandUK = (e) => {
        history.push("/metricsCustomerSLAforIEandUK");
    }
    const goMetricsChainOfCustodyDealIntegrity = (e) => {
        history.push("/metricsChainOfCustodyDealIntegrity")
    }
    const goMetricsChainOfCustodyVerificationWip = (e) => {
        history.push("/metricsChainOfCustodyVerificationWip")
    }
    const goMetricsChainOfCustodyDealsInSales = (e) => {
        history.push("/metricsChainOfCustodyDealsInSales")
    }
    const goMetricsQualityCapaVol = (e) => {
        history.push("/metricsQualityCapaVol")
    }
    const goMetricsQualityCustomerIssues = (e) => {
        history.push("/metricsQualityCustomerIssues")
    }
    const goMetricsQualityFPY = (e) => {
        history.push("/metricsQualityFPY")
    }
    const goMetricsInventory = (e) => {
        history.push("/metricsInventory")
    }
    const goMetricsOption2 = (e) => {
        history.push("/")
    }
    const goProductionMemoryScan = (e) => {
        history.push("/memoryScan")
    }
    const goNavisionCustomerService = (e) => {
        history.push("/navisionCustomerService")
    }
    const goNavisionFinance = (e) => {
        history.push("/navisionFinance")
    }
    const goNavisionProductionBasicAudit = (e) => {
        history.push("/navisionProductionBasicAudit")
    }
    const goNavisionProductionInventoryManagement = (e) => {
        history.push("/navisionProductionInventoryManagement")
    }
    const goNavisionProductionReceiving = (e) => {
        history.push("/navisionProductionReceiving")
    }
    const goNavisionProductionShipping = (e) => {
        history.push("/navisionProductionShipping")
    }
    const goNavisionProductionTechAudit = (e) => {
        history.push("/navisionProductionTechAudit")
    }
    const goNavisionProductionVerify = (e) => {
        history.push("/navisionProductionVerify")
    }
    const goReturntekTraining = (e) => {
        history.push("/returntekTraining")
    }
    const goFactorialTraining = (e) => {
        history.push("/factorialTraining")
    }
    const goNavisionSales = (e) => {
        history.push("/navisionSales")
    }
    const goNavisionArchieAiken = (e) => {
        history.push("/navisionArchieAiken")
    }
    const goNavisionArchieBlancco = (e) => {
        history.push("/navisionArchieBlancco")
    }
    const goNavisionArchieDellBiosOwnership = (e) => {
        history.push("/navisionArchieDellBiosOwnership")
    }
    const goNavisionArchieDEP = (e) => {
        history.push("/navisionArchieDEP")
    }
    const goNavisionArchieDigitalLicenses = (e) => {
        history.push("/navisionArchieDigitalLicenses")
    }
    const goNavisionArchieOS = (e) => {
        history.push("/navisionArchieOperatingSystem")
    }
    const goNavisionArchieT2Workaround = (e) => {
        history.push("/navisionArchieT2Workaround")
    }
    const goNavisionArchieWhiteCanyon = (e) => {
        history.push("/navisionArchieWhiteCanyon")
    }
    const goWiseAcademy = (e) => {
        history.push("/wiseAcademy");
    }
    const goAttendanceClock = (e) => {
        history.push("/attendanceClock");
    }

    const sideList = side => (
        <div
            className={classes.fullList}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            {/* visible for all */}
            <List>
                <ListItem button name="dashboard" onClick={goDashboard}><ListItemIcon><DashboardIcon /></ListItemIcon><ListItemText>Dashboard</ListItemText></ListItem>
                {uid === '6swPjBlta5XPapuq4FIgZQLrDPB3' || uid === '6swPjBlta5XPapuq4FIgZQLrDPB3'
                    ? <ListItem button name="attendanceClock" onClick={goAttendanceClock}><ListItemIcon><AddAlarmIcon /></ListItemIcon><ListItemText>Attendance Clock</ListItemText></ListItem> : null}
                <ListItem button name="ticketing" onClick={handleClickTicketing}><ListItemIcon><MailIcon /></ListItemIcon><ListItemText>Ticketing</ListItemText>
                    {openTicketingColapse ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openTicketingColapse} timeout="auto" unmountOnExit>
                    <List dense={true} component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={goTicketingISandT}><ListItemIcon><LaptopWindowsTwoToneIcon /></ListItemIcon><ListItemText primary="IS & T" /></ListItem>
                        <ListItem button className={classes.nested} onClick={goTicketingFacilities}><ListItemIcon><ApartmentTwoToneIcon /></ListItemIcon><ListItemText primary="Facilities" /></ListItem>
                        <ListItem button className={classes.nested} onClick={goTicketingHR}><ListItemIcon><SupervisorAccountTwoToneIcon /></ListItemIcon><ListItemText primary="HR" /></ListItem>
                    </List>
                </Collapse>
                <ListItem button name="production" onClick={handleClickProduction}><ListItemIcon><BusinessIcon /></ListItemIcon><ListItemText>Production</ListItemText>
                    {openProductionColapse ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openProductionColapse} timeout="auto" unmountOnExit>
                    <List dense={true} component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={goProductionMemoryScan}><ListItemIcon><MemoryTwoToneIcon /></ListItemIcon><ListItemText primary="Memory Scan" /></ListItem>
                    </List>
                </Collapse>
                <ListItem button name="meetingRooms" onClick={goMeetingRooms}><ListItemIcon><MeetingRoomIcon /></ListItemIcon><ListItemText>Meeting Rooms</ListItemText></ListItem>
                {/* <ListItem button name="holiday" onClick={goHoliday}><ListItemIcon><BeachAccessIcon /></ListItemIcon><ListItemText>Holiday Request</ListItemText></ListItem> */}
                <ListItem button name="wiseAcademy" onClick={goWiseAcademy}><ListItemIcon><SchoolIcon /></ListItemIcon><ListItemText>Wise Academy</ListItemText></ListItem>
                <ListItem button name="howToVideos" onClick={handleClickHowToVideos}><ListItemIcon><OndemandVideoIcon /></ListItemIcon><ListItemText>How to videos</ListItemText>
                    {openHowToVideosColapse ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openHowToVideosColapse} timeout="auto" unmountOnExit>
                    <List dense={true} component="div" disablePadding>
                        {/* <ListItem button className={classes.nested} onClick={handleClickHowToVideosNavision}><ListItemIcon><NavIcon width={24} height={24} /></ListItemIcon><ListItemText primary="NAV Training" />{openHowToVideosNavisionColapse ? <ExpandLess /> : <ExpandMore />}</ListItem> */}
                        <ListItem button className={classes.nested} onClick={handleClickHowToVideosNavisionArchie}><ListItemIcon><SettingsInputSvideoIcon /></ListItemIcon><ListItemText primary="Archie" />{openHowToVideosNavisionArchieColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                        <Collapse in={openHowToVideosNavisionArchieColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionArchieAiken}><ListItemText secondary="Aiken" /></ListItem>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionArchieBlancco}><ListItemText secondary="Blancco" /></ListItem>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionArchieWhiteCanyon}><ListItemText secondary="WhiteCanyon" /></ListItem>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionArchieDellBiosOwnership}><ListItemText secondary="Dell BIOS Ownership Date" /></ListItem>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionArchieDEP}><ListItemText secondary="DEP" /></ListItem>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionArchieDigitalLicenses}><ListItemText secondary="Digital Licenses" /></ListItem>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionArchieOS}><ListItemText secondary="Operating System" /></ListItem>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionArchieT2Workaround}><ListItemText secondary="T2 Workaround" /></ListItem>
                            </List>
                        </Collapse>
                        <ListItem button className={classes.nested} onClick={handleClickHowToVideosNavision}><ListItemIcon><img src={NavIcon} width="25px" height="25px" /></ListItemIcon><ListItemText primary="Navision" />{openHowToVideosNavisionColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                        <Collapse in={openHowToVideosNavisionColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionCustomerService}><ListItemText primary="Customer Service" /></ListItem>
                                <ListItem button className={classes.tripleNested} onClick={handleClickHowToVideosNavisionEngineering}><ListItemText primary="Engineering" />{openHowToVideosNavisionEngineeringColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                                {department === 'Finance' || role === 'admin' || uid === 'PJRnhkoSFeYOSLa3jUMvbsFfCDu1' ? <ListItem button className={classes.tripleNested} onClick={goNavisionFinance}><ListItemText primary="Finance" /></ListItem> : null}
                                <ListItem button className={classes.tripleNested} onClick={handleClickHowToVideosNavisionHR}><ListItemText primary="HR" />{openHowToVideosNavisionHRColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                                <ListItem button className={classes.tripleNested} onClick={handleClickHowToVideosNavisionISandT}><ListItemText primary="IS&T" />{openHowToVideosNavisionISandTColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                                <ListItem button className={classes.tripleNested} onClick={handleClickHowToVideosNavisionLogistics}><ListItemText primary="Logistics" />{openHowToVideosNavisionLogisticsColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                                <ListItem button className={classes.tripleNested} onClick={handleClickHowToVideosNavisionMarketing}><ListItemText primary="Marketing" />{openHowToVideosNavisionMarketingColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                                <ListItem button className={classes.tripleNested} onClick={handleClickHowToVideosNavisionProduction}><ListItemText primary="Production" />{openHowToVideosNavisionProductionColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                                <Collapse in={openHowToVideosNavisionProductionColapse} timeout="auto" unmountOnExit>
                                    <List dense={true} component="div" disablePadding>
                                        <ListItem button className={classes.quadripleNested} onClick={goNavisionProductionBasicAudit}><ListItemText secondary="Basic Audit" /></ListItem>
                                        <ListItem button className={classes.quadripleNested} onClick={goNavisionProductionInventoryManagement}><ListItemText secondary="Inventory Management" /></ListItem>
                                        <ListItem button className={classes.quadripleNested} onClick={goNavisionProductionReceiving}><ListItemText secondary="Receiving" /></ListItem>
                                        <ListItem button className={classes.quadripleNested} onClick={goNavisionProductionShipping}><ListItemText secondary="Shipping" /></ListItem>
                                        <ListItem button className={classes.quadripleNested} onClick={goNavisionProductionTechAudit}><ListItemText secondary="Tech Audit" /></ListItem>
                                        <ListItem button className={classes.quadripleNested} onClick={goNavisionProductionVerify}><ListItemText secondary="Verify" /></ListItem>
                                    </List>
                                </Collapse>
                                <ListItem button className={classes.tripleNested} onClick={handleClickHowToVideosNavisionPurchasing}><ListItemText primary="Purchasing" />{openHowToVideosNavisionPurchasingColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                                <ListItem button className={classes.tripleNested} onClick={handleClickHowToVideosNavisionQandCHandS}><ListItemText primary="Quality, Compliance, H&S" />{openHowToVideosNavisionQandCHandSColapse ? <ExpandLess /> : <ExpandMore />}</ListItem>
                                <ListItem button className={classes.tripleNested} onClick={goNavisionSales}><ListItemText primary="Sales" /></ListItem>
                            </List>
                        </Collapse>
                        <ListItem button className={classes.nested} onClick={goReturntekTraining}><ListItemIcon><img src={ReturntekIcon} width="22px" height="22px" /></ListItemIcon><ListItemText primary="Returntek" /></ListItem>
                        <ListItem button className={classes.nested} onClick={goFactorialTraining}><ListItemIcon><img src={FactorialIcon} width="22px" height="22px" /></ListItemIcon><ListItemText primary="Factorial" /></ListItem>
                    </List>
                </Collapse>
                <ListItem button name="metrics" onClick={handleClickMetrics}><ListItemIcon><AssessmentIcon /></ListItemIcon><ListItemText>Metrics</ListItemText>
                    {openMetricsColapse ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openMetricsColapse} timeout="auto" unmountOnExit>
                    <List dense={true} component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={handleClickMetricsISandT}>
                            <ListItemText primary="IS & T" />
                            {openMetricsISandTColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        {role === 'admin' || role === 'adminIS&T' ?
                            <Collapse in={openMetricsISandTColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.doubleNested} onClick={goMetricsKPIs}>
                                        <ListItemText secondary="KPI's" />
                                    </ListItem>
                                    <ListItem button className={classes.doubleNested} onClick={goMetricsOption2}>
                                        <ListItemText secondary="Option 2" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            :
                            <Collapse in={openMetricsISandTColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.doubleNestedRed} >
                                        <ListItemText className={classes.restrictedRed}>Restricted access</ListItemText>
                                    </ListItem>
                                </List>
                            </Collapse>
                        }

                        <ListItem button className={classes.nested} onClick={handleClickMetricsOperations}>
                            <ListItemText primary="Operations" />
                            {openMetricsOperationsColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        {role === 'admin' || role === 'adminIS&T' ?
                            <Collapse in={openMetricsOperationsColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.doubleNested} onClick={goArchieKPIs}>
                                        <ListItemText secondary="Archie KPI's" />
                                    </ListItem>
                                    <ListItem button className={classes.doubleNested} onClick={goMetricsOption2}>
                                        <ListItemText secondary="Option 2" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            :
                            <Collapse in={openMetricsOperationsColapse} timeout="auto" unmountOnExit>
                                <List dense={true} component="div" disablePadding>
                                    <ListItem button className={classes.doubleNestedRed} >
                                        <ListItemText className={classes.restrictedRed}>Restricted access</ListItemText>
                                    </ListItem>
                                </List>
                            </Collapse>
                        }

                        <ListItem button className={classes.nested} onClick={handleClickMetricsQandC}>
                            <ListItemText primary="Quality & Compliance" />
                            {openMetricsQandCColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openMetricsQandCColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>

                                <ListItem button className={classes.doubleNested} onClick={handleClickMetricsQandCChain}>
                                    <ListItemText secondary="Chain of Custody" />
                                    {openMetricsQandCChainColapse ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={openMetricsQandCChainColapse} timeout="auto" unmountOnExit>
                                    <List dense={true} component="div" disablePadding>
                                        <ListItem button className={classes.tripleNested} onClick={goMetricsChainOfCustodyDealIntegrity}>
                                            <ListItemText secondary="Deal Integrity" />
                                        </ListItem>
                                        <ListItem button className={classes.tripleNested} onClick={goMetricsChainOfCustodyVerificationWip}>
                                            <ListItemText secondary="Verification Wip" />
                                        </ListItem>
                                        <ListItem button className={classes.tripleNested} onClick={goMetricsChainOfCustodyDealsInSales}>
                                            <ListItemText secondary="Deals in Sales" />
                                        </ListItem>
                                    </List>
                                </Collapse>
                                <ListItem button className={classes.doubleNested} onClick={handleClickMetricsQandCQuality}>
                                    <ListItemText secondary="Quality" />
                                    {openMetricsQandCQualityColapse ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={openMetricsQandCQualityColapse} timeout="auto" unmountOnExit>
                                    <List dense={true} component="div" disablePadding>
                                        <ListItem button className={classes.tripleNested} onClick={goMetricsQualityCapaVol}>
                                            <ListItemText secondary="Capa Vol & Aging" />
                                        </ListItem>
                                        <ListItem button className={classes.tripleNested} onClick={goMetricsQualityCustomerIssues}>
                                            <ListItemText secondary="Customer Issues" />
                                        </ListItem>
                                        <ListItem button className={classes.tripleNested} onClick={goMetricsQualityFPY}>
                                            <ListItemText secondary="FPY" />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </List>
                        </Collapse>

                        <ListItem button className={classes.nested} onClick={handleClickMetricsCustomerSLA}>
                            <ListItemText primary="Customer SLA" />
                            {openMetricsCustomerSLAColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openMetricsCustomerSLAColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.doubleNested} onClick={goMetricsCustomerSLAforIEandUK}>
                                    <ListItemText secondary="IE & UK SLA" />
                                </ListItem>
                                <ListItem button className={classes.doubleNested} onClick={goMetricsCustomerSLAforUS}>
                                    <ListItemText secondary="US SLA" />
                                </ListItem>
                            </List>
                        </Collapse>

                        <ListItem button className={classes.nested} onClick={goMetricsInventory}>
                            <ListItemText primary="Inventory" />
                            {/* {openMetricsQandCCustomerSLAColapse ? <ExpandLess /> : <ExpandMore />} */}
                        </ListItem>
                    </List>
                </Collapse>
            </List>
            <Divider />

            {/* temporary solution for Sean Collins to see facilities section*/}
            {/* {
                displayName === 'Sean Collins' || displayName === 'Chris O\'Neill' || displayName === 'Bernard Cronin' ?
                    <List>
                        <ListItem button name="facilitiesconsole" onClick={goFacilities}><ListItemIcon><ApartmentIcon /></ListItemIcon><ListItemText>Facilities</ListItemText></ListItem>
                    </List>
                    : null
            } */}


            {/* visible for memberIS&T */}
            {
                role === 'memberIS&T' ?
                    <List>
                        <ListItem button name="IS&T" onClick={handleClickISandT}><ListItemIcon><LaptopWindowsIcon /></ListItemIcon><ListItemText>IS&T</ListItemText>
                            {openISandTColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openISandTColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={goTicketsIST}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goKnowledgeBox}><ListItemIcon><AllInboxTwoToneIcon /></ListItemIcon><ListItemText primary="KnowledgeBox" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goIncidentsIST}><ListItemIcon><AssignmentLateTwoToneIcon /></ListItemIcon><ListItemText primary="Incident Log" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goISTActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                            </List>
                        </Collapse>
                    </List>
                    : null
            }

            {/* visible for memberHR */}

            {/* visible for memberENG */}
            {
                role === 'memberENG' ?
                    <List>
                        <ListItem button name="engconsole" onClick={handleClickEng}><ListItemIcon><BuildIcon /></ListItemIcon><ListItemText>Engineering</ListItemText>
                            {openEngColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openEngColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={goEngActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goEngDowntimeLog}><ListItemIcon><BookTwoToneIcon /></ListItemIcon><ListItemText primary="Downtime Log" /></ListItem>
                            </List>
                        </Collapse>
                    </List>
                    : null
            }

            {/* visible for memberAM */}
            {
                role === 'memberAM' ?
                    <List>
                        <ListItem button name="partnermatrixconsole" onClick={goPartnerMatrix}><ListItemIcon><GroupWorkIcon /></ListItemIcon><ListItemText>Partner Matrix</ListItemText></ListItem>
                    </List>
                    : null
            }

            {/* visible for memberFacilities */}
            {
                role === 'memberFacilities' ?
                    <List>
                        <ListItem button name="facilitiesconsole" onClick={goFacilities}><ListItemIcon><ApartmentIcon /></ListItemIcon><ListItemText>Facilities</ListItemText></ListItem>
                    </List>
                    : null
            }

            {/* visible for adminIS&T */}
            {
                department === "IS&T" && role === 'adminIS&T' ?
                    <List>
                        <ListItem button name="IS&T" onClick={handleClickISandT}><ListItemIcon><LaptopWindowsIcon /></ListItemIcon><ListItemText>IS&T</ListItemText>
                            {openISandTColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openISandTColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={goTicketsIST}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goKnowledgeBox}><ListItemIcon><AllInboxTwoToneIcon /></ListItemIcon><ListItemText primary="KnowledgeBox" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goIncidentsIST}><ListItemIcon><AssignmentLateTwoToneIcon /></ListItemIcon><ListItemText primary="Incident Log" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goISTActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                            </List>
                        </Collapse>
                        <ListItem button name="engconsole" onClick={handleClickEng}><ListItemIcon><BuildIcon /></ListItemIcon><ListItemText>Engineering</ListItemText>
                            {openEngColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openEngColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={goEngActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goEngDowntimeLog}><ListItemIcon><BookTwoToneIcon /></ListItemIcon><ListItemText primary="Downtime Log" /></ListItem>
                            </List>
                        </Collapse>
                    </List>
                    : null
            }

            {/* visible for adminHR */}
            {
                department === 'HR' && role === 'adminHR' || department === 'HR' && role === 'teamManager' ?
                    <List>
                        <ListItem button name="hrconsole" onClick={goHR}><ListItemIcon><SupervisorAccountIcon /></ListItemIcon><ListItemText>HR</ListItemText></ListItem>
                    </List>
                    : null
            }

            {/* visible for adminENG */}
            {
                department === 'Engineering' && role === 'adminENG' || department === 'Engineering' && role === 'teamManager' ?
                    <List>
                        <ListItem button name="engconsole" onClick={handleClickEng}><ListItemIcon><BuildIcon /></ListItemIcon><ListItemText>Engineering</ListItemText>
                            {openEngColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openEngColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={goEngActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goEngDowntimeLog}><ListItemIcon><BookTwoToneIcon /></ListItemIcon><ListItemText primary="Downtime Log" /></ListItem>
                            </List>
                        </Collapse>
                    </List>
                    : null
            }

            {/* visible for adminAM */}
            {
                role === 'adminAM' ?
                    <List>
                        <ListItem button name="partnermatrixconsole" onClick={goPartnerMatrix}><ListItemIcon><GroupWorkIcon /></ListItemIcon><ListItemText>Partner Matrix</ListItemText></ListItem>
                    </List>
                    : null
            }

            {/* visible for adminFacilities */}
            {
                department === "Quality and Compliance" && role === 'adminFacilities' || department === "Quality and Compliance" && role === 'teamManager' ?
                    <List>
                        <ListItem button name="facilitiesconsole" onClick={goFacilities}><ListItemIcon><ApartmentIcon /></ListItemIcon><ListItemText>Facilities</ListItemText></ListItem>
                    </List>
                    : null
            }

            {/* visible for admin */}
            {
                role === 'admin' ?
                    <List>
                        <ListItem button name="IS&T" onClick={handleClickISandT}><ListItemIcon><LaptopWindowsIcon /></ListItemIcon><ListItemText>IS&T</ListItemText>
                            {openISandTColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openISandTColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={goTicketsIST}><ListItemIcon><MailTwoToneIcon /></ListItemIcon><ListItemText primary="Tickets" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goKnowledgeBox}><ListItemIcon><AllInboxTwoToneIcon /></ListItemIcon><ListItemText primary="KnowledgeBox" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goIncidentsIST}><ListItemIcon><AssignmentLateTwoToneIcon /></ListItemIcon><ListItemText primary="Incident Log" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goISTActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                            </List>
                        </Collapse>
                        <ListItem button name="facilitiesconsole" onClick={goFacilities}><ListItemIcon><ApartmentIcon /></ListItemIcon><ListItemText>Facilities</ListItemText></ListItem>
                        <ListItem button name="engconsole" onClick={handleClickEng}><ListItemIcon><BuildIcon /></ListItemIcon><ListItemText>Engineering</ListItemText>
                            {openEngColapse ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openEngColapse} timeout="auto" unmountOnExit>
                            <List dense={true} component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={goEngActionTracker}><ListItemIcon><TrackChangesTwoToneIcon /></ListItemIcon><ListItemText primary="Action Tracker" /></ListItem>
                                <ListItem button className={classes.nested} onClick={goEngDowntimeLog}><ListItemIcon><BookTwoToneIcon /></ListItemIcon><ListItemText primary="Downtime Log" /></ListItem>
                            </List>
                        </Collapse>
                        <ListItem button name="partnermatrixconsole" onClick={goPartnerMatrix}><ListItemIcon><GroupWorkIcon /></ListItemIcon><ListItemText>Partner Matrix</ListItemText></ListItem>
                        <ListItem button name="hrconsole" onClick={goHR}><ListItemIcon><SupervisorAccountIcon /></ListItemIcon><ListItemText>HR</ListItemText></ListItem>
                        <ListItem button name="admin" onClick={goAdmin}><ListItemIcon><AccountBoxIcon /></ListItemIcon><ListItemText>Admin</ListItemText></ListItem>
                        <ListItem button name="testing" onClick={goTesting}><ListItemIcon><AdjustIcon /></ListItemIcon><ListItemText>Testing</ListItemText></ListItem>
                    </List>
                    : null
            }

            {/* Wise Academy access:Pawel Gajewski, Derek, JP, Sean Collins, Robert Daly Declan Maguire*/}
            {/* {uid === '6swPjBlta5XPapuq4FIgZQLrDPB3' ||
                uid === 'IiKGJ6kAEmOYrY1D9GOXVmLrrB03' ||
                uid === 'mxBUvBxfb8PJS57QV8TaAMz5WJO2' ||
                uid === '7TkWmfhWLvWlPKjPRVMsfww0bLu1' ||
                uid === 'Jvge4olivDhXBUGjr2aWJrbzLbg2' ||
                uid === 'GGHIDr4IU2do3fVfCzCKr4NmwgB2' ?
                <ListItem button name="wiseAcademy" onClick={goWiseAcademy}><ListItemIcon><SchoolIcon /></ListItemIcon><ListItemText>Wise Academy</ListItemText></ListItem>
                : null} */}

            {/* show JP the testing console */}
            {uid === 'mxBUvBxfb8PJS57QV8TaAMz5WJO2' ? <ListItem button name="testing" onClick={goTesting}><ListItemIcon><AdjustIcon /></ListItemIcon><ListItemText>Testing</ListItemText></ListItem> : null}

            {role != 'member' || role != 'teamManager' ? <Divider /> : null}
            <List>
                <ListItem button onClick={goProfile}><ListItemIcon><FaceIcon /></ListItemIcon><ListItemText>Profile</ListItemText></ListItem>
                <ListItem button onClick={signOut}><ListItemIcon><ExitToAppIcon /></ListItemIcon><ListItemText>Sign out</ListItemText></ListItem>
            </List>
            <Snackbar />
        </div >
    );

    return (
        <div>
            <BrowserRouter>
                <Button className={classes.menuButton} onClick={toggleDrawer('left', true, 'opening')}>Menu
                    <MoreVertIcon />
                </Button>


                <Drawer classes={{ paper: classes.menuDrawer }} open={menuOpen} onClose={toggleDrawer('left', false, 'clickaway')}>
                    {sideList('left')}
                </Drawer>

            </BrowserRouter>
        </div>
    );
}