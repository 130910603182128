import React, { useContext, useEffect, useState } from 'react'
import { Grid, Chip, CssBaseline, LinearProgress, makeStyles, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import { DataContext } from '../../../../contexts/DataContext';
import Footer from '../../../Footer';
import Loading from '../../Loading';
import { db } from '../../../../config/fbConfig';
import moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table';
import ChartistGraph from 'react-chartist';
import ctPointLabels from 'chartist-plugin-pointlabels'
import Chartist from 'chartist';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MetricsChainOfCustodyDealsInSalesDetails from './MetricsChainOfCustodyDealsInSalesDetails';
import SideMenuV2 from '../../SideMenus/SideMenuV2';
import SnackbarComponent from '../../../customComponents/SnackbarComponent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // backgroundColor: '#cfd8dc',
        backgroundColor: '#f0f5f4',
    },
    main: {
        margin: theme.spacing(2)
    },
    media: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '20%',
    },
    searchProgress: {
        // visibility: 'hidden',
        marginBottom: '15px'
    },
    graphHeader: {
        marginTop: '100px',
        textAlign: 'center'
    },
    green: {
        width: '20px',
        color: 'rgb(20, 150, 30)',  // green
        backgroundColor: 'rgb(20, 150, 30)',  // green
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    blue: {
        width: '20px',
        color: 'rgb(50, 50, 240)', // blue
        backgroundColor: 'rgb(50, 50, 240)', // blue
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
    grey: {
        width: '20px',
        color: 'rgb(110, 110, 110)', // grey
        backgroundColor: 'rgb(110, 110, 110)', // grey
        borderRadius: '20%',
        marginLeft: '35px',
        marginRight: '10px',
    },
}));

const MetricsChainOfCustodyDealsInSales = (props) => {
    const classes = useStyles();

    const { isAuthenticated, loading, roles } = useContext(AuthContext);
    const { addDealsInSales, updateDealsInSales, deleteDealsInSales } = useContext(DataContext);

    const todaysDate = new Date();

    const [dealsInSales, setDealsInSales] = useState({
        // dateCreated:new Date(),
        // totalIE:0,
        // discrepantIE:0,
        // compliantIE:0,
        // compliantPercIE:0,
        // dealsWithPartsIE:0,
        // dealsWithPartsCompliantIE:0,
        // verifiedCompliantPercIE:0,
        // totalUS:0,
        // discrepantUS:0,
        // compliantUS:0,
        // compliantPercUS:0,
        // dealsWithPartsUS:0,
        // dealsWithPartsCompliantUS:0,
        // verifiedCompliantPercUS:0,
        // totalUK:0,
        // discrepantUK:0,
        // compliantUK:0,
        // compliantPercUK:0,
        // dealsWithPartsUK:0,
        // dealsWithPartsCompliantUK:0,
        // verifiedCompliantPercUK:0,
        columns: [
            { title: 'Date', field: 'dateCreated', type: 'date', filtering: false, initialEditValue: todaysDate },
            { title: 'IRE Total', field: 'totalIE', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'IRE Discrepant', field: 'discrepantIE', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'IRE Compliant', field: 'compliantIE', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'IRE Percent Compliant', field: 'compliantPercentIE', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'IRE Deals with Parts to be Verified', field: 'dealsWithPartsIE', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'IRE Compliant', field: 'dealsWithPartsCompliantIE', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'IRE Verified Compliancy Percent', field: 'verifiedCompliantPercentIE', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(50, 50, 240, 0.2)' } },
            { title: 'US Total', field: 'totalUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'US Discrepant', field: 'discrepantUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'US Compliant', field: 'compliantUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'US Percent Compliant', field: 'compliantPercentUS', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'US Deals with Parts to be Verified', field: 'dealsWithPartsUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'US Compliant', field: 'dealsWithPartsCompliantUS', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'US Verified Compliancy Percent', field: 'verifiedCompliantPercentUS', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(200, 110, 10, 0.2)' } },
            { title: 'UK Total', field: 'totalUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'UK Discrepant', field: 'discrepantUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'UK Compliant', field: 'compliantUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'UK Percent Compliant', field: 'compliantPercentUK', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'UK Deals with Parts to be Verified', type: 'numeric', field: 'dealsWithPartsUK', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'UK Compliant', field: 'dealsWithPartsCompliantUK', type: 'numeric', filtering: false, initialEditValue: 0, headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
            { title: 'UK Verified Compliancy Percent', field: 'verifiedCompliantPercentUK', filtering: false, editable: 'never', headerStyle: { backgroundColor: 'rgb(20, 150, 30, 0.2)' } },
        ],
        data: []
    });
    const [baTaPercentageGraph, setBaTaPercentageGraph] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })
    const [verCompPercentageGraph, setVerCompPercentageGraph] = useState({
        data: {},
        type: 'Line',
        options: {
            // fullWidth: true,
            // width: 300,
            height: 400,
            // high: 105,
            // low: -5,
            showPoint: true,
            axisX: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true
            },
            axisY: {
                // We can disable the grid for this axis
                showGrid: true,
                // show the label
                showLabel: true,
                // int on Y axis
                onlyInteger: true,
                // type: Chartist.FixedScaleAxis,
                // ticks: [-2, 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26],
                // high: 26,
                // low: -2,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelInterpolationFnc: v => v ? v + '%' : 0 + '%' // fix to show zero values
                })
            ]
        },
        animation: {
            draw: function (data) {
                if (data.type === "line" || data.type === "area") {
                    data.element.animate({
                        d: {
                            begin: 600,
                            dur: 700,
                            from: data.path
                                .clone()
                                .scale(1, 0)
                                .translate(0, data.chartRect.height())
                                .stringify(),
                            to: data.path.clone().stringify(),
                            easing: Chartist.Svg.Easing.easeOutQuint
                        }
                    });
                } else if (data.type === "point") {
                    data.element.animate({
                        opacity: {
                            begin: (data.index + 1) * 80,
                            dur: 500,
                            from: 0,
                            to: 1,
                            easing: "ease"
                        }
                    });
                }
            }
        }
    })
    const [verWipGraphIE, setDealsInSalesGraphIE] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    })
    const [verWipGraphUS, setDealsInSalesGraphUS] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    })
    const [verWipGraphUK, setDealsInSalesGraphUK] = useState({
        data: {},
        type: 'Bar',
        options: {
            stackBars: false,
            height: 300,
            high: 110,
            low: -1,
            seriesBarDistance: 30,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            },
            axisY: {
                offset: 30,
                // int on Y axis
                onlyInteger: true,
            },
            plugins: [
                ctPointLabels({
                    textAnchor: 'middle',
                    labelOffset: {
                        x: 0,
                        y: 15
                    },
                    labelInterpolationFnc: v => v ? v : 0  // fix to show zero values
                })
            ]
        }
    })

    //#region Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });
    //#endregion

    const handleGraphChangeClick = (e) => {

        var optionId = e.target.id

        switch (optionId) {
            case 'bataie':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'bataus':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'batauk':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[0].style.visibility == '' || graph[0].style.visibility == 'visible' ? graph[0].style.visibility = 'hidden' : graph[0].style.visibility = 'visible'
                break;
            case 'vercompie':
                var graph = document.getElementsByClassName('ct-series-a');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'vercompus':
                var graph = document.getElementsByClassName('ct-series-b');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;
            case 'vercompuk':
                var graph = document.getElementsByClassName('ct-series-c');
                graph[1].style.visibility == '' || graph[1].style.visibility == 'visible' ? graph[1].style.visibility = 'hidden' : graph[1].style.visibility = 'visible'
                break;

            default:
                break;
        }
    }

    function getMaxFrom2dAarray(a) {
        return Math.max(...a.map(e => Array.isArray(e) ? getMaxFrom2dAarray(e) : e));
    }
    function getMinFrom2dAarray(a) {
        return Math.min(...a.map(e => Array.isArray(e) ? getMinFrom2dAarray(e) : e));
    }

    function calcWeeklyVars(data) {
        var dealsInSalesData = [];
        var dealsInSalesLabels = [];

        var baTaPercent = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//compliantPercIE
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//compliantPercUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //compliantPercUK
        ]
        var verCompPercent = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//verifiedCompliantPercIE
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//verifiedCompliantPercUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //verifiedCompliantPercUK
        ]
        var dealsInSalesIE = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//totalIE
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//discrepantIE
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //dealsWithPartsIE
        ]
        var dealsInSalesUS = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//totalUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//discrepantUS
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //dealsWithPartsUS
        ]
        var dealsInSalesUK = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//totalUK
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],//discrepantUK
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //dealsWithPartsUK
        ]

        data.forEach(doc => {
            // console.log(doc.id)
            var row = doc.data()
            row.id = doc.id
            row.compliantPercentIE = doc.data().compliantPercIE + '%'
            row.compliantPercentUS = doc.data().compliantPercUS + '%'
            row.compliantPercentUK = doc.data().compliantPercUK + '%'
            row.verifiedCompliantPercentIE = doc.data().verifiedCompliantPercIE + '%'
            row.verifiedCompliantPercentUS = doc.data().verifiedCompliantPercUS + '%'
            row.verifiedCompliantPercentUK = doc.data().verifiedCompliantPercUK + '%'
            row.dateCreated = moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY')

            dealsInSalesData.push(row)
            dealsInSalesLabels.push(moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY'))
        })
        var labelsLength = dealsInSalesLabels.length
        if (labelsLength < 13) {
            // console.log('We have ' + labelsLength + ' labels')
            var noLabel = 'n/a'
            var calculatedLabels = []
            for (let i = labelsLength; i < 13; i++) {
                calculatedLabels.push(noLabel)
            }
            for (let j = 0; j < labelsLength; j++) {
                calculatedLabels.push(dealsInSalesLabels.pop())
            }
        }
        else {
            calculatedLabels = dealsInSalesLabels.reverse()
        }
        data.forEach(doc => {
            var positionDate = calculatedLabels.findIndex(dateCreated => dateCreated === moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY'))

            if (positionDate >= 0) {
                var percCompIE = doc.data().compliantPercIE
                var percCompUS = doc.data().compliantPercUS
                var percCompUK = doc.data().compliantPercUK
                var verifiedCompliantPercIE = doc.data().verifiedCompliantPercIE
                var verifiedCompliantPercUS = doc.data().verifiedCompliantPercUS
                var verifiedCompliantPercUK = doc.data().verifiedCompliantPercUK
                var totalIRELAND = doc.data().totalIE
                var discrepantIRELAND = doc.data().discrepantIE
                var toBeVerifiedIRELAND = doc.data().dealsWithPartsIE
                var totalUSA = doc.data().totalUS
                var discrepantUSA = doc.data().discrepantUS
                var toBeVerifiedUSA = doc.data().dealsWithPartsUS
                var totalUNITEDKINGDOM = doc.data().totalUK
                var discrepantUNITEDKINGDOM = doc.data().discrepantUK
                var toBeVerifiedUNITEDKINGDOM = doc.data().dealsWithPartsUK
                baTaPercent[0][positionDate] = percCompIE
                baTaPercent[1][positionDate] = percCompUS
                baTaPercent[2][positionDate] = percCompUK
                verCompPercent[0][positionDate] = verifiedCompliantPercIE
                verCompPercent[1][positionDate] = verifiedCompliantPercUS
                verCompPercent[2][positionDate] = verifiedCompliantPercUK
                dealsInSalesIE[0][positionDate] = totalIRELAND
                dealsInSalesIE[1][positionDate] = discrepantIRELAND
                dealsInSalesIE[2][positionDate] = toBeVerifiedIRELAND
                dealsInSalesUS[0][positionDate] = totalUSA
                dealsInSalesUS[1][positionDate] = discrepantUSA
                dealsInSalesUS[2][positionDate] = toBeVerifiedUSA
                dealsInSalesUK[0][positionDate] = totalUNITEDKINGDOM
                dealsInSalesUK[1][positionDate] = discrepantUNITEDKINGDOM
                dealsInSalesUK[2][positionDate] = toBeVerifiedUNITEDKINGDOM
            }
        })

        var baTaPercentageHigh = getMaxFrom2dAarray(baTaPercent) + 5
        var verCompPercentageHigh = getMaxFrom2dAarray(verCompPercent) + 5
        var verWipIEHigh = getMaxFrom2dAarray(dealsInSalesIE) + 5
        var verWipUSHigh = getMaxFrom2dAarray(dealsInSalesUS) + 5
        var verWipUKHigh = getMaxFrom2dAarray(dealsInSalesUK) + 5
        setDealsInSales({
            ...dealsInSales,
            data: dealsInSalesData
        })
        setBaTaPercentageGraph({
            ...baTaPercentageGraph,
            data: {
                labels: calculatedLabels,
                series: baTaPercent
            },
            options: {
                high: baTaPercentageHigh,
                low: -5
            }
        })
        setVerCompPercentageGraph({
            ...verCompPercentageGraph,
            data: {
                labels: calculatedLabels,
                series: verCompPercent
            },
            options: {
                high: verCompPercentageHigh,
                low: -5
            }
        })
        setDealsInSalesGraphIE({
            ...verWipGraphIE,
            data: {
                labels: calculatedLabels,
                series: dealsInSalesIE
            },
            options: {
                high: verWipIEHigh,
                low: -5
            }
        })
        setDealsInSalesGraphUS({
            ...verWipGraphUS,
            data: {
                labels: calculatedLabels,
                series: dealsInSalesUS
            },
            options: {
                high: verWipUSHigh,
                low: -5
            }
        })
        setDealsInSalesGraphUK({
            ...verWipGraphUK,
            data: {
                labels: calculatedLabels,
                series: dealsInSalesUK
            },
            options: {
                high: verWipUKHigh,
                low: -5
            }
        })

        var searchProgress = document.getElementById('searchGraphProgress');
        searchProgress.style.visibility = 'hidden';
    }

    const getGraphsData = () => {
        // searchProgress.style.visibility = 'visible';

        db.collection('qualityDealsInSales')
            .orderBy("dateCreated", "desc")
            .limit(13)
            .get()
            .then(snapshot => {
                calcWeeklyVars(snapshot)
            })
            .catch(function (error) {
                console.log("Error getting Deals in Sales data: ", error);
            });
    }

    useEffect(() => {
        getGraphsData()
        return () => { }
    }, [])

    if (loading) {
        return <Loading />
    }
    else {
        if (!isAuthenticated || roles.includes('viewAcc')) {
            return <Redirect to='/' />
        }
        else {
            return (
                <div className={classes.root}>
                    <CssBaseline>
                        <SideMenuV2 selectedIndex={32} openMetricsColapse={true} openMetricsQandCColapse={true} openMetricsQandCChainColapse={true} />
                        <div className={classes.main}>

                            {/* <h1>Metrics - Deals in Sales</h1> */}
                            <LinearProgress id='searchGraphProgress' className={classes.searchProgress} />
                            {/* <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <img className={classes.media} src={underConstruction} alt="Under Construction" />
                            </Grid> */}

                            {roles.includes('admin') ?
                                <MaterialTable
                                    title={"Deals in Sales & Verified Compliancy Percent"}
                                    columns={dealsInSales.columns}
                                    data={dealsInSales.data}
                                    onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    detailPanel={rowData => {
                                        // console.log(rowData)
                                        return (
                                            <MetricsChainOfCustodyDealsInSalesDetails rowData={rowData} refreshData={getGraphsData} />
                                        )
                                    }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="Ireland" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="United States" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                    <Chip label="United Kingdom" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15],
                                        headerStyle: {
                                            textAlign: 'center'
                                        },
                                        cellStyle: {
                                            textAlign: 'center'
                                        }
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    addDealsInSales(newData)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            // resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    getGraphsData()
                                                    resolve();
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    if (oldData) {
                                                        setDealsInSales(prevState => {
                                                            const data = [...prevState.data];
                                                            newData.dateCreated = moment(newData.dateCreated).format('DD MMM YYYY')
                                                            data[data.indexOf(oldData)] = newData;
                                                            console.log('Updated Verification Wip row in DB')
                                                            // Update data
                                                            updateDealsInSales(newData)
                                                                .then(success => {
                                                                    //display success
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'success',
                                                                        message: success,
                                                                    });
                                                                    resolve();
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                    //display error
                                                                    setSnackbar({
                                                                        open: true,
                                                                        severity: 'error',
                                                                        message: error,
                                                                    });
                                                                })
                                                            getGraphsData();
                                                            return { ...prevState, newData };
                                                        })
                                                    }
                                                }, 600)
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    console.log(oldData.id)
                                                    deleteDealsInSales(oldData.id)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            setDealsInSales(prevState => {
                                                                const data = [...prevState.data];
                                                                data.splice(data.indexOf(oldData), 1);
                                                                return { ...prevState, data };
                                                            });
                                                            resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    // getGraphsData();
                                                }, 600);
                                            }),
                                    }}
                                />
                                :
                                <MaterialTable
                                    title={"Deals in Sales & Verified Compliancy Percent"}
                                    columns={dealsInSales.columns}
                                    data={dealsInSales.data}
                                    detailPanel={rowData => {
                                        // console.log(rowData)
                                        return (
                                            <MetricsChainOfCustodyDealsInSalesDetails rowData={rowData} refreshData={getGraphsData} />
                                        )
                                    }}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                                                    <Chip label="Ireland" color="primary" style={{ marginBottom: 25, marginRight: 10, backgroundColor: 'rgb(50, 50, 240, 0.2)', color: '#000' }} />
                                                    <Chip label="United States" color="primary" style={{ marginBottom: 25, marginLeft: 10, marginRight: 10, backgroundColor: 'rgb(200, 110, 10, 0.2)', color: '#000' }} />
                                                    <Chip label="United Kingdom" color="primary" style={{ marginBottom: 25, marginLeft: 10, backgroundColor: 'rgb(20, 150, 30, 0.2)', color: '#000' }} />
                                                </div>
                                            </div>
                                        ),
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        // exportButton: true,
                                        // filtering: true,
                                        addRowPosition: 'first',
                                        pageSize: 5,
                                        pageSizeOptions: [5, 15],
                                        headerStyle: {
                                            textAlign: 'center'
                                        },
                                        cellStyle: {
                                            textAlign: 'center'
                                        }
                                        //, { value: recruitmentRequisitions.data.length, label: 'All' }] }}
                                    }}
                                    onRowClick={(event, rowData, togglePanel) => togglePanel()} // makes clickable row
                                    actions={
                                        [{
                                            icon: 'refresh',
                                            tooltip: 'Refresh Data',
                                            isFreeAction: true,
                                            onClick: () => {
                                                getGraphsData()
                                                return () => { }
                                            },
                                        }]
                                    }
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    addDealsInSales(newData)
                                                        .then(success => {
                                                            //display success
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'success',
                                                                message: success,
                                                            });
                                                            // resolve();
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                            //display error
                                                            setSnackbar({
                                                                open: true,
                                                                severity: 'error',
                                                                message: error,
                                                            });
                                                        })
                                                    getGraphsData()
                                                    resolve();
                                                    // setCustomerComplaintsStatus(prevState => {
                                                    //     const data = [...prevState.data];
                                                    //     data.push(newData);
                                                    //     // return { ...prevState, data };
                                                    // });
                                                }, 600);
                                            }),
                                        // onRowUpdate: (newData, oldData) =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             if (oldData) {
                                        //                 setDealsInSales(prevState => {
                                        //                     const data = [...prevState.data];
                                        //                     newData.dateCreated = moment(newData.dateCreated).format('DD MMM YYYY')
                                        //                     data[data.indexOf(oldData)] = newData;
                                        //                     console.log('Updated Verification Wip row in DB')
                                        //                     // Update data
                                        //                     updateDealsInSales(newData)
                                        //                         .then(success => {
                                        //                             //display success
                                        //                             setSnackbar({
                                        //                                 open: true,
                                        //                                 severity: 'success',
                                        //                                 message: success,
                                        //                             });
                                        //                             resolve();
                                        //                         })
                                        //                         .catch(error => {
                                        //                             console.log(error)
                                        //                             //display error
                                        //                             setSnackbar({
                                        //                                 open: true,
                                        //                                 severity: 'error',
                                        //                                 message: error,
                                        //                             });
                                        //                         })
                                        //                     getGraphsData();
                                        //                     return { ...prevState, newData };
                                        //                 })
                                        //             }
                                        //         }, 600)
                                        //     }),
                                        // onRowDelete: oldData =>
                                        //     new Promise(resolve => {
                                        //         setTimeout(() => {
                                        //             console.log(oldData.id)
                                        //             deleteDealsInSales(oldData.id)
                                        //                 .then(success => {
                                        //                     //display success
                                        //                     setSnackbar({
                                        //                         open: true,
                                        //                         severity: 'success',
                                        //                         message: success,
                                        //                     });
                                        //                     setDealsInSales(prevState => {
                                        //                         const data = [...prevState.data];
                                        //                         data.splice(data.indexOf(oldData), 1);
                                        //                         return { ...prevState, data };
                                        //                     });
                                        //                     resolve();
                                        //                 })
                                        //                 .catch(error => {
                                        //                     console.log(error)
                                        //                     //display error
                                        //                     setSnackbar({
                                        //                         open: true,
                                        //                         severity: 'error',
                                        //                         message: error,
                                        //                     });
                                        //                 })
                                        //             // getGraphsData();
                                        //         }, 600);
                                        //     }),
                                    }}
                                />
                            }

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Basic Audit / Tech Audit Percent Compliant - IRE, USA & UK
                                </Typography>

                                <ChartistGraph
                                    id='baTaPercComp'
                                    data={baTaPercentageGraph.data}
                                    type={baTaPercentageGraph.type}
                                    options={baTaPercentageGraph.options}
                                    // listener={baTaPercentageGraph.animation}
                                    plugins={baTaPercentageGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="bataie" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Ireland"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="bataus" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="United States"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="batauk" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="United Kingdom"
                                    />

                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Verified Compliancy Percent - IRE, USA & UK
                                </Typography>

                                <ChartistGraph
                                    id='verCompPerc'
                                    data={verCompPercentageGraph.data}
                                    type={verCompPercentageGraph.type}
                                    options={verCompPercentageGraph.options}
                                    // listener={verCompPercentageGraph.animation}
                                    plugins={verCompPercentageGraph.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="vercompie" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(50, 50, 240)',
                                            '&$checked': {
                                                color: 'rgb(50, 50, 240)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="Ireland"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="vercompus" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(200, 110, 10)',
                                            '&$checked': {
                                                color: 'rgb(200, 110, 10)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="United States"
                                    />
                                    <FormControlLabel className={classes.ticks}
                                        control={<Checkbox onChange={handleGraphChangeClick} id="vercompuk" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxOutlineBlankIcon />} style={{
                                            color: 'rgb(20, 150, 30)',
                                            '&$checked': {
                                                color: 'rgb(20, 150, 30)',
                                            },
                                            marginLeft: '35px',
                                        }} />}
                                        label="United Kingdom"
                                    />

                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Deals in Sales - IRE
                                </Typography>

                                <ChartistGraph
                                    id='verWipGraphIE'
                                    data={verWipGraphIE.data}
                                    type={verWipGraphIE.type}
                                    options={verWipGraphIE.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={verWipGraphIE.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Total IRE</p>
                                    <div className={classes.blue}>.</div><p>Discrepant IRE</p>
                                    <div className={classes.grey}>.</div><p>Parts to be Verified IRE</p>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Deals in Sales - USA
                                </Typography>

                                <ChartistGraph
                                    id='verWipGraphUS'
                                    data={verWipGraphUS.data}
                                    type={verWipGraphUS.type}
                                    options={verWipGraphUS.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={verWipGraphUS.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Total USA</p>
                                    <div className={classes.blue}>.</div><p>Discrepant USA</p>
                                    <div className={classes.grey}>.</div><p>Parts to be Verified USA</p>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.marginLeftRight}>
                                <Typography className={classes.graphHeader} variant="h6">
                                    Deals in Sales - UK
                                </Typography>

                                <ChartistGraph
                                    id='verWipGraphUK'
                                    data={verWipGraphUK.data}
                                    type={verWipGraphUK.type}
                                    options={verWipGraphUK.options}
                                    // listener={weeklyUptimeGraph.animation}
                                    plugins={verWipGraphUK.plugins}
                                />
                                <Grid container direction="row" justifyContent="center" alignItems="center">

                                    <div className={classes.green}>.</div><p>Total UK</p>
                                    <div className={classes.blue}>.</div><p>Discrepant UK</p>
                                    <div className={classes.grey}>.</div><p>Parts to be Verified UK</p>

                                </Grid>
                            </Grid>


                        </div>
                    </CssBaseline>
                    <Footer />

                    <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                </div>
            )
        }
    }
}
export default MetricsChainOfCustodyDealsInSales